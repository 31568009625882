import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { getOrdersList } from "../../../services/CustomerService";
import OrdersTab from "../../Common/AssignmentsTab";
import Constants from "../../../shared/Constants";

const {
  sorting: { type: sortType }, language, filter: { options: optionsData, components },
} = Constants;

const header = [
  {
    className: "col",
    value: language.filter_label_status,
    key: "status",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.header_service,
    key: "services",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.common_dog,
    key: "dog_size",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.header_date_time,
    key: "date_time",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.label_organisation,
    key: "organization",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.label_price,
    key: "price",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: "Room",
    key: "room",
    icon: Constants.icons.SortIcon,
  },
  {
    className: "col",
    value: language.common_service_providers,
    key: "serviceProvider",
    icon: sortType.ALPHABETS,
  },
];
const value = [
  {
    className: "col",
    key: "status",
    type: "label",
    group: false,
  },
  {
    className: "col",
    key: "service",
    type: "multipleUser",
    group: false,
  },
  {
    className: "col",
    key: "height",
    type: "label",
    group: true,
  },
  {
    className: "col",
    key: "dateTime",
    type: "date",
    group: true,
  },
  {
    className: "col",
    key: "organization",
    type: "multipleUser",
    group: true,
  },
  {
    className: "col",
    key: "price",
    type: "price",
    group: true,
  },
  {
    className: "col",
    key: "room",
    type: "multipleUser",
    group: false,
  },
  {
    className: "col",
    key: "serviceProvider",
    type: "multipleUser",
    group: false,
  },
];
const filterDataValues = [
  {
    id: "status",
    component: components.CHECKBOX,
    label: language.filter_label_status,
    values:  optionsData.ORDER_STATUS,
    defaultValues: [],
    name: "status",
  },
  {
    id: "service_providers",
    component: components.LETTERSELECT,
    label: language.common_service_provider,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "service_providers",
  },
  {
    id: "services",
    component: components.CHECKBOX,
    label: language.common_service,
    values: [],
    defaultValues: [],
    name: "services",
  },
  {
    id: "date",
    component: components.DATEPICKER,
    label: language.filter_date_diapason,
    fromDate: { defaultDate: "" },
    toDate: { defaultDate: "" },
    name: "date_time",
  },
  {
    id: "room",
    component: components.LETTERSELECT,
    label: language.filter_label_room,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "room",
  },
  {
    id: "organization",
    component: components.LETTERSELECT,
    label: language.filter_label_organization,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "organization",
  },
];

const Orders = ({ userId, redirectTo }) => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 10,
    pages: 1,
  });
  const [filterOptions, setFilterOptions] = useState(filterDataValues);
  const serviceProviderFilters = useSelector((state) => state.FilterReducer.serviceProviderFilters);
  const roomFilters = useSelector((state) => state.FilterReducer.roomFilters);
  const organizationFilters = useSelector((state) => state.FilterReducer.organizationFilters);
  const allServices = useSelector((state) => state.FilterReducer.allServices);

  const fetchFilters = async (options) => {
    setLoading(true);
    const filteredData = options || filterOptions;
    filteredData[1].availableValues = serviceProviderFilters;
    filteredData[2].values = allServices;
    filteredData[4].availableValues = roomFilters;
    filteredData[5].availableValues = organizationFilters;
    setFilterOptions(filteredData);
    setLoading(false);
  };

  /**
   *
   * @async
   * @function fetchAssignments Fetches assignments
   * @param {String} searchInput search text
   *
   */
  const fetchAssignments = (options, params = []) => {
    setLoading(true);
    getOrdersList(userId, params).then((response) => {
      const { meta: srcMetaData = {}, data = {} } = response;
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMetaData;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      const orders = data.map((order) => (
        {
          ...order,
          assignments: order.assignments.map((assignment) => (
            {
              ...assignment,
              assignment_start_datetime: moment.utc(assignment.assignment_start_datetime).local(),
            }
          )),
        }
      ));
      fetchFilters(options);
      setOrderData(orders);
      setMeta(metaData);
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  };

  const handleGotoPage = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 10;
    setMeta(meta);
  };
  return (
    <OrdersTab
      handleGotoPage={handleGotoPage}
      loading={loading}
      fetchData={fetchAssignments}
      meta={meta}
      isOrder
      redirectTo={redirectTo}
      filterDataValues={filterDataValues}
      header={header}
      value={value}
      orderData={orderData}
    />
  );
};
Orders.propTypes = {
  redirectTo: PropTypes.func,
  userId: PropTypes.string,
};
Orders.defaultProps = {
  redirectTo: () => {},
  userId: "",
};
export default Orders;
