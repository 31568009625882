import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDLabel, Div } from "../../../../styles/Styles";
import { BoxDivision } from "../../BookingFlowStyles/BookingFlowStyles";
import BookingFlowCards from "../../CommonSections/BookingFlowCards";
import Theme from "../../../../styles/Theme";
import Constants from "../../../../shared/Constants";
import {
  bookingFlowServices,
  fetchChooseServicesList,
  emptyAddonsToStore,
  emptyBookingFlowValues,
} from "../../../../store/actions/BookingFlowServicesAction";
import Loader from "../../../../components/Loader/Loader";

const { icons, images } = Constants;

const BookingCategories = ({ onStepClick, heading, uidChecked }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getCategoriesList = useSelector(
    (state) => state.BookingFlowServicesReducer.getCategoriesList,
  );

  useEffect(() => {
    dispatch(fetchChooseServicesList());
  }, []);

  const chooseServiceHandler = (item) => () => {
    history.push(Constants.routes.chooseDog.url);
    if(uidChecked && uidChecked !== item?.serviceId) {
      dispatch(emptyBookingFlowValues("service"))
    }
    dispatch(bookingFlowServices(item));
    dispatch(emptyAddonsToStore());
  };
  return (
    <Div>
      {getCategoriesList.length > 0 ? (
        getCategoriesList.map((item, index) => {
          const { name } = item;
          return (
            <Div key={index}>
              {item.service_count > 0  && (
                <>
                  <MDLabel
                    type="booking_Page_Title"
                    width={Theme.widths.full}
                    justifyContent="center"
                    fontSize={Theme.fontSizes.xl}
                    pt={2}
                    mt={4}
                    pb={4}
                  >
                    {name}
                  </MDLabel>
                  <BoxDivision type="cate_One">
                    {item?.services?.length > 0 &&
                      item.services.map((service) => {
                        const { id, price, duration } = service;
                        return (
                          <BookingFlowCards
                            key={id + price}
                            chooseId={id}
                            uidChecked={uidChecked}
                            id={item.id}
                            dogFace={service?.profile_pic?.url || icons.FemaleDog}
                            imgWidth="160px"
                            title={service.name}
                            timeIcon={icons.ClockIcon}
                            tag={price.mini}
                            time={duration.mini}
                            tagIcon={images.TagSingleIcon}
                            onStepClick={onStepClick}
                            heading={heading}
                            category={item.name}
                            buttonOuter="btnActive"
                            chooseServiceHandler={chooseServiceHandler}
                            service={service} />
                        );
                      })
                    }
                  </BoxDivision>
                </>
              )}
            </Div>
          );
        })
      ) : (
        <>
          <Div>
            <MDLabel
              type="booking_Page_Title"
              width={Theme.widths.full}
              justifyContent="center"
              fontSize={Theme.fontSizes.xl}
              pt={2}
              mt={4}
              pb={4}
            >
              <Loader width={120} height={40} />
            </MDLabel>
            <BoxDivision type="cate_One">
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
            </BoxDivision>
            <BoxDivision type="cate_One">
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
              <Div m={3}>
                <Loader width={130} height={170} />
              </Div>
            </BoxDivision>
          </Div>
        </>
      )}
    </Div>
  );
};
BookingCategories.propTypes = {
  onStepClick: PropTypes.func,
  heading: PropTypes.string,
  uidChecked: PropTypes.string,
};
BookingCategories.defaultProps = {
  onStepClick: () => { },
  heading: "",
  uidChecked: "",
};
export default BookingCategories;
