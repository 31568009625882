import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import PortalContainer from "../../components/Containers/PortalContainer";
import Button from "../../components/Button/Button";
import FilterButton from "../../components/Filter/FilterButton";
import { Div, Header } from "../../styles/Styles";
import ListScreen from "../../components/ListScreen/ListScreen";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Pagination from "../../components/Pagination/Pagination";
import Constants from "../../shared/Constants";
import { getOrdersList } from "../../services/OrdersService";
import MainLoader from "../../components/Loader/MainLoader";
import { IsDesktop, IsMobile } from "../../components/ListScreen/Styles";
import { setLoader } from "../../store/actions/AppAction";
import SortButton from "../../components/Sorting/Sorting";
import { checkRightPermission } from "../../shared/utils";
import SearchBox from "../../components/Input/SearchBox";

const {
  language, filter: { options: optionsType, components },
  sorting: { type: sortType, keys: { sortBy, sort } },
  routes, adminRoutes,
} = Constants;

const filterColumns = [
  {
    id: "status",
    component: components.CHECKBOX,
    label: language.filter_label_status,
    values: optionsType.ORDER_STATUS,
    defaultValues: [],
    name: "status",
  },
  {
    id: "services",
    component: components.CHECKBOX,
    label: language.common_service,
    values: [],
    defaultValues: [],
    name: "services",
  },
  {
    id: "date",
    component: components.DATEPICKER,
    label: language.filter_date_diapason,
    fromDate: { defaultDate: "" },
    toDate: { defaultDate: "" },
    name: "date_time",
  },
];

const Orders = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const isLoader = useSelector((state) => state.AppReducer.isLoader);
  const serviceProviderFilters = useSelector((state) => state.FilterReducer.serviceProviderFilters);
  const roomFilters = useSelector((state) => state.FilterReducer.roomFilters);
  const organizationFilters = useSelector((state) => state.FilterReducer.organizationFilters);
  const allServices = useSelector((state) => state.FilterReducer.allServices);
  const customerFilters = useSelector((state) => state.FilterReducer.customerFilters);
  const onLoading = (payload) => dispatch(setLoader(payload));
  const [sortAscending, setSortAscending] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const search = location.search;
  const params = new URLSearchParams(search);
  const pageNo = params.get("page");
  const searchWord = params.get("search");
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: pageNo || 1,
    pageSize: 10,
    pages: 1,
  });
  const { key } = location;
  const [sortedBy, setSortedBy] = useState("created_at");
  // eslint-disable-next-line no-nested-ternary
  const userInfo = userDetails?.user_access
    ? userDetails
    : localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
  const { permissions = [] } = userInfo;
  const hasWriteAccess = checkRightPermission(permissions,"is_order_editable",Constants.WRITE);

  const [toBeFilteredData, setToBeFilteredData] = useState({});
  const orderFilterValues = JSON.parse(params.get("filter"));
  const [filterOptions, setFilterOptions] = useState(filterColumns);
  const [filterValues, setFilterValues] = useState(orderFilterValues || {});
  const [showSortModal, setShowSortModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchWord || "");

  const header = [
    {
      className: "col-3",
      value: language.filter_label_status,
      key: "status",
    },
    {
      className: "col",
      value: language.label_client,
      key: "customer",
    },
    {
      className: "col",
      value: language.service,
      key: "services",
    },
    {
      className: "col",
      value: language.header_date_time,
      key: "dateTime",
    },
    {
      className: "col",
      value: language.label_organisation,
      key: "organization",
    },
    {
      className: "col",
      value: language.label_service_provider,
      key: "service_provider",
    },
  ];
  const value = [
    {
      className: "col-3",
      key: "status",
      type: "label",
      group: false,
    },
    {
      className: "col",
      key: "customer",
      type: "singleUser",
      group: false,
      isDesktop: true,
    },
    {
      className: "col",
      key: "service",
      type: "multipleUser",
      group: false,
    },
    {
      className: "col",
      key: "dateTime",
      type: "date",
      group: true,
    },
    {
      className: "col",
      key: "organization",
      type: "multipleUser",
      group: true,
      isOrg: true,
    },
    {
      className: "col",
      key: "serviceProvider",
      type: "multipleUser",
      group: false,
    },
  ];

  const handleRedirectToOrg = (id) => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.organization}/${id}/${adminRoutes.editOrganization}`);
  };

  const fetchFilters = (options) => {
    const filters = options || filterOptions;
    filters[1].values = allServices;
    setFilterOptions(filters);
  };

  /**
   *
   * @async
   * @function fetchOrders Fetches orders
   * @param {String} searchInput search text
   *
   */
  const fetchOrders = (filters = undefined, searchInput, options, searchWord = "") => {
    onLoading(true);
    const filter = filters || toBeFilteredData;
    fetchFilters(options);
    const queryParams = [
      ["pageNumber", searchInput ? 1 : meta.pageNumber],
      ["pageSize", meta.pageSize],
      [sortBy, sortedBy],
      [sort, sortAscending ? sortType.ASC : sortType.DESC],
      ["filter", filter],
      ["search_name", searchWord],
    ];
    getOrdersList(queryParams).then((response) => {
      const { meta: srcMeta = {}, data = {} } = response;
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMeta;
      const newMeta = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      const responseData = data.map((order) => ({ ...order, name: order?.customer?.name || "" }));
      window.scrollTo(0,0);
      setOrders(responseData);
      setMeta(newMeta);
      onLoading(false);
    }, (error) => {
      console.error(error);// Error!
      onLoading(false);
    });
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePagination = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 10;
    setMeta(meta);
    fetchOrders(false, false, false, searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  /**
   *
   * Calls fetchOrders function when the below values changes
   * [loading, meta.pageNumber,location, sortAscending, sortedBy]
   *
   */

  useEffect(() => {
    fetchFilters();
  }, [serviceProviderFilters, customerFilters, organizationFilters, allServices, roomFilters]);

  useEffect(() => {
    const { filter = {}, options = filterOptions } = filterValues;
    setFilterOptions(options);
    if(!pageNo){
      fetchOrders(filter, true, options, searchQuery);
    } else {
      fetchOrders(filter, false, options, searchQuery);
    }
  }, [sortAscending, sortedBy, key]);

  /**
   *
   * @function handleAddAssignment
   * Redirects to create new room page
   *
   */
  const handleAddOrder = () => {
    history.push(routes.addOrder.url);
  };

  /**
   *
   * Redirects to detailed view page
   * @param {object} item order data
   * @returns to selected order's detailed page
   *
   */
  const handleDetailPage = (item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.orders}/${item.id}/${adminRoutes.editOrder}`);
  };

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSorting = (name) => () => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };

  const handleSortModal = () => {
    setShowSortModal(!showSortModal);
  };

  const handleApplySorting = (sortKey = "", sortOrder = false) => {
    setSortAscending(sortOrder);
    setSortedBy(sortKey);
    setShowSortModal(false);
  };

  const handleFilterApply = async (values = [], options = undefined) => {
    const filter = {};
    values.map((obj) => {
      if (filter[obj.name]) {
        filter[obj.name].push(obj.value);
      } else {
        filter[obj.name] = [];
        filter[obj.name].push(obj.value);
      }
      return obj;
    });
    setToBeFilteredData(filter);
    setFilterOptions(options);
    setIsFilterOpen(false);
    setFilterValues({
      filter,
      values,
      options,
    })
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("filter", JSON.stringify({ filter, options }));
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    await fetchOrders(filter, true, options, searchQuery);
  };

  const handleSearchOnChange = useCallback((e) => {
    const { target: { value: searchValue } } = e;
    setSearchQuery(searchValue);
  });

  const handleApplySearch = useCallback((isEmpty = false) => {
    setMeta({...meta, pageNumber: 1});
    fetchOrders(false, true, false, isEmpty ? "" : searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", 1);
    currentUrlParams.set("search", searchQuery);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());

  });

  const handleClearSearch = useCallback(() => {
    setSearchQuery("");
    setMeta({...meta, pageNumber: 1});
    fetchOrders(false, true, false, "");
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete("search");
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  });

  return (
    <PortalContainer>
      <Div justifyContent="space-between" display="flex" pb={4}>
        <Div>
          <Header display="inline-block">
            <FormattedMessage id="common_orders" />
          </Header>
        </Div>
        <Div display="flex" alignItems="center">
          <IsDesktop>
            <SearchBox
              name="searchQuery"
              value={searchQuery}
              onChange={handleSearchOnChange}
              onSearch={handleApplySearch}
              onClear={handleClearSearch}
            />
          </IsDesktop>
          <IsDesktop>
            <Div className="row m-0">
              {hasWriteAccess && (
                <Button
                  label={<FormattedMessage id="common_add_new" />}
                  primary
                  startIcon={PlusIcon}
                  width="175px"
                  onClick={handleAddOrder}
                  padding="10px !important"
                />
              )}
              <FilterButton
                open={isFilterOpen}
                options={filterOptions}
                closeFilter={handleCloseFilter}
                onApply={handleFilterApply}
              />
            </Div>
          </IsDesktop>
        </Div>
      </Div>
      <IsMobile>
        <Div display="flex" justifyContent="space-between" pb={20}>
          {hasWriteAccess && (
            <Div>
              <Button
                label={<FormattedMessage id="common_add_new" />}
                primary
                startIcon={PlusIcon}
                width="175px"
                onClick={handleAddOrder}
                padding="10px !important"
              />
            </Div>
          )}
          <Div display="flex">
            <SortButton
              isOpen={showSortModal}
              sortingKeys={header}
              sortedBy={sortedBy}
              sortAscending={sortAscending}
              onClose={handleSortModal}
              onSubmit={handleApplySorting}
            />
            <FilterButton
              open={isFilterOpen}
              options={filterOptions}
              closeFilter={handleCloseFilter}
              onApply={handleFilterApply}
            />
          </Div>
        </Div>
      </IsMobile>
      <IsMobile pb={20}>
        <SearchBox
          name ="searchQuery"
          value={searchQuery}
          onChange={handleSearchOnChange}
          onSearch={handleApplySearch}
          onClear={handleClearSearch}
        />
      </IsMobile>
      {isLoader && <MainLoader type="list" />}
      {!isLoader && (
        <>
          <ListScreen
            moreButton
            header={header}
            value={value}
            data={orders}
            isOrders
            onClick={handleDetailPage}
            handleSorting={handleSorting}
            redirectToOrg={handleRedirectToOrg}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            dataNotFoundMessage={<FormattedMessage id="orders_not_found" />}
          />
          {meta.pages > 1 && (
            <Div py={4} display={["block", "flex", "flex"]} >
              <Div display={["flex"]} justifyContent="center" alignItems="center">
                {`${orders.length < 10 ? orders.length + ((meta.pageNumber - 1) * 10) : orders.length * meta.pageNumber} / ${meta.totalCount}`}
              </Div><Div width="90%" justifyContent="center">
                <Pagination gotoPage={handlePagination} cursor={meta} />
              </Div>
            </Div>
          )}
        </>
      )}
    </PortalContainer>
  );
};

export default Orders;
