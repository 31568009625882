import styled from "styled-components";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import { Div } from "../../styles/Styles";

export const CalendarWrapper = styled(Div)`
  width: 100%;
  & .react-datepicker-popper {
    z-index: 5;
  }
  & .react-datepicker {
    border: none;
    box-shadow: ${Theme.shadows.light};
    color: ${Colors.Black};
  }
  & .react-datepicker__header {
    background-color: ${Colors.White} !important;
    border-bottom: none;
    padding-top: 0px;
  }
  & .react-datepicker__day-names {
    background-color: rgb(202, 221, 253, 0.5);
  }
  & .react-datepicker__day {
    text-align: center;
    font-size: ${Theme.fontSizes.regular}px;
    font-family: ${Theme.fonts.semibold};
  }
  & .react-datepicker__day-name {
    opacity: 0.8 !important;
    font-size: ${Theme.fontSizes.small}px;
    font-family: ${Theme.fonts.regular};
  }
  & .react-datepicker__current-month {
    padding: 8px;
    background-color: ${Colors.DarkWhite};
    font-family: ${Theme.fonts.semibold};
    font-size: ${Theme.fontSizes.medium}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Colors.Black};
    text-align: left;
    font-weight: 500;
  }
  & .react-datepicker__day--keyboard-selected {
    font-family: ${Theme.fonts.semibold};
    background-color: ${Colors.White};
    color: ${Colors.Black};
    &:focus {
      border: none;
      outline: none;
    }
  }
  & .react-datepicker__day--selected {
    background-color: ${Colors.Blue};
    color: ${Colors.Black};
    opacity: 1 !important;
    padding: 1px 0px 1px 0px;
    &:focus {
      border: none;
      outline: none;
    }
  }
  & .react-datepicker__day--today {
    font-weight: normal;
  }
  & .react-datepicker__navigation {
    outline: none !important;
  }
  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${Colors.DarkWhite};
  }
  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${Colors.DarkWhite};
  }
  & .react-datepicker__navigation--previous {
    right: 50px;
    left: auto;
    border-right-color: ${Colors.Primary};
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${Colors.Primary};
  }
`;

export const DropDownListItem = styled(Div)`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  .dropdown-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  &:active,
  &:focus,
  &:hover {
    background-color: ${Colors.DarkWhite};
    font-weight: 600;
    border-radius: 5px;
  }
`;

export const DropDownWrapper = styled(Div)`
  border: 1px solid ${Colors.Border};
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 3;
  width: 100%;
  padding: 16px 0px;
  background-color: ${Colors.DarkWhite};
  max-height: 300px;
  overflow: auto;
`;

export const CheckBox = styled.div`
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  border: 2px solid ${Colors.LightGrey};
  display: flex;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  align-self: start;
  background-color: ${({ bgcolor }) => (bgcolor || `${Colors.Transparent}`)};
`;

export const InputContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
