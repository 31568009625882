import React, {useRef, forwardRef, useImperativeHandle} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { BoxDivision } from "../BookingFlowStyles/BookingFlowStyles";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import ArrowLeft from "../../../assets/Icons/PrimaryColorArrow.svg";
import { editOrderSummary } from "../../../store/actions/BookingFlowServicesAction";

const { icons } = Constants;

const NextAndBack = forwardRef(({ disabled, typeName }, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const buttonRef = useRef(null);

  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);
  const showNextButton = false;

  const handlerPreviousStep = () => {
    const { location: { pathname = "" } } = history;
    let moveToStep = steps;
    if (steps === 2 || pathname === Constants.routes.chooseDog.url) {
      moveToStep = 1;
      history.push(Constants.routes.chooseService.url);
    } else if (steps === 3 || pathname === Constants.routes.chooseCenter.url) {
      moveToStep = 2;
      history.push(Constants.routes.chooseDog.url);
    } else if (steps === 4 || pathname === Constants.routes.chooseTimeDate.url) {
      moveToStep = 3;
      history.push(Constants.routes.chooseCenter.url);
    } else if (steps === 5 || pathname === Constants.routes.orderSummary.url) {
      moveToStep = 4;
      history.push(Constants.routes.chooseTimeDate.url);
    }
    dispatch(editOrderSummary(moveToStep));
  };

  const handlerNextStep = () => {
    const { location: { pathname = "" } } = history;
    let moveToStep;
    if (steps === 2 || pathname === Constants.routes.chooseDog.url) {
      moveToStep = 3;
      history.push(Constants.routes.chooseCenter.url);
    } else if (steps === 3 || pathname === Constants.routes.chooseCenter.url) {
      moveToStep = 4;
      history.push(Constants.routes.chooseTimeDate.url);
    } else if (steps === 4 || pathname === Constants.routes.chooseTimeDate.url) {
      moveToStep = 5;
      history.push(Constants.routes.orderSummary.url);
    } else {
      moveToStep = 1;
      history.push(Constants.routes.chooseService.url);
    }
    dispatch(editOrderSummary(moveToStep));
  };

  useImperativeHandle(ref, () => ({
    buttonRef: buttonRef,
    handlerNextStep: handlerNextStep
  }));

  return (
    <BoxDivision pt={3} pb={10} type={typeName}>
      <Button
        secondary
        label={<FormattedMessage id="common_back" defaultMessage="Back" />}
        className="mx-2"
        width="160px"
        startIcon={ArrowLeft}
        onClick={handlerPreviousStep}
        mr={4}
      />
      {showNextButton && (
        <Button
          ref={buttonRef}
          primary
          className="mx-2"
          width="160px"
          onClick={handlerNextStep}
          label={<FormattedMessage id="common_next" defaultMessage="Next" />}
          endIcon={icons.ArrowRightWhite}
          mr={4}
          disabled={!disabled}
        />
      )}
    </BoxDivision>
  );
});
NextAndBack.propTypes = {
  disabled: PropTypes.bool,
  typeName: PropTypes.string,
};
NextAndBack.defaultProps = {
  disabled: false,
  typeName: "",
};
NextAndBack.displayName = "NextAndBack";
export default NextAndBack;
