import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Theme from "../../../styles/Theme";
import Constants from "../../../shared/Constants";
import ServiceAppointment from "../components/ServiceAppointment";
import ServiceSelected from "../components/ServiceSelected";
import { MobileDiv } from "../Style";
import { Box } from "../../../styles/Styles";
import { setStepAndContentOfBooking, setSelectedCartId } from "../../../store/actions/BookingFlowServicesAction";
import {
  stepRoute, formatDate
} from "../../../shared/utils";
import {
  Division, LabelBox, SpanBox,
} from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import moment from "moment";

const { icons, images, input: { format: { twentyFourHourFormat, dottedHourFormat, twoDigitDateFormat } } } = Constants;

const BodyAccordion = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isUserLoggedIn = useSelector((state) => state.BookingFlowServicesReducer.isUserLogged);
  const {
    item, index, activeIndex,
  } = props;
  const handleOnClick = (step) => () => {
    dispatch(setSelectedCartId(index));
    dispatch(setStepAndContentOfBooking({ ...item, selectCartId: index }, step));
    const timer = setTimeout(() => {
      history.push(stepRoute(step));
    }, 1000);
    return () => clearTimeout(timer);
  };

  const { selectedServices : { service : { is_full_day_service: isAFullDayService = false } = {} } = {} } = item;

  const dogSize = isUserLoggedIn ? item?.dogSizes?.duration : `${item?.dogSizes?.duration} min`;

  return (
    <MobileDiv
      display={activeIndex === index ? "flex" : "none"}
      className="m-auto"
      borderRadius={Theme.radii.medium}
      type="cart"
      pl={2}
      pr={4}
    >
      <Box width="5%" mr={2} py={2}>
        &nbsp;
      </Box>
      <Box
        width="95%"
        boxShadow={Theme.shadows.light}
        borderRadius={activeIndex === index ? "0 0 15px 15px" : Theme.radii.medium}
        justifyContent="start !important"
      >
        <Box justifyContent="start !important" className="row" py={2} px={3} mb={3}>
          <ServiceSelected
            imageSrc={item?.selectedServices?.banner}
            headerTitle={item?.selectedServices?.serviceName}
            leftIcon={images.TagSingleIcon}
            leftContent={item?.selectedServices?.categoryName || <FormattedMessage id="label_service" defaultMessage="Service" />}
            newWidth="responsive"
            stepValue={1}
            handleOnClick={handleOnClick}
          />
          <ServiceSelected
            imageSrc={item?.dogSizes?.dogFace}
            headerTitle={item?.dogSizes?.dogWeight}
            leftIcon={icons.ClockIcon}
            leftContent={isAFullDayService === 1 ? Constants.language.label_day : dogSize}
            rightIcon={images.TagSingleIcon}
            rightContent={`${item?.dogSizes?.price} kr`}
            newWidth="responsive"
            stepValue={2}
            handleOnClick={handleOnClick}
          />
          <ServiceSelected
            imageSrc={item?.selectedLocation?.profilePic.url || icons.Avatar}
            headerTitle={item?.selectedLocation?.organisationName}
            leftIcon={icons.ServiceLocation}
            leftContent={item?.selectedLocation?.address}
            type="responsive_view"
            newWidth="responsive"
            stepValue={3}
            handleOnClick={handleOnClick}
          />
          {isAFullDayService  ? (
            <>
              <Division
                onClick={handleOnClick(4)}
                height={65}
                className="edit_button"
                ml="16px"
                mr={["20px", 2, 2]}
                mt={[1, 10]}
                mb={2}
                type="booking_service_date"
                width={["100%", "100%", "258px"]}
              >
                <LabelBox flexWrap="wrap"  width={["100%", "100%", "258px"]}>
                  <SpanBox type="booking_date" p={1}>
                    <FormattedMessage id="label_start_date" defaultMessage="Start date" />
                  </SpanBox>
                  <SpanBox type="booking_time" mx={["auto"]} p={1}>
                    {Array.isArray(item?.selectedDate) ? item?.selectedDate[0]: item?.selectedDate}
                  </SpanBox>
                </LabelBox>
              </Division>
              <Division
                onClick={handleOnClick(4)}
                height={65}
                className="edit_button"
                mr={["20px", 2, 2]}
                ml="16px"
                mt={[1, 10]}
                mb={2}
                type="booking_service_date"
                width={["100%", "100%", "258px"]}
              >
                <LabelBox flexWrap="wrap"  width={["100%", "100%", "258px"]}>
                  <SpanBox type="booking_date" p={1}>
                    <FormattedMessage id="label_end_date" defaultMessage="End date" />
                  </SpanBox>
                  <SpanBox p={1} type="booking_time" mx={["auto"]}>
                    {Array.isArray(item?.selectedDate) ? item?.selectedDate[1]: item?.selectedDate}
                  </SpanBox>
                </LabelBox>
              </Division>
            </>
          ) : (
            <ServiceAppointment
              scheduledOn={formatDate(item?.selectedDate, twoDigitDateFormat)}
              scheduledAt={moment(item?.selectedProvider?.serviceTime?.split(" - ")[0], twentyFourHourFormat).format(dottedHourFormat)}
              stepValue={4}
              handleOnClick={handleOnClick}
            />
          )}
          <ServiceSelected
            imageSrc={item?.selectedProvider?.providerFace || icons.Avatar}
            headerTitle={item?.selectedProvider?.providerName}
            leftIcon={icons.UserSettingsIcon}
            leftContent={
              item?.service_provider_id?.type || (
                <FormattedMessage id="service_provider" defaultMessage="Service provider" />
              )
            }
            newWidth="responsive"
            fontSize={Theme.fontSizes.small}
            fontWeight={Theme.fontWeights.medium}
            fontFamily={Theme.fonts.semibold}
            stepValue={4}
            handleOnClick={handleOnClick}
          />
          {item?.selectedAddOns?.length > 0
                && item?.selectedAddOns?.map((addOnValue) => (
                  <ServiceSelected
                    headerTitle={addOnValue?.name}
                    leftIcon={icons.ClockIcon}
                    leftContent={addOnValue?.duration.concat(" min")}
                    rightIcon={images.TagSingleIcon}
                    rightContent={String(addOnValue?.price).concat(" kr")}
                    type="responsive_view"
                    fontSize={Theme.fontSizes.xs}
                    fontWeight={Theme.fontWeights.normal}
                    fontFamily={Theme.fonts.regular}
                    stepValue={5}
                    handleOnClick={handleOnClick}
                  />
                ))}
        </Box>
      </Box>
    </MobileDiv>
  );
};
BodyAccordion.propTypes = {
  item: PropTypes.node,
  index: PropTypes.node,
  activeIndex: PropTypes.node,
};
BodyAccordion.defaultProps = {
  item: {},
  index: "",
  activeIndex: "",
};
export default memo(BodyAccordion);
