import React, { useEffect, useState, memo } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import BannedModal from "./components/BannedModal";
import SelectAnotherAvailabilityModal from "./components/SelectAnotherAvailabilityModal";
import HeadAccordion from "./wrapper/HeadAccordion";
import BodyAccordion from "./wrapper/BodyAccordion";
import BottomSection from "./components/BottomSection";
import HeaderSection from "./components/HeaderSection";
import { BoxDivision } from "../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { Div, MDLabel, Span } from "../../styles/Styles";
import {
  deleteGuestCartItem,
} from "../../store/actions/BookingFlowServicesAction";
import {
  mapDogSizeToPrice, mapDogSizeToDuration,
  redirectToPage,
  mapSizeToDog, isLoggedIn, isCustomer,
} from "../../shared/utils";
import { verifyCustomer } from "../../services/CustomerService";
import * as bookingAction from "../../store/actions/BookingFlowServicesAction";
import * as types from "../../store/actions/types";
import * as payAction from "../../store/actions/PaymentAction";
import { emptyPaymentSnippet, setPayLoaData } from "../../store/actions/PaymentAction";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const Cart = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handlePush = (path) => () => {
    history.push(path);
  };

  const isUserLogged = localStorage.getItem("token");

  const { icons, routes } = Constants;
  const [cartData, setCartData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const { availabilityErrorPopup, emailErrorPopup } = useSelector(
    (state) => state.BookingFlowServicesReducer,
  );

  const { bannedPopup } = useSelector(
    (state) => state.BookingFlowServicesReducer,
  );

  const [duplicateCartItem, setDuplicateCartItem] = useState(false);

  const payloadData = useSelector((state) => state.PaymentReducer.payloadData);
  const paymentDone = useSelector((state) => state.PaymentReducer.paymentDone);
  const orderData = useSelector((state) => state.PaymentReducer.orderData);

  useEffect(() => {
    dispatch(emptyPaymentSnippet());
    dispatch(bookingAction.fetchChooseServicesList());
    const guestCart = sessionStorage.getItem(Constants.storage.cart);
    const cart = (guestCart && JSON.parse(guestCart)) || [];
    setCartData(cart);
    dispatch({
      payload: cart?.length,
      type: types.CART_COUNT,
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const toggleHandler = (index) => () => {
    setActiveIndex(index);
  };

  const deleteHandler = (id, index) => () => {
    dispatch(deleteGuestCartItem(id, index));
    // guest user cart
    const guestCart = sessionStorage.getItem(Constants.storage.cart);
    const cart = (guestCart && JSON.parse(guestCart)) || [];
    setCartData(cart);
  };

  const totalService = Number(cartData?.reduce(
    (acc, val) => acc + val?.dogSizes?.price, 0,
  ));
  const totalcharge = Number(
    cartData?.reduce(
      (acc, val) => {
        const value = (val?.selectedAddOns.length > 0
          && val?.selectedAddOns?.map((item) => (Number(item?.price))) || 0);
        const totalPrice = value &&  value?.reduce((x,y)=> Number(x)+Number(y));
        return Number(acc) + Number(totalPrice);
      },
      0,
    ),
  );

  const totalNoOfAddOns = Number(
    cartData?.reduce(
      (acc, val) => {
        const value = val?.selectedAddOns.length;
        return acc + value;
      },
      0,
    ),
  );
  const totalRate = Number(totalService) + Number(totalcharge);

  const handleModalClick = () => {
    dispatch(bookingAction.bannedPopup(!bannedPopup));
  };
  const handleModalClose = () => {
    setDisableBtn(false);
    setDuplicateCartItem(false);
  };

  const handleAvailabilityModalClick = () => {
    dispatch(bookingAction.availabilityErrorPopup(!availabilityErrorPopup));
  };

  const handleEmailErrorModalClick = () => {
    setDisableBtn(false);
    dispatch(bookingAction.emailErrorPopup(!emailErrorPopup));
  };

  const rawHTML = useSelector((state) => state.PaymentReducer.snippetResponse?.html_snippet);

  function renderSnippet(htmlSnippet) {
    const checkoutContainer = document.getElementById("my-checkout-container");
    checkoutContainer.innerHTML = htmlSnippet;
    const scriptsTags = checkoutContainer.getElementsByTagName("script") || [];
    for (let i = 0; i < scriptsTags.length; i += 1) {
      const { parentNode } = scriptsTags[i];
      const newScriptTag = document.createElement("script");
      newScriptTag.type = "text/javascript";
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }

  useEffect(() => {
    if (rawHTML) {
      renderSnippet(rawHTML);
    }
  }, [rawHTML]);

  useEffect(() => {
    if (payloadData && disableBtn) {
      payloadData.assignment_list.map((assignment, index) => {
        const content = [...payloadData.assignment_list];
        content.splice(index, 1);
        const duplicateItem = content.find((obj) => (
          obj.assignment_datetime === assignment.assignment_datetime
          && obj.service_provider_id === assignment.service_provider_id
        ));
        if (duplicateItem) {
          setDuplicateCartItem(true);
        }
        return assignment;
      });
    }
  }, [payloadData]);

  const checkOrderPrice = async () => {
    if (totalRate === 0) {
      dispatch(payAction.placeOrder());
    } else {
      dispatch(payAction.requestPaymentAction());
    }
  };

  useEffect(() => {
    if (paymentDone && orderData?.id && totalRate === 0) {
      setDisableBtn(false);
      history.push(`${routes.orderConfirm.url}?order_id=${orderData.id}&isChecked=false&isFreeOrder=true`);
    }
  }, [paymentDone, orderData]);

  const handleVerifyCustomer = (values) => {
    dispatch(setPayLoaData());
    if (isUserLogged) {
      const cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
      if (cartData.length > 0 && cart?.length > 0) {
        const obj = cart && cart?.map((c) => {
          const {
            selectedServices = {},
            selectedLocation = {},
            selectedAddOns = {},
            selectedDate = "",
            dogSizes = {},
            selectedProvider: cartSelectedProvider = {},
          } = c;
          let caluculateTotal = 0;
          selectedAddOns.map((x) => {
            caluculateTotal += Number(x.price || 0);
            return x;
          });
          return {
            service_title: selectedServices.serviceName,
            service_category: selectedServices.categoryName,
            total: Number(caluculateTotal) + Number(dogSizes.price),
            service: {
              name: selectedServices.serviceName,
              type: "service",
              is_full_day_service: selectedServices?.service?.is_full_day_service === 1,
              bnr: selectedServices.banner || icons.PawIcon,
              tagIcon: icons.TagIcon,
              ...selectedServices,
            },
            service_location: {
              title: selectedLocation.organisationName,
              bnr: selectedLocation.profilePic || icons.Avatar,
              area: selectedLocation.address,
              locationIcon: icons.ServiceLocation,
              location: selectedLocation.location || {},
              ...selectedLocation,
            },
            service_detail: {
              title: dogSizes.dogName,
              size: dogSizes.dogWeight,
              time: mapDogSizeToDuration(dogSizes.dogWeight, selectedServices),
              tagRate: mapDogSizeToPrice(dogSizes.dogWeight, selectedServices),
              dogSizes: icons.PawIcon,
              iconOne: icons.ClockIcon,
              iconTwo: icons.TagIcon,
              age: mapSizeToDog(dogSizes.dogWeight),
              ...dogSizes,
            },
            service_provider: {
              provider: cartSelectedProvider.providerName,
              info: Constants.language.common_service_provider,
              face: cartSelectedProvider.providerFace || icons.Avatar,
              location: icons.UserSettingsIcon,
              ...cartSelectedProvider,
            },
            service_schedule: {
              scheduled_on: selectedDate,
              scheduled_at: cartSelectedProvider.serviceTime,
            },
            additional_service: selectedAddOns,
          };
        });
        dispatch(payAction.loadCartContent(obj));
        setDisableBtn(true);
        checkOrderPrice();
      }
    } else {
      setDisableBtn(true);
      const { email = "", firstName = "", lastName = "", telephone = "", create_user = false } = values;
      sessionStorage.setItem("guestEmail", email);
      sessionStorage.setItem("guestFirstName", firstName);
      sessionStorage.setItem("guestLastName", lastName);
      sessionStorage.setItem("guestPhoneNumber", telephone);
      sessionStorage.setItem("guestCreateGuestUser", create_user);
      const encodedEmail = encodeURIComponent(email);
      verifyCustomer(encodedEmail).then(
        (res) => {
          const { customer_register: customerRegistered = false, status: customerstatus = "" } = res;
          setDisableBtn(false);
          if (customerstatus !== "Customer Banned") {
            if (customerRegistered) {
              history.push({
                pathname: routes.guestUserLogin.url,
                state: {
                  fromPage: "cart",
                  email,
                },
              });
            } else {
              const cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
              if (cart?.length > 0) {
                const obj = cart && cart?.map((c) => {
                  const {
                    selectedServices = {},
                    selectedLocation = {},
                    selectedAddOns = {},
                    selectedDate = "",
                    dogSizes = {},
                    selectedProvider: cartSelectedProvider = {},
                  } = c;
                  let caluculateTotal = 0;
                  selectedAddOns.map((x) => {
                    caluculateTotal += Number(x.price || 0);
                    return x;
                  });
                  return {
                    service_title: selectedServices.serviceName,
                    service_category: selectedServices.categoryName,
                    total: Number(caluculateTotal) + Number(dogSizes.price),
                    service: {
                      name: selectedServices.serviceName,
                      type: "service",
                      is_full_day_service: selectedServices?.service?.is_full_day_service === 1,
                      bnr: selectedServices.banner || icons.PawIcon,
                      tagIcon: icons.TagIcon,
                      ...selectedServices,
                    },
                    service_location: {
                      title: selectedLocation.organisationName,
                      bnr: selectedLocation.profilePic || icons.Avatar,
                      area: selectedLocation.address,
                      locationIcon: icons.ServiceLocation,
                      location: selectedLocation.location || {},
                      ...selectedLocation,
                    },
                    service_detail: {
                      title: dogSizes.dogName,
                      size: dogSizes.dogWeight,
                      time: mapDogSizeToDuration(dogSizes.dogWeight, selectedServices),
                      tagRate: mapDogSizeToPrice(dogSizes.dogWeight, selectedServices),
                      dogSizes: icons.PawIcon,
                      iconOne: icons.ClockIcon,
                      iconTwo: icons.TagIcon,
                      age: mapSizeToDog(dogSizes.dogWeight),
                      ...dogSizes,
                    },
                    service_provider: {
                      provider: cartSelectedProvider.providerName,
                      info: Constants.language.common_service_provider,
                      face: cartSelectedProvider.providerFace || icons.Avatar,
                      location: icons.UserSettingsIcon,
                      ...cartSelectedProvider,
                    },
                    service_schedule: {
                      scheduled_on: selectedDate,
                      scheduled_at: cartSelectedProvider.serviceTime,
                    },
                    additional_service: selectedAddOns,
                  };
                });
                dispatch(payAction.loadCartContent(obj));
              }
              setDisableBtn(true);
              checkOrderPrice()
            }
          } else {
            handleModalClick();
          }
        },
        (error) => {
          setDisableBtn(false);
          const { response: { data: responsedata = "" } = {} } = error || {};
          const { customer_register: customerRegistered = false, status: customerstatus = "" } = responsedata;
          // stop user only user is banned
          if (customerstatus === "Customer Banned") {
            handleModalClick();
          } else if (customerRegistered) {
            history.push({
              pathname: routes.guestUserLogin.url,
              state: {
                fromPage: "cart",
                email,
                firstName,
                lastName,
                telephone,
                create_user
              },
            });
          }
        },
      );
    }
  };
  if (isLoggedIn() && !isCustomer()) {
    const loggedInUserData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    return <Redirect to={redirectToPage(loggedInUserData)} />;
  }
  return (
    <Div className="container" px={2}>
      <HeaderSection onPush={handlePush} />

      <Div
        className="container"
        px={0}
        pb={43}
        mb={[40, 40, 50]}
        background={Theme.colors.text.white}
        borderRadius={Theme.radii.medium}
        boxShadow={Theme.shadows.light}
      >
        <BoxDivision
          boxShadow={Theme.shadows.light}
          type="chooseServices"
          position="relative"
          justifyContent="space-between"
          mb={2}
          height={70}
        >
          <MDLabel fontSize={Theme.fontSizes.xl} ml={3}>
            <FormattedMessage id="your_services" defaultMessage="Your services" />
          </MDLabel>
        </BoxDivision>

        {cartData?.length > 0 && cartData?.map((item, index) => (
          <Span key={item?.id}>
            <HeadAccordion
              item={item}
              index={index}
              deleteIcon={icons.PrimaryDeleteIcon}
              toggleHandler={toggleHandler}
              deleteHandler={deleteHandler}
              activeIndex={activeIndex}
              arrowUpIcon={icons.UpArrow}
              arrowDownIcon={icons.ArrowDown}
              isUserLogged={isUserLogged}
            />
            <BodyAccordion
              item={item}
              index={index}
              activeIndex={activeIndex}
            />
          </Span>
        ))}

        <BottomSection
          totalRate={totalRate}
          totalService={totalService}
          totalcharge={totalcharge}
          onCheckout={handleVerifyCustomer}
          onPush={handlePush}
          isUserLogged={isUserLogged}
          disableBtn={rawHTML ? true : disableBtn}
          totalNoOfAddOns={totalNoOfAddOns}
        />
      </Div>
      <Div id={rawHTML ? "my-checkout-container" : ""} />

      {duplicateCartItem && (
        <Modal>
          <Card
            header={<FormattedMessage id="header_error" defaultMessage="Error" />}
            message={<FormattedMessage id="duplicate_cart_item" defaultMessage="Duplicate cart Items" />}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleModalClose}
          />
        </Modal>
      )}
      {bannedPopup
        && <BannedModal onClickModal={handleModalClick} onPush={handlePush} />}
      {availabilityErrorPopup
        && <SelectAnotherAvailabilityModal onClickModal={handleAvailabilityModalClick} handleClose={handleAvailabilityModalClick} />}
      {emailErrorPopup
        && <SelectAnotherAvailabilityModal showEmailErrorPopup={emailErrorPopup} onClickModal={handleEmailErrorModalClick} handleClose={handleEmailErrorModalClick}/>}
    </Div>
  );
};

export default memo(Cart);
