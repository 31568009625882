/* eslint-disable no-restricted-globals */
import React from "react";
import PropTypes from "prop-types";
import { CheckBox as EmptyBox } from "./Styles";
import { Div, MDLabel, Span } from "../../styles/Styles";
import { TickIcon, EmptyIcon, TickMark } from "../../styles/Icons";
import Colors from "../../styles/Colors";

const CheckBox = ({
  isSelected, onClick, label, className, primary, disabled, size, formikValues = {}, name,
}) => {
  const { errors = {}, touched = {} } = formikValues;

  return (
    <>
      <Div onClick={!disabled && onClick} display="flex" cursor="pointer" width="fit-content" className={className} alignSelf="center">
        <Span alignSelf="start" display="flex" disable={disabled}>
          {primary && (
            isSelected ? (
              <TickIcon />
            ) : (
              <EmptyIcon />
            )
          )}
          {!primary && <EmptyBox>{isSelected && <TickMark className="align-self-center" />}</EmptyBox>}
        </Span>
        <MDLabel pl={2} wordBreak="break-all" alignSelf="center" cursor="pointer" disable={disabled} fontSize={size} textAlign="left">
          {label}
        </MDLabel>
      </Div>
      {errors[name] && touched[name] && (
        <Div display="flex" className={className}>
          <MDLabel justifyContent="center" color={Colors.Red} pt={1}>
            {errors[name]}
          </MDLabel>
        </Div>
      )}
    </>
  );
};

CheckBox.propTypes = {
  /**
   * Is checkbox checked..
   */
  isSelected: PropTypes.bool,
  /**
   * Label of the checkbox
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Do you wanna add some more styles
   */
  className: PropTypes.node,
  /**
   * Which colored check box u need?
   */
  primary: PropTypes.bool,
  /**
   * Is disabled?
   */
  disabled: PropTypes.bool,
  /**
   * Size of label text
   */
  size: PropTypes.string,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  /**
   * Name of the input
   */
  name: PropTypes.string,
};
CheckBox.defaultProps = {
  isSelected: false,
  label: null,
  onClick: undefined,
  className: null,
  primary: false,
  disabled: false,
  size: null,
  formikValues: {},
  name: null,
};

export default CheckBox;
