import { createSlice } from "@reduxjs/toolkit";
import { fetchOrganizations, fetchSubscriptionServices, SubscriptionBookingFlowAction } from "../actions/SubscriptionBookingFlowAction";

export const initialState = {
  step: 1,
  subscriptionPackages: [],
  selectedSubscriptionId: "",
  selectedSubscription: "",
  organizations: [],
  selectedServiceLocation:{},
  info: {},
  selectedDate:""
};

export const SubscriptionBookingFlowReducer = createSlice({
  name: "SubscriptionBookingFlowReducer",
  initialState: initialState,
  reducers: SubscriptionBookingFlowAction,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchSubscriptionServices.fulfilled, (state, action) => {
      state.subscriptionPackages = action.payload;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload;
    });
  },
});

export const SubscriptionBookingFlowActions = SubscriptionBookingFlowReducer.actions;
export default SubscriptionBookingFlowReducer.reducer;
