import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "../../components/Containers/PortalContainer";
import CreateUserForm from "./Components/CreateUserForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import Tabs from "../../components/Tabs/Tabs";
import AssignmentsTab from "./Components/AssignmentsTab";
import UserService from "../../services/UserService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const { language, adminRoutes, routes } = Constants;
class EditUser extends Component {
  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      disableData: false,
      tabs: [language.common_details, language.common_assignments],
      selectedTab: language.common_details,
      data: {},
      userType: "",
      userName: "",
      loading: false,
      isDeleteModalOpen: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
    };
  }

  componentDidMount() {
    const { path } = this.props;
    this.setState({ selectedTab: path === routes.assignmentsOfUser.url ? language.common_assignments : language.common_details })
    this.fetchUserDetails();
  }

  componentDidUpdate=(prevProps)=>{
    const { path } = this.props;
    if(prevProps.path !== path){
      this.setState({ selectedTab: path === routes.assignmentsOfUser.url ? language.common_assignments : language.common_details })
    }
  }

  handleSubmitData= async (isSaved = false) => {
    if (isSaved) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_user_updated,
      }, this.fetchUserDetails);
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  fetchUserDetails = async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id } = params;
    const { userDetails = {} } = this.props;
    const userData = userDetails.permissions
      ? userDetails : localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { permissions = [] } = userData;

    try {
      const response = await this.userService.getUserDetails(id);
      if (response) {
        const { data: { role = "", fullName = "" } = "", userAccess = [] } = response;
        const orgIds = userAccess.map((o) => o.organisation_id);
        let userPermits = [];
        orgIds.map((obj)=>{
          const permit = permissions.find((o)=> o.organization_id === obj);
          userPermits.push(permit?.is_users_editable);
        })
        window.scrollTo(0,0);
        this.setState({
          disableData: !userPermits.includes(Constants.WRITE),
          data: response, userType: role, userName: fullName, loading: false,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleCancelChanges=() => {
    this.fetchUserDetails();
  }

  handleDeleteUser = async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id } = params;
    try {
      const response = await this.userService.deleteUser(id);
      if (response) {
        this.setState({
          isDeleteModalOpen: false,
          alertMessage: language.user_deleted_alert_message,
          changesSaved: true,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  };

  /**
   * Opens a delete confirmation popup
   */
  handleDeleteModal =() => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({
      changesSaved: false,
      isDeleteModalOpen: false,
      alertMessage: "",
      alertHeader: "",
    });
  }

  handleLoader=() => () => {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  handleSwitchBetweenTabs = (data) => {
    const { history, match: { params = {} } } = this.props;
    const { id = "" } = params;
    let switchPath =  `/admin/users/${id}/edit-user`;
    if(data === language.common_assignments) {
      switchPath = `/admin/users/${id}/edit-user/assignments`;
    }

    history.push(switchPath);
  }

  handleTabs = (data) => {
    this.setState({ selectedTab: data });
    this.handleSwitchBetweenTabs(data);
  };

  handleBackToUsers = () => {
    const { alertMessage } = this.state;
    if (alertMessage === language.user_deleted_alert_message) {
      const { history } = this.props;
      history.push(Constants.routes.usersList.url);
    } else {
      this.handleCancel();
    }
  };

  handleRedirectToAssignments = (item) => () => {
    const { history } = this.props;
    history.push({
      pathname:`${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${item.id}/${adminRoutes.editAssignment}`,
    });
  };

  handleRedirectToUsers = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      tabs, selectedTab, data, userType, userName, loading, disableData,
      isDeleteModalOpen,
      changesSaved, alertMessage, alertHeader,
    } = this.state;
    const {
      match: { params = {} },
    } = this.props;
    const { id } = params;
    const { icons } = Constants;
    const { openOrders = 0 } = data;
    return (
      <Container>
        <FormWrapper className="w-100">
          <Div display="flex">
            <MDLabel cursor="pointer" onClick={this.handleRedirectToUsers}>
              <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
              <FormattedMessage id="common_back" defaultMessage="Back" />
            </MDLabel>
          </Div>
          {loading && <FormWrapper><FormLoader /></FormWrapper>}
          {!loading && (
            <>
              <Header className="py-2">{userName}</Header>
              {userType !== Constants.role.User ? (
                <>
                  <Div className="my-4">
                    <Tabs
                      tabs={tabs}
                      selectedTab={selectedTab}
                      onClick={this.handleTabs}
                      openOrders={openOrders}
                    />
                  </Div>
                  {selectedTab === tabs[1] && (
                    <>
                      <AssignmentsTab userId={id} redirectTo={this.handleRedirectToAssignments} />
                    </>
                  )}
                  {selectedTab === tabs[0] && (
                    <>
                      <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
                        <FormattedMessage id="user_edit_user_description" />
                      </MDLabel>
                      <CreateUserForm
                        data={data}
                        deleteUser={this.handleDeleteModal}
                        submitData={this.handleSubmitData}
                        handleBackToUsers={this.handleBackToUsers}
                        edit
                        userId={id}
                        disableData={disableData}
                        onCancel={this.handleCancelChanges}
                      />
                    </>
                  )}
                </>
              ) : (
                <FormWrapper>
                  <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
                    <FormattedMessage id="user_edit_user_description" />
                  </MDLabel>
                  <CreateUserForm
                    edit
                    userId={id}
                    handleBackToUsers={this.handleBackToUsers}
                    submitData={this.handleSubmitData}
                    handleLoader={this.handleLoader}
                    data={data}
                    disableData={disableData}
                    deleteUser={this.handleDeleteUser}
                  />
                </FormWrapper>
              )}
            </>
          )}
        </FormWrapper>
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="user_delete_user" defaultMessage="Delete user" />}
              message={<FormattedMessage id="user_deleted_confirmation_message" defaultMessage="Are you sure you want to delete this user?" />}
              buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteUser}
              onCancel={this.handleCancel}
              onLoader={loading}
            />
          </Modal>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToUsers}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
EditUser.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
  location: PropTypes.node,
  path: PropTypes.string,
};
EditUser.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
  location: undefined,
  path: ""
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

export default compose(
  connect(mapStateToProps),
)(EditUser);
