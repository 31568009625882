import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  ListCard, ListCardHeader, ListRow, IsDesktop, IsMobile, Content,
} from "./Styles";
import {
  Div, MDImage, MDLabel, Span,
} from "../../styles/Styles";
import Button from "../Button/Button";
// import { EditIcon, DeleteIcon } from "../../styles/Icons";
import Constants from "../../shared/Constants";
import Colors from "../../styles/Colors";
import ColumnItem from "./ColumnItem";
import { Card } from "../../styles/Styles";

const { sorting: { type: sortType }, icons, listItemTypes } = Constants;

const ListScreen = ({
  header,
  value,
  data,
  editAction,
  deleteAction,
  moreButton,
  // onEdit,
  // onDelete,
  onClick,
  handleSorting,
  dataNotFoundMessage,
  sortedBy,
  sortAscending,
  redirectTo,
  isOrders,
  isCustomers,
  redirectToOrg,
}) => {
  // const handleEditData = (item) => (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (onEdit) {
  //     onEdit(item);
  //   }
  // };
  // const handleDeleteData = (item) => (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (onDelete) {
  //     onDelete(item);
  //   }
  // };
  const handleRedirectToDetailsPage = (item) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (redirectTo) {
      redirectTo(item);
    }
  };
  const SortIcon = (sortValue, itemValue) => {
    if (sortValue === sortType.ALPHABETS) {
      if (sortedBy === itemValue && sortAscending) {
        return icons.AZSortIcon;
      }
      if (sortedBy === itemValue && !sortAscending) {
        return icons.ZASortIcon;
      }
      return icons.AlphabetSort;
    }
    if (sortValue === sortType.ARROW) {
      if (sortedBy === itemValue && sortAscending) {
        return icons.SortDownArrow;
      }
      if (sortedBy === itemValue && !sortAscending) {
        return icons.SortUpArrow;
      }
      return icons.SortIcon;
    }
    return icons.SortIcon;
  };
  return (
    <ListCard m={0}>
      <IsDesktop>
        <ListCardHeader>
          <Div className="row m-0 w-100" py={2} px={3}>
            {header.map((headItem) => (
              <Div
                display="flex"
                className={headItem.className}
                onClick={headItem.icon ? handleSorting(headItem.key) : () => {}}
                key={headItem}
              >
                <Span
                  mr={1}
                  color={sortedBy === headItem.key ? Colors.Primary : Colors.DarkGrey}
                  useCursorPointer={headItem.icon}
                >
                  {headItem.value}
                </Span>
                {headItem.icon && (
                  <Span>
                    <MDImage src={SortIcon(headItem.icon, headItem.key)} alt="icon" useCursorPointer />
                  </Span>
                )}
              </Div>
            ))}
            <Div className="col-auto" width={(editAction || deleteAction) ? 180 : 95} />
            {/* {(editAction || deleteAction) && (
              <Div className="col-auto text-center" width="120px">
                <FormattedMessage id="list_header_actions" />
              </Div>
            )}  */}
          </Div>
        </ListCardHeader>
        {data.length > 0 && data.map((obj) => (
          <ListRow className="row m-0" py={2} px={3} onClick={onClick(obj)} key={obj}>
            {value.map((item, index) => {
              const {
                type = "", key = "", className = "", group = false, isOrg = false,
              } = item;
              if (index === value.length - 1) {
                return (
                  <>
                    <ColumnItem
                      type={type}
                      itemData={type === listItemTypes.user ? obj : obj[key]}
                      className={className}
                      group={group}
                      isMobile={false}
                      isCustomers={isCustomers}
                      isOrders={isOrders}
                      redirectTo={handleRedirectToDetailsPage}
                      isOrg={isOrg}
                      redirectToOrg={redirectToOrg}
                    />
                    <Div className="col-auto" width={(editAction || deleteAction) ? 180 : 95}>
                      {moreButton && (
                        <Content>
                          <Button width={(editAction || deleteAction) ? 150 : 65} label={<FormattedMessage id="common_more" defaultMessage="More" />} height="35px" padding="0px !important" />
                        </Content>
                      )}
                    </Div>
                    {/* {(editAction || deleteAction) && (
                      <Div
                        className="col-auto"
                        justifyContent="space-between"
                        display="flex"
                        width={120}
                      >
                        {editAction && (
                          <Content>
                            <EditIcon onClick={handleEditData(obj)} role="button" className="align-self-center" />
                          </Content>
                        )}
                        {deleteAction && (
                          <Content>
                            <DeleteIcon onClick={handleDeleteData(obj)} role="button" className="align-self-center" />
                          </Content>
                        )}
                      </Div>
                    )} */}
                  </>
                );
              }
              return (
                <ColumnItem
                  type={type}
                  itemData={type === listItemTypes.user ? obj : obj[key]}
                  className={className}
                  group={group}
                  isMobile={false}
                  isCustomers={isCustomers}
                  isOrders={isOrders}
                  redirectTo={handleRedirectToDetailsPage}
                  isOrg={isOrg}
                  redirectToOrg={redirectToOrg}
                />
              );
            })}
          </ListRow>
        ))}
        {data.length === 0 && (
          <Div className="d-flex justify-content-center p-4">
            <MDLabel>{dataNotFoundMessage || "-"}</MDLabel>
          </Div>
        )}
      </IsDesktop>
      <IsMobile>
        {data.length === 0 && (
          <Div className="d-flex justify-content-center p-4">
            <MDLabel>{dataNotFoundMessage || "-"}</MDLabel>
          </Div>
        )}
        {data.length > 0 && data.map((obj,i) => (
          <Card key={i} width="100%" className="mb-3">
            {value.map((item, index) => {
              const {
                type = "", key = "", group = false, isDesktop = false, isOrg = false,
              } = item;
              if (index === 0) {
                return (
                  <>
                    <ListCardHeader display="flex" justifyContent="space-between" onClick={onClick(obj)}>
                      <ColumnItem
                        type={listItemTypes.user}
                        itemData={obj}
                        className="col"
                        group={group}
                        isMobile
                        isCustomers={isCustomers}
                        isOrders={isOrders}
                        redirectTo={handleRedirectToDetailsPage}
                      />
                      <Div className="col-auto" width="95px" alignSelf="center">
                        {moreButton && (
                          <Button width="65px" label={<FormattedMessage id="common_more" defaultMessage="More" />} height="35px" padding="0px !important"/>
                        )}
                      </Div>
                    </ListCardHeader>
                    {!isDesktop && (
                      <Div display="flex" justifyContent="space-between" className="py-2">
                        <Div className={header[index].className}>{header[index].value}</Div>
                        <ColumnItem
                          type={type}
                          itemData={type === listItemTypes.user ? obj : obj[key]}
                          className="col justify-content-end mr-3 px-0"
                          group={group}
                          isMobile
                          isCustomers={isCustomers}
                          isOrders={isOrders}
                          redirectTo={handleRedirectToDetailsPage}
                          isOrg={isOrg}
                          redirectToOrg={redirectToOrg}
                        />
                      </Div>
                    )}
                  </>
                );
              }
              return (
                <>
                  {!isDesktop && (
                    <Div display="flex" justifyContent="space-between" py={["5px", "10px"]}>
                      <Div className={header[index].className}>{header[index].value}</Div>

                      <ColumnItem
                        type={type}
                        itemData={type === listItemTypes.user ? obj : obj[key]}
                        className="col justify-content-end mr-3 px-0"
                        group={group}
                        isMobile
                        isCustomers={isCustomers}
                        isOrders={isOrders}
                        redirectTo={handleRedirectToDetailsPage}
                        isOrg={isOrg}
                        redirectToOrg={redirectToOrg}
                      />
                    </Div>
                  )}
                </>
              );
            })}
          </Card>
        ))}
      </IsMobile>
    </ListCard>
  );
};
ListScreen.propTypes = {
  /**
   * Consists of key , type, group, className
   */
  value: PropTypes.string,
  /**
   * Data that need to be displayed in the list
   */
  data: PropTypes.string,
  /**
   * Do you need more button...
   */
  moreButton: PropTypes.bool,
  /**
   * Do you need edit action..
   */
  editAction: PropTypes.bool,
  /**
   * Do you need delete acton
   */
  deleteAction: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Delete handler
   */
  onDelete: PropTypes.func,
  /**
   * Edit handler
   */
  onEdit: PropTypes.func,
  /**
   * Header values
   */
  header: PropTypes.string,
  /**
   * Sort the column
   */
  handleSorting: PropTypes.func,
  /**
   * Data not found message
   */
  dataNotFoundMessage: PropTypes.string,
  /**
   * SortedBy
   */
  sortedBy: PropTypes.string,
  /**
   * Type of sorting
   */
  sortAscending: PropTypes.bool,
  /**
   * Redirect To
   */
  redirectTo: PropTypes.func,
  /**
   * Is orders list screen?
   */
  isOrders: PropTypes.bool,
  /**
   * Is customers list screen?
   */
  isCustomers: PropTypes.bool,
  redirectToOrg: PropTypes.func,
};
ListScreen.defaultProps = {
  moreButton: false,
  editAction: false,
  deleteAction: false,
  value: [],
  data: [],
  header: [],
  onClick: undefined,
  onDelete: undefined,
  onEdit: undefined,
  handleSorting: undefined,
  dataNotFoundMessage: "",
  sortedBy: "",
  sortAscending: false,
  redirectTo: undefined,
  isOrders: false,
  isCustomers: false,
  redirectToOrg: () => {},
};
export default ListScreen;
