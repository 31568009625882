import React, { useState, memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Constants from "../../../../shared/Constants";
import { LogOutIcon } from "../../../../styles/Icons";
import {
  IsDesktop,
  IsMobile, MDImage, MDLabel, Span, Ul, AvtarIcon, Div,
} from "../../../../styles/Styles";
import {
  BoxDivision, Division, LinkItem, ListItemBox, MDImg, UL,
} from "../../BookingFlowStyles/BookingFlowStyles";
import Theme from "../../../../styles/Theme";
import Colors from "../../../../styles/Colors";
import Button from "../../../../components/Button/Button";
import Avtar from "../../../../assets/Icons/Avtar.svg";
import { loggedInUser } from "../../../../store/actions/AppAction";

const { icons, images, language } = Constants;
const BookingFlowHeader = () => {
  const [hide, setHide] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const openHandler = () => {
    setHide(!hide);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AppReducer.userDetails);
  const paymentDone = useSelector((state) => state.PaymentReducer.paymentDone);
  let userDetails = {};
  if (localStorage.userData) {
    userDetails = data.id ? data : JSON.parse(localStorage.userData);
  }
  const { customer: { name: userName = "" } = {} } = userDetails || {};
  const { profile_pic: profilePic = {} } = userDetails.customer || {};
  const { url: userAvtar = "" } = profilePic || {};
  const isUserLogged = useSelector((state) => state.AppReducer.isUserLogged) || localStorage.getItem("token");
  const handleLogout = (url) => () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(loggedInUser({}));
    history.push(url);
  };
  const handlePush = (url) => () => {
    history.push(url);
  };

  const handleLoginClick = () => {
    history.push(Constants.routes.customerLogin.url);
  };

  const cartItems = useSelector(
    (state) => state.BookingFlowServicesReducer.cartCount,
  );

  useEffect(async () => {
    const guestCart = sessionStorage.getItem(Constants.storage.cart);
    const cart = guestCart && JSON.parse(guestCart);
    setCartItemCount(cart ? cart.length : 0);
  }, []);

  useEffect(() => {
    if (!isUserLogged && paymentDone) {
      setCartItemCount(0);
    }
  }, [paymentDone, isUserLogged]);

  useEffect(() => {
    setCartItemCount(cartItems);
  }, [cartItems]);

  return (
    <Division type="booking_flow_header" bg="white" minHeight="70px">
      <Division type="head_relative" className="container">
        <BoxDivision type="set_header">
          <Division className="resLogo" alignSelf="center">
            <LinkItem href="https://mydogcare.com/">
              <MDImg type="logo_link" src={images.Logo} alt="logo" />
            </LinkItem>
          </Division>
          <Division alignSelf="center">
            <Ul pl={0} mb={0} display="flex" height="70px">
              <ListItemBox type="list_header" mr={4} alignSelf="center">
                <IsDesktop>
                  {(userDetails.id || isUserLogged) ? (
                    <Division display="flex">
                      <AvtarIcon
                        src={userAvtar || Avtar}
                        alt="icon"
                        isMyAccount
                        onClick={handlePush(Constants.routes.myProfile.url)}
                      />
                      <MDLabel
                        ml={2}
                        display="flex"
                        flexDirection="column"
                        useCursorPointer
                      >
                        <Span textAlign="left" fontSize="14px" onClick={handlePush(Constants.routes.myProfile.url)}>
                          {userName || language.label_profile}
                        </Span>
                        <Span
                          onClick={handleLogout(Constants.routes.customerLogin.url)}
                          useCursorPointer
                        >
                          <LogOutIcon />
                          <Span px={1} display="initial" fontSize="12px" cursor="pointer">
                            <FormattedMessage id="common_logout" />
                          </Span>
                        </Span>
                      </MDLabel>
                    </Division>
                  ) : (
                    <Link to="/login" className="text-decoration-none">
                      <Division display="flex" alignSelf="center">
                        <MDImage src={icons.PawIcon} alt="icon" />
                        <MDLabel
                          useCursorPointer
                          fontSize={Theme.fontSizes.xs}
                          pl={1}
                          color={Colors.Black}
                        >
                          <FormattedMessage id="common_login" defaultMessage="Login" />
                        </MDLabel>
                      </Division>
                    </Link>
                  )}
                </IsDesktop>
              </ListItemBox>
              <ListItemBox
                type="list_header"
                mr={4}
                alignSelf="center"
                onClick={openHandler}
                onMouseEnter={() => setHide(true)}
              >
                <LinkItem className="menus" type="link_header" display="flex" alignSelf="center">
                  <MDImage src={icons.menuBars} alt="icon" height="21px" width="16px" />
                  <MDLabel fontSize={Theme.fontSizes.xs} pl={1}>
                    <FormattedMessage id="common_menu" defaultMessage="Menu" />
                  </MDLabel>
                </LinkItem>
                <BoxDivision type="menu_container" className={`${hide ? "menushow" : ""}`}>
                  <IsMobile>
                    <BoxDivision type="close_outer">
                      <MDLabel>
                        <FormattedMessage id="common_menu" defaultMessage="Menu" />
                      </MDLabel>
                      <Button className="closeButton" label="X" secondary onClick={openHandler} />
                    </BoxDivision>
                  </IsMobile>
                  <UL px={0} mb={0} display="flex" type="wrapper" onMouseLeave={() => setHide(false)}>
                    <ListItemBox mr={3} type="hover_menu">
                      <LinkItem py={3} href="https://mydogcare.com/om-oss/" type="header_links">
                        <MDLabel>
                          <FormattedMessage id="about_us" defaultMessage="About us" />
                        </MDLabel>
                      </LinkItem>
                    </ListItemBox>
                    <ListItemBox mr={3} type="hover_menu">
                      <LinkItem py={3} href="https://mydogcare.com/tjanster/" type="header_links">
                        <MDLabel>
                          <FormattedMessage id="our_services" defaultMessage="OurServices" />
                        </MDLabel>
                      </LinkItem>
                    </ListItemBox>
                    <ListItemBox mr={3} type="hover_menu">
                      <LinkItem py={3} href="https://mydogcare.com/kontakt/" type="header_links">
                        <MDLabel>
                          <FormattedMessage id="apply_daycare" defaultMessage="ApplyforDaycare" />
                        </MDLabel>
                      </LinkItem>
                    </ListItemBox>
                    <ListItemBox mr={3} type="hover_menu">
                      <LinkItem py={3} href="https://mydogcare.com/kontakt/" type="header_links">
                        <MDLabel>
                          <FormattedMessage id="contact_us" defaultMessage="Contact Us" />
                        </MDLabel>
                      </LinkItem>
                    </ListItemBox>
                    <ListItemBox type="hover_menu">
                      <LinkItem py={3} href="https://mydogcare.com/" type="header_links">
                        <MDLabel>
                          <FormattedMessage id="blog" defaultMessage="Blog" />
                        </MDLabel>
                      </LinkItem>
                    </ListItemBox>
                  </UL>
                  <IsMobile>
                    <Division type="is_mobile_show">
                      <Button label={<FormattedMessage id="common_login" defaultMessage="Login" />} secondary startIcon={icons.PawIcon} onClick={handleLoginClick} />
                    </Division>
                  </IsMobile>
                </BoxDivision>
              </ListItemBox>
              <ListItemBox bg="rgb(253,194,130,0.2)" type="list_header" mr={0} px={1} alignItems="center">

                <LinkItem
                  type="link_header"
                  color={Colors.Black}
                  onClick={handlePush(Constants.routes.cart.url)}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  {cartItemCount > 0 ? (
                    <Div
                      bg="red"
                      width={15}
                      height={15}
                      position="relative"
                      top="8px"
                      left="10px"
                      color={Colors.White}
                      borderRadius="50%"
                      display="flex"
                      justifyContent="center"
                      fontSize="10px"
                      fontWeight="600"
                    >
                      {cartItemCount}

                    </Div>
                  ) : ""}
                  <MDImage src={icons.Cart} alt="icon" height={20} width={22} />
                  <MDLabel fontSize={Theme.fontSizes.xs} pl={1} useCursorPointer>
                    <FormattedMessage id="title_cart" defaultMessage="Cart" />
                  </MDLabel>
                </LinkItem>
              </ListItemBox>
            </Ul>
          </Division>
        </BoxDivision>
      </Division>
    </Division>
  );
};

export default memo(BookingFlowHeader);
