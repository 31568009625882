import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Div, MDLabel, CardHeader,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import CustomDatePicker from "../Input/CustomDatePicker";
import Button from "../Button/Button";
import CheckBox from "../Input/CheckBox";
import { formatDate } from "../../shared/utils";
import Constants from "../../shared/Constants";
import CircleTickMark from "../../assets/Icons/CircleTickMark.svg";

const { language } = Constants;

export default function DaysModel({
  onClose, renderAvailability, availabilityab, setAvailability,
}) {
  const handleDateChange = (name, val) => {
    const formattedDate = formatDate(val);
    setAvailability({
      ...availabilityab,
      [name]: formattedDate,
    });
  };
  const [isDay] = useState([
    {
      key: language.label_monday,
      value: "Monday",
    },
    {
      key: language.label_tuesday,
      value: "Tuesday",
    },
    {
      key: language.label_wednesday,
      value: "Wednesday",
    },
    {
      key: language.label_thursday,
      value: "Thursday",
    },
    {
      key: language.label_friday,
      value: "Friday",
    },
    {
      key: language.label_saturday,
      value: "Saturday",
    },
    {
      key: language.label_sunday,
      value: "Sunday",
    },
  ]);
  const [isChecked, setIsChecked] = useState(
    [false, false, false, false, false, false, false],
  );
  const handleSubmit = useCallback(() => {
    renderAvailability();
    onClose();
  });
  const selectionMap = (value) => {
    setAvailability({
      ...availabilityab,
      daysArray: value,
      key: "custom-range",
    });
  };
  const handleSelect = (value, key) => {
    const updatedCheckedState = isChecked.map((item, index) => (
      index === key ? !item ? value.value : false : item
    ));
    const filterArray = updatedCheckedState.filter(Boolean);
    selectionMap(filterArray);
    setIsChecked(updatedCheckedState);
  };

  const {
    endDate,
    startDate,
  } = availabilityab;
  return (
    <Modal className="availability-form">
      <Div width="400px">
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="24px">
            <FormattedMessage id="label_repeat_availability" defaultMessage="Repeat Availibility" />
          </MDLabel>
        </CardHeader>
        <Div alignSelf="center" mt={[20, 20, 4]} mb={[0, 0, 3]} justifyContent="center" display="flex">
          <MDLabel fontSize="18px">
            {`${language.label_custom_day}:`}
          </MDLabel>
        </Div>
        <Div display="flex" className="col-12" mx={[26, 26, 40]} p={0}>
          <Div display="flex" className="row col-9" p={0}>
            {isDay.map((value, index) => (
              <Div display="flex" className="col-12" mt={20} key={`value-${[index]}`}>
                <CheckBox
                  isSelected={!!isChecked[index]}
                  onClick={() => handleSelect(value, index)}
                  label={value.key}
                />
              </Div>
            ))}
          </Div>
        </Div>

        <Div display="flex" alignSelf="center" mt={[20, 20, 30]} justifyContent="center">
          <MDLabel fontSize="18px">
            <FormattedMessage id="label_end_date" defaultMessage="End Date" />*
          </MDLabel>
        </Div>
        <Div display="flex" mb={[20, 20, 30]}>
          <CustomDatePicker
            value={endDate}
            disabled={false}
            name="endDate"
            onChange={handleDateChange}
            maxDate=""
            minDate={(startDate && new Date(startDate)) || ""}
            width={335}
          />
        </Div>
        <Div display="flex" className="col-12" mb={[13, 13, 30]}>
          <Div display="flex" className="col-6">
            <Button
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              secondary
              width={160}
              onClick={onClose}
            />
          </Div>
          <Div display="flex" className="col-6">
            <Button
              label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              width={160}
              onClick={handleSubmit}
              startIcon={CircleTickMark}
            />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
DaysModel.propTypes = {
  /**
   * Pass children innto the modal
   */
  onClose: PropTypes.func,
  renderAvailability: PropTypes.func,
  availabilityab: PropTypes.string,
  setAvailability: PropTypes.string,
};
DaysModel.defaultProps = {
  onClose: undefined,
  renderAvailability: undefined,
  availabilityab: {},
  setAvailability: {},
};
