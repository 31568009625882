import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import UserModel from "../models/UserProfile";
import { queryParamParser } from "../shared/utils";

const {
  apiEndPoints: { endPointUrl = {} }, HTTP_STATUS: { OK, CREATED }, requestType: { GET },
  input: { param: { userId } },
} = Constants;

export async function getAllAssignments(id, params) {
  const param = [[userId, id]];
  const queryParams = [...params, ...param];
  const url = `${endPointUrl.ASSIGNMENTS}${queryParamParser(queryParams)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function getUserList(params = []) {
  const url = `${endPointUrl.USERS}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export function getFilterLetters() {}

class UserService extends ApiService {
  async getUserList(queryParam) {
    const response = await this.get(`${endPointUrl.USERS}${this.queryParamString(queryParam)}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getOrganisations() {
    const response = await this.get(`${endPointUrl.PUBLIC}/${endPointUrl.ORGANIZATIONS}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getUserDetails(id) {
    this.profileModel = new UserModel({});
    const response = await this.get(`${endPointUrl.USERS}/${id}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.profileModel.fromUserProfileJson(data.data);
    }
  }

  async addUser(payload) {
    const response = await this.post(`${endPointUrl.USERS}/${endPointUrl.REGISTER}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (status !== CREATED) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateUser(id, payload) {
    const response = await this.put(`${endPointUrl.USERS}/${id}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async deleteUser(id) {
    const response = await
    this.delete(`${endPointUrl.USERS}/${id}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }
}
export default UserService;
