import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import Constants from "../../../../shared/Constants";
import Colors from "../../../../styles/Colors";
import Theme from "../../../../styles/Theme";
import {
  MDImage, MDLabel, Ul,
} from "../../../../styles/Styles";
import {
  BoxDivision, Division, LinkItem, ListItemBox, MDImg, MDLabelBox, UL,
} from "../../BookingFlowStyles/BookingFlowStyles";
import KlarnaLogo from "../../../../assets/Icons/Klarnalogo.svg";

const { images, icons } = Constants;
const BookingFlowFooter = () => (
  <Division bg={Colors.Black} position="absolute" width="100%">
    <Division className="container">
      <BoxDivision type="main_footers">
        <BoxDivision type="booking_footer" pt={3} pb={2}>
          <Division alignSelf="center" mr={3} type="full">
            <MDImg type="footer_avtar" src={images.FooterLogo} alt="logo" />
          </Division>
          <Division py={1} alignSelf="center">
            <UL pl={0} mb={0} display="flex" type="wrapper_footer">
              <ListItemBox alignSelf="center" mr={4} type="full">
                <LinkItem type="foot_links" href="https://mydogcare.com/integritetspolicy/">
                  <MDLabel pl={1}>
                    <FormattedMessage id="privacy_policy" defaultMessage="Privacy Policy" />
                  </MDLabel>
                </LinkItem>
              </ListItemBox>
              <ListItemBox alignSelf="center" mr={4} type="full">
                <LinkItem type="foot_links">
                  <MDLabel pl={1}>
                    <FormattedMessage id="cookie_policy" defaultMessage="Cookie Policy" />
                  </MDLabel>
                </LinkItem>
              </ListItemBox>
              <ListItemBox alignSelf="center" mr={4} type="full">
                <LinkItem type="foot_links" href="https://mydogcare.com/tjanster/">
                  <MDLabel pl={1}>
                    <FormattedMessage id="dog_daycare_application" defaultMessage="Dog Daycare Application" />
                  </MDLabel>
                </LinkItem>
              </ListItemBox>
              <ListItemBox alignSelf="center" type="full">
                <LinkItem type="foot_links" href="https://mydogcare.com/kontakt/">
                  <MDLabel pl={1}>
                    <FormattedMessage id="contact_us" defaultMessage="Contact Us" />
                  </MDLabel>
                </LinkItem>
              </ListItemBox>
            </UL>
          </Division>
        </BoxDivision>
        <Division display="flex" flexDirection={["column", "row"]}>
          <Division width={70} alignSelf="center" mr={[0, 4]} ml={[62, 0]} mb={[1, 0]}>
            <MDImg type="footer_logo" src={KlarnaLogo} alt="icon" height={15} width={70} />
          </Division>
          <Division alignSelf="center" type="move_left">
            <Ul pl={0} mb={0} display="flex">
              <ListItemBox alignSelf="center" mr={2} ml={1}>
                <LinkItem href="https://www.facebook.com/MyDogCareSverige" type="social_icon">
                  <MDImg type="footer_logo" src={icons.Facebook} alt="icon" />
                </LinkItem>
              </ListItemBox>
              <ListItemBox alignSelf="center" mr={2} ml={1}>
                <LinkItem href="https://www.instagram.com/mydogcaresverige/" type="social_icon">
                  <MDImage src={icons.Instagram} alt="icon" />
                </LinkItem>
              </ListItemBox>
              <ListItemBox alignSelf="center" mr={2} ml={1}>
                <LinkItem href="https://www.linkedin.com/company/mydogcaresverige/" type="social_icon">
                  <MDImage src={icons.LinkedIn} alt="icon" />
                </LinkItem>
              </ListItemBox>
            </Ul>
          </Division>
        </Division>
      </BoxDivision>
      <Division type="box_border" py={2}>
        <MDLabelBox type="footer_copyright" color={Colors.White} fontSize={Theme.fontSizes.xs} py={1}>
          <FormattedMessage id="copy_right" defaultMessage="© MyDogCare - part of Pet Services Sweden AB (Org.nr 559288-8928)" />
        </MDLabelBox>
      </Division>
    </Division>
  </Division>
);
export default memo(BookingFlowFooter);
