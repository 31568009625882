import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Div, IsDesktop, MDLabel, P, IsMobile } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import { Division } from "../../Services/Styles";
import Constants from "../../../shared/Constants";
import { BoxDivision } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import Button from "../../../components/Button/Button";
import { isLoggedIn } from "../../../shared/utils";
import queryString from "query-string";

export const SubscriptionOrderSuccess = () => {
  const history = useHistory();
  const { location: { search = "" } = {} } = history || {};
  const params = queryString.parse(search);
  const { isChecked } = params;
  const selectedSubscription = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedSubscription);
  const [subscriptionPackageName, setSubscriptionPackageName] = useState("");

  useEffect(() => {
    if (selectedSubscription && Object.keys(selectedSubscription).length > 0) {
      const name = selectedSubscription.name;
      setSubscriptionPackageName(name);
    }
  }, [selectedSubscription]);

  const handleLogin = () => {
    history.push(Constants.routes.customerLogin.url);
  };

  const handleMyAccounts = () => {
    history.push(Constants.routes.myProfile.url);
  };

  const hadleToServices = () => {
    history.push(Constants.routes.chooseService.url);
  };

  return (
    <Division mt={["30px", "30px", "65px"]} ml={80} mx={[3, 3, 80]}>
      <Box>
        <MDLabel fontSize={Theme.fontSizes.xl} mb={[1, 1, 3]} pb={1}>
          <FormattedMessage id="done" defaultMessage="Done!" />
        </MDLabel>
      </Box>
      <Box>
        <P mt={20} fontSize={Theme.fontSizes.regular} fontWeight={Theme.fontWeights.normal} textAlign="center">
          <FormattedMessage id="subscription_success_start_key" defaultMessage="Your request for Subscription:" />
          {` ${subscriptionPackageName} `}
          {JSON.parse(isChecked) ? (
            <FormattedMessage
              id="subscription_success_text_for_customer_account"
              defaultMessage="is sent! MyDogCare manager will call you soon to discuss details. Soon you will get an e-mail with subscription details. Also you will find this subscription in your Profile."
            />
          ) : (
            <FormattedMessage
              id="subscription_success_end_key"
              defaultMessage="is sent! MyDogCare manager will call you soon to discuss details. Also we have sent you an e-mail with instructions how to activate your account in MyDogCare system."
            />
          )}
        </P>
      </Box>
      <BoxDivision display={["block !important", "flex !important", "flex !important"]}>
        <Box display={["block !important", "flex !important", "flex !important"]} py={4}>
          <IsMobile>
            <Button
              primary
              type="submit"
              label={<FormattedMessage id="to_services" defaultMessage="To Services" />}
              width={["100%", "175px", "175px"]}
              onClick={hadleToServices}
            />
          </IsMobile>
          {!isLoggedIn() && (
            <Div mr={[0, 2, 2]} mt={["20px", 0, 0]}>
              <Button
                secondary
                type="button"
                label={<FormattedMessage id="common_login" defaultMessage="Login" />}
                width={["100%", "175px", "175px"]}
                onClick={handleLogin}
              />
            </Div>
          )}
          {isLoggedIn() && (
            <Div mr={[0, 2, 2]} mt={["20px", 0, 0]}>
              <Button
                secondary
                type="button"
                label={<FormattedMessage id="my_account_user_info" defaultMessage="My account" />}
                width={["100%", "175px", "175px"]}
                mr={3}
                onClick={handleMyAccounts}
              />
            </Div>
          )}
          <IsDesktop>
            <Button
              primary
              type="submit"
              label={<FormattedMessage id="to_services" defaultMessage="To Services" />}
              width={["100%", "175px", "175px"]}
              onClick={hadleToServices}
            />
          </IsDesktop>
        </Box>
      </BoxDivision>
    </Division>
  );
};
