import styled from "styled-components";
import {
  typography, space, color, flexbox, layout, background, position, compose, border, grid,
} from "styled-system";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import { Label } from "../../../components/UploadSection/Styles";
import {
  Span, Box, ListItem, Link, MDImage, Div, MDLabel, ContentLeft, ContentRight, MDInputValue, Ul,
} from "../../../styles/Styles";

export const SpanBox = styled(Span)`
display: ${({ type }) => (type === "step_Number" ? "inline-block" : "")};
text-align: ${({ type }) => (type === "step_Number" ? "center" : "")};
opacity: ${({ type }) => (type === "step_Number" ? "0.5" : "")};
cursor: ${({ type }) => (type === "cursor_pointer" ? "pointer" : "inherit")};
&.active{
  opacity: ${({ type }) => (type === "step_Number" ? "1" : "")};
};
background: ${({ type }) => (type === "booking_date" ? `${Colors.Primary}` : "")};
width: ${({ type }) => (type === "booking_date" ? "100%" : "")};
text-align: ${({ type }) => ((type === "booking_date") || (type === "booking_time") ? "center" : "")};
align-self: ${({ type }) => (type === "booking_date" ? "center" : "")};
border-top-left-radius: ${({ type }) => (type === "booking_date" ? `${Theme.radii.medium}px` : "")};
border-top-right-radius: ${({ type }) => (type === "booking_date" ? `${Theme.radii.medium}px` : "")};
color: ${({ type }) => (type === "booking_date" ? `${Colors.White}` : "")};
font-size: ${({ type }) => (type === "booking_date" ? `${Theme.fontSizes.regular}` : "")};
font-weight: ${({ type }) => ((type === "booking_date") || (type === "booking_time") ? `${Theme.fontWeights.semibold}` : "")};
@media(max-width:570px){
  padding: ${({ type }) => (type === "booking_date" ? "5px 0" : "")};
  padding: ${({ type }) => (type === "booking_time" ? "4px 0" : "")};
}
padding: ${({ type }) => (type === "booking_time" ? "4px 0" : "")};
`;
export const MDLabelBox = styled(MDLabel)`
  font-size: ${({ type }) => (type === "responsive_view" ? "12px" : "")};
  font-family: ${({ type }) => (type === "footer_copyright" ? `${Theme.fonts.regular}` : "")};
  justify-content: ${({ type }) => (type === "footer_copyright" ? "flex-end" : "")};
  word-break: break-word;
 @media (max-width:767px){
  line-height: ${({ type }) => (type === "like_extra_services" ? "27px" : "")};
  justify-content: ${({ type }) => (type === "footer_copyright" ? "flex-start" : "")};
 }
 @media(max-width: 570px) {
     text-align: ${({ type }) => (type === "footer_copyright" ? "left !important" : "")};
     justify-content: ${({ type }) => (type === "content_center" ? "center !important" : "")};
  @media(max-width: 420px) {
    font-size: ${({ type }) => (type === "booking_Service" ? `${Theme.fontSizes.large}` : type === "choose_Text" ? `${Theme.fontSizes.regular}` : "")};
  }
`;
export const UL = styled(Ul)`
  @media(max-width: 767px) {
    flex-wrap: ${({ type }) => ((type === "wrapper") || (type === "wrapper_footer") ? "wrap !important" : "")};
    width: ${({ type }) => (type === "wrapper_footer" ? "100%" : "")};
  }
  @media(max-width: 570px) {
    padding: ${({ type }) => (type === "wrapper" ? "85px 0 0 0" : "")};
  }
`;
export const LinkItem = styled(Link)`
 display: ${({ type }) => (type === "back_To_services" ? "inline-block" : "")};
 display: ${({ type }) => (type === "link_header" ? "flex" : "")};
 color: ${({ type }) => (type === "back_To_services" ? `${Colors.Black}` : "")};
 cursor: ${({ type }) => ((type === "categories") || (type === "social_icon") ? "pointer" : "")};
 position: ${({ type }) => (type === "categories" ? "relative" : "")};
 z-index: ${({ type }) => (type === "categories" ? 6 : "")};
 color: ${({ type }) => (type === "categories" ? `${Colors.Black}` : "")};
 text-decoration: ${({ type }) => (type === "categories" ? "underline" : "")};
 color:${({ type }) => (type === "foot_links" ? `${Colors.White} !important` : "")};
 color:${({ type }) => (type === "header_links" ? `${Colors.Primary} !important` : "")};
 &:hover{
   color:${({ type }) => (type === "foot_links" ? `${Colors.Yellow} !important` : "")};
   color:${({ type }) => (type === "header_links" ? `${Colors.White} !important` : "")};
  }
  min-width: ${({ type }) => (type === "link_header" ? "85px" : "")};
  @media(max-width: 570px) {
   word-break: break-word;
   &.menus {
    justify-content: center !important
   }
}
`;
export const MDImg = styled(MDImage)`
   border-radius: ${({ type }) => (type === "roundImg" ? "10px" : "")};
   width: ${({ type }) => (type === "dog_Paw" ? "35px" : "")};
   cursor: ${({ type }) => (type === "logo_link" ? "pointer" : "inherit")};
   @media(max-width:570px){
    width: ${({ type }) => (type === "footer_avtar" ? "41px" : "")};
   }
   cursor: ${({ type }) => (type === "cursor_pointer" ? "pointer" : "inherit")};
`;
export const ContentLeftDivision = styled(ContentLeft)`
    width: ${({ type }) => (type === "choose_day" ? "40%" : "")};
    width: ${({ type }) => (type === "choose_Places" ? "40%" : "")};
    @media(max-width:767px){
      width: ${({ type }) => ((type === "choose_Places") || (type === "choose_day") ? "100%" : "")};
      margin-bottom: ${({ type }) => (type === "choose_day" ? "40px" : "")};
      padding-left: ${({ type }) => ((type === "choose_Places") || (type === "choose_day") ? "0" : "")};
      & p {
        width: 100%;
      }
    }
    @media(max-width:570px){
      & p {
        text-align:center;
      }
    }
`;
export const ContentRightDivision = styled(ContentRight)`
    width: ${({ type }) => (type === "choose_time" ? "60%" : "")};
    width: ${({ type }) => (type === "marker_Map" ? "45%" : "")};
    ::-webkit-scrollbar {
      width: ${({ type }) => (type === "choose_time" ? "10px" : "")};
      height: ${({ type }) => (type === "choose_time" ? "10px" : "")};
    }
    @media(max-width:767px){
      width: ${({ type }) => ((type === "choose_time") || (type === "marker_Map") ? "100%" : "")};
      height: ${({ type }) => (type === "marker_Map" ? "532px" : "")};
      padding-right: ${({ type }) => ((type === "marker_Map") || (type === "choose_time") ? "0" : "")};
      padding-bottom: ${({ type }) => ((type === "marker_Map") ? "150px" : "")};
    }
`;
export const BoxDivision = styled(Box)`
    ${compose(grid, layout, flexbox, position )};
    justify-content: ${({ type }) => ((type === "place_Content") || (type === "button_outer") ? "flex-start !important" : "")};
    flex-wrap: ${({ type }) => ((type === "place_Content") || (type === "choose_Address") || (type === "wrap_div") ? "wrap" : "")};
    position: ${({ type }) => (type === "choose_Address" ? "relative" : "")};
    justify-content: ${({ type }) => ((type === "accordion") || (type === "choose_Services") ? "space-between !important" : "")};
    justify-content: ${({ type, justifyContent }) => ((type === "choose_Item") || (type === "chooseItem") ? justifyContent || "flex-start !important" : "")};
    border-radius: ${({ mainType }) => (mainType === "active" ? "10px 10px 0 0" : "10px")};
    width: ${({ type }) => ((type === "choose_Item") || (type === "button_outer") ? "100% !important" : "")};
    margin-bottom: ${({ type }) => ((type === "wrap_div") ? "15px" : "")};
    justify-content: ${({ type }) => (type === "menu_container" ? "flex-end !important" : "")};
    position: ${({ type }) => (type === "menu_container" ? "absolute" : "")};
    right: ${({ type }) => (type === "menu_container" ? 0 : "")};
    top: ${({ type }) => (type === "menu_container" ? "80px" : "")};
    display: ${({ type }) => (type === "menu_container" ? "none" : "")};
    z-index: ${({ type }) => (type === "menu_container" ? 1 : "")};
    & button {
      margin: ${({ type }) => (type === "backToNext" ? "0 15px !important" : "")};
    }
    &::after{
    content: ${({ type }) => (type === "choose_Services" ? "''" : "")};
    position: ${({ type }) => (type === "choose_Services" ? "absolute" : "")};
    right: ${({ type }) => (type === "choose_Services" ? "0" : "")};
    background: ${({ type }) => (type === "choose_Services" ? `${Colors.Blue}` : "")};
    opacity: ${({ type }) => (type === "choose_Services" ? "0.2" : "")};
    height: ${({ type }) => (type === "choose_Services" ? "70px" : "")};
    width: ${({ type }) => (type === "choose_Services" ? "260px" : "")};
    z-index: ${({ type }) => (type === "choose_Services" ? 0 : "")};
    }
    width: ${({ msLeft }) => (msLeft ? "50%" : "")};
    margin-right: ${({ msLeft }) => (msLeft ? "0 !important" : "")};
    flex-wrap: ${({ type }) => ((type === "cate_One") ? "wrap" : "")};
    width: ${({ type }) => ((type === "msLeft") || (type === "Right") ? "50%" : "")};
    justify-content: ${({ type }) => ((type === "msLeft") || (type === "Right") ? "flex-start" : "")};
    width: ${({ type }) => (type === "msFull" ? "100%" : "")};
    justify-content: ${({ type }) => (type === "msFull" ? "flex-start" : "")};
    justify-content: ${({ type }) => ((type === "set_header") || (type === "booking_footer") ? "space-between !important" : "")};
    width: ${({ type }) => ((type === "msRight") || (type === "msLeft") ? "50%" : "")};
    width: ${({ type }) => (type === "msFull" ? "100%" : "")};
    justify-content: ${({ type }) => ((type === "msRight") || (type === "msLeft") ? "flex-start" : "")};
    flex-wrap: ${({ type }) => (type === "chooseItem" ? "nowrap !important" : "")};
    width: ${({ msLeft }) => (msLeft ? "50%" : "")};
    margin-right: ${({ msLeft }) => (msLeft ? "0 !important" : "")};
    justify-content: ${({ msLeft }) => (msLeft ? "flex-start" : "")};
    @media (max-width: 767px) {
      flex-wrap:wrap;
      flex-wrap: ${({ type }) => (type === "backToNext" ? "nowrap !important" : "")};
      margin-bottom: ${({ type }) => (type === "extra_kr" ? "15px !important" : "")};
      flex-wrap: ${({ type }) => (type === "extra_services_button" ? "nowrap !important" : "")};
      position: ${({ type }) => (type === "button_outer" ? "absolute" : "")};
      bottom: ${({ type }) => (type === "button_outer" ? "50px" : "")};
      background: ${({ type }) => (type === "menu_container" ? `${Colors.White}` : "")};
      flex-wrap: ${({ type }) => (type === "main_footers" ? "wrap !important" : "")};
    }
    justify-content: ${({ type }) => ((type === "msFull") ? "flex-start" : "")};
    @media (max-width: 575px) {
      flex-wrap: ${({ type }) => (type === "set_header" ? "nowrap !important" : "")};
      &::after{
        width: ${({ type }) => (type === "choose_Services" ? "140px" : "")};
      }
      padding-right: ${({ type }) => (type === "choose_Services" ? "15px" : "")};
      justify-content: ${({ type }) => (type === "place_Content" ? "center !important" : "")};
      flex-wrap: ${({ type }) => (type === "booking_footer" ? "nowrap !important" : "")};
      justify-content: ${({ type }) => (type === "button_outer" ? "center !important" : "")};
    }
    @media(max-width:570px){
      position: ${({ type }) => (type === "menu_container" ? "fixed" : "")};
      top: ${({ type }) => (type === "menu_container" ? 0 : "")};
      height: ${({ type }) => (type === "menu_container" ? "100vh" : "")};
      width: ${({ type }) => (type === "menu_container" ? "200px" : "")};
      z-index: ${({ type }) => (type === "menu_container" ? "9" : "")};
      border-radius:  ${({ type }) => (type === "menu_container" ? 0 : "")};
      position: ${({ type }) => (type === "close_outer" ? "absolute" : "")};
      padding: ${({ type }) => (type === "menu_container" ? "11px" : "")};
      width: ${({ type }) => (type === "close_outer" ? "100%" : "")};
      justify-content: ${({ type }) => (type === "close_outer" ? "space-between !important" : "")};
      right: ${({ type }) => (type === "close_outer" ? 0 : "")};
      padding: ${({ type }) => (type === "close_outer" ? "20px" : "")};
      justify-content: ${({ type }) => (type === "button_outer" ? "center !important" : "")};
    }
    @media (max-width: 375px) {
      justify-content: ${({ type }) => (type === "cate_One" ? "flex-start" : "")};
    }
    flex-wrap: ${({ type }) => (type === "choose_Item" ? "nowrap !important" : "")};
    display: ${({ type }) => (type === "extra_none" ? "none" : "")};
    & .btnActive{
      & button {
        &.active{
          background:${Colors.Primary};
          color: ${Colors.White}
        }
      }
    }
    justify-content: ${({ type }) => (type === "main_footers" ? "space-between !important" : "")};
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
    & .klarna_checkout{
      width: 460px;
      min-height:605px;
      border: none;
      margin-top:60px
    }
`;
export const ListItemBox = styled(ListItem)`
display: ${({ type }) => (type === "list_header" ? "flex" : "")};
&:last-child{
  width: ${({ type }) => (type === "steps_Numbers" ? "auto" : "")};
  &:after{
    display: ${({ type }) => (type === "steps_Numbers" ? "none" : "")};
  }
}
padding: ${({ type }) => (type === "hover_menu" ? "0 8px" : "")};
&:hover {
  background: ${({ type }) => (type === "hover_menu" ? `${Colors.Primary}` : "")};
  color: ${({ type }) => (type === "hover_menu" ? `${Colors.White}` : "")};
}
& .menushow, .menushow:hover {
  display:block !important;
}

&:after{
  content: ${({ type }) => (type === "steps_Numbers" ? "''" : "")};
  position: ${({ type }) => (type === "steps_Numbers" ? "absolute" : "")};
  background-color: ${({ type }) => (type === "steps_Numbers" ? `${Colors.Blue}` : "")};
  width: ${({ type }) => (type === "steps_Numbers" ? "100%" : "")};
  height: ${({ type }) => (type === "steps_Numbers" ? "1px" : "")};
  top: ${({ type }) => (type === "steps_Numbers" ? "50%" : "")};
  transform: ${({ type }) => (type === "steps_Numbers" ? "translateY(-50%)" : "")};
  z-index: ${({ type }) => (type === "steps_Numbers" ? "0" : "")};
  opacity: ${({ type }) => (type === "steps_Numbers" ? "0.3" : "")};
}
&.line{
  &:after{
    opacity: ${({ type }) => (type === "steps_Numbers" ? "1" : "")};
  }
}
@media (max-width: 570px){
  margin-right: ${({ type }) => (type === "full" ? "10px !important" : "")};
  margin-bottom: ${({ type }) => (type === "full" ? "15px !important" : "")};

  &:first-child {
    margin-right: ${({ type }) => (type === "list_header" ? "0px !important" : "")};
  }
  &:nth-child(2) {
    margin-right: ${({ type }) => (type === "list_header" ? "0px !important" : "")};
  }
@media(max-width:570px){
  width: ${({ type }) => (type === "hover_menu" ? "100%" : "")};
  & .hide {
    display: none !important;
  }
}
@media (max-width: 380px){
  margin-right: ${({ type }) => (type === "list_header" ? "15px !important" : "")};
  &:last-child{
    margin-right: ${({ type }) => (type === "list_header" ? "0 !important" : "")};
  }
@media (max-width: 340px){
    margin-right: ${({ type }) => (type === "list_header" ? "8px !important" : "")};
}
`;
export const LabelBox = styled(Label)`
  ${compose(typography, border, space, color, flexbox, layout, background, position)};
  width: ${({ type }) => (type === "wide_input" || type === "light_input" ? "160px !important" : type === "responsivediv" ? "auto" : "255px")};
  height: ${({ type }) => (type === "wide_input" || type === "light_input" ? "50px !important" : "100%")};
  background: ${Colors.White};
  box-shadow: ${Theme.shadows.light};
  border-radius: 15px;
  border-width: ${Theme.borderWidths.regular}px;
  border-color: ${Colors.White};
  border: ${({ type }) => (type === "light_input" ? `3px solid ${Colors.Primary}` : "")};
  display: flex;
  cursor: ${({ type }) => (type === "no_cursor" ? "" : "pointer")};
  justify-content: flex-start;
  padding: ${({ type }) => (type === "responsivediv" ? "0 20px 0 15px" : "")};
  width: ${({ type }) => (type === "date_time" ? "110px !important" : type === "extra" ? "100% !important" : "")};
  flex-wrap: ${({ type }) => (type === "date_time" ? "wrap !important" : "")};
  &:hover {
    border: 3px solid ${Colors.Primary};
    transition: all 0.1s linear;
  }
  @media (max-width: 575px) {
    width: ${({ type }) => (type === "wide_input" || type === "light_input" ? "160px !important" : type === "responsivediv" ? "auto" : "100%")};
  }
`;

export const MDInputValueBox = styled(MDInputValue)`
  font-size: ${({ type }) => (type === "colon_add" ? `${Theme.fontSizes.xl}` : "")};
  font-size: ${({ type }) => (type === "colon_eighteen" ? `${Theme.fontSizes.medium}` : "")};
  color: ${({ type }) => ((type === "colon_add") || (type === "colon_eighteen") ? `${Colors.LightGrey}` : "")};
  @media (max-width: 575px) {
      flex-wrap: ${({ type }) => (type === "services_title" ? "wrap !important" : "")};
      width: ${({ type }) => (type === "services_title" ? "75px !important" : "")};
      justify-content: ${({ type }) => (type === "services_title" ? "flex-end !important" : "")};
  }
`;

export const Division = styled(Div)`
  width: ${({ type }) => (type === "selectSize" ? "160px !important" : "")};
  margin-right: ${({ type }) => (type === "selectSize" ? "0" : "")};
  min-height: ${({ type }) => (type === "main_wrapper" ? "100vh" : "")};
  margin-right: ${({ type }) => (type === "padded_div" ? "30px" : "")};
  margin-bottom: ${({ type }) => (type === "booking_Cards" ? "20px" : "")};
  background: ${({ type }) => (type === "booking_flow_header" ? `${Colors.White}` : "")};
  border-top: ${({ type }) => (type === "box_border" ? "1px solid #ffffff29 !important" : "")};
  position: ${({ type }) => (type === "head_relative" ? "relative" : "")};
  margin-top: ${({ type }) => (type === "is_mobile_show" ? "90px" : "")};
  text-align: ${({ type }) => (type === "is_mobile_show" ? "center" : "")};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
  &#my-checkout-container{
    width: 489px;
    min-height:657px;
    border: none;
    margin:60px auto 0 auto;
  }
  opacity: ${({ offSelect }) => (offSelect === "true" ? 0.5 : "")};
  display: ${({ flexProp }) => (flexProp ? "flex" : "")};
  width: ${({ type }) => (type === "msFull" ? "100%" : "")};
  flex-wrap: ${({ flexProp }) => (flexProp ? "wrap" : "")};
  @media(min-width: 767px){
    width: ${({ type }) => (type === "width_height_map" ? "483px !important" : "")};
    height: ${({ type }) => (type === "width_height_map" ? "400px !important" : "")};
  }
  & .bannedModals {
    & .rounded{
      border-radius: 10px !important;
    }
  }
  display: ${({ flexProp }) => (flexProp ? "flex" : "")};
  flex-wrap: ${({ flexProp }) => (flexProp ? "wrap" : "")};
  @media (max-width: 575px) {
    width: ${({ type }) => (type === "banned_modal" ? "100% !important" : "")};
    margin-right: ${({ type }) => (type === "padded_div" ? 0 : "")};
    margin-left: ${({ type }) => ((type === "box_border") || (type === "move_left") ? "55px" : "")};
    padding-bottom: ${({ type }) => (type === "move_left" ? "10px" : "")};
    & .bannedOuter{
      & .modal-small{
        align-self:center !important;
        align-items: center !important;
      }
    }
  }
  & input:checked ~ label {
    border: ${({ setBorder }) => ((setBorder) ? `3px solid ${Colors.Primary}` : "")};
  }
  &:nth-child(odd){
    margin-right: ${({ type }) => ((type === "select_Place") || (type === "places") ? "30px" : "")};
  }
  &.edit_button {
    position:relative;
  }
  &.edit_button{
    &:after{
      width: 100%;
      height: 100%;
      content: 'Edit';
      position: absolute;
      background: ${Colors.Primary};
      top: 0;
      border-radius: ${Theme.radii.medium}px;
      text-align: center;
      line-height: 67px;
      color: ${Colors.White};
      cursor: pointer;
      display: none;
      opacity: 0.9;
      font-family: ${Theme.fonts.semibold};
    }
    &:hover{
      &::after{
        display:block;
      }
    }
  }
  & .closeButton{
    padding: 1px 5px 0 5px !important;
    border-color: ${Colors.Black};
    color: ${Colors.Black};
    display: none;
  }
  margin-right: ${({ type }) => (type === "main_wrapper" ? "auto !important" : "")};
  @media (max-width: 767px) {
    &.container{
      max-width:100%;
      padding:0 15px;
    }
    margin-bottom: ${({ type }) => ((type === "booking_Cards") || (type === "select_Size") ? "20px" : "")};
    &:nth-child(odd){
      margin-right: ${({ type }) => ((type === "select_Place") || (type === "select_Size") ? "0" : "")};
      margin-left: ${({ type }) => ((type === "places") || (type === "select_Size") ? "0" : "")};
    }
    margin-right: ${({ type }) => (type === "places" ? "0px !important" : "")};
    margin-left: ${({ type }) => (type === "provider_summary" ? "0px !important" : "")};
    margin-right: ${({ type }) => (type === "set_width" ? "0px !important" : "")};
  }

  & .resLogo{
    @media(max-width:570px){
      & img{width:140px}
    }
    @media(max-width:460px){
      padding-left:15px;
    }
  }
  @media (max-width: 575px) {
    padding: ${({ type }) => (type === "head_relative" ? "0 !important" : "")};
    &.container{
      max-width:100%;
      padding: ${({ type }) => (type === "main_Box" ? "0" : "")};
    }
    margin-right: ${({ type }) => ((type === "order_summary") || (type === "choose_summary") ? "0px !important" : "")};
    margin-bottom: ${({ type }) => ((type === "booking_Cards") || (type === "select_Size") ? "35px" : "")};
  }
  @media(max-width: 570px) {
    & .closeButton{
      display:block;
    }
  }
  @media (max-width: 375px) {
    &.container{
      padding:0 10px;
      padding: ${({ type }) => (type === "main_Box" ? "0 8px" : "")};
    }
    &:nth-child(odd){
      margin-right: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
      margin-left: ${({ type }) => (type === "booking_Cards" ? "0" : "")};
    }
    &:nth-child(even){
      margin-right: ${({ type }) => (type === "booking_Cards" ? "0" : "")};
      margin-left: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
    }
  }
  @media (max-width: 371px){
   width: ${({ type }) => (type === "booking_Cards" ? "100%" : "")};
   text-align: ${({ type }) => (type === "booking_Cards" ? "center" : "")};
   padding-top: ${({ type }) => (type === "booking_Cards" ? "10px" : "")};
   &:nth-child(odd){
    margin-right: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
    margin-left: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
  }
  &:nth-child(even){
    margin-right: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
    margin-left: ${({ type }) => (type === "booking_Cards" ? "8px" : "")};
  }
  }
  display: ${({ type }) => (type === "active" ? "none" : "")};
  &.active{
    display:block;
    border-radius: ${({ mainActive }) => (mainActive ? "0 0 10px 10px" : "10px")};
  };
  text-align: ${({ type }) => (type === "order_summery" ? "center" : "")};

  @media(max-width:767px){
    width: ${({ type }) => (type === "set_width" ? "200px !important" : "")};
    margin-bottom: ${({ type }) => (type === "set_width" ? "20px" : "")};
  }
  &:nth-child(3) {
    @media(max-width:767px){
      margin-bottom: ${({ type }) => (type === "set_width" ? "20px" : "")};
    }
  }
  &:last-child {
    margin-right: ${({ type }) => (type === "set_width" ? "0 !important" : "")};
  }


  &.modifyCalendar{
    @media(max-width:575px){
      & .react-calendar{
        width:100%;
      }
    }
    & button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      display: none;
    }
    & button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: none;
    }
    & .react-calendar {
      border:none;
    }
    & button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
      order: 1;
      font-size: 27px;
      color: ${Colors.Green};
    }
    & button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
      color: ${Colors.Grey};
      background-color: transparent;
    }
    & button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
      order: 2;
      font-size: 27px;
      color: ${Colors.Green};
    }
    & button.react-calendar__navigation__label {
      text-align: left;
    }
    & .react-calendar__month-view__weekdays {
      background: #caddfda6;
      border-top-left-radius: ${Theme.radii.medium}px;
      border-top-right-radius: ${Theme.radii.medium}px;
      padding:10px 11px;
      font-family: ${Theme.fonts.regular};
      font-weight: normal;
      font-size: ${Theme.fontSizes.xs};
      line-height: 16px;
      color:${Colors.Black};
      & abbr[title], abbr[data-original-title] {
        text-decoration: none;
      }
    }
    & .react-calendar__month-view__days {
      background: ${Colors.White};
      box-shadow: ${Theme.shadows.light};
      border-radius: ${Theme.radii.medium}px;
    }
    & .react-calendar__navigation {
      height: auto;
      margin-bottom: 15px;
    }
    & button.react-calendar__navigation__label {
      font-family: ${Theme.fonts.semibold};
       font-weight: ${Theme.fontWeights.semibold};
      font-size: ${Theme.fontSizes.medium};;
      line-height: 21px;
      color: ${Colors.Black};
    }
    & button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
      border-radius: ${Theme.radii.medium}px;
      background: ${Colors.Blue};
      height: 42px;
      line-height:33px;
      padding:0;
      color: ${Colors.Black};
    }
    & button.react-calendar__tile.react-calendar__month-view__days__day {
      font-size: ${Theme.fontSizes.xs};
      font-family: ${Theme.fonts.semibold};
      padding: 0;
      abbr {
        display: none;
      }
    }
    & button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
      color: ${Colors.Black};
    }
    & .react-calendar__tile--now {
      height: 42px;
      line-height:33px;
      padding:0;
    }
    & .transparent{
      & .react-calendar__tile--now {
        height: 42px;
        line-height:33px;
        padding:0;
      }
    }
    & .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
      border-radius: ${Theme.radii.medium}px;
      background: ${Colors.Blue};
      height: 42px;
      line-height:33px;
      padding:0;
      color: ${Colors.Black};
    }
    & .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
      background-color: ${Colors.Transparent};
    }
    & .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
      border-radius: ${Theme.radii.medium}px;
      background: ${Colors.Blue};
    }
    & button.react-calendar__navigation__label {
      pointer-events: none;
    }
    & .react-calendar__tile--active {
      border-radius: ${Theme.radii.medium}px;
      background: ${Colors.Blue};
      color: ${Colors.Black};
    }
`;
