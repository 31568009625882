import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import {
  FormWrapper, Header, Div, EditDogSpan, MDImage,
} from "../../styles/Styles";
import Theme from "../../styles/Theme";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import CustomerService from "../../services/CustomerService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser, fetchDogBreeds } from "../../store/actions/AppAction";
import ServiceCard from "../../components/Card/ProfileCard";

const { language } = Constants;
class CustomerDogs extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { customer = {} } = userData;
    this.state = {
      loading: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
      userId: customer.id,
      dogData: [],
    };
  }

  componentDidMount=() => {
    const { getDogBreeds } = this.props;
    getDogBreeds();
    this.getDetails();
    this.fetchDogDetails();
  }

  fetchDogDetails=async () => {
    this.setState({ loading: true });
    const { userId } = this.state;
    try {
      const response = await this.customerService.getDogsList(userId);
      this.setState({ dogData: response, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleSubmitData= (isSaved = false) => {
    if (isSaved) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_customer_updated,
      }, this.fetchDetails);
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  handleAddDog = () => {
    const { history } = this.props;
    history.push(Constants.routes.addMyDog.url);
  };

  handleEditDog = (evemt, dog) => {
    const { history } = this.props;
    history.push(`/my-account/dogs/${dog}/edit-dog`);
  };

  render() {
    const { icons, themes: { widths } } = Constants;
    const {
      loading,
      changesSaved, alertMessage, alertHeader, dogData,
    } = this.state;
    return (
      <Container bg="transparent !important">
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Div display="flex" justifyContent="space-between">
              <Header className="py-2">
                <FormattedMessage id="my_account_header_dogs" />
              </Header>
            </Div>
            <Div className="row m-0" pr={2}>
              {dogData.length > 0 ? dogData.map((dog) => (
                <Div mb={3}>
                  <Div display="flex" className="mr-lg-4 mr-sm-0 mr-md-0 my-2">
                    <ServiceCard
                      data={dog.data}
                      userAvtar={dog.data && dog.data.profilePic ? dog.data.profilePic.url : null}
                    />
                  </Div>
                  <Div onClick={(e) => this.handleEditDog(e, dog.id)}>
                    <EditDogSpan
                      color={Theme.colors.primary}
                      textDecoration="underline"
                      cursor="pointer"
                    >
                      <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-2" role="button" />
                      <FormattedMessage id="my_account_edit_dog_info" />
                    </EditDogSpan>
                  </Div>
                </Div>
              )) : (
                ""
              )}
            </Div>
            <Div py={3} display="flex" justifyContent="start">
              <Button
                label={<FormattedMessage id="customer_add_new_dog" defaultMessage="Add new dog" />}
                primary
                startIcon={icons.PlusIcon}
                width={widths.w175}
                padding="10px !important"
                onClick={this.handleAddDog}
              />
            </Div>
          </>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
              onClick={this.handleCancel}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
CustomerDogs.propTypes = {
  history: PropTypes.node,
  getLoginUser: PropTypes.func,
  getDogBreeds: PropTypes.func,
};
CustomerDogs.defaultProps = {
  history: undefined,
  getLoginUser: () => {},
  getDogBreeds: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getDogBreeds: () => dispatch(fetchDogBreeds()),
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerDogs);
