import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import {
  MDLabel, Div, IsDesktop, IsMobile, FormWrapper, MDImage, Span, MDInputValue,
} from "../../../styles/Styles";
import CustomerModel from "../../../models/Customers";
import Theme from "../../../styles/Theme";
import CustomerService from "../../../services/CustomerService";
import ServiceList from "../../../services/ServicesPageService";
import Config from "../../../config";
import UploadPicture from "../../../components/UploadSection/UploadPicture";
import FormLoader from "../../../components/Loader/FormLoader";
import { isCustomer, formatDate } from "../../../shared/utils";

const { language, themes: { fontSizes }, input: { format: { twoDigitDateFormat } } } = Constants;

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" />)
    .required(<FormattedMessage id="validation_empty_email_input" />),
  phone: Yup.array()
    .of(
      Yup.string()
        .typeError(language.validation_valid_phone_number)
        .required(language.validation_valid_phone_number)
        .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
    )
    .required(language.validation_valid_phone_number)
    .min(1, language.validation_valid_phone_number),
});
class CreateCustomerForm extends Component {
  customerService = new CustomerService(Config.apiBase);

  serviceList = new ServiceList(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        firstName: "",
        lastName: "",
        email: "",
        profilePic: "",
        phone: [""],
        createdAt: "",
      },
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const { edit } = this.props;
    if (edit) {
      await this.handleFormatData();
    }
    this.setState({ loading: false });
  };

  handleFormatData = () => {
    const { customerData } = this.props;
    const { data = {} } = customerData;
    this.setState({ data });
  };

  handleSubmit = async (values, props) => {
    const { setErrors } = props;
    this.setState({ loading: true });
    const { edit, handleBackToCustomers, userId } = this.props;
    try {
      this.customerModel = new CustomerModel({});
      if (edit) {
        const response = await
        this.customerService.updateCustomer(userId, this.customerModel.toJson(values));
        if (response) {
          this.setState({ loading: false });
          handleBackToCustomers(true);
        }
      } else {
        const response = await this.customerService.addCustomer(this.customerModel.toJson(values));
        if (response) {
          this.setState({ loading: false });
          handleBackToCustomers(true);
        }
      }
    } catch (e) {
      const { response: { data: { errors = {} } = {} } = {} } = e;
      const { email = "" } = errors;
      if (email) {
        setErrors({ email: Constants.language.validate_email_already_exists });
      } else {
        handleBackToCustomers(false);
      }
      this.setState({ loading: false });
    }
  };

  handleReset=() => {
    const { edit, onCancel } = this.props;
    if (edit) {
      onCancel();
    } else {
      this.setState({
        data: {
          name: "",
          email: "",
          profilePic: "",
          phone: [""],
        },
      });
    }
  }

  render() {
    const {
      data,
      loading,
    } = this.state;
    const {
      input: { name: inputNames, format: { dottedHourFormat } },
      icons,
    } = Constants;
    const {
      deleteCustomer, edit, disableData, showBanUserModal, status,
    } = this.props;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={ValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {(props) => {
            const {
              values, errors, handleChange, handleBlur, handleSubmit,
              isValid, touched, handleReset,
            } = props;
            const { phone = [""] } = values;
            let isAddMoreDisabled = false;
            phone.forEach((num) => {
              if ((num && num.length < 9) || !num) {
                isAddMoreDisabled = true;
              }
            });

            return (
              <>
                {loading && (
                  <FormWrapper>
                    <FormLoader />
                  </FormWrapper>
                )}
                {!loading && (
                  <>
                    <FormWrapper>
                      <UploadPicture
                        label={language.label_profile_picture}
                        value={values.profilePic}
                        name={inputNames.profilePic}
                        files={values.profilePic}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableData}
                      />
                    </FormWrapper>
                    <Form>
                      <FormWrapper>
                        <TextBox
                          startIcon={icons.ProfileIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.placeholder_name}
                          placeholder={language.placeholder_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          name={inputNames.firstName}
                          formikValues={props}
                          IconHeight="20px"
                          disabled={disableData}
                        />
                        <TextBox
                          startIcon={icons.ProfileIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.placeholder_surname}
                          placeholder={language.placeholder_surname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          name={inputNames.lastName}
                          formikValues={props}
                          IconHeight="20px"
                          disabled={disableData}
                        />
                        <MDLabel className="mt-3 mb-2" fontSize="16px" disable={disableData}>
                          {language.label_phone}
                        </MDLabel>
                        <FieldArray
                          name={inputNames.phone}
                          render={({ push, remove }) => (
                            <div>
                              {values.phone.map((ph, index) => (
                                <Div className="d-flex mb-3" key={index}>
                                  {values.phone.length > 1 && (
                                    <MDImage
                                      src={Constants.icons.MinusIcon}
                                      alt="icon"
                                      onClick={() => {
                                        remove(index); handleBlur({
                                          target: {
                                            name: `${inputNames.phone}.${index}`,
                                            value: ph,
                                          },
                                        });
                                      }}
                                      role="button"
                                      className={`${disableData && "disabled-state"} mr-3`}
                                    />
                                  )}
                                  <TextBox
                                    startIcon={icons.PhoneIcon}
                                    containerProps="m-auto justify-content-center"
                                    placeholder={language.telephone_label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={ph}
                                    showError={errors.phone && errors.phone[index]}
                                    isTouched={touched.phone && touched.phone[index]}
                                    name={`${inputNames.phone}.${index}`}
                                    IconHeight="20px"
                                    width="100%"
                                    disabled={disableData}
                                    phone
                                  />
                                </Div>
                              ))}
                              <MDLabel
                                onClick={() => push("")}
                                disable={disableData || isAddMoreDisabled || phone.length === 3}
                                ml={4}
                              >
                                <MDImage src={Constants.icons.AddIcon} alt="icon" className="mr-2" role="button" />
                                <Span
                                  color={Theme.colors.primary}
                                  textDecoration="underline"
                                  cursor="pointer"
                                  disable={disableData || isAddMoreDisabled || phone.length === 3}
                                >
                                  <FormattedMessage id="label_add_phone" />
                                </Span>
                              </MDLabel>
                            </div>
                          )}
                        />
                        {isCustomer() ? (
                          <>
                            <MDLabel className="mt-3 mb-2" fontSize="16px">
                              {language.label_email}
                            </MDLabel>
                            <Div display="flex" mb={40}>
                              <MDImage src={icons.EmailIcon} alt="icon" className="mr-2" />
                              <MDLabel fontSize={fontSizes.regular}>
                                {values.email}
                              </MDLabel>
                            </Div>
                          </>
                        ) : (
                          <>
                            <TextBox
                              startIcon={icons.EmailIcon}
                              containerProps="m-auto justify-content-center"
                              label={language.label_email}
                              placeholder={language.label_email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              name={inputNames.email}
                              formikValues={props}
                              IconHeight="20px"
                              disabled={disableData}
                            />
                          </>
                        )}
                      </FormWrapper>
                      {edit && (
                        <Div display={["block", "flex"]} mt={3} pt={1}>
                          <MDLabel pl={1} fontSize="18px" mb={[2, 0]}>
                            <FormattedMessage id="label_created_timestamp" defaultMessage="Date and time of register" />
                            :
                          </MDLabel>
                          <MDInputValue pl={1}>
                            {data.createdAt ? formatDate(data.createdAt, twoDigitDateFormat) : ""}
                            ,
                            <MDInputValue px={1}>
                              {data.createdAt ? formatDate(data.createdAt, dottedHourFormat) : ""}
                            </MDInputValue>
                          </MDInputValue>
                        </Div>
                      )}
                      <IsDesktop className="d-lg-flex mt-5">
                        <Button
                          primary
                          startIcon={icons.AssignmentsMenuIcon}
                          IconHeight="18px"
                          label={<FormattedMessage id="button_save_changes" />}
                          onClick={handleSubmit}
                          disable={!(isValid && Object.keys(touched).length > 0)}
                          width="200px"
                          className="mr-3"
                          type="submit"
                          disabled={disableData}
                        />
                        <Button
                          secondary
                          label={<FormattedMessage id="button_delete_changes" />}
                          onClick={() => { handleReset(); this.handleReset(); }}
                          width="200px"
                          className="mx-3"
                          type="reset"
                          disabled={disableData}
                        />
                        {edit && !isCustomer() && (
                          <>
                            <Button
                              secondary
                              startIcon={icons.BanIcon}
                              label={<FormattedMessage id="button_ban_customer" />}
                              onClick={showBanUserModal}
                              width="auto"
                              className="mx-3"
                              type="button"
                              disabled={disableData || status === Constants.status.banned}
                            />
                            <Button
                              secondary
                              startIcon={icons.PrimaryDeleteIcon}
                              IconHeight="18px"
                              label={<FormattedMessage id="common_delete" />}
                              onClick={deleteCustomer}
                              width="200px"
                              className="ml-4"
                              type="button"
                              disabled={disableData}
                            />
                          </>
                        )}
                      </IsDesktop>
                      <IsMobile className="mt-5">
                        <Button
                          primary
                          startIcon={icons.AssignmentsMenuIcon}
                          IconHeight="18px"
                          label={<FormattedMessage id="button_save_changes" />}
                          onClick={handleSubmit}
                          disable={!(isValid && Object.keys(touched).length > 0)}
                          width="100%"
                          className="mb-3"
                          type="submit"
                          disabled={disableData}
                        />
                        <Button
                          secondary
                          label={<FormattedMessage id="button_delete_changes" />}
                          onClick={() => { handleReset(); this.handleReset(); }}
                          width="100%"
                          type="reset"
                          className="mb-4 mt-1"
                          disabled={disableData}
                        />
                        {edit && !isCustomer() && (
                          <>
                            <Button
                              secondary
                              startIcon={icons.BanIcon}
                              label={<FormattedMessage id="button_ban_customer" />}
                              onClick={showBanUserModal}
                              width="100%"
                              type="button"
                              className="mb-4 mt-1"
                              disabled={disableData || status === Constants.status.banned}
                            />
                            <Button
                              primary
                              startIcon={icons.DeleteIcon}
                              IconHeight="18px"
                              label={<FormattedMessage id="common_delete" />}
                              onClick={deleteCustomer}
                              width="100%"
                              type="button"
                              className="mb-4"
                              disabled={disableData}
                            />
                          </>
                        )}
                      </IsMobile>
                    </Form>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
CreateCustomerForm.propTypes = {
  handleBackToCustomers: PropTypes.func,
  deleteCustomer: PropTypes.func,
  edit: PropTypes.bool,
  disableData: PropTypes.bool,
  customerData: PropTypes.string,
  userId: PropTypes.string,
  showBanUserModal: PropTypes.func,
  status: PropTypes.string,
  onCancel: PropTypes.func,
};
CreateCustomerForm.defaultProps = {
  handleBackToCustomers: null,
  deleteCustomer: null,
  edit: false,
  disableData: false,
  customerData: {},
  userId: "",
  showBanUserModal: null,
  status: "",
  onCancel: () => {},
};
export default CreateCustomerForm;
