import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Div } from "../../styles/Styles";
import styled from "styled-components";
import SearchIcon from "../../assets/Icons/SearchIconWhite.svg";
import CloseIcon from "../../assets/Icons/CloseIconWhite.svg";
import Button from "../Button/Button";
import Constants from "../../shared/Constants";

const Input = styled.input`
  height: 40px;
  width: 235px;
  padding-left: 1rem;
  border: 1px solid rgba(26, 36, 36, 0.3);
  border-radius: 5px;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #1A2424;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
}
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: -10px;
  padding: 0;

  @media (max-width: 768px) {
    right: 0;
  }

  img {
    padding-right: 0;
  }
}
`;

const SearchBox = ({ value, onChange, onSearch, onClear }) => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const isSearchApplied = params.get("search");
  const [showCloseIcon, setShowCloseIcon] = useState(isSearchApplied ? true : false);


  const handleChange = useCallback((e) => {
    setShowCloseIcon(false);
    onChange(e);
    const { target: { value = "" }} = e;
    if(!value) {
      onSearch(true);
    }
  });

  const handleOnSearch = useCallback(() => {
    setShowCloseIcon(true);
    onSearch();
  });

  const handleOnClose = useCallback(() => {
    setShowCloseIcon(false);
    onClear();
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setShowCloseIcon(true);
      onSearch();
    }
  };

  return (
    <Div mr={[0, 0, 25, 25]} display="flex" position="relative">
      <Input value={value} onChange={handleChange} type="text" placeholder={`${Constants.language.label_search}...`} onKeyDown={handleKeyPress}/>
      <StyledButton width={65} height={40} startIcon={SearchIcon} onClick={handleOnSearch} />
      {showCloseIcon && <StyledButton width={65} height={40} startIcon={CloseIcon} onClick={handleOnClose} />}
    </Div>
  );
};

SearchBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
};

SearchBox.defaultProps = {
  value: null,
  onChange: () => {},
  onSearch: () => {},
  onClear: () => {},
};

export default SearchBox;
