import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import StepsHeader from "../BookingFlow/CommonSections/StepsHeader/StepsHeader";
import { MDImage, CurvedDiv } from "../../styles/Styles";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import {
  BoxDivision,
  LinkItem,
  MDImg,
  Division,
  MDLabelBox,
  MDInputValueBox,
} from "../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { isLoggedIn, isCustomer, redirectToPage } from "../../shared/utils";
import ScrollToTop from "../../shared/ScrollToTop";
import {
  SubscriptionFlowStepFive,
  SubscriptionFlowStepFour,
  SubscriptionFlowStepOne,
  SubscriptionFlowStepThree,
  SubscriptionFlowStepTwo,
} from "./BookingFlowSteps";
import { SubscriptionBookingFlowActions } from "../../store/reducers/SubscriptionBookingFlowReducer";
import { SubscriptionOrderSuccess } from "./BookingFlowSteps/SubscriptionOrderSuccess";

const { icons } = Constants;

const BookingFlow = {
  [Constants.routes.chooseSubscription.url]: {
    step: 1,
    redirectRoute: Constants.MY_DOG_CARE_URL,
    component: <SubscriptionFlowStepOne />,
  },
  [Constants.routes.chooseSubscriptionDog.url]: {
    step: 2,
    redirectRoute: Constants.routes.chooseSubscription.url,
    component: <SubscriptionFlowStepTwo />,
  },
  [Constants.routes.chooseSubscriptionOrganization.url]: {
    step: 3,
    redirectRoute: Constants.routes.chooseSubscriptionDog.url,
    component: <SubscriptionFlowStepThree />,
  },
  [Constants.routes.chooseSubscriptionDate.url]: {
    step: 4,
    redirectRoute: Constants.routes.chooseSubscriptionOrganization.url,
    component: <SubscriptionFlowStepFour />,
  },
  [Constants.routes.subscriptionOrderSummary.url]: {
    step: 5,
    redirectRoute: Constants.routes.chooseSubscriptionDate.url,
    component: <SubscriptionFlowStepFive />,
  },
  [Constants.routes.subscriptionConfirmation.url]: {
    step: 6,
    redirectRoute: Constants.routes.chooseService.url,
    component: <SubscriptionOrderSuccess />,
  },
};

const SubscriptionFlow = () => {
  const { pathname = "" } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const step = useSelector((state) => state.SubscriptionBookingFlowReducer.step);

  const selectedSubscription = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedSubscription);
  useEffect(() => {
    if (!selectedSubscription || Object.keys(selectedSubscription).length === 0) {
      dispatch(SubscriptionBookingFlowActions.moveToStep(1));
      history.push(Constants.routes.chooseSubscription.url);
    } else {
      let moveToStep = BookingFlow[pathname]?.step ? BookingFlow[pathname]?.step : 1;
      dispatch(SubscriptionBookingFlowActions.moveToStep(moveToStep));
    }
  }, [selectedSubscription, step]);

  const handlePush = () => {
    if (pathname === Constants.routes.chooseSubscription.url) {
      history.push(Constants.routes.home.url);
    } else {
      dispatch(SubscriptionBookingFlowActions.previousStep());
      history.push(BookingFlow[pathname].redirectRoute);
    }
  };

  const backToPage = () => {
    return (
      <LinkItem
        mt={["15px", "15px", 4]}
        pt={[0, 0, 2]}
        mb={2}
        onClick={handlePush}
        width={Theme.widths.full}
        fontFamily={Theme.fonts.semibold}
        fontSize={Theme.fontSizes.xs}
        type="back_To_services">
        <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
        <FormattedMessage id="Back_to_Services_Page" defaultMessage="Back to Services Page" />
      </LinkItem>
    );
  };

  const showTitle = () => {
    if (step === 1) {
      return (<FormattedMessage id="user_choose_services" defaultMessage="Choose service" />);
    } else {
      return (<FormattedMessage id="subscription_daycare_space" defaultMessage="Subscrition: Daycare space" />);
    }
  };

  if (isLoggedIn() && !isCustomer()) {
    const loggedInUserData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    return <Redirect to={redirectToPage(loggedInUserData)} />;
  }
  return (
    <ScrollToTop>
      <Division>
        <Division className="container" px={0} type="main_wrapper" pb={4} mb={["40px", "40px", 4]}>
          {backToPage()}
          <MDLabelBox
            fontSize={Theme.fontSizes.header}
            pb={3}
            type="booking_Service"
            lineHeight={1.3}
            justifyContent="start">
            <FormattedMessage id="booking_the_service" defaultMessage="Booking the service" />
          </MDLabelBox>
          <Division
            type="main_Box"
            className="container"
            px={0}
            mb={2}
            background={Colors.White}
            borderRadius={Theme.radii.medium}
            boxShadow={Theme.shadows.light}>
            <BoxDivision
              background={Colors.White}
              boxShadow={Theme.shadows.light}
              borderRadius={Theme.radii.medium}
              type="choose_Services"
              pl={3}
              pr={4}
              position="relative"
              height={70}>
              <CurvedDiv display="flex">
                <MDLabelBox fontSize={[Theme.fontSizes.medium, Theme.fontSizes.medium, Theme.fontSizes.xl]} type="choose_Text">
                  {showTitle()}
                </MDLabelBox>
              </CurvedDiv>
              {step === 1 ? (
                <MDImg src={icons.PawIcon} type="dog_Paw" alt="icon" />
              ) : (
                <MDInputValueBox
                  fontSize={Theme.fontSizes.small}
                  fontWeight={Theme.fontWeights.normal}
                  type="services_title">
                  <FormattedMessage id="common_category" defaultMessage="Category:" />
                  <LinkItem href type="categories" ml={2} fontWeight={Theme.fontWeights.semibold}>
                    <FormattedMessage id="subscription" defaultMessage="Subscription" />
                  </LinkItem>
                </MDInputValueBox>
              )}
            </BoxDivision>
            {step <= 5 && <StepsHeader currentStep={step} />}
            {BookingFlow[pathname].component}
          </Division>
        </Division>
      </Division>
    </ScrollToTop>
  );
};
export default SubscriptionFlow;
