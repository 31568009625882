import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import BookingFlowStepOne from "./BookingFlowSteps/BookingFlowStepOne";
import BookingFlowStepTwo from "./BookingFlowSteps/BookingFlowStepTwo";
import BookingFlowStepThree from "./BookingFlowSteps/BookingFlowStepThree";
import BookingFlowStepFour from "./BookingFlowSteps/BookingFlowStepFour";
import StepsHeader from "./CommonSections/StepsHeader/StepsHeader";
import {
  MDImage, CurvedDiv, MDLabel,
} from "../../styles/Styles";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import BookingFlowStepFive from "./BookingFlowSteps/BookingFlowStepFive";
import {
  BoxDivision, LinkItem, MDImg, Division, MDLabelBox, MDInputValueBox,
} from "./BookingFlowStyles/BookingFlowStyles";
import "../../styles/main.css";
import { previousSteps, editOrderSummary } from "../../store/actions/BookingFlowServicesAction";
import { isLoggedIn, isCustomer, redirectToPage } from "../../shared/utils";
import ScrollToTop from "../../shared/ScrollToTop";

const { icons } = Constants;

const BookingFlow = () => {
  const { pathname = "" } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);

  const chooseServiceTitle = useSelector((state) => (
    state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices));

  const getCategoryTitle = useSelector((state) => (
    state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices?.categoryName));

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    localStorage.setItem("newDog", "false");
    return () => {
      localStorage.removeItem("newDog");
    };
  }, []);

  const handlePopLocate = (currentPath) => {
    let moveToStep;
    if (currentPath === Constants.routes.chooseService.url) {
      moveToStep = 1;
      history.go(-1);
    } else if (currentPath === Constants.routes.chooseDog.url) {
      moveToStep = 2;
    } else if (currentPath === Constants.routes.chooseCenter.url) {
      moveToStep = 3;
    } else if (currentPath === Constants.routes.chooseTimeDate.url) {
      moveToStep = 4;
    } else if (currentPath === Constants.routes.orderSummary.url) {
      moveToStep = 5;
    } else {
      moveToStep = 1;
      history.go(-1);
      history.go(Constants.routes.chooseService.url);
    }
    dispatch(editOrderSummary(moveToStep));
  };

  useEffect(() => history.listen((location) => {
    if (history.action === "POP") {
      const { pathname: currentPath = "" } = location;
      handlePopLocate(currentPath);
    }
  }), []);

  const handleProviders = () => {
    setIsActive(true);
  };

  const stepsFlow = () => {
    switch (pathname) {
    case "/booking-flow/choose-service":
      return (
        <BookingFlowStepOne />
      );
    case "/booking-flow/choose-dog":
      return (
        <BookingFlowStepTwo />
      );
    case "/booking-flow/choose-center":
      return (
        <BookingFlowStepThree />
      );
    case "/booking-flow/choose-date-and-time":
      return (
        <BookingFlowStepFour
          handleOnchange={handleProviders}
          isActive={isActive}
        />
      );
    case "/booking-flow/order-summary":
      return (
        <BookingFlowStepFive />
      );
    default:
      break;
    }
    return null;
  };

  const handlePush = (route) => (e) => {
    e.preventDefault();
    dispatch(previousSteps());
    history.push(route);
  };

  const backToPage = () => {
    switch (pathname) {
    case "/booking-flow/choose-service":
      return (
        <LinkItem
          mt={4}
          pt={2}
          mb={2}
          href="https://mydogcare.com/tjanster"
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="back_to_my_dogcare" defaultMessage="Back to Services Page" />
        </LinkItem>
      );
    case "/booking-flow/choose-dog":
      return (
        <LinkItem
          mt={4}
          pt={2}
          mb={2}
          onClick={handlePush(Constants.routes.chooseService.url)}
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="back_to_services" defaultMessage="Back to Services Page" />
        </LinkItem>
      );
    case "/booking-flow/choose-center":
      return (
        <LinkItem
          mt={4}
          pt={2}
          mb={2}
          onClick={handlePush(Constants.routes.chooseDog.url)}
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="back_to_dog" defaultMessage="Back to Services Page" />
        </LinkItem>
      );
    case "/booking-flow/choose-date-and-time":
      return (
        <LinkItem
          mt={4}
          pt={2}
          mb={2}
          onClick={handlePush(Constants.routes.chooseCenter.url)}
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="back_to_centre" defaultMessage="Back to Services Page" />
        </LinkItem>
      );
    case "/booking-flow/order-summary":
      return (
        <LinkItem
          mt={4}
          pt={2}
          mb={2}
          onClick={handlePush(Constants.routes.chooseTimeDate.url)}
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImage src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="back_to_time_date" defaultMessage="Back to Services Page" />
        </LinkItem>
      );
    default:
      break;
    }
    return null;
  };
  if (isLoggedIn() && !isCustomer()) {
    const loggedInUserData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    return <Redirect to={redirectToPage(loggedInUserData)} />;
  }
  if (pathname === Constants.routes.bookingFlow.url) {
    return <Redirect to={Constants.routes.chooseService.url} />;
  }
  return (
    <ScrollToTop>
      <Division>
        <Division className="container" px={0} type="main_wrapper" pb={4}>
          {backToPage()}
          <MDLabelBox
            fontSize={Theme.fontSizes.header}
            pb={2}
            type="booking_Service"
            lineHeight={1.3}
            justifyContent="center"
          >
            <FormattedMessage id="booking_the_service" defaultMessage="Booking the service" />
          </MDLabelBox>
          <MDLabel display="flex" justifyContent="center" pb={3} fontSize={16}>
            <FormattedMessage id="subtext_to_book_service" defaultMessage="Subtext to Book Service" />
          </MDLabel>
          <Division type="main_Box" className="container" px={0} mb={["20px", 2, 2]} background={Colors.White} borderRadius={Theme.radii.medium} boxShadow={Theme.shadows.light}>
            <BoxDivision
              background={Colors.White}
              boxShadow={Theme.shadows.light}
              borderRadius={Theme.radii.medium}
              type="choose_Services"
              pl={3}
              pr={4}
              position="relative"
              height={70}
            >
              <CurvedDiv
                display="flex"
              >
                <MDLabelBox
                  fontSize={Theme.fontSizes.xl}
                  type="choose_Text"
                >
                  {steps === 1 ? <FormattedMessage id="user_choose_services" defaultMessage="Choose services" /> : chooseServiceTitle?.serviceName}
                </MDLabelBox>
              </CurvedDiv>
              {
                steps === 1 ? (
                  <MDImg src={icons.PawIcon} type="dog_Paw" alt="icon" />
                ) : (
                  <MDInputValueBox
                    fontSize={Theme.fontSizes.small}
                    fontWeight={Theme.fontWeights.normal}
                    type="services_title"
                  >
                    <FormattedMessage id="common_category" defaultMessage="Category:" />
                    <LinkItem href type="categories" ml={2} fontWeight={Theme.fontWeights.semibold}>
                      {getCategoryTitle}
                    </LinkItem>
                  </MDInputValueBox>
                )
              }
            </BoxDivision>
            <StepsHeader currentStep={steps} />
            {stepsFlow()}
          </Division>
        </Division>
      </Division>
    </ScrollToTop>

  );
};
export default BookingFlow;
