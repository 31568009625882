import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Division } from "../BookingFlowStyles/BookingFlowStyles";
import BookingCategories from "../Components/Categories/BookingCategories";
import { emptyPaymentSnippet } from "../../../store/actions/PaymentAction";
import { setCartCount } from "../../../store/actions/BookingFlowServicesAction";
import Constants from "../../../shared/Constants";
import { Box, MDLabel, P } from "../../../styles/Styles";
import Button from "../../../components/Button/Button";
import Colors from "../../../styles/Colors";

const { icons, themes } = Constants;

const BookingFlowStepOne = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(emptyPaymentSnippet());
    dispatch(setCartCount());
  }, []);
  const uidChecked = useSelector((state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices);
  const handleCheckDaycareClick = () => {
    history.push(Constants.routes.chooseSubscription.url);
  };
  return (
    <Division className="bannedOuter">
      <BookingCategories uidChecked={uidChecked?.serviceId} />
      <Box
        display="flex"
        borderRadius="10px"
        flexDirection="column"
        background={Colors.Secondary}
        type="cate_one"
        px={[4, 4, 5]}
        pt={4}
        mt={5}>
        <MDLabel
          lineHeight={["24px", "24px", "17px"]}
          fontSize={[themes.fontSizes.medium, themes.fontSizes.medium, themes.fontSizes.xl]}
          fontWeight="400">
          <FormattedMessage id="check_daycare_title_subscription" defaultMessage="Looking for Daycare Services?" />
        </MDLabel>
        <P mt={4} px={[0, 0, 4]} textAlign="center" fontWeight={themes.fontWeights.light} fontSize={themes.fontSizes.medium}>
          <FormattedMessage
            id="check_daycare_description"
            defaultMessage="We have three different packages of services so you can choose the best set for you and your dog! With us, your dog should feel safe and have fun during the day."
            values={{ break: <br /> }}
          />
        </P>
        <Box my={4}>
          <Button
            onClick={handleCheckDaycareClick}
            label={<FormattedMessage id="check_daycare_button_label" defaultMessage="Check Daycare" />}
            endIcon={icons.ArrowRightWhite}
          />
        </Box>
      </Box>
    </Division>
  );
};
export default memo(BookingFlowStepOne);
