import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Constants from "../../shared/Constants";
import { DragAndDrop, Label } from "./Styles";
import {
  MDImage, Div, MDInputValue, Span, MDLabel,
} from "../../styles/Styles";
import ImageIcon from "../../assets/Icons/ImageIcon.svg";
import FavIcon from "../../assets/Icons/FavIcon.svg";
import Theme from "../../styles/Theme";
import Colors from "../../styles/Colors";
import { ButtonBase } from "../Button/Styles";
import { deleteImage, uploadImage } from "../../services/FilesService";

class UploadPicture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTypeValid: false,
      isUploadError: false,
      isSizeValid: false,
      allowedTypes: Constants.acceptImageTypes,
    };
  }

  /**
   * @async
   * @function onFileLoad Uploads file
   * @param {event} e
   * @listens Event
   */

  onFileLoad = async (e) => {
    const { onChange, name, onBlur } = this.props;
    const { allowedTypes } = this.state;
    e.preventDefault();
    this.setState({
      isSizeValid: false,
      isUploadError: false,
      isTypeValid: false,
    });
    const file = e.currentTarget.files && e.currentTarget.files[0];
    if (file && allowedTypes.includes(file.type)) {
      if (file.size <= Constants.fileSizeLimit) {
        try {
          const response = await uploadImage(file);
          if (response && onChange) {
            const event = { target: { name: [name], value: response } };
            onChange(event);
            onBlur(event);
          }
        } catch (err) {
          this.setState({ isUploadError: true });
        }
      } else {
        this.setState({ isSizeValid: true });
      }
    } else {
      this.setState({ isTypeValid: true });
    }
  };

  /**
   * @async
   * @function handleRemoveFile Removes uploaded file
   */

  handleRemoveFile = async () => {
    const {
      files, onChange, name, onBlur,
    } = this.props;
    let response;
    const { id = "" } = files;
    try {
      response = await deleteImage(id);
      if (response && onChange) {
        const event = { target: { name: [name], value: "" } };
        onChange(event);
        onBlur(event);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      files = {}, label, formikValues, name, disabled,
    } = this.props;
    const { errors = {}, touched = {} } = formikValues;
    const {
      isSizeValid, isTypeValid, isUploadError, allowedTypes,
    } = this.state;
    const { url = "" } = files || {};
    return (
      <div className={disabled ? "disabled-state" : ""}>
        {label && (
          <Div pb={2} pt="15px">
            <MDLabel fontSize="16px" display="initial">
              {label}
            </MDLabel>
          </Div>
        )}
        <Div display="flex" className="row m-0">
          <Div mr={3} bg={Theme.colors.primary} borderRadius="10px !important">
            {url ? (
              <MDImage
                src={url}
                alt="icon"
                p={0}
                borderRadius="10px !important"
                width={79}
                height={79}
              />
            )
              : (
                <MDImage
                  src={FavIcon}
                  alt="icon"
                  p={3}
                  borderRadius="10px !important"
                  width={79}
                  height={79}
                />
              )}
          </Div>
          <Div className={disabled ? "disabled-state" : ""}>
            <ButtonBase primary width="100px">
              <Label>
                <MDImage src={ImageIcon} alt="icon" pr={2} />
                <Span cursor="pointer">{files && files.file_name ? "Change" : "Load"}</Span>
                <DragAndDrop
                  accept={allowedTypes.join(", ")}
                  onClick={this.openFileDialog}
                  className="FileInput"
                  type="file"
                  id="file-browser-input"
                  name="file-browser-input"
                  ref={(input) => {
                    this.fileInput = input;
                  }}
                  onChange={this.onFileLoad}
                  disabled={disabled}
                />
              </Label>
            </ButtonBase>
            <MDInputValue placeholder lineHeight="16px" pt={3}>
              {files && files.file_name ? (
                <MDLabel
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={this.handleRemoveFile}
                  fontWeight={Theme.fontWeights.normal}
                  fontFamily={Theme.fonts.regular}
                >
                  <FormattedMessage id="common_delete" />
                </MDLabel>
              ) : (
                <Span><FormattedMessage id="max_file_size" /></Span>
              )}
            </MDInputValue>
          </Div>
        </Div>
        {((name && errors[name] && touched[name])
        || isSizeValid || isUploadError || isTypeValid) && (
          <MDLabel color={Colors.Red} py={2}>
            {name && errors[name] && touched[name]
              ? errors[name]
              : isSizeValid
                ? <FormattedMessage id="file_invalid_size" />
                : isUploadError
                  ? <FormattedMessage id="file_unable_to_upload" />
                  : isTypeValid
                    ? <FormattedMessage id="file_invalid_type" />
                    : ""}
          </MDLabel>
        )}
      </div>
    );
  }
}
UploadPicture.propTypes = {
  /**
   * Image file
   */
  files: PropTypes.string,
  /**
   * Image upload handler
   */
  onChange: PropTypes.func,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Formik values
   */
  formikValues: PropTypes.string,
  /**
   * name
   */
  name: PropTypes.string,
  /**
   * disabled state
   */
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};
UploadPicture.defaultProps = {
  files: null,
  onChange: () => {},
  label: "",
  formikValues: {},
  name: null,
  disabled: false,
  onBlur: () => {},
};
export default UploadPicture;
