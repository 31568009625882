import React, { useState } from "react";
import Constants from "../../../shared/Constants";
import PropTypes from "prop-types";
import Colors from "../../../styles/Colors";
import { Box, Div, MDImage, MDLabel, P } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import { Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { FormattedMessage } from "react-intl";
import Button from "../../../components/Button/Button";

const { icons } = Constants;

export default function SubscriptionDetail({ subscription, onClick }) {
  const [selectedPrice, setSelectedPrice] = useState(0);
  const detail = JSON.parse(subscription.details);

  const handlePriceClick = (index) => {
    if (selectedPrice != index) {
      setSelectedPrice(index);
    }
  };

  const handleChooseSubscription = () => {
    let selectedId = "";
    if (subscription.priceList.length > 1) {
      selectedId = subscription.priceList[selectedPrice].id;
    } else {
      selectedId = subscription.id;
    }
    onClick(selectedId);
  };

  return (
    <Division type="choose_Services" width={["100%", "unset", "unset"]} b={1} mb={2} mx={[0, 0, 3]} mt={["27px", "27px", subscription.mostPopular ? 4 : 5]}>
      {subscription.mostPopular && (
        <Box background={Colors.Pink} borderTopLeftRadius="10px" borderTopRightRadius="10px">
          <MDLabel mt={2} mb={2} alignSelf="center" fontSize={Theme.fontSizes.large} textTranform="uppercase">
            <FormattedMessage id="most_popular" defaultMessage="Most Popular!" />
          </MDLabel>
        </Box>
      )}
      <Division
        border={subscription.mostPopular ? `2px Solid ${Colors.Pink}` : ""}
        background={Colors.White}
        boxShadow={Theme.shadows.light}
        borderTopLeftRadius={subscription.mostPopular ? "" : Theme.radii.medium}
        borderTopRightRadius={subscription.mostPopular ? "" : Theme.radii.medium}
        borderBottomRightRadius={Theme.radii.medium}
        borderBottomLeftRadius={Theme.radii.medium}
        py={3}>
        <Box mb={3} p={3}>
          <MDLabel alignSelf="center" fontSize={Theme.fontSizes.xl}>
            {subscription.name}
          </MDLabel>
        </Box>
        {subscription.description && (
          <Box mb={3}>
            <MDImage src={icons.CalendarSelected} />
            <MDLabel
              color={Colors.DarkGrey}
              mx={1}
              fontSize={Theme.fontSizes.regular}
              fontWeight={Theme.fontWeights.normal}>
              {subscription.description}
            </MDLabel>
          </Box>
        )}
        {detail.pickup_return && (
          <Box mb={4}>
            <MDImage src={icons.ClockIcon} />
            <MDLabel
              color={Colors.DarkGrey}
              mx={1}
              mr={1}
              fontSize={Theme.fontSizes.regular}
              fontWeight={Theme.fontWeights.normal}>
              {`${Constants.language.pickup_return}:`}
            </MDLabel>
            <MDLabel
              color={Colors.DarkGrey}
              fontSize={Theme.fontSizes.regular}
              fontWeight={Theme.fontWeights.normal}>
              {detail.pickup_return}
            </MDLabel>
          </Box>
        )}
        <Box background={Colors.Secondary} type="cate_one">
          {detail.services_included && (
            <Box display="flex" flexDirection="column" py={3}>
              <MDLabel fontSize={Theme.fontSizes.medium}>
                <FormattedMessage id="services_included" defaultMessage="Services Included:" />
              </MDLabel>
              <Div
                mb={0}
                mt={3}
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                alignItems="flex-start">
                {detail.services_included?.map((service, index) => {
                  return (
                    <Div key={index}>
                      <Box my={subscription.mostPopular ? 2 : 1}>
                        <MDImage src={icons.YellowPoint} />
                        <MDLabel color={Colors.DarkGrey} mx={1}>
                          {service}
                        </MDLabel>
                      </Box>
                    </Div>
                  );
                })}
              </Div>
            </Box>
          )}
        </Box>
        <Division>
          {subscription.priceList && subscription.priceList.length > 1 ? (
            subscription.priceList.map((price, index) => {
              return (
                <Div key={index} mt={4}>
                  <Box
                    mb={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    onClick={handlePriceClick.bind(this, index)}>
                    <MDImage
                      width="20px"
                      height="20px"
                      src={selectedPrice === index ? icons.SelectedRadio : icons.EmptyGreyCircle}
                    />
                    <Div ml={3}>
                      <MDLabel fontSize={Theme.fontSizes.medium}>
                        <MDLabel fontSize={Theme.fontSizes.medium} mr={1}>
                          <FormattedMessage id="common_from" defaultMessage="from" />
                        </MDLabel>
                        <FormattedMessage id="sek" defaultMessage="SEK" /> {price.price}/
                        <FormattedMessage id="month" defaultMessage="mon" />
                      </MDLabel>
                      <Div mt={1} display="flex" flexDirection="row" justifyContent="flex-start">
                        <MDImage src={icons.CalendarSelected} />
                        <MDLabel mx={1} fontSize={Theme.fontSizes.xs}>
                          {price.days}
                        </MDLabel>
                      </Div>
                    </Div>
                  </Box>
                </Div>
              );
            })
          ) : (
            <Box mt={4}>
              <MDLabel mx={1} mb={2} fontSize={Theme.fontSizes.medium} >
                <MDLabel fontSize={Theme.fontSizes.medium} mr={1}>
                  <FormattedMessage id="common_from" defaultMessage="from" />
                </MDLabel>
                <FormattedMessage id="sek" defaultMessage="SEK" /> {subscription.price.large}/
                <FormattedMessage id="month" defaultMessage="mon" />
              </MDLabel>
            </Box>
          )}
        </Division>
        <P mb={1} mt={4} p={2} display="flex" justifyContent="center">
          <Button
            width="255px"
            label={<FormattedMessage id="choose_and_apply" defaultMessage="Choose and Apply" />}
            onClick={handleChooseSubscription}
            primary
          />
        </P>
      </Division>
    </Division>
  );
}

SubscriptionDetail.propTypes = {
  subscription: PropTypes.object,
  onClick: PropTypes.func,
};
