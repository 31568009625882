import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PlusIcon from "../../../assets/Icons/PlusIccon.svg";
import Button from "../../../components/Button/Button";
import PortalContainer from "../../../components/Containers/PortalContainer";
import FilterButton from "../../../components/Filter/FilterButton";
import ListScreen from "../../../components/ListScreen/ListScreen";
import MainLoader from "../../../components/Loader/MainLoader";
import Pagination from "../../../components/Pagination/Pagination";
import Tabs from "../../../components/Tabs/Tabs";
import { getSubscriptionsList } from "../../../services/SubscriptionService";
import Constants from "../../../shared/Constants";
import { checkRightPermission, getUserInfo, formatDate, getExceptionPopupContent, getSubscriptionFilters } from "../../../shared/utils";
import { getAvailableOrganizations, getOrdersServicesFilters } from "../../../store/actions/FilterAction";
import { Div, Header } from "../../../styles/Styles";
import Routes from "../../../shared/Routes";
import AuthService from "../../../services/AuthService";
import config from "../../../config";
import { loggedInUser } from "../../../store/actions/AppAction";
import SearchBox from "../../../components/Input/SearchBox";
import CsvDownloadButton from "../../../components/CsvDownload/CsvDownloadButton";
import DeleteSuccessPopUp from "../../../components/AvailabilityModal/DeleteSuccessPopUp";

const {
  subscriptionPackage,
  packageTranslations,
  filter: { components, options: optionType },
  sorting: { type: sortType },
  language,
  themes: { widths },
  adminRoutes,
  routes,
  defaultApplicationStatus,
  CSV_FILENAME: { SUBSCRIPTION },
  input: { format },
  SUBSCRIPTION_SORT_FIELDS,
} = Constants;

const ColumnFilters = [
  {
    id:"dog_size",
    component: components.CHECKBOX,
    label: language.label_height,
    values: optionType.DOG_HEIGHT,
    defaultValues: [],
    availableValues: [],
    name: "dog_size",
  },
  {
    id: "application_status",
    component: components.CHECKBOX,
    label: language.filter_info_status,
    values: optionType.INFO_STATUS,
    defaultValues: defaultApplicationStatus,
    initialValues: defaultApplicationStatus,
    availableValues: [],
    name: "application_status",
  },
  {
    id:"gender",
    component: components.CHECKBOX,
    label: language.dog_gender_label,
    values: optionType.DOG_GENDER,
    defaultValues: [],
    availableValues: [],
    name: "gender",
  },
];

const header = [
  {
    className: "col-3",
    value: language.list_header_customer,
    key: "customer",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.list_header_type,
    key: "product",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.label_dog,
    key: "dog",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.label_height,
    key: "dog_size",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.filter_label_status,
    key: "application_status",
    icon: sortType.ARROW,
  },
  {
    className: "col-2",
    value: language.list_header_organisations,
    key: "organizations",
    icon: sortType.ALPHABETS,
  },
];

const Columns = [
  {
    className: "col-3",
    key: "customer",
    type: "singleUser",
    isDesktop: true,
  },
  {
    className: "col",
    key: "product",
    type: "packages",
  },
  {
    className: "col",
    key: "dog",
    type: "singleUser",
  },
  {
    className: "col",
    key: "dog",
    type: "dogHeight",
  },
  {
    className: "col",
    key: "application_status",
    type: "applicationStatus",
  },
  {
    className: "col-2",
    key: "organization",
    type: "singleUser",
    isOrg: true,
  },
];

const Subscription = ({ path }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = new AuthService(config.apiBase);
  const search = location.search;
  const params = new URLSearchParams(search);
  const pageNo = params.get("page");
  const searchWord = params.get("search");
  const subscriptionFilterValues = JSON.parse(params.get("filter"));
  const [selectedTab, setSelectedTab] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState("created_at");
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(subscriptionFilterValues || {});
  const [switchTab, setSwitchTab] = useState(false);
  const [filterOptions, setFilterOptions] = useState(ColumnFilters);
  const { permissions = [] } = getUserInfo(userDetails);
  const hasWriteAccess = checkRightPermission(permissions,"is_subscription_editable",Constants.WRITE);
  const tabs = [language.label_application, language.label_active, language.label_stop, language.label_archived]
  const [subscriptions, setSubscriptions] = useState();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(searchWord || "");
  const [toBeFilteredData, setToBeFilteredData] = useState({});
  const organizationsFilters = useSelector((state) => state.FilterReducer.organizationsFilters);
  const orderServicesFilters = useSelector((state) => state.FilterReducer.orderServicesFilters);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const getOrganizationsFilters = () => dispatch(getAvailableOrganizations());
  const { newSubscription = 0 } = userDetails || {};
  const getServicesFilters = () => dispatch(getOrdersServicesFilters());
  const tabByStatus= {
    [language.label_application]: "open",
    [language.label_active]: "active",
    [language.label_stop]: "canceled",
    [language.label_archived]: "archived",
  };

  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: pageNo || 1,
    pageSize: 10,
    pages: 1,
  });

  const [isErrorModal, setIsErrorModal] = useState(false);

  const fetchSelectedTab = () =>{

    let currentTab = "";
    if(path === routes.activeSubscriptionsList.url) {
      currentTab = language.label_active;
    } else if(path === routes.cancelledSubscriptionsList.url) {
      currentTab = language.label_stop;
    } else if(path === routes.archievedSubscriptionsList.url) {
      currentTab = language.label_archived;
    } else {
      currentTab = language.label_application;
    }
    setSelectedTab(currentTab)
  }

  useEffect(()=>{
    const updateUserDetails = async () => {
      try {
        const res =  await authService.usersDetails();
        getLoginUser(res);
      } catch (e) {
        console.error(e);
      }
    }
    updateUserDetails();
  }, []);

  useEffect(() => {
    fetchSelectedTab();
  },[path])


  const getOrganisationFilter = async () => {
    if (!organizationsFilters.length) {
      await getOrganizationsFilters();
    }
  }

  const getServiceFilter = async () => {
    if(!orderServicesFilters.length){
      await getServicesFilters();
    }
  }


  const getOrdersService = () => {
    const packageType = []

    const makeProductFilter =  (item) => {
      const bronzePackage =  packageType.find(product => product.label.includes(subscriptionPackage.bronze))

      if(!bronzePackage && item?.label?.toLowerCase().includes(subscriptionPackage.bronze.toLowerCase())){
        packageType.push({
          label: packageTranslations.bronze,
          value: item.value,
          className: "col-4"
        });
      }
      if(bronzePackage && item.label.includes(subscriptionPackage.bronze)){
        bronzePackage.value = `${bronzePackage.value}, ${item.value}`
      }
      if(item.label.toLowerCase().includes(subscriptionPackage.silver.toLowerCase())){
        packageType.push({
          label: packageTranslations.silver,
          value: item.value,
          className: "col-4"
        })
      }
      if(item.label.toLowerCase().includes(subscriptionPackage.gold.toLowerCase())){
        packageType.push({
          label: packageTranslations.gold,
          value: item.value,
          className: "col-4"
        })
      }
      if(item.label.toLowerCase().includes(subscriptionPackage.custom.toLowerCase())){
        packageType.push({
          label: packageTranslations.custom,
          value: item.value,
          className: "col-4"
        })
      }
    }

    orderServicesFilters.forEach(makeProductFilter)
    return packageType
  }

  const fetchFilters = async () => {
    await Promise.all([getOrganisationFilter(), getServiceFilter()])
    const filter =  filterOptions.filter(item => !["organization_id"].includes(item.id))
    const isOrdersServiceFilter = filter.find(item=>item.id === "product_id");
    const isOrganizationIdInFilter = filter.find(item=>item.id === "organization_id");
    const approvedOrganizations = organizationsFilters.filter(({value})=>{
      const orgs = permissions.find(org => org.organization_id === value);
      return orgs?.is_subscription_editable >= 0;
    });
    if(!isOrdersServiceFilter && orderServicesFilters.length > 0 && !isOrganizationIdInFilter && approvedOrganizations.length >= 0) {
      const newFilters = [{
        id:"product_id",
        component: components.CHECKBOX,
        label: language.list_header_type,
        values: getOrdersService(),
        defaultValues: [],
        name: "product_id",
      },
      {
        id: "subscription_days",
        defaultValues: [],
        component: components.CHECKBOX,
        label: language.days_choose,
        values: optionType.WEEK_DAYS,
        name: "subscription_days",
      },
      ...filter,
      {
        id: "organization_id",
        defaultValues: [],
        component: components.CHECKBOX,
        label: language.list_header_organisations,
        values: approvedOrganizations.map(item => {return {...item, className: "col-12"}}),
        name: "organization_id",
      }]
      setFilterOptions(newFilters);
      setFilterValues( switchTab ? { filter: {} } : filterValues);
    }
    if(selectedTab === language.label_application) {
      const filteredOptions =  filter.filter(item => item.id !== "organization_id");
      const itHasApplicationStatus = filter.find(item => item.id === "application_status");
      if(!itHasApplicationStatus && !isOrganizationIdInFilter && approvedOrganizations.length >= 0){
        const applicationStatus = ColumnFilters.find(item => item.id === "application_status");
        setFilterOptions([...filteredOptions, applicationStatus, {
          id: "organization_id",
          defaultValues: [],
          component: components.CHECKBOX,
          label: language.list_header_organisations,
          values: approvedOrganizations.map(item => {return {...item, className: "col-12"}}),
          name: "organization_id",
        }]);
        setFilterValues( switchTab ? { filter: {} } : filterValues);
      }
    }
    if(selectedTab === language.label_active || selectedTab === language.label_stop) {
      const isOrganizationIdInFilter = filter.find(item=>item.id === "organization_id");
      const filteredOptions =  filter.filter(item => item.id !== "application_status")
      const approvedOrganizations = organizationsFilters.filter(({value})=>{
        const orgs = permissions.find(org => org.organization_id === value);
        return orgs?.is_subscription_editable >= 0;
      });
      // When it has more then one organization filter will be shown again
      if(!isOrganizationIdInFilter && approvedOrganizations.length >= 0) {
        const newFilters = [...filteredOptions, {
          id: "organization_id",
          defaultValues: [],
          component: components.CHECKBOX,
          label: language.list_header_organisations,
          values: approvedOrganizations.map(item => {return {...item, className: "col-12"}}),
          name: "organization_id",
        }]
        setFilterOptions(newFilters);
        setFilterValues( switchTab ? { filter: {} } : filterValues);
      } else {
        setFilterOptions(filteredOptions);
        setFilterValues( switchTab ? { filter: {} } : filterValues);
      }
    }
  };

  useEffect(()=>{
    fetchFilters();
  },[selectedTab, orderServicesFilters.length, organizationsFilters.length])

  /**
   *
   * @async
   * @function fetchSubscriptions Fetches subscriptions
   *
   */
  const fetchSubscriptions = (filters = undefined, switchPage = false, searchWord = "") => {
    setLoading(true);
    const filter = filters || toBeFilteredData;
    // It will show subscription whose organization has READ/WRITE permissions
    if(!filter["organization_id"] && permissions.length){
      const orgs = permissions.filter(org => org.is_subscription_editable !== -1);
      filter["organization_id"] = orgs.map(org => org.organization_id);
    }
    const sortOrder = sortAscending ? sortType.ASC : sortType.DESC;
    const queryParams = [
      ["page", switchPage ? 1 : meta.pageNumber],
      ["pageSize", meta.pageSize],
      ["sortBy", sortedBy],
      ["sort", sortOrder],
      ["filter", getSubscriptionFilters(filter)],
      ["status", tabByStatus[selectedTab]],
      ["search_name", searchWord],
      selectedTab === language.label_application && !filter.application_status ? ["whereIn[application_status]", defaultApplicationStatus] : "",
      ["is_csv", 0],
      ...(sortedBy === "customer"
        ? [
          ["orderBy[customers.firstname]", sortOrder],
          ["orderBy[customers.lastname]", sortOrder],
        ]
        : [["orderBy", `${SUBSCRIPTION_SORT_FIELDS[sortedBy]},${sortOrder}`]]),
    ];
    getSubscriptionsList(queryParams).then(
      async (response) => {
        const {  meta: srcMeta = {}, data = []} = response;
        const responseData = data.map((subscription) => ({
          ...subscription,
          type: subscription?.dog?.breed || "",
          name: subscription?.customer?.name || "",
        }));
        const {
          current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
        } = srcMeta;
        const newMeta = {
          pageNumber: currentPage,
          pageSize: perPage,
          pages,
          totalCount,
        };
        window.scrollTo(0,0);
        setSubscriptions(responseData);
        setMeta(newMeta);
        setLoading(false);
      },
      (error) => {
        console.error(error); // Error!
        setLoading(false);
      },
    ).finally(()=>{
      setLoading(false);
    })
  };

  /**
   *
   * @async
   * @function fetchCsvData Fetches the subscriptions to be downloaded in csv.
   * @returns data of subscriptions.
   *
   */
  const fetchCsvData = async () => {
    setLoading(true);
    const filter = toBeFilteredData;
    if (!filter["organization_id"] && permissions.length) {
      const orgs = permissions.filter(org => org.is_subscription_editable !== -1);
      filter["organization_id"] = orgs.map(org => org.organization_id);
    }
    const queryParams = [
      ["filter", getSubscriptionFilters(filter)],
      ["status", tabByStatus[selectedTab]],
      ["search_name", searchQuery],
      selectedTab === language.label_application && !filter.application_status ? ["whereIn[application_status]", defaultApplicationStatus] : "",
      ["is_csv", 1]
    ];
    try {
      const response = await getSubscriptionsList(queryParams);
      const { data = [] } = response;
      setLoading(false);
      return getSubscriptionFieldsForCsv(data);
    } catch (e) {
      setIsErrorModal(true);
      setLoading(false);
    }
  };


  const handleSearchOnChange = useCallback((e) => {
    const { target: { value: searchValue } }= e;
    setSearchQuery(searchValue);
  });

  const handleApplySearch = useCallback((isEmpty = false) => {
    setMeta(prevMeta => {
      return {...prevMeta, pageNumber: 1 }
    });
    fetchSubscriptions(false, true, isEmpty ? "" : searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("search", searchQuery);
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  });

  const handleClearSearch = useCallback(() => {
    setSearchQuery("");
    setMeta(prevMeta => {
      return {...prevMeta, pageNumber: 1 }
    });
    fetchSubscriptions(false,true, "");
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete("search");
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  });

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePagination = (pageNumber) => {
    if(pageNumber !== meta.pageNumber){
      setMeta(prevMeta => {
        return {...prevMeta, pageNumber}
      });
    }
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  useEffect(() => {
    if (selectedTab && meta?.pageNumber && permissions.length > 0) {
      const { filter = {}, options = filterOptions } = filterValues;
      setFilterOptions(options);
      fetchSubscriptions(filter, false, searchQuery || searchWord);
    }
  }, [selectedTab, meta?.pageNumber, permissions.length, sortedBy, sortAscending]);


  const handleSwitchTabs = (data) => {
    let switchPath = "";
    setSearchQuery("");
    if(data === language.label_active) {
      switchPath = routes.activeSubscriptionsList.url;
    } else if (data === language.label_stop) {
      switchPath = routes.cancelledSubscriptionsList.url;
    } else if (data === language.label_archived) {
      switchPath = routes.archievedSubscriptionsList.url;
    } else {
      switchPath = routes.subscriptionsList.url;
    }
    history.push(switchPath);
  }

  const handleTabs = (tab) => {
    setToBeFilteredData({})
    setSwitchTab(true);
    setFilterValues({ filter: {} })
    setFilterOptions(previousFilter => previousFilter.map(item => ({...item,defaultValues:[]})));
    setSelectedTab(tab);
    setMeta({...meta, pageNumber: 1})
    handleSwitchTabs(tab);
  };

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };


  const handleFilterApply = async (filterValues = [], options = undefined) => {
    const filters = {};
    filterValues.forEach(({name, value}) => {
      if (filters[name]) {
        filters[name].push(value);
      } else {
        filters[name] = [];
        filters[name].push(value);
      }
    });
    setToBeFilteredData(filters);
    setFilterOptions(options);
    setIsFilterOpen(false);
    setFilterValues({
      filter: filters,
      values : filterValues,
      options,
    })
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("filter", JSON.stringify({ filter: filters, options }));
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    fetchSubscriptions(filters, true, searchQuery);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSorting = (name) => () => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };
  /**
   *
   * Redirects to detailed view page
   * @param {object} item subscription data
   * @returns to selected subscription's detailed page
   *
   */
  const handleDetailPage = (item) => () => {
    //Redirect to subscription details page
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.subscription}/${item.id}/${adminRoutes.editSubscription}`);
  };

  const handleAddNew = () => {
    history.push(Routes.adminAddSubscriptions.url)
  }

  const handleMoveTabs = (id) => {
    setSelectedTab(tabs[id]);
    setSwitchTab(true);
    setMeta({...meta, pageNumber: 1})
    handleSwitchTabs(tabs[id]);
  };

  const getSubscriptionFieldsForCsv = (subscriptions) => {
    return subscriptions?.map(subscription => ({
      "First Name": subscription.customer?.first_name,
      "Last Name": subscription.customer?.last_name,
      "Email": subscription.customer?.email,
      "Phone Number": subscription.customer?.phone_number_list,
      "Registered Date And Time": formatDate(subscription.created_at, format.dateWithTime),
      "Subscription Start Date": formatDate(subscription.start_date, format.date),
      "Package": subscription.product?.name,
      "Application Status": subscription.application_status,
      "Dog Name": subscription.dog?.name
    }));
  }

  const handleClosePopup = () => {
    setIsErrorModal(false);
  }

  return <PortalContainer>
    <Div justifyContent="space-between" display="flex" pb={4}>
      {isErrorModal && <DeleteSuccessPopUp data={getExceptionPopupContent()} renderModal={handleClosePopup} />}
      <Header display="inline-block">
        <FormattedMessage id="common_subscriptions" defaultMessage="Subscriptions" />
      </Header>
    </Div>
    <Div justifyContent="space-between" display="flex" flexDirection={["column","row"]}>
      <Tabs
        tabs={tabs}
        openOrders={newSubscription}
        selectedTab={selectedTab}
        onClick={handleTabs}
        onNext={handleMoveTabs}
        onPrevious={handleMoveTabs}
        minWidth={"100px"}
        tabHeight={"40px"}
      />
      <Div display="flex"  justifyContent={[ "space-between","flex-start"]} pb={[3,3,4,4]} pt={[4,4,0,0]} flexDirection={["column-reverse","row"]}>
        <Div pt={[20,0]}>
          <SearchBox
            name="searchQuery"
            value={searchQuery}
            onChange={handleSearchOnChange}
            onSearch = {handleApplySearch}
            onClear= {handleClearSearch}
          />
        </Div>
        <Div display="flex"  justifyContent={[ "space-between","flex-start"]}>
          {hasWriteAccess && (
            <Button
              label={<FormattedMessage id="common_add_new" defaultMessage="Add new" />}
              primary
              startIcon={PlusIcon}
              width={widths.w125}
              height={"40px"}
              padding="10px !important"
              onClick={handleAddNew}
            />
          )}
          <FilterButton
            open={isFilterOpen}
            options={filterOptions}
            closeFilter={handleCloseFilter}
            onApply={handleFilterApply}
          />
          <CsvDownloadButton
            fileName={SUBSCRIPTION}
            onCsvClick={fetchCsvData}
          />
        </Div>
      </Div>
    </Div>
    {loading && <MainLoader type="list" />}
    {!loading &&
    <>
      <ListScreen
        moreButton
        header={header}
        value={Columns}
        isCustomers
        data={subscriptions}
        onClick={handleDetailPage}
        handleSorting={handleSorting}
        sortedBy={sortedBy}
        sortAscending={sortAscending}
        dataNotFoundMessage={<FormattedMessage id="subscription_not_found" defaultMessage="No subscriptions found" />}
      />
      {meta.pages > 1 && (
        <Div py={4} display={["block", "flex", "flex"]} >
          <Div display={["flex"]} justifyContent="center" alignItems="center">
            {`${subscriptions.length < 10 ? subscriptions.length + ((meta.pageNumber - 1) * 10) : subscriptions.length * meta.pageNumber} / ${meta.totalCount}`}
          </Div>
          <Div width="90%" justifyContent="center">
            <Pagination gotoPage={handlePagination} cursor={meta} />
          </Div>
        </Div>
      )}
    </>
    }
  </PortalContainer>
}
Subscription.propTypes = {
  path: PropTypes.string
}

export default Subscription;
