import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import {
  FormWrapper, Header, Div,
} from "../../styles/Styles";
import CustomerService from "../../services/CustomerService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import CreateDogForm from "../Customers/Components/CreateDogForm";
import DogModel from "../../models/Customers";
import Constants from "../../shared/Constants";

const { icons, language, routes } = Constants;

class CustomerEditDog extends Component {
  dogService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { customer = {} } = userData;
    this.state = {
      loading: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
      userId: customer.id,
      dogData: {},
      isDeleteModalOpen: false,
    };
  }

  componentDidMount=() => {
    this.getDetails();
    this.fetchDogDetails();
  }

  fetchDogDetails=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    const { userId } = this.state;
    try {
      const response = await this.dogService.getDogDetails(userId, id);
      this.setState({ dogData: response, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleCancelChanges=() => {
    this.fetchDogDetails();
  }

  handleCancel = () => {
    const { alertMessage } = this.state;
    const { history } = this.props;
    if (alertMessage === language.dog_delete_confiramtion) {
      history.push(routes.myDogs.url);
    } else {
      this.setState({
        changesSaved: false,
        isDeleteModalOpen: false,
        alertMessage: "",
        alertHeader: "",
      }, this.fetchDogDetails);
    }
  }

  handleDeleteDog= async () => {
    this.setState({ loading: true });
    const { userId, dogData } = this.state;
    const { id = "" } = dogData;
    try {
      const response = await this.dogService.deleteDog(userId, id);
      if (response) {
        this.setState({
          isDeleteModalOpen: false,
          alertMessage: language.dog_delete_confiramtion,
          changesSaved: true,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleDeleteModal =() => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleUpdateData=async (dogId, data) => {
    this.setState({ loading: true });
    const { userId } = this.state;
    try {
      this.dogModel = new DogModel({});
      const response = await this.dogService.updateDog(userId,
        dogId, this.dogModel.toDogJson(data));
      if (response) {
        this.setState({ alertMessage: language.dog_details_updated, changesSaved: true });
      }
    } catch (e) {
      this.fetchDogDetails();
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      loading,
      changesSaved, isDeleteModalOpen, alertMessage, alertHeader, dogData, userId,
    } = this.state;
    return (
      <Container bg="transparent !important">
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Div display="flex" justifyContent="space-between">
              <Header className="py-2">
                <FormattedMessage id="my_account_header_dogs" />
              </Header>
            </Div>
            <FormWrapper>
              <CreateDogForm
                onCancel={this.handleCancelChanges}
                edit
                userId={userId}
                submitData={this.handleUpdateData}
                dogData={dogData}
                dogId={dogData.id}
                deleteDog={this.handleDeleteModal}
              />
            </FormWrapper>
          </>
        )}
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="button_delete_dog" defaultMessage="Delete room" />}
              message={<FormattedMessage id="dog_delete_message" defaultMessage="Are you sure to delete this customer?" />}
              buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              confirmationCard
              buttonIcon={icons.DeleteIcon}
              onClick={this.handleDeleteDog}
              onCancel={this.handleCancel}
              onLoader={loading}
            />
          </Modal>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
              onClick={this.handleCancel}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
CustomerEditDog.propTypes = {
  getLoginUser: PropTypes.func,
  match: PropTypes.node,
  history: PropTypes.func,
};
CustomerEditDog.defaultProps = {
  getLoginUser: () => {},
  match: {},
  history: {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerEditDog);
