import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import TextBox from "../../../components/Input/TextBox";
import SelectBox from "../../../components/Input/SelectBox";
import TextArea from "../../../components/Input/TextArea";
import RadioSelect from "../FormElement/RadioSelect";
import Constants from "../../../shared/Constants";
import Theme from "../../../styles/Theme";
import Button from "../../../components/Button/Button";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import { ButtonBox, MobileDiv } from "../Style";
import { Box, Div, MDLabel, Span, ContentRight, ContentLeft } from "../../../styles/Styles";
import * as payAction from "../../../store/actions/PaymentAction";
import CustomerService, { getDogBreeds } from "../../../services/CustomerService";
import Config from "../../../config";
import dogSizes from "../../BookingFlow/Content/dogSizes.json";
import { getDogSizeTranslation } from "../../../shared/utils";

const customerService = new CustomerService(Config.apiBase);
const {
  language,
  icons,
  input,
  options: { dogsAge, dogsHeight, dogsWeight },
} = Constants;

const ValidationSchema = Yup.object().shape({
  dogName: Yup.string().required(language.dog_name_label),
  dogBreed: Yup.object().required(language.dog_breed_label),
  dogGender: Yup.string().required(language.validation_dog_gender),
  sterilized: Yup.string().required(language.validation_option),
  dogAge: Yup.object().required(language.dog_age_label),
  dogInsured: Yup.string().required(language.validation_option),
  dog_size_height: Yup.object().required(language.validation_height),
  moreInformation: Yup.string().required(language.validation_additional_information),
});

const AboutDogForm = (props) => {
  const { index, item = {}, lastOne = false, hideDuration, hideSubmitButton } = props;
  const { dog: dogDetails = {} } = item || {};
  const dispatch = useDispatch();
  const pageStep = useSelector((state) => state.PaymentReducer.pageStep);
  const [dogInitialValues, setDogInitialValues] = useState({
    dogName: "",
    dogBreed: "",
    dogGender: "",
    sterilized: "",
    dogAge: "",
    dog_size_weight: "",
    dog_size_height: "",
    dogInsured: "",
    moreInformation: "",
    isNewToDayCare: "",
  });
  const [valOptions] = useState(dogsAge.map((o) => ({ ...o, isSelected: false })));
  const [dogWeightOptions, setDogWeightOptions] = useState(dogsWeight.map((o) => ({ ...o, isSelected: false })));
  const [dogHeightOptions, setDogHeightOptions] = useState(dogsHeight.map((o) => ({ ...o, isSelected: false })));

  const isNewToDayCareOptions = [
    {
      value: true,
      label: language.common_yes,
      checked: true,
    },
    {
      value: false,
      label: language.common_no,
    },
  ];
  const [dogGenderValue, setDogGenderValue] = useState("");
  const [breedOption, setBreedOption] = useState([]);
  const dogGenderHandler = (handleChange) => (e) => {
    setDogGenderValue(e.target.value);
    handleChange(e);
  };
  const [dogSterilizedValue, setDogSterilizedValue] = useState("");
  const dogSterilizedHandler = (handleChange) => (e) => {
    setDogSterilizedValue(e.target.value);
    handleChange(e);
  };
  const [dogInsuredValue, setDogInsuredValue] = useState("");
  const dogInsuredHandler = (handleChange) => (e) => {
    setDogInsuredValue(e.target.value);
    handleChange(e);
  };

  const handlePrevPage = () => {
    dispatch(payAction.moveToPrevStep());
  };
  const handleFormSubmit = async (event) => {
    dispatch(payAction.submitAboutDogForm(event, pageStep));
    localStorage.removeItem("newDog");

    const payload = {
      name: event.dogName,
      profile_pic_id: "string",
      description: event.moreInformation,
      breed: event.dogBreed.value || "",
      age: event.dogAge.value || "",
      dog_size_height: event.dog_size_height.value || "",
      dog_size_weight: dogDetails.dog_size_weight || "",
      gender: event.dogGender,
      is_sterilized: event.sterilized === "Ja",
      is_insured: event.dogInsured === "Ja",
      api_token: dogDetails.api_token,
    };
    customerService.updateDogDetails(dogDetails.id, payload).then(() => {
      if (lastOne) {
        dispatch(payAction.submitContactDetails(event));
      }
    });
   
  };

  const getYesOrNo = (value = "") => {
    if (value === true) {
      return language.common_yes;
    } else if (value === false) {
      return language.common_no;
    }
    return "";
  };

  useEffect(async () => {
    const breedOptions = await getDogBreeds();
    setBreedOption(breedOptions);
    const breed = breedOptions.find((bo) => bo.value === dogDetails.breed);
    const { name = "", gender = "", description = "", isSterilized = "", age, dog_size_weight = "", dog_size_height = "",  isInsured = "" } = dogDetails;
    const dogAge = valOptions.find((bo) => bo.value === age);
    const dogWeight = dogWeightOptions.find((bo) => bo.value === dog_size_weight);
    const dogHeight = dogHeightOptions.find((bo) => bo.value === dog_size_height);
    const isDogInsured = getYesOrNo(isInsured);
    const isDogSterlized = getYesOrNo(isSterilized);
    setDogGenderValue(gender);
    setDogInitialValues({
      dogName: name || "",
      dogBreed: breed || "",
      dogGender: gender || "",
      sterilized: isDogSterlized,
      dogAge: dogAge || "",
      dog_size_weight: dogWeight || "",
      dog_size_height: dogHeight || "",
      dogInsured: isDogInsured,
      moreInformation: description || "",
    });
    setDogHeightOptions(dogHeightOptions.map((o)=>({...o, isSelected: o?.value === dogHeight?.value })));
    setDogWeightOptions(dogWeightOptions.map((o)=>({...o, isSelected: o?.value === dogWeight?.value })))
    return () => {
      setBreedOption([]);
    };
  }, [item]);

  useEffect(() => {
    const {
      values = {},
    } = props;
    const { sterilized = "", dogInsured = "", dogGender = "" } = values;
    setDogSterilizedValue(sterilized);
    setDogGenderValue(dogGender);
    setDogInsuredValue(dogInsured);
  }, [props]);

  const dogAvtar = dogSizes.filter((dog) => dog.height === item.dog_size);

  const renderTitle = () => {
    if (props.newDog) {
      return language.new_dog;
    }
    return <FormattedMessage id="about_the_dog" defaultMessage="About the Dog" />;
  };


  const renderDuration = () => {
    if (!hideDuration) {
      return (
        <Box mt={3} pt={1}>
          <CommonCard
            avtarImg={dogDetails?.profile_pic?.url || (dogAvtar && dogAvtar[0]?.avatar) || ""}
            header={getDogSizeTranslation(item.dog_size) || ""}
            leftIcon={Constants.icons.ClockIcon}
            leftContent={`${item?.duration || ""} ${language.min}`}
            rightIcon={Constants.icons.TagIcon}
            rightContent={`${Number(item?.price || 0)} ${language.common_kr}`}
            paddingLeft={10}
            mainType="no_cursor"
          />
        </Box>
      );
    }
  };

  return (
    <Formik
      validationSchema={ValidationSchema}
      enableReinitialize
      validateOnMount
      initialValues={dogInitialValues}
      onSubmit={handleFormSubmit}>
      {(propsFormik) => {
        const { formik = propsFormik } = props;
        const {
          values = formik.values,
          handleChange = formik.handleChange,
          handleBlur = formik.handleBlur,
          handleSubmit = formik.handleSubmit,
          validateField = formik.validateField,
          showNewToDayCareSelect = false,
          isSubscription = false,
        } = props; 
        const { sterilized, dogInsured, dogGender } = values;
        setDogSterilizedValue(sterilized);
        setDogGenderValue(dogGender);
        setDogInsuredValue(dogInsured);
        return (
          <MobileDiv type="widewrapper" className="m-auto">
            <Box mt={4}>
              <MDLabel fontSize={Theme.fontSizes.xl}>{renderTitle()}</MDLabel>
            </Box>
            <Box mt={3} pt={1}>
              <MDLabel px={2}>
                <FormattedMessage
                  id="dog_form_subtitle"
                  defaultMessage="Please, fill the inputs
                  below about the dog for Dog wash service."
                />
              </MDLabel>
            </Box>
            {renderDuration()}
            <Span key={index}>
              {isSubscription ? (
                <Div className="row m-0">
                  <ContentLeft>
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      label={language.dog_name_label}
                      placeholder={language.dog_name_label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dogName}
                      name={input.name.dogName}
                      formikValues={formik}
                    />
                  </ContentLeft>
                  <ContentRight>
                    <SelectBox
                      containerProps="m-auto justify-content-center"
                      label={language.dog_breed_label}
                      placeholder={language.dog_breed_label}
                      onChange={handleChange}
                      name={input.name.dogBreed}
                      onBlur={() => validateField(input.name.dogBreed)}
                      formikValues={formik}
                      value={values.dogBreed}
                      options={breedOption}
                    />
                  </ContentRight>
                </Div>
              ) : (
                <TextBox
                  containerProps="m-auto justify-content-center"
                  label={language.dog_name_label}
                  placeholder={language.dog_name_label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dogName}
                  name={input.name.dogName}
                  formikValues={formik}
                />
              )}
              <Div className="row m-0">
                {isSubscription ? (
                  <>
                    <ContentLeft>
                      <SelectBox
                        containerProps="m-auto justify-content-center"
                        label={language.label_select_weight_dog}
                        placeholder={language.label_weight}
                        onChange={handleChange}
                        name={input.name.dog_size_weight}
                        onBlur={() => validateField(input.name.dog_size_weight)}
                        formikValues={formik}
                        value={values.dog_size_weight}
                        options={dogWeightOptions} />
                    </ContentLeft>
                    <ContentRight>
                      <SelectBox
                        containerProps="m-auto justify-content-center"
                        label={language.label_select_height_dog}
                        placeholder={language.label_height}
                        onChange={handleChange}
                        name={input.name.dog_size_height}
                        onBlur={() => validateField(input.name.dog_size_height)}
                        formikValues={formik}
                        value={values.dog_size_height}
                        options={dogHeightOptions} />
                    </ContentRight>
                  </>
                ) : (
                  <>
                    <ContentLeft>
                      <SelectBox
                        containerProps="m-auto justify-content-center"
                        label={language.dog_breed_label}
                        placeholder={language.dog_breed_label}
                        onChange={handleChange}
                        name={input.name.dogBreed}
                        onBlur={() => validateField(input.name.dogBreed)}
                        formikValues={formik}
                        value={values.dogBreed}
                        options={breedOption} />
                    </ContentLeft>
                    <ContentRight>
                      <SelectBox
                        containerProps="m-auto justify-content-center"
                        label={language.label_select_height_dog}
                        placeholder={language.label_height}
                        onChange={handleChange}
                        name={input.name.dog_size_height}
                        onBlur={() => validateField(input.name.dog_size_height)}
                        formikValues={formik}
                        value={values.dog_size_height}
                        options={dogHeightOptions} />
                    </ContentRight>
                  </>
                )}
              </Div>
              <ButtonBox type="mainwrapper" flexDirection="column">
                <RadioSelect
                  labelProps={
                    <>
                      <MDLabel py={2} mt={3} fontSize={Theme.fontSizes.regular}>
                        <FormattedMessage id="dog_gender_label" defaultMessage="Välj kön" />
                      </MDLabel>
                    </>
                  }
                  name={input.name.dogGender}
                  leftDivType="selectSize"
                  leftValue={input.name.male}
                  leftId={`${input.name.dogGender}.${index}.${input.name.male}`}
                  leftOnChange={dogGenderHandler(handleChange)}
                  leftOnBlur={handleBlur}
                  leftChecked={dogGenderValue === input.name.male}
                  formikValues={formik}
                  leftHtmlFor={`${input.name.dogGender}.${index}.${input.name.male}`}
                  leftImageSrc={icons.MaleDog}
                  leftHeader={language.label_gender_male}
                  leftImgHeight={dogGenderValue === input.name.male ? 44 : 50}
                  leftHeightValue={dogGenderValue === input.name.male ? 44 : 50}
                  rightValue={input.name.female}
                  rightDivType="selectSize"
                  rightId={`${input.name.dogGender}.${index}.${input.name.female}`}
                  rightOnChange={dogGenderHandler(handleChange)}
                  rightOnBlur={handleBlur}
                  rightChecked={dogGenderValue === input.name.female}
                  rightHtmlFor={`${input.name.dogGender}.${index}.${input.name.female}`}
                  rightImageSrc={icons.FemaleDog}
                  rightHeader={language.label_gender_female}
                  rightImgHeight={dogGenderValue === input.name.female ? 44 : 50}
                  rightHeightValue={dogGenderValue === input.name.female ? 44 : 50}
                  mr={[0, 3, 3]}
                />
                <RadioSelect
                  labelProps={
                    <>
                      <MDLabel py={2} mt={3} fontSize={Theme.fontSizes.regular}>
                        <FormattedMessage id="kastrerad_steriliserad" defaultMessage="Kastrerad/steriliserad?" />
                      </MDLabel>
                    </>
                  }
                  name={input.name.sterilized}
                  leftValue={language.common_yes}
                  leftId={`${input.name.sterilized}.${index}.${language.common_yes}`}
                  leftOnChange={dogSterilizedHandler(handleChange)}
                  leftOnBlur={handleBlur}
                  leftChecked={dogSterilizedValue === language.common_yes}
                  formikValues={formik}
                  leftHtmlFor={`${input.name.sterilized}.${index}.${language.common_yes}`}
                  leftHeader={language.common_yes}
                  leftMainType={dogSterilizedValue === language.common_yes ? "light_input" : "wide_input"}
                  leftImgHeight={50}
                  rightValue={language.common_no}
                  rightId={`${input.name.sterilized}.${index}.${language.common_no}`}
                  rightOnChange={dogSterilizedHandler(handleChange)}
                  rightOnBlur={handleBlur}
                  rightChecked={dogSterilizedValue === language.common_no}
                  rightHtmlFor={`${input.name.sterilized}.${index}.${language.common_no}`}
                  rightHeader={language.common_no}
                  rightMainType={dogSterilizedValue === language.common_no ? "light_input" : "wide_input"}
                  rightImgHeight={50}
                />
              </ButtonBox>
              <SelectBox
                containerProps="m-auto justify-content-center"
                label={language.dog_age_label}
                placeholder={language.dog_age_label}
                onChange={handleChange}
                name={input.name.dogAge}
                value={values.dogAge}
                formikValues={formik}
                options={valOptions}
                onBlur={() => validateField(input.name.dogAge)}
              />
              <ButtonBox type="mainwrapper">
                {showNewToDayCareSelect && (
                  <Div width={["100%", "50%"]} pr={[0, 0, 4]}>
                    <SelectBox
                      containerProps="m-auto justify-content-center"
                      label={language.label_is_dog_new_to_daycare}
                      onChange={handleChange}
                      name={input.name.isNewToDayCare}
                      value={values.isNewToDayCare}
                      formikValues={formik}
                      options={isNewToDayCareOptions}
                    />
                  </Div>
                )}
                <RadioSelect
                  labelProps={
                    <>
                      <MDLabel py={2} mt={3} fontSize={Theme.fontSizes.regular}>
                        <FormattedMessage id="is_dog_insured_label" defaultMessage="Är hunden försäkrad?" />
                      </MDLabel>
                    </>
                  }
                  name={input.name.dogInsured}
                  leftValue={language.common_yes}
                  leftId={`${input.name.dogInsured}.${index}.${language.common_yes}`}
                  leftOnChange={dogInsuredHandler(handleChange)}
                  leftOnBlur={handleBlur}
                  leftChecked={dogInsuredValue === language.common_yes}
                  formikValues={formik}
                  leftHtmlFor={`${input.name.dogInsured}.${index}.${language.common_yes}`}
                  leftHeader={language.common_yes}
                  leftMainType={dogInsuredValue === language.common_yes ? "light_input" : "wide_input"}
                  leftImgHeight={50}
                  rightValue={Constants.translations.no}
                  rightId={`${input.name.dogInsured}.${index}.${Constants.translations.no}`}
                  rightOnChange={dogInsuredHandler(handleChange)}
                  rightOnBlur={handleBlur}
                  rightChecked={dogInsuredValue === Constants.translations.no}
                  rightHtmlFor={`${input.name.dogInsured}.${index}.${Constants.translations.no}`}
                  rightHeader={Constants.translations.no}
                  rightMainType={dogInsuredValue === Constants.translations.no ? "light_input" : "wide_input"}
                  rightImgHeight={50}
                />
              </ButtonBox>
              <TextArea
                containerProps="m-auto justify-content-center"
                label={language.more_information_label}
                placeholder={language.text_area_placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                name={input.name.moreInformation}
                formikValues={formik}
                width="100%"
                value={values.moreInformation}
              />
            </Span>
            {!hideSubmitButton && (
              <Box py={4}>
                <Div mr={2}>
                  {pageStep === 0 ? null : (
                    <Button
                      primary
                      type="button"
                      label={<FormattedMessage id="label_prev" defaultMessage="Prev" />}
                      width="175px"
                      onClick={handlePrevPage}
                    />
                  )}
                </Div>
                <Button
                  primary
                  type="submit"
                  label={<FormattedMessage id="common_save" defaultMessage="Save" />}
                  width="175px"
                  onClick={handleSubmit}
                />
              </Box>
            )}
          </MobileDiv>
        );
      }}
    </Formik>
  );
};
AboutDogForm.propTypes = {
  props: PropTypes.object,
  index: PropTypes.number,
  item: PropTypes.object,
  lastOne: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  values: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  validateField: PropTypes.func,
  hideDuration: PropTypes.bool,
  newDog: PropTypes.bool,
  showNewToDayCareSelect: PropTypes.bool,
  isSubscription: PropTypes.bool,
  formik: PropTypes.node,
};
AboutDogForm.defaultProps = {
  index: "",
  item: {},
  lastOne: false,
  formik: undefined,
};

export default AboutDogForm;
