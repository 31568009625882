import { fromJson } from "../../models/Calendar";
import { fetchAvailabilityLocalDateConvertedJson } from "../../shared/utils";
import Constants from "../../shared/Constants";
import * as types from "../actions/types";

const initialState = {
  isLoader: false,
  roomsList: {},
  userDetails: {},
  userToken: "",
  calendarEvents: [],
  availableDateRange: {},
  dogBreeds: {},
  Availability: [],
  organization: [],
  serviceProvider: [],
  userService: [],
  slotData: [],
  updateSlot: [],
  deleteAvailability: {},
  deleteAvailabilityDetails: {},
  calendarPayload: {},
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  const breeds = {};
  const { dogSize } = Constants;
  const serviceableDogSize = Object.values(dogSize);
  switch (type) {
  case types.SET_LOADER:
    return { ...state, isLoader: payload };
  case types.FETCH_ROOMS_TRIAL: {
    const { data = {} } = payload || "";
    return { ...state, roomsList: data };
  }
  case types.SET_LOGIN_USER: {
    const { token = "", user = {} } = payload || {};
    return { ...state, userToken: token, userDetails: user };
  }
  case types.SET_USER_DATA: {
    return { ...state, userDetails: payload };
  }
  case types.FETCH_CALENDAR_EVENTS: {
    const { data = [] } = payload;
    const constructedData = data.map((event) => fromJson(event));
    return { ...state, calendarEvents: constructedData };
  }
  case types.SET_ORGANISATION_RECORD: {
    return { ...state, organization: payload };
  }
  case types.GET_SERVICE_PROVIDER: {
    return { ...state, serviceProvider: payload };
  }

  case types.SET_CALENDAR_PAYLOAD: {
    return { ...state, calendarPayload: payload };
  }

  case types.GET_USER_SERVICES: {
    const { data = [] } = payload;
    const services = data.map((o) => ({
      ...o,
      id: o.id,
      label: o.name,
      serviceable_dog_size: o.serviceable_dog_size
        ? o.serviceable_dog_size : serviceableDogSize,
      selectedDogSize: o.serviceable_dog_size
        ? o.serviceable_dog_size : serviceableDogSize,
    }));
    return { ...state, userService: [...services] };
  }
  case types.SAVE_AVAILABLE_DATE_RANGE:
    return { ...state, availableDateRange: payload };
  case types.GET_SLOT_AVAILABILITY:
    return { ...state, availableSlot: payload };
  case types.DOG_BREEDS:
    payload.map((obj) => {
      const { label, value } = obj;
      breeds[value] = label;
      return payload;
    });
    return { ...state, dogBreeds: breeds };
  case types.FETCH_AVAILABILITY: {
    const { data = [] } = payload;
    const formattedData = data.map((o) => (fetchAvailabilityLocalDateConvertedJson({ ...o, display: "background" })));
    return { ...state, availability: formattedData };
  }
  case types.GET_SLOT_DATA: {
    const { data = [] } = payload;
    return { ...state, slotData: data ? fetchAvailabilityLocalDateConvertedJson(data) : {} };
  }
  case types.UPDATE_SLOT_DATA: {
    const { data = [] } = payload;
    return { ...state, updateSlot: data };
  }
  case types.DELETE_AVAILABILITY: {
    return { ...state, deleteAvailability: payload };
  }
  case types.DELETE_AVAILABILITY_DETAILS: {
    return { ...state, deleteAvailabilityDetails: payload };
  }
  default:
    return { ...state };
  }
};
