import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import {
  Box, CardHeader, Div, MDLabel,
} from "../../../styles/Styles";

const SelectAnotherAvailabilityModal = (props) => {
  const { onClickModal, showEmailErrorPopup = false } = props;

  return (
    <Modal>
      <Div width={380} mb={3} p={2}>
        <CardHeader>
          <MDLabel alignSelf="center" fontSize={Theme.fontSizes.medium}>
            <FormattedMessage id="sorry" defaultMessage="Sorry" />
          </MDLabel>
        </CardHeader>
        <Div className="text-center" alignSelf="center" my={3}>
          {showEmailErrorPopup ? 
            <FormattedMessage
              id="error_email_available"
              defaultMessage="The email has already been taken."
            />
            : <FormattedMessage
              id="booking_flow_cart_availability_popup_error_message"
              defaultMessage="Sorry, looks like this slot is not available. Please try another time slot."
            />}
        </Div>
        <Box>
          <Button
            label={<FormattedMessage id="common_ok" defaultMessage="OK" />}
            primary
            onClick={onClickModal}
          />
        </Box>
      </Div>
    </Modal>
  );
};

SelectAnotherAvailabilityModal.propTypes = {
  onClickModal: PropTypes.func,
  handleClose: PropTypes.func,
  showEmailErrorPopup: PropTypes.bool,
};

SelectAnotherAvailabilityModal.defaultProps = {
  onClickModal: () => {},
  handleClose: () => {},
  showEmailErrorPopup: false,
};

export default memo(SelectAnotherAvailabilityModal);
