import styled from "styled-components";
import Theme from "../../styles/Theme";
import Colors from "../../styles/Colors";
import { Div, MDLabel } from "../../styles/Styles";

export const ListItem = styled(Div)`
  display: flex;
  cursor: pointer !important;
`;
export const ListCard = styled(Div)`
  width: 100%;
  boxShadow: ${Theme.shadows.light};
}
`;
export const ListRow = styled(Div)`
  cursor: pointer !important;
  &:hover,
  &: focus,
  &: active {
    background-color: ${Colors.DarkWhite};
    border-radius: 10px;
  }
`;
export const ListCardHeader = styled(Div)`
  background-color: ${Colors.DarkWhite};
  height: ${({ height }) => height || "60px"};
  align-self: center;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  align-items: center;
  color: ${Colors.DarkGrey};
  font-size: ${Theme.fontSizes.small};
  font-family: ${Theme.fonts.regular};
`;
ListCard.defaultProps = {
  width: "100%",
  boxShadow: Theme.shadows.light,
  fontFamily: Theme.fonts.regular,
  bg: Theme.colors.white,
  display: "block",
  m: "auto",
  borderRadius: "15px",
};
export const Header = styled.div``;
export const IsDesktop = styled(Div)`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const IsMobile = styled(Div)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const Content = styled(MDLabel)`
  height: 45px;
  align-self: baseline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 768px) {
    height: 100%;
  }
`;
