import React from "react";
import PropTypes from "prop-types";
import Loader from "./Loader";
import ListScreenLoader from "./ListScreenLoader";
import CardLoader from "./CardLoader";
import CalendarLoader from "./CalendarLoader";
import TimeAccordianLoader from "./TimeAccordianLoader";

const MainLoader = (props) => {
  const { type } = props;
  switch (type) {
  case "list":
    return <ListScreenLoader />;
  case "card":
    return <CardLoader />;
  case "calendar":
    return <CalendarLoader display="flex" min-height="500px" />;
  case "time-accordian":
    return <TimeAccordianLoader />;
  default:
    return <Loader />;
  }
};

MainLoader.propTypes = {
  type: PropTypes.string,
};

MainLoader.defaultProps = {
  type: "",
};

export default MainLoader;
