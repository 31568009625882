import produce from "immer";
import * as types from "../actions/types";
import { toCreateOrderJson } from "../../models/Orders";
import Config from "../../config";

export const initialState = {
  token: null,
  userData: false,
  isLogged: false,
  totalCartContent: 0,
  totalRate: 0,
  totalcharge: 0,
  cartContent: [],
  pageStep: 0,
  aboutDogFormData: [],
  contactFormData: {},
  paymentDone: false,
  orderConfirm: false,
  orderData: {},
  requestPickUp: false,
  payloadData: {
    purchase_country: "SE",
    purchase_currency: "SEK",
    locale: "en-US",
    order_amount: 0,
    order_tax_amount: 0,
    order_lines: [],
    merchant_reference1: "",
    merchant_urls: {
      terms: "https://www.example.com/terms.html",
      checkout: "https://www.example.com/checkout.html?order_id={checkout.order.id}&isChecked=false",
      confirmation: `${Config.orderConfirmUrl}&isChecked=false`,
      push: "https://www.example.com/api/push?order_id={checkout.order.id}&isChecked=false",
    },
    customer_id: "",
    total_duration: "",
    order_total: "",
    firstname: "",
    lastname: "",
    phone_number: "",
    assignment_list: [],
  },
  snippetResponse: {},
  cartId: [],
};

const setPaymentPayload = (data) => {
  const store = data;
  let orderTotal = 0;
  store.payloadData.order_lines = data.cartContent?.map((item) => {
    let totalAddOnsPrice = 0;
    if (item?.additional_service) {
      item.additional_service?.forEach((value) => {
        totalAddOnsPrice += Number(value?.price || 0);
        return value;
      });
    }

    const loadData = {
      type: "physical",
      reference: item?.service_location?.title,
      name: item.service_title,
      quantity: 1,
      quantity_unit: "pcs",
      unit_price:
        Number(item?.service_detail?.tagRate) + Number(totalAddOnsPrice),
      tax_rate: 0,
      total_amount:
        Number(item?.service_detail?.tagRate) + Number(totalAddOnsPrice),
      total_discount_amount: 0,
      total_tax_amount: 0,
    };
    orderTotal += Number(loadData.total_amount);
    return loadData;
  });

  const orderDuration = data.cartContent?.reduce(
    (acc, val) => acc + (val.service_detail.duration || 0), 0,
  );
  store.payloadData.order_amount = orderTotal;
  store.payloadData.order_total = orderTotal;
  store.payloadData.total_duration = orderDuration;
  store.payloadData.cartId = data.cartId;
  store.payloadData = {
    ...store.payloadData,
    ...toCreateOrderJson(data.cartContent),
  };
};

const PaymentReducer = (state = initialState, action) => produce(state, (draft) => {
  const data = draft;
  let formData;
  const { assignments = [] } = action.payload || {};
  const isPickUpfromHome = [];

  switch (action.type) {
  case types.LOAD_CART_CONTENT:
    data.cartContent = action.payload;
    data.totalRate = data?.cartContent?.reduce?.((acc, val) => acc + val.total, 0) || 0;
    data.totalcharge = data?.cartContent?.reduce?.((acc, val) => {
      const { additional_service: additionalService = {} } = val;
      const totalAddOns = additionalService.length > 0;
      let totalAddOnsPrice = 0;
      if (totalAddOns) {
        totalAddOnsPrice = additionalService
          .map((values) => values?.price || 0);
        totalAddOnsPrice = totalAddOnsPrice.reduce((a, v) => Number(a) + Number(v), 0);
      }
      return acc + totalAddOnsPrice;
    }, 0) || 0;
    data.token = localStorage.getItem("token");
    data.userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    data.isLogged = !!localStorage.getItem("token")
          && !(localStorage.userData && Object.keys(localStorage.userData).length === 0);
    data.cartContent = action.payload?.map((obj) => {
      const { dogId } = obj.service_detail;
      let serviceDetaills = obj.service_detail;
      if (dogId >= 3 && dogId <= 6 ) {
        serviceDetaills = { ...serviceDetaills, dogId: "" };
        return { ...obj, service_detail: serviceDetaills };
      }
      return obj;
    });
    data.totalCartContent = action.payload.length;
    break;
  case types.MOVE_TO_NEXT_PAGE:
    data.pageStep += 1;
    break;
  case types.REQUEST_PAYMENT:
    setPaymentPayload(data);
    // data.paymentDone = true;
    break;
  case types.PAYMENT_COMPLETE:
    data.paymentDone = true;
    data.orderId = action.payload;
    break;
  case types.PAYMENT_SNIPPET:
    data.snippetResponse = action.payload;
    break;
  case types.MOVE_TO_PREV_PAGE:
    data.pageStep -= 1;
    break;
  case types.SUBMIT_ABOUT_DOG_FORM:
    formData = data.aboutDogFormData;
    formData[action.payload.page] = action.payload.data;
    data.aboutDogFormData = formData;
    data.pageStep += 1;
    break;
  case types.SUBMIT_CONTACT_FORM:
    data.contactFormData = action.payload;
    data.orderConfirm = true;
    localStorage.setItem("newdog", "false");
    break;
  case types.ORDER_CONFIRM:
    data.orderConfirm = true;
    localStorage.setItem("newdog", "false");
    break;
  case types.ORDER_DATA:
    data.orderData = action.payload;
    data.token = localStorage.getItem("token");
    data.userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    data.isLogged = !!localStorage.getItem("token")
          && !(localStorage.userData && Object.keys(localStorage.userData).length === 0);
    assignments.map((assignment) => {
      const addons = assignment.add_ons || [];
      return addons.map((addon) => {
        if (addon.name.toLowerCase().includes("pick")) {
          isPickUpfromHome.push(true);
        }
        return assignment;
      });
    });
    data.requestPickUp = isPickUpfromHome.filter((o) => o === true).length > 0;
    break;
  case types.ADD_CART_ID:
    data.cartId = action.payload;
    break;
  default:
    return { ...state };
  }
  return data;
});

export default PaymentReducer;
