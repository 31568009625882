import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateRoomForm from "./Components/CreateRoomForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import RoomService from "../../services/RoomService";
import Config from "../../config";
import RoomModel from "../../models/Room";
import Tabs from "../../components/Tabs/Tabs";
import AssignmentTab from "./Components/AssignmentTab";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const { language, adminRoutes, routes } = Constants;
class EditRoom extends Component {
  roomService = new RoomService(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      roomData: {},
      tabs: [language.common_details, language.common_assignments],
      selectedTab: language.common_details,
      isDeleteModalOpen: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
    };
  }

  componentDidMount = () => {
    const { path } = this.props;
    this.setState({
      selectedTab:
        path === routes.assignmentsInRoom.url ? language.common_assignments : language.common_details,
    });
    this.fetchDetails();
  };

  componentDidUpdate=(prevProps)=>{
    const { path } = this.props;
    if(prevProps.path !== path){
      this.setState({
        selectedTab:
          path === routes.assignmentsInRoom.url ? language.common_assignments : language.common_details,
      });
      this.fetchDetails();
    }
  }

  fetchDetails=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.roomService.getRoomDetails(id);
      window.scrollTo(0,0);
      this.setState({ loading: false, roomData: response });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleCancelDetails=() => {
    this.fetchDetails();
  }

  handleSwitchTabs = (data) => {
    const { history, match: { params = {} } } = this.props;
    const { id = "" } = params;
    if(data === language.common_assignments){
      history.push(`/admin/rooms/${id}/edit-room/assignments`);
    } else {
      history.push(`/admin/rooms/${id}/edit-room`);
    }
  }

  handleTabs = (data) => {
    this.setState({ selectedTab: data });
    this.handleSwitchTabs(data)
  };

  handleSubmitData= async (data) => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      this.roomModel = new RoomModel({});
      const response = await this.roomService.updateRoom(id, this.roomModel.toJson(data));
      if (response) {
        this.setState({
          loading: false,
          changesSaved: true,
          alertMessage: language.alert_room_updated,
        }, this.fetchDetails);
      }
    } catch (e) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  /**
   * Opens a delete confirmation popup
   */
  handleDeleteModal =() => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  handleDeleteRoom=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.roomService.deleteRoom(id);
      if (response) {
        this.setState({
          isDeleteModalOpen: false,
          changesSaved: true,
          alertMessage: language.room_deleted_alert_message,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({
      changesSaved: false, isDeleteModalOpen: false, alertMessage: "", alertHeader: "",
    });
  }

  handleBackToRooms = () => {
    const { alertMessage } = this.state;
    if (alertMessage === language.room_deleted_alert_message) {
      const { history } = this.props;
      history.push(Constants.routes.roomsList.url);
    } else {
      this.handleCancel();
    }
  };

  handleRedirectToAssignments=(item) => () => {
    const { history, location: { pathname: currentPath = "", search = "" } } = this.props;
    const params = new URLSearchParams(search);
    const pageNo = params.get("page");
    history.push({
      pathname: `${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${item.id}/${adminRoutes.editAssignment}`,
      state: `${currentPath}?isAssignment=true${pageNo && `&page=${pageNo}`}`
    });
  }

  handleRedirectToRooms = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { icons } = Constants;
    const {
      loading, roomData = {}, selectedTab, tabs,
      isDeleteModalOpen,
      changesSaved, alertMessage, alertHeader,
    } = this.state;
    const { data: { name = "", openOrders = 0 } = {} } = roomData;
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    return (
      <Container>
        <Div display="flex">
          <MDLabel cursor="pointer" onClick={this.handleRedirectToRooms}>
            <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
            <FormattedMessage id="common_back" defaultMessage="Back" />
          </MDLabel>
        </Div>
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Header className="py-2">{name}</Header>
            <Div className="my-3">
              <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                onClick={this.handleTabs}
                openOrders={openOrders}
              />
            </Div>
            {selectedTab === tabs[0]
            && (
              <>
                <CreateRoomForm
                  edit
                  roomId={id}
                  roomData={roomData}
                  submitData={this.handleSubmitData}
                  deleteRoom={this.handleDeleteModal}
                  onCancel={this.handleCancelDetails}
                />
              </>
            )}
            {selectedTab === tabs[1] && (
              <>
                <AssignmentTab roomId={id} redirectTo={this.handleRedirectToAssignments} />
              </>
            )}
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="room_delete_room" defaultMessage="Delete room" />}
              message={<FormattedMessage id="room_deleted_confirmation_message" defaultMessage="Are you sure you want to delete ?" />}
              buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteRoom}
              onCancel={this.handleCancel}
              onLoader={loading}
            />
          </Modal>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToRooms}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
EditRoom.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
  location: PropTypes.node,
  path: PropTypes.node,
};
EditRoom.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
  location: undefined,
  path: undefined,
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

export default compose(
  connect(mapStateToProps),
)(EditRoom);
