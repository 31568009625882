const calculateTime = (t) => {
  const hh = Math.floor(t / 60); // getting hours of day in 0-24 format
  const mm = (t % 60); // getting minutes of the hour in 0-55 format
  return `${(`0${hh}`).slice(-2)}:${(`0${mm}`).slice(-2)}`;
};

export const timeIntervals = () => {
  const x = 15; // minutes interval
  const times = []; // time array
  let tt = 0 * 60; // start time

  [...Array(100)].every((xyz, i) => {
    if (tt > 24 * 60) {
      return false;
    }
    times[i] = {
      value: "", label: "",
    };
    times[i].value = calculateTime(tt);
    times[i].label = calculateTime(tt);
    tt += x;
    return true;
  });
  return times;
};

export default timeIntervals;
