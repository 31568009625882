import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { CardWrapper } from "../Services/Styles";
import {
  CardHeader, MDLabel, Div,
} from "../../styles/Styles";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import ProfileCard from "../../components/Card/ProfileCard";

const ServicesModal = ({
  data = [], onSave, onCancel, onChange,
}) => {
  const [showServices, setShowServices] = useState(data);
  const { icons, themes: { fontSizes } } = Constants;

  const handleSelectData = (service) => {
    setShowServices(showServices.map((obj) => (
      {
        ...obj,
        isSelected: obj.id !== service.id ? obj.isSelected : !obj.isSelected,
      })));
  };

  return (
    <CardWrapper width="887px">
      <CardHeader className="position-sticky">
        <MDLabel alignSelf="center" fontSize={fontSizes.xl}>
          <FormattedMessage id="header_choose_services" defaultMessage="Select services" />
        </MDLabel>
      </CardHeader>
      <Div className="overflow-auto" maxHeight="377px" display="contents">
        <Div p={3}>
          {showServices.map((obj) => (
            <Div display="inline-flex" className="col-lg-auto col-md-12 p-0">
              <Div width="100%" m={2} display="flex">
                <ProfileCard
                  data={obj}
                  onClick={handleSelectData}
                  userAvtar={obj.profilePic || ""}
                  serviceCard
                />
              </Div>
            </Div>
          ))}
          <Div my={3} display="flex" justifyContent="center" className="position-sticky">
            <Button
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              secondary
              className="mr-3"
              onClick={onCancel}
            />
            <Button
              className="ml-3"
              onClick={onSave(showServices, onChange)}
              label={<FormattedMessage id="common_save" defaultMessage="Save" />}
              startIcon={icons.AssignmentsMenuIcon}
              primary
            />
          </Div>
        </Div>
      </Div>
    </CardWrapper>
  );
};

ServicesModal.propTypes = {
  data: PropTypes.node,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};
ServicesModal.defaultProps = {
  data: [],
  onSave: () => {},
  onCancel: () => {},
  onChange: () => {},
};
export default ServicesModal;
