import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import { Div, CardHeader, MDLabel, MDImage } from "../../styles/Styles";
import Tabs from "../../components/Tabs/Tabs";
import SelectBox from "../../components/Input/SelectBox";
import CrossCircle from "../../assets/Icons/CrossCircle.svg";
import { fetchServiceProvider } from "../../store/actions/AppAction";

const { language } = Constants;

export const Filter = ({ handleApplyFilter, handleClose, filterData = "", allOrgs = [], allServiceProviders = [] }) => {
  const tabs = [language.common_orders, language.label_availability];
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab]= useState(language.common_orders);
  const [allOrganisations, setAllOrganisations] = useState(allOrgs || []);
  const [selectedServiceProvider, setSelectedServiceProvider ] = useState("");
  const intialData = {
    organization : {...allOrgs?.[0], isSelected: true },
    service_provider: "",
    orders: true,
    availabilities: false,
  };
  
  const [data, setData] = useState(intialData);

  const handleTabs = useCallback((data)=> {
    setData({
      organization : {...allOrgs?.[0], isSelected: true },
      service_provider: "",
      orders: data === tabs[0],
      availabilities: data === tabs[1],
    });
    setAllOrganisations(allOrganisations.map((o)=>({...o,isSelected: false})));
    setSelectedServiceProvider({});
    setSelectedTab(data);
  });

  const handleOrgChange = useCallback(({ target }) => {
    const { name , value } = target;
    setData({...data,[name]: value, service_provider: ""});
    dispatch(fetchServiceProvider(value?.value));
    setSelectedServiceProvider("");
  });

  const handleSPChange = useCallback(({ target }) => {
    const { name , value } = target;
    setData({...data,[name]: value});
    setSelectedServiceProvider({...value, isSelected: true});
  });


  useEffect(()=>{
    setData(filterData || intialData);
    setSelectedTab(filterData?.availabilities ? tabs[1] : tabs[0])
    if(filterData?.availabilities) {
      setSelectedServiceProvider(filterData?.service_provider);
    }
  },[filterData]);

  const { organization="" } = data;

  return (
    <Modal className="availability-form">
      <Div  width="100vw" height="70vh" position="relative">
        <Div>
          <CardHeader px={3}>
            <Div display="flex" alignSelf="center" justifyContent="space-between" width={1}>
              <MDLabel fontSize="18px">
                <FormattedMessage id="header_filter" defaultMessage="Filter" />
              </MDLabel>
              <MDImage src={CrossCircle} alt="icon" onClick={handleClose}/>
            </Div>
          </CardHeader>
          <Div p={3}>
            <Tabs
              tabs={tabs}
              selectedTab={selectedTab}
              onClick={handleTabs}
            />
            <>
              <MDLabel fontSize="20px" mr={15} my={15} ml={0} alignSelf="left">
                <FormattedMessage id="label_organisation" defaultMessage="Organisation" />:
              </MDLabel>
              <SelectBox
                value={organization}
                onChange={handleOrgChange}
                name="organization"
                options={allOrganisations}
                placeholder=""
                width="100%"
              />
              {organization && organization.access === Constants.MANAGE && (
                <React.Fragment>
                  <MDLabel fontSize="20px" mr={15} my={15} ml={[0, 15]} alignSelf="left">
                    <FormattedMessage id="common_service_provider" defaultMessage="Service provider" />:
                  </MDLabel>
                  <SelectBox
                    value={selectedServiceProvider}
                    onChange={handleSPChange}
                    name="service_provider"
                    options={allServiceProviders}
                    placeholder={<FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />}
                    width="100%"
                  />
                </React.Fragment>
              )}
            </>
          </Div>
        </Div>
        <Div position="absolute" bottom={0} width={1} p={3}>
          <Div mb={3}>
            <Button
              width={1}
              primary
              label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={handleApplyFilter(data)}
            />
          </Div>
          <Button
            width={1}
            label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
            secondary
            onClick={handleClose}
          />
        </Div>
      </Div>
    </Modal>
  );
};

Filter.propTypes = {
  handleApplyFilter : PropTypes.func,
  handleClose: PropTypes.func,
  filterData: PropTypes.node,
  allOrgs: PropTypes.node,
  allServiceProviders: PropTypes.node,
};

Filter.defaultProps = {
  handleApplyFilter : ()=>{},
  handleClose: ()=> {},
  filterData: {},
  allOrgs: [],
  allServiceProviders: [],
};

export default Filter;
