import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateUserForm from "./Components/CreateServiceForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import Service from "../../services/ServicesPageService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import { checkRightPermission } from "../../shared/utils";

const { icons, language } = Constants;
class EditService extends Component {
  service = new Service(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const { userDetails = {} } = this.props;
    const userData = userDetails.user_access
      ? userDetails : localStorage.userData ? JSON.parse(localStorage.userData) : {};

    const { permissions = [] } = userData;
    const hasWriteAccess= checkRightPermission(permissions,"is_services_editable",Constants.WRITE);

    this.state = {
      hasWriteAccess,
      loading: false,
      serviceData: {},
      isDeleteModalOpen: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
    };
  }

  componentDidMount=() => {
    this.fetchDetails();
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  fetchDetails=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.service.getServiceDetails(id);
      window.scrollTo(0,0);
      this.setState({ loading: false, serviceData: response });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleSubmitData= (isSaved = false) => {
    if (isSaved) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_service_updated,
      }, this.fetchDetails);
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  handleDeleteService=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.service.deleteService(id);
      if (response) {
        this.getDetails();
        this.setState({
          isDeleteModalOpen: false,
          changesSaved: true,
          alertMessage: language.service_deleted_alert_message,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  /**
   * Opens a delete confirmation popup
   */
  handleDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  handleRedirectToServices = () => {
    const { history } = this.props;
    history.goBack();
  };

  /**
   * Cancels the service deletion operation
   */
  handleCancel = () => {
    this.setState({
      changesSaved: false,
      isDeleteModalOpen: false,
      alertMessage: "",
      alertHeader: "",
    });
  };

  handleLoader=() => {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  handleBackToServices = () => {
    const { alertMessage } = this.state;
    if (alertMessage === language.service_deleted_alert_message) {
      const { history } = this.props;
      history.push(Constants.routes.servicesList.url);
    } else {
      this.handleCancel();
    }
  };

  render() {
    const {
      loading,
      serviceData = {}, hasWriteAccess, isDeleteModalOpen, changesSaved,
      alertMessage, alertHeader,
    } = this.state;
    const { data: { name = "" } = {} } = serviceData;
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    return (
      <Container>
        <FormWrapper>
          <Div display="flex">
            <MDLabel cursor="pointer" onClick={this.handleRedirectToServices}>
              <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
              <FormattedMessage id="common_back" defaultMessage="Back" />
            </MDLabel>
          </Div>
          {loading && <FormLoader />}
          {!loading && (
            <>
              <Header className="py-2">{name}</Header>
              <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
                <FormattedMessage id="service_add_service_description" />
              </MDLabel>
              <CreateUserForm
                edit
                userId={id}
                serviceData={serviceData}
                submitData={this.handleSubmitData}
                handleLoader={this.handleLoader}
                deleteService={this.handleDeleteModal}
                disableData={!hasWriteAccess}
                handleBackToServices={this.handleSubmitData}
              />
            </>
          )}
        </FormWrapper>
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => {
            this.openElement = openModal;
          }}
        />
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="service_delete_service" defaultMessage="Delete service" />}
              message={<FormattedMessage id="service_deleted_confirmation_message" defaultMessage="Are you sure you want to delete service?" />}
              buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteService}
              onCancel={this.handleCancel}
              onLoader={loading}
            />
          </Modal>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToServices}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
EditService.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
  getLoginUser: PropTypes.func,
};
EditService.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
  getLoginUser: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(EditService);
