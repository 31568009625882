import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import Button from "../../../components/Button/Button";
import { MDImg, Division, BoxDivision } from "../BookingFlowStyles/BookingFlowStyles";
import {
  MDLabel, Span, Box, MDImage, Div,
} from "../../../styles/Styles";

const BookingFlowCards = ({
  dogFace,
  title,
  timeIcon,
  time,
  tag,
  tagIcon,
  chooseId,
  uidChecked,
  buttonOuter,
  chooseServiceHandler,
  category,
  id,
  imgWidth,
  service,
}) => {
  const selectedServices = {
    banner: dogFace,
    serviceId: chooseId,
    categoryId: id,
    serviceName: title,
    serviceTime: time,
    servicePrice: tag,
    categoryName: category,
    service,
    is_full_day_service: service?.is_full_day_service,
  };
  const isFullDayService = service?.is_full_day_service === 1;
  return (
    <Division
      background={Colors.White}
      boxShadow={Theme.shadows.light}
      borderRadius={Theme.radii.medium}
      mx={3}
      type="booking_Cards"
    >
      <MDImg src={dogFace} type="roundImg" alt="icon" width={imgWidth} height={125} />
      <MDLabel width={Theme.widths.full} justifyContent="center" mb={10} mt={2}>
        {title}
      </MDLabel>
      <Box mb={2}>
        <MDLabel>
          <MDImage src={timeIcon} alt="icon" />
          {isFullDayService ?
            (
              <MDLabel color={Colors.DarkGrey} mx={1}>
                <FormattedMessage id="label_day" defaultMessage="Day" />
              </MDLabel>
            )
            : (<MDLabel>
              <MDLabel color={Colors.DarkGrey} mx={1}>
                <FormattedMessage id="common_from" defaultMessage="from" />
              </MDLabel>
              {`${time}`}
              <Div pl={1}>
                <FormattedMessage id="min" defaultMessage="min" />
              </Div>
            </MDLabel>)}
        </MDLabel>
      </Box>
      <Box>
        <MDLabel>
          <MDImage src={tagIcon} alt="icon" />
          <Span
            fontSize={Theme.fontSizes.small}
            fontFamily={Theme.fonts.semibold}
            fontWeight={Theme.fontWeights.medium}
            color={Colors.Black}
          >
            <Span
              fontSize={Theme.fontSizes.small}
              fontFamily={Theme.fonts.semibold}
              fontWeight={Theme.fontWeights.medium}
              color={Colors.DarkGrey}
              mx={1}
            >
              <FormattedMessage id="common_from" defaultMessage="from" />
            </Span>
            <FormattedMessage id="common_sek" defaultMessage="SEK" />
            <Span ml={1}>{tag}</Span>
          </Span>
        </MDLabel>
      </Box>
      <BoxDivision pt={3} pb={10} className={buttonOuter}>
        <Button
          className={uidChecked === chooseId ? "active" : ""}
          width="140px"
          secondary
          label={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
          color={uidChecked === chooseId ? Colors.White : Colors.Green}
          onClick={chooseServiceHandler(selectedServices)}
        />
      </BoxDivision>
    </Division>
  );
};
BookingFlowCards.propTypes = {
  dogFace: PropTypes.string,
  title: PropTypes.string,
  timeIcon: PropTypes.string,
  time: PropTypes.string,
  tag: PropTypes.string,
  tagIcon: PropTypes.string,
  chooseId: PropTypes.string,
  uidChecked: PropTypes.string,
  buttonOuter: PropTypes.string,
  chooseServiceHandler: PropTypes.func,
  category: PropTypes.string,
  id: PropTypes.string,
  imgWidth: PropTypes.string,
  service: PropTypes.string,
};
BookingFlowCards.defaultProps = {
  dogFace: "",
  title: "",
  timeIcon: "",
  time: "",
  tag: "",
  tagIcon: "",
  chooseId: "",
  uidChecked: "",
  buttonOuter: "",
  chooseServiceHandler: () => {},
  category: "",
  id: "",
  imgWidth: "",
  service: {},
};
export default memo(BookingFlowCards);
