import React, { useEffect, useState } from "react";
import { CardHeader, Div, MDLabel, P } from "../../../styles/Styles";
import { CardWrapper } from "../../Services/Styles";
import PropTypes from "prop-types";
import Constants from "../../../shared/Constants";
import { FormattedMessage } from "react-intl";
import ProfileCard from "../../../components/Card/ProfileCard";
import Button from "../../../components/Button/Button";
import Calendar from "react-calendar";
import { CalendarWrapper } from "../../Assignments/Styles";

const Modals = ({ data, name, values, onCancel, handleSubmit, minDate }) => {
  const {
    subscriptionHeaderTranslations: subscriptionHeader,
    icons,
    translations: SubHeader,
    input: { name: inputs },
  } = Constants;
  const [cardData, setCardData] = useState([]);
  const mediumModal = [inputs.endDate, inputs.startDate];
  const mediumSize = mediumModal.includes(name);
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    let showData = [];
    const prevData = values[name];
    showData = data.map((obj) => {
      if (prevData && obj.value === prevData.value) {
        return { ...obj, isSelected: true };
      }
      return obj;
    });
    setCardData(showData);

    if (name === "start_date" || name === "end_date") {
      if (prevData) {
        setCalendarValue(new Date(prevData));
      } else if (minDate) {
        setCalendarValue(new Date(minDate));
      }
    }
  }, [data]);

  const handleSave = () => {
    if ((name === "start_date" || name === "end_date") && calendarValue) {
      handleSubmit(name, calendarValue);
      return;
    }
    let saveData = { ...selectedData, isSelected: false };
    handleSubmit(name, saveData);
  };

  const handleOnChangeCalendar = async (e) => {
    setCalendarValue(e);
  };

  const handleSelectData = (value) => {
    setSelectedData(value);
    const objData = cardData.map((o) => {
      return { ...o, isSelected: o.value === value.value };
    });
    setCardData(objData);
  };

  return (
    <CardWrapper width={[1, 1, mediumSize ? 410 : 650]}>
      <CardHeader className="position-sticky">
        <MDLabel alignSelf="center" fontSize="24px">
          {subscriptionHeader[name]}
        </MDLabel>
      </CardHeader>
      <MDLabel p={3} justifyContent="center" fontSize="18px">
        {SubHeader[name]}
      </MDLabel>
      <Div>
        {(name === inputs.startDate || name === inputs.endDate) && (
          <Div display="flex" alignItems="center" justifyContent="center">
            <CalendarWrapper>
              <Calendar
                onChange={handleOnChangeCalendar}
                value={calendarValue}
                next2Label={null}
                prev2Label={null}
                minDate={minDate ? new Date(minDate) : null}
              />
            </CalendarWrapper>
          </Div>
        )}
        {name === inputs.place && (
          <>
            {!cardData.length ? (
              <Div p={3} display="flex" justifyContent="center">
                <FormattedMessage id="no_data_found" />
              </Div>
            ) : (
              <Div maxHeight="410px" className="overflow-auto" display="contents">
                <Div p={3}>
                  <P m={0}>
                    {cardData.map((obj) => (
                      <Div key={obj.id} display="inline-flex" className="col-lg-auto col-md-12 p-0">
                        <Div className={"m-2 'd-flex w-100'"}>
                          <ProfileCard
                            data={obj}
                            width={255}
                            height={64}
                            onClick={handleSelectData}
                            userAvtar={obj.profilePic || ""}
                          />
                        </Div>
                      </Div>
                    ))}
                  </P>
                </Div>
              </Div>
            )}
          </>
        )}

        <Div my={3} mx={3} display="flex" justifyContent="center" className="position-sticky">
          <Button label={<FormattedMessage id="common_cancel" />} secondary className="mr-3" onClick={onCancel} />
          <Button
            className="ml-3"
            onClick={handleSave}
            label={<FormattedMessage id="common_save" />}
            startIcon={icons.AssignmentsMenuIcon}
            primary
          />
        </Div>
      </Div>
    </CardWrapper>
  );
};

Modals.propTypes = {
  data: PropTypes.string,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  handleSubmit: PropTypes.func,
  values: PropTypes.node,
  minDate: PropTypes.string,
};

Modals.defaultProps = {
  data: [],
  onCancel: undefined,
  name: "",
  handleSubmit: undefined,
  values: {},
};

export default Modals;
