import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import PortalContainer from "../../components/Containers/PortalContainer";
import Button from "../../components/Button/Button";
import FilterButton from "../../components/Filter/FilterButton";
import { Div, Header } from "../../styles/Styles";
import ListScreen from "../../components/ListScreen/ListScreen";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Pagination from "../../components/Pagination/Pagination";
import Constants from "../../shared/Constants";
import { getAssignmentList } from "../../services/AssignmentService";
import MainLoader from "../../components/Loader/MainLoader";
import { IsDesktop, IsMobile } from "../../components/ListScreen/Styles";
import SortButton from "../../components/Sorting/Sorting";
import { getFilters } from "../../store/actions/FilterAction";
import { checkRightPermission } from "../../shared/utils";
import SearchBox from "../../components/Input/SearchBox";

const {
  sorting: { type: sortType, keys: { sortBy, sort } },
  filter: { components, options: optionType },
  routes,
  language,
  themes: { widths },
  adminRoutes,
} = Constants;

const filterColumns = [
  {
    id: "status",
    component: components.CHECKBOX,
    label: language.filter_label_status,
    values: optionType.ORDER_STATUS,
    defaultValues: [],
    name: "status",
  },
  {
    id: "services",
    component: components.CHECKBOX,
    label: language.common_service,
    values: [],
    defaultValues: [],
    name: "services",
  },
  {
    id: "date",
    component: components.DATEPICKER,
    label: language.filter_date_diapason,
    fromDate: { defaultDate: "" },
    toDate: { defaultDate: "" },
    name: "date_time",
  },
];

const Assignments = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const allServices = useSelector((state) => state.FilterReducer.allServices);
  const [sortAscending, setSortAscending] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllFilters = () => dispatch(getFilters());
  const search = location.search;
  const params = new URLSearchParams(search);
  const pageNo = params.get("page");
  const searchWord = params.get("search");
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: pageNo || 1,
    pageSize: 10,
    pages: 1,
  });
  const { key } = location;
  const [sortedBy, setSortedBy] = useState("created_at");
  const userInfo = userDetails.user_access
    ? userDetails
    : localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
  const { permissions = [] } = userInfo;
  const hasWriteAccess = checkRightPermission(permissions,"is_assignments_editable",Constants.WRITE);

  const [toBeFilteredData, setToBeFilteredData] = useState({});
  const [filterOptions, setFilterOptions] = useState(filterColumns);
  const assignmentFilterValues = JSON.parse(params.get("filter"));
  const [filterValues, setFilterValues] = useState(assignmentFilterValues || {});
  const [showSortModal, setShowSortModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchWord || "");
  const header = [
    {
      className: "col-3",
      value: language.filter_label_status,
      key: "status",
    },
    {
      className: "col",
      value: language.common_service_provider,
      key: "service_provider",
    },
    {
      className: "col",
      value: language.common_room,
      key: "room",
    },
    {
      className: "col",
      value: language.common_service,
      key: "services",
    },
    {
      className: "col",
      value: language.header_date_time,
      key: "assignment_datetime",
      icon: sortType.ARROW,
    },
    {
      className: "col",
      value: language.label_organisation,
      key: "organization",
    },
    {
      className: "col",
      value: language.filter_label_customer,
      key: "customer",
    },
  ];
  const value = [
    {
      className: "col-3",
      key: "status",
      type: "label",
      group: false,
    },
    {
      className: "col",
      key: "serviceProvider",
      type: "singleUser",
      group: false,
    },
    {
      className: "col",
      key: "room",
      type: "singleUser",
      group: false,
    },
    {
      className: "col",
      key: "service",
      type: "singleUser",
      group: false,
    },
    {
      className: "col",
      key: "startDateTime",
      type: "date",
      group: false,
    },
    {
      className: "col",
      key: "organization",
      type: "singleUser",
      group: true,
      isOrg: true,
    },
    {
      className: "col",
      key: "customer",
      type: "singleUser",
      group: false,
      isDesktop: true,
    },
  ];

  const handleRedirectToOrg = (id) => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.organization}/${id}/${adminRoutes.editOrganization}`);
  };

  const fetchFilters = async () => {
    await getAllFilters();
  };

  /**
   *
   * @async
   * @function fetchAssignments Fetches assignments
   * @param {String} searchInput search text
   *
   */
  const fetchAssignments = (searchInput = false, filters = undefined, searchWord= "") => {
    setLoading(true);
    const filter = filters || toBeFilteredData;
    const queryParams = [
      ["pageNumber", searchInput ? 1 : meta.pageNumber],
      ["pageSize", meta.pageSize],
      [sortBy, sortedBy],
      [sort, sortAscending ? sortType.ASC : sortType.DESC],
      ["filter", filter],
      ["search_name",searchWord],
    ];
    getAssignmentList(queryParams).then(
      (response) => {
        const { meta: srcMeta = {}, data = {} } = response;
        const {
          current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
        } = srcMeta;
        const newMeta = {
          pageNumber: currentPage,
          pageSize: perPage,
          pages,
          totalCount,
        };
        const responseData = data.map((order) => ({ ...order, startDateTime: moment.utc(order.startDateTime).local(), name: order?.customer?.name || "" }));
        setOrders(responseData);
        window.scrollTo(0,0);
        setMeta(newMeta);
        setLoading(false);
      },
      (error) => {
        console.error(error); // Error!
        setLoading(false);
      },
    );
  };

  /**
   *
   * Calls fetchAssignments function when the below values changes
   * [loading, meta.pageNumber,location, sortAscending, sortedBy]
   *
   */

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    if (allServices.length > 0) {
      const filter = filterOptions;
      filter[1].values = allServices;
      setFilterOptions(filter);
    }
  }, [allServices]);

  useEffect(() => {
    const { filter = {}, options = filterOptions } = filterValues;
    setFilterOptions(options);
    fetchAssignments(false, filter, searchQuery);
  }, [sortAscending, sortedBy, key]);

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePagination = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 10;
    setMeta(meta);
    fetchAssignments(false, toBeFilteredData, searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  /**
   *
   * @function handleAddAssignment
   * Redirects to create new room page
   *
   */
  const handleAddAssignment = () => {
    history.push(routes.addAssignment.url);
  };

  const handleSearchOnChange = useCallback((e) => {
    const { target: { value: searchValue } }= e;
    setSearchQuery(searchValue);
  });

  const handleApplySearch = useCallback((isEmpty = false) => {
    setMeta({...meta, pageNumber: 1});
    fetchAssignments(true, toBeFilteredData, isEmpty ? "" : searchQuery);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("search", searchQuery);
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  });

  const handleClearSearch = useCallback(() => {
    setSearchQuery("");
    setMeta({...meta, pageNumber: 1});
    fetchAssignments(true, toBeFilteredData, "");
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.delete("search");
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  });

  /**
   *
   * Redirects to detailed view page
   * @param {object} item assignment data
   * @returns to selected assignment's detailed page
   *
   */
  const handleDetailPage = (item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${item.id}/${adminRoutes.editAssignment}`);
  };

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterApply = async (filterValues = [], options = undefined) => {
    const filters = {};
    filterValues.map((obj) => {
      if (filters[obj.name]) {
        filters[obj.name].push(obj.value);
      } else {
        filters[obj.name] = [];
        filters[obj.name].push(obj.value);
      }
      return obj;
    });
    setToBeFilteredData(filters);
    setFilterOptions(options);
    setIsFilterOpen(false);
    setFilterValues({
      filter: filters,
      values: filterValues,
      options,
    });
    setMeta({...meta, pageNumber: 1});
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("filter", JSON.stringify({ filter: filters, options }));
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    fetchAssignments(true, filters,searchQuery);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSorting = (name) => () => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };

  const handleSortModal = () => {
    setShowSortModal(!showSortModal);
  };

  const handleApplySorting = (sortKey = "", sortOrder = false) => {
    setSortAscending(sortOrder);
    setSortedBy(sortKey);
    setShowSortModal(false);
  };

  return (
    <PortalContainer>
      <Div justifyContent="space-between" display="flex" pb={4} flexDirection={["column","column","row","row"]}>
        <Div>
          <Header display="inline-block">
            <FormattedMessage id="common_assignments" defaultMessage="Assignments" />
          </Header>
        </Div>
        <Div display="flex" alignItems="center" >
          <IsDesktop>
            <SearchBox
              name="searchQuery"
              value={searchQuery}
              onChange={handleSearchOnChange}
              onSearch = {handleApplySearch}
              onClear= {handleClearSearch}
            />
          </IsDesktop>
          <IsDesktop>
            <Div className="row m-0">
              {hasWriteAccess && (
                <Button
                  label={<FormattedMessage id="common_add_new" defaultMessage="Add new" />}
                  primary
                  startIcon={PlusIcon}
                  width={widths.w175}
                  onClick={handleAddAssignment}
                  padding="10px !important"
                />
              )}
              <FilterButton
                open={isFilterOpen}
                options={filterOptions}
                closeFilter={handleCloseFilter}
                onApply={handleFilterApply}
              />
            </Div>
          </IsDesktop>
        </Div>
      </Div>
      <IsMobile>
        <Div display="flex" justifyContent="space-between" pb={4}>
          {hasWriteAccess && (
            <Div>
              <Button
                label={<FormattedMessage id="common_add_new" defaultMessage="Add new" />}
                primary
                startIcon={PlusIcon}
                width={widths.w175}
                onClick={handleAddAssignment}
                padding="10px !important"
              />
            </Div>
          )}
          <Div display="flex">
            <SortButton
              isOpen={showSortModal}
              sortingKeys={header}
              sortedBy={sortedBy}
              sortAscending={sortAscending}
              onClose={handleSortModal}
              onSubmit={handleApplySorting}
            />
            <FilterButton
              open={isFilterOpen}
              options={filterOptions}
              closeFilter={handleCloseFilter}
              onApply={handleFilterApply}
            />
          </Div>
        </Div>
      </IsMobile>
      <IsMobile pb={20}>
        <SearchBox
          name ="searchQuery"
          value={searchQuery}
          onChange={handleSearchOnChange}
          onSearch={handleApplySearch}
          onClear={handleClearSearch}
        />
      </IsMobile>
      {loading && <MainLoader type="list" />}
      {!loading && (
        <>
          <ListScreen
            moreButton
            header={header}
            value={value}
            data={orders}
            isOrders
            onClick={handleDetailPage}
            handleSorting={handleSorting}
            redirectToOrg={handleRedirectToOrg}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            dataNotFoundMessage={<FormattedMessage id="assignments_not_found" defaultMessage="No assignments found" />}
          />
          {meta.pages > 1 && (
            <Div py={4} display={["block", "flex", "flex"]} >
              <Div display={["flex"]} justifyContent="center" alignItems="center">
                {`${orders.length < 10 ? orders.length + ((meta.pageNumber - 1) * 10) : orders.length * meta.pageNumber} / ${meta.totalCount}`}
              </Div>
              <Div width="90%" justifyContent="center">
                <Pagination gotoPage={handlePagination} cursor={meta} />
              </Div>
            </Div>
          )}
        </>
      )}
    </PortalContainer>
  );
};

export default Assignments;
