import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  MDLabelWrapper, Div, IsMobile, CardHeader, MDLabel, MDImage,
} from "../../styles/Styles";
import { CardWrapper, Row } from "../Filter/Styles";
import Constants from "../../shared/Constants";
import { SortIcon } from "../../styles/Icons";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

const { themes: { colors, fonts, fontSizes }, icons, sorting: { type: sortType } } = Constants;

export const Sorting = ({
  isOpen, onClose, sortingKeys, onSubmit, sortedBy, sortAscending,
}) => {
  const [sortBy, setSortBy] = useState(sortedBy === "created_at" ? "" : sortedBy);
  const [sort, setSort] = useState(sortAscending);

  const updateSortingFields = (key = "") => () => {
    if (sortBy === key) {
      setSort(!sort);
    } else {
      setSortBy(key);
      setSort(true);
    }
  };

  const clearSorting = () => {
    setSort(false);
    setSortBy("");
  };

  const handleSorting = () => {
    onSubmit(sortBy, sort);
  };

  const getSortIcon = (sortValue, itemValue) => {
    if (sortValue === sortType.ALPHABETS) {
      if (sortBy === itemValue && sort) {
        return icons.AZSortIcon;
      }
      if (sortBy === itemValue && !sort) {
        return icons.ZASortIcon;
      }
      return icons.AlphabetSort;
    }
    if (sortValue === sortType.ARROW) {
      if (sortBy === itemValue && sort) {
        return icons.SortDownArrow;
      }
      if (sortBy === itemValue && !sort) {
        return icons.SortUpArrow;
      }
      return icons.SortIcon;
    }
    return icons.SortIcon;
  };

  return (
    <IsMobile position="relative" width="40px" ml={3}>
      <MDLabelWrapper height="40px" width="40px" backgroundColor={colors.white} onClick={onClose}>
        <SortIcon />
      </MDLabelWrapper>
      {isOpen && (
        <Modal>
          <CardWrapper>
            <CardHeader justifyContent="space-between !important" px={3}>
              <MDLabel alignSelf="center" fontSize="18px">
                <FormattedMessage id="header_sorting" defaultMessage="Sorting" />
              </MDLabel>
              <MDImage
                src={icons.CrossCircle}
                alt="icon"
                onClick={onClose}
                alignSelf="center"
                height="21px"
                width="18px"
              />
            </CardHeader>
            <Div fontFamily={fonts.semibold}>
              {sortingKeys.map((obj) => {
                const { icon = "", key = "", value = "" } = obj;
                if (icon) {
                  return (
                    <Row
                      flex
                      onClick={updateSortingFields(key)}
                      disable={sortBy ? sortBy !== key : false}
                    >
                      <Div className="col-auto " p={0}>
                        <MDLabel
                          color={sortBy === key
                            ? colors.primary : colors.black}
                          fontSize={fontSizes.regular}
                        >
                          {value}
                        </MDLabel>
                      </Div>
                      <Div className="col" p={0}>
                        <MDLabel float="right">
                          <MDImage src={getSortIcon(icon, key)} />
                        </MDLabel>
                      </Div>
                    </Row>
                  );
                }
                return "";
              })}
            </Div>
            <Div>
              <Div className="row m-0">
                <Button
                  primary
                  label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
                  className="mx-4 my-3"
                  width="100%"
                  startIcon={icons.AssignmentsMenuIcon}
                  onClick={handleSorting}
                />
              </Div>
              <Div className="row mb-4 mx-4" justifyContent="space-between">
                <Button
                  secondary
                  label={<FormattedMessage id="sort_clear" defaultMessage="Clear" />}
                  className="mr-2"
                  startIcon={icons.EraseIcon}
                  width="50%"
                  onClick={clearSorting}
                />
                <Button
                  secondary
                  label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                  className="ml-2"
                  width="45%"
                  onClick={onClose}
                />
              </Div>
            </Div>
          </CardWrapper>
        </Modal>
      )}
      <a
        href
        data-toggle="modal"
        data-target="#Modal"
      />
    </IsMobile>
  );
};
Sorting.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  sortingKeys: PropTypes.node,
  onSubmit: PropTypes.func,
  sortedBy: PropTypes.string,
  sortAscending: PropTypes.bool,
};
Sorting.defaultProps = {
  isOpen: false,
  onClose: () => {},
  sortingKeys: [],
  onSubmit: () => {},
  sortedBy: "",
  sortAscending: false,
};
export default Sorting;
