import Constants from "../shared/Constants";

/**
 * Handles user profile serialization.
 */

const {
  input: {
    name: { labelForNoAccess },
  },
  userRights,
  language,
} = Constants;
class UserProfile {
  fromUserProfileJson = (obj) => {
    const {
      email = "",
      full_name: fullName,
      first_name: firstName = "",
      last_name: lastName = "",
      description = "",
      minimum_preparation_time: bookingNoticeTime = "",
      organization: { organization_details: orgDetails = [" "] },
      phone_number: phoneNumbers = [""],
      type = "",
      organization_number: organizationNumber = "",
      user_access: userAccessRights = {},
      profile_pic: profilePic = "",
      services: selectedServices = [],
      new_assignments: openOrders = 0,
    } = obj;
    const organisations =
      orgDetails && orgDetails.length > 0
        ? orgDetails.map((org) => ({ label: org.name, value: org.id, id: org.id }))
        : [""];
    const phone = phoneNumbers && phoneNumbers.length ? phoneNumbers : [""];
    const data = {
      fullName: fullName || "",
      firstName,
      lastName,
      description,
      email,
      bookingNoticeTime,
      id: organizationNumber,
      role: type,
      profilePic,
      openOrders,
      phone,
      serviceProviderOrgs: organisations,
    };
    const userAccess = userAccessRights.map((right) => {
      const {
        organization_id: orgId = "",
        is_assignments_editable: assignment = labelForNoAccess,
        is_calendar_editable: calender = labelForNoAccess,
        is_customer_editable: customer = labelForNoAccess,
        is_dog_editable: dog = labelForNoAccess,
        is_order_editable: order = labelForNoAccess,
        is_organization_editable: organisation = labelForNoAccess,
        is_room_editable: room = labelForNoAccess,
        is_users_editable: users = labelForNoAccess,
        is_services_editable: services = labelForNoAccess,
        is_subscription_editable: subscription = labelForNoAccess,
        is_customized: isCustomised,
        is_order_deletable = 1,
        rights,
      } = right;
      const userData = {
        organisation_id: orgId,
        calendar: userRights[calender],
        room: userRights[room],
        order: is_order_deletable === 2 ? { label: language.common_delete, value: "delete" } : userRights[order],
        dog: userRights[dog],
        customer: userRights[customer],
        services: userRights[services],
        users: userRights[users],
        assignment: userRights[assignment],
        organisation: userRights[organisation],
        subscription: userRights[subscription],
        rights: userRights[rights],
      };
      if (isCustomised) {
        userData.rights.label = userData.rights.label.includes(language.common_customize)
          ? userData.rights.label
          : `${userData.rights.label}/${language.common_customize}`;
      }
      return userData;
    });
    return {
      data,
      userAccess,
      selectedServices,
    };
  };

  /**
   * Returns the user Details payload of JSON type.
   *
   * @method
   */
  toJson = (obj) => {
    const {
      profilePic: { id: profilePic = "" } = {},
      phone = "",
      firstName = "",
      lastName = "",
      email = "",
      description,
      bookingNoticeTime = "",
      role: { value: role = "" } = {},
      id,
      service_list: servicesList = "",
    } = obj;
    const data = {
      profile_pic_id: profilePic,
      phone_number_list: phone,
      first_name: firstName,
      last_name: lastName,
      description,
      email,
      minimum_preparation_time: bookingNoticeTime,
      role,
      organization_number: id && id.replace("-", ""),
      service_list: servicesList,
    };
    let userAccess = [];
    userAccess = obj.userAccess
      .map((access) => {
        const {
          organisation_id: { value: organisationId = "" },
          calendar: { value: calender = labelForNoAccess },
          room: { value: room = labelForNoAccess },
          rights: { value: rightValue = "", label: rightLabel = "" },
          order: { value: order = labelForNoAccess },
          dog: { value: dog = labelForNoAccess },
          users: { value: users = labelForNoAccess },
          assignment: { value: assignment = labelForNoAccess },
          services: { value: services = labelForNoAccess },
          customer: { value: customer = labelForNoAccess },
          organisation: { value: organisation = labelForNoAccess },
          subscription: { value: subscription = labelForNoAccess },
        } = access;
        if (organisationId) {
          return {
            organization_id: organisationId,
            is_calendar_editable: userRights[calender],
            is_room_editable: userRights[room],
            is_order_editable: userRights[order] || order === "delete",
            is_dog_editable: userRights[dog],
            is_customer_editable: userRights[customer],
            is_users_editable: userRights[users],
            is_organization_editable: userRights[organisation],
            is_assignments_editable: userRights[assignment],
            is_services_editable: userRights[services],
            is_customized: rightLabel.includes(language.common_customize),
            is_subscription_editable: userRights[subscription],
            rights: rightValue,
            is_order_deletable: order === "delete" ? 2 : 1,
          };
        }
        return {};
      })
      .filter((fltr) => fltr && Object.keys(fltr).length > 0);
    data.user_access_list = userAccess;
    data.organization_linked = obj.organization_linked.filter((orgData) => orgData);
    return data;
  };
}
export default UserProfile;
