import React, { Component } from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import {
  Div, MDImage, MDInputValue, MDLabel,
} from "../../styles/Styles";
import { DropDownListItem, DropDownWrapper } from "./Styles";
import { ArrowDown } from "../../styles/Icons";

class SelectBox extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props;
    this.state = {
      isDropdownOpened: false,
      selectedOption: {},
    };
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  /**
   * Adding an event listener to check click event.
   */
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps) {
    let { options: currentOptions = [] } = this.props;
    if (currentOptions && prevProps.options !== currentOptions) {
      let selectedValue = "";
      currentOptions = currentOptions
        ? currentOptions.map((obj) => {
          let option = obj;
          option = {
            ...obj,
            isSelected: obj.isSelected ? (selectedValue = obj.value) : false,
          };
          return option;
        })
        : [];
      this.onOptionsUpdate(selectedValue, currentOptions);
    }
  }

  /**
   * Removing added event listener.
   */
  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  /**
   * Closes the dropdown on clicking outside the component.
   * @param {event} e captures onclick event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { id } = this.props;
    const { isDropdownOpened } = this.state;
    if (!this.selectRef[id].contains(e.target) && isDropdownOpened) {
      this.handleDropDown();
    }
  }

  /**
   * Handles open and close action of dropdown
   */
  handleDropDown = () => {
    const { isDropdownOpened, selectedOption } = this.state;
    const { onBlur, name } = this.props;
    if (onBlur && isDropdownOpened) {
      onBlur({ target: { name, value: selectedOption } });
    }
    this.setState({ isDropdownOpened: !isDropdownOpened });
  };

  /**
   * Updates the selected value and options.
   * @param {*} selectedValue selected options
   * @param {object} options Options
   */
  onOptionsUpdate = (selectedValue, options) => {
    const { value } = this.props;
    const selectedLabel = options.find((o) =>  o.value === (selectedValue || value));
    const option = selectedLabel || {};
    this.setState({
      options,
      selectedOption: option,
    });
  };

  /**
   * Handles selected option
   * @param {object} option
   */
  handleSelectOption = (option) => {
    let { options } = this.state;
    const { onChange, name } = this.props;
    options = options
      && options.map((obj) => {
        if (option.value === obj.value) {
          return { ...obj, isSelected: true };
        }
        return { ...obj, isSelected: false };
      });
    this.setState(
      {
        options, selectedOption: option,
      },
      this.handleDropDown,
    );
    if (onChange) {
      const event = { target: { name, value: option } };
      onChange(event);
    }
  };

  getOptionLabel = () => {
    if(this.state.selectedOption?.label){
      return <MDInputValue>{this.state.selectedOption?.label}</MDInputValue>
    }
    if(this.props?.value?.label){
      return <MDInputValue>{this.props.value?.label}</MDInputValue>
    }
    return <MDInputValue>{this.props?.placeholder}</MDInputValue>
  };

  render() {
    const {
      startIcon,
      id,
      IconHeight,
      formikValues = {},
      name,
      label,
      disabled,
      className,
      width,
      options,
      showError,
      isTouched,
      endIcon,
      dropdownPosition,
    } = this.props;
    const { isDropdownOpened, selectedOption } = this.state;
    const { errors = {}, touched = {} } = formikValues;
    const displayError = (errors[name] && touched[name]) || (showError && isTouched);
    return (
      <Div
        width={width}
        disable={disabled}
        ref={(selectRef) => {
          this.selectRef[id] = selectRef;
        }}
        position="relative"
        className={disabled ? "disabled-state" : ""}
      >
        <Div className={(label) ? "pb-2 pt-3" : ""}>
          {label && (
            <MDLabel fontSize="16px" display="initial">
              {label}
            </MDLabel>
          )}
          {displayError && (
            <MDLabel float="right" color={Colors.Red} className={!label ? "mb-3" : ""}>
              {errors[name] || showError}
            </MDLabel>
          )}
        </Div>

        <Div
          border="1px solid"
          borderColor={displayError ? Colors.Red : Colors.Border}
          display="flex"
          borderRadius={5}
          width={width}
          height="48px"
          cursor="pointer"
          className={className}
          onClick={!disabled && this.handleDropDown}
        >
          {startIcon && (
            <Div className="col-auto pl-2 pr-0" alignSelf="center" cursor="pointer">
              <MDImage
                src={startIcon}
                alt="icon"
                width="20px"
                height={IconHeight}
              />
            </Div>
          )}
          <Div
            className="col"
            width="inherit"
            px={2}
            py="15px"
            display="flex"
            alignItems="center"
            cursor="pointer"
          >
            {selectedOption.icon && (
              <Div mr="8px" display="flex" alignSelf="center" cursor="pointer">
                <MDImage
                  src={selectedOption.icon}
                  alt="icon"
                  width="11px"
                  height="20px"
                />
              </Div>
            )}
            {this.getOptionLabel()}
          </Div>
          <Div
            className="col-auto"
            alignSelf="center"
            cursor="pointer"
            px={2}
          >
            {endIcon
              ? (
                <MDImage
                  src={endIcon}
                  alt="icon"
                  width="20px"
                  height={IconHeight}
                />
              )
              : <ArrowDown className={isDropdownOpened ? "rotate-180" : ""} />}
          </Div>
        </Div>
        {isDropdownOpened && (
          <>
            <DropDownWrapper position={[dropdownPosition, "absolute"]}>
              {options.map((option) => (
                <DropDownListItem key={option.id} onClick={() => this.handleSelectOption(option)}>
                  {option.icon && (
                    <Div className="dropdown-icon" alignSelf="center" cursor="pointer">
                      <MDImage
                        src={option.icon}
                        alt="icon"
                        width="11px"
                        height="20px"
                      />
                    </Div>
                  )} <MDInputValue cursor="pointer">{option.label}</MDInputValue>
                </DropDownListItem>
              ))}
            </DropDownWrapper>
          </>
        )}
      </Div>
    );
  }
}

SelectBox.propTypes = {
  /**
   * Label of the input field
   */
  label: PropTypes.string,
  /**
   * value of the input field
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Id of the input
   */
  id: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Do input field be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Width of the input field
   */
  width: PropTypes.string,
  /**
   * What options should be shown in dropdown
   */
  options: PropTypes.node,
  /**
   * Calls when value is changed
   */
  onChange: PropTypes.func,
  /**
   * What should be as placeholder..
   */
  placeholder: PropTypes.string,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  /**
 * Url of the startIcon
 */
  startIcon: PropTypes.string,
  /**
   * Height of the icon
   */
  IconHeight: PropTypes.string,
  /**
   * Do you want to customize the styles..
   */
  className: PropTypes.string,
  onBlur: PropTypes.func,
  showError: PropTypes.string,
  isTouched: PropTypes.bool,
  endIcon: PropTypes.string,
  dropdownPosition: PropTypes.string,
};
SelectBox.defaultProps = {
  name: null,
  id: null,
  value: null,
  formikValues: {},
  onChange: undefined,
  placeholder: null,
  disabled: false,
  label: null,
  options: [],
  width: "auto",
  startIcon: null,
  IconHeight: "auto",
  className: null,
  onBlur: undefined,
  showError: "",
  isTouched: false,
  endIcon: "",
  dropdownPosition: "absolute",
};
export default SelectBox;
