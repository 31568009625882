import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
import {
  Header, Div, MDInputValue,
} from "../../styles/Styles";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import { verifyEmail } from "../../services/SignUpService";

const ConfirmCustomer = () => {
  const { language } = Constants;
  const history = useHistory();

  const handleRedirectToLogin = () => {
    history.push(Constants.routes.customerLogin.url);
  };

  useEffect(() => {
    const { location: { search = "" } = {} } = history || {};

    const params = queryString.parse(search);
    const { id = "", token = "" } = params || {};
    verifyEmail(id, token);
  }, []);

  return (
    <Div display="flex" justifyContent="center" flexDirection="column" className="min-vh-100">
      <Div className="d-lg-flex d-md-block justify-content-center" pb={4}>
        <Header justifyContent="center">
          <FormattedMessage id="confirm_register_title" defaultMessage="Registration Completed!" />
        </Header>
      </Div>

      <Div className="d-lg-flex d-md-block justify-content-center">
        <MDInputValue className="text-break" size="16" justifyContent="center">
          <FormattedMessage id="confirm_register_message" defaultMessage="Registration complete" />
        </MDInputValue>
      </Div>
      <Div className="d-lg-flex d-md-block justify-content-center">
        <MDInputValue pt={1} className=" text-break" size="16" justifyContent="center">
          <FormattedMessage id="confirm_register_message_byline" defaultMessage="Registration complete" />
        </MDInputValue>
      </Div>
      <Div display="flex" justifyContent="center" mt={4}>
        <Button
          primary
          label={language.common_back_to_login}
          width="auto"
          onClick={handleRedirectToLogin}
        />
      </Div>
    </Div>
  );
};

export default ConfirmCustomer;
