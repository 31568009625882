import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateRoomForm from "./Components/CreateRoomForm";
import Constants from "../../shared/Constants";
import RoomsModel from "../../models/Room";
import RoomService from "../../services/RoomService";
import Config from "../../config";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import { FormWrapper } from "../../styles/Styles";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const { language } = Constants;
class AddRoom extends Component {
  roomService = new RoomService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      roomCreated: false,
      alertHeader: "",
      alertMessage: "",
    };
  }

  handleSubmitData=async (data) => {
    this.setState({ loading: true });
    try {
      this.roomsModel = new RoomsModel({});
      const response = await this.roomService.addRoom(this.roomsModel.toJson(data));
      if (response) {
        this.setState({ roomCreated: true, alertMessage: language.alert_room_created });
      }
    } catch (e) {
      this.setState({
        loading: false,
        roomCreated: true,
        alertHeader: language.header_error,
        alertMessage: language.alert_try_later,
      });
    }
  }

  handleBackToRooms = () => {
    this.setState({ roomCreated: false, alertHeader: "", alertMessage: "" });
    const { history } = this.props;
    history.push(Constants.routes.roomsList.url);
  };

  render() {
    const {
      loading, roomCreated, alertHeader, alertMessage,
    } = this.state;
    return (
      <Container>
        {loading && <FormWrapper><FormLoader /></FormWrapper>}

        {!loading && (
          <>
            <FormWrapper>
              <CommonAddPage
                handleBack={this.handleBackToRooms}
                header={Constants.language.room_add_new_room}
                description="room_add_room_description"
                breadCrumbLabel="breadcrumb_back"
              />
            </FormWrapper>
            <CreateRoomForm
              submitData={this.handleSubmitData}
            />
          </>
        ) }
        {roomCreated && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToRooms}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
AddRoom.propTypes = {
  history: PropTypes.node,
};
AddRoom.defaultProps = {
  history: undefined,
};
export default AddRoom;
