import moment from "moment";
import {
  addServiceToCart,
  deleteItemFromCart,
  getCategoriesList,
  getDogDetail,
  getOrganisationsServiceProvider,
  getOrganisationsRecord,
  loadUserCartData,
  getOrganisationAvailability,
  getAvailableDates,
} from "../../services/BookingFlowServices";
import * as types from "./types";
import { isLoggedIn } from "../../shared/utils";

export const fetchChooseServicesList = () => async (dispatch) => {
  const res = await getCategoriesList();
  dispatch({
    type: types.GET_CATEGORIES_LIST,
    payload: isLoggedIn() ? res : {
      ...res,
      data: res?.data?.map((category) => ({
        ...category,
        services: category.services.filter((service) =>
          (service.price.large !== 0
          && service.price.medium !== 0
          && service.price.mini !== 0
          && service.price.small !== 0)),
      }))},
  });
};

function isServiceProviderAvailable(date, sp_id, duration, availability, assignments) {
  // Check if the availability date matches the input date
  if (date !== availability.date || sp_id !== availability.sp_id) {
    return false;
  }

  const availableStartTime = new Date(`${availability.date}T${availability.start_time}:00.000Z`);
  const availableEndTime = new Date(`${availability.date}T${availability.end_time}:00.000Z`);
  const requiredEndTime = new Date(availableStartTime.getTime() + duration * 60000);

  // Check if the service provider's availability period is sufficient for the required duration
  if (requiredEndTime > availableEndTime) {
    return false;
  }

  // Check for overlapping assignments
  for (const assignment of assignments) {
    if (assignment.service_provider_id !== sp_id.toString()) {
      continue;
    }

    const assignmentStartTime = new Date(assignment.assignment_start_datetime);
    const assignmentEndTime = new Date(assignment.assignment_end_datetime);

    // Check if the required time overlaps with any assignments
    if (
      (availableStartTime < assignmentEndTime && requiredEndTime > assignmentStartTime)
    ) {
      return false;
    }
  }

  return true;
}

export const fetchAvailableDates = (
  orgId,
  startDate,
  endDate,
  serviceId,
  dogWeight,
  isFirstTime,
  dogDuration
) => async (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: true,
  });
  dispatch({
    type: types.GET_AVAILABLE_DATES,
    payload: [],
  });
  let retries = 0;
  let foundAvailability = false;
  let firstDay = startDate;
  let lastDay = endDate;
  do {
    const {available_slot_dates: _available_slot_dates = [] , assignments} = await getAvailableDates(orgId, firstDay, lastDay, serviceId, dogWeight);
    const available_slot_dates = _available_slot_dates.filter(availability => {
      const { date, sp_id } = availability;
      return isServiceProviderAvailable(date, sp_id, dogDuration, availability, assignments);
    });
    foundAvailability = available_slot_dates.length > 0;
    if (foundAvailability) {
      dispatch({
        type: types.GET_AVAILABLE_DATES,
        payload: available_slot_dates,
      });
    } else {
      retries++;
      firstDay = moment(firstDay, "YYYY-MM-DD").add(1, "M").startOf("month").format("YYYY-MM-DD");
      lastDay = moment(firstDay, "YYYY-MM-DD").add(1, "M").endOf("month").format("YYYY-MM-DD");

    }
  } while (!foundAvailability && isFirstTime && retries < 6);
};

export const fetchDogDetails = (id, costumerId) => async (dispatch) => {
  const res = await getDogDetail(id, costumerId);
  dispatch({
    type: types.GET_DOG_DETAILS,
    payload: res,
  });
};

export const bookingFlowServices = (data) => ({
  type: types.CHOOSE_SERVICE,
  payload: data,
});

export const emptyBookingFlowValues = (data) => ({
  type: types.EMPTY_CHOOSEN_VALUES,
  payload: data,
});

export const selectDogSize = (data) => ({
  type: types.SELECTED_DOG_SIZE,
  payload: data,
});

export const getOrganisationsData = (id, dogWeight) => async (dispatch) => {
  const organizations = await getOrganisationsRecord(id, dogWeight);
  const payload = organizations.map((obj) => {
    const { has_availability: hasAvailability = "" } = obj;
    return { ...obj, isDisabled: !hasAvailability };
  }).filter(organization => organization.visible_for_customers);
  dispatch({
    type: types.SET_ORGANISATION_RECORD,
    payload: payload,
  });
};

function getUpdatedAvailability(serviceProvider, assignments) {
  return serviceProvider.available_slots.map((slot) => {
    const slotStart = new Date(`${slot.date}T${slot.start_time}Z`);
    const slotEnd = new Date(`${slot.date}T${slot.end_time}Z`);

    // Initialize availability for this slot
    let availability = [{ start: slotStart, end: slotEnd }];

    assignments.forEach((assignment) => {
      const assignmentStart = new Date(assignment.assignment_start_datetime);
      const assignmentEnd = new Date(assignment.assignment_end_datetime);

      if (
        assignment.service_provider_id == serviceProvider.id &&
        assignmentStart < slotEnd &&
        assignmentEnd > slotStart
      ) {
        // Split the availability into before and after the assignment
        const newAvailability = [];

        availability.forEach((avail) => {
          if (assignmentStart > avail.start && assignmentEnd < avail.end) {
            newAvailability.push({ start: avail.start, end: assignmentStart });
            newAvailability.push({ start: assignmentEnd, end: avail.end });
          } else if (assignmentStart <= avail.start && assignmentEnd >= avail.end) {
            // Entire slot is blocked by the assignment
          } else if (assignmentStart > avail.start && assignmentStart < avail.end) {
            newAvailability.push({ start: avail.start, end: assignmentStart });
          } else if (assignmentEnd > avail.start && assignmentEnd < avail.end) {
            newAvailability.push({ start: assignmentEnd, end: avail.end });
          } else {
            newAvailability.push(avail);
          }
        });

        availability = [...newAvailability];
      }
    });

    return availability.map((avail) =>
      ({
        date: slot.date,
        start_time: avail.start.toISOString().split("T")[1].slice(0, 8),
        end_time: avail.end.toISOString().split("T")[1].slice(0, 8),
        sp_id: slot.sp_id,
        slot_id: slot.slot_id,
        id: slot.id,
        minimum_preparation_time: slot.minimum_preparation_time,
      })
    );
  }).flat();
}

export const loadOrganisationSchedule = (orgId, id, date) => async (dispatch) => {
  const [serviceId, organisationId, serviceableDogSize, isAFulldayService] = id;
  const [startDate, endDate] = date;
  const resp = await getOrganisationsServiceProvider([serviceId, organisationId, serviceableDogSize, isAFulldayService, startDate, endDate]);
  const organizationSPAvailability = await getOrganisationAvailability(orgId, [
    serviceId,
    startDate,
    endDate,
    organisationId,
    ["is_customer", true],
    serviceableDogSize,
  ]);
  const {service_provider: serviceProvider = [], assignments = []} = organizationSPAvailability;
  dispatch({
    type: types.SET_ORGANISATION_PROVIDERS,
    payload: resp.data,
  });
  dispatch({
    type: types.SET_ORGANISATION_PROVIDERS_SERVICE,
    payload: {
      ...organizationSPAvailability,
      service_provider:  serviceProvider.map(sp => ({
        ...sp,
        available_slots: getUpdatedAvailability(sp, assignments),
      }))
    },
  });
  dispatch({ type: types.SET_LOADER, payload: false });
};

export const selectServiceLocation = (data) => ({
  type: types.SELECTED_SERVICE_LOCATION,
  payload: data,
});

export const setSelectServiceProvider = (data) => ({
  type: types.SELECTED_SERVICE_PROVIDER,
  payload: data,
});

export const serviceBookingDate = (data) => ({
  type: types.SELECTED_BOOKING_DATE,
  payload: data,
});

export const updateServiceBookingDate = (data) => ({
  type: types.SELECT_BOOKING_DATE,
  payload: data,
});

export const serviceBookingMonth = (data) => ({
  type: types.SELECTED_BOOKING_MONTH,
  payload: data,
});

export const editOrderSummary = (stepNumber) => ({
  type: types.GO_TO_SELECTED_SERVICES,
  payload: stepNumber,
});

export const bannedPopup = (open = false) => (dispatch) => {
  dispatch({
    type: types.BANNED_POPUP,
    payload: open,
  });
};

export const goNextPagewithBannedPopup = () => ({
  type: types.GO_TO_NEXT_STEP,
});

export const previousSteps = () => ({
  type: types.SELECTED_PREVIOUS_STEP,
});

export const nextSteps = () => ({
  type: types.SELECTED_NEXT_STEP,
});

export const addOnsToStore = (item, checked) => ({
  payload: { item, checked },
  type: types.ADDONS_TO_STORE,
});

export const emptyAddonsToStore = () => ({
  type: types.EMPTY_ADDONS_TO_STORE,
});

export const addToGuestCart = () => ({
  type: types.ADD_TO_GUEST_CART,
});

export const addToGuestCheckout = () => ({
  type: types.ADD_TO_GUEST_CHECKOUT,
});

export const resetBookingFlow = () => ({
  type: types.RESET_BOOKING_FLOW,
});

export const addCartId = (id) => (dispatch) => {
  dispatch({
    type: types.ADD_CART_ID,
    payload: id,
  });
};

export const loadCartData = () => async (dispatch) => {
  const res = await loadUserCartData();
  dispatch({
    payload: res?.data,
    type: types.SET_USER_CART_DATA,
  });
};

export const addToLoggedCart = (flag = false) => async (dispatch, getState) => {
  const {
    BookingFlowServicesReducer: {
      selectedServiceContainer: { selectedServices, dogSizes, selectedLocation, selectedProvider, selectedAddOns },
      calenderDate,
    },
  } = getState();
  const extraRate = [];
  const allAddOns = [];
  if (Object.keys(selectedAddOns)?.length > 0) {
    Object.keys(selectedAddOns).forEach((key) => extraRate.push(selectedAddOns[key]?.price));
  }
  if (Object.keys(selectedAddOns)?.length > 0) {
    Object.keys(selectedAddOns).forEach((key) => allAddOns.push(selectedAddOns[key]?.id));
  }
  const totalExtra = extraRate?.reduce((acc, item) => acc + item, 0);
  const totalPrice = dogSizes?.price + totalExtra;
  const startTime = selectedProvider?.serviceTime?.split(" - ")[0];
  const endTime = selectedProvider?.serviceTime?.split(" - ")[1];
  if (dogSizes?.dogId <= 6) {
    const object = {
      service_id: selectedServices?.serviceId,
      organization_id: selectedLocation?.organisationId,
      service_provider_id: selectedProvider?.serviceProviderId,
      price: totalPrice,
      add_on: allAddOns,
      assignment_start_datetime: `${calenderDate} ${startTime}`,
      assignment_end_datetime: `${calenderDate} ${endTime}`,
      duration: dogSizes?.duration,
      height: dogSizes?.dogWeight,
      slot_id: selectedProvider.slot_id,
    };
    localStorage.setItem("newDog", "true");
    const res = await addServiceToCart(object);
    dispatch({
      type: types.ADD_CART_ID,
      payload: res?.data?.id,
    });
    dispatch(loadCartData());
    dispatch({
      payload: { ...dogSizes, id: res?.data?.dog_id?.id },
      type: types.SELECTED_DOG_SIZE,
    });
    dispatch(addCartId([res.data.id]));
    if (flag && res) {
      dispatch({
        payload: flag,
        type: types.SET_FLAG,
      });
    }
  } else {
    const object = {
      service_id: selectedServices?.serviceId,
      dog_id: dogSizes?.dogId,
      organization_id: selectedLocation?.organisationId,
      service_provider_id: selectedProvider?.serviceProviderId,
      price: totalPrice,
      add_on: allAddOns,
      assignment_start_datetime: `${calenderDate} ${startTime}`,
      assignment_end_datetime: `${calenderDate} ${endTime}`,
      duration: dogSizes?.duration,
      slot_id: selectedProvider.slot_id,
    };
    const res = await addServiceToCart(object);

    dispatch(addCartId([res.data.id]));
    dispatch(loadCartData());
    if (flag && res) {
      dispatch({
        payload: flag,
        type: types.SET_FLAG,
      });
    }
  }
};

export const setFlagValue = (flag) => (dispatch) => {
  dispatch({
    payload: flag,
    type: types.SET_FLAG,
  });
};

export const setErrorValue = (flag) => (dispatch) => {
  dispatch({
    payload: flag,
    type: types.SET_ERROR,
  });
};

export const setStepAndContentOfBooking = (data, step) => (dispatch) => {
  dispatch({
    payload: { data, step },
    type: types.SET_STEP_AND_CONTENT,
  });
};

export const deleteCartItem = (id) => async (dispatch) => {
  const res = await deleteItemFromCart(id);
  if (res === 200 || res === "200") {
    const result = await loadUserCartData();
    dispatch({
      payload: result?.data,
      type: types.SET_USER_CART_DATA,
    });
  }
};

export const deleteGuestCartItem = (id, index) => (dispatch) => {
  const cartData = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
  cartData.splice(index, 1);
  const updatedCartData = cartData.map((obj, cartIndex) => ({ ...obj, id: cartIndex }));
  sessionStorage.setItem("cart", JSON.stringify(updatedCartData));
  dispatch({
    payload: cartData?.length,
    type: types.CART_COUNT,
  });
};

export const setSelectedCartId = (cartId) => (dispatch) => {
  dispatch({
    payload: cartId,
    type: types.SET_SELECTED_CART_ID,
  });
};

export const clearBookingFlowData = () => (dispatch) => {
  dispatch({
    type: types.DESTROY_SESSION,
  });
};

export const setCartCount = () => (dispatch) => {
  const cartData = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
  dispatch({
    payload: cartData.length,
    type: types.CART_COUNT,
  });
};

export const availabilityErrorPopup = (open = false) => (dispatch) => {
  dispatch({
    type: types.AVAILABILITY_ERROR_POPUP,
    payload: open,
  });
};

export const emailErrorPopup = (open = false) => (dispatch) => {
  dispatch({
    type: types.EMAIL_ERROR_POPUP,
    payload: open,
  });
};
