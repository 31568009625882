import React from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import { MDLabelWrapper, MDLabel as LableName } from "../../styles/Styles";

/**
 * Label component
 */
const Label = ({ color, value, bg }) => (
  <MDLabelWrapper
    textColor={color || `${Colors.Black}`}
    size="small"
    backgroundColor={bg}
  >
    <LableName>{value}</LableName>
  </MDLabelWrapper>
);
Label.propTypes = {
  /**
   * What background color to use
   */
  bg: PropTypes.string,
  /**
   * What color to use
   */
  color: PropTypes.string,
  /**
   * What value to be shown on label
   */
  value: PropTypes.string,
};
Label.defaultProps = {
  color: `${Colors.Black}`,
  bg: `${Colors.Orange}`,
  value: null,
};
export default Label;
