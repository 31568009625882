import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Container from "../../components/Containers/PortalContainer";
import CreateCustomerForm from "./Components/CreateCustomerForm";
import Constants from "../../shared/Constants";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";

const { language } = Constants;

const AddCustomer = (props) => {
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [customerCreated, setCustomerCreated] = useState(false);
  const dispatch = useDispatch();
  const authService = new AuthService(Config.apiBase);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));

  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackToCustomers = () => {
    getDetails();
    setCustomerCreated(false);
    setAlertMessage("");
    setAlertHeader("");
    const { history } = props;
    history.push(Constants.routes.customersList.url);
  };

  const handleAlertModal = (isSaved = false) => {
    if (isSaved) {
      setCustomerCreated(true);
      setAlertMessage(language.alert_customer_created);
    } else {
      setCustomerCreated(true);
      setAlertMessage(language.alert_try_later);
      setAlertHeader(language.header_error);
    }
  };

  return (
    <Container>
      <CommonAddPage
        handleBack={handleBackToCustomers}
        breadCrumbLabel="breadcrumb_back"
        header={Constants.language.customer_add_new_customer}
        description="customer_add_customer_description"
      />
      <CreateCustomerForm handleBackToCustomers={handleAlertModal} />
      {customerCreated && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleBackToCustomers}
          />
        </Modal>
      )}
    </Container>
  );
};
AddCustomer.propTypes = {
  history: PropTypes.node,
};
AddCustomer.defaultProps = {
  history: undefined,
};
export default AddCustomer;
