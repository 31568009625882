import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import useUserPermission from "./useUserPermission";
import {
  setOrganization, fetchServiceProvider,
} from "../../store/actions/AppAction";
import Constants from "../../shared/Constants";
import { getCalendarEventsPromise } from "../../services/CalendarService";
import { getAvailabilityPromise } from "../../services/availableService";
import { fromJson as formatAssignmentJson } from "../../models/Calendar";
import { formatDate as customFormatDate, groupAvailabilities, groupSlotData, formatUtcToLocal } from "../../shared/utils";

const { calendar, input: { format, param } } = Constants;

const { slotStartDate, slotEndDate } = calendar;

const LABEL_ALL = "All";
const BACKGROUND = "background";
const ORDERS = "orders";
const AVAILABILITY = "availability";

const useCalendarData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userPermission = useUserPermission();
  const availableDateRange = useSelector((state) => state.AppReducer.availableDateRange);
  const collectedEventsData = useSelector((state) => state.AppReducer.calendarPayload);
  const serviceProviderData = useSelector((state) => state.AppReducer.serviceProvider);
  const userInfo = useSelector((state) => state.AppReducer.userDetails);

  const getOrganisations = () => {
    const { permissions = [], organization: { organization_details: allOrganisations = [] } = {} } = userInfo;
    const orgIds = permissions.map(permission => permission.organization_id);
    const orgData = allOrganisations
      .filter(org => orgIds.includes(org.id))
      .map(org => {
        const permission = permissions.find(permission => permission.organization_id === org.id);
        return {
          name: permission.organization_name,
          id: permission.organization_id,
          access: permission.is_calendar_editable,
        };
      });
    dispatch({
      type: "setOrganisationRecord",
      payload: orgData,
    });
  };

  const getServiceProviders = (organisationId = "") => {
    dispatch(fetchServiceProvider(organisationId));
  };

  const getLoaderState = () => isLoading;

  const fetchOrdersAndAvailabilities = async (selectedOrganization) => {
    setIsLoading(true);
    const promises = {};
    const { value: organizationId = "" } = selectedOrganization;
    const { startDate, endDate } = availableDateRange;
    const availabilityDateRange = [
      [slotStartDate, startDate],
      [slotEndDate, endDate],
    ];

    const calendarParam =[
      [param.orgId, organizationId],
      ["screen", "calendar"],
      ...availabilityDateRange,
    ];

    if (userPermission.isAssignmentsReadable()) {
      promises.calendarEvents = getCalendarEventsPromise(calendarParam);
    }
    if (selectedOrganization.access === Constants.MANAGE && !serviceProviderData?.length) {
      getServiceProviders(organizationId);
    }
    promises.availabilitySlot = getAvailabilityPromise(calendarParam);
    const responses = await axios.all(Object.values(promises));
    dispatch(setOrganization(promises, responses));
    setIsLoading(false);
  };

  const duplicateMultipleDayAssignments = (calendarEvents)=> {
    const duplicateAssignments = calendarEvents.filter((o)=>((formatUtcToLocal(o.endDateTime,"YYYY-MM-DD") !== formatUtcToLocal(o.startDateTime,"YYYY-MM-DD")) && (formatUtcToLocal(o.endDateTime,"YYYY-MM-DD") > formatUtcToLocal(o.startDateTime,"YYYY-MM-DD"))))
    const createNewAssignments = [];
    const oneDay = 1000*60*60*24;
    duplicateAssignments.map((assgn)=>{
      const { endDateTime, startDateTime } = assgn;
      const startTime = moment(startDateTime, format.dateWithTime ).format("HH:mm");
      const start = moment(formatUtcToLocal(startDateTime,format.date));
      const timeEnd = moment(formatUtcToLocal(endDateTime,format.date));
      const endDate = moment(endDateTime,format.dateWithTime).format(format.date);
      const noOfDays = timeEnd.diff(start)/oneDay;
      const endTime = new Date(timeEnd).getTime();
      if(noOfDays > 1) {
        [...Array(noOfDays)].forEach((obj,i) => {
          const assgnStartDate = moment(new Date(endTime - (i * oneDay))).format(format.date);
          createNewAssignments.push({...assgn, startDateTime: moment(`${assgnStartDate} ${startTime}`).format(format.dateWithTime)})
        })
      } else {
        createNewAssignments.push({...assgn, startDateTime: moment.utc(`${endDate} ${startTime}`).format(format.dateWithTime)})
      }
      return assgn;
    })
    return createNewAssignments;
  }

  const formatDataForWeekAndDayView = (selectedEvent = LABEL_ALL, serviceProviderId = "") => {
    let eventData = [];
    const { availableSlots = [], calendarEvents = [] } = collectedEventsData;
    if (selectedEvent === LABEL_ALL || selectedEvent === ORDERS) {
      const multipleDayAssignments = duplicateMultipleDayAssignments(calendarEvents);
      const allAssignments = [...calendarEvents,...multipleDayAssignments];
      let assignments = allAssignments.map((event) => ({ ...formatAssignmentJson(event), type: "assignment" }));
      assignments = assignments.filter((o) => (serviceProviderId
        ? o?.serviceProvider?.id === serviceProviderId : o));
      const fullDayServiceAssignments = assignments.filter((o)=>(o?.data?.is_full_day_service));
      const groupedOrder = fullDayServiceAssignments.reduce((accumulator, obj) => {
        const date = customFormatDate(obj.start, format.date);
        (accumulator[date] = accumulator[date] || []).push(obj);
        return accumulator;
      }, {});

      const orderGroupedByDate = Object.keys(groupedOrder).map((date) => ({
        date,
        start: date,
        end: date,
        count: groupedOrder[date].length,
        orderData: groupedOrder[date],
        type: "adminOrder",
      }));
      const ordersCount = orderGroupedByDate
        .filter((orderSingleDate) => moment(orderSingleDate.date, format.date, true).isValid());
      let finalAssignments = assignments.filter((o)=>!o?.data?.is_full_day_service);
      eventData = [...eventData, ...finalAssignments, ...ordersCount];
    }
    if (selectedEvent === LABEL_ALL || selectedEvent === AVAILABILITY) {
      const availablities = availableSlots.filter((o) => (serviceProviderId
        ? o?.sp_id === serviceProviderId : o));
      const availabilityLabels = groupAvailabilities(availablities);
      let slots = groupSlotData(availablities);
      slots = groupSlotData(slots);
      const availability = [...slots.map((o) => ({
        ...o,
        display: o?.service_provider_slot?.is_full_day_available ? "none" : BACKGROUND,
        start: `${o.start.split("T")[0]}T${o.start_time}:00`,
        end: `${o.end.split("T")[0]}T${o.end_time}:00`,
      })), ...availabilityLabels.map((o) => ({ ...o, title: `${Constants.language.label_available} ${o.serviceProviderIds.length}` }))];
      eventData = [...availability, ...eventData];
    }
    return eventData;
  };

  const formatDataForMonthView = (selectedEvent = LABEL_ALL, serviceProviderId = "") => {
    let eventData = [];
    const { availableSlots = [], calendarEvents = [] } = collectedEventsData;
    if (selectedEvent === LABEL_ALL || selectedEvent === ORDERS) {
      const multipleDayAssignments = duplicateMultipleDayAssignments(calendarEvents);
      const allAssignments = [...calendarEvents,...multipleDayAssignments];
      let assignments = allAssignments.map((event) => formatAssignmentJson(event));
      assignments = assignments.filter((o) => (serviceProviderId
        ? o?.serviceProvider?.id === serviceProviderId : o));
      const fullDayServiceAssignments = assignments.filter((o)=>(o?.data?.is_full_day_service));
      const groupedOrder = fullDayServiceAssignments.reduce((accumulator, obj) => {
        const date = customFormatDate(obj.start, format.date);
        (accumulator[date] = accumulator[date] || []).push(obj);
        return accumulator;
      }, {});

      const orderGroupedByDate = Object.keys(groupedOrder).map((date) => ({
        date,
        start: date,
        end: date,
        count: groupedOrder[date].length,
        orderData: groupedOrder[date],
        type: "adminOrder",
      }));
      const ordersCount = orderGroupedByDate
        .filter((orderSingleDate) => moment(orderSingleDate.date, format.date, true).isValid());
      eventData = [...eventData, ...calendarEvents, ...ordersCount];
    }
    if (selectedEvent === LABEL_ALL || selectedEvent === AVAILABILITY) {
      const slots = availableSlots.filter((o) => (serviceProviderId
        ? o?.sp_id === serviceProviderId : o));
      const AvailableSP= slots.filter((o)=>(!o?.is_full_day_available));
      const availabilityCount = AvailableSP.reduce((acc, obj) => {
        const key = obj.date;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      const event = Object.keys(availabilityCount).map((i, availabilityIndex) => {
        const slotValue = Object.values(availabilityCount)[availabilityIndex];
        const uniqueSlots = [...new Map(slotValue.map((item) => [item.slot_id, item])).values()];
        const eventName = uniqueSlots.length;

        return ({
          date: i,
          start: i,
          end: i,
          name: eventName,
          slotDetails: slotValue.map((slotDetail) => slotDetail),
          type: "adminAvailable",
        });
      });
      eventData = [ ...event, ...slots, ...eventData];
      if (selectedEvent === "availability") {
        eventData = eventData.filter((slot) => slot.type === "available" || slot.type === "adminAvailable");
      }
    }

    return eventData;
  };

  return {
    getOrganisations,
    getServiceProviders,
    fetchOrdersAndAvailabilities,
    formatDataForWeekAndDayView,
    formatDataForMonthView,
    getLoaderState,
  };
};

export default useCalendarData;
