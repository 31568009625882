import styled from "styled-components";
import Colors from "../../styles/Colors";
import { Div } from "../../styles/Styles";

export const FlexContainer = styled(Div)`
  display: flex;
  justify-content: center;
  height: auto;
  background-color: ${Colors.DarkWhite};
  @media (max-width: 768px) {
    margin-top: 30%;
    flex-direction: column-reverse;
    width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 368px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;
export const Container = styled.div`
  width: ${({ type }) => (type === "calendar" ? "94%" : "80%")};
  margin: 32px;
  @media (max-width: 1200px) {
    width: 92%;
    margin: 20px 4%;
  }
`;
export const SectionLeft = styled(Div)`
  width: 45%;
  flex: 0 0 45%;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;
export const SectionRight = styled(Div)`
  display: block;
  width: 55%;
  flex: 0 0 55%;
  height: inherit;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const ImageWrapper = styled.div`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
  background-position: ${({ backgroundPosition }) => backgroundPosition || "center center"};
  background-repeat: ${({ backgroundRepeat }) => backgroundRepeat || "no-repeat"};
  background-size: ${({ backgroundSize }) => backgroundSize || "cover !important"};
  position: ${({ position }) => position || "relative"};
  background-image: ${({ backgroundImage }) => `url("${backgroundImage}")`};
  transition: transform 0.5s ease;
`;
export const ContentWrapper = styled.div`
  width: 380px;
  text-align: center;
  @media (max-width: 570px) {
    width: 300px;
  }
  @media (max-width: 370px) {
    width: 100%;
    padding: 2%;
  }
`;
