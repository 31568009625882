/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Div, MDLabel, CardHeader, MDImage } from "../../styles/Styles";
import Modal from "../Modal/Modal";
import SelectBox from "../Input/SelectBox";
import SelectTime from "../Input/SelectTime";
import CustomDatePicker from "../Input/CustomDatePicker";
import Button from "../Button/Button";
import DaysModel from "./DaysModel";
import { fetchUserServices } from "../../store/actions/AppAction";
import { formatDate } from "../../shared/utils";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import CircleTickMark from "../../assets/Icons/CircleTickMark.svg";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import PlusIccon from "../../assets/Icons/PlusIccon.svg";
import Theme from "../../styles/Theme";
import CheckBox from "../Input/CheckBox";
import WarningPopupModal from "./WarningPopupModal";
import { Span } from "../../pages/MyAccounts/Styles";
import Constants from "../../shared/Constants";
import { timeIntervals } from "./TimeIntervals";
import Loader from "../Loader/Loader";
import { getSlotData, updateSlotData } from "../../services/availableService";
import CheckBoxAccordian from "../Accordian/CheckBoxAccordian";

export default function AvailabilityOrderModal({
  onClose,
  showSuccessPopup,
  hasManageCalendarAccess,
  availabilityModalTitle,
  data,
  availability,
  setAvailability,
  handleDelete,
  organizationId,
}) {
  const {
    widths: { w25 },
    heights: { h20 },
    radii: { large },
  } = Theme;
  const { date = "" } = data;
  const dispatch = useDispatch();
  const [timeError, setTimeError] = useState(false);
  const [isOverlapMsg, setIsOverlapMsg] = useState(false);
  const [isBreak, setisBreak] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const intervals = timeIntervals();
  const [slotData, setSlotData] = useState({});
  const checkState = useSelector((state) => state.AppReducer.userDetails);
  const collectUserServices = useSelector((state) => state.AppReducer.userService) || [];
  const [userServices, setUserServices] = useState(collectUserServices);
  const [serviceList, setServiceList] = useState([]);

  const {
    input: { format },
  } = Constants;

  const fetchSlots = async (params) => {
    setIsLoading(true);
    try {
      const response = await getSlotData(params);
      const { data: availablData = [] } = response;
      availablData.brake = availablData.get_breaks.length ? availablData.get_breaks[0].break : [];
      availablData.brakes = availablData.brake.map((value) => [
        { label: value[0], value: [0] },
        { label: value[1], value: value[1] },
      ]);
      setSlotData(availablData);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setSlotData({});
      setIsLoading(false);
    }
  };
  const fetchUserService = (params) => {
    dispatch(fetchUserServices(params));
  };

  useEffect(() => {
    fetchSlots(data);
  }, [data]);

  useEffect(() => {
    if(availability?.is_full_day_available){
      setUserServices(collectUserServices.filter((o)=>o.is_full_day_service));
    } else {
      setUserServices(collectUserServices.filter((o)=>!o.is_full_day_service));
    }
  }, [collectUserServices, availability?.is_full_day_available]);

  const [isDisabled, setIsDisabled] = useState(true);

  const handleValidateForm = (formdata = {}) => {
    const { startDate: formStartDate, endDate: formEndDate, time, daysArray, services } = formdata;

    if (daysArray && daysArray.length === 0) {
      if (formStartDate && time && time[0] && time[1] && services.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (formStartDate && formEndDate && time && time[0] && time[1] && services.length && daysArray.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const selectionMap = (value) => {
    setAvailability({
      ...availability,
      services: value,
    });
    handleValidateForm({
      ...availability,
      services: value,
    });
  };
  useEffect(() => {
    const { service_provider_available_slots: spAvailableSlots = [] } = slotData;
    const slotServices =
      spAvailableSlots?.length &&
      spAvailableSlots[0]?.slots?.length &&
      spAvailableSlots[0].slots.map((value) => ({
        ...value,
        id: value.service_id,
        label: value.name,
        selectedDogSize: value.serviceable_dog_size ? JSON.parse(value.serviceable_dog_size) : [],
        isSelected: true,
      }));
    if (slotServices?.length) {
      const serviceData = userServices.map((service) => ({
        ...service,
        isSelected: slotServices.find((obj) => (obj.id === service.id))?.isSelected,
        serviceable_dog_size: slotServices.find(
          (obj) => (obj.id === service.id),
        )?.selectedDogSize ?? service.selectedDogSize,
        selectedDogSize: service.selectedDogSize,
      }));
      setServiceList(serviceData);
    } else {
      setServiceList(userServices);
    }
  }, [userServices, slotData]);
  const [isMore, setisMore] = useState(false);
  const [isNotARecurrentEvent, setIsNotARecurrentEvent] = useState(false);
  const [showRepeat, setRepeat] = useState({
    label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />,
    value: "Don't Repeat",
    isSelected: true,
  });
  const [selectedDay, setSelectedDay] = useState(formatDate(date || new Date(), "dddd"));
  const handleDateChange = (name, val) => {
    if (name === "startDate") {
      setSelectedDay(formatDate(val, "dddd"));
    }
    setAvailability({
      ...availability,
      [name]: formatDate(val),
    });
    handleValidateForm({
      ...availability,
      [name]: formatDate(val),
    });
  };
  useEffect(() => {
    if (slotData) {
      if (slotData.key === "everyday") {
        setRepeat({
          label: <FormattedMessage id="label_everyday" defaultMessage="Everyday" />,
          value: "everyDay",
          isSelected: false,
        });
      } else if (slotData.key === "monday-friday") {
        setRepeat({
          label: <FormattedMessage id="label_monday_friday" defaultMessage="Monday-Friday" />,
          value: "mon-fri",
          isSelected: false,
        });
      } else if (slotData.key === "monday-saturday") {
        setRepeat({
          label: <FormattedMessage id="label_monday_saturday" defaultMessage="Monday-Saturday" />,
          value: "mon-sat",
          isSelected: false,
        });
      } else if (slotData && slotData.key && slotData.key.includes("every-")) {
        const daySelected = slotData.key.split("-")[1];
        setRepeat({
          label: `${Constants.language.label_every} ${Constants.translations[daySelected]}`,
          value: "everyTuesday",
          isSelected: false,
        });
      } else if (slotData.key === "custom-range") {
        setRepeat({
          label: <FormattedMessage id="label_more" defaultMessage="More..." />,
          value: "more",
          isSelected: false,
        });
      } else {
        setRepeat({
          label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />,
          value: "Don't Repeat",
          isSelected: false,
        });
      }
    }
  }, [slotData]);
  useEffect(() => {}, [availability]);
  useEffect(() => {
    const { service_provider_available_slots: availableSlot = [] } = slotData;
    const services =
      availableSlot?.length &&
      availableSlot[0]?.slots?.length &&
      availableSlot[0].slots.map((value) => ({
        ...value,
        serviceable_dog_size: value.serviceable_dog_size ? JSON.parse(value.serviceable_dog_size) : [],
      }));
    const currentBrakes = slotData.brake;
    const totalBrakes = slotData.brakes;
    if (currentBrakes && currentBrakes.length) {
      setisBreak(true);
    }

    if (slotData.key) {
      let days = [];
      if (slotData.key === "everyday") {
        days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      } else if (slotData.key === "monday-friday") {
        days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      } else if (slotData.key === "monday-saturday") {
        days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      } else if (slotData && slotData.key && slotData.key.includes("every-")) {
        days = [slotData.key.split("-")[1]];
      } else if (slotData.key === "custom-range") {
        days = JSON.parse(slotData.recursive_day_range);
      } else {
        days = [];
      }
      setAvailability({
        ...availability,
        startDate: slotData.date,
        time: [slotData.start_time, slotData.end_time],
        endDate: slotData.endDate,
        services,
        key: slotData.key,
        daysArray: days,
        recKey: false,
        brake: currentBrakes || [],
        brakes: totalBrakes || [],
        is_full_day_available: slotData?.is_full_day_available,
        times: [
          { label: slotData.start_time, value: slotData.start_time },
          { label: slotData.end_time, value: slotData.end_time },
        ],
      });
    } else {
      setIsNotARecurrentEvent(true);
      setAvailability({
        ...availability,
        startDate: slotData.date,
        time: [slotData.start_time, slotData.end_time],
        services,
        key: slotData.key,
        endDate: "",
        recKey: false,
        is_full_day_available: slotData?.is_full_day_available,
        brake: currentBrakes || [],
        brakes: totalBrakes || [],
        daysArray: [],
        times: [
          { label: slotData.start_time, value: slotData.start_time },
          { label: slotData.end_time, value: slotData.end_time },
        ],
      });
    }
  }, [slotData]);
  useEffect(() => {
    if (hasManageCalendarAccess) {
      const { sp_id: spID } = data || {};
      setAvailability({
        ...availability,
        sp_id: spID,
      });
    } else if (checkState) {
      const { id } = checkState;
      setAvailability({
        ...availability,
        sp_id: id,
      });
    }
  }, [checkState]);

  useEffect(async () => {
    if (hasManageCalendarAccess) {
      const { sp_id: spID } = data || {};
      await fetchUserService(spID);
    } else if (checkState) {
      const { id } = checkState || {};
      await fetchUserService(id);
    }
  }, [checkState]);
  const handleRepeat = ({ target }) => {
    setRepeat(target.value);
    let formData = availability;
    if (target.value.value === "everyDay") {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "everyday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "everyday",
      });
    } else if (target.value.value === "mon-fri") {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "monday-friday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "monday-friday",
      });
    } else if (target.value.value === "Don't Repeat") {
      formData = {
        ...availability,
        daysArray: [],
        key: "",
      };
      setAvailability({
        ...availability,
        daysArray: [],
        key: "",
      });
    } else if (target.value.value === "mon-sat") {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "monday-saturday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "monday-saturday",
      });
    } else if (target.value.value === "everyTuesday") {
      const days = [selectedDay];
      formData = {
        ...availability,
        daysArray: days,
        key: `every-${selectedDay && selectedDay.toLowerCase()}`,
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: `every-${selectedDay && selectedDay.toLowerCase()}`,
      });
    } else if (target.value.value === "more") {
      setisMore(!isMore);
    }
    handleValidateForm(formData);
  };
  const handleCloseAvailability = () => {
    setisMore(false);
  };
  const handleRenderAvailability = () => {
    setisMore(!isMore);
  };
  const openDaysMOdal = () => {
    setisMore(true);
  };

  const validateTime = (formdata = {}) => {
    const { time = [] } = formdata;
    let startTime = (time && time[0]) || "";
    let endTime = (time && time[1]) || "";
    if (startTime && endTime) {
      startTime = startTime.split(":")[0] * 60 + startTime.split(":")[1];
      endTime = endTime.split(":")[0] * 60 + endTime.split(":")[1];
      return Number(startTime) >= Number(endTime);
    }
    return false;
  };

  const handleValidateBreaks = (formData) => {
    const { brake: formDataBrake, time } = formData;
    const brakeData = [...formDataBrake];
    const timeData = [...time];
    if (brakeData && brakeData.length > 0) {
      const latestBrake = brakeData[brakeData.length - 1];
      if (latestBrake && latestBrake[0] && latestBrake[1]) {
        const newBrakeStart = latestBrake[0].split(":")[0] * 60 + latestBrake[0].split(":")[1];
        const newBrakeEnd = latestBrake[1].split(":")[0] * 60 + latestBrake[1].split(":")[1];
        const startTime = timeData && timeData[0] && timeData[0].split(":")[0] * 60 + timeData[0].split(":")[1];
        const endTime = timeData && timeData[1] && timeData[1].split(":")[0] * 60 + timeData[1].split(":")[1];
        if (
          Number(newBrakeStart) >= Number(startTime) &&
          Number(newBrakeStart) < endTime &&
          Number(newBrakeEnd) > Number(newBrakeStart) &&
          Number(newBrakeEnd) > Number(startTime) &&
          Number(newBrakeEnd) <= Number(endTime)
        ) {
          return false;
        }
        return true;
      }
      return true;
    }
    return false;
  };

  const handleTimeChange = ({ target }) => {
    setIsOverlapMsg(false);
    const { name = "", value = {} } = target;
    let formdata;
    const { time, times: availabilityTimes } = availability;
    if (name === "startTime") {
      availabilityTimes[0] = value || "";
      time[0] = value.value || "";
      formdata = {
        ...availability,
        time,
        availabilityTimes,
      };
      setAvailability({
        ...availability,
        time,
        availabilityTimes,
      });
    } else {
      availabilityTimes[1] = value || "";
      time[1] = value.value || "";
      formdata = {
        ...availability,
        time,
        availabilityTimes,
      };
      setAvailability({
        ...availability,
        time,
        availabilityTimes,
      });
    }
    setTimeError(validateTime(formdata));
    setIsOverlapMsg(handleValidateBreaks(formdata));
    handleValidateForm(formdata);
  };

  const handleBrakeChange = useCallback((index) => ({ target }) => {
    let formdata = availability;
    if (target.name === "startTime") {
      const { brake, brakes } = availability;
      brakes[index][0] = target.value;
      brake[index][0] = target.value && target.value.value;
      formdata = {
        ...availability,
        brake,
        brakes,
      };
      setAvailability({
        ...availability,
        brake,
        brakes,
      });
    } else {
      const { brake, brakes } = availability;
      brakes[index][1] = target.value;
      brake[index][1] = target.value && target.value.value;
      formdata = {
        ...availability,
        brake,
        brakes,
      };
      setAvailability({
        ...availability,
        brake,
        brakes,
      });
    }
    setIsOverlapMsg(handleValidateBreaks(formdata));
    handleValidateForm(formdata);
  });
  const addBreakeFiled = () => {
    const { brake, brakes = "" } = availability;
    setAvailability({
      ...availability,
      brake: [...brake, ...[["", ""]]],
      brakes: [...brakes, ...[["", ""]]],
    });
    handleValidateForm({
      ...availability,
      brake: [...brake, ...[["", ""]]],
      brakes: [...brakes, ...[["", ""]]],
    });
    setIsDisabled(true);
  };

  const handleSelect = (value) => {
    selectionMap(
      value
        .filter((selectedService) => selectedService.isSelected)
        .map((service) => ({
          service_id: service.id,
          serviceable_dog_size: service.serviceable_dog_size,
        }))
    );
  };
  const handleSubmit = async () => {
    setErrorMessage("");
    if (!slotData.key || isSubmit) {
      setIsLoading(true);
      try {
        const { sp_id: spID } = data || {};
        const { brake, brakes, daysArray } = availability;
        const recKeyValue = isNotARecurrentEvent ? daysArray.length > 0 : false;
        setAvailability({
          ...availability,
          sp_id: spID,
          brake: !isBreak ? brake.splice(0) : brake,
          brakes: !isBreak ? brakes.splice(0) : brakes,
          organization_id: organizationId,
          recKey: recKeyValue,
        });
        const res = await updateSlotData({
          ...availability,
          sp_id: spID,
          brake: !isBreak ? brake.splice(0) : brake,
          brakes: !isBreak ? brakes.splice(0) : brakes,
          organization_id: organizationId,
          recKey: recKeyValue,
        });
        if (res) {
          setIsLoading(false);
          setIsWarning(false);
          showSuccessPopup();
        }
      } catch (e) {
        setIsLoading(false);
        const { response: { data: responseData = "" } = {} } = e;
        if (e && responseData) {
          const {
            CUSTOM_ERROR_CODE: { SERVICE_PROVIDER_HAS_SLOTS, SERVICE_AND_AVAILABLE_SLOT_NOT_RELATED }
          } =Constants;
          if ([SERVICE_PROVIDER_HAS_SLOTS, SERVICE_AND_AVAILABLE_SLOT_NOT_RELATED].includes(responseData.error_code)) {
            setErrorMessage(Constants.language.slots_available_message);
          } else {
            setErrorMessage(Constants.language.alert_try_later);
          }
        }
        console.error(e);
      }
    }
    if (slotData?.assignments?.length > 0 || slotData.key) {
      setIsWarning(true);
    }
  };
  const handleChecked = async () => {
    setIsLoading(true);
    try {
      const res = await updateSlotData(availability);
      if (res) {
        setIsLoading(false);
        setIsWarning(false);
        showSuccessPopup();
      }
    } catch (e) {
      const { response: { data: responseData = "" } = {} } = e;
      if (e && responseData) {
        const {
          CUSTOM_ERROR_CODE: { SERVICE_PROVIDER_HAS_SLOTS }
        } =Constants;
        if (responseData.error_code === SERVICE_PROVIDER_HAS_SLOTS) {
          setErrorMessage(Constants.language.slots_available_message);
        } else {
          setErrorMessage(Constants.language.alert_try_later);
        }
      }
      setIsWarning(false);
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleWarningClose = () => {
    setIsWarning(!isWarning);
    onClose();
  };

  const { endDate, brake, startDate, times, brakes } = availability || {};
  let isAddBreak = false;
  brake.forEach((num) => {
    if (!(num && num[0] && num[1])) {
      isAddBreak = true;
    }
  });
  const { service_provider_available_slots: availableSlots = [] } = slotData;

  const handleIsBreakClick = () => {
    if (!isBreak) {
      setAvailability({
        ...availability,
        brake: [["", ""]],
        brakes: [["", ""]],
      });
    }
    setIsOverlapMsg(false);
    setisBreak(!isBreak);
    setIsDisabled(!isBreak);
  };

  return (
    <Modal className="availability-form">
      <Div width={[400, 500]}>
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            {availabilityModalTitle}
          </MDLabel>
          {isMore && (
            <DaysModel
              onClose={handleCloseAvailability}
              renderAvailability={handleRenderAvailability}
              onClick={openDaysMOdal}
              setSubmit={setIsSubmit}
              availabilityab={availability}
              setAvailability={setAvailability}
            />
          )}
          {isWarning ? (
            slotData?.assignments?.length > 0 ? (
              <WarningPopupModal
                onClose={handleWarningClose}
                availabilityModalTitle="label_warning"
                time={["2:30", "14:35"]}
                setSubmit={handleChecked}
                data={slotData.assignments}
                setStateObject={setAvailability}
                stateObject={availability}
                details=""
                assignemntCount={slotData}
                showSuccessPopup={showSuccessPopup}
              />
            ) : (
              <WarningPopupModal
                onClose={handleWarningClose}
                availabilityModalTitle="label_warning"
                time=""
                data=""
                setSubmit={handleChecked}
                setStateObject={setAvailability}
                stateObject={availability}
                details={slotData && slotData.assignments}
                assignemntCount={slotData}
                showSuccessPopup={showSuccessPopup}
              />
            )
          ) : (
            ""
          )}
        </CardHeader>
        {isLoading && (
          <Div display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={40}>
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
          </Div>
        )}
        {!isLoading && (
          <>
            {slotData && slotData.assignments && slotData.assignments.length > 0 ? (
              <>
                <Div display="flex" mx={40} paddingTop="1rem" justifyContent="center" alignItems="center">
                  <Div className="col-3" p={0}>
                    <MDLabel fontSize="18px" mr={35}>
                      <FormattedMessage id="label_order" defaultMessage="Order:" />:
                    </MDLabel>
                  </Div>
                  <Div className="col-9" p={0}>
                    {slotData &&
                      slotData.assignments?.length > 0 &&
                      slotData.assignments.map((value, key) => (
                        <Div display="flex" className="order-model" mx={1} id={key} width="fit-content">
                          <FormattedMessage fontSize="5px" id="label_assignment" defaultMessage="Assignment:" />
                          <Span>{value && value?.service_name}</Span>
                        </Div>
                      ))}
                  </Div>
                </Div>
                <hr />
              </>
            ) : (
              <Div display="flex" mx={[18, 40, 40]}>
                <Div display="flex" className="col-3" p={0}>
                  <MDLabel fontSize="18px" mr={35}>
                    <FormattedMessage id="label_date" defaultMessage="Date:" />:
                  </MDLabel>
                </Div>
                <Div display="flex" className="col-9" p={0}>
                  <CustomDatePicker
                    value={startDate}
                    disabled={false}
                    name="startDate"
                    onChange={handleDateChange}
                    maxDate=""
                    minDate={new Date()}
                  />
                </Div>
              </Div>
            )}
            {slotData?.assignments?.length > 0 ? (
              <Div display="flex" mx={40}>
                <Div display="flex" className="col-3" p={0}>
                  <MDLabel fontSize="18px" mr={35}>
                    <FormattedMessage id="label_date" defaultMessage="Date:" />:
                  </MDLabel>
                </Div>
                <Div display="flex" className="col-9" alignItems="center" p={0}>
                  <MDImage
                    src={CalendarIcon}
                    useCursorPointer
                    alt="icon"
                    width={w25}
                    height={h20}
                    borderRadius={large}
                  />
                  <Span>{formatDate(availability && availability.startDate, format.dateWithDay)}</Span>
                </Div>
              </Div>
            ) : (
              ""
            )}
            <hr />
            <Div display="flex" className="row" mx={[18, 40, 40]} alignItems="center">
              <Div display="flex" className="col-3" p={0}>
                <MDLabel fontSize="18px" mr={35} mb={[13, 13, 0]}>
                  <FormattedMessage id="label_time" defaultMessage="Time:" />:
                </MDLabel>
              </Div>
              <Div display="flex" className="col-lg-9" p={0}>
                <MDLabel fontSize="16px" fontWeight="400" color="darkgray" mr={15}>
                  <FormattedMessage id="label_from" defaultMessage="From:" />:
                </MDLabel>
                <SelectTime
                  value={times && times[0]}
                  name="startTime"
                  options={intervals}
                  placeholder="--:--"
                  width={100}
                  endIcon={ClockIcon}
                  onChange={handleTimeChange}
                  IconHeight="20px"
                />

                <MDLabel fontSize="16px" fontWeight="400" color="darkgray" ml={[40, 25, 25]} mr={15}>
                  <FormattedMessage id="label_to" defaultMessage="To:" />:
                </MDLabel>
                <SelectTime
                  name="endTime"
                  value={times && times[1]}
                  options={intervals}
                  placeholder="--:--"
                  width={100}
                  endIcon={ClockIcon}
                  onChange={handleTimeChange}
                  IconHeight="20px"
                />
              </Div>
              {timeError && (
                <Div display="flex" justifyContent="center" my={3}>
                  <MDLabel color="red">
                    <FormattedMessage
                      id="error_time_start_end"
                      defaultMessage="Start time shouldn't be greater than end time."
                    />
                  </MDLabel>
                </Div>
              )}
              <Div display={["flex"]} flexDirection={["unset", "unset", "row"]} className="offset-lg-3" p={0} width={1} mt={[2, 0, 0]}>
                <Div width={[1, 1, 1, 1 / 2]} mt={3}>
                  <CheckBox
                    isSelected={isBreak}
                    size="14px"
                    disabled={timeError}
                    onClick={handleIsBreakClick}
                    label={<FormattedMessage id="label_set_break" defaultMessage="Set Break" />}
                    name="isBreak"
                  />
                </Div>
              </Div>
            </Div>
            {isBreak && (
              <Div display={["block", "block", "flex"]} mt={3} mx={[18, 40, 40]} p={0}>
                <Div display="flex" className="col-3" p={0} mb={[10, 10, 0]}>
                  <MDLabel fontSize="18px" alignSelf="flex-start" mt={3}>
                    <FormattedMessage id="label_break" defaultMessage="Break:" />
                  </MDLabel>
                </Div>
                <Div display="flex" className="row col-9" p={0}>
                  {brake && brake.length
                    ? brake.map((el, i) => (
                      <Div display="flex" className="col-12" mt={i > 0 ? 3 : 0} key={`brake-${el}`}>
                        <MDLabel fontSize="16px" color="darkgray" mr={15} fontWeight="normal">
                          <FormattedMessage id="label_from" defaultMessage="From:" />:
                        </MDLabel>
                        <SelectTime
                          name="startTime"
                          value={brakes[i][0] || ""}
                          options={intervals}
                          placeholder="--:--"
                          width={100}
                          onChange={handleBrakeChange(i)}
                          endIcon={ClockIcon}
                          IconHeight="20px"
                        />
                        <MDLabel fontSize="16px" color="darkgray" ml={[40, 25, 25]} mr={15} fontWeight="normal">
                          <FormattedMessage id="label_to" defaultMessage="To:" />:
                        </MDLabel>
                        <SelectTime
                          name="endTime"
                          value={brakes[i][1] || ""}
                          options={intervals}
                          placeholder="--:--"
                          width={100}
                          onChange={handleBrakeChange(i)}
                          endIcon={ClockIcon}
                          IconHeight="20px"
                        />
                      </Div>
                    ))
                    : ""}
                  {isOverlapMsg && (
                    <MDLabel
                      fontSize="12px"
                      className="col-12"
                      alignSelf="flex-end"
                      justify-content="flex-end"
                      color="red"
                      mt={1}>
                      <FormattedMessage
                        id="label_brake_overlapping"
                        defaultMessage="Brake is overlapping, Please change the brake time"
                      />
                    </MDLabel>
                  )}
                  <Div display="flex" className="col-12" flexDirection="column" mt={3}>
                    <Button
                      className="break-btn"
                      startIcon={PlusIccon}
                      label={<FormattedMessage id="label_one_more_break" defaultMessage="One more break" />}
                      width="100%"
                      onClick={addBreakeFiled}
                      fontSize="14px"
                      disabled={isAddBreak || isOverlapMsg}
                    />
                  </Div>
                </Div>
              </Div>
            )}
            <hr />
            {userServices && userServices.length ? (
              <Div display={["block", "block", "flex"]} p={0} mx={[18, 40, 40]} mt={3}>
                <Div display="flex" alignItems="center" className="col-3" p={0}>
                  <MDLabel fontSize="18px" mr={35} mb={[16, 16, 0]}>
                    <FormattedMessage id="label_services" defaultMessage="Service" />:
                  </MDLabel>
                </Div>
                {slotData && slotData.assignments && slotData.assignments.length > 0 ? (
                  <Div className="row col-9 m-0" p={0}>
                    {slotData &&
                      availableSlots &&
                      availableSlots.length &&
                      availableSlots[0] &&
                      availableSlots[0].slots &&
                      availableSlots[0].slots.length &&
                      availableSlots[0].slots.map((value, key) => (
                        <Div display="inline-flex" className="col-5 p-0" margin="2px" id={key}>
                          <MDLabel fontSize="14px" mr={35} fontWeight="normal">
                            {value?.slot_services?.[0]?.name}
                          </MDLabel>
                        </Div>
                      ))}
                  </Div>
                ) : (
                  <Div className="col col-9" p={0} m={0}>
                    {userServices.length > 0 && (
                      <CheckBoxAccordian handleOnChange={handleSelect} services={serviceList} isEdit editAvailabilty />
                    )}
                  </Div>
                )}
              </Div>
            ) : (
              ""
            )}
            <hr />

            <Div display="flex" alignItems="center" p={0} mx={[12, 40, 40]} mt={3}>
              <Div className="col-4 col-lg-3" p={0}>
                <MDLabel fontSize="18px" mr={35}>
                  <FormattedMessage id="label_repeat" defaultMessage="Repeat" />:
                </MDLabel>
              </Div>
              <Div className="col-8 col-lg-9" pr={20} p={0}>
                <SelectBox
                  value={showRepeat}
                  onChange={handleRepeat}
                  name="repeat"
                  options={[
                    {
                      label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />,
                      value: "Don't Repeat",
                      isSelected: false,
                    },
                    {
                      label: <FormattedMessage id="label_everyday" defaultMessage="Everyday" />,
                      value: "everyDay",
                      isSelected: false,
                    },
                    {
                      label: <FormattedMessage id="label_monday_friday" defaultMessage="Monday-Friday" />,
                      value: "mon-fri",
                      isSelected: false,
                    },
                    {
                      label: <FormattedMessage id="label_monday_saturday" defaultMessage="Monday-Saturday" />,
                      value: "mon-sat",
                      isSelected: false,
                    },
                    {
                      label: `${Constants.language.label_every} ${
                        Constants.translations[formatDate(startDate, "dddd")]
                      }`,
                      value: "everyTuesday",
                      isSelected: false,
                    },
                    {
                      label: <FormattedMessage id="label_more" defaultMessage="More..." />,
                      value: "more",
                      isSelected: false,
                    },
                  ]}
                  placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
                  width={255}
                />
              </Div>
            </Div>
            <Div display="flex" mx={[12, 40, 40]}>
              <Div display="flex" className="col-4 col-lg-3" p={0}>
                <MDLabel textAlign="left" fontSize="18px" mt={3}>
                  <FormattedMessage id="label_end_date" defaultMessage="End Date" />:
                </MDLabel>
              </Div>
              <Div display="flex" className="col-8 col-lg-9" p={0}>
                <CustomDatePicker
                  value={endDate}
                  name="endDate"
                  onChange={handleDateChange}
                  maxDate=""
                  disabled={showRepeat && showRepeat.value === "Don't Repeat"}
                  minDate={(startDate && new Date(startDate)) || ""}
                  width={255}
                />
              </Div>
            </Div>
            <hr />
            {showErrorMessage && (
              <Div display="flex" justifyContent="center" my={3}>
                <MDLabel color="red">{showErrorMessage}</MDLabel>
              </Div>
            )}
            <Div display={["block", "block", "flex"]} flexDirection="row-reverse" className="col-12" justifyContent="center" mb={20}>
              <Div mx={[20, 20, 0]}>
                <Button
                  label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
                  width={["100%", "100%", 160]}
                  onClick={handleSubmit}
                  startIcon={CircleTickMark}
                  disabled={isOverlapMsg || isDisabled || timeError}
                />
              </Div>
              <Div ml={[20, 20, 0]} mr={[20, 20, 4]} mt={[15, 15, 0]}>
                <Button
                  width={["100%", "100%", 160]}
                  label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                  secondary
                  onClick={onClose}
                />
              </Div>
            </Div>
            <Div display="flex" justifyContent="center" my={[27, 27, 3]} mx={1}>
              <MDLabel
                fontSize="14px"
                className="order-model"
                mr={35}
                onClick={handleDelete}
                fontWeight="normal"
                useCursorPointer>
                <FormattedMessage id="label_delete_btn" defaultMessage="Delete this availability" />
              </MDLabel>
            </Div>
          </>
        )}
      </Div>
    </Modal>
  );
}
AvailabilityOrderModal.propTypes = {
  /**
   * Pass children innto the modal
   */
  onClose: PropTypes.func,
  availabilityModalTitle: PropTypes.string,
  data: PropTypes.string,
  availability: PropTypes.string,
  setAvailability: PropTypes.string,
  handleDelete: PropTypes.func,
  showSuccessPopup: PropTypes.func,
  organizationId: PropTypes.string,
  hasManageCalendarAccess: PropTypes.bool,
};
AvailabilityOrderModal.defaultProps = {
  onClose: undefined,
  availabilityModalTitle: undefined,
  data: {},
  availability: {},
  setAvailability: {},
  handleDelete: undefined,
  showSuccessPopup: () => {},
  organizationId: undefined,
  hasManageCalendarAccess: false,
};
