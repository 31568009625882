import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Ul, ListItem, Span, MDPaginationLabel, IsDesktop, IsMobile, Link,
} from "../../styles/Styles";
import { ArrowLeft, ArrowRight } from "../../styles/Icons";
import Button from "../Button/Button";

class Pagination extends Component {
  /**
   * @function handleClick
   * @callback gotoPage
   * Takes to the selected page
   * @param {number} param0 PageNumber
   * @param {event} e event
   */
  handleClick = ({ pageNumber }) => () => {
    // e.preventDefault();
    const { gotoPage } = this.props;
    gotoPage(pageNumber);
  };

  /**
   * Generates an array with and start and end page
   * @param {number} min starting page
   * @param {number} max ending page
   */
  generateArray = (min, max) => Array(max - min)
    .join()
    .split(",")
    .map((e, i) => min + i);

  render() {
    const { cursor } = this.props;
    return (
      <>
        <nav>
          <Ul justifyContent="center" mx={2} my={0} p="0px" display="flex" height="27px">
            <ListItem listStyle="none" m={0} display="flex">
              <Link
                display="flex"
                justifyContent="center"
                href
                aria-label="Previous"
                className={`${(cursor.pageNumber <= 1 || cursor.pages === 1) && "disabled-state"} 'text-decoration-none'`}
                // disable={cursor.pageNumber <= 1 || cursor.pages === 1}
                onClick={!(cursor.pageNumber <= 1 || cursor.pages === 1) && this.handleClick({
                  pageNumber: cursor.pageNumber - 1,
                })}
              >
                <MDPaginationLabel navButtons p="0px !important" disable={cursor.pageNumber <= 1 || cursor.pages === 1}>
                  <ArrowLeft />
                  <Span px={2} cursor={(cursor.pageNumber <= 1 || cursor.pages === 1) ? "auto" : "pointer"}>Prev</Span>
                </MDPaginationLabel>
              </Link>
            </ListItem>
            <IsDesktop height="fit-content">
              {cursor.pages < 8 ? (
                this.generateArray(0, cursor.pages).map((obj, index) => (
                  <Link
                    key={`page${{ index }}`}
                    href
                    className="text-decoration-none"
                    onClick={this.handleClick({
                      pageNumber: obj + 1,
                    })}
                  >
                    {cursor.pageNumber === obj + 1 ? (
                      <Button size="small" label={obj + 1} />
                    ) : (
                      <MDPaginationLabel>{obj + 1}</MDPaginationLabel>
                    )}
                  </Link>
                ))
              ) : (
                <ListItem m={0}>
                  <Span>
                    <Span>
                      {this.generateArray(0, 3).map((obj, index) => (
                        <Link
                          key={`page${{ index }}`}
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: obj + 1,
                          })}
                        >
                          {cursor.pageNumber === obj + 1 ? (
                            <Button size="small" label={obj + 1} />
                          ) : (
                            <MDPaginationLabel>{obj + 1}</MDPaginationLabel>
                          )}
                        </Link>
                      ))}
                      <MDPaginationLabel cursor="auto"> ... </MDPaginationLabel>
                      {cursor.pageNumber <= cursor.pages - 3 && cursor.pageNumber > 3 ? (
                        <Link
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: cursor.pageNumber,
                          })}
                        >
                          <Button size="small" label={cursor.pageNumber} />
                        </Link>
                      ) : (
                        <Link
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: Math.round(cursor.pages / 2),
                          })}
                        >
                          <MDPaginationLabel>
                            {cursor.pages && Math.round(cursor.pages / 2)}
                          </MDPaginationLabel>
                        </Link>
                      )}
                      <MDPaginationLabel cursor="auto"> ... </MDPaginationLabel>
                      {this.generateArray(cursor.pages - 3, cursor.pages).map((obj, index) => (
                        <Link
                          key={`page${{ index }}`}
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: obj + 1,
                          })}
                        >
                          {cursor.pageNumber === obj + 1 ? (
                            <Button size="small" label={obj + 1} />
                          ) : (
                            <MDPaginationLabel>{obj + 1}</MDPaginationLabel>
                          )}
                        </Link>
                      ))}
                    </Span>
                  </Span>
                </ListItem>
              )}
            </IsDesktop>
            <IsMobile height="fit-content">
              {cursor.pages < 6 ? (
                this.generateArray(0, cursor.pages).map((obj, index) => (
                  <Link
                    key={`page${{ index }}`}
                    href
                    className="text-decoration-none"
                    onClick={this.handleClick({
                      pageNumber: obj + 1,
                    })}
                  >
                    {cursor.pageNumber === obj + 1 ? (
                      <Button size="small" label={obj + 1} />
                    ) : (
                      <MDPaginationLabel>{obj + 1}</MDPaginationLabel>
                    )}
                  </Link>
                ))
              ) : (
                <ListItem m="0px">
                  <Span>
                    <Span>
                      <Link
                        href
                        className="text-decoration-none"
                        onClick={this.handleClick({
                          pageNumber: 1,
                        })}
                      >
                        {cursor.pageNumber === 1 ? (
                          <Button size="small" label="1" />
                        ) : (
                          <MDPaginationLabel>1</MDPaginationLabel>
                        )}
                      </Link>
                      <MDPaginationLabel cursor="auto"> ... </MDPaginationLabel>
                      {cursor.pageNumber < cursor.pages - 1 && cursor.pageNumber > 1 ? (
                        <Link
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: cursor.pageNumber,
                          })}
                        >
                          <Button size="small" label={cursor.pageNumber} />
                        </Link>
                      ) : (
                        <Link
                          href
                          className="text-decoration-none"
                          onClick={this.handleClick({
                            pageNumber: Math.round(cursor.pages / 2),
                          })}
                        >
                          <MDPaginationLabel>
                            {cursor.pages && Math.round(cursor.pages / 2)}
                          </MDPaginationLabel>
                        </Link>
                      )}
                      <MDPaginationLabel cursor="auto"> ... </MDPaginationLabel>

                      <Link
                        href
                        className="text-decoration-none"
                        onClick={this.handleClick({
                          pageNumber: cursor.pages,
                        })}
                      >
                        {cursor.pageNumber === cursor.pages ? (
                          <Button size="small" label={cursor.pageNumber} />
                        ) : (
                          <MDPaginationLabel>{cursor.pages}</MDPaginationLabel>
                        )}
                      </Link>
                    </Span>
                  </Span>
                </ListItem>
              )}
            </IsMobile>
            <ListItem listStyle="none" m={0} display="flex">
              <Link
                display="flex"
                justifyContent="center"
                href
                aria-label="Next"
                className={`${(cursor.pageNumber >= cursor.pages || cursor.pages === 1) && "disabled-state"} 'text-decoration-none'`}
                disable={cursor.pageNumber >= cursor.pages || cursor.pages === 1}
                onClick={!(cursor.pageNumber >= cursor.pages || cursor.pages === 1)
                  && this.handleClick({
                    pageNumber: cursor.pageNumber + 1,
                  })}
              >
                <MDPaginationLabel navButtons p="0px !important" disable={cursor.pageNumber >= cursor.pages || cursor.pages === 1}>
                  <Span px={2} cursor={(cursor.pageNumber >= cursor.pages || cursor.pages === 1) ? "auto" : "pointer"}>Next</Span>
                  <ArrowRight />
                </MDPaginationLabel>
              </Link>
            </ListItem>
          </Ul>
        </nav>
      </>
    );
  }
}

Pagination.propTypes = {
  /**
   * Renders the selected page
   */
  gotoPage: PropTypes.func,
  /**
   * Pagination details with current page number and total no of pages etc..
   */
  cursor: PropTypes.node,
};
Pagination.defaultProps = {
  gotoPage: undefined,
  cursor: null,
};
export default Pagination;
