const WeekDays = [
  { label: "Everyday", value: "everyday" },
  { label: "Mondays", value: "monday" },
  { label: "Tuesdays", value: "tuesday" },
  { label: "Wednesdays", value: "wednesday" },
  { label: "Thursdays", value: "thursday" },
  { label: "Fridays", value: "friday" },
  { label: "Saturdays", value: "saturday" },
  { label: "Sundays", value: "sunday" },
];
export default WeekDays;
