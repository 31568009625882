import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import Label from "../../components/Label/Label";
import ProfileCard from "../../components/Card/ProfileCard";
import Button from "../../components/Button/Button";
import {
  Div,
  MDImage,
  MDLabel,
  Span,
  MDInputValue,
  P,
  Card,
} from "../../styles/Styles";
import {
  Division, BoxDivision, SpanBox, MDImg,
} from "../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { DateCard } from "../Assignments/Styles";
import { formatDate } from "../../shared/utils";
import moment from "moment";

const {
  icons, themes: { fontSizes },
  input: { format: { twentyFourHourFormat, dottedHourFormat, twoDigitDateFormat } },
  language, labelColor, status, assignmentHeaderTranslations: translate,
} = Constants;

const Accordion = ({ data = [], onCancel = () => {} }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleAccordion = (index) => () => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const addonCard = (des) => {
    const { name = "", duration = "", price = "" } = des;
    return (
      <Card p={2} width="fit-content" mr={30} mb={15} height="64px">
        <Div pb={2}>
          <MDLabel fontSize="16px" lineHeight="24px">{name}</MDLabel>
        </Div>
        <P m={0} display="flex" justifyContent="center">
          <MDImage src={Constants.icons.ClockIcon} alt="icon" className="pr-2" />
          <MDInputValue className="pr-2" fontSize="12px">
            <span className="disabled-state">
              {`+${duration} min`}
            </span>
          </MDInputValue>
          <MDInputValue className="pl-3" fontSize="12px">
            <MDImage src={Constants.icons.PriceTag} alt="icon" className="pr-2" />
            <span className="disabled-state">
              {`+${price} kr`}
            </span>
          </MDInputValue>
        </P>
      </Card>
    );
  };

  const renderContent = (values) => {
    const {
      assignments = [], order_no: orderId = "",
    } = values;
    return (
      <>
        <Div display="flex">
          <MDLabel fontSize={fontSizes.medium} my={3}>
            {`${language.header_order_id}: ${orderId}`}
          </MDLabel>
        </Div>
        {assignments.map((obj, index) => {
          const {
            service_id: service = {}, dog_id: dog = {}, add_ons: addOns = [], assignment_start_datetime: dateTime = "",
            service_provider_id: serviceProvider = {}, organization: org = {}, dog_size: dogSize = "",
          } = obj;

          const { profile_pic: sProPic = {}, duration = {}, price = {} } = service || {};
          const { url: servicePic = "" } = sProPic || {};
          const { profile_pic: dProPic = {} } = dog || {};
          const { url: dogPic = "" } = dProPic || {};
          const { profile_pic: spProPic = {}, full_name: spFullName = "" } = serviceProvider || {};
          const { url: serviceProviderPic = "" } = spProPic || {};
          if (serviceProvider) {
            serviceProvider.name = spFullName || "";
            serviceProvider.info = (
              <MDLabel mt={1}>
                <MDImage src={icons.ProfileIcon} alt="icon" mr={1} />
                {language.label_serviceProvider}
              </MDLabel>
            );
          }
          if (service) {
            service.info = (
              <Div mt={1}>
                <MDLabel>
                  <MDImage src={icons.TagIcon} alt="icon" mr={1} />
                  {language.common_service}
                </MDLabel>
              </Div>
            );
          }
          if (dog) {
            dog.breed = "";
            dog.info = (
              <Div display="flex" justifyContent="space-between" width="100%" mt={1}>
                <MDLabel>
                  <MDImage src={icons.ClockIcon} alt="icon" mr={1} />
                  {service.is_full_day_service === 1 ? Constants.language.label_day : `${duration[dogSize]} min`}
                </MDLabel>
                <MDLabel>
                  <MDImage src={icons.TagIcon} alt="icon" mr={1} />
                  {price[dogSize]}
                  {" "}
                  kr
                </MDLabel>
              </Div>
            );
          }
          if (org) {
            org.info = (
              <Div mt={1}>
                <MDImage src={icons.Marker} alt="icon" mr={1} height={14} width={14} />
                {org.address}
              </Div>
            );
          }

          const { profile_pic: profilePic = {} } = org || {};
          const { url: orgPic = "" } = profilePic || {};
          return (
            <Div borderBottom="1px solid rgb(182,186,189,0.2)" pb={3} mb={3} key={index}>
              <Div display="flex" justifyContent="space-between">
                <MDLabel fontSize={fontSizes.medium} my={3}>
                  {`${language.common_part} ${index + 1}`}
                </MDLabel>
                <MDLabel fontSize={fontSizes.medium}>
                  {`${language.common_sek} ${obj.price}`}
                </MDLabel>
              </Div>
              <Div mt={15} className="row m-0">
                <Div mr={30} mb={15}>
                  <ProfileCard data={service} height="64px" userAvtar={servicePic || ""} />
                </Div>
                <Div mr={30} mb={15}>
                  <ProfileCard data={dog} height="64px" userAvtar={dogPic || ""} />
                </Div>
                <Div mb={15}>
                  <ProfileCard data={org} width="255px" height="64px" userAvtar={orgPic || ""} />
                </Div>
              </Div>
              <Div className="row m-0">
                <Div mr={30} mb={15}>
                  <DateCard date width={100}>
                    <MDLabel fontSize={fontSizes.regular}>
                      {dateTime ? formatDate(dateTime, twoDigitDateFormat) : ""}
                    </MDLabel>
                  </DateCard>
                  <DateCard width={100}>
                    <MDLabel fontSize={fontSizes.regular}>
                      {dateTime ? moment(dateTime, twentyFourHourFormat).format(dottedHourFormat) : ""}
                    </MDLabel>
                  </DateCard>
                </Div>
                <Div mr={30} mb={15}>
                  <ProfileCard data={serviceProvider} width="255px" height="64px" userAvtar={serviceProviderPic || ""} />
                </Div>
                {addOns.map((x) => (addonCard(x)))}
              </Div>
            </Div>
          );
        })}
      </>
    );
  };

  const handleCalculateTotal = (values) => {
    if (activeIndex >= 0 && activeIndex !== null) {
      const { assignments = [] } = values[activeIndex];
      let serviceTotal = 0;
      let addOnTotal = 0;
      assignments.map((o) => {
        const { dog_size: size = "", service_id: { price = {} } = {}, add_ons: addOns = [] } = o;
        serviceTotal += price[size];
        addOns.map((obj) => {
          const { price: addOnPrice = "" } = obj;
          addOnTotal += addOnPrice;
          return obj;
        });
        return o;
      });
      return { serviceTotal, addOnTotal };
    }
    return { serviceTotal: 0, addOnTotal: 0 };
  };

  useEffect(() => {
    handleCalculateTotal(data);
  }, [data]);

  return (
    <Div>
      {data.map((item, index) => (
        <Div key={item.id} mb={3} pb={1}>
          <BoxDivision
            type="accordion"
            minHeight={60}
            bg={Colors.White}
            boxShadow={Theme.shadows.light}
            borderRadius={Theme.radii.medium}
            pl={3}
            height="auto"
          >
            <Span
              alignItems={["start", "center"]}
              alignSelf={["start", "center"]}
              fontFamily={Theme.fonts.semibold}
              fontSize={Theme.fontSizes.regular}
              display="flex"
              className="col p-0"
              flexDirection={["column", "row"]}
            >
              <Span>
                {item.service.map((o) => o.name).join(" ,")}
              </Span>
              <Span ml={3}>
                <Label
                  value={item.status ? translate[item.status] : "-"}
                  bg={labelColor[item.status]}
                  color={(item.status === status.close || item.status === status.refunded)
                    ? Colors.White : Colors.Black}
                />
              </Span>
            </Span>

            <BoxDivision className="col-auto p-0" flexDirection={["column", "row"]} justifyContent="end !important" alignSelf="center">
              <MDLabel mr={2}>
                {item.created_at ? formatDate(item.created_at, twoDigitDateFormat) : ""}
              </MDLabel>
              <MDLabel mr={2}>
                SEK
                {item.total_price}
              </MDLabel>
            </BoxDivision>
            <SpanBox
              bg={Colors.Blue}
              width={40}
              textAlign="center"
              lineHeight="60px"
              borderRadius={Theme.radii.medium}
              opacity="0.5"
              onClick={handleAccordion(index)}
              type="cursor_pointer"
            >
              <MDImg type="cursor_pointer" src={activeIndex === index ? icons.UpArrow : icons.ArrowDown} alt="icon" />
            </SpanBox>
          </BoxDivision>
          <Division bg="transparent" boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)" className={activeIndex === index ? "active" : ""} type="active" p={3}>
            <BoxDivision type="place_Content" flexDirection="column">
              {renderContent(item)}
              <Div
                pb={0}
                className="row m-0"
                justifyContent={(item.status !== status.close && item.status !== status.cancelled) ? "space-between" : "end"}
              >
                {(item.status !== status.close && item.status !== status.cancelled)
                && !item.assignments.find(
                  (assignment) => assignment.status === status.close,
                ) && (
                  <Button
                    label={language.button_cancel_order}
                    secondary
                    startIcon={icons.PrimaryDeleteIcon}
                    IconHeight="18px"
                    onClick={onCancel(item)}
                  />
                )}
                <Div className="row m-0" flexDirection="column" py={3}>
                  <MDLabel alignSelf="end" mb={2} fontSize={fontSizes.xl}>
                    <MDInputValue fontSize="24px !important" opacity="0.7">
                      {language.common_total}
                      :
                      &nbsp;
                    </MDInputValue>
                    {`${language.common_sek} ${item.total_price}`}
                  </MDLabel>
                  <MDLabel alignSelf="end" mb={2} fontSize={fontSizes.regular} className="row m-0" justifyContent="end" py={1}>
                    {`${language.common_sek} ${handleCalculateTotal(data).serviceTotal}`}
                    &nbsp;
                    <MDInputValue>{language.order_min_service}</MDInputValue>
                    &nbsp;
                    +
                    &nbsp;
                    {`${language.common_sek} ${handleCalculateTotal(data).addOnTotal}`}
                    &nbsp;
                    <MDInputValue>{language.order_min_additional_options}</MDInputValue>
                  </MDLabel>
                  <MDLabel alignSelf="end" fontSize={fontSizes.regular}>
                    {`${language.payment_method}:`}
                    &nbsp;
                    <MDInputValue>{language.label_klarna}</MDInputValue>
                  </MDLabel>
                </Div>
              </Div>
            </BoxDivision>
          </Division>
        </Div>
      ))}
    </Div>
  );
};

Accordion.propTypes = {
  data: PropTypes.node,
  onCancel: PropTypes.func,
};

Accordion.defaultProps = {
  data: [],
  onCancel: () => {},
};

export default Accordion;
