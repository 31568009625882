import React, { useEffect, useState, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Div,
  P,
  Span,
  InputBox,
} from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import Map from "../Maps/Maps";
import CommonCard from "../CommonSections/CommonCards/CommonCard";
import {
  Division, BoxDivision, ContentLeftDivision, ContentRightDivision, MDLabelBox,
} from "../BookingFlowStyles/BookingFlowStyles";
import NextAndBack from "../NextAndBackButtons/NextAndBack";
import Constants from "../../../shared/Constants";
import { selectServiceLocation, getOrganisationsData, emptyBookingFlowValues } from "../../../store/actions/BookingFlowServicesAction";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import { emptyPaymentSnippet } from "../../../store/actions/PaymentAction";

const { icons } = Constants;

const BookingFlowStepThree = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const childRef = useRef(null);

  const { serviceId, serviceName } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices,
  );

  const organisationsData = useSelector(
    (state) => state.BookingFlowServicesReducer.getOrganisationDetails,
  );

  const selectedServiceLocation = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedLocation,
  );

  const { dogWeight = "" } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes,
  );

  const [organizationAddress, setOrganizationAddress] = useState([]);

  useEffect(() => {
    dispatch(emptyPaymentSnippet());
  }, []);

  useEffect(() => {
    if (serviceId && dogWeight) {
      dispatch(getOrganisationsData(serviceId, dogWeight));
    }
  }, [serviceId, dogWeight]);

  const [zoom, setZoom] = useState(8);

  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);

  useEffect(() => {
    if (steps === 1) {
      history.push(Constants.routes.chooseService?.url);
    }
  }, [steps]);

  useEffect(() => {
    const addresses = [];
    organisationsData.map((organization) => {
      addresses.push(organization.address);
      return organization;
    });
    setOrganizationAddress(addresses);
  }, [organisationsData]);

  const handleSelectedLocation = (item) => () => {
    if(!item.isDisabled){
      const {
        name, address, id, profile_pic: profilePic,
      } = item;
      const selectedPlaces = {
        organisationId: id,
        address,
        organisationName: name,
        profilePic,
      };
      if(selectedServiceLocation?.organisationId && selectServiceLocation?.organisationId !== id) {
        dispatch(emptyBookingFlowValues("location"));
      }
      dispatch(selectServiceLocation(selectedPlaces));
      setZoom(12);
      childRef.current?.handlerNextStep();
    }
  };

  const renderPlaces = organisationsData?.length > 0
    && organisationsData.map((item) => (
      <Division key={item?.name} type="select_Place" mb={3} pb={1} width="340px" setBorder onClick={handleSelectedLocation(item)}>
        <InputBox
          type="radio"
          name="dogSizes"
          id={item.id}
          title={item.name}
          checked={selectedServiceLocation?.organisationId === item.id}
          hidden
          disabled={item.isDisabled}
          ref={childRef}
        />
        <CommonCard
          htmlFor={item.id}
          avtarImg={item?.profile_pic?.url || FavIcon}
          header={item.name}
          leftIcon={icons.ServiceLocation}
          leftContent={item.address}
          fontSize={Theme.fontSizes.xs}
          fontFamily={Theme.fonts.regular}
          fontWeight={Theme.fontWeights.normal}
          paddingLeft={10}
          imagePadding={item.profile_pic ? "" : "8px"}
          width="auto !important"
          boxDivType="stepThree"
          isDisabled={item.isDisabled}
        />
      </Division>
    ));

  return (
    <Div pb={4}>
      <BoxDivision mt={4} type="choose_Address">
        <ContentLeftDivision pl={3} type="choose_Places">
          <MDLabelBox
            fontSize={Theme.fontSizes.xl}
            mb={3}
            pb={1}
            type="content_center"
          >
            <FormattedMessage id="choose_the_place" defaultMessage="Choose the Place" />
          </MDLabelBox>
          <P
            fontFamily={Theme.fonts.regular}
            fontSize={Theme.fontSizes.regular}
            color={Colors.DarkGrey}
            className="center_text"
          >
            <FormattedMessage id="places_where" defaultMessage="Here are places, where" />
            <Span fontWeight={Theme.fontWeights.bold} color={Colors.Black} paddingLeft="1px" paddingRight="8px">
              {serviceName}
            </Span>
          </P>
          <BoxDivision type="place_Content">{renderPlaces}</BoxDivision>
          <BoxDivision type="button_outer">
            <NextAndBack typeName="choose_Item" ref={childRef}/>
          </BoxDivision>
        </ContentLeftDivision>
        <ContentRightDivision pr={3} type="marker_Map">
          <Division type="width_height_map" mb={[4, 0]}>
            {organizationAddress && organizationAddress.length !== 0 && (
              <Map
                selectedLocation={selectedServiceLocation?.address}
                zoom={zoom}
                organizationAddress={organizationAddress}
              />
            )}
          </Division>
        </ContentRightDivision>
      </BoxDivision>
    </Div>
  );
};
export default memo(BookingFlowStepThree);
