import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import { Box, Div } from "../../../styles/Styles";
import PropTypes from "prop-types";
import ArrowLeft from "../../../assets/Icons/PrimaryColorArrow.svg";

const PrevNextButtons = ({ nextDisabled, handlePrevPage, handleNext, showNextButton }) => {
  return (
    <Box py={4} mb={2}>
      <Div mr={3}>
        <Button
          secondary
          startIcon={ArrowLeft}
          type="button"
          label={<FormattedMessage id="label_prev" defaultMessage="Prev" />}
          width="160px"
          onClick={handlePrevPage}
        />
      </Div>
      <Div ml={1}>
        {showNextButton &&
          <Button
            primary
            endIcon={Constants.icons.ArrowRightWhite}
            type="submit"
            label={<FormattedMessage id="label_next" defaultMessage="Next" />}
            width="160px"
            disabled={nextDisabled}
            onClick={handleNext}
          />
        }
      </Div>
    </Box>
  );
};

PrevNextButtons.propTypes = {
  nextDisabled: PropTypes.bool,
  handlePrevPage: PropTypes.func,
  handleNext: PropTypes.func,
  showNextButton:PropTypes.bool,
};

export default PrevNextButtons;
