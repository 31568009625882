import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Div, Header } from "../../../styles/Styles";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import { resetBookingFlow, setSelectedCartId } from "../../../store/actions/BookingFlowServicesAction";

const HeaderSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePush = () => {
    dispatch(setSelectedCartId(-1));
    dispatch(resetBookingFlow());
    history.push(Constants.routes.chooseService.url);
  };
  return (
    <>
      <Div className="row m-0 mt-4" justifyContent="center">
        <Button
          className="d-lg-none"
          label={<FormattedMessage id="to_the_services" defaultMessage="To the services" />}
          primary
          width={175}
          p={1}
          onClick={handlePush}
        />
      </Div>
      <Div className="container" justifyContent={["center", "space-between"]} display="flex" my={4}>
        <Div>
          <Header>
            <FormattedMessage id="title_cart" defaultMessage="Cart" />
          </Header>
        </Div>
        <Div className="row m-0">
          <Button
            className="d-none d-lg-block"
            label={<FormattedMessage id="to_the_services" defaultMessage="To the services" />}
            primary
            width={175}
            p={1}
            onClick={handlePush}
          />
        </Div>
      </Div>
    </>
  );
};

export default memo(HeaderSection);
