import React, { memo } from "react";
import PropTypes from "prop-types";
import Colors from "../../../../styles/Colors";
import { Div, Ul } from "../../../../styles/Styles";
import { ListItemBox, SpanBox } from "../../BookingFlowStyles/BookingFlowStyles";
import Theme from "../../../../styles/Theme";
import stepsCount from "./stepsNumbers.json";

const Steps = ({ currentStep }) => {
  const renderStepsCount = stepsCount.map((item, index) => (
    <ListItemBox
      key={item.number}
      type="steps_Numbers"
      position="relative"
      width="calc(100% / 5)"
      className={`${currentStep >= index + 1 ? "active" : ""} ${currentStep >= index + 2 ? "line" : ""}`}
    >
      <SpanBox
        type="step_Number"
        lineHeight={2.5}
        width={40}
        height={40}
        background={Colors.Blue}
        fontFamily={Theme.fonts.semibold}
        fontWeight={Theme.fontWeights.medium}
        borderRadius={Theme.radii.medium}
        className={`${currentStep >= index + 1 ? "active" : ""}`}
      >
        {item.number}
      </SpanBox>
    </ListItemBox>
  ));
  return (
    <Div mt={30}>
      <Ul display="flex" className="container" p={0} justifyContent="center">
        {renderStepsCount}
      </Ul>
    </Div>
  );
};
Steps.propTypes = {
  currentStep: PropTypes.number,
};
Steps.defaultProps = {
  currentStep: () => {},
};
export default memo(Steps);
