import React, { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import styled from "styled-components";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Div, MDLabel, Span, MDImage, FormWrapper, IsDesktop, IsMobile,
  Card,
} from "../../../styles/Styles";
import Modal from "../../../components/Modal/Modal";
import Popup from "../../Assignments/Components/Modals";
import Constants from "../../../shared/Constants";
import PlusIcon from "../../../assets/Icons/PlusIccon.svg";
import Button from "../../../components/Button/Button";
import ProfileCard from "../../../components/Card/ProfileCard";
import { setLoader } from "../../../store/actions/AppAction";
import { getClients } from "../../../services/AssignmentService";
import { OrderPageLoader } from "../../../components/Loader/FormLoader";
import { toJson } from "../../../models/Orders";
import { addOrder } from "../../../services/OrdersService";
import { ChangeButton, DateCard } from "../../Assignments/Styles";
import { converTimeFrom24To12, formatDate } from "../../../shared/utils";
import Colors from "../../../styles/Colors";
import usePermissions, { ModulePermissions } from "../../../shared/usePermissions";

const StyledCard = styled(Card)`
  height: 65px;
  padding: 12px;
  margin-right: 30px;

  span {
    justify-content: center;
  }
`;

const {
  language, icons, input: { name: inputs, format: { dotted } }, errorMessages,
  themes: { widths, fontSizes, colors },

} = Constants;

const CreateOrderForm = ({
  isLoading, isLoader, handleBackToOrders,
}) => {
  const [isModalOpen, setModalOpen] = useState("");
  const [organisations, setOrganisations] = useState([]);
  const [clients, setClients] = useState([]);
  const [data, setData] = useState([{}]);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [duration, setDuration] = useState(0);
  const [index, setIndex] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isClientBanned, setIsClientBanned] = useState(false);
  const openModal = useRef(null);
  const [selectedDogSize, setSelectedDogSize] = useState("");
  const userInfo = useSelector((state) => state.AppReducer.userDetails);
  const [getOrganizations] = usePermissions();

  const handleValidation = (filteredData = data) => {
    const valid = [];
    filteredData.map((obj) => {
      if (!(obj && obj.place && obj.client
        && obj.service && obj.serviceProvider && obj.dog && (obj.time || obj.startEndDate))) {
        valid.push(false);
      }
      return obj;
    });
    setIsDisabled(valid.length > 0);
  };

  const handleRemovePart = async (value) => {
    const filteredData = data.filter((n, ind) => ind !== value);
    setData(filteredData);
    handleValidation(filteredData);
  };

  const fetchOrganisations = async () => {
    isLoading(true);
    setOrganisations(getOrganizations(ModulePermissions.Order, Constants.WRITE));
    isLoading(false);
  };

  const fetchClients = async () => {
    isLoading(true);
    try {
      const response = await getClients();
      let { data: customers = [] } = response;
      customers = customers.map((o) => {
        const {
          full_name: fullName = "", name = "", id: clientId = "", profile_pic: profilePic = {},
        } = o;
        const { url = "" } = profilePic || {};
        return ({ label: fullName || name, value: clientId, profilePic: url });
      });
      setClients(customers);
      isLoading(false);
    } catch (e) {
      isLoading(false);
    }
  };

  const calculateTotal = () => {
    let calctotal = 0;
    let calcDuration = 0;
    data.map((calc) => {
      const { service = {}, dog = {}, startEndDate = {} } = calc;
      if (dog) {
        const { dog_size_weight = "" } = dog;
        const { price = {}, serviceDuration = {}, is_full_day_service = false } = service;
        const { date = "", endDate = "" } = startEndDate;
        const units = is_full_day_service ? moment(endDate,"YYYY-MM-DD").diff(moment(date,"YYYY-MM-DD"), "days") : 1;
        calctotal += Number(price[dog_size_weight]) * units;
        calcDuration += Number(serviceDuration[dog_size_weight]);
        if (calc.description) {
          calc.description.map((obj) => {
            calctotal += Number(obj.price);
            calcDuration += Number(obj.duration);
            return obj;
          });
        }
      }
      return calc;
    });
    setDuration(calcDuration);
    setTotal(calctotal);
  };

  const getTotalDuration = () => {
    const text = data
      ?.map((calc) => {
        const { service = {}, dog = {}, startEndDate = {} } = calc;
        let context = "";
        if (dog) {
          const { dog_size_weight = "" } = dog;
          const { date = "", endDate = "" } = startEndDate;
          const { serviceDuration = {}, is_full_day_service } = service;
          if (is_full_day_service) {
            const days = ((new Date(endDate)?.getTime() - new Date(date)?.getTime()) / (1000 * 3600 * 24)) + 1;
            context = days + " " + language.days;
          } else {
            context = Number(serviceDuration[dog_size_weight]) || 0;
            if (calc.description) {
              calc.description.map((obj) => {
                context += Number(obj.duration);
              });
            }
            context = context + " " + language.min;
          }
          return context;
        }
        return context;
      })
      .filter((o) => o).join(" + ");
    return text;
  };

  const handleSelectedData = (name, value) => {
    isLoading(true);
    let values = data;
    switch (name) {
    case inputs.place:
      if (values[index][name] !== value) {
        values[index].serviceProvider = "";
        values[index].room = "";
        values[index].service = "";
        values[index].description = "";
      }
      break;
    case inputs.client:
      setIsClientBanned(false);
      if (values[index][name] !== value) {
        values = values.map((o) => ({ ...o, client: value, dog: "" }));
        values[index].dog = "";
      }
      break;
    case inputs.service:
      if (values[index][name] !== value) {
        values[index].serviceProvider = "";
        values[index].room = "";
        values[index].description = "";
      }
      break;
    case inputs.dog:
      if (values[index][name] !== value) {
        setSelectedDogSize(value.dog_size_weight);
      }
      break;
    default:
      break;
    }
    values[index][name] = value;
    setData(values);
    setModalOpen("");
    calculateTotal();
    isLoading(false);
    handleValidation();
  };

  const handleSubmit = async () => {
    isLoading(true);
    await addOrder(toJson({ payload: data, duration, total })).then((response) => {
      if (response) {
        isLoading(false);
        setIsClientBanned(false);
        handleBackToOrders(true);
      }
    }, (error) => {
      const { response = {} } = error;
      const { data: { message = "" } = {} } = response || {};
      isLoading(false);
      if (message === errorMessages.bannedCustomer) {
        setIsClientBanned(true);
      } else {
        setIsClientBanned(false);
        handleBackToOrders(false);
      }
      console.error(error);
    });
  };

  const handleCancel = () => {
    setIsClientBanned(false);
    setData([{}]);
  };

  useEffect(() => {
    setIsDisabled(true);
    fetchOrganisations();
    fetchClients();
  }, [userInfo]);

  return (
    <FormWrapper width="870px">
      {isLoader && <OrderPageLoader />}
      {!isLoader && (
        <>
          {data && data.map((part, indice) => (
            <Div mb={4} key={`part-${[indice]}`}>
              <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="24px">
                {data.length > 1 && (
                  <MDImage
                    src={Constants.icons.MinusIcon}
                    alt="icon"
                    onClick={() => handleRemovePart(indice)}
                    role="button"
                    mr={3}
                  />
                )}
                <Span mr={3} textAlign="left">
                  {`${language.common_part} ${indice + 1}`}
                </Span>
              </MDLabel>
              <Div className="row m-0">
                <Div className="col-lg-4 col-md-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.label_client}
                  </MDLabel>
                  {part.client ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.client.profilePic || ""}
                        data={part.client}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("client");
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="common_change" />
                        </ChangeButton>
                      </MDLabel>
                      {isClientBanned && (
                        <MDLabel my={3} fontSize={fontSizes.small} color={colors.error}>
                          <FormattedMessage id="customer_is_banned" defaultMessage="This customer is banned" />
                        </MDLabel>
                      )}
                    </>
                  ) : (
                    <Button
                      size="small"
                      padding="2px 8px"
                      label={<FormattedMessage id="common_edit" />}
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("client");
                      }}
                    />
                  )}
                </Div>
                <Div className="col-lg-4 col-md-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.label_dog}
                  </MDLabel>
                  {part.dog ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.dog.profilePic || ""}
                        data={part.dog}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("dog");
                          setId(part.client.value);
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="common_change" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("dog");
                        setId(part.client.value);
                      }}
                      disabled={!part.client}
                    />
                  )}
                </Div>
              </Div>
              <Div className="row m-0">
                <Div className="col-lg-4 col-md-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.label_place}
                  </MDLabel>
                  {part.place ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.place.profilePic ? part.place.profilePic : ""}
                        data={part.place}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("place");
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="assignment_change_place" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("place");
                      }}
                    />
                  )}
                </Div>
                <Div className="col-lg-4 col-md-12 p-0">
                  <Div className="row m-0" justifyContent="space-between" alignItems="center">
                    <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                      {language.label_service}
                    </MDLabel>
                  </Div>
                  {part.service ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.service.profilePic ? part.service.profilePic : ""}
                        data={part.service}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("service");
                          setId(part.place.value);
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="assignment_change_service" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("service");
                        setId(part.place.value);
                      }}
                      disabled={!part.place}
                    />
                  )}
                </Div>
              </Div>
              <Div className="row m-0">
                <Div className="col-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.label_additional_options}
                  </MDLabel>
                  {part.description ? (
                    <>
                      <Div className="row m-0 p-0">
                        {part.description.map((obj) => (
                          <Div mr={3} mt={2}>
                            <Card p={3} width="fit-content">
                              {obj.label}
                            </Card>
                          </Div>
                        ))}
                      </Div>
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("description");
                          setId(part.service.value);
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="common_change" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("description");
                        setId(part.service.value);
                      }}
                      disabled={!part.service}
                    />
                  )}
                </Div>
              </Div>
              <Div className="row m-0">
                <Div className="col-lg-4 col-md-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.common_service_provider}
                  </MDLabel>
                  {part.serviceProvider ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.serviceProvider.profilePic ? part.serviceProvider.profilePic : ""}
                        data={part.serviceProvider}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("serviceProvider");
                          setId(part.service.value);
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="assignment_change_service_provider" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      startIcon={Constants.icons.WhiteEditIcon}
                      disabled={!part.service}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("serviceProvider");
                        setId(part.service.value);
                      }}
                    />
                  )}
                </Div>
                <Div className="col-lg-4 col-md-12 p-0">
                  <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                    {language.common_room}
                  </MDLabel>
                  {part.room ? (
                    <>
                      <ProfileCard
                        width="255px"
                        height="64px"
                        userAvtar={part.room.profilePic ? part.room.profilePic : ""}
                        data={part.room}
                        disableData
                        showCursor
                      />
                      <MDLabel
                        className="mt-3"
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("room");
                          setId(part.service.value);
                        }}
                      >
                        <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                        <ChangeButton>
                          <FormattedMessage id="assignment_change_room" />
                        </ChangeButton>
                      </MDLabel>
                    </>
                  ) : (
                    <Button
                      label={<FormattedMessage id="common_edit" />}
                      size="small"
                      padding="2px 8px"
                      disabled={!part.service}
                      startIcon={Constants.icons.WhiteEditIcon}
                      onClick={() => {
                        setIndex(indice);
                        setModalOpen("room");
                        setId(part.service.value);
                      }}
                    />
                  )}
                </Div>
                {part?.service?.is_full_day_service ?
                  (<Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
                    <MDLabel mb={3} fontSize={fontSizes.medium}>
                      <FormattedMessage id="label_date" defaultMessage="Date" />
                    </MDLabel>
                    {part?.startEndDate ? (
                      <>
                        <Div display="flex">
                          <StyledCard mr={2}>
                            <MDLabel fontSize={fontSizes.xs} color={Colors.DarkGrey} mb={2}>Start date</MDLabel>
                            <MDLabel fontSize={fontSizes.regular} >{part?.startEndDate ? formatDate(part?.startEndDate?.date, dotted) : formatDate(part?.startEndDate?.date, dotted)}</MDLabel>
                          </StyledCard>
                          <StyledCard>
                            <MDLabel fontSize={fontSizes.xs} color={Colors.DarkGrey} mb={2}>End date</MDLabel>
                            <MDLabel fontSize={fontSizes.regular}>{part?.startEndDate ? formatDate(part?.startEndDate?.endDate, dotted) : formatDate(part?.startEndDate?.endDate, dotted)}</MDLabel>
                          </StyledCard>
                        </Div>
                        <MDLabel mt={3}  onClick={() => {
                          setIndex(indice);
                          setModalOpen("startEndDate");
                          setId(part.service.value);
                        }}>
                          <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                          <ChangeButton>
                            <FormattedMessage id="edit" defaultMessage="Edit" />
                          </ChangeButton>
                        </MDLabel>
                      </>
                    ) : (
                      <Button
                        label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                        size="small"
                        startIcon={icons.WhiteEditIcon}
                        padding="2px 8px"
                        disabled={!(part.serviceProvider)}
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("startEndDate");
                          setId(part.service.value);
                        }}
                      />
                    )}
                  </Div>)
                  : (<Div className="col-lg-4 col-md-12 p-0">
                    <MDLabel className="pb-2 pt-3" lineHeight="19px" fontSize="16px">
                      {language.label_time_date}
                    </MDLabel>
                    {part.time ? (
                      <>
                        <DateCard date width={widths.w125}>
                          <MDLabel fontSize={fontSizes.regular}>{part.time.date}</MDLabel>
                        </DateCard>
                        <DateCard width={widths.w125}>
                          <MDLabel fontSize={fontSizes.regular}>
                            {part.time.from ? converTimeFrom24To12(part.time.from) : ""}
                          </MDLabel>
                        </DateCard>
                        <MDLabel
                          className="mt-3"
                          onClick={() => {
                            setIndex(indice);
                            setModalOpen("time");
                            setId(part.service.value);
                          }}
                        >
                          <MDImage src={Constants.icons.EditIcon} alt="icon" className="mr-1" role="button" />
                          <ChangeButton>
                            <FormattedMessage id="assignment_edit_time_date" />
                          </ChangeButton>
                        </MDLabel>
                      </>
                    ) : (
                      <Button
                        label={<FormattedMessage id="common_edit" />}
                        size="small"
                        padding="2px 8px"
                        startIcon={Constants.icons.WhiteEditIcon}
                        onClick={() => {
                          setIndex(indice);
                          setModalOpen("time");
                          setId(part.service.value);
                        }}
                        disabled={!(part.serviceProvider)}
                      />
                    )}
                  </Div>)}
              </Div>
            </Div>
          ))}
          <>
            <Div className="row my-sm-3 mt-sm-3 m-0">
              <Button
                label={<FormattedMessage id="button_new_part_order" />}
                primary
                size="small"
                padding="2px 8px"
                startIcon={PlusIcon}
                disabled={isDisabled}
                onClick={() => {
                  setIsDisabled(true);
                  setData([
                    ...data,
                    { client: data[0].client },
                  ]);
                }}
              />
            </Div>
            <MDLabel className="pb-4 pt-3" lineHeight="19px" fontSize="24px">
              {language.common_total}
            </MDLabel>
            <MDLabel className="pb-2" lineHeight="19px" fontSize="18px">
              {language.label_duration}
            </MDLabel>
            <MDLabel className="pb-4" lineHeight="19px" fontSize="16px">
              {getTotalDuration()}
&nbsp;
              {language.total}
            </MDLabel>
            <MDLabel className="pb-2" lineHeight="19px" fontSize="18px">
              {language.common_cost}
            </MDLabel>
            <MDLabel className="pb-4" lineHeight="19px" fontSize="16px">
              {total}
&nbsp;SEK
            </MDLabel>
          </>
          <IsDesktop className="d-lg-flex mt-2">
            <Button
              startIcon={icons.AssignmentsMenuIcon}
              IconHeight="18px"
              label={<FormattedMessage id="common_save" defaultMessage="Save" />}
              width="200px"
              className="mr-3"
              disabled={isDisabled}
              onClick={handleSubmit}
            />
            <Button
              secondary
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              width={widths.w200}
              className="mx-3"
              onClick={handleCancel}
            />
          </IsDesktop>
          <IsMobile className="mt-2">
            <Button
              startIcon={icons.AssignmentsMenuIcon}
              IconHeight="18px"
              label="Save"
              width="100%"
              className="mb-3"
              disabled={isDisabled}
              onClick={handleSubmit}
            />
            <Button
              secondary
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              width={widths.full}
              type="reset"
              className="mb-4 mt-1"
              onClick={handleCancel}
            />
          </IsMobile>
          <a
            href
            data-toggle="modal"
            data-target="#Modal"
            data-backdrop="static"
            data-keyboard="false"
            ref={openModal}
          />
          {isModalOpen && (
            <Modal>
              <Popup
                data={isModalOpen === "client" ? clients : isModalOpen === "place" ? organisations : []}
                values={data[index]}
                handleSubmit={handleSelectedData}
                name={isModalOpen}
                id={id}
                order
                dogSize={selectedDogSize}
                onCancel={() => {
                  setModalOpen("");
                }}
              />
            </Modal>
          )}
        </>
      )}
    </FormWrapper>
  );
};

CreateOrderForm.propTypes = {
  isLoading: PropTypes.func,
  isLoader: PropTypes.bool,
  handleBackToOrders: PropTypes.func,
};

CreateOrderForm.defaultProps = {
  isLoading: undefined,
  isLoader: false,
  handleBackToOrders: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (payload) => dispatch(setLoader(payload)),
  };
}

const mapStateToProps = (state) => ({
  isLoader: state.AppReducer.isLoader,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CreateOrderForm);
