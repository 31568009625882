import React from "react";
import moment, { now } from "moment";
import { Div, MDLabel } from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import Colors from "../../../styles/Colors";
import { formatDate } from "../../../shared/utils";

const LABEL_ALL = "All";

const MonthViewEvent = ({ showServiceProvider, eventInfo }) => {
  const isMobile = window.screen.width < 992;

  const renderEventContent = () => {
    const {
      input: { format },
    } = Constants;

    const { value: selectedServiceProvider = LABEL_ALL } = showServiceProvider;
    /**
     *    Get Unique Service providers from the availability
     */
    const slots = eventInfo.event.extendedProps?.slotDetails || [];
    const serviceProviderAvailability = [...new Set(slots.filter((i) => i.sp_id).map((j) => j.sp_id))];
    const getServiceProviderLabel = () => {
      if(selectedServiceProvider !== LABEL_ALL) {
        return selectedServiceProvider;
      } else if(isMobile) {
        return `SP: ${serviceProviderAvailability?.length}`;
      } else {
        return `${Constants.language.common_service_providers}: ${
          serviceProviderAvailability?.length}`
      }
    };

    const getOrderLabel = () => {
      if(isMobile){
        return `O: ${eventInfo.event.extendedProps?.count}`;
      }
      return `${Constants.language.common_orders} (${Constants.language.label_full_day}): ${eventInfo.event.extendedProps?.count}`;
    }

    if (eventInfo.event.extendedProps.type === "available" || eventInfo.event.extendedProps.type === "adminAvailable") {
      const dateAvailable = formatDate(eventInfo.event.start, format.date);
      const currentDate = moment(now()).format(format.date);
      return (
        <Div
          display="flex"
          color={Colors.White}
          className={`available-btn ${dateAvailable >= currentDate ? "" : "available-btn-dark"}`}
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          width="auto !important"
          ml={2}>
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            color={dateAvailable >= currentDate ? Colors.Black : ""}>
            <MDLabel fontSize="11px" lineHeight="13px" wordbreak="break-all" whiteSpace="break-spaces" >
              {getServiceProviderLabel()}
            </MDLabel>
          </Div>
        </Div>
      );
    }

    const dateAvailable = formatDate(eventInfo.event.start, format.date);
    const currentDate = moment(now()).format(format.date);
    return (
      <Div
        display="flex"
        color={Colors.Black}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        bg={dateAvailable >= currentDate ? Colors.Blue : Colors.LightGrey}
        ml={2}
        mt={1}
        borderRadius="10px">
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          borderRadius="10px"
          px={2}
          py={2}>
          <MDLabel alignSelf="center" fontSize="11px" lineHeight="12px">
            {getOrderLabel()}
          </MDLabel>
        </Div>
      </Div>
    );
  };
  return renderEventContent();
};

export default MonthViewEvent;
