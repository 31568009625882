import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from "../../components/Containers/PortalContainer";
import CreateOrderForm from "./Components/CreateOrderForm";
import Constants from "../../shared/Constants";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";

const { language } = Constants;

const AddOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = new AuthService(Config.apiBase);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [assignmentCreated, setassignmentCreated] = useState(false);

  /* Scroll to top when navigated from calendar screen */
  useEffect(() => {
    const location = history.location || {};
    const state = location.state || {};
    const sourcePage = state.fromPage || "";
    if (sourcePage === Constants.input.name.calendar) {
      window.scrollTo(0, 0);
    }
    return "";
  }, []);

  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackToOrders = () => {
    getDetails();
    setassignmentCreated(false);
    setAlertMessage("");
    setAlertHeader("");
    history.goBack();
  };

  const handleAlertModal = (isSaved = false) => {
    if (isSaved) {
      getDetails();
      setassignmentCreated(true);
      setAlertMessage(language.alert_order_created);
    } else {
      setassignmentCreated(true);
      setAlertMessage(language.alert_try_later);
      setAlertHeader(language.header_error);
    }
  };

  return (
    <Container>
      <CommonAddPage
        handleBack={handleBackToOrders}
        breadCrumbLabel="breadcrumb_back"
        header={Constants.language.add_new_order}
      />
      <CreateOrderForm handleBackToOrders={handleAlertModal} history={history} />
      {assignmentCreated && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleBackToOrders}
          />
        </Modal>
      )}
    </Container>
  );
};

export default AddOrder;
