import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Constants from "../../shared/Constants";
import Theme from "../../styles/Theme";
import Label from "../../components/Label/Label";
import {
  MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";

const CommonBreadCrumbSection = ({
  handleBack,
  breadCrumbLabel,
  header,
  description,
  status,
}) => {
  const {
    icons, assignmentHeaderTranslations: translate, labelColor, status: { close, cancelled },
  } = Constants;
  return (
    <>
      <Div>
        {breadCrumbLabel && (
          <Div display="flex">
            <MDLabel cursor="pointer" onClick={handleBack}>
              <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
              <FormattedMessage id={breadCrumbLabel} />
            </MDLabel>
          </Div>
        )}
        <>
          {header && (
            <Div className="row m-0 justify-content-between">
              <Header className="py-2" fontSize={["24px", "36px"]}>
                {header}
              </Header>
              {status && (
                <Label
                  value={translate[status]}
                  bg={labelColor[status]}
                  color={status === close || status === cancelled
                    ? Theme.colors.white : Theme.colors.black}
                />
              )}
            </Div>
          )}
          {description && (
            <MDLabel fontSize={["18px", "24px"]} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
              <FormattedMessage id={description} />
            </MDLabel>
          )}
        </>
      </Div>
    </>
  );
};
CommonBreadCrumbSection.propTypes = {
  handleBack: PropTypes.func,
  breadCrumbLabel: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
};
CommonBreadCrumbSection.defaultProps = {
  handleBack: undefined,
  breadCrumbLabel: null,
  header: null,
  description: null,
  status: "",
};
export default CommonBreadCrumbSection;
