import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from "date-fns/locale/sv";
import { CalendarWrapper } from "./Styles";
import Constants from "../../shared/Constants";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import InputDateBox from "./TextBox";

registerLocale("sv", sv);

const { en, input: { format: { date: dateFormat } } } = Constants;

const Input = (props) => {
  const {
    props: {
      name = "", onChange = () => {}, placeholder = "", disabled = false, value = "",
    } = {}, onClick, formikValues, label = "", width = "",
  } = props;
  return (
    <>
      <InputDateBox
        label={label}
        width={width}
        onClick={onClick}
        name={name}
        onChange={onChange}
        value={value ? `${value}, ${moment(value, "YYYY-MM-DD HH:mm:ss").format("dddd")}` : ""}
        placeholder={placeholder}
        disabled={disabled}
        hasEndIcon
        IconHeight="20px"
        endIcon={CalendarIcon}
        formikValues={formikValues}
      />
    </>
  );
};
Input.propTypes = {
  /**
   * Formik values
   */
  formikValues: PropTypes.string,
  /**
   * props
   */
  props: PropTypes.string,
  /**
   * onclick handler
   */
  onClick: PropTypes.func,
  /**
   * label
   */
  label: PropTypes.string,
  /**
   * width of the input
   */
  width: PropTypes.string,
};
Input.defaultProps = {
  formikValues: {},
  props: {},
  onClick: () => {},
  label: null,
  width: "auto",
};

export default class CustomDatePicker extends Component {
  /**
   * @callback
   * Handles change in input field and informs parent about the change of value
   * @param {event} event
   */
  handleChange = (event) => {
    const { name, onChange } = this.props;
    const date = moment(event, dateFormat).format(dateFormat);
    if (onChange) {
      onChange(name, date);
    }
  };

  render() {
    const {
      value, disabled,
      minDate, maxDate, width,
    } = this.props;
    const date = value ? new Date(value) : "";
    const locale = en;
    return (
      <CalendarWrapper width={width}>
        <DatePicker
          value={value ? moment(value, dateFormat).format(dateFormat) : ""}
          dateFormat="YYYY-MM-DD"
          customInput={<Input props={this.props} width={width} />}
          selected={date}
          onChange={this.handleChange}
          disabled={disabled}
          locale={locale}
          minDate={minDate || ""} // momemnt formatted date not working
          maxDate={maxDate ? moment(maxDate, dateFormat).format(dateFormat) : ""}
        />
      </CalendarWrapper>
    );
  }
}
CustomDatePicker.propTypes = {
  /**
   * value of the input
   */
  value: PropTypes.string,
  /**
   * Disable the inputmn
   */
  disabled: PropTypes.bool,
  /**
   * name of the input
   */
  name: PropTypes.string,
  /**
   * Onchange of value handler
   */
  onChange: PropTypes.func,
  maxDate: PropTypes.node,
  minDate: PropTypes.node,
  width: PropTypes.string,
};
CustomDatePicker.defaultProps = {
  value: null,
  disabled: false,
  name: null,
  onChange: () => {},
  maxDate: "",
  minDate: "",
  width: "100%",
};
