export const SET_LOADER = "setLoader";

export const FETCH_ROOMS_TRIAL = "fetchRoomsTrial";

export const SET_LOGIN_USER = "setLoginUser";

export const SET_USER_DATA = "setUserData";

export const FETCH_CALENDAR_EVENTS = "fetchCalendarEvents";

export const SAVE_AVAILABLE_DATE_RANGE = "saveAvailableDateRange";

export const DOG_BREEDS = "dogBreeds";

export const FILTERS = "filters";

export const ROOMS_FILTERS = "roomsFilters";

export const CUSTOMERS_FILTERS = "customersFilters";

export const USERS_FILTERS = "usersFilters";

export const SERVICES_FILTERS = "servicesFilters";

export const ORDERS_SERVICES_FILTERS = "ordersServicesFilter"

export const ORGANIZATION_FILTERS = "organizationsFilters";

export const CATEGORY_FILTERS = "categoryFilters";

/* BOOKING FLOW CONSTANTS */
export const CHOOSE_SERVICE = "chooseServices";

export const GET_CATEGORIES_LIST = "getCategoriesList";

export const GET_DOG_DETAILS = "getDogDetails";

export const CHOOSE_SERVICE_LIST = "chooseServiceList";

export const SELECTED_PREVIOUS_STEP = "selectedPreviousStep";

export const SELECTED_NEXT_STEP = "selectedNextStep";

export const SELECTED_DOG_SIZE = "selectedDogSize";

export const SELECTED_SERVICE_LOCATION = "selectedServiceLocaiton";

export const SELECTED_SERVICE_PROVIDER = "selectedServiceProvider";

export const SELECTED_BOOKING_DATE = "selectedBookingDate";

export const SELECT_BOOKING_DATE = "selectBookingDate";

export const SELECTED_BOOKING_MONTH = "selectedBookingMonth";

export const GO_TO_SELECTED_SERVICES = "goToSelectedServices";

export const BANNED_POPUP = "bannedPopup";

export const GO_TO_NEXT_STEP = "goToNextStep";

export const SET_CALENDAR_PAYLOAD = "setOrganisation";

export const SET_ORGANISATION_RECORD = "setOrganisationRecord";

export const SET_ORGANISATION_SCHEDULE = "setOrganisationSchedule";

export const SET_ORGANISATION_PROVIDERS = "setOrganisationProviders";

export const SET_ORGANISATION_PROVIDERS_SERVICE = "setOrganisationProvidersService";

export const ADDONS_TO_STORE = "addOnsToStore";

export const EMPTY_ADDONS_TO_STORE = "EMPTY_ADDONS_TO_STORE";

export const ADD_TO_GUEST_CART = "addToGuestCart";

export const ADD_TO_GUEST_CHECKOUT = "addToGuestCheckout";

export const RESET_BOOKING_FLOW = "resetBookingFlow";

export const SET_FLAG = "setFlag";

export const SET_ERROR = "setError";

export const SET_STEP_AND_CONTENT = "setStepAndContent";

export const SET_USER_CART_DATA = "setUserCartData";

// PAYMENT CONSTANTS
export const LOAD_CART_CONTENT = "loadCartContent";

export const MOVE_TO_NEXT_PAGE = "moveToNextPage";

export const MOVE_TO_PREV_PAGE = "moveToPrevPage";

export const SUBMIT_ABOUT_DOG_FORM = "submitAboutDogForm";

export const SUBMIT_CONTACT_FORM = "submitContactForm";

export const SET_PAYMENT_REQUEST = "setPaymentRequest";

export const REQUEST_PAYMENT = "requestPayment";

export const PAYMENT_SNIPPET = "paymentSnippet";

export const PAYMENT_COMPLETE = "PAYMENT_COMPLETE";

export const ORDER_DATA = "ORDER_DATA";

export const PICK_UP = "PICK_UP";

export const ORDER_CONFIRM = "ORDER_CONFIRM";

export const ADD_CART_ID = "ADD_CART_ID";

export const CART_COUNT = "CART_COUNT";

export const FETCH_AVAILABILITY = "fetchAvailability";

export const GET_SERVICE_PROVIDER = "getServiceProvider";

export const GET_SLOT_AVAILABILITY = "storeSlotAvailability";

export const GET_USER_SERVICES = "getUserServices";

export const GET_SLOT_DATA = "getSlotData";

export const UPDATE_SLOT_DATA = "updateSlotData";

export const DELETE_AVAILABILITY = "deleteAvailability";

export const DELETE_AVAILABILITY_DETAILS = "deleteAvailabilityDetails";

export const SET_CALENDAR_EVENTS = "setCalendarEvents";

export const GET_CART_ID = "getCartId";

export const SET_SELECTED_CART_ID = "setSelectedCartId";

export const DESTROY_SESSION = "destroy_session";

export const GET_AVAILABLE_DATES = "getAvailableDates";

export const SET_LOADING = "SET_LOADING";

export const AVAILABILITY_ERROR_POPUP = "availability_error";

export const EMAIL_ERROR_POPUP = "email_error";

export const STORE_ROOMS_FILTERS = "STORE_ROOMS_FILTERS"

export const STORE_CUSTOMERS_FILTERS = "STORE_CUSTOMERS_FILTERS";

export const STORE_SERVICES_FILTERS = "STORE_SERVICES_FILTERS";

export const STORE_ORDERS_FILTERS = "STORE_ORDERS_FILTERS";

export const STORE_ASSIGNMENTS_FILTERS = "STORE_ASSIGNMENTS_FILTERS";

export const STORE_SUBSCRIPTION_FILTERS = "STORE_SUBSCRIPTION_FILTERS";

export const EMPTY_CHOOSEN_VALUES = "EMPTY_CHOOSEN_VALUES";