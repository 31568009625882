import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Theme from "../../../styles/Theme";
import HeadSection from "./HeadSection";
import CardContainer from "./CardContainer";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import { ButtonBox } from "../Style";
import { Div, MDLabel, Box } from "../../../styles/Styles";
import TextBox from "../../../components/Input/TextBox";
import { setLoginUser } from "../../../store/actions/AppAction";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" defaultMessage="Invalid email" />)
    .required(<FormattedMessage id="validation_empty_email_input" defaultMessage="Enter email" />),
  password: Yup.string()
    .min(8, Constants.language.validation_length_password_input)
    .matches(
      /^.*(?=.{8,})((?=.*[!~@#$%^&*/?()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      Constants.language.validation_valid_password_input
    )
    .required(Constants.language.validation_empty_password_input),
});

const GuestOrderConfirm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disableBtn, setDisableBtn] = useState(false);

  const { language, routes } = Constants;
  const { location = {} } = history;
  const { email = "" } = location.state || {};
  const { redirectRoute = "" } = location.state || {};

  const getLoginUser = (payload) => dispatch(setLoginUser(payload));

  const handleLogin = (data) => {
    setDisableBtn(true);
    getLoginUser(data).then(
      (res) => {
        localStorage.setItem("userData", JSON.stringify(res?.user));
        localStorage.setItem("token", res?.token);
        sessionStorage.removeItem("guestEmail");
        setDisableBtn(false);
        history.push(redirectRoute ? redirectRoute : routes.cart.url);
      },
      (e) => {
        setDisableBtn(false);
        console.error(e);
      }
    );
  };

  const handleGoBack = () => {
    history.push(redirectRoute ? redirectRoute : routes.cart.url);
  };

  return (
    <>
      <Div className="container" px={2}>
        <HeadSection fontProp={Theme.fontSizes.header} headerTitle="Order status" />
        <CardContainer cardTitle={<FormattedMessage id="label_details" defaultMessage="Details" />}>
          <Box mt={4}>
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="is_that_you_label" defaultMessage="Is that you?" />
            </MDLabel>
          </Box>
          <Box mt={4} px={2}>
            <MDLabel>
              <FormattedMessage
                id="already_registered_email_label"
                defaultMessage="E-mail sample@gmail.com is already registered in MyDogCare system. Please, log in here to send your application for Daycare Subscribtion."
                values={{ email }}
              />
            </MDLabel>
          </Box>
          <Formik initialValues={{ email, password: "" }} validationSchema={loginSchema} onSubmit={handleLogin}>
            {(formik) => {
              const { values, handleChange, handleBlur, handleSubmit, isValid, touched } = formik;
              return (
                <Form>
                  <>
                    <Box mt={4}>
                      <MDLabel>
                        <FormattedMessage id="email" defaultMessage="E-mail:" />
                      </MDLabel>
                    </Box>
                    <Box mt={1}>{email}</Box>
                    <Box mt={1}>
                      <TextBox
                        onChange={handleChange}
                        name="password"
                        onBlur={handleBlur}
                        formikValues={formik}
                        value={values.password}
                        IconHeight="20px"
                        label={language.login_label_password}
                        placeholder={language.login_label_password}
                        type={Constants.input.type.password}
                      />
                    </Box>
                    <Box mt={4}>
                      <ButtonBox>
                        <Button
                          width="200px"
                          disable={!(isValid && Object.keys(touched).length > 0) || disableBtn}
                          onClick={handleSubmit}
                          type="payment_button"
                          primary
                          label={language.login_submit_button_log_in}
                        />
                      </ButtonBox>
                    </Box>
                    <Box mt={4} pt={2}>
                      <MDLabel fontSize={Theme.fontSizes.xl}>
                        <FormattedMessage id="not_you_label" defaultMessage="Not you?" />
                      </MDLabel>
                    </Box>
                  </>
                </Form>
              );
            }}
          </Formik>
          <Box mt={4}>
            <MDLabel>
              <FormattedMessage
                id="change_email_label"
                defaultMessage="Please, change your e-mail into application form and try again."
              />
            </MDLabel>
          </Box>
          <Box mt={4}>
            <ButtonBox>
              <Button
                width="200px"
                type="payment_button"
                secondary
                label={language.common_change}
                onClick={handleGoBack}
              />
            </ButtonBox>
          </Box>
        </CardContainer>
      </Div>
    </>
  );
};

export default GuestOrderConfirm;
