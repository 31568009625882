import Colors from "./Colors";

const Theme = {
  colors: {
    primary: Colors.Primary,
    secondary: Colors.Secondary,
    error: Colors.Red,
    white: Colors.White,
    black: Colors.Black,
    transparent: Colors.Transparent,
    modalBg: Colors.ModalBg,
    blue: Colors.Blue,
    text: {
      black: Colors.Black,
      lightgrey: Colors.LightGrey,
      darkGrey: Colors.DarkGrey,
      white: Colors.White,
      body: Colors.Black,
    },
  },
  radii: {
    none: 0,
    small: 2,
    regular: 5,
    medium: 10,
    large: "50%",
  },
  borders: {
    none: "none",
  },
  borderWidths: {
    small: 1,
    regular: 2,
    none: "none",
  },
  letterSpacings: {
    normal: 0,
    wide: 0.4,
    wider: 0.8,
    widest: 1.6,
  },
  lineHeights: {
    none: 1,
    tight: 1.25,
    normal: "normal",
    relaxed: 1.625,
    loose: 2,
    body: 1.625,
    heading: 1.25,
  },
  fontWeights: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
    body: "400",
    heading: "700",
  },
  fonts: {
    regular: "Roboto",
    semibold: "Rubik-Medium",
  },
  fontSizes: {
    xs: "12px",
    es: "11px",
    small: "14px",
    regular: "16px",
    medium: "18px",
    large: "20px",
    xl: "24px",
    header: "36px",
  },
  space: {
    xs: "4px",
    s: "8px",
    regular: "16px",
    m: "24px",
    l: "32px",
    xl: "40px",
    mb: "30px",
  },
  widths: {
    fillAvailable: "-webkit-fill-available",
    fitContent: "fit-content",
    half: "50%",
    full: "100%",
    quarter: "25%",
    auto: "auto",
    w125: "125px",
    w35: "35px",
    w15p: "15%",
    w25: "25px",
    w175: "175px",
    w200: "200px",
    w255: "255px",
    w400: "400px",
    w540: "540px",
    w730: "730px",
    w100: "100px"
  },
  heights: {
    h20: "20px",
    h35: "35px",
    w175: "175px",
    w160: "160px",
    w730: "730px",
    h800: "800px",
    h100: "100%",
  },
  shadows: {
    light: "0px 7px 64px rgba(0, 0, 0, 0.07)",
    normal: "0px 7px 15px rgba(0, 0, 0, 0.15)",
    medium: "0px 8px 24px rgba(0, 0, 0, 0.25)",
    dark: "0px 8px 24px rgba(0, 0, 0, 0.55)",
  },
  zIndices: {
    low: 1,
    medium: 5,
    high: 999,
  },
};
export default Theme;
