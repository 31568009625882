import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Constants from "../../shared/Constants";
import OrganizationService from "../../services/OrganizationService";
import Config from "../../config";
import { Container } from "../../components/Containers/Styles";
import {
  Div, FormWrapper, MDImage, MDLabel, Row, Header,
} from "../../styles/Styles";
import Tabs from "../../components/Tabs/Tabs";
import FormLoader from "../../components/Loader/CardLoader";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Button from "../../components/Button/Button";
import ServiceProvider from "./Components/ServiceProvider";
import Room from "./Components/Room";
import Orders from "./Components/Orders";

const {
  icons, language, routes, adminRoutes,
} = Constants;
class Organization extends Component {
    organzationService = new OrganizationService(Config.apiBase);

    constructor(props) {
      super(props);
      const { userDetails = {} } = this.props;
      const userInfo = userDetails.user_access
        ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
      const { user_access: userAccess = {} } = userInfo;
      const { is_organization_editable: isEditable = 1 } = userAccess;
      this.state = {
        isEditable,
        loading: false,
        tabs: [language.common_service_providers, language.common_rooms, language.common_orders],
        selectedTab: language.common_service_providers,
        organization: {},
      };
    }

    componentDidMount=() => {
      this.fetchOrganizationDetails();
    }

    handleTabs = (tab) => {
      this.setState({ selectedTab: tab });
    };

    handleMoveTabs=(id) => {
      const { tabs } = this.state;
      const selectedTab = tabs[id];
      this.setState({ selectedTab });
    }

    handleBackToRooms = () => {
      const { history } = this.props;
      history.goBack();
    };

    /**
     *
     * @function handleAddUser
     * Redirects to create new user page
     *
     */
    handleAddUser = () => {
      const { history } = this.props;
      history.push(routes.addUser.url);
    };

    /*
     * @function handleAddRoom
     * Redirects to create new user page
     *
     */
    handleAddRoom = () => {
      const { history } = this.props;
      history.push(routes.addRoom.url);
    };

    handleRedirectToOrders = (item) => () => {
      const { history } = this.props;
      history.push(`${adminRoutes.adminPrefix}/${adminRoutes.orders}/${item.id}/${adminRoutes.editOrder}`);
    };

    fetchOrganizationDetails = async () => {
      this.setState({ loading: true });
      const {
        match: { params = {} },
      } = this.props;
      const { id = "" } = params;
      try {
        const response = await this.organzationService.getOrganisationsDetails(id);
        const { data = {} } = response;
        if (response) {
          window.scrollTo(0,0);
          this.setState({ organization: data, loading: false });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    };

    render() {
      const {
        loading, selectedTab, tabs, isEditable, organization,
      } = this.state;
      const { new_order: newOrders = 0, name = "" } = organization;
      const {
        match: { params = {} }, history,
      } = this.props;
      const { id = "" } = params;
      if (isEditable === -1) {
        return <Redirect to={routes.editOrganization.url} />;
      }
      return (
        <Container>
          <Div display="flex">
            <MDLabel cursor="pointer" onClick={this.handleBackToRooms}>
              <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
              <FormattedMessage id="breadcrumb_back" />
            </MDLabel>
          </Div>
          {loading && (
            <FormWrapper>
              <FormLoader />
            </FormWrapper>
          )}
          {!loading && (
            <>
              <Header className="py-2">{name}</Header>
              <Div className="my-3">
                <Tabs
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onClick={this.handleTabs}
                  onNext={this.handleMoveTabs}
                  onPrevious={this.handleMoveTabs}
                  openOrders={newOrders}
                />
              </Div>
              {selectedTab === tabs[0]
            && (
              <>
                <Row>
                  <div>
                    <ServiceProvider
                      organizationId={id}
                      history={history}
                      disableData={isEditable === 0}
                    />
                  </div>
                </Row>
                {isEditable === 1 && (
                  <div className="row m-0 pr-3 py-3">
                    <Button
                      label={<FormattedMessage id="common_add_new" />}
                      primary
                      startIcon={PlusIcon}
                      onClick={this.handleAddUser}
                    />
                  </div>
                )}
              </>
            )}
              {selectedTab === tabs[1]
            && (
              <>
                <Row>
                  <div>
                    <Room
                      organizationId={id}
                      history={history}
                      disableData={isEditable === 0}
                    />
                  </div>
                </Row>
                {isEditable === 1 && (
                  <div className="d-flex my-2">
                    <Button label={<FormattedMessage id="common_add_new" />} primary startIcon={PlusIcon} onClick={this.handleAddRoom} />
                  </div>
                )}
              </>
            )}
              {selectedTab === tabs[2] && (
                <>
                  <Orders userId={id} redirectTo={this.handleRedirectToOrders} />
                </>
              )}
            </>
          )}
        </Container>
      );
    }
}
Organization.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
};
Organization.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

export default compose(
  connect(mapStateToProps),
)(Organization);
