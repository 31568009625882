/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import {
  MDLabelWrapper, Div, IsDesktop, IsMobile,
} from "../../styles/Styles";
import Colors from "../../styles/Colors";
import FilterPopover from "./FilterPopover";
import { FilterIcon } from "../../styles/Icons";
import Modal from "../Modal/Modal";

const FilterButton = ({
  open, closeFilter, ...props
}) => (
  <Div position="relative" width="40px" ml={3}>
    <MDLabelWrapper height="40px" width="40px" backgroundColor={Colors.White} onClick={closeFilter} id="filter-button">
      <FilterIcon />
    </MDLabelWrapper>
    <IsDesktop>
      {open && (
        <FilterPopover
          closeFilter={closeFilter}
          {...props}
        />
      )}
    </IsDesktop>
    <IsMobile>
      {open && (
        <Modal>
          <FilterPopover
            closeFilter={closeFilter}
            {...props}
          />
        </Modal>
      )}
    </IsMobile>
    <a
      href
      data-toggle="modal"
      data-target="#Modal"
    />
  </Div>
);
FilterButton.propTypes = {
  open: PropTypes.bool,
  closeFilter: PropTypes.func,
};
FilterButton.defaultProps = {
  open: false,
  closeFilter: undefined,
};
export default FilterButton;
