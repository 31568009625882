import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import OrganizationService from "../../../services/OrganizationService";
import ProfileCard from "../../../components/Card/ProfileCard";
import Config from "../../../config";
import Constants from "../../../shared/Constants";
import Card from "../../../components/Card/Card";
import Modal from "../../../components/Modal/Modal";
import RoomService from "../../../services/RoomService";
import CardLoader from "../../../components/Loader/CardLoader";

class Room extends Component {
    organzationService = new OrganizationService(Config.apiBase);

    roomService = new RoomService(Config.apiBase);

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        room: [],
        isDeleteModalOpen: false,
        deleteRoomID: "",
        isRoomDeleted: false,
      };
    }

    componentDidMount=() => {
      this.fetchOrganizationRooms();
    }

    handleRoomsData = (room) => ({
      name: room.name,
      id: room.id,
    });

    /**
     *
     * @async
     * @function handleDeleteRoom
     * Deletes the selected room.
     * @returns room deletion
     *
     */
    handleDeleteRoom = async () => {
      this.setState({ loading: true });
      const { deleteRoomID } = this.state;
      try {
        const response = await this.roomService.deleteRoom(deleteRoomID);
        if (response) {
          this.setState({
            isDeleteModalOpen: false, isRoomDeleted: true, deleteRoomID: "", loading: false,
          },
          this.fetchOrganizationRooms);
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    }

    /**
     * Opens a delete confirmation popup
     * @param {object} item selected room data
     */
    handleDeleteModal =(item) => {
      const { id = "" } = item;
      this.setState({ isDeleteModalOpen: true, deleteRoomID: id }, () => {
        this.openElement.click();
      });
    };

    /**
     * Cancels the room deletion operation
     */
    handleCancel = () => {
      this.setState({ isRoomDeleted: false, isDeleteModalOpen: false });
    }

    handleMoreClick = (room) => {
      const { history } = this.props;
      history.push(`${Constants.routes.roomsList.url}/${room.id}/edit-room`);
    };

    fetchOrganizationRooms = async () => {
      const { organizationId: ID } = this.props;
      this.setState({ loading: true });
      try {
        const response = await this.organzationService.getOrganisationsRoomsDetails(ID);
        const { data = [] } = response;
        if (response) {
          this.setState({ room: data, loading: false });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    };

    render() {
      const {
        room, loading, isRoomDeleted, isDeleteModalOpen,
      } = this.state;
      const { disableData } = this.props;

      return (
        <>
          {loading && <CardLoader />}
          {!loading && room.map((obj) => {
            const { profile_pic: profilePic = {} } = obj;
            const { url = "" } = profilePic || {};
            return (
              <div className="pr-3 py-3 my-1 d-inline-flex">
                <ProfileCard
                  userAvtar={url || ""}
                  profileCard
                  data={this.handleRoomsData(obj)}
                  handleMore={this.handleMoreClick}
                  handleDelete={this.handleDeleteModal}
                  disableData={disableData}
                />
              </div>
            );
          })}
          <a
            href
            data-toggle="modal"
            data-target="#Modal"
            data-backdrop="static"
            data-keyboard="false"
            ref={(openModal) => { this.openElement = openModal; }}
          />
          {isDeleteModalOpen && (
            <Modal>
              <Card
                header={<FormattedMessage id="room_delete_room" />}
                message={<FormattedMessage id="room_deleted_confirmation_message" />}
                buttonLabel={<FormattedMessage id="common_delete" />}
                confirmationCard
                buttonIcon={Constants.icons.DeleteIcon}
                onClick={this.handleDeleteRoom}
                onCancel={this.handleCancel}
              />
            </Modal>
          )}
          {isRoomDeleted && (
            <Modal>
              <Card header={<FormattedMessage id="common_done" />} message={<FormattedMessage id="room_deleted_alert_message" />} alertCard buttonLabel={<FormattedMessage id="common_ok" />} onClick={this.handleCancel} />
            </Modal>
          )}
        </>
      );
    }
}
Room.propTypes = {
  organizationId: PropTypes.string,
  history: PropTypes.node,
  disableData: PropTypes.bool,
};
Room.defaultProps = {
  organizationId: "",
  history: undefined,
  disableData: false,
};

export default Room;
