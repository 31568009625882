import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import PortalContainer from "../../components/Containers/PortalContainer";
import Button from "../../components/Button/Button";
import { Div, Header } from "../../styles/Styles";
import ListScreen from "../../components/ListScreen/ListScreen";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Pagination from "../../components/Pagination/Pagination";
import Constants from "../../shared/Constants";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import UserService from "../../services/UserService";
import Config from "../../config";
import ListScreenLoader from "../../components/Loader/ListScreenLoader";
import { IsDesktop, IsMobile } from "../../components/ListScreen/Styles";
import { setLoader } from "../../store/actions/AppAction";
import SortButton from "../../components/Sorting/Sorting";
import { checkRightPermission } from "../../shared/utils";

const {
  language, sorting: { type: sortType, keys: { sortBy, sort } },
  adminRoutes,
} = Constants;

class Users extends Component {
  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);
    const { userDetails = {}, location: { search = "" } } = this.props;

    const userInfo = userDetails.user_access
      ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};

    const { permissions = [] } = userInfo;

    const hasWriteAccess= checkRightPermission(permissions,"is_users_editable",Constants.WRITE);

    const urlParams = new URLSearchParams(search);
    const pageNo = urlParams.get("page");

    this.state = {
      hasWriteAccess,
      deleteAccID: "",
      meta: {
        pages: 1,
        totalCount: null,
        pageNumber: pageNo || 1,
        pageSize: 10,
      },
      sortedBy: "",
      sortAscending: false,
      isFilterOpen: false,
      isDeleteModalOpen: false,
      isUserDeleted: false,
      users: [],
      toBeFilteredData: {},
      showSortModal: false,
    };
  }

  async componentDidMount() {
    const { onLoading } = this.props;
    onLoading(true);
    await this.fetchUsers();
    onLoading(false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        pageNumber,
      },
      sortedBy,
      sortAscending,
    } = this.state;
    const { location = {} } = this.props;
    if (prevState.meta.pageNumber !== pageNumber || location.key
      !== prevProps.location.key || sortedBy !== prevState.sortedBy
      || sortAscending !== prevState.sortAscending) {
      this.fetchUsers();
    }
  }

  handleRedirectToOrg=(id) => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.organization}/${id}/${adminRoutes.editOrganization}`);
  };

  /**
   *
   * @function handleAddUser
   * Redirects to create new user page
   *
   */
  handleAddUser = () => {
    const { history } = this.props;
    history.push(Constants.routes.addUser.url);
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber },
    }));
    const { history } = this.props;
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  /**
   * Handles filter actions
   */
  handleCloseFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleFilterApply=(filterValues = [], options = undefined) => {
    const filters = {};
    filterValues.map((obj) => {
      if (filters[obj.id]) {
        filters[obj.id].push(obj.value);
      } else {
        filters[obj.id] = [];
        filters[obj.id].push(obj.value);
      }
      return obj;
    });
    this.setState({ toBeFilteredData: filters, isFilterOpen: false });
    this.fetchUsers(filters, filterValues, options);
  }

  /**
   *
   * @async
   * @function fetchUsers Fetches all types of users.
   * @returns data of the users.
   *
   */
   fetchUsers=async (filters, searchInput, options) => {
     const {
       onLoading, userFilters, organizationFilters,
     } = this.props;
     onLoading(true);
     const {
       filterOptions = [],
       meta: { pageNumber, pageSize }, sortedBy, sortAscending, toBeFilteredData,
     } = this.state;
     const filter = filters || toBeFilteredData;
     const filterColumns = options || filterOptions;
     const queryParams = [
       ["pageNumber", searchInput ? 1 : pageNumber],
       ["pageSize", pageSize],
       [sortBy, sortedBy],
       [sort, sortAscending ? sortType.ASC : sortType.DESC],
       ["filter", filter],
     ];
     try {
       const response = await this.userService.getUserList(queryParams);
       if (filterColumns[0] && filterColumns[0].availableValues) {
         filterColumns[0].availableValues = userFilters;
       }
       if (filterColumns[1] && filterColumns[1].availableValues) {
         filterColumns[1].availableValues = organizationFilters;
       }
       const { meta: srcMeta = {}, data = [] } = response;
       const {
         current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
       } = srcMeta;
       const newMeta = {
         pageNumber: currentPage,
         pageSize: perPage,
         pages,
         totalCount,
       };
       window.scrollTo(0,0);
       this.setState({
         filterOptions: filterColumns, users: data, meta: newMeta,
       });
       onLoading(false);
     } catch (e) {
       onLoading(false);
     }
   }

  /**
   *
   * Redirects to detailed view page
   * @param {object} item user data
   * @returns to selected user's detailed page
   *
   */
  handleDetailPage = (item) => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.users}/${item.id}/${adminRoutes.editUser}`);
  };

  handleEditUser = (item) => () => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.users}/${item.id}/${adminRoutes.editUser}`);
  };

  /**
   * Opens a delete confirmation popup
   * @param {object} item selected user data
   */
  handleDeleteModal =(item) => {
    const { id = "" } = item;
    this.setState({ isDeleteModalOpen: true, deleteAccID: id }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * @async
   * @function handleDeleteUser
   * Deletes the selected user.
   * @returns user deletion
   *
   */
  handleDeleteUser =async () => {
    const { deleteAccID } = this.state;
    const { onLoading } = this.props;
    onLoading(true);
    try {
      const response = await this.userService.deleteUser(deleteAccID);
      if (response) {
        this.setState({ isDeleteModalOpen: false, isUserDeleted: true, deleteAccID: "" }, this.fetchUsers);
        onLoading(false);
      }
    } catch (e) {
      onLoading(false);
    }
  }

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({ isUserDeleted: false, isDeleteModalOpen: false });
  }

  /**
   *
   * Handles sorting of columns in the list screen
   * @param {object} item column data
   * @returns sorted list
   *
   */
  handleSorting=(item) => () => {
    const { sortedBy, sortAscending } = this.state;
    if (sortedBy === item) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      this.setState({ sortedBy: item, sortAscending: true });
    }
  }

  handleSortModal=() => {
    const { showSortModal } = this.state;
    this.setState({ showSortModal: !showSortModal });
  }

  handleApplySorting=(sortKey = "", sortOrder = false) => {
    this.setState({ sortedBy: sortKey, sortAscending: sortOrder, showSortModal: false });
  }

  render() {
    const {
      sortedBy,
      sortAscending,
      meta,
      isDeleteModalOpen,
      isUserDeleted,
      users,
      hasWriteAccess,
      showSortModal,
    } = this.state;
    const { isLoader } = this.props;
    const header = [
      {
        className: "col", value: language.filter_label_user, icon: sortType.ALPHABETS, key: "full_name",
      },
      {
        className: "col", value: language.list_header_type, icon: sortType.ARROW, key: "type",
      },
      {
        className: "col", value: language.list_header_id, icon: sortType.ARROW, key: "organization_number",
      },
      {
        className: "col", value: language.label_roles,
      },
      {
        className: "col", value: language.list_header_organisations,
      },
    ];
    const value = [
      {
        className: "col",
        key: "full_name",
        type: "user",
        group: false,
      },
      {
        className: "col",
        key: "type",
        type: "type",
        group: false,
      },
      {
        className: "col",
        key: "organization_number",
        type: "personalNumber",
        group: false,
      },
      {
        className: "col",
        key: "rights",
        type: "roles",
        group: false,
      },
      {
        className: "col",
        key: "organization",
        type: "organization",
        subItem: "organization_details",
        group: true,
        isOrg: true,
      },
    ];
    return (
      <PortalContainer>
        <Div justifyContent="space-between" display="flex" pb={4}>
          <Div>
            <Header display="inline-block"><FormattedMessage id="common_users" /></Header>
          </Div>
          <IsDesktop>
            <Div className="row m-0">
              {hasWriteAccess && <Button label={<FormattedMessage id="common_add_new" />} primary startIcon={PlusIcon} width="175px" onClick={this.handleAddUser} />}
            </Div>
          </IsDesktop>
        </Div>
        <IsMobile>
          <Div display="flex" justifyContent="space-between" pb={4}>
            {hasWriteAccess && (
              <Div>
                <Button label={<FormattedMessage id="common_add_new" />} primary startIcon={PlusIcon} width="175px" onClick={this.handleAddUser} />
              </Div>
            )}
            <Div display="flex">
              <SortButton
                isOpen={showSortModal}
                sortingKeys={header}
                sortedBy={sortedBy}
                sortAscending={sortAscending}
                onClose={this.handleSortModal}
                onSubmit={this.handleApplySorting}
              />
            </Div>
          </Div>
        </IsMobile>

        {isLoader && <ListScreenLoader />}

        {!isLoader && (
          <>
            <ListScreen
              moreButton
              deleteAction={hasWriteAccess}
              editAction={hasWriteAccess}
              sortedBy={sortedBy}
              sortAscending={sortAscending}
              header={header}
              value={value}
              data={users}
              onEdit={this.handleDetailPage}
              onDelete={this.handleDeleteModal}
              onClick={this.handleEditUser}
              handleSorting={this.handleSorting}
              redirectToOrg={this.handleRedirectToOrg}
              dataNotFoundMessage={<FormattedMessage id="users_not_found" />}
            />
            {meta.pages > 1 && (
              <Div py={4} display={["block", "flex", "flex"]}>
                <Div display={["flex"]} justifyContent="center" alignItems="center">
                  {`${users.length < 10 ? users.length + ((meta.pageNumber - 1) * 10) : users.length * meta.pageNumber} / ${meta.totalCount}`}
                </Div>
                <Div width="90%" justifyContent="center">
                  <Pagination gotoPage={this.handleGotoPage} cursor={meta} /></Div>
              </Div>
            )}
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="user_delete_user" />}
              message={<FormattedMessage id="user_deleted_confirmation_message" />}
              buttonLabel={<FormattedMessage id="common_delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteUser}
              onCancel={this.handleCancel}
              onLoader={isLoader}
            />
          </Modal>
        )}
        {isUserDeleted && (
          <Modal>
            <Card header={<FormattedMessage id="common_done" />} message={<FormattedMessage id="user_deleted_alert_message" />} alertCard buttonLabel={<FormattedMessage id="common_ok" />} onClick={this.handleCancel} />
          </Modal>
        )}
      </PortalContainer>
    );
  }
}
Users.propTypes = {
  history: PropTypes.node,
  location: PropTypes.node,
  userDetails: PropTypes.node,
  onLoading: PropTypes.func,
  isLoader: PropTypes.bool,
  userFilters: PropTypes.node,
  organizationFilters: PropTypes.node,
};
Users.defaultProps = {
  history: undefined,
  location: null,
  userDetails: {},
  onLoading: () => {},
  isLoader: false,
  userFilters: [],
  organizationFilters: [],
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
  userFilters: state.FilterReducer.userFilters,
  organizationFilters: state.FilterReducer.organizationFilters,
  isLoader: state.AppReducer.isLoader,
});

function mapDispatchToProps(dispatch) {
  return {
    onLoading: (payload) => dispatch(setLoader(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Users);
