import React from "react";
import PropTypes from "prop-types";
import LogInBgImage from "../../assets/Images/LogInBgImage.png";
import {
  FlexContainer, SectionLeft, SectionRight, ImageWrapper, ContentWrapper,
} from "./Styles";

/**
 * Container UI
 */
const LoginContainer = ({ children }) => (
  <FlexContainer>
    <SectionLeft>
      <ContentWrapper>{children}</ContentWrapper>
    </SectionLeft>
    <SectionRight>
      <ImageWrapper backgroundImage={LogInBgImage} backgroundSize="cover !important" />
    </SectionRight>
  </FlexContainer>
);
LoginContainer.propTypes = {
  /**
   * Pass children component
   */
  children: PropTypes.node,
};
LoginContainer.defaultProps = {
  children: null,
};
export default LoginContainer;
