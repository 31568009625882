import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Div, MDLabel, CardHeader, MDImage,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import SelectBox from "../Input/SelectBox";
import CustomDatePicker from "../Input/CustomDatePicker";
import Button from "../Button/Button";
import { fetchUserServices } from "../../store/actions/AppAction";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import CircleTickMark from "../../assets/Icons/CircleTickMark.svg";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import Theme from "../../styles/Theme";
import { Span } from "../../pages/MyAccounts/Styles";
import { formatDate } from "../../shared/utils";
import Constants from "../../shared/Constants";
import { getSlotData } from "../../services/availableService";
import Loader from "../Loader/Loader";

const { input: { format } } = Constants;

export default function AvailabilityDetailsOld({
  onClose, availabilityModalTitle, data, hasManageCalendarAccess,
}) {
  const {
    widths: {
      w25,
    },
    heights: {
      h20,
    },
    radii: {
      large,
    },
  } = Theme;
  const dispatch = useDispatch();
  const [showRepeat, setRepeat] = useState("");
  const checkState = useSelector((state) => state.AppReducer.userDetails);
  const userServices = useSelector((state) => state.AppReducer.userService);
  const serviceProviderData = useSelector((state) => state.AppReducer.serviceProvider);
  const [slotData, setSlotData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { sp_id: spID = "" } = data;
  const { user_access: { is_calendar_editable: rightOnCalendar = "" } = {} } = checkState;
  const serviceProvider = serviceProviderData.filter((sp) => sp.id === spID);
  const { full_name: spName = "" } = serviceProvider.length ? serviceProvider[0] : {};
  const fetchSlots = async (params) => {
    setIsLoading(true);
    try {
      const response = await getSlotData(params);
      const { data: availablData = [] } = response;
      setSlotData(availablData);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setSlotData({});
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSlots(data);
  }, [data]);
  useEffect(() => {
    if (slotData) {
      if (slotData.key === "everyday") {
        setRepeat({ label: <FormattedMessage id="label_everyday" defaultMessage="Everyday" />, value: "everyDay", isSelected: false });
      } else if (slotData.key === "monday-friday") {
        setRepeat({ label: <FormattedMessage id="label_monday_friday" defaultMessage="Monday-Friday" />, value: "mon-fri", isSelected: false });
      } else if (slotData.key === "monday-saturday") {
        setRepeat({ label: <FormattedMessage id="label_monday_saturday" defaultMessage="Monday-Saturday" />, value: "mon-sat", isSelected: false });
      } else if (slotData && slotData.key && slotData.key.includes("every-")) {
        const daySelected = slotData.key.split("-")[1];
        setRepeat({ label: `${Constants.language.label_every} ${Constants.translations[daySelected]}`, value: "everyTuesday", isSelected: false });
      } else if (slotData.key === "custom-range") {
        setRepeat({ label: <FormattedMessage id="label_more" defaultMessage="More..." />, value: "more", isSelected: false });
      } else {
        setRepeat({ label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />, value: "Don't Repeat", isSelected: false });
      }
    }
  }, [slotData]);
  const fetchUserService = (params) => {
    dispatch(fetchUserServices(params));
  };
  useEffect(async () => {
    if (hasManageCalendarAccess) {
      await fetchUserService(spID);
    } else if (checkState) {
      const { id } = checkState || {};
      await fetchUserService(id);
    }
  }, [checkState]);
  const { service_provider_available_slots: availableSlots = [] } = slotData;
  return (
    <Modal className="availability-form">
      <Div width="500px">
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            {hasManageCalendarAccess ? spName : "" }
            {" "}
            <FormattedMessage id="header_sortins" defaultMessage={availabilityModalTitle} />
          </MDLabel>
        </CardHeader>
        {isLoading && (
          <Div
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            my={40}
          >
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />
            <Loader height="40px" width="400px" />

          </Div>
        )}
        {!isLoading && (
          <>
            <Div display="flex" mx={40} paddingTop="1rem" justifyContent="center">
              <MDLabel alignSelf="center" fontSize="16px" fontWeight="normal" fontFamily={Theme.fonts.regular}>
                {rightOnCalendar > 0 ? (
                  <FormattedMessage id="label_access_not_available" defaultMessage="You dont have enough permissions" />
                )
                  : <FormattedMessage id="label_past_days" defaultMessage="You cant edit Slots for past days" />}
              </MDLabel>
            </Div>
            <hr />
            <Div display="flex" mx={40} alignItems="center">
              <Div className="col-4" p={0}>
                <MDLabel fontSize="18px" mr={35}>
                  <FormattedMessage id="label_date" defaultMessage="Date:" />
                </MDLabel>
              </Div>
              <Div className="col-8" p={0} alignItems="center" display="flex">
                <MDImage
                  src={CalendarIcon}
                  useCursorPointer
                  alt="icon"
                  width={w25}
                  height={h20}
                  borderRadius={large}
                />
                <Span>
                  {formatDate(slotData.date, format.dateWithDay)}
                </Span>
              </Div>
            </Div>
            <hr />
            <Div display="flex" mx={40} alignItems="center">
              <Div className="col-4" p={0}>
                <MDLabel fontSize="18px" mr={35}>
                  <FormattedMessage id="label_time" defaultMessage="Time:" />
                </MDLabel>
              </Div>
              <Div display="flex" className="col-8" alignItems="center" p={0}>
                <MDLabel color="darkgray" mr={15} fontSize="16px" fontWeight="normal">
                  <FormattedMessage id="label_from" defaultMessage="From:" />
                </MDLabel>
                <MDImage
                  src={ClockIcon}
                  useCursorPointer
                  alt="icon"
                  width={w25}
                  height={h20}
                  borderRadius={large}
                />
                <Span>
                  {slotData.start_time}
                </Span>
                <MDLabel color="darkgray" ml={15} mr={15} fontSize="16px" fontWeight="normal">
                  <FormattedMessage id="label_to" defaultMessage="To:" />
                </MDLabel>
                <MDImage
                  src={ClockIcon}
                  useCursorPointer
                  alt="icon"
                  width={w25}
                  height={h20}
                  borderRadius={large}
                />
                <Span>
                  {slotData.end_time}
                </Span>
              </Div>
            </Div>
            <hr />
            {userServices ? (
              <Div display="flex" mx={40} mt={20} alignItems="center">
                <Div className="col-4" p={0}>
                  <MDLabel fontSize="18px" mr={35}>
                    <FormattedMessage id="label_services" defaultMessage="Service:" />
                  </MDLabel>
                </Div>
                <Div className="col-8 m-0" flexWrap="inherit" p={0}>
                  {availableSlots && availableSlots.length
              && availableSlots[0] && availableSlots[0].slots && availableSlots[0].slots.length
                    ? availableSlots[0].slots.map((value, key) => (
                      <Div className="col-6" display="inline-flex" p="2px" id={key}>
                        <MDLabel fontSize="14px" mr={35} fontWeight="normal">{value?.slot_services?.[0]?.name}</MDLabel>
                      </Div>
                    )) : ""}
                </Div>
              </Div>
            ) : ""}
            <hr />

            <Div display="flex" mx={40} mt={20} alignItems="center">
              <Div className="col-4" p={0}>
                <MDLabel fontSize="18px" mr={35}>
                  <FormattedMessage id="label_repeat" defaultMessage="Repeat:" />
                </MDLabel>
              </Div>
              <Div className="col-8" p={0}>
                <SelectBox
                  value={showRepeat}
                  name="repeat"
                  disabled
                  options={[
                    { label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />, value: "Don't Repeat", isSelected: false },
                    { label: <FormattedMessage id="label_everyday" defaultMessage="Everyday" />, value: "everyDay", isSelected: false },
                    { label: <FormattedMessage id="label_monday_friday" defaultMessage="Monday-Friday" />, value: "mon-fri", isSelected: false },
                    { label: <FormattedMessage id="label_monday_saturday" defaultMessage="Monday-Saturday" />, value: "mon-sat", isSelected: false },
                    { label: <FormattedMessage id="label_every_tuesday" defaultMessage="Every Tuesday" />, value: "everyTuesday", isSelected: false },
                    { label: <FormattedMessage id="label_more" defaultMessage="More..." />, value: "more", isSelected: false },
                  ]}
                  placeholder={<FormattedMessage id="label_everyday" defaultMessage="Dont't Repeat" />}
                  width={[1, 255]}
                />
              </Div>
            </Div>
            <Div display="flex" mx={40} alignItems="center">
              <Div className="col-4" p={0}>
                <MDLabel textAlign="left" fontSize="18px" mt={3}>
                  <FormattedMessage id="label_end_date" defaultMessage="End Date:" />
                </MDLabel>
              </Div>
              <Div className="col-8" p={0}>
                <CustomDatePicker
                  value={slotData?.endDate}
                  disabled={1}
                  name="endDate"
                  maxDate=""
                  minDate=""
                  width={[1, 255]}
                />
              </Div>
            </Div>
            <hr />
            <Div display="flex" alignSelf="center" justifyContent="center" mb={20} mx={40}>
              <Button label={<FormattedMessage id="common_ok" defaultMessage="Ok" />} width={[1, 160]} onClick={onClose} startIcon={CircleTickMark} />
            </Div>
          </>
        )}
      </Div>
    </Modal>
  );
}
AvailabilityDetailsOld.propTypes = {
  /**
   * Pass children innto the modal
   */
  onClose: PropTypes.func,
  availabilityModalTitle: PropTypes.string,
  data: PropTypes.func,
  hasManageCalendarAccess: PropTypes.bool,
};
AvailabilityDetailsOld.defaultProps = {
  onClose: undefined,
  availabilityModalTitle: undefined,
  data: {},
  hasManageCalendarAccess: false,
};
