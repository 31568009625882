import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import { fromOrderslistJson } from "../models/Customers";
import { queryParamParser } from "../shared/utils";

const { HTTP_STATUS: { OK }, requestType: { GET }, input: { param: { orgId, userId } } } = Constants;

const { apiEndPoints: { endPointUrl = {} } } = Constants;
export async function getOrdersList(id, params) {
  const param = [[orgId, id]];
  const queryParams = [...params, ...param];
  const url = `${endPointUrl.ORDERS}${queryParamParser(queryParams)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return fromOrderslistJson(data);
}
export async function getOrganization(id = "") {
  const queryParams = id ? [[userId, id]] : [];
  
  const url = `${endPointUrl.ORGANIZATIONS}${queryParamParser(queryParams)}`;
  const response = await request("get", url);
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}
export async function getOrderFilters(id) {
  const url = `${endPointUrl.ORGANIZATIONS}/${id}/${endPointUrl.ORDERS}/${endPointUrl.FILTERS}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

class OrganizationService extends ApiService {
  async getOrganisationsDetails(id) {
    const response = await this.get(
      `${endPointUrl.ORGANIZATIONS}/${id}`,
    );
    const { data = {}, status = "", statusText = "" } = response;
    if (status !== OK) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getOrganisationsServiceProvider(id) {
    const queryParam = [[orgId, id]];
    const response = await this.get(
      `${endPointUrl.ORGANIZATIONS}/${endPointUrl.SERVICEPROVIDERS}${queryParamParser(queryParam)}`,
    );
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getOrganisationsRoomsDetails(id) {
    const queryParam = [[orgId, id]];
    const response = await this.get(
      `${endPointUrl.ORGANIZATIONS}/${endPointUrl.ROOMS}${queryParamParser(queryParam)}`,
    );
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }
}
export default OrganizationService;
