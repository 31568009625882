import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PortalContainer from "../../../components/Containers/PortalContainer";
import Constants from "../../../shared/Constants";
import { Box, Div, InputBox, IsDesktop, IsMobile, MDImage, MDLabel, P } from "../../../styles/Styles";
import { useHistory } from "react-router-dom";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import Theme from "../../../styles/Theme";
import Button from "../../../components/Button/Button";
import DraftIcon from "../../../assets/Icons/DraftIcon.svg";
import { addSubscriptionByAdmin, getCustomers } from "../../../services/SubscriptionService";
import { BoxDivision, Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import Colors from "../../../styles/Colors";
import Modal from "../../../components/Modal/Modal";
import { formatDate } from "../../../shared/utils";
import SelectBox from "../../../components/Input/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionServices } from "../../../store/actions/SubscriptionBookingFlowAction";
import TextArea from "../../../components/Input/TextArea";
import { DateCard } from "../../Assignments/Styles";
import Popup from "../../Assignments/Components/Modals";
import DatePopup from "./Modals";
import { getDogs } from "../../../services/AssignmentService";
import usePermissions, { ModulePermissions } from "../../../shared/usePermissions";

const {
  icons,
  routes,
  input,
  language,
  input: { format },
  options,
  packagesTranslations
} = Constants;

const AddSubscripiton = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState({});

  const [isClientSelected, setIsClientSelected] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedDog, setSelectedDog] = useState({});
  const [customerDogs, setCustomerDogs] = useState([]);
  const [selectedValues, setSelectedvalues] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [isStatusEnabled, setIsStatusEnabled] = useState("enabled");
  const [notes, setNotes] = useState("");
  const [getOrganizations] = usePermissions();

  const handleBackClick = () => {
    const {
      location: { state = "",search="" },
    } = history;
    const params = new URLSearchParams(search);
    const pageNo = params.get("page");
    const selectedTab = params.get("tab");
    if (pageNo) {
      history.push(`${routes.subscriptionsList.url}?page=${pageNo}&tab=${selectedTab}`);
    }
    else if(state) {
      history.push(state);
    } else {
      history.push(routes.subscriptionsList.url);
    }
  };

  const [showModal, setShowModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [email, setEmail] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const userInfo = useSelector((state) => state.AppReducer.userDetails);
  const fetchOrganizations = async () => {
    if (userInfo) {
      setOrganizations(getOrganizations(ModulePermissions.Subscription, Constants.WRITE));
      setEmail(userInfo.email);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [userInfo]);

  const { subscriptionPackages } = useSelector((state) => state.SubscriptionBookingFlowReducer);

  useEffect(() => {
    fetchCustomers();
    dispatch(fetchSubscriptionServices());
  }, []);

  const fetchCustomers = () => {
    getCustomers().then((response) => {
      const { data = [] } = response;
      if (data) {
        data.forEach((customer) => {
          customer.label = customer.name;
          customer.value = {
            clientId: customer.id,
          };
        });
        setCustomers(data);
      }
    });
  };

  useEffect(() => {
    const packageList = [...subscriptionPackages];
    const packageOptions = [];
    const completedPackageNames = [];
    packageList.forEach((option) => {
      const { name = "" } = option;
      let displayName = name;
      const containsDuplicateName = packageList.find((p) => p.id !== option.id && p.name === option.name);
      let packageName = packagesTranslations[displayName];
      if (packageName) {
        if (containsDuplicateName) {
          const days = option.description.split(" ")[0];
          displayName += " (" + days + " " + language.days + ") ";
        }
      }
      let optionLabel = packageName;
      if (packageName !== language.custom) {
        optionLabel = `${packageName} - ${language.common_sek} ${option.price.large}/${language.month}`;
      }

      const data = {
        value: option.id,
        label: optionLabel,
        isSelected: false,
        days: option.description,
        icon: option.icon,
      };
      packageOptions.push(data);
      completedPackageNames.push(name);
    });
    setPackages(packageOptions);
  }, [subscriptionPackages]);


  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleModalOk = (name, data) => {
    const values = { ...selectedValues };
    values[name] = data;
    setSelectedvalues(values);
    setShowModal("");
    if (name === input.name.client) {
      setSelectedCustomer(data);
      if (nullCheck(data)) {
        setIsClientSelected(true);
        setSelectedDog({});
        getDogs(data.id).then((response) => {
          setCustomerDogs(response.data);
        });
      }
    } else if (name === input.name.dog) {
      setSelectedDog(data);
      const dog = customerDogs.find((customerDog) => customerDog.id === data.value);
      if (!dog) {
        getDogs(selectedCustomer.id).then((response) => {
          setCustomerDogs(response.data);
        });
      }
    } else if (name === input.name.startDate) {
      setStartDate(data);
    } else if (name === input.name.endDate) {
      setEndDate(data);
    }
  };

  const handleModalCancel = () => {
    setShowModal("");
  };

  const renderOrganizations = organizations.map((organization) => {
    return (
      <Division key={organization.name} type="select_Size" mb={3} pb={1} pr={[0,4]} width="340px" setBorder>
        <InputBox
          type="radio"
          name="organizations"
          id={organization.id}
          title={organization.label}
          onChange={handleSelectedLocation.bind(this, organization)}
          checked={selectedLocation?.id === organization.id}
          hidden
        />
        <CommonCard
          htmlFor={organization.id}
          avtarImg={organization?.profile_pic?.url || FavIcon}
          header={organization.label}
          leftIcon={icons.ServiceLocation}
          leftContent={organization.info}
          fontSize={Theme.fontSizes.xs}
          fontFamily={Theme.fonts.regular}
          fontWeight={Theme.fontWeights.normal}
          paddingLeft={10}
          imagePadding={organization?.profile_pic?.url ? "" : "8px"}
          width="auto !important"
          boxDivType="stepThree"
        />
      </Division>
    );
  });

  const handleOpenModal = (modalName) => {
    setShowModal(modalName);
    if (modalName === input.name.client) {
      if (!customers || customers.length === 0) {
        fetchCustomers();
      }
      setModalData(customers);
    } else if (modalName === input.name.startDate) {
      setModalData([]);
    }
  };

  const handlePackageSelection = (target) => {
    const { value = {} } = target?.target;
    setSelectedPackage(value);
  };

  const handleStatusEnable = (target) => {
    const { value = {} } = target?.target;
    setIsStatusEnabled(value);
  };

  const handleNotesChange = (target) => {
    const { value = "" } = target?.target;
    setNotes(value);
  };

  const handleCreate = () => {
    const dog = customerDogs.find((customerDog) => customerDog.id === selectedDog.value);
    const data = {
      email: email,
      customer_id: selectedCustomer.id,
      dog: dog,
      product_id: selectedPackage.value,
      start_date: formatDate(startDate, format.dateWithTime),
      organization_id: selectedLocation.id,
      subscription_days: [],
      description: notes,
    };
    addSubscriptionByAdmin(data).then((response) => {
      if (response) {
        history.push(routes.subscriptionsList.url);
      }
    });
  };

  const renderChangeField = (inputName, label) => {
    return (
      <Div mt={3} display="flex" justifyContent="flex-start">
        <Box onClick={handleOpenModal.bind(this, inputName)}>
          <MDImage src={icons.EditIcon} />
          <MDLabel
            textDecoration="underline"
            cursor="pointer"
            fontSize={Theme.fontSizes.regular}
            fontWeight={Theme.fontWeights.normal}
            fontFamily={Theme.fonts.medium}>
            {label}
          </MDLabel>
        </Box>
      </Div>
    );
  };

  const renderEditButton = (inputName, disableButton) => {
    return (
      <Div mt={3}>
        <Button
          size="small"
          padding="2px 8px"
          primary
          label={language.edit}
          startIcon={DraftIcon}
          disabled={disableButton && !isClientSelected}
          onClick={handleOpenModal.bind(this, inputName)}
        />
      </Div>
    );
  };

  const renderCustomerCard = () => {
    let cardData = renderEditButton(input.name.client, false);
    if (nullCheck(selectedCustomer)) {
      cardData = (
        <Division mt={3}>
          <Div>
            <CommonCard
              avtarImg={selectedCustomer?.profile_pic?.url || FavIcon}
              imgWidth={90}
              imgHeight={90}
              header={selectedCustomer?.name}
              fontSize={Theme.fontSizes.small}
              fontFamily={Theme.fonts.semibold}
              paddingLeft={12}
              msLeft="msLeft"
              msRight="msRight"
              color={Colors.DarkGrey}
              heightValue={90}
              width="290px !important"
              isDog
              imagePadding={selectedCustomer?.profile_pic?.url ? "" : "8px"}
            />
          </Div>
          {renderChangeField(input.name.client, language.change)}
        </Division>
      );
    }
    return (
      <Div mt={4}>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="label_client" defaultMessage="Client" />
        </MDLabel>
        {cardData}
      </Div>
    );
  };

  const renderDogCard = () => {
    let cardData = renderEditButton(input.name.dog, true);
    if (nullCheck(selectedDog)) {
      cardData = (
        <Division mt={3}>
          <Div>
            <CommonCard
              avtarImg={selectedDog.profilePic || FavIcon}
              imgWidth={90}
              imgHeight={90}
              header={selectedDog?.label}
              leftContent={selectedDog?.breed}
              fontSize={Theme.fontSizes.small}
              fontFamily={Theme.fonts.semibold}
              paddingLeft={12}
              msLeft="msLeft"
              msRight="msRight"
              color={Colors.DarkGrey}
              heightValue={90}
              width="290px !important"
              isDog
              imagePadding={selectedDog.profilePic ? "" : "8px"}
            />
          </Div>
          {renderChangeField(input.name.dog, language.change)}
        </Division>
      );
    }
    return (
      <Div mt={4}>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="common_dog" defaultMessage="Dog" />
        </MDLabel>
        {cardData}
      </Div>
    );
  };

  const renderStartDateCard = () => {
    let cardData = renderEditButton(input.name.startDate, true);
    if (startDate) {
      cardData = (
        <Div mt={3}>
          <DateCard height="64px" width={Theme.widths.w100}>
            <MDLabel fontSize={Theme.fontSizes.regular}>{formatDate(startDate)}</MDLabel>
          </DateCard>
          {renderChangeField(input.name.startDate, language.edit_start_date)}
        </Div>
      );
    }
    return (
      <Div mt={4}>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="start_date" defaultMessage="Start Date" />
        </MDLabel>
        {cardData}
      </Div>
    );
  };

  const renderEndDateCard = () => {
    let cardData = renderEditButton(input.name.endDate, true);
    if (endDate) {
      cardData = (
        <Div mt={3}>
          <DateCard height="64px" width={Theme.widths.w100}>
            <MDLabel fontSize={Theme.fontSizes.regular}>{formatDate(endDate)}</MDLabel>
          </DateCard>
          {renderChangeField(input.name.endDate, language.edit_end_date)}
        </Div>
      );
    }
    return (
      <Div mt={4}>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="label_end_date" defaultMessage="End Date" />
        </MDLabel>
        {cardData}
      </Div>
    );
  };

  const renderClientDogModals = () => {
    if (showModal === input.name.client || showModal === input.name.dog) {
      return (
        <Division p={2}>
          <Modal>
            <Popup
              data={modalData}
              values={selectedValues}
              handleSubmit={handleModalOk}
              name={showModal}
              onCancel={handleModalCancel}
              id={showModal === input.name.dog && selectedCustomer.id}
            />
          </Modal>
        </Division>
      );
    }
  };

  const renderStartAndEndDateModals = () => {
    if (showModal === input.name.startDate || showModal === input.name.endDate) {
      const minDate = showModal === input.name.endDate && startDate;
      return (
        <Division p={2}>
          <Modal>
            <DatePopup
              handleSubmit={handleModalOk}
              values={selectedValues}
              name={showModal}
              onCancel={handleModalCancel}
              minDate={minDate}
            />
          </Modal>
        </Division>
      );
    }
  };

  const renderNotes = () => {
    return (
      <Div>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="notes" defaultMessage="Notes" />
        </MDLabel>
        <MDLabel mt={2} fontSize={Theme.fontSizes.small} fontWeight={Theme.fontWeights.light}>
          <FormattedMessage id="if_needed" defaultMessage="If needed" />
        </MDLabel>
        <Div mt={3}>
          <TextArea
            containerProps="m-auto justify-content-center"
            placeholder={`${language.notes} ...`}
            onChange={handleNotesChange}
            name="notes"
            width="100%"
            value={notes}
          />
        </Div>
      </Div>
    );
  };

  const isCreateDisabled = () => {
    return !(nullCheck(selectedCustomer) && nullCheck(selectedDog) && nullCheck(selectedLocation) && nullCheck(selectedPackage) && startDate);
  }

  const nullCheck = (obj) => {
    return obj && Object.keys(obj).length > 0;
  }

  return (
    <PortalContainer>
      <Div justifyContent="flex-start" display="flex" pb={2} onClick={handleBackClick} ml={[0, 3, 3]}>
        <MDImage src={icons.ArrowLeft} />
        <MDLabel pl="5px">
          <FormattedMessage id="common_back" defaultMessage="Back" />
        </MDLabel>
      </Div>
      <Div justifyContent="space-between" display="flex" pb={3} ml={[0, 3, 3]}>
        <P fontFamily={Theme.fonts.semibold} fontSize={[Theme.fontSizes.xl, Theme.fontSizes.header]}>
          <FormattedMessage id="new_subscription_application" defaultMessage="New Subscription Application" />
        </P>
      </Div>
      <Div justifyContent="flex-start" display="flex" pb={3} ml={[0, 3, 3]}>
        <MDLabel fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="label_place" defaultMessage="Place" />
        </MDLabel>
      </Div>
      <Div justifyContent="flex-start" display="flex" pb={[0, 3, 3]} ml={[0, 3, 3]}>
        <BoxDivision justifyContent="flex-start !important">{renderOrganizations}</BoxDivision>
      </Div>
      <Div justifyContent="flex-start" display="flex" ml={[0, 3, 3]}>
        <MDLabel fontSize={Theme.fontSizes.large}>
          <FormattedMessage id="details_of_the_application" defaultMessage="Details of the application" />
        </MDLabel>
      </Div>
      <IsMobile>
        <Div>
          <Div ml={0}>
            {renderCustomerCard()}
            {renderDogCard()}
            {renderStartDateCard()}
            {renderEndDateCard()}
          </Div>
        </Div>
        <Div className="col-12" px={0} mt={0}>
          <SelectBox
            options={packages}
            value={selectedPackage?.value}
            onChange={handlePackageSelection}
            placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
            label={language.packages}
          />
        </Div>

        <Div className="col-12" px={0} mt={0}>
          <SelectBox
            options={options.applicationStatus}
            value={isStatusEnabled}
            onChange={handleStatusEnable}
            placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
            label={language.status_of_application}
          />
        </Div>

        <Div className="col-12" px={0} pt={0} pb={4} mt="20px">
          {renderNotes()}
        </Div>
      </IsMobile>
      <IsDesktop>
        <Div justifyContent="space-evenly" alignItems="flex-start" flexDirection="column" display="flex" ml={3}>
          <Box>
            <Div mr={6}>
              {renderCustomerCard()}
              {renderStartDateCard()}
            </Div>
            <Div>
              {renderDogCard()}
              {renderEndDateCard()}
            </Div>
          </Box>
        </Div>
        <Div className="col-5" pr={0} mt="20px">
          <SelectBox
            options={packages}
            value={selectedPackage}
            onChange={handlePackageSelection}
            placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
            label={language.packages}
          />
        </Div>

        <Div className="col-5" pr={0} mt="20px">
          <SelectBox
            options={options.applicationStatus}
            value={isStatusEnabled}
            onChange={handleStatusEnable}
            placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
            label={language.status_of_application}
          />
        </Div>

        <Div className="col-5" pr={0} py={4} mt={2}>
          {renderNotes()}
        </Div>
      </IsDesktop>

      <Div>
        {renderClientDogModals()}
        {renderStartAndEndDateModals()}
      </Div>
      <Division ml={[0, 3, 3]} mt={2} display="flex" flexDirection={["column", "unset", "unset"]}>
        <Div mb={["20px", 0, 0]}>
          <Button
            primary
            startIcon={Constants.icons.AssignmentsMenuIcon}
            type="button"
            label={<FormattedMessage id="common_create" defaultMessage="Create" />}
            width={["100%", "175px", "175px"]}
            onClick={handleCreate}
            disabled={isCreateDisabled()}
          />
        </Div>
        <Div ml={[0, 3, 3]}>
          <Button
            secondary
            type="button"
            label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
            width={["100%", "175px", "175px"]}
            onClick={handleBackClick}
          />
        </Div>
      </Division>
    </PortalContainer>
  );
};

export default AddSubscripiton;
