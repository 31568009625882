import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";

const { apiEndPoints: { endPointUrl = {} }, HTTP_STATUS: { CREATED, OK } } = Constants;

export async function verifyEmail(id = "", token = "") {
  const url = `${endPointUrl.EMAIL_VERIFY}/${id}`;
  const response = await request("get", url, {}, token);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

class SignUpService extends ApiService {
  async loginService(data) {
    const response = await this.post(endPointUrl.LOGIN, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      const { data: { user = {} } } = response.data;
      localStorage.setItem("userData", JSON.stringify(user));
      return response.data;
    }
  }

  async logoutService() {
    const response = await this.get(endPointUrl.LOGOUT);
    return response;
  }

  async forgotPasswordService(data) {
    const response = await this.post(endPointUrl.FORGOT_PASSWORD, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async resetPasswordService(data) {
    const response = await this.post(endPointUrl.RESET_PASSWORD, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async registerCustomer(payload) {
    const response = await this.post(`${endPointUrl.CUSTOMERS}/${endPointUrl.REGISTER}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (!(status === CREATED || status === OK)) {
      throw Error(statusText);
    } else {
      return data;
    }
  }
}
export default SignUpService;
