import Constants from "./Constants";

// common pages
import Home from "../pages/Login/Home";
import LogIn from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import PageNotFound from "../pages/PageNotFound";
import PageNotAccessible from "../pages/PageNotAccessible";
import Payment from "../pages/Payment/Payment";
import Cart from "../pages/Cart/Cart";
import OrderConfirmPage from "../pages/Payment/OrderConfirmPage";

// admin portal pages
import Rooms from "../pages/Rooms/Rooms";
import Customers from "../pages/Customers/Customers";
import Orders from "../pages/Orders/Orders";
import Services from "../pages/Services/Services";
import Users from "../pages/Users/Users";
import Organization from "../pages/Organization/Organization";
import Assignments from "../pages/Assignments/Assignments";

import AddUsers from "../pages/Users/AddUser";
import EditUsers from "../pages/Users/EditUser";
import AddService from "../pages/Services/AddService";
import EditService from "../pages/Services/EditService";
import AddRoom from "../pages/Rooms/AddRoom";
import EditRoom from "../pages/Rooms/EditRoom";
import AddCustomer from "../pages/Customers/AddCustomer";
import EditCustomer from "../pages/Customers/EditCustomer";
import AddDog from "../pages/Customers/AddDog";
import Calendar from "../pages/Calendar/Calendar";
import AddOrder from "../pages/Orders/AddOrder";
import EditOrder from "../pages/Orders/EditOrder";
import AddAssignment from "../pages/Assignments/AddAssignment";
import EditAssignment from "../pages/Assignments/EditAssignment";
import BookingFlow from "../pages/BookingFlow/BookingFlow";
import SubscriptionBookingFlow from "../pages/Subscription/SubscriptionFlow";

import RegisterCustomer from "../pages/Login/RegisterCustomer";
import ConfirmCustomer from "../pages/Login/ConfirmCustomer";

import CustomerDetails from "../pages/MyAccounts/CustomerDetails";
import CustomerDogs from "../pages/MyAccounts/CustomerDogs";
import CustomerAddDog from "../pages/MyAccounts/CustomerAddDog";
import CustomerEditDog from "../pages/MyAccounts/CustomerEditDog";
import MyOrders from "../pages/MyAccounts/MyOrders";
import GuestOrderConfirm from "../pages/Payment/Components/GuestOrderConfirm";
import Subscription from "../pages/Subscription/admin/Subscription";
import AddSubscripiton from "../pages/Subscription/admin/AddSubscripiton";
import EditSubscription from "../pages/Subscription/admin/EditSubscription";
import DogDetails from "../pages/Payment/DogDetails";

const { routes, language } = Constants;

export const RoutesList = [
  {
    url: routes.home.url,
    title: language.title_home,
    component: Home,
  },
  {
    url: routes.chooseService.url,
    title: language.title_home,
    component: BookingFlow,
  },
  {
    url: routes.chooseDog.url,
    title: language.title_home,
    component: BookingFlow,
  },
  {
    url: routes.chooseCenter.url,
    title: language.title_home,
    component: BookingFlow,
  },
  {
    url: routes.chooseTimeDate.url,
    title: language.title_home,
    component: BookingFlow,
  },
  {
    url: routes.orderSummary.url,
    title: language.title_home,
    component: BookingFlow,
  },
  {
    url: routes.chooseSubscription.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.chooseSubscriptionDog.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.chooseSubscriptionOrganization.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.chooseSubscriptionDate.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.subscriptionOrderSummary.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.subscriptionConfirmation.url,
    title: language.title_home,
    component: SubscriptionBookingFlow,
  },
  {
    url: routes.customerLogin.url,
    title: language.common_login,
    component: LogIn,
    isAdmin: routes.customerLogin.isAdmin,
  },
  {
    url: routes.logIn.url,
    title: language.common_login,
    component: LogIn,
    isAdmin: routes.logIn.isAdmin,
  },
  {
    url: routes.forgotPassword.url,
    title: language.common_forgot_password,
    component: ForgotPassword,
  },
  {
    url: routes.forgotPasswordAlertPage.url,
    title: language.title_alert,
    component: ForgotPassword,
  },
  {
    url: routes.resetPassword.url,
    title: language.common_reset_password,
    component: ResetPassword,
    isAdmin: routes.resetPassword.isAdmin,
  },
  {
    url: routes.resetPasswordAlertPage.url,
    title: language.title_alert,
    component: ResetPassword,
  },
  {
    url: routes.adminPortal.url,
    title: language.title_home,
    component: Rooms,
  },
  {
    url: routes.roomsList.url,
    title: language.common_rooms,
    component: Rooms,
  },
  {
    url: routes.addRoom.url,
    title: language.common_rooms,
    component: AddRoom,
  },
  {
    url: routes.editRoom.url,
    title: language.common_rooms,
    component: EditRoom,
  },
  {
    url: routes.assignmentsInRoom.url,
    title: language.common_rooms,
    component: EditRoom,
  },
  {
    url: routes.customersList.url,
    title: language.common_customers,
    component: Customers,
  },
  {
    url: routes.addCustomer.url,
    title: language.common_customers,
    component: AddCustomer,
  },
  {
    url: routes.editCustomer.url,
    title: language.common_customers,
    component: EditCustomer,
  },
  {
    url: routes.dogsInCustomer.url,
    title: language.common_customers,
    component: EditCustomer,
  },
  {
    url: routes.ordersOfCustomer.url,
    title: language.common_customers,
    component: EditCustomer,
  },
  {
    url: routes.subscriptionsOfCustomer.url,
    title: language.common_customers,
    component: EditCustomer,
  },
  {
    url: routes.addDog.url,
    title: language.common_dog,
    component: AddDog,
  },
  {
    url: routes.ordersList.url,
    title: language.common_orders,
    component: Orders,
  },
  {
    url: routes.addOrder.url,
    title: language.common_orders,
    component: AddOrder,
  },
  {
    url: routes.editOrder.url,
    title: language.common_orders,
    component: EditOrder,
  },
  {
    url: routes.customerDogTabInOrder.url,
    title: language.common_orders,
    component: EditOrder,
  },
  {
    url: routes.paymentTabInOrder.url,
    title: language.common_orders,
    component: EditOrder,
  },
  {
    url: routes.subscriptionsList.url,
    title: language.common_subscriptions,
    component: Subscription,
  },
  {
    url: routes.activeSubscriptionsList.url,
    title: language.common_subscriptions,
    component: Subscription,
  },
  {
    url: routes.cancelledSubscriptionsList.url,
    title: language.common_subscriptions,
    component: Subscription,
  },
  {
    url: routes.archievedSubscriptionsList.url,
    title: language.common_subscriptions,
    component: Subscription,
  },
  {
    url: routes.adminAddSubscriptions.url,
    title: language.common_subscriptions,
    component: AddSubscripiton,
  },
  {
    url: routes.editSubscriptions.url,
    title: language.common_subscriptions,
    component: EditSubscription,
  },
  {
    url: routes.customerDogInSubscriptions.url,
    title: language.common_subscriptions,
    component: EditSubscription,
  },
  {
    url: routes.assignmentsList.url,
    title: language.common_assignments,
    component: Assignments,
  },
  {
    url: routes.addAssignment.url,
    title: language.common_assignments,
    component: AddAssignment,
  },
  {
    url: routes.editAssignment.url,
    title: language.common_assignments,
    component: EditAssignment,
  },
  {
    url: routes.servicesList.url,
    title: language.common_services,
    component: Services,
  },
  {
    url: routes.addService.url,
    title: language.user_create_user,
    component: AddService,
  },
  {
    url: routes.editService.url,
    title: language.user_create_user,
    component: EditService,
  },
  {
    url: routes.calendar.url,
    title: language.common_calendar,
    component: Calendar,
  },
  {
    url: routes.usersList.url,
    title: language.common_users,
    component: Users,
  },
  {
    url: routes.addUser.url,
    title: language.user_create_user,
    component: AddUsers,
  },
  {
    url: routes.editUser.url,
    title: language.user_create_user,
    component: EditUsers,
  },
  {
    url: routes.assignmentsOfUser.url,
    title: language.user_create_user,
    component: EditUsers,
  },
  {
    url: routes.editOrganization.url,
    title: language.label_organisation,
    component: Organization,
  },
  {
    url: routes.pageNotFound.url,
    title: language.title_page_not_found,
    component: PageNotFound,
  },
  {
    url: routes.pageNotAccessible.url,
    title: language.title_page_not_found,
    component: PageNotAccessible,
  },
  {
    url: routes.bookingFlow.url,
    title: language.title_booking_flow,
    component: BookingFlow,
  },
  {
    url: routes.payment.url,
    title: language.title_payment,
    component: Payment,
  },
  {
    url: routes.cart.url,
    title: language.title_cart,
    component: Cart,
  },
  {
    url: routes.registerCustomer.url,
    title: language.register_customer,
    component: RegisterCustomer,
  },
  {
    url: routes.confirmCustomerRegistration.url,
    title: language.confirm_register_title,
    component: ConfirmCustomer,
  },
  {
    url: routes.customerResetPassword.url,
    title: language.reset_password_label_new_password,
    component: ResetPassword,
  },
  {
    url: routes.myProfile.url,
    title: language.common_customers,
    component: CustomerDetails,
  },
  {
    url: routes.myOrders.url,
    title: language.common_orders,
    component: MyOrders,
  },
  {
    url: routes.myDogs.url,
    title: language.common_dogs,
    component: CustomerDogs,
  },
  {
    url: routes.addMyDog.url,
    title: language.common_dogs,
    component: CustomerAddDog,
  },
  {
    url: routes.editMyDog.url,
    title: language.common_dogs,
    component: CustomerEditDog,
  },
  {
    url: routes.orderConfirm.url,
    title: language.common_orders,
    component: OrderConfirmPage,
  },
  {
    url: routes.confirmDogDetails.url,
    title: language.common_orders,
    component: DogDetails,
  },
  {
    url: routes.guestUserLogin.url,
    title: language.common_orders,
    component: GuestOrderConfirm,
  },
];
export default RoutesList;
