import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import TextArea from "../../../components/Input/TextArea";
import SelectBox from "../../../components/Input/SelectBox";
import UploadPicture from "../../../components/UploadSection/UploadPicture";
import {
  MDLabel, Row, Div, IsDesktop, IsMobile, MDImage, Span,
} from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import Service from "../../../services/ServicesPageService";
import Config from "../../../config";
import ServiceModel from "../../../models/Service";
import FormLoader from "../../../components/Loader/FormLoader";
import { getRoomsList } from "../../../services/RoomService";
const { language } = Constants;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(language.validation_service_name),
  description: Yup.string().required(language.validation_description),
  priceXS: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value >= 0,
    ),
  priceS: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value >= 0,
    ),
  priceM: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value >= 0,
    ),
  priceL: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value >= 0,
    ),
  durationXS: Yup.number().required(language.validation_duration)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  durationS: Yup.number().required(language.validation_duration)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  durationM: Yup.number().required(language.validation_duration)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  durationL: Yup.number().required(language.validation_duration)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  category: Yup.object().required(language.validation_select_category),
  profilePic: Yup.object().required(language.validation_profile_picture),
});

const ValidationSchemaForFullDayBooking = Yup.object().shape({
  name: Yup.string().required(language.validation_service_name),
  description: Yup.string().required(language.validation_description),
  priceXS: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  priceS: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  priceM: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  priceL: Yup.number().required(language.validation_price)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  category: Yup.object().required(language.validation_select_category),
  profilePic: Yup.object().required(language.validation_profile_picture),
});
class CreateServiceForm extends Component {
  service = new Service(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      additionalOptions: [],
      categoryOptions: [],
      roomsOptions: [],
      isTouched: false,
      isChecked: false,
      data: {
        name: "",
        category: "",
        description: "",
        fulldayservice: false,
        priceXS: "",
        priceS: "",
        priceM: "",
        priceL: "",
        durationXS: "",
        durationS: "",
        durationM: "",
        durationL: "",
        profilePic: "",
        room: "",
      },
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.fetchRooms();
    await this.fetchCategories();
    const { edit } = this.props;
    if (edit) {
      await this.handleFormatData();
    }
    this.setState({ loading: false });
  }

  handleFormatData=() => {
    const { serviceData } = this.props;
    const { categoryOptions, roomsOptions } = this.state;
    const { additionalOptions = [], data = {} } = serviceData;
    categoryOptions.map((obj) => {
      if (obj.value === data.category) {
        data.category = obj;
        return { ...obj, isSelected: true };
      }
      return { ...obj };
    });
    roomsOptions.map((obj) => {
      if (obj.value === data.room) {
        data.room = obj;
        return { ...obj, isSelected: true };
      }
      return { ...obj };
    });
    this.setState({ additionalOptions, data , isChecked: data?.fulldayservice });
  }

  fetchCategories=async () => {
    try {
      const response = await this.service.getCategoryList();
      const { data = [] } = response;
      const options = data.map((obj) => ({ label: obj.name, value: obj.id }));
      this.setState({ categoryOptions: options });
    } catch (e) {
      console.error(e);
    }
  }

  fetchRooms = async () => {
    const params = [["organization_rooms", false]];
    try {
      const response = await getRoomsList(params);
      const { data = [] } = response;
      const options = data.map((obj) => ({ label: obj.name, value: obj.id }));
      this.setState({ roomsOptions: options });
    } catch (e) {
      console.error(e);
    }
  };

  handleSubmit =async (data, props) => {
    const { setErrors } = props;
    this.setState({ loading: true });
    const serviceData = data;
    const { additionalOptions } = this.state;
    const {
      userId, edit, handleBackToServices, submitData,
    } = this.props;
    serviceData.additionalOptions = additionalOptions;
    try {
      this.serviceModel = new ServiceModel({});
      if (edit) {
        const response = await this.service.updateService(userId, this.serviceModel.toJson(data));
        if (response) {
          submitData(true);
        }
      } else {
        const response = await this.service.addService(this.serviceModel.toJson(data));
        if (response) {
          this.setState({ loading: false });
          handleBackToServices(true);
        }
      }
    } catch (e) {
      const { response: { status = "" } } = e;
      if (status !== 200) {
        setErrors({ name: Constants.language.validate_service_already_exists });
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false },
          handleBackToServices(false));
      }
    }
  };

  handleAdditionalOptions = (index) => (event) => {
    const { value, name } = event.target;
    const { additionalOptions } = this.state;
    additionalOptions[index][name] = value;
    this.setState({ additionalOptions });
  };

  handleAddOption = () => {
    const { additionalOptions } = this.state;
    additionalOptions.push({ name: "", duration: null, price: null });
    this.setState({ additionalOptions });
  };

  handleRemoveOption = (id) => () => {
    const { additionalOptions } = this.state;
    const options = additionalOptions.filter((o, index) => index !== id);
    this.setState({ additionalOptions: options });
  };

  handleBlurForSelectComp=() => {
    this.setState({ isTouched: true });
  }

  handleReset = () => {
    const { edit } = this.props;
    if (edit) {
      let {
        categoryOptions,
        roomsOptions,
      } = this.state;
      categoryOptions = categoryOptions.map((o) => ({ ...o, isSelected: false }));
      roomsOptions = roomsOptions.map((o) => ({ ...o, isSelected: false }));
      this.setState({
        isTouched: false,
        additionalOptions: [],
        categoryOptions,
        roomsOptions,
      });
    }
  }

  render() {
    const {
      input: { name: inputNames, type: inputTypes },
      icons,
    } = Constants;
    const {
      additionalOptions, categoryOptions, data, loading, isTouched, roomsOptions, isChecked,
    } = this.state;
    const { deleteService, edit, disableData } = this.props;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={isChecked ? ValidationSchemaForFullDayBooking :ValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {(props) => {
            const {
              values, handleChange, handleBlur, handleSubmit, isValid, touched, handleReset,
            } = props;
            return (
              <>
                {loading && <FormLoader />}
                {!loading && (
                  <>

                    <UploadPicture
                      label={language.label_picture}
                      value={values.profilePic}
                      name={inputNames.profilePic}
                      files={values.profilePic}
                      formikValues={props}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableData}
                    />
                    <Form>
                      <TextBox
                        startIcon={icons.TagIcon}
                        containerProps="m-auto justify-content-center"
                        label={language.label_name_of_service}
                        placeholder={language.placeholder_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        name={inputNames.name}
                        formikValues={props}
                        IconHeight="20px"
                        disabled={disableData}
                      />
                      <SelectBox
                        onChange={handleChange}
                        onBlur={this.handleBlurForSelectComp}
                        value={values.room}
                        name={inputNames.room}
                        options={roomsOptions}
                        placeholder={language.placeholder_choose}
                        label={language.label_select_room}
                        formikValues={props}
                        disabled={disableData}
                      />
                      <SelectBox
                        onChange={handleChange}
                        onBlur={this.handleBlurForSelectComp}
                        value={values.category}
                        name={inputNames.category}
                        options={categoryOptions}
                        placeholder={language.placeholder_choose}
                        label={language.label_category}
                        formikValues={props}
                        disabled={disableData}
                      />
                      <TextArea
                        containerProps="m-auto justify-content-center"
                        label={language.label_description}
                        placeholder={language.label_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        name={inputNames.description}
                        formikValues={props}
                        IconHeight="20px"
                        disabled={disableData}
                      />
                      <MDLabel fontSize={Theme.space.m} className="pb-2 pt-4" lineHeight={Theme.lineHeights.tight}>
                        <FormattedMessage id="label_price" />
                      </MDLabel>
                      <Row justifyContent="space-between">
                        <Div justifyContent="space-between" className="d-flex m-0 col-lg-12 col-md-12 p-0">
                          <TextBox
                            startIcon={icons.TagIcon}
                            containerProps="col p-0 mr-3"
                            label={language.label_for_mini_dogs}
                            placeholder={language.label_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.priceXS}
                            name={inputNames.priceXS}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData}
                          />
                          <TextBox
                            startIcon={icons.TagIcon}
                            containerProps="col p-0"
                            label={language.label_for_small_dogs}
                            placeholder={language.label_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.priceS}
                            name={inputNames.priceS}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData}
                          />
                        </Div>
                      </Row>
                      <Row>
                        <Div justifyContent="space-between" className="d-flex m-0 col-lg-12 col-md-12 p-0">
                          <TextBox
                            startIcon={icons.TagIcon}
                            containerProps="col p-0 mr-3"
                            label={language.label_for_medium_dogs}
                            placeholder={language.label_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.priceM}
                            name={inputNames.priceM}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData}
                          />
                          <TextBox
                            startIcon={icons.TagIcon}
                            containerProps="col p-0"
                            label={language.label_for_big_dogs}
                            placeholder={language.label_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.priceL}
                            name={inputNames.priceL}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData}
                          />
                        </Div>
                      </Row>
                      <MDLabel fontSize={Theme.space.m} className="pt-4" lineHeight={Theme.lineHeights.tight}>
                        <FormattedMessage id="label_duration" />
                      </MDLabel>
                      <Row justifyContent="space-between">
                        <Div justifyContent="space-between" className="d-flex m-0 col-lg-12 col-md-12 p-0">
                          <TextBox
                            startIcon={icons.ClockIcon}
                            containerProps="col p-0 mr-3"
                            label={language.label_for_mini_dogs}
                            placeholder={language.label_duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.durationXS}
                            name={inputNames.durationXS}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData} />
                          <TextBox
                            startIcon={icons.ClockIcon}
                            containerProps="col p-0"
                            label={language.label_for_small_dogs}
                            placeholder={language.label_duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.durationS}
                            name={inputNames.durationS}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData} />
                        </Div>
                      </Row>
                      <Row className="mb-4">
                        <Div justifyContent="space-between" className="d-flex m-0 col-lg-12 col-md-12 p-0">
                          <TextBox
                            startIcon={icons.ClockIcon}
                            containerProps="col p-0 mr-3"
                            label={language.label_for_medium_dogs}
                            placeholder={language.label_duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.durationM}
                            name={inputNames.durationM}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData} />
                          <TextBox
                            startIcon={icons.ClockIcon}
                            containerProps="col p-0"
                            label={language.label_for_big_dogs}
                            placeholder={language.label_duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.durationL}
                            name={inputNames.durationL}
                            formikValues={props}
                            IconHeight="20px"
                            width="160px"
                            login
                            type={inputTypes.number}
                            disabled={disableData} />
                        </Div>
                      </Row>
                      <MDLabel fontSize={Theme.space.m} className="pb-4 pt-1" lineHeight={Theme.lineHeights.tight}>
                        <FormattedMessage id="label_additional_options" />
                      </MDLabel>
                      {additionalOptions.map((phone, index) => (
                        <Div className="d-flex mb-3" key={index}>
                          <Div>
                            <MDImage
                              src={Constants.icons.MinusIcon}
                              alt="icon"
                              onClick={this.handleRemoveOption(index)}
                              className={`${disableData && "disabled-state "} mr-3`}
                            />
                          </Div>
                          <Div className="col p-0">
                            <MDLabel
                              fontSize={Theme.fontSizes.medium}
                              lineHeight={Theme.lineHeights.tight}
                            >
                              <FormattedMessage id="label_option" />
&nbsp;
                              {index + 1}
                            </MDLabel>
                            <TextBox
                              startIcon={icons.TagIcon}
                              containerProps="m-auto justify-content-center"
                              placeholder={language.placeholder_name}
                              label={language.placeholder_name}
                              onChange={this.handleAdditionalOptions(index)}
                              onBlur={handleBlur}
                              value={additionalOptions[index].name}
                              name={inputNames.name}
                              IconHeight="20px"
                              width="100%"
                              disabled={disableData}
                            />
                            <Div display="flex" flexDirection={["column", "row"]}>
                              <TextBox
                                startIcon={icons.AddIcon}
                                containerProps="col-6 pl-0"
                                placeholder={language.label_price}
                                label={language.label_additional_price}
                                onChange={this.handleAdditionalOptions(index)}
                                onBlur={handleBlur}
                                value={additionalOptions[index].price}
                                name={inputNames.price}
                                IconHeight="20px"
                                width="100%"
                                type={inputTypes.number}
                                disabled={disableData}
                              />
                              <TextBox
                                startIcon={icons.AddIcon}
                                containerProps={["px-0", "col-6 pr-0"]}
                                placeholder={language.label_duration}
                                label={language.label_additional_time}
                                onChange={this.handleAdditionalOptions(index)}
                                onBlur={handleBlur}
                                value={additionalOptions[index].duration}
                                name={inputNames.duration}
                                IconHeight="20px"
                                width="100%"
                                type={inputTypes.number}
                                disabled={disableData}
                              />
                            </Div>
                          </Div>
                        </Div>
                      ))}
                      <MDLabel onClick={this.handleAddOption} className="mb-3" disable={disableData}>
                        <MDImage src={Constants.icons.AddIcon} alt="icon" className="mr-2" />
                        <Span color={Theme.colors.primary} textDecoration="underline" cursor="pointer" disable={disableData}>
                          <FormattedMessage id="label_add_option" />
                        </Span>
                      </MDLabel>
                      <IsDesktop className="d-lg-flex">
                        <Button
                          primary
                          type="submit"
                          startIcon={icons.AssignmentsMenuIcon}
                          IconHeight="18px"
                          label={<FormattedMessage id="common_save" />}
                          onClick={handleSubmit}
                          disable={!(isValid && (Object.keys(touched).length > 0 || isTouched))}
                          width="200px"
                          className="mr-3"
                          disabled={disableData}
                        />
                        <Button
                          secondary
                          label={<FormattedMessage id="common_cancel" />}
                          type="reset"
                          onClick={() => { handleReset(); this.handleReset(); }}
                          width="200px"
                          className="ml-2"
                          disabled={disableData}
                        />
                        {edit && (
                          <Button
                            primary
                            type="button"
                            startIcon={icons.DeleteIcon}
                            IconHeight="18px"
                            label={<FormattedMessage id="common_delete" />}
                            onClick={deleteService}
                            width="200px"
                            className="ml-4"
                            disabled={disableData}
                          />
                        )}
                      </IsDesktop>
                      <IsMobile>
                        <Button
                          primary
                          type="submit"
                          startIcon={icons.AssignmentsMenuIcon}
                          IconHeight="18px"
                          label={<FormattedMessage id="common_save" />}
                          onClick={handleSubmit}
                          disable={!(isValid && (Object.keys(touched).length > 0 || isTouched))}
                          width="100%"
                          className="mb-3"
                          disabled={disableData}
                        />
                        <Button
                          secondary
                          label={<FormattedMessage id="common_cancel" />}
                          type="reset"
                          onClick={() => { handleReset(); this.handleReset(); }}
                          width="100%"
                          className="mb-4 mt-1"
                          disabled={disableData}
                        />
                        <Button
                          primary
                          type="button"
                          startIcon={icons.DeleteIcon}
                          IconHeight="18px"
                          label={<FormattedMessage id="common_delete" />}
                          onClick={deleteService}
                          width="100%"
                          className="mb-4"
                          disabled={disableData}
                        />
                      </IsMobile>
                    </Form>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
CreateServiceForm.propTypes = {
  edit: PropTypes.bool,
  deleteService: PropTypes.func,
  handleBackToServices: PropTypes.func,
  serviceData: PropTypes.node,
  disableData: PropTypes.bool,
  userId: PropTypes.string,
  submitData: PropTypes.func,
};
CreateServiceForm.defaultProps = {
  edit: false,
  deleteService: undefined,
  handleBackToServices: undefined,
  serviceData: {},
  disableData: false,
  userId: null,
  submitData: undefined,
};
export default CreateServiceForm;
