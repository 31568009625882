import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import AuthService from "../services/AuthService";
import Constants from "./Constants";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    location, path, exact, computedMatch, isAdmin,
  } = rest;
  const authService = new AuthService();
  const paths = [
    Constants.routes.logIn.url,
    Constants.routes.customerLogin.url,
    Constants.routes.home.url,
    Constants.routes.bookingFlow.url,
    Constants.routes.forgotPassword.url,
    Constants.routes.resetPassword.url,
    Constants.routes.payment.url,
    Constants.routes.cart.url,
    Constants.routes.registerCustomer.url,
    Constants.routes.confirmCustomerRegistration.url,
    Constants.routes.customerResetPassword.url,
    Constants.routes.orderConfirm.url,
    Constants.routes.guestUserLogin.url,
    Constants.routes.chooseService.url,
    Constants.routes.chooseDog.url,
    Constants.routes.chooseCenter.url,
    Constants.routes.chooseTimeDate.url,
    Constants.routes.orderSummary.url,
    Constants.routes.chooseSubscription.url,
    Constants.routes.chooseSubscriptionDog.url,
    Constants.routes.chooseSubscriptionOrganization.url,
    Constants.routes.chooseSubscriptionDate.url,
    Constants.routes.subscriptionOrderSummary.url,
    Constants.routes.subscriptionConfirmation.url,
    Constants.routes.confirmDogDetails.url
  ];
  const avaliablePaths = Object.values(Constants.routes).map((o) => o.url);
  return (
    <Route
      location={location}
      exact={exact}
      path={path}
      computedMatch={computedMatch}
      isAdmin={isAdmin}
      render={(props) => (authService.isAuthenticated() === true
        || paths.includes(path) ? (
          <Component
            history={props.history}
            location={props.location}
            match={props.match}
            path={path}
            staticContext={props.staticContext}
            isAdmin={isAdmin}
          />
        ) : (
          avaliablePaths.includes(path)
            ? (
              <Redirect
                to={{
                  pathname: Constants.routes.logIn.url,
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: Constants.routes.pageNotFound.url,
                }}
              />
            )
        ))}
    />
  );
};
PrivateRoute.propTypes = {
  /**
     * name of the component
     */
  component: PropTypes.func,
  /**
   * History of the routing
   */
  history: PropTypes.string,
  /**
   * current locaton and pathname
   */
  location: PropTypes.string,
  match: PropTypes.string,
  staticContext: PropTypes.string,
};
PrivateRoute.defaultProps = {
  component: undefined,
  history: null,
  location: null,
  match: null,
  staticContext: null,
};
export default PrivateRoute;
