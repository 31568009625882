/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Constants from "../shared/Constants";
import SideMenu from "./Sidemenu/Sidemenu";
import Header from "./Header/Header";
import AuthService from "../services/AuthService";
import { MainPanel, SideMenuPanel, Div } from "../styles/Styles";
import Colors from "../styles/Colors";
import { RoutesList as routesList } from "../shared/RoutesList";
import CustomerFlowHeader from "../pages/BookingFlow/CommonSections/Header/BookingFlowHeader";
import CustomerFlowFooter from "../pages/BookingFlow/CommonSections/Footer/BookingFlowFooter";
import Menu from "../pages/MyAccounts/Menu";

function Container(props) {
  const authService = new AuthService();
  const { children } = props;
  const routePath = window.location.pathname.split("/");
  const routePathName = `/${routePath[routePath.length - 1]}`;
  const route = routesList.find((obj) => obj.url.includes(routePathName));
  if (route) {
    document.title = Constants.ApplicationName + Constants.TitleDivder + route.title;
  }
  const isAuthenticated = authService.isAuthenticated() === true;
  const sidemenuRoute = window.location.pathname;
  const isAdminPortal = sidemenuRoute.search(Constants.routes.adminPortal.url) >= 0;
  const isMyAccounts = sidemenuRoute.search(Constants.routes.myAccounts.url) >= 0;

  return (
    <div>
      <div className={`${isAuthenticated ? (isAdminPortal ? "row" : "") : "w-100"} h-100  m-0`} id="main-wrapper">
        {isAuthenticated && isAdminPortal ? (
          <>
            <SideMenuPanel>
              <SideMenu route={route} {...props} />
            </SideMenuPanel>
            <Div className="col p-0">
              <Header />
            </Div>
            <MainPanel>{children}</MainPanel>
          </>
        ) : !isAuthenticated && isAdminPortal
          ? (
            <>
              <CustomerFlowHeader />
              <Div
                className="col mh-100 h-100 p-0"
                bg={Colors.DarkWhite}
                position="absolute"
                height={["calc(100vh - 136px) !important", "calc(100vh - 70px) !important"]}
                bottom={0}
              >
                {children}
              </Div>
            </>
          )
          : (
            <>
              <CustomerFlowHeader />
              <Div
                className="col p-0"
                bg={Colors.DarkWhite}
                position="absolute"
                height={["calc(100vh - 136px) !important", "calc(100vh - 70px) !important"]}
                bottom="unset"
              >
                {isMyAccounts
                  ? (
                    <Menu>
                      {children}
                    </Menu>
                  )
                  : (
                    <Div className="min-vh-100">
                      {children}
                    </Div>
                  )}
                <CustomerFlowFooter />
              </Div>
            </>
          )}
      </div>
    </div>
  );
}
Container.propTypes = {
  /**
   * Childern component
   */
  children: PropTypes.func,
};
Container.defaultProps = {
  children: undefined,
};

export default withRouter(Container);
