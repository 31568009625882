import { Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import { Div, InputBox, MDLabel, P } from "../../../styles/Styles";
import { BoxDivision, Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import AboutDogForm from "../../Payment/FormContent/AboutDogForm";
import AdditionalInfoForm from "../../Payment/FormContent/AdditionalInfoForm";
import SelectedSubscriptionDetail from "../Components/SelectedSubscriptionDetail";
import { isCustomer, isLoggedIn } from "../../../shared/utils";
import { getDogDetail } from "../../../services/BookingFlowServices";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import PrevNextButtons from "../Components/PrevNextButtons";
import * as Yup from "yup";
import { getDogBreeds } from "../../../services/CustomerService";
import { fetchDogDetails } from "../../../store/actions/BookingFlowServicesAction";
import Loader from "../../../components/Loader/CardLoader";

const { icons, language, options: { dogsAge, dogsHeight, dogsWeight } } = Constants;

const fullValidationSchema = Yup.object().shape({
  dogName: Yup.string().required(language.dog_name_label),
  dogBreed: Yup.object().required(language.dog_breed_label),
  dogGender: Yup.string().required(language.validation_dog_gender),
  sterilized: Yup.string().required(language.validation_option),
  dogAge: Yup.object().required(language.dog_age_label),
  dogInsured: Yup.string().required(language.validation_option),
  moreInformation: Yup.string().required(language.validation_additional_information),
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  telephone: Yup.string().required(language.validation_valid_phone_number),
  dog_size_height: Yup.object().required(language.validation_height),
  dog_size_weight: Yup.object().required(language.validation_weight),
});

const loggedInValidationSchema = Yup.object().shape({
  dogName: Yup.string().required(language.dog_name_label),
  dogBreed: Yup.object().required(language.dog_breed_label),
  dogGender: Yup.string().required(language.validation_dog_gender),
  sterilized: Yup.string().required(language.validation_option),
  dogAge: Yup.object().required(language.dog_age_label),
  dogInsured: Yup.string().required(language.validation_option),
  moreInformation: Yup.string().required(language.validation_additional_information),
  dog_size_height: Yup.object().required(language.validation_height),
  dog_size_weight: Yup.object().required(language.validation_weight),
});

const SubscriptionFlowStepTwo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState({
    dogName: "",
    dogBreed: "",
    dogGender: "",
    dog_size_weight: "",
    dog_size_height: "",
    sterilized: "",
    dogAge: "",
    dogInsured: "",
    moreInformation: "",
    house: "",
    flat: "",
    firstName: "",
    lastName: "",
    telephone: "",
    city: "",
    postcode: "",
    isNewToDayCare: true,
  });

  const [userDogs, setUserDogs] = useState([]);
  const [enableForm, setEnableForm] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userSelectedDog, setUserSelectedDog] = useState({});
  const [dogBreedList, setDogBreedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const userData = localStorage.userData && JSON.parse(localStorage.userData);
  const customerId = userData?.customer?.id;

  const info = useSelector((state) => state.SubscriptionBookingFlowReducer.info);

  const getYesOrNo = (value) => value ? language.common_yes : language.common_no;

  useEffect(() => {
    if (info && dogBreedList.length > 0 && Object.keys(info).length > 0) {
      const dogWeight = dogsWeight.find((bo) => bo.value === info.dog_size_weight);
      const dogHeight = dogsHeight.find((bo) => bo.value === info.dog_size_height);
      const isNewToDayCareOptions = [
        {
          value: true,
          label: language.common_yes,
          checked: true,
        },
        {
          value: false,
          label: language.common_no,
        },
      ];
      const isNewToDayCare = isNewToDayCareOptions.find((bo) => bo.value === info.isNewToDayCare);
      setFormValues({
        ...info,
        dogInsured: getYesOrNo(info.dogInsured),
        sterilized: getYesOrNo(info.sterilized),
        dogBreed: dogBreedList.find((o)=> o.value === info.dogBreed),
        dogAge: dogsAge.find((o)=> o.value === info.dogAge),
        dog_size_weight: dogWeight || "",
        dog_size_height: dogHeight || "",
        isNewToDayCare: isNewToDayCare || false,
      });
    }
  }, [info, dogBreedList]);

  const handlePrevPage = () => {
    dispatch(SubscriptionBookingFlowActions.previousStep());
    history.push(Constants.routes.chooseSubscription.url);
  };

  const handleFormSubmit = async (event) => {
    const data = { ...event };
    data.isNewToDayCare = event?.isNewToDayCare?.value;
    data.sterilized = event?.sterilized === "Ja";
    data.dogInsured = event?.dogInsured === "Ja";
    data.dogAge = event?.dogAge?.value;
    data.dogBreed = event?.dogBreed?.value;
    data.dog_size_height = event?.dog_size_height?.value;
    data.dog_size_weight = event?.dog_size_weight?.value;
    dispatch(SubscriptionBookingFlowActions.setInfo(data));

    dispatch(SubscriptionBookingFlowActions.nextStep());
    history.push(Constants.routes.chooseSubscriptionOrganization.url);
  };

  const handleNext = () => {
    if (userSelectedDog) {
      const data = {
        dogName: userSelectedDog.name,
        dogBreed: userSelectedDog.breed,
        dogGender: userSelectedDog.gender,
        dog_size_weight: userSelectedDog.dog_size_weight,
        dog_size_height: userSelectedDog.dog_size_height,
        sterilized: userSelectedDog.isSterilized,
        dogAge: userSelectedDog.age,
        dogInsured: userSelectedDog.isInsured,
        moreInformation: userSelectedDog.description,
        isNewToDayCare: userSelectedDog.isNewToDayCare,
        id: userSelectedDog ? userSelectedDog.id : null,
      };
      dispatch(SubscriptionBookingFlowActions.setInfo(data));
      dispatch(SubscriptionBookingFlowActions.nextStep());
      history.push(Constants.routes.chooseSubscriptionOrganization.url);
    }
  };

  useEffect(async () => {
    if (isUserLoggedIn && customerId) {
      setIsLoading(true);
      await dispatch(fetchDogDetails(customerId, customerId));
      setIsLoading(false);
    }
  }, [isUserLoggedIn, customerId]);

  useEffect(async () => {
    setIsLoading(true);
    setDogBreedList(await getDogBreeds());
    setIsLoading(false);
    if (isLoggedIn() && isCustomer()) {
      const loggedInUserData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
      const customerId = loggedInUserData?.customer.id;
      getDogDetail(customerId, customerId)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setUserDogs(response.data);
            setEnableForm(false);
          } else {
            setEnableForm(true);
          }
        })
        .catch(() => {
          setEnableForm(true);
        });

      setIsUserLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn() && isCustomer() && info && Object.keys(info).length > 0 && info.id) {
      userDogs.forEach((dog) => {
        if (info.id === dog.id) {
          setUserSelectedDog(dog);
        }
      });
    }
  }, [info, userDogs]);


  const handleDogSelection = (dog) => {
    setUserSelectedDog(dog);
    setEnableForm(false);
  };

  const renderUserDogs = () => {
    if (userDogs && userDogs?.length > 0) {
      return userDogs.map((dog) => (
        <Division key={dog.name} type="select_Size" mb={3} mx={1} width="300px" setBorder>
          <InputBox
            type="radio"
            name="dogs"
            title={dog?.name}
            id={dog?.id}
            onChange={handleDogSelection.bind(this, dog)}
            checked={userSelectedDog?.id === dog?.id}
            hidden
          />
          <CommonCard
            htmlFor={dog?.id}
            avtarImg={dog?.profile_pic?.url || FavIcon}
            imgWidth={80}
            imgHeight={80}
            header={dog?.name}
            leftContent={dogBreedList.find(breed => breed.value === dog?.breed)?.label}
            fontSize={Theme.fontSizes.small}
            fontFamily={Theme.fonts.semibold}
            paddingLeft={12}
            msRight="msRight"
            color={Colors.DarkGrey}
            heightValue={90}
            width="255px !important"
            isDog
            imagePadding={dog?.profile_pic?.url ? "" : "8px"}
          />
        </Division>
      ));
    }
  };

  const addAnotherDog = () => {
    setEnableForm(true);
    setUserSelectedDog(formValues);
  };

  return (
    <Division>
      <SelectedSubscriptionDetail />
      {isUserLoggedIn && userDogs && userDogs.length > 0 && (
        <Div>
          <Div textAlign={["center"]} mt={4} pt={2} mb={4}>
            <MDLabel fontSize={Theme.fontSizes.xl} justifyContent="center" mb={20}>
              <FormattedMessage id="your_label_dog" defaultMessage="Your Dog" />
            </MDLabel>
            <P fontFamily={Theme.fonts.regular} fontSize={Theme.fontSizes.regular} color={Colors.DarkGrey} px={3}>
              <FormattedMessage id="please_choose_a_dog_label" defaultMessage="Please, choose a dog" />
            </P>
          </Div>
          {isLoading
            ? (
              <BoxDivision mb={20}>
                <Loader width={255} height={64} />
              </BoxDivision>
            )
            : (
              <BoxDivision type="wrap_div" px={3}>
                {renderUserDogs()}
              </BoxDivision>
            )}
          {!enableForm && (
            <Div textAlign={["center"]} mt={4} pt={2} mb={4}>
              <Button primary label={language.another_dog} startIcon={icons.PlusIcon} onClick={addAnotherDog} />
            </Div>
          )}
        </Div>
      )}
      {enableForm && (
        <Formik
          validationSchema={isUserLoggedIn ? loggedInValidationSchema : fullValidationSchema}
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleFormSubmit}>
          {(formik) => {
            const { values, handleChange, handleBlur, handleSubmit, validateField } = formik;
            return (
              <Division>
                {isLoading
                  ? (
                    <BoxDivision mb={20}>
                      <Loader width={255} height={64} />
                    </BoxDivision>
                  )
                  : (
                    <AboutDogForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      hideSubmitButton={true}
                      hideDuration={true}
                      validateField={validateField}
                      newDog={true}
                      showNewToDayCareSelect={true}
                      isSubscription={true}
                      formik={formik}
                    />
                  )}
                {!isUserLoggedIn && (
                  isLoading ? (
                    <BoxDivision mb={20}>
                      <Loader width={255} height={64} />
                    </BoxDivision>
                  ) : (
                    <AdditionalInfoForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      hideSubmitButton={true}
                      requestPickUp={true}
                      formik={formik}
                    />
                  )
                )}
                {isLoading
                  ? (
                    <BoxDivision mb={20}>
                      <Loader width={255} height={64} />
                    </BoxDivision>
                  )
                  : (
                    <PrevNextButtons
                      handleNext={handleSubmit}
                      handlePrevPage={handlePrevPage}
                      nextDisabled={false}
                      showNextButton={true}
                    />
                  )}
              </Division>
            );
          }}
        </Formik>
      )}
      {!enableForm && (
        <PrevNextButtons
          handleNext={handleNext}
          handlePrevPage={handlePrevPage}
          nextDisabled={!userSelectedDog || !Object.keys(userSelectedDog).length > 0}
          showNextButton={true}
        />
      )}
    </Division>
  );
};
export default memo(SubscriptionFlowStepTwo);
