import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import { EditBox, SpanElement } from "../Style";
import {
  Box, Div, MDImage, MDLabel,
} from "../../../styles/Styles";
import { BoxDivision, MDLabelBox } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import Constants from "../../../shared/Constants";

const ServiceSelected = (props) => {
  const {
    imageSrc, headerTitle, leftIcon, rightIcon, leftContent,
    rightContent, isEditable, type, newWidth, fontSize, fontWeight, fontFamily,
    stepValue, handleOnClick,
  } = props;
  const {
    shadows, radii, heights, fontSizes, colors, fontWeights,
  } = Theme;
  return (
    <SpanElement
      height={65}
      position="relative"
      newWidth={newWidth}
      type={!isEditable && "services"}
      boxShadow={shadows.light}
      borderRadius={radii.medium}
      ml={3}
      mr={["20px", "20px", 2]}
      my={2}
      display="flex"
      alignItems="center"
      width={["100%", "100%", "258px"]}
      justifyContent={[!imageSrc && "center" ,"unset", "unset",]}
    >
      {imageSrc && <MDImage src={imageSrc} borderRadius={radii.medium} alt="icon" alignSelf="center" height={heights.h100} width={65} />}
      {!isEditable && (
        <EditBox fontSize={fontSizes.medium} type="editdiv" color={colors.text.white} cursor="pointer" onClick={handleOnClick(stepValue)}>
          <FormattedMessage id="common_edit" defaultMessage="Edit" />
        </EditBox>
      )}
      <Div display={!imageSrc ? "flex" : "inline-block"} justifyContent="center" flexDirection="column" verticalAlign="middle" pl={3}>
        <MDLabel alignSelf="start" fontWeight={fontWeights.medium} fontSize={fontSizes.regular} mt={2}>
          {stepValue === 2 ? Constants.translations[headerTitle] : headerTitle}
        </MDLabel>
        <BoxDivision mt={2} type="choose_Item" justifyContent={[!imageSrc && "center !important","start !important", "start !important",]}>
          {leftIcon && (
            <Box pr={1}>
              <MDImage src={leftIcon} mr={1} alt="icon" />
              <MDLabelBox
                type={type}
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontFamily={fontFamily}
                textAlign="left"
                display="block"
                width="100%"
              >
                {leftContent}
              </MDLabelBox>
            </Box>
          )}
          {rightIcon && (
            <Box pr={1} ml={3}>
              <MDImage src={rightIcon} mr={1} alt="icon" />
              <MDLabel
                type={type}
                fontSize={fontSize}
                fontWeight={fontWeight}
                fontFamily={fontFamily}
                textAlign="left"
                display="block"
                width="100%"
              >
                {rightContent}
              </MDLabel>
            </Box>
          )}
        </BoxDivision>
      </Div>
    </SpanElement>
  );
};
ServiceSelected.propTypes = {
  imageSrc: PropTypes.node,
  headerTitle: PropTypes.string,
  leftIcon: PropTypes.node,
  leftContent: PropTypes.node,
  rightIcon: PropTypes.node,
  rightContent: PropTypes.node,
  isEditable: PropTypes.bool,
  type: PropTypes.string,
  newWidth: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  fontFamily: PropTypes.string,
  stepValue: PropTypes.string,
  handleOnClick: PropTypes.func,
};
ServiceSelected.defaultProps = {
  imageSrc: "",
  headerTitle: "Loading...",
  leftIcon: "",
  leftContent: "Loading...",
  rightIcon: "",
  rightContent: "Loading...",
  isEditable: false,
  type: "",
  newWidth: "",
  fontSize: "",
  fontWeight: "",
  fontFamily: "",
  stepValue: "",
  handleOnClick: () => {},
};
export default memo(ServiceSelected);
