import { getAll, get } from "./ApiService";
import Constants from "../shared/Constants";
import { getServiceList as getOrdersServices } from "./SubscriptionService";

const { HTTP_STATUS: { OK }, apiEndPoints: { endPointUrl: endPoint } } = Constants;

export const getAllCategories = async () => {
  const response = await get(endPoint.CATEGORIES);
  const { data: { data = [] } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  const categories = data.map((o) => ({ label: o.name, value: o.name }));
  return categories;
};

export const getAllServices = async () => {
  const response = await get(`${endPoint.ORDERS}/${endPoint.SERVICES}`);
  const { data: { data = [] } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  const res = data.map((o) => ({ label: o.name, value: o.name }));
  return res;
};

export const getAllOrganizations = async () => {
  const response = await get(`${endPoint.ORDERS}/${endPoint.ORGANIZATIONS}`);
  const { data: { data = [] } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data.map((o) => ({ label: o.name, value: o.id }));
};

export const getAllOrdersServices = async () => {
  const {data} = await getOrdersServices()
  return data.map((o) => ({ label: o.name, value: o.id }));
};

export const getAllFilters = async () => {
  const response = await getAll(
    [
      getAllServices(),
      getAllCategories(),
      getAllOrdersServices()
    ],
  );
  const { status = "", statusText = "" } = response;
  if (status && status !== OK) {
    throw Error(statusText);
  }
  return response;
};

export default getAllFilters;
