import { request } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser, saveAvailabilityUTCDateConvertedJson, fetchAvailabilityLocalDateConvertedJson } from "../shared/utils";

const { apiEndPoints: { endPointUrl = {} } } = Constants;

export function getAvailabilityPromise(params) {
  const url = `${endPointUrl.SLOTS}`;
  const queryParams = [...params];
  const modifiedUrl = params.length ? `${endPointUrl.SLOTS}${queryParamParser(queryParams)}` : url;
  return request("get", modifiedUrl)
    .then((response) => {
      let { data: formattedData = [] } = response.data;
      if (formattedData) {
        formattedData = formattedData.map((o) => (fetchAvailabilityLocalDateConvertedJson(o)));
      }
      return { ...response, data: formattedData };
    });
}

export async function getAvailability(params) {
  const response = await getAvailabilityPromise(params);
  if (response && response.statusCode !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  return response;
}

export async function storeSlotAvailability(params) {
  const url = `${endPointUrl.SLOTS}`;
  const response = await request("post", url, saveAvailabilityUTCDateConvertedJson(params));
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}
export async function getUserServices(params) {
  const url = `${endPointUrl.USER_SERVICES}?user_id=${params}`;
  const response = await request("get", url);
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}
export async function getSlotData(params) {
  const url = `${endPointUrl.SLOTS}/${params.slot_id}`;
  const response = await request("get", url, params);
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return {
    ...data,
    data: fetchAvailabilityLocalDateConvertedJson(data.data),
  };
}

export async function updateSlotData(params) {
  const url = `${endPointUrl.SLOTS}`;
  const response = await request("put", url, saveAvailabilityUTCDateConvertedJson(params));
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}
export async function deleteAvailability(params) {
  const url = `${endPointUrl.DELETE_AVAILABILITY}`;
  const response = await request("post", url, saveAvailabilityUTCDateConvertedJson(params));
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}

export async function deleteAvailabilityDetails(params) {
  const url = `${endPointUrl.DELETE_AVAILABILITY_DETAILS}`;
  const response = await request("post", url, params);
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data = {} } = response;
  return data;
}
