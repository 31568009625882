import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import Accordion from "./Accordion";
import Loader from "../../components/Loader/Loader";
import {
  Div,
  MDLabel,
} from "../../styles/Styles";
import Pagination from "../../components/Pagination/Pagination";
import { getOrdersList } from "../../services/CustomerService";
import { cancelOrder } from "../../services/OrdersService";
import Modal from "../../components/Modal/Modal";
import Constants from "../../shared/Constants";
import Card from "../../components/Card/Card";

const { icons, language } = Constants;

const MyOrders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isCancelModalOpen, setIsCancelModal] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeader, setAlertHeader] = useState("");
  const [orderData, setOrderData] = useState({});
  const [partData, setPartData] = useState("");
  const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
  const { customer = {} } = userData;
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 10,
    pages: 1,
  });

  const handleCancelModal = (item) => () => {
    const today = new Date();

    const { assignments = [] } = item;
    const noCancellation = [];
    const getRefund = assignments.map((obj) => {
      const { assignment_start_datetime: dateTime = "" } = obj;
      const isPastDate = (new Date(dateTime) - today) < 0;
      if (!isPastDate) {
        return (new Date(dateTime).getTime() - today.getTime() > 7200000);
      }
      return noCancellation.push(true);
    }).filter((o) => o === false);
    if (noCancellation.length) {
      setAlertHeader(language.header_error);
      setAlertMessage(language.order_no_cancellation_message);
      setChangesSaved(true);
    } else if (getRefund.length) {
      setOrderData(item);
      setAlertMessage(language.order_no_refund_message);
      setIsCancelModal(true);
    } else {
      setOrderData(item);
      setAlertMessage(language.order_get_refund_message);
      setIsCancelModal(true);
    }
  };

  const fetchAssignments = () => {
    setLoading(true);
    const { id = "" } = customer || {};
    const queryParams = [
      ["pageNumber", meta.pageNumber],
      ["pageSize", meta.pageSize],
    ];
    getOrdersList(id, queryParams).then((response) => {
      const { meta: srcMetaData = {}, data = [] } = response;
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMetaData;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      const ordersData = data.map((order) => (
        {
          ...order,
          assignments: order.assignments.map((assignment) => (
            {
              ...assignment,
              assignment_start_datetime: moment.utc(assignment.assignment_start_datetime).local(),
            }
          )),
        }
      ));
      setOrders(ordersData);
      setMeta(metaData);
      setLoading(false);
    }, (error) => {
      console.error(error);
      setLoading(false);
    });
  };

  const handleGotoPage = (pageNumber) => {
    setMeta({ ...meta, pageNumber });
  };

  const handleCancel = () => {
    setAlertMessage("");
    setAlertHeader("");
    setPartData("");
    setIsCancelModal(false);
    setChangesSaved(false);
    setOrderData({});
  };

  const handleCancelOrder = () => {
    setLoading(true);
    const { id = "" } = orderData;
    const { id: cusId = "" } = customer || {};
    let payload = {};
    if (partData) {
      const { id: partId = "", service_total: total = 0 } = partData;
      payload = {
        id: partId,
        total,
      };
    }
    cancelOrder(id, cusId, payload).then((response) => {
      if (response) {
        fetchAssignments();
        setIsCancelModal(false);
        setAlertMessage(partData ? language.part_order_cancelled : language.order_cancelled);
        setChangesSaved(true);
        setPartData("");
        setOrderData({});
        setLoading(false);
      }
    }, (error) => {
      console.error(error);
      setIsCancelModal(false);
      setAlertMessage(language.alert_try_later);
      setChangesSaved(true);
      setPartData("");
      setOrderData({});
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchAssignments();
  }, [meta.pageNumber]);

  return (
    <Div m={4} pt={2}>
      <MDLabel mb={3} fontSize="24px">
        <FormattedMessage id="your_label_orders" defaultMessage="Your orders" />
      </MDLabel>
      {loading
        ? <Loader height={50} />
        : orders.length ? (
          <>
            <Accordion data={orders} onCancel={handleCancelModal} />
            {meta.pages > 1 && (
              <Div py={4}>
                <Pagination gotoPage={handleGotoPage} cursor={meta} />
              </Div>
            )}
          </>
        )
          : (
            <MDLabel my={2} fontSize="16px">
              <FormattedMessage id="my_account_no_orders" defaultMessage="You have not ordered anything yet." />
            </MDLabel>
          )}
      {isCancelModalOpen && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="button_cancel_order" defaultMessage="Cancel Order" />}
            message={alertMessage}
            buttonLabel={<FormattedMessage id="common_yes" defaultMessage="Cancel" />}
            confirmationCard
            buttonIcon={icons.DeleteIcon}
            onClick={handleCancelOrder}
            onCancel={handleCancel}
            onLoader={loading}
          />
        </Modal>
      )}
      {changesSaved && (
        <Modal>
          <Card
            header={<FormattedMessage id="common_done" defaultMessage="Done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
            onClick={handleCancel}
          />
        </Modal>
      )}
    </Div>
  );
};

export default MyOrders;
