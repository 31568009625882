import styled from "styled-components";
import Theme from "../../styles/Theme";

export const HeaderContainer = styled.div`
  width: calc(100% - 192px) !important;
  background-color: ${Theme.colors.white};
  top: 0;
  right: 0;
  z-index: 999;
  position: sticky;
  font-family: ${Theme.fonts.semibold};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  @media (max-width: 768px) {
    width: calc(100% - 192px) !important;
  }
  @media (max-width: 570px) {
    width: 100% !important;
    padding: 20px 16px;
  }
`;
export const ProfileWrapper = styled.div`
  text-align: right !important;
  padding: 0px;
  display: inherit;
  margin-right: 16%;
  @media (max-width: 570px) {
    margin-right: 0;
  }
`;
