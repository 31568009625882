import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Constants from "../../shared/Constants";
// import {
//   isLoggedIn, isAdmin, isCustomer,
//   redirectToPage,
// } from '../../shared/utils';
import { redirectToPage } from "../../shared/utils";

const Home = ({ history = {}, userDetails = {} }) => {
  const { location: { pathName = "" } = {} } = history;
  const data = userDetails.user_access
    ? userDetails : localStorage.userData ? JSON.parse(localStorage.userData) : {};
  const { type = "" } = userDetails;
  if (pathName) {
    if (type === "customer") {
      return <Redirect to={Constants.routes.myProfile.url} />;
    }
    return <Redirect to={redirectToPage(data)} />;
  }
  return (<Redirect to={Constants.routes.bookingFlow.url} />);
};

// const Home = (props) => {
//   const { userDetails = {} } = props;
//   console.log('entered');
//   const data = userDetails.user_access
//     ? userDetails : localStorage.userData ? JSON.parse(localStorage.userData) : {};
//   if (!isLoggedIn()) {
//     return <Redirect to={Constants.routes.bookingFlow.url} />;
//   }
//   if (isAdmin()) {
//     return <Redirect to={Constants.routes.ordersList.url} />;
//   }
//   if (isCustomer()) {
//     return <Redirect to={Constants.routes.myProfile.url} />;
//   }
//   return (
//     <Redirect to={redirectToPage(data)} />
//   );
// };

Home.propTypes = {
  userDetails: PropTypes.node,
  history: PropTypes.node,
};
Home.defaultProps = {
  userDetails: {},
  history: {},
};

const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});
export default connect(mapStateToProps)(Home);
