import { combineReducers } from "redux";
import AppReducer from "./AppReducer";
import FilterReducer from "./FilterReducer";
import BookingFlowServicesReducer from "./BookingFlowServicesReducer";
import PaymentReducer from "./PaymentReducer";
import { DESTROY_SESSION } from "../actions/types";
import SubscriptionBookingFlowReducer from "./SubscriptionBookingFlowReducer";

// Combine all reducers.
export const RootReducer = (state, action) => {
  let currentState = state;
  const appReducer = combineReducers({
    AppReducer,
    FilterReducer,
    BookingFlowServicesReducer,
    PaymentReducer,
    SubscriptionBookingFlowReducer
  });
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    currentState = undefined;
  }
  return appReducer(currentState, action);
};

export default RootReducer;
