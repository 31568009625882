/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Colors from "../../styles/Colors";
import Label from "../Label/Label";
import PopupCard from "./Card";
import {
  Card as CardWrapper,
  Div,
  MDLabel,
  Span,
  MDImage,
  Box,
  P,
  Link,
} from "../../styles/Styles";
import FavIcon from "../../assets/Icons/FavIcon.svg";
import Constants from "../../shared/Constants";

const { themes: { fontSizes } } = Constants;
/**
 * Profile card component
 */
const ProfileCard = ({
  userAvtar, onClick,
  nameCard, width, minWidth, height, profileCard, serviceCard, serviceProviderCard, data = {}, handleMore, handleDelete,
  disableData = false, isCalendar = false,
  showCursor = false,
  serviceProviderDetails= {},
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const dogBreeds = useSelector((state) => state.AppReducer.dogBreeds);
  const {
    organisationServiceProviders,
  } = useSelector((state) => state.BookingFlowServicesReducer);
  const { id = ""}= serviceProviderDetails;
  const { description: aboutUserDescription = "" } = organisationServiceProviders?.find((o)=>(o?.id === id)) || {};
  const showPopup = (values) => () => {
    setOpenPopup(true);
    setServiceData(values);
  };
  const closePopup = () => {
    setOpenPopup(false);
    setServiceData({});
  };
  const {
    label = "", description = "", duration = "", price = "",
    smallDogDuration = "", smallDogPrice = "",
  } = serviceData;
  const {
    info = "", breed = "", isSelected = false, name = "",
    open_assignment: openAssignment = 0, open_assignments: openAssignments = 0,
    label: serviceLabel = "",
  } = data || {};
  return (
    <Div
      className={openPopup ? "w-100 position-relative" : showCursor ? "cursor-pointer w-100" : "w-auto"}
      display={["flex", "flex", "flex", "block"]}
      flex={[1, 1, 1, 0]}
    >
      {openPopup && (
        <PopupCard
          header={label}
          message={description}
          duration={smallDogDuration || duration || ""}
          price={smallDogPrice || price || ""}
          isPopOver
        />
      )}
      <CardWrapper
        width={profileCard ? "350px" : width || "fit-content"}
        minWidth={minWidth}
        height={nameCard
          ? isSelected
            ? "56px"
            : "50px"
          : height
            ? isSelected
              ? "70px"
              : height
            : "auto"}
        m="0px !important"
        borderRadius={isSelected ? "13px" : "10px"}
        isSelected={isSelected}
        boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)"
        onClick={onClick ? () => onClick(data) : () => {}}
      >
        <Div display="flex" flex={[1, 0]}>
          <Div display="contents">
            {!serviceProviderCard && (
              <MDImage
                width={(serviceCard || nameCard) ? "50px" : height || "80px"}
                height={(serviceCard || nameCard) ? "50px" : height || "80px"}
                borderRadius={isSelected ? "5px !important" : "10px !important"}
                src={userAvtar || FavIcon}
                bg={userAvtar ? "" : `${Colors.Primary}`}
                className={userAvtar ? "" : "p-2"}
                alt="icon"
              />
            )}
          </Div>
          {!profileCard && !serviceProviderCard && (
            <Div py="15px" px="20px" alignSelf="center" className="text-truncate" width="100%">
              <MDLabel fontSize="16px">
                <Span pr="15px" className="text-truncate" title={serviceLabel || name}>
                  {serviceLabel || name}
                </Span>
                {serviceCard && (
                  <MDImage
                    src={Constants.icons.QuestionMarkIcon}
                    alt="icon"
                    onMouseOver={showPopup(data)}
                    onMouseLeave={closePopup}
                    width={15}
                    height={15}
                  />
                )}
              </MDLabel>
              {info && (
                <P m={0} title={info} color={Colors.DarkGrey} fontSize={fontSizes.small} className="text-truncate">
                  {info}
                </P>
              )}
              {breed && (
                <MDLabel title={dogBreeds[breed] || breed} display="inline" fontSize={fontSizes.small} pt={1} m={0}>
                  {dogBreeds[breed] || breed}
                </MDLabel>
              )}
            </Div>
          )}
          {profileCard && (
            <>
              <Div p="15px" width="175px" display="grid">
                <P m={0} alignSelf="center">
                  <MDLabel fontSize="16px">{name}</MDLabel>
                </P>
                <P m={0} alignSelf="center">
                  <MDLabel>
                    <Span alignSelf="center">
                      <FormattedMessage id="common_assignments" />
                      :
                    </Span>
                    &nbsp;
                    <Label value={openAssignment || openAssignments || 0} />
                  </MDLabel>
                </P>
              </Div>
              <Div width="95px" bg={Colors.DarkWhite} borderRadius="0px 10px 10px 0px">
                {!isCalendar && (
                  <>
                    <Box height="50%" cursor="pointer">
                      <Link alignSelf="center" textDecoration="underline" onClick={() => handleMore(data)}>
                        <MDLabel alignSelf="center" textDecoration="underline" cursor="pointer">
                          <FormattedMessage id="common_more" />
                        </MDLabel>
                      </Link>
                    </Box>
                    <Box height="50%" cursor="pointer">
                      <MDLabel
                        alignSelf="center"
                        textDecoration="underline"
                        onClick={() => handleDelete(data)}
                        useCursorPointer
                        className={disableData ? "disabled-state" : ""}
                      >
                        <FormattedMessage id="common_delete" />
                      </MDLabel>
                    </Box>
                  </>
                )}
              </Div>
            </>
          )}
          {serviceProviderCard && (
            <Div py={["16px", "16px", "24px", "24px"]} pl={["16px", "16px", "24px", "24px"]} pr="15px" width={["220px", "220px", "366px", "366px"]}>
              {aboutUserDescription ? (
                <>
                  <MDLabel mb={2} pb={1}>
                    <FormattedMessage id="label_about_me" defaultMessage="About me" />
                  </MDLabel>
                  <P fontSize={fontSizes.small} mb={0}>{aboutUserDescription}</P>
                </>
              ) : (
                <P fontSize={fontSizes.small} mb={0}>
                  <FormattedMessage id="label_coming_soon_text" defaultMessage="Info about service provider coming soon" />
                </P>
              )}
            </Div>
          )}
        </Div>
      </CardWrapper>
    </Div>
  );
};

ProfileCard.propTypes = {
  /**
   * Renders the user image.
   */
  userAvtar: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * Is it a card representing a service?
   */
  serviceCard: PropTypes.bool,
  nameCard: PropTypes.bool,
  serviceProviderCard: PropTypes.bool,
  /**
   * Is it a card representing a profile?
   */
  profileCard: PropTypes.bool,
  /**
   * Data to shown on card
   */
  data: PropTypes.string,
  /**
   * Function to handle more button click event.
   */
  handleMore: PropTypes.func,
  /**
   * Function to handle delete button click event.
   */
  handleDelete: PropTypes.func,
  /**
   * Disable the card
   */
  disableData: PropTypes.bool,
  /**
   * Display for calendar
   */
  isCalendar: PropTypes.bool,
  /**
   * Width of the card
   */
  width: PropTypes.string,
  /**
   * min Width of the card
   */
  minWidth: PropTypes.string,
  /**
   * Height of the card
   */
  height: PropTypes.string,
  /**
   * Optional onclick handler
   */
  onClick: PropTypes.func,
  showCursor: PropTypes.bool,
  serviceProviderDetails: PropTypes.node,
};

ProfileCard.defaultProps = {
  userAvtar: null,
  serviceCard: false,
  profileCard: false,
  height: null,
  data: null,
  handleMore: undefined,
  handleDelete: undefined,
  onClick: undefined,
  disableData: false,
  isCalendar: false,
  nameCard: false,
  width: null,
  showCursor: false,
  serviceProviderCard: false,
  serviceProviderDetails: {},
};

export default ProfileCard;
