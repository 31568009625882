import React from "react";
import PropTypes from "prop-types";
import { Div } from "../../styles/Styles";
import Colors from "../../styles/Colors";

const Modal = ({ children }) => (
  <>
    <Div
      bg={Colors.ModalBg}
      className="modal overflow-auto"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
      display="flex"
      justifyContent="center"
      m="auto"
      pt={[40, 40, 200]}
      pb={[40, 50]}
      px={[4, 0]}
    >
      <Div className="modal-dialog modal-small" role="document">
        <Div className="modal-content rounded border-0" width="fit-content">
          <Div className="modal-body rounded p-0 m-0">
            {children}
          </Div>
        </Div>
      </Div>
    </Div>
  </>
);
Modal.propTypes = {
  /**
   * Pass children innto the modal
   */
  children: PropTypes.node,
};
Modal.defaultProps = {
  children: null,
};
export default Modal;
