import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  MDLabel, Div, FormWrapper, IsDesktop, IsMobile, MDImage, MDInputValue, Card
} from "../../../styles/Styles";
import Button from "../../../components/Button/Button";
import TextArea from "../../../components/Input/TextArea";
import Constants from "../../../shared/Constants";
import ProfileCard from "../../../components/Card/ProfileCard";
import Modal from "../../../components/Modal/Modal";
import Popup from "./Modals";
import WeekDays from "../../../shared/WeekDays";
import {
  getServiceProviders,
  getRooms,
  getClients,
  getServices,
  createAssignment,
  updateAssignment,
} from "../../../services/AssignmentService";
import { toJson } from "../../../models/Assignment";
import { OrderPageLoader } from "../../../components/Loader/FormLoader";
import { DateCard, ChangeButton } from "../Styles";
import { converTimeFrom24To12, formatDate } from "../../../shared/utils";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import usePermissions, { ModulePermissions } from "../../../shared/usePermissions";

const StyledCard = styled(Card)`
  height: 65px;
  padding: 12px;
  margin-right: 30px;

  span {
    justify-content: center;
  }
`;

const AssignmentForm = ({
  handleClose, assignmentData, edit, handleBackToAssignments, assignmentId, handleDelete, status,
  redirectToDetailPage, hasNoWriteAccess
}) => {
  const {
    icons, input: { name: inputs, format: { twoDigitDateFormat, dotted } }, themes: { widths, fontSizes, colors }, errorMessages,
  } = Constants;
  const [showModal, setShowModal] = useState("");
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState("");
  const [modalData, setModalData] = useState([]);
  const [data, setData] = useState({});
  const [organisations, setOrganisations] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [clients, setClients] = useState([]);
  const [dogs] = useState([]);
  const [services, setServices] = useState([]);
  const [isRoomAvailable, setIsRoomAvailable] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const openModal = useRef(null);
  const [getOrganizations] = usePermissions();

  const handleOpenModal = (name) => () => {
    setShowModal(name);
    setIsTouched(true);
    switch (name) {
    case inputs.place:
      return setModalData(organisations);
    case inputs.serviceProvider:
      return setModalData(serviceProviders);
    case inputs.room:
      return setModalData(rooms);
    case inputs.client:
      return setModalData(clients);
    case inputs.dog:
      return setModalData(dogs);
    case inputs.service:
      return setModalData(services);
    case inputs.schedule:
    { const prevData = [];
      const { schedule: { scheduleDays = [] } = {} } = data;
      scheduleDays.map((o) => prevData.push(o.value));
      const showData = WeekDays.map((obj) => {
        if (prevData.includes(obj.value)) {
          return { ...obj, isSelected: true };
        }
        return obj;
      });
      return setModalData(showData); }
    default:
      return "";
    }
  };

  const fetchOrganisations = () => {
    setLoader(true);
    setOrganisations(getOrganizations(ModulePermissions.Assignment, Constants.WRITE));
    setLoader(false);
  };

  const fetchClients = async () => {
    setLoader(true);
    try {
      const response = await getClients();
      let { data: customers = [] } = response;
      customers = customers.map((o) => {
        const {
          name = "", id: clientId = "", profile_pic: profilePic = {},
        } = o;
        const { url: profilePicUrl = "" } = profilePic || {};
        return ({ label: name, value: clientId, profilePic: profilePicUrl || "" });
      });
      setClients(customers);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchServiceProviders = async (id) => {
    setLoader(true);
    try {
      const response = await getServiceProviders(id);
      let { data: orgData = [] } = response;
      orgData = orgData.map((o) => ({ label: o.full_name, value: o.id, profilePic: o.profile_pic ? o.profile_pic.url : "" }));
      setServiceProviders(orgData);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchRooms = async (id) => {
    setLoader(true);
    try {
      const response = await getRooms(id);
      const { data: room = [] } = response;
      const orgData = room.map((o) => ({ label: o.name, value: o.id, profilePic: o.profile_pic ? o.profile_pic.url : "" }));
      setRooms(orgData);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchServices = async (room = "") => {
    setLoader(true);
    try {
      const response = await getServices(room);
      let {
        data: service = [],
      } = response;
      service = service.map((o) => {
        const {
          duration = {}, price = {}, name: label = "", id: value = "",
          profile_pic: profilePic = {},
          description: serviceDescription = "",
          is_full_day_service : isFullDayService = false,
        } = o;
        const { url = "" } = profilePic || {};
        const { small: smallDogDuration = "" } = duration || {};
        const { small: smallDogPrice = "" } = price || {};
        return ({
          label,
          value,
          profilePic: url,
          duration,
          description: serviceDescription,
          smallDogDuration,
          smallDogPrice,
          price,
          is_full_day_service : isFullDayService === 1,
        });
      });
      setServices(service);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const handleSelectedData = (name, value) => {
    const { value: id = "" } = value;
    setIsTouched(true);
    switch (name) {
    case inputs.place:
      if (data[name] !== value) {
        data.serviceProvider = "";
        data.room = "";
      }
      setIsRoomAvailable(false);
      fetchServiceProviders(id);
      fetchRooms(id);
      break;
    case inputs.client:
      if (data[name] !== value) {
        data.dog = "";
      }
      break;

    case inputs.serviceProvider:
      if (data[name] !== value) {
        data.service = "";
      }
      fetchServices(id);
      break;
    case inputs.room:
      setIsRoomAvailable(false);
      break;
    default:
      break;
    }
    data[name] = value;
    setData(data);
    setShowModal("");
  };

  const handleSubmit = () => {
    data.description = description;
    setLoader(true);
    if (edit) {
      updateAssignment(assignmentId, toJson(data)).then((response) => {
        if (response) {
          const { duplicate = {} } = data;
          if (duplicate && duplicate.date) {
            data.time = duplicate;
            createAssignment(toJson(data)).then((res) => {
              if (res) {
                setLoader(false);
                handleBackToAssignments(true);
              }
            }).catch((error) => {
              const { response: { data: errorResponse = {} } = {} } = error;
              const { message = "" } = errorResponse || {};
              if (message === errorMessages.roomNotAvailable) {
                setIsRoomAvailable(true);
                setLoader(false);
              } else {
                handleBackToAssignments(false);
              }
            });
          }
          handleBackToAssignments(true);
          setIsTouched(false);
          setLoader(false);
        }
      }).catch((error) => {
        const { response: { data: errorResponse = {} } = {} } = error;
        const { message = "" } = errorResponse || {};
        if (message === errorMessages.roomNotAvailable) {
          setIsRoomAvailable(true);
          setLoader(false);
        } else {
          handleBackToAssignments(false);
        }
      });
    } else {
      createAssignment(toJson(data)).then((response) => {
        if (response) {
          const { duplicate = {} } = data;
          if (duplicate && duplicate.date) {
            data.time = duplicate;
            createAssignment(toJson(data)).then((res) => {
              if (res) {
                setLoader(false);
                handleBackToAssignments(true);
              }
            }).catch((error) => {
              const { response: { data: errorResponse = {} } = {} } = error;
              const { message = "" } = errorResponse || {};
              if (message === errorMessages.roomNotAvailable) {
                setIsRoomAvailable(true);
                setLoader(false);
              } else {
                handleBackToAssignments(false);
              }
            });
          }
          setLoader(false);
          handleBackToAssignments(true);
        }
      }).catch((error) => {
        const { response: { data: errorResponse = {} } = {} } = error;
        const { message = "" } = errorResponse || {};
        if (message === errorMessages.roomNotAvailable) {
          setIsRoomAvailable(true);
          setLoader(false);
        } else {
          handleBackToAssignments(false);
        }
      });
    }
  };
  const handleCancel = () => {
    setIsRoomAvailable(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setDescription(value);
    setIsTouched(true);
  };

  useEffect(() => {
    fetchOrganisations();
    fetchClients();
    if (edit) {
      const { data: asgData = {} } = assignmentData;
      setData(asgData);
      setDescription(assignmentData.description || "");
      const {
        place: { value: placeValue = "" } = {}, serviceProvider: { value: serviceProviderValue = "" } = {},
      } = asgData;
      if (placeValue) {
        fetchRooms(placeValue);
        fetchServiceProviders(placeValue);
      }
      if (serviceProviderValue) {
        fetchServices(serviceProviderValue);
      }
    }
  }, []);
  const isDisabled = !(isTouched && data && data.place);
  return (
    <>
      {loader && <OrderPageLoader />}
      {!loader && (
        <FormWrapper width="870px">
          <Div className="row m-0 my-3">
            <Div className="col-lg-4 col-md-12" p={0}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="assignment_content_place" defaultMessage="Place" />
              </MDLabel>
              {data.place ? (
                <>
                  <ProfileCard
                    data={data.place}
                    width="255px"
                    height="64px"
                    userAvtar={data.place.profilePic || ""}
                    onClick={redirectToDetailPage(data?.place?.value, "place")}
                    showCursor
                  />
                  <MDLabel mt={3} className={ hasNoWriteAccess ? "disabled-state" : "" } onClick={handleOpenModal(inputs.place)} >
                    <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                    <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                      <FormattedMessage id="assignment_change_place" defaultMessage="Change place" />
                    </ChangeButton>
                  </MDLabel>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                  size="small"
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  onClick={handleOpenModal(inputs.place)}
                  disabled={hasNoWriteAccess}
                />
              )}
            </Div>
            <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="common_service_provider" defaultMessage="Service provider" />
              </MDLabel>
              {data.serviceProvider ? (
                <>
                  <ProfileCard
                    data={data.serviceProvider}
                    width="255px"
                    height="64px"
                    userAvtar={data.serviceProvider.profilePic || ""}
                    onClick={redirectToDetailPage(data?.serviceProvider?.value, "serviceProvider")}
                    showCursor
                  />
                  <MDLabel mt={3}
                    className={ hasNoWriteAccess ? "disabled-state" : "" }
                    onClick={handleOpenModal(inputs.serviceProvider)}>
                    <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                    <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                      <FormattedMessage id="assignment_change_service_provider" defaultMessage="Change service provider" />
                    </ChangeButton>
                  </MDLabel>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                  size="small"
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  disabled={!data.place || hasNoWriteAccess}
                  onClick={handleOpenModal(inputs.serviceProvider)}
                />
              )}
            </Div>
            <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="common_room" defaultMessage="Room" />
              </MDLabel>
              {data.room ? (
                <>
                  <ProfileCard
                    data={data.room}
                    width="255px"
                    height="64px"
                    userAvtar={data.room.profilePic || ""}
                    onClick={redirectToDetailPage(data?.room?.value, "room")}
                    showCursor
                  />
                  <MDLabel mt={3} onClick={handleOpenModal(inputs.room)} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                    <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                    <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                      <FormattedMessage id="assignment_change_room" defaultMessage="Change room" />
                    </ChangeButton>
                  </MDLabel>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="edit" />}
                  size="small"
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  disabled={!data.place || hasNoWriteAccess}
                  onClick={handleOpenModal(inputs.room)}
                />
              )}
              {isRoomAvailable && (
                <MDLabel my={3} fontSize={fontSizes.small} color={colors.error}>
                  <FormattedMessage id="room_is_occupied" defaultMessage="This room is occupied" />
                </MDLabel>
              )}
            </Div>
          </Div>
          <Div className="row m-0 my-3">
            <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="common_client" defaultMessage="Client" />
              </MDLabel>
              {data.client ? (
                <>
                  <ProfileCard
                    data={data.client}
                    width="255px"
                    height="64px"
                    userAvtar={data.client.profilePic || ""}
                    onClick={redirectToDetailPage(data?.client?.value, "client")}
                    showCursor
                  />
                  <MDLabel mt={3} onClick={handleOpenModal(inputs.client)} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                    <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                    <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                      <FormattedMessage id="assignment_change_client" defaultMessage="Change client" />
                    </ChangeButton>
                  </MDLabel>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                  size="small"
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  onClick={handleOpenModal(inputs.client)}
                  disabled={hasNoWriteAccess}
                />
              )}
            </Div>
            <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="common_dog" defaultMessage="Dog" />
              </MDLabel>
              {data.dog ? (
                <>
                  <Div onClick={handleOpenModal(inputs.dog)}>
                    <ProfileCard
                      data={data.dog ? data.dog : ""}
                      onClick={redirectToDetailPage(data?.client?.value, "dog")}
                      width="255px"
                      height="64px"
                      userAvtar={data.dog.profilePic || ""}
                      showCursor
                    />
                    <MDLabel mt={3} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                      <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                      <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                        <FormattedMessage id="assignment_chnage_dog" defaultMessage="Change dog" />
                      </ChangeButton>
                    </MDLabel>
                  </Div>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                  size="small"
                  disabled={!data.client || hasNoWriteAccess}
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  onClick={handleOpenModal(inputs.dog)}
                />
              )}
            </Div>
          </Div>
          <Div className="row m-0 my-3">
            <Div className="col-lg-4 col-md-12 " p={0}>
              <MDLabel mb={3} fontSize={fontSizes.medium}>
                <FormattedMessage id="common_service" defaultMessage="Service" />
              </MDLabel>
              {data.service ? (
                <>
                  <ProfileCard
                    data={data.service}
                    width="255px"
                    height="64px"
                    userAvtar={data.service.profilePic || ""}
                    onClick={redirectToDetailPage(data?.service?.value, "service")}
                    showCursor
                  />
                  <MDLabel mt={3} onClick={handleOpenModal(inputs.service)} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                    <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                    <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                      <FormattedMessage id="assignment_change_service" defaultMessage="Change service" />
                    </ChangeButton>
                  </MDLabel>
                </>
              ) : (
                <Button
                  label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                  size="small"
                  disabled={!(data.serviceProvider || data.room) || hasNoWriteAccess}
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  onClick={handleOpenModal(inputs.service)}
                />
              )}
            </Div>
            {data?.service?.is_full_day_service ? (
              <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
                <MDLabel mb={3} fontSize={fontSizes.medium}>
                  <FormattedMessage id="label_date" defaultMessage="Date" />
                </MDLabel>
                {data?.startEndDate ? (
                  <>
                    <Div display="flex">
                      <StyledCard mr={2}>
                        <MDLabel fontSize={fontSizes.xs} color={Colors.DarkGrey} mb={2}>Start date</MDLabel>
                        <MDLabel fontSize={fontSizes.regular} >{data?.startEndDate ? formatDate(data?.startEndDate?.date, dotted) : formatDate(data?.startEndDate?.date, dotted)}</MDLabel>
                      </StyledCard>
                      <StyledCard>
                        <MDLabel fontSize={fontSizes.xs} color={Colors.DarkGrey} mb={2}>End date</MDLabel>
                        <MDLabel fontSize={fontSizes.regular}>{data?.startEndDate ? formatDate(data?.startEndDate?.endDate, dotted) : formatDate(data?.startEndDate?.endDate, dotted)}</MDLabel>
                      </StyledCard>
                    </Div>
                    <MDLabel mt={3} onClick={handleOpenModal(inputs.startEndDate)} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                      <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                      <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                        <FormattedMessage id="edit" defaultMessage="Edit" />
                      </ChangeButton>
                    </MDLabel>
                  </>
                ) : (
                  <Button
                    label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                    size="small"
                    startIcon={icons.WhiteEditIcon}
                    padding="2px 8px"
                    disabled={!(data.service && data.serviceProvider && data.room) || hasNoWriteAccess}
                    onClick={handleOpenModal(inputs.startEndDate)}
                  />
                )}
              </Div>
            ) : (
              <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
                <MDLabel mb={3} fontSize={fontSizes.medium}>
                  <FormattedMessage id="assignment_time_date" defaultMessage="Date and time" />
                </MDLabel>
                {data.time ? (
                  <>
                    <DateCard date width={widths.w125}>
                      <MDLabel fontSize={fontSizes.regular}>{formatDate(data.time.date, twoDigitDateFormat)}</MDLabel>
                    </DateCard>
                    <DateCard width={widths.w125}>
                      <MDLabel fontSize={fontSizes.regular}>
                        {data.time.from ? converTimeFrom24To12(data.time.from) : ""}
                      </MDLabel>
                    </DateCard>
                    <MDLabel mt={3} onClick={handleOpenModal(inputs.time)} className={ hasNoWriteAccess ? "disabled-state" : "" }>
                      <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
                      <ChangeButton className={ hasNoWriteAccess ? "events-none" : "" }>
                        <FormattedMessage id="assignment_edit_time_date" defaultMessage="Date and time" />
                      </ChangeButton>
                    </MDLabel>
                  </>
                ) : (
                  <Button
                    label={<FormattedMessage id="common_edit" defaultMessage="Edit" />}
                    size="small"
                    startIcon={icons.WhiteEditIcon}
                    padding="2px 8px"
                    disabled={!(data.service && data.serviceProvider && data.room) || hasNoWriteAccess}
                    onClick={handleOpenModal(inputs.time)}
                  />
                )}
              </Div>
            )}
          </Div>
          <Div className="my-3 pb-3" maxWidth="730px">
            <MDLabel mb={3} fontSize={fontSizes.medium}>
              <FormattedMessage id="label_additional_information" defaultMessage="Additional information" />
            </MDLabel>
            <TextArea
              value={description}
              name={inputs.description}
              onChange={handleChange}
            />
          </Div>
          <MDLabel mb={3} fontSize={fontSizes.medium}>
            <FormattedMessage id="assignment_duplicate_schedule" defaultMessage="Duplicate" />
          </MDLabel>
          {data.duplicate && (
            <Div my={3}>
              <DateCard date width={widths.w125}>
                <MDLabel fontSize={fontSizes.regular}>{formatDate(data.duplicate.date, twoDigitDateFormat)}</MDLabel>
              </DateCard>
              <DateCard width={widths.w125}>
                <MDLabel fontSize={fontSizes.regular}>
                  {data.duplicate.from ? converTimeFrom24To12(data.duplicate.from) : ""}
                </MDLabel>
              </DateCard>
            </Div>
          )}
          <Button
            label={<FormattedMessage id={data.duplicate ? "assignment_edit_duplicate" : "button_duplicate"} />}
            size="small"
            startIcon={icons.WhiteEditIcon}
            padding="2px 8px"
            className="mr-3"
            disabled={!(data.service && data.serviceProvider && data.room) || hasNoWriteAccess}
            onClick={handleOpenModal(inputs.duplicate)}
          />
          {(!data.schedule || data.schedule.length === 0) ? (
            <Button
              label={<FormattedMessage id="button_schedule" defaultMessage="Schedule" />}
              size="small"
              startIcon={icons.WhiteEditIcon}
              padding="2px 8px"
              onClick={handleOpenModal(inputs.schedule)}
              disabled={hasNoWriteAccess}
            />
          )
            : (
              <Div>
                <MDLabel py={3} fontSize={fontSizes.regular}>
                  <FormattedMessage id="assignment_schedule_description" defaultMessage="This assignment repeats on following days" />
                </MDLabel>
                {data.schedule && data.schedule.scheduleDate && (
                  <>
                    <MDLabel pb={2}>
                      <FormattedMessage id="label_schedule_end_date" defaultMessage="Scheduled end date" />
                    :
                    </MDLabel>
                    <MDLabel pb={3}>
                      {formatDate(data.schedule.scheduleDate, twoDigitDateFormat)}
                    </MDLabel>
                  </>
                )}
                {data.schedule && data.schedule.scheduleDays.map((o) => {
                  const { value = "", label = "" } = o;
                  if (value !== inputs.everyday) {
                    return <MDInputValue pb={2}>{label}</MDInputValue>;
                  }
                  return "";
                })}
                <Button
                  label={<FormattedMessage id="button_edit_schedule" defaultMessage="Edit schedule" />}
                  size="small"
                  startIcon={icons.WhiteEditIcon}
                  padding="2px 8px"
                  onClick={handleOpenModal(inputs.schedule)}
                  disabled={hasNoWriteAccess}
                />
              </Div>
            )}
          <IsDesktop className="d-lg-flex mt-5">
            {edit && (
              <Button
                primary
                onClick={handleClose}
                startIcon={icons.AssignmentsMenuIcon}
                IconHeight={fontSizes.medium}
                label={<FormattedMessage id="common_close" defaultMessage="Close" />}
                width={widths.w200}
                className="mr-3"
                disabled={status === Constants.status.close || hasNoWriteAccess}
                type="submit"
              />
            )}
            <Button
              secondary={edit}
              onClick={handleSubmit}
              startIcon={edit ? icons.PrimaryTickIcon : icons.AssignmentsMenuIcon}
              IconHeight={fontSizes.medium}
              label={<FormattedMessage id="common_save" defaultMessage="Save" />}
              width={widths.w200}
              className="mr-3"
              type="submit"
              disabled={isDisabled || hasNoWriteAccess}
            />
            {edit
              ? (
                <Button
                  secondary
                  startIcon={icons.PrimaryDeleteIcon}
                  onClick={handleDelete}
                  label={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
                  width={widths.w200}
                  className="mx-3"
                  disabled={hasNoWriteAccess}
                />
              )
              : (
                <Button
                  secondary
                  onClick={handleCancel}
                  label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                  width={widths.w200}
                  className="mx-3"
                  disabled={hasNoWriteAccess}
                />
              )}
          </IsDesktop>
          <IsMobile className="mt-5">
            {edit && (
              <Button
                primary
                onClick={handleClose}
                startIcon={icons.AssignmentsMenuIcon}
                IconHeight={fontSizes.medium}
                label={<FormattedMessage id="common_close" defaultMessage="Close" />}
                width={widths.full}
                disabled={status === Constants.status.close || hasNoWriteAccess}
                className="mb-3"
              />
            )}
            <Button
              secondary={edit}
              onClick={handleSubmit}
              startIcon={edit ? icons.PrimaryTickIcon : icons.AssignmentsMenuIcon}
              IconHeight={fontSizes.medium}
              label={<FormattedMessage id="common_save" defaultMessage="Save" />}
              width={widths.full}
              className="mb-3"
              disabled={isDisabled || hasNoWriteAccess}
            />
            {edit
              ? (
                <Button
                  secondary
                  startIcon={icons.PrimaryDeleteIcon}
                  onClick={handleDelete}
                  label={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
                  width={widths.full}
                  className="mb-3"
                  disabled={hasNoWriteAccess}
                />
              )
              : (
                <Button
                  secondary
                  onClick={handleCancel}
                  label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                  width={widths.full}
                  className="mb-4 mt-1"
                  disabled={hasNoWriteAccess}
                />
              )}
          </IsMobile>
        </FormWrapper>
      )}
      <a
        href
        data-toggle="modal"
        data-target="#Modal"
        data-backdrop="static"
        data-keyboard="false"
        ref={openModal}
      />
      {showModal && (
        <Modal>
          <Popup
            data={modalData}
            values={data}
            handleSubmit={handleSelectedData}
            name={showModal}
            onCancel={() => {
              setShowModal("");
            }}
          />
        </Modal>
      )}
    </>
  );
};

AssignmentForm.propTypes = {
  assignmentData: PropTypes.node,
  edit: PropTypes.bool,
  handleBackToAssignments: PropTypes.func,
  assignmentId: PropTypes.string,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
  status: PropTypes.string,
  redirectToDetailPage: PropTypes.func,
  hasNoWriteAccess: PropTypes.bool,
};
AssignmentForm.defaultProps = {
  assignmentData: {},
  edit: false,
  handleBackToAssignments: () => {},
  assignmentId: "",
  handleDelete: () => {},
  handleClose: () => {},
  status: "",
  redirectToDetailPage: () => {},
  hasNoWriteAccess: false,
};

export default AssignmentForm;
