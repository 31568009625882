import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import OrganizationService from "../../../services/OrganizationService";
import ProfileCard from "../../../components/Card/ProfileCard";
import Config from "../../../config";
import Constants from "../../../shared/Constants";
import Modal from "../../../components/Modal/Modal";
import Card from "../../../components/Card/Card";
import UserService from "../../../services/UserService";
import CardLoader from "../../../components/Loader/CardLoader";

class ServiceProvider extends Component {
    organzationService = new OrganizationService(Config.apiBase);

    userService = new UserService(Config.apiBase);

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        serviceProvider: [],
        isDeleteModalOpen: false,
        deleteAccID: "",
        isUserDeleted: false,
      };
    }

    componentDidMount=() => {
      this.fetchOrganizationServiceProvider();
    }

    handleServiceProviderData = (serviceProvider) => ({
      name: serviceProvider.full_name,
      id: serviceProvider.id,
    });

    handleMoreClick = (serviceProvider) => {
      const { history } = this.props;
      history.push(`${Constants.routes.usersList.url}/${serviceProvider.id}/edit-user`);
    };

    /**
     * Opens a delete confirmation popup
     * @param {object} item selected user data
     */
    handleDeleteModal =(item) => {
      const { id = "" } = item;
      this.setState({ isDeleteModalOpen: true, deleteAccID: id }, () => {
        this.openElement.click();
      });
    };

    /**
     *
     * @async
     * @function handleDeleteUser
     * Deletes the selected user.
     * @returns user deletion
     *
     */
    handleDeleteUser =async () => {
      this.setState({ loading: true });
      const { deleteAccID } = this.state;
      try {
        const response = await this.userService.deleteUser(deleteAccID);
        if (response) {
          this.setState({
            isDeleteModalOpen: false, isUserDeleted: true, deleteAccID: "", loading: false,
          },
          this.fetchOrganizationServiceProvider);
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    }

    /**
     * Cancels the user deletion operation
     */
    handleCancel = () => {
      this.setState({ isUserDeleted: false, isDeleteModalOpen: false });
    }

    fetchOrganizationServiceProvider = async () => {
      const { organizationId: ID } = this.props;
      this.setState({ loading: true });
      try {
        const response = await this.organzationService.getOrganisationsServiceProvider(ID);
        const { data = [] } = response;

        if (response) {
          this.setState({ serviceProvider: data });
          this.setState({ loading: false });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    };

    render() {
      const {
        serviceProvider, loading, isDeleteModalOpen, isUserDeleted,
      } = this.state;

      const { disableData } = this.props;

      return (
        <>
          {loading && <CardLoader />}
          {!loading && serviceProvider.map((obj) => {
            const { profile_pic: profilePic = {} } = obj;
            const { url = "" } = profilePic || {};
            return (
              <div className="pr-3 py-2 my-1 d-inline-flex">
                <ProfileCard
                  userAvtar={url || ""}
                  profileCard
                  data={this.handleServiceProviderData(obj)}
                  handleMore={this.handleMoreClick}
                  handleDelete={this.handleDeleteModal}
                  disableData={disableData}
                />
              </div>
            );
          })}
          <a
            href
            data-toggle="modal"
            data-target="#Modal"
            data-backdrop="static"
            data-keyboard="false"
            ref={(openModal) => { this.openElement = openModal; }}
          />
          {isDeleteModalOpen && (
            <Modal>
              <Card
                header={<FormattedMessage id="user_delete_user" />}
                message={<FormattedMessage id="user_deleted_confirmation_message" />}
                buttonLabel={<FormattedMessage id="common_delete" />}
                confirmationCard
                buttonIcon={Constants.icons.DeleteIcon}
                onClick={this.handleDeleteUser}
                onCancel={this.handleCancel}
              />
            </Modal>
          )}
          {isUserDeleted && (
            <Modal>
              <Card header={<FormattedMessage id="common_done" />} message={<FormattedMessage id="user_deleted_alert_message" />} alertCard buttonLabel={<FormattedMessage id="common_ok" />} onClick={this.handleCancel} />
            </Modal>
          )}
        </>
      );
    }
}
ServiceProvider.propTypes = {
  organizationId: PropTypes.string,
  history: PropTypes.node,
  disableData: PropTypes.bool,
};
ServiceProvider.defaultProps = {
  organizationId: "",
  history: undefined,
  disableData: false,
};

export default ServiceProvider;
