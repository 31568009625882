import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { CardWrapper } from "../../Services/Styles";
import { CalendarWrapper, DateCard } from "../Styles";
import { DropDownListItem } from "../../../components/Input/Styles";
import {
  CardHeader, MDLabel, Div, P, Card, MDImage, MDInputValue,
} from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import ProfileCard from "../../../components/Card/ProfileCard";
import CheckBox from "../../../components/Input/CheckBox";
import TextBox from "../../../components/Input/TextBox";
import { getDogs } from "../../../services/AssignmentService";
import WhiteClockIcon from "../../../assets/Icons/WhiteClockIcon.svg";
import {
  getServiceProviders, getRooms, getServices, getTimeAndDate,
} from "../../../services/OrdersService";
import CardLoader from "../../../components/Loader/CardLoader";
import AddDog from "../../Customers/Components/CreateDogForm";
import {
  formatDate, getStartAndEndDateOfaMonth,
  converTimeFrom12To24, timelineLabels,
} from "../../../shared/utils";
import DatePicker from "../../../components/Input/CustomDatePicker";
import { Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";

const ToolTip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    background-color: #ffffff;
    color: #000000;
    &::after {
      border-top-color: #ffffff !important;
    }
  }
`;

const TileDiv = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: inherit;
`;

const Modals = ({
  data, onCancel, name, handleSubmit, values, order, id, dogSize,
}) => {
  const {
    assignmentHeaderTranslations: assignmentHeader,
    orderHeaderTranslations: orderHeader,
    icons, input: { name: inputs, format: { yearToDay } },
    translations: SubHeader, themes: { widths, heights, fontSizes },
    language,
  } = Constants;
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [calendarValue, setCalendarValue] = useState(new Date());
  const smallSize = (name === inputs.client || name === inputs.schedule);
  const mediumModal = [inputs.time, inputs.duplicate, inputs.place, inputs.dog, inputs.description];
  const mediumSize = (mediumModal.includes(name));
  const [showAddDogModal, setShowAddDogModal] = useState(false);
  const [availableMonth, setAvailableMonth] = useState(getStartAndEndDateOfaMonth(new Date()));
  const [schedulerEndDate, setSchedulerEndDate] = useState("");
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [activeTransparent, setActiveTransparent] = useState(false);
  const [activeStartDate, setActiveStartDate] = useState(new Date());

  const handleSave = () => {
    let saveData = { ...selectedData, isSelected: false };
    if (name === inputs.description) {
      saveData = selectedData.map((o) => ({ ...o, isSelected: false }));
    }
    if (name === inputs.schedule) {
      saveData.scheduleDate = schedulerEndDate;
      saveData.scheduleDays = cardData.filter((o) => o.isSelected);
    }
    if ((name === "time" || name === "duplicate") && calendarValue) {
      const { time = {} } = values;
      saveData.date = formatDate(calendarValue, yearToDay);
      saveData.from = saveData.from ? converTimeFrom12To24(saveData.from) : time.from;
      saveData.to = saveData.to ? converTimeFrom12To24(saveData.to) : time.to;
    }
    if ((name === inputs.startEndDate) && calendarValue) {
      saveData.date = formatDate(calendarValue[0], yearToDay);
      saveData.endDate = formatDate(calendarValue[1] || calendarValue[0], yearToDay);
      saveData.slotId = availableDates.find((o)=> o.date === formatDate(calendarValue[0], yearToDay))?.slotId;
    }
    handleSubmit(name, saveData);
  };
  const handleChange = (e) => {
    const { value } = e.target;
    let filteredData = [];
    setSearchQuery(value);
    filteredData = data;
    if (value) {
      filteredData = filteredData.filter((obj) => {
        const result = obj.label.toLowerCase().includes(value.toLowerCase());
        return result;
      });
    }
    setCardData(filteredData);
  };

  const handleAdditionalOptions = (value) => {
    const objData = cardData.map((o) => {
      if (o.value === value.value) {
        return { ...o, isSelected: !o.isSelected };
      }
      return o;
    });
    const filterSelectData = objData.filter((o) => o.isSelected === true);
    setSelectedData(filterSelectData);
    setCardData(objData);
  };

  const handleSelectData = (value) => {
    setSelectedData(value);
    let selectData = cardData;
    if (name === inputs.client) {
      selectData = data;
      setSearchQuery(value.label);
    }
    const objData = selectData.map((o) => {
      if (o.value === value.value) {
        return { ...o, isSelected: true };
      }
      return { ...o, isSelected: false };
    });
    setCardData(objData);
  };

  const handleSelectedTime = (value, index) => () => {
    if (value && !value.isDisabled) {
      setSelectedData(value);
      const objData = timeData.map((o, innerIndex) => {
        if (innerIndex === index) {
          const time = o.time.split(" - ");
          o.from = time[0];
          o.to = time[1];
          return { ...o, isSelected: true };
        }
        return { ...o, isSelected: false };
      });
      setTimeData(objData);
    }
  };

  const handleCheckBoxValues = (value) => () => {
    let objData = [];
    if (value.value === "everyday") {
      objData = cardData.map((o) => {
        if (value.isSelected) {
          return ({ ...o, isSelected: false });
        }
        return ({ ...o, isSelected: true });
      });
    } else {
      objData = cardData.map((o) => {
        if (o.value === value.value) {
          return { ...o, isSelected: !o.isSelected };
        }
        return { ...o, isSelected: o.isSelected || false };
      });
    }
    const falseData = objData.filter((o) => (o.isSelected === false && o.value !== "everyday"));
    objData = objData.map((o) => {
      if (o.value === "everyday") {
        return { ...o, isSelected: (falseData.length <= 0) };
      }
      return o;
    });
    setCardData(objData);
  };

  const fetchServiceProviders = async () => {
    const { place = {} } = values;
    setIsLoading(true);
    try {
      const response = await getServiceProviders(id, place, dogSize);
      let { data: serviceProviders = [] } = response;
      serviceProviders = serviceProviders.map((o) => {
        const obj = { label: o.full_name, value: o.id, profilePic: o.profile_pic ? o.profile_pic.url : "" };
        if (o.id === values[name] && values[name].value) {
          obj.isSelected = true;
        }
        return obj;
      });
      setCardData(serviceProviders);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchRooms = async () => {
    const { place = {} } = values;
    setIsLoading(true);
    try {
      const response = await getRooms(id, place);
      const { data: rooms = [] } = response;
      const roomData = rooms.map((o) => {
        const obj = { label: o.name, value: o.id, profilePic: o.profile_pic ? o.profile_pic.url : "" };
        if (o.id === values[name] && values[name].value) {
          obj.isSelected = true;
        }
        return obj;
      });
      setCardData(roomData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchServices = async () => {
    setIsLoading(true);
    try {
      const response = await getServices(id);
      const serviceData = response.map((o) => {
        const {
          duration: serviceDuration = {}, price = {}, name: label = "", id: value = "",
          profile_pic: profilePic = {}, add_ons: additionalOptions = [],
          description = "",
          is_full_day_service : isFullDayService = false,
        } = o;
        const { url = "" } = profilePic || {};
        const { small: smallDogDuration = "" } = serviceDuration || {};
        const { small: smallDogPrice = "" } = price || {};
        const obj = {
          label,
          value,
          profilePic: url,
          serviceDuration,
          price,
          additionalOptions,
          description,
          smallDogDuration,
          smallDogPrice,
          is_full_day_service : isFullDayService ? true: false,
        };
        if (o.id === values[name] && values[name].value) {
          obj.isSelected = true;
        }
        return obj;
      });
      setCardData(serviceData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchDogs = async () => {
    const { client: { value: clientID = "" } } = values;
    setIsLoading(true);
    try {
      const response = await getDogs(id || clientID);
      let { data: orgData = [] } = response;
      orgData = orgData.map((o) => {
        const obj = {
          label: o.name, value: o.id, profilePic: o.profile_pic ? o.profile_pic.url : "", dog_size_weight: o.dog_size_weight, breed: o.breed || "",
        };
        if (o.id === values[name] && values[name].value) {
          obj.isSelected = true;
        }
        return obj;
      });
      setCardData(orgData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchAdditionalOptions = async () => {
    const { service: { additionalOptions = [] }, description = [] } = values;
    const selectedAddons = description && description.map((o) => o.value);
    setIsLoading(true);
    const addOns = additionalOptions.map((o) => {
      const obj = {
        label: o.name, value: o.id, duration: o.duration, price: o.price,
      };
      if (selectedAddons && selectedAddons.includes(o.id)) {
        obj.isSelected = true;
      }
      return obj;
    });
    setCardData(addOns);
    setIsLoading(false);
  };


  useEffect(() => {
    const arraySlot = [];
    if (timeIntervals.length > 0) {
      const { time: { from = "", to = "" } = {} } = values;
      timeIntervals.forEach((el) => {
        const startTimeslot = el?.split(" - ")[0];
        const slotStartTime = startTimeslot?.split(":")[0] * 60 + startTimeslot?.split(":")[1];
        const endTimeslot = el?.split(" - ")[1];
        const slotEndTIme = endTimeslot?.split(":")[0] * 60 + endTimeslot?.split(":")[1];
        cardData?.service_provider?.length > 0
        && cardData?.service_provider?.forEach((sp) => {
          sp?.available_slots?.length > 0
            && sp?.available_slots?.every((slots) => {
              const startTimeAvailable = slots.start_time.split(":")[0] * 60 + slots.start_time.split(":")[1];
              const endTimeAvailable = slots.end_time.split(":")[0] * 60 + slots.end_time.split(":")[1];
              if (+startTimeAvailable <= +slotStartTime && +endTimeAvailable >= +slotEndTIme) {
                const slotTime = el.split(" - ");
                const selectedTime = (from && from === slotTime[0]) || (to && to === slotTime[1]);
                arraySlot.push({time: el, isDisabled: false, isSelected: selectedTime, slotId: slots.slot_id});
                return false;
              }
              return true;
            });
        });
      });
      setTimeData(arraySlot);
    } else {
      setTimeData([]);
    }
  }, [timeIntervals]);

  const fetchAvailableDates = (activeDate = moment(new Date()).format("YYYY-MM-DD")) => {
    setIsLoading(true);
    const firstDay = moment(activeDate, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD");
    const lastDay = moment(activeDate, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD");
    const {
      place: { value: placeId = "" } = {},
      service: { value: serviceId = "", is_full_day_service: isFullDayService = false },
      serviceProvider: { value: spId = "" } = {},
    } = values;
    const payload = [
      ["service_provider_id", spId],
      ["date", firstDay],
      ["end_date", lastDay],
      ["service_id", serviceId],
      ["serviceable_dog_size", dog_size_weight],
      ["is_full_day_service", isFullDayService],
    ];
    getTimeAndDate(placeId, payload).then(
      (response) => {
        const { service_provider: serviceProviders = [] } = response;
        const selectedServiceProvider = serviceProviders.find((o) => o.id === spId) || {};
        const { available_slots: availableSlots = [] } = selectedServiceProvider;
        const availableDates = availableSlots.map((o) => ({ date: o?.date, slotId: o?.slot_id }));
        let filterDates = availableDates.filter(
          (obj) => moment(obj.date).toDate() > moment(new Date()).subtract(1, "days")
        ) || [];
        let [firstDate] = filterDates.map((o) => o?.date).sort();

        if (firstDate) {
          setCalendarValue(moment(firstDate).toDate());
        } else {
          setCalendarValue(null);
        }
        setActiveStartDate(moment(activeDate).toDate())
        setSelectedStartDate(null);
        setAvailableDates(filterDates);
        setIsLoading(false);
      }
    ).catch(()=>{
      setIsLoading(false);
    });
  };

  const onActiveStartDateChange = ({ activeStartDate }) => {
    fetchAvailableDates(moment(activeStartDate).format("YYYY-MM-DD"));
  };

  function tileDisabled({ date }) {
    const formattedDate = formatDate(date);
    const {
      service: { is_full_day_service: isFullDayService = false },
    } = values;

    if (isFullDayService) {
      // Here we are trying to group the continous dates
      const groupedDates = availableDates
        .map((obj) => obj?.date)
        .reduce(
          (acc, date) => {
            const group = acc[acc.length - 1];
            if (moment(date).diff(moment(group[group.length - 1] || date), "days") > 1) {
              acc.push([date]);
            } else {
              group.push(date);
            }
            return acc;
          },
          [[]]
        )
        .find((obj) => obj.includes(formatDate(selectedStartDate)));

      if (groupedDates) {
        return !groupedDates.flat().find((obj) => obj === formattedDate);
      }
    }
    return !availableDates.find((obj) => obj?.date === formattedDate);
  }

  function tileContent({ date }) {
    const formattedDate = formatDate(date);
    const isDisabled = !availableDates.find((obj) => obj?.date === formattedDate);
    return isDisabled ? (
      <>
        <TileDiv data-tip={Constants.language.services_provider_not_found} data-for="toolTip1" data-place="top">
          {moment(date).format("D")}
        </TileDiv>
        <ToolTip id="toolTip1" />
      </>
    ) : (
      <TileDiv>{moment(date).format("D")}</TileDiv>
    );
  }

  const fetchTimeAndDate = (month = {}) => {
    const { startDate: start = "", endDate: end = "" } = month;
    setIsLoading(true);
    let { startDate, endDate } = availableMonth;
    startDate = (start || startDate);
    endDate = (end || endDate);
    startDate = startDate.format(Constants.input.format.date);
    endDate = new moment(endDate).endOf("month").format(Constants.input.format.date);
    const {
      place: { value: placeId = "" } = {},
      service: { value: serviceId = "", serviceDuration: serviceTime = {}, duration: sizeDuration = {}, is_full_day_service : isFullDayService= false },
      dog: { dog_size_weight: selectedDogWeight = "" },
      serviceProvider: { value: spId = "" } = {},
    } = values;
    const selectedDogSizeIs = serviceTime[selectedDogWeight] ?? sizeDuration[selectedDogWeight];
    const payload = [["service_provider_id", spId], ["date", startDate], ["end_date", endDate], ["service_id", serviceId], ["serviceable_dog_size", dog_size_weight], ["is_full_day_service", isFullDayService ? true : false]];
    getTimeAndDate(placeId, payload).then((response) => {
      setCardData(response);
      const { start_time = "", end_time = "" } = response;
      setTimeIntervals(timelineLabels(
        Number(selectedDogSizeIs ?? 0),
        "m",
        start_time,
        end_time, startDate, false));
      setIsLoading(false);
    }, () => {
      setIsLoading(false);
    });
  };

  async function handleOnChangeRangeCalendar (e) {
    setActiveTransparent(true);
    setCalendarValue(e);
    const {
      service: { is_full_day_service : isFullDayService = false },
    } = values;
    if(isFullDayService && typeof e[1] === "undefined") {
      setSelectedStartDate(new Date(e[0]));
    } else {
      setSelectedStartDate(null);
    }
  }



  const handleOnChangeCalendar = useCallback(async(e) => {
    setCalendarValue(e);
    setTimeData(timeData.map((o) => ({ ...o, isDisabled: false, isSelected: false })));
    const selectedDate = Date.parse(e);
    const newavailableMonth = getStartAndEndDateOfaMonth(new Date(e));
    let { startDate = "", endDate = "" } = availableMonth;
    endDate = Date.parse(endDate);
    startDate = Date.parse(startDate);
    if (selectedDate < startDate || selectedDate > endDate) {
      setAvailableMonth(newavailableMonth);
      fetchTimeAndDate(newavailableMonth);
    }
  });

  useEffect(() => {
    if (name === inputs.time) {
      fetchTimeAndDate();
    }
  }, [calendarValue]);

  useEffect(() => {
    if (name === inputs.startEndDate) {
      fetchAvailableDates();
    }
  }, []);

  useEffect(() => {
    let showData = [];
    if (name === inputs.time || name === inputs.duplicate) {
      let selectedDate = "";
      if (name === inputs.time) {
        const { time: { date = "" } = {} } = values;
        selectedDate = date;
      }
      if (name === inputs.duplicate) {
        const { duplicate: { date = "" } = {} } = values;
        selectedDate = date;
      }
      if (selectedDate) {
        selectedDate = moment(selectedDate).format(Constants.input.format.date);
        selectedDate = new Date(selectedDate);
        setCalendarValue(selectedDate);
      }
    }
    if (name === inputs.schedule) {
      const { schedule: { scheduleDate = "" } = {} } = values;
      setSchedulerEndDate(scheduleDate || "");
      setCardData(data);
    }
    if (order && (name !== "client" && name !== "place")) {
      switch (name) {
      case inputs.serviceProvider:
        fetchServiceProviders();
        break;
      case inputs.room:
        fetchRooms();
        break;
      case inputs.dog:
        fetchDogs();
        break;
      case inputs.service:
        fetchServices();
        break;
      case inputs.description:
        fetchAdditionalOptions();
        break;
      default:
        return "";
      }
    } else if (name === inputs.dog) {
      fetchDogs();
    } else {
      const prevData = values[name];
      showData = data.map((obj) => {
        if (prevData && (obj.value === prevData.value)) {
          return { ...obj, isSelected: true };
        }
        return obj;
      });
      setCardData(showData);
    }
    return {};
  }, [data]);

  const { service: { serviceDuration = {}, duration = {} } = {}, dog: { dog_size_weight = "" } = {}, client: { value: clientId = "" } = {} } = values;

  const handleAddDog = () => {
    setShowAddDogModal(true);
  };

  return (
    <CardWrapper width={[1, 1, showAddDogModal ? 780 : smallSize ? 400 : mediumSize ? 650 : 887]}>
      <CardHeader className="position-sticky">
        <MDLabel alignSelf="center" fontSize="24px">
          {showAddDogModal ? <FormattedMessage id="header_not_registered_dog" defaultMessage="Not registered dog" /> : order ? orderHeader[name] : assignmentHeader[name]}
        </MDLabel>
      </CardHeader>
      {isLoading && <Div p={4}><CardLoader /></Div>}
      {!isLoading && (
        <Div>
          {name === inputs.schedule && (
            <Div p={3} pb={0}>
              <MDLabel fontSize={fontSizes.regular}>
                <FormattedMessage id="label_select_end_date" defaultMessage="Select end Date" />
            :
              </MDLabel>
              <DatePicker
                onChange={(date, value) => { setSchedulerEndDate(value); }}
                name="date"
                value={schedulerEndDate}
                label="End date"
                placeholder={language.placeholder_from}
              />
            </Div>
          )}
          {(!showAddDogModal && name !== inputs.schedule) && (
            <MDLabel p={3} justifyContent="Center" fontSize="18px">
              {SubHeader[name]}
            </MDLabel>
          )}
          {(name === "time" || name === "duplicate") && (
            <Div p={3} maxHeight="410px" className=" row m-0 overflow-auto">
              <CalendarWrapper className="col-auto">
                <Calendar
                  onChange={handleOnChangeCalendar}
                  value={calendarValue}
                  next2Label={null}
                  prev2Label={null}
                />
              </CalendarWrapper>
              <Div className="col overflow-auto" maxHeight="377px">
                <MDLabel p={3} pb={2} fontSize="18px">
                  <FormattedMessage id="header_choose_time" />
                </MDLabel>
                {timeData?.length ? timeData?.map((obj, index) => (
                  <Div
                    display="inline-flex"
                    p={2}
                    className={obj.isDisabled ? "disabled-state" : ""}
                    onClick={handleSelectedTime(obj, index)}
                    key={index}
                  >
                    <Div className={obj.isSelected ? "is_selected" : ""}>
                      <DateCard date height="24px" boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)">
                        <MDLabel fontSize="12px">
                          <MDImage src={WhiteClockIcon} alt="icon" height="14px" alignSelf="center" mr={1} />
                          {serviceDuration[dog_size_weight] ?? duration[dog_size_weight]}
                          {" "}
                        min
                        </MDLabel>
                      </DateCard>
                      <DateCard height="60px" boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)" display="grid">
                        <Div alignSelf="center"><MDLabel fontSize="16px">{obj.time.split(" - ")[0]}</MDLabel></Div>
                        <Div alignSelf="center"><MDLabel fontSize="16px">{obj.time.split(" - ")[1]}</MDLabel></Div>
                      </DateCard>
                    </Div>
                  </Div>
                )) :
                  <MDLabel p={3} pb={2} fontSize="16px">
                    <FormattedMessage id="no_data_found" defaultMessage="No data found" />
                  </MDLabel>
                }
              </Div>
            </Div>
          )}

          {(name === "startEndDate") && (
            <Div p={3} maxHeight="410px" className=" row m-0 overflow-auto">
              <CalendarWrapper className="col-auto">
                <Division className="modifyCalendar">
                  <Calendar
                    className={activeTransparent ? "transparent" : ""}
                    onChange={handleOnChangeRangeCalendar}
                    value={calendarValue}
                    tileContent={tileContent}
                    tileDisabled={tileDisabled}
                    activeStartDate={activeStartDate}
                    calendarValue="calendarValue"
                    selectRange
                    onActiveStartDateChange={onActiveStartDateChange}
                    allowPartialRange={true}
                  />
                </Division>
              </CalendarWrapper>
            </Div>
          )}

          {!showAddDogModal && name !== "startEndDate" && name !== inputs.time && (!cardData.length
            ? (
              <Div p={3} display="flex" justifyContent="center">
                <FormattedMessage id="no_data_found" />
              </Div>
            )
            : (
              <Div maxHeight="410px" className="overflow-auto" display="contents">
                <Div p={3}>
                  <P m={0}>
                    <Div>
                      {name === inputs.client && (
                        <TextBox
                          value={searchQuery}
                          placeholder="Search client"
                          onChange={handleChange}
                          width="100%"
                          startIcon={icons.SearchIcon}
                          containerProps="col p-0"
                        />
                      )}
                      {name === inputs.schedule && (
                        <>
                          <MDLabel fontSize="18px" lineHeight="24px">
                            <FormattedMessage id="modal_schedule_description" />
                          :
                          </MDLabel>
                        </>
                      )}
                      <Div maxHeight={400} className="overflow-auto">
                        {cardData.map((obj) => (
                          <Div display={smallSize ? "block" : "inline-flex"} className="col-lg-auto col-md-12 p-0">
                            <Div className={`${smallSize ? "m-0" : "m-2 "}'d-flex w-100'`}>
                              {name === inputs.schedule ? (
                                <Div py={2}>
                                  <CheckBox
                                    isSelected={obj.isSelected}
                                    onClick={handleCheckBoxValues(obj)}
                                    label={obj.label}
                                  />
                                </Div>
                              )
                                : name === inputs.client
                                  ? (
                                    <DropDownListItem className="text-truncate w-100 cursor-pointer" onClick={() => { handleSelectData(obj); }}>
                                      <MDLabel onClick={() => { handleSelectData(obj); }} fontSize="16px" cursor="pointer">
                                        {obj.label}
                                      </MDLabel>
                                    </DropDownListItem>
                                  )
                                  : name === inputs.description ? (
                                    <Card p={3} width="fit-content" isSelected={obj.isSelected} onClick={() => { handleAdditionalOptions(obj); }}>
                                      <Div pb={2}>
                                        <MDLabel fontSize="16px" lineHeight="24px">{obj.label}</MDLabel>
                                      </Div>
                                      <P m={0} display="flex" justifyContent="center">
                                        <MDImage src={Constants.icons.ClockIcon} alt="icon" className="pr-2" />
                                        <MDInputValue className="pr-2" fontSize="12px">
                                          <span className="disabled-state">
                                          +
                                            {obj.duration}
                                          &nbsp;
                                          min
                                          </span>
                                        </MDInputValue>
                                        <MDInputValue className="pl-3" fontSize="12px">
                                          <MDImage src={Constants.icons.PriceTag} alt="icon" className="pr-2" />
                                          <span className="disabled-state">
                                          +
                                            {obj.price}
                                          &nbsp;
                                          kr
                                          </span>
                                        </MDInputValue>
                                      </P>
                                    </Card>
                                  )
                                    : (
                                      <ProfileCard
                                        data={obj}
                                        width={name === inputs.service ? "auto" : 255}
                                        height={name === inputs.service ? "" : 64}
                                        onClick={handleSelectData}
                                        userAvtar={obj.profilePic || ""}
                                        serviceCard={name === inputs.service}
                                      />
                                    )}
                            </Div>
                          </Div>
                        ))}
                      </Div>
                    </Div>
                  </P>
                </Div>
              </Div>
            ))}
          {name === inputs.dog
            ? showAddDogModal
              ? (
                <Div p={2} px="4%" maxHeight={heights.h800} className=" row m-0 overflow-auto">
                  <AddDog
                    userId={clientId}
                    isOrder
                    handleBackToCustomers={onCancel}
                    onCancel={onCancel}
                  />
                </Div>
              )
              : (
                <Div p={3} display="flex" justifyContent={cardData.length ? "start" : "center"}>
                  <Button
                    label={<FormattedMessage id="customer_add_new_dog" defaultMessage="Add new dog" />}
                    primary
                    startIcon={icons.PlusIcon}
                    width={widths.w175}
                    onClick={handleAddDog}
                    padding="10px !important"
                  />
                </Div>
              ) : ""}
          {!showAddDogModal && (
            <Div my={3} mx={3} display="flex" justifyContent="center" className="position-sticky">
              <Button label={<FormattedMessage id="common_cancel" />} secondary className="mr-3" onClick={onCancel} />
              <Button
                className="ml-3"
                onClick={handleSave}
                disabled={!((cardData.length > 0
               || name === inputs.startEndDate ||  name === inputs.time || name === inputs.duplicate) && selectedData)}
                label={<FormattedMessage id="common_save" />}
                startIcon={icons.AssignmentsMenuIcon}
                primary
              />
            </Div>
          )}
        </Div>
      )}
    </CardWrapper>
  );
};
Modals.propTypes = {
  data: PropTypes.string,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  handleSubmit: PropTypes.func,
  values: PropTypes.node,
  order: PropTypes.bool,
  id: PropTypes.string,
  dogSize: PropTypes.string,
};
Modals.defaultProps = {
  data: [],
  onCancel: undefined,
  name: "",
  handleSubmit: undefined,
  values: {},
  id: "",
  order: false,
  dogSize: "",
};
export default Modals;
