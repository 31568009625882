import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
// import Constants from '../../../shared/Constants';
import {
  Box, CardHeader, Div, MDLabel,
} from "../../../styles/Styles";

const BannedModal = (props) => {
  const { onClickModal, handleClose } = props;

  return (
    <Modal>
      <Div width={380} mb={3} p={2}>
        {/* <Button label="X" secondary padding={2} onClick={handleCloseClick} /> */}
        <CardHeader>
          <MDLabel alignSelf="center" fontSize={Theme.fontSizes.medium}>
            <FormattedMessage id="sorry" defaultMessage="Sorry" />
          </MDLabel>
        </CardHeader>
        <Div className="text-center" alignSelf="center" my={3}>
          <FormattedMessage
            id="access_resticted"
            defaultMessage="You are not allowd to do this. Please, contact the MyDogCre support for more info"
          />
        </Div>
        <Box>
          <Button
            label={<FormattedMessage id="to_contact_us_page" defaultMessage="To Contact Us page" />}
            primary
            onClick={() => {
              onClickModal?.();
              handleClose?.();
              window.location.href = "https://mydogcare.com/kontakt/";
            }}
          />
        </Box>
      </Div>
    </Modal>
  );
};

BannedModal.propTypes = {
  onClickModal: PropTypes.func,
  handleClose: PropTypes.func,
};

BannedModal.defaultProps = {
  onClickModal: () => {},
  handleClose: () => {},
};

export default memo(BannedModal);
