import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Div, MDLabel, CardHeader, MDImage,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import Theme from "../../styles/Theme";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import CheckBox from "../Input/CheckBox";
import { formatDate } from "../../shared/utils";
import { availabilityDeleteDetails } from "../../store/actions/AppAction";

export default function DeleteModal({
  renderModal, data, setAvailability, setSubmit,
}) {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const handleSelect = () => {
    setIsChecked(!isChecked);
  };
  const [isAssignments, setAssignments] = useState([]);
  const getAssignmentsDetails = useSelector((state) => state.AppReducer.deleteAvailabilityDetails);
  const fetchAssignmentsDetails = (params) => {
    dispatch(availabilityDeleteDetails(params));
  };
  useEffect(() => {
    if (isChecked) {
      setAvailability({
        ...data,
        recKey: true,
      });
    } else {
      setAvailability({
        ...data,
        recKey: false,
      });
    }
  }, [isChecked]);
  useEffect(() => {
    fetchAssignmentsDetails(data);
  }, [data.recKey]);
  useEffect(() => {
    if (getAssignmentsDetails) {
      setAssignments(getAssignmentsDetails?.data);
    }
  }, [getAssignmentsDetails]);
  const {
    widths: {
      w25,
    },
    heights: {
      h20,
    },
    radii: {
      large,
    },
  } = Theme;
  const handleModal = useCallback(() => {
    setAssignments([]);
    setSubmit(data);
    renderModal();
  });
  const handleModalClose = useCallback(() => {
    renderModal(false);
  });
  return (
    <Modal className="availability-form">
      <Div width={["400px", "500px", "500px"]}>
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id="label_del" defaultMessage="Delete Availability" />
          </MDLabel>
        </CardHeader>
        <Div display="flex" flexDirection="column" className="col-12" alignItems="center" paddingTop="1rem" justifyContent="center">
          {isAssignments?.length > 0 ? (
            <>
              <FormattedMessage ml={2} id="label_delete" defaultMessage="Are you Sure you want to delete this availability?" />
              <FormattedMessage id="label_time_warning_a" defaultMessage="There is an order for this time." />
            </>
          )
            : <FormattedMessage ml={2} id="label_delete" defaultMessage="Are you Sure you want to delete this availability?" />}
          {isAssignments?.length > 0
            && (
              <Div my={2}>
                {isAssignments.map((assignments) => (
                  <>
                    <Div display="flex" my={2} className="col-10 offset-1">
                      <Div display="flex" justifyContent="center" className="col-12">
                        <MDImage
                          src={CalendarIcon}
                          useCursorPointer
                          alt="icon"
                          width={w25}
                          height={h20}
                          borderRadius={large}
                        />
                        {formatDate(assignments.assignment_start_datetime)}
                      </Div>
                    </Div>
                    <Div display="flex" my={2} className="row col-10 offset-1">
                      <Div display="flex" className="col-3" />
                      <Div display="flex" className="col-9" mx={2} alignItems="center">
                        <MDLabel fontSize="18px" color="darkgray" mr={15}>From: </MDLabel>
                        <MDImage
                          src={ClockIcon}
                          useCursorPointer
                          alt="icon"
                          width={w25}
                          height={h20}
                          mx={2}
                          borderRadius={large}
                        />
                        {moment(assignments.assignment_start_datetime).format("HH:mm")}
                        <MDLabel fontSize="18px" color="darkgray" ml={15} mr={15}>To: </MDLabel>
                        <MDImage
                          src={ClockIcon}
                          useCursorPointer
                          alt="icon"
                          width={w25}
                          height={h20}
                          mx={1}
                          borderRadius={large}
                        />
                        {moment(assignments.assignment_end_datetime).format("HH:mm")}
                      </Div>
                    </Div>
                  </>
                ))}
                {data.key && (
                  <Div display="flex" flexDirection="column">
                    <MDLabel alignSelf="center" fontSize="15px" mt={20} class="col-12" fontWeight="normal">
                      <FormattedMessage
                        ml={2}
                        id="label_delete_recurrent"
                        defaultMessage="All availabilities is RECURRENT.You also
                  can Delete all recurrent availabilities similar to it.
                  Past Availabilities will not Delete."
                      />
                    </MDLabel>
                    <Div display="flex" className="col-10 offset-1" justifyContent="center" mt={20}>
                      <CheckBox
                        primary
                        label={<FormattedMessage id="label_similar" defaultMessage="save changes for all similar" />}
                        isSelected={!!isChecked}
                        inputProps="py-2"
                        onClick={() => handleSelect()}
                      />
                    </Div>
                  </Div>
                )}
              </Div>
            )}
          {!isAssignments?.length && data && (
            <Div my={2}>
              <Div display="flex" my={2} className="col-10 offset-1">
                <Div display="flex" justifyContent="center" className="col-12" mb={2}>
                  <MDImage
                    src={CalendarIcon}
                    useCursorPointer
                    alt="icon"
                    width={w25}
                    height={h20}
                    borderRadius={large}
                  />
                  <FormattedMessage
                    id="details_sortins"
                    defaultMessage={data?.startDate}
                  />
                </Div>
              </Div>
              <Div display="flex" my={2} className="row col-10 offset-1">
                <Div display="flex" className="col-3" />
                <Div display="flex" className="col-9" mx={2} alignItems="center">
                  <MDLabel fontSize="16px" color="darkgray" mr={10}>From: </MDLabel>
                  <MDImage
                    src={ClockIcon}
                    useCursorPointer
                    alt="icon"
                    width={w25}
                    height={h20}
                    mr={1}
                    borderRadius={large}
                  />
                  <FormattedMessage
                    mx={1}
                    id="startTime_sortins"
                    defaultMessage={data?.time?.[0]}
                  />
                  <MDLabel fontSize="16px" color="darkgray" ml={30} mr={10}>To: </MDLabel>
                  <MDImage
                    src={ClockIcon}
                    useCursorPointer
                    alt="icon"
                    width={w25}
                    height={h20}
                    mr={1}
                    borderRadius={large}
                  />
                  <FormattedMessage
                    mx={2}
                    id="startTime_sortins"
                    defaultMessage={data?.time?.[1]}
                  />
                </Div>
              </Div>
              {data.key && (
                <Div display="flex" flexDirection="column">
                  <MDLabel alignSelf="center" fontSize="15px" mt={20} class="col-12" fontWeight="normal">
                    <FormattedMessage
                      ml={2}
                      id="label_delete_recurrent"
                      defaultMessage="All availabilities is RECURRENT.You also
                  can Delete all recurrent availabilities similar to it.
                  Past Availabilities will not Delete."
                    />
                  </MDLabel>
                  <Div display="flex" className="col-10 offset-1" justifyContent="center" mt={20} mb={15}>
                    <CheckBox
                      primary
                      label={<FormattedMessage id="label_similar" defaultMessage="save changes for all similar" />}
                      isSelected={!!isChecked}
                      inputProps="py-2"
                      onClick={() => handleSelect()}
                    />
                  </Div>
                </Div>
              )}
            </Div>
          )}
        </Div>
        <Div display="flex" className="col-12 col-lg-8 mx-auto offset-lg-2" justifyContent="center" mt={1} mb={20}>
          <Div display="flex" className="col-6">
            <Button
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              secondary
              width="100%"
              onClick={handleModalClose} />
          </Div>
          <Div display="flex" className="col-6">
            <Button
              label={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              width="100%"
              onClick={handleModal}
            />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
DeleteModal.propTypes = {
  /**
   * Pass children innto the modal
   */
  renderModal: PropTypes.func,
  data: PropTypes.string,
  setAvailability: PropTypes.string,
  setSubmit: PropTypes.string,
};
DeleteModal.defaultProps = {
  renderModal: false,
  data: {},
  setAvailability: {},
  setSubmit: undefined,
};
