import moment from "moment";
import { request } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser, fetchAvailabilityLocalDateConvertedJson } from "../shared/utils";
import { fromListJson, fromOrderDetailsJson } from "../models/Orders";

const {
  apiEndPoints: { endPointUrl = {} }, HTTP_STATUS: { OK }, requestType: {
    GET, PUT, POST, DELETE,
  },
  input: {
    param: {
      orgId, serviceId, customerId, dogSize,
    },
  },
} = Constants;

export async function cancelOrder(id, cusId, payload = {}) {
  const params = [[customerId, cusId]];
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.CANCEL}${queryParamParser(params)}`;
  const response = await request("put", url, payload);
  const { data: responseData = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return responseData;
}

export async function getServices(id) {
  const params = [[orgId, id]];
  const url = `${endPointUrl.ORGANIZATIONS}/${endPointUrl.SERVICES}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data: { data: responseData = {} } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return responseData;
}

export async function getTimeAndDate(id, payload) {
  const url = `${endPointUrl.ORGANIZATIONS}/${id}/available-slots${queryParamParser(payload)}`;
  const response = await request(GET, url);
  const { data:  responseData = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return {
    ...responseData,
    end_time: responseData.end_time ? moment.utc(`${payload[1][1]} ${responseData.end_time}`).local().format("HH:mm") : "",
    start_time: responseData.start_time ? moment.utc(`${payload[1][1]} ${responseData.start_time}`).local().format("HH:mm") : "",
    service_provider: responseData?.service_provider?.map(
      (o) => ({
        ...o,
        available_slots: o.available_slots.map(
          (obj) => (fetchAvailabilityLocalDateConvertedJson(obj)),
        ),
      }),
    )};
}

export async function getAdditionalOptions(id) {
  const url = `${endPointUrl.SERVICES}/${id}/${endPointUrl.ADDITIONAL_OPTIONS}`;
  const response = await request(GET, url);
  const { data: { data: responseData = {} } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return responseData;
}

export async function getRooms(id, org) {
  const params = [[orgId, org.value || ""]];
  const url = `${endPointUrl.SERVICES}/${id}/${endPointUrl.ROOMS}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function getServiceProviders(id, { value: orgValue = "" }, dogSizeValue = "") {
  const params = [[orgId, orgValue || ""], [serviceId, id], [dogSize, dogSizeValue]];
  const url = `${endPointUrl.SERVICEPROVIDERS}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export default async function getClientDogDetails(id) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.CLIENTS}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function getPaymentDetails(id) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.TOTAL}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function getOrdersList(queryParam = []) {
  const url = `${endPointUrl.ORDERS}${queryParamParser(queryParam)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return fromListJson(data);
  }
}

export async function getFilterLetters() {
  const url = `${endPointUrl.ORDERS}/${endPointUrl.FILTER}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function getOrderDetails(id, apiToken = "") {
  let url = `${endPointUrl.ORDERS}/${id}`;
  if (apiToken) {
    url = `${url}?api_token=${apiToken}`
  }
  const response = await request(GET, url);
  const { data: { data: responseData = {} } = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return fromOrderDetailsJson(responseData);
  }
}

export async function addOrder(payload) {
  const url = `${endPointUrl.ORDERS}`;
  const response = await request(POST, url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function updateOrder(id, payload) {
  const url = `${endPointUrl.ORDERS}/${id}`;
  const response = await request(PUT, url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function deleteOrder(id) {
  const url = `${endPointUrl.ORDERS}/${id}`;
  const response = await request(DELETE, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function closeOrder(id) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.CLOSE}`;
  const response = await request(PUT, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function getOrderRefundEligibility(id) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.REFUND}`;
  const response = await request(GET, url);
  const { data: { refund = "" } = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return refund;
  }
}

export function refundOrder(id) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.REFUND}`;
  return request(PUT, url);
}
