import styled from "styled-components";
import { MDLabel } from "../../styles/Styles";
import Theme from "../../styles/Theme";

const {
  fontSizes, lineHeights, fonts,
} = Theme;
export const ContentHeader = styled(MDLabel)`
`;
ContentHeader.defaultProps = {
  fontSize: fontSizes.medium,
  lineHeight: lineHeights.tight,
};

export const Content = styled(MDLabel)`
`;
Content.defaultProps = {
  fontFamily: fonts.regular,
  lineHeight: lineHeights.tight,
};

export const ButtonWrapper = styled.div`
    flex-direction: column;
    align-items: flex-start;
`;
