import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BoxDivision, Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { FormattedMessage } from "react-intl";
import Constants from "../../../shared/Constants";
import { Box, Div, MDLabel, P } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubscriptionServices,
  updatedSubscriptionPackages,
} from "../../../store/actions/SubscriptionBookingFlowAction";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import SubscriptionDetail from "../Components/SubscriptionDetail";
import Button from "../../../components/Button/Button";
import Colors from "../../../styles/Colors";

const { icons, themes } = Constants;

const SubscriptionFlowStepOne = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCheckDaycareClick = () => {
    history.push(Constants.routes.chooseService.url);
  };

  const subscriptionPackages = useSelector((state) => updatedSubscriptionPackages(state));
  const [subscriptionsWithMostPopular, setSubscriptionsWithMostPopular] = useState([]);

  useEffect(() => {
    dispatch(fetchSubscriptionServices());
  }, []);

  useEffect(() => {
    if (subscriptionPackages && Object.keys(subscriptionPackages).length > 0) {
      const mostPopularPackage = subscriptionPackages.find((subscription) => subscription.mostPopular === true);
      if (!mostPopularPackage || Object.keys(mostPopularPackage).length === 0) {
        const tempSubsciptionPackages = [...subscriptionPackages];
        tempSubsciptionPackages.forEach((subscription) => {
          if (subscription?.name?.toLowerCase().includes("silver")) {
            subscription.mostPopular = true;
          }
        });
        setSubscriptionsWithMostPopular(tempSubsciptionPackages);
      } else {
        setSubscriptionsWithMostPopular(subscriptionPackages);
      }
    }
  }, [subscriptionPackages]);

  const handleChooseSubscription = (selectedId) => {
    dispatch(SubscriptionBookingFlowActions.setSelectedSubscription(selectedId));
    dispatch(SubscriptionBookingFlowActions.nextStep());
    history.push(Constants.routes.chooseSubscriptionDog.url);
  };

  const checkServicesCard = () => {
    return (
      <Box
        display="flex"
        borderRadius="10px"
        flexDirection="column"
        type="cate_one"
        background={Colors.Secondary}
        pt={4}
        px={[0,5]}
        mt={5}>
        <MDLabel fontSize={themes.fontSizes.large} fontWeight="400" mt={1}>
          <FormattedMessage id="looking_for_services"  defaultMessage="Looking for Services?"/>
        </MDLabel>
        <P
          mt={4}
          px={[1,5]}
          textAlign="center"
          fontWeight={themes.fontWeights.light}
          fontSize={themes.fontSizes.medium}>
          <FormattedMessage
            id="looking_for_services_description"
            defaultMessage="We have gathered a wide range of skills under the same roof to be able to offer all the services we believe dog owners need."
          />
        </P>
        <Box mt={4} mb={4}>
          <Button
            onClick={handleCheckDaycareClick}
            label={<FormattedMessage id="check_services_button_label" defaultMessage="Check Services" />}
            endIcon={icons.ArrowRightWhite}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Division>
      <Div p="11px">
        <BoxDivision mt="20px">
          <MDLabel fontSize={Theme.fontSizes.large}>
            <FormattedMessage id="subscription_daycare_space" defaultMessage="Subscription: Daycare space" />
          </MDLabel>
        </BoxDivision>
        <BoxDivision type="cate_One">
          {subscriptionsWithMostPopular &&
            subscriptionsWithMostPopular
              .filter((subscription) => subscription?.name?.toLowerCase() !== "custom")
              .map((subscription) => {
                return (
                  <SubscriptionDetail
                    key={subscription.id}
                    subscription={subscription}
                    onClick={handleChooseSubscription}
                  />
                );
              })}
        </BoxDivision>
      </Div>
      {checkServicesCard()}
    </Division>
  );
};
export default memo(SubscriptionFlowStepOne);
