import { request } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser } from "../shared/utils";

const userData = JSON.parse(localStorage.getItem(Constants.storage.dataUser));

const {
  apiEndPoints: { endPointUrl = {} },
  HTTP_STATUS: { OK, CREATED },
  requestType: { POST },
} = Constants;

export const getCategoriesList = async () => {
  const response = await request("get", `${endPointUrl.ORDERS}/${endPointUrl.CATEGORIES}`);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data;
};

export const getAvailableDates = async (orgId, startDate, endDate, serviceId, dogWeight) => {
  const url = `${endPointUrl.ORGANIZATIONS}/${orgId}/${endPointUrl.AVAILABLE_SLOTS_DATES}?start_date=${startDate}&end_date=${endDate}&service_id=${serviceId}&serviceable_dog_size=${dogWeight}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data;
};

export const getDogDetail = async (id, customerId) => {
  const url = `${endPointUrl.CUSTOMERS}/${id}/${endPointUrl.DOGS}?customer_id=${customerId}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
};

export const getOrganisationsRecord = async (id, dogWeight) => {
  const url = `${endPointUrl.ORDERS}/${endPointUrl.SERVICES}/${id}/${endPointUrl.ORGANIZATIONS}?dog_size=${dogWeight}`;
  const response = await request("get", url);
  const { data : { data = {} } = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
};

export const getOrganisationSchedule = async (orgId, params) => {
  const url = `${endPointUrl.ORGANIZATIONS}/${orgId}/${endPointUrl.SCHEDULE}/${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
};

export const getOrganisationAvailability = async (orgId, params) => {
  const url = `${endPointUrl.ORGANIZATIONS}/${orgId}/${endPointUrl.AVAILABLE_SLOTS}/${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
};

export const getOrganisationsServiceProvider = async (queryParam) => {
  const url = (
    `${endPointUrl.SERVICEPROVIDERS}${queryParamParser(queryParam)}`
  );
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
};

export async function setPaymentsDetails(payload) {
  const url = `${endPointUrl.ORDERS}/${endPointUrl.INIT_KLARNA}`;
  const response = await request(POST, url, payload);
  const { data = {}, status = "", statusText = "", error_list = [] } = response;

  if (!(CREATED !== status || OK !== status)) {
    throw Error({statusText, error_list});
  } else {
    return data;
  }
}

export async function addServiceToCart(payload) {
  const user = JSON.parse(localStorage.userData);
  const url = `${endPointUrl.CUSTOMERS}/${user?.customer?.id}/${endPointUrl.CART}`;
  const response = await request(POST, url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (status >= 399) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function mapDogToUser(payload) {
  const url = `${endPointUrl.CUSTOMERS}/${userData?.customer?.id}/${endPointUrl.DOGS}`;
  const response = await request(POST, url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (status >= 399) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export async function loadUserCartData() {
  const user = JSON.parse(localStorage.userData);
  const url = `${endPointUrl.CUSTOMERS}/${user?.customer?.id}/${endPointUrl.CART}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function deleteItemFromCart(id) {
  const user = JSON.parse(localStorage.userData);
  const url = `${endPointUrl.CUSTOMERS}/${user?.customer?.id}/${endPointUrl.CART}/${id}`;
  const response = await request("delete", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
