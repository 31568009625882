import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import HeadSection from "./HeadSection";
import CardContainer from "./CardContainer";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import { ButtonBox, MobileDiv } from "../Style";
import { Div, MDLabel, Box } from "../../../styles/Styles";

const OrderConfirm = ({ isLoggedIn }) => {
  const history = useHistory();
  const { language, routes } = Constants;
  const navigateTo = (url) => () => {
    history.push(url);
  };

  const handleNewOrder = () => {
    window.location.href = routes.bookingFlow.url;
  }

  return (
    <>
      <Div className="container" px={2}>
        <HeadSection fontProp={Theme.fontSizes.header} headerTitle="Order status" />
        <CardContainer cardTitle={<FormattedMessage id="label_details" defaultMessage="Details" />}>
          <Box mt={4}>
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="done" defaultMessage="Done!" />
            </MDLabel>
          </Box>
          <Box mt={4}>
            <MobileDiv type="subnewwrapper">
              <MDLabel>
                {isLoggedIn ? (
                  <FormattedMessage
                    id="thanks_for_order_customer_account"
                    defaultMessage="Thank you, your order is completed! Soon you will get an e-mail with order details. Also you will find this order in your User Profile."
                  />
                ) : (
                  <FormattedMessage
                    id="thanks_for_order"
                    defaultMessage="Thank you, your order is completed! Soon you will get an e-mail with order details. Also we have sent you and e-mail with instructions how to activate your profile."
                  />
                )}
              </MDLabel>
            </MobileDiv>
          </Box>
          <Div display={["block", "flex"]} justifyContent="center">
            <ButtonBox mt={4} pr={[0, 4]}>
              <Button width="200px" type="payment_button" secondary label={language.book_new_service} onClick={handleNewOrder} />
            </ButtonBox>
            <ButtonBox mt={4}>
              {isLoggedIn
                ? <Button width="200px" type="payment_button" primary label={language.to_my_profile} onClick={navigateTo(routes.myProfile.url)} />
                : <Button width="200px" type="payment_button" primary label={language.login_submit_button_log_in} onClick={navigateTo(routes.customerLogin.url)} />}
            </ButtonBox>
          </Div>
        </CardContainer>
      </Div>
    </>
  );
};

OrderConfirm.propTypes = {
  isLoggedIn: PropTypes.bool,
};
OrderConfirm.defaultProps = {
  isLoggedIn: false,
};

export default OrderConfirm;
