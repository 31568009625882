import React, { useState, memo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import AddToCartPopup from "../AddToCartPopup/AddToCartPopup";
import CommonCard from "../CommonSections/CommonCards/CommonCard";
import ExtraServices from "../ExtraServices/ExtraServices";
import { Div, MDLabel } from "../../../styles/Styles";
import {
  editOrderSummary, addToGuestCart, addToGuestCheckout,
  setErrorValue,
  setFlagValue,
} from "../../../store/actions/BookingFlowServicesAction";
import {
  BoxDivision, Division, LabelBox, MDLabelBox, SpanBox,
} from "../BookingFlowStyles/BookingFlowStyles";
import {
  mapDogSizeToPrice, mapDogSizeToDuration, stepRoute,
  isLoggedIn, changeDateFormat, mapSizeToDog
} from "../../../shared/utils";
import { emptyPaymentSnippet, placeOrder, loadCartContent } from "../../../store/actions/PaymentAction";
import moment from "moment";


const { icons, images, input: { format: { twentyFourHourFormat, dottedHourFormat, twoDigitDateFormat } } } = Constants;

const BookingFlowStepFive = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disableBtn, setDisableBtn] = useState(false);
  const [totalPrice, setTotalPrice] = useState(-1);

  useEffect(() => {
    dispatch(emptyPaymentSnippet());
  }, []);

  const setError = useSelector(
    (state) => state.BookingFlowServicesReducer.setError,
  );

  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);

  useEffect(() => {
    if (steps === 1) {
      history.push(Constants.routes.chooseService.url);
    }
  }, [steps]);

  useEffect(() => {
    let timer;
    if (setError) {
      timer = setTimeout(() => {
        dispatch(setErrorValue(null));
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [setError]);

  const passToCheckout = useSelector(
    (state) => state.BookingFlowServicesReducer.passToCheckout,
  );

  const rawHTML = useSelector((state) => state.PaymentReducer.snippetResponse?.html_snippet);

  function renderSnippet(htmlSnippet) {
    const checkoutContainer = document.getElementById("my-checkout-container");
    checkoutContainer.innerHTML = htmlSnippet;
    const scriptsTags = checkoutContainer.getElementsByTagName("script") || [];
    for (let i = 0; i < scriptsTags.length; i += 1) {
      const { parentNode } = scriptsTags[i];
      const newScriptTag = document.createElement("script");
      newScriptTag.type = "text/javascript";
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }

  useEffect(() => {
    if (rawHTML) {
      renderSnippet(rawHTML);
    }
  }, [rawHTML]);

  useEffect(() => {
    let timer;
    if (passToCheckout) {
      timer = setTimeout(() => {
        if (isLoggedIn()) {
          setDisableBtn(true);
          // history.push(Constants.routes.payment.url);
        } else {
          history.push(Constants.routes.cart.url);
        }
      }, 50);
    }
    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        dispatch(setFlagValue(false));
      }, 50);
    };
  }, [passToCheckout]);

  const selectedServices = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices,
  );

  const dogSizes = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes,
  );

  const selectedLocation = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedLocation,
  );

  const selectedProvider = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedProvider,
  );

  const { selectedDate = ""} = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer,
  );

  const paymentDone = useSelector((state) => state.PaymentReducer.paymentDone);
  const orderData = useSelector((state) => state.PaymentReducer.orderData);

  const firstService = useSelector((state) => state.BookingFlowServicesReducer.firstSelectService);
  const addOns = firstService && firstService[0]?.add_ons;

  const { service : { is_full_day_service : isAFullDayService = false } = {} } = selectedServices;

  useEffect(() => {
    if(dogSizes?.dogWeight && selectedServices?.service?.price) {
      setTotalPrice(selectedServices.service.price[dogSizes.dogWeight]);
    }
  }, [selectedServices, dogSizes]);

  useEffect(() => {
    if (paymentDone && orderData?.id && totalPrice === 0) {
      setDisableBtn(false);
      history.push(`${Constants.routes.orderConfirm.url}?order_id=${orderData.id}&isChecked=false&isFreeOrder=true`);
    }
  }, [paymentDone, orderData]);

  const [openCartModal, setOpenCartModal] = useState(false);
  const handleModalClick = () => {
    dispatch(addToGuestCart());
    setOpenCartModal((prev) => !prev);
    setDisableBtn(true);
  };

  const handleCheckoutClick = () => {
    setDisableBtn(true);
    if (totalPrice == 0) {
      let caluculateTotal = 0;
      const { add_ons: selectedAddOns = [] } = selectedServices;
      selectedAddOns.map((x) => {
        caluculateTotal += Number(x.price || 0);
        return x;
      });
      const obj = [{
        service_title: selectedServices.serviceName,
        service_category: selectedServices.categoryName,
        total: Number(caluculateTotal) + Number(dogSizes.price),
        service: {
          name: selectedServices.serviceName,
          type: "service",
          is_full_day_service: selectedServices?.service?.is_full_day_service === 1,
          bnr: selectedServices.banner || icons.PawIcon,
          tagIcon: icons.TagIcon,
          ...selectedServices,
        },
        service_location: {
          title: selectedLocation.organisationName,
          bnr: selectedLocation.profilePic || icons.Avatar,
          area: selectedLocation.address,
          locationIcon: icons.ServiceLocation,
          location: selectedLocation.location || {},
          ...selectedLocation,
        },
        service_detail: {
          title: dogSizes.dogName,
          size: dogSizes.dogWeight,
          time: mapDogSizeToDuration(dogSizes.dogWeight, selectedServices),
          tagRate: mapDogSizeToPrice(dogSizes.dogWeight, selectedServices),
          dogSizes: icons.PawIcon,
          iconOne: icons.ClockIcon,
          iconTwo: icons.TagIcon,
          age: mapSizeToDog(dogSizes.dogWeight),
          ...dogSizes,
        },
        service_provider: {
          provider: selectedProvider.providerName,
          info: Constants.language.common_service_provider,
          face: selectedProvider.providerFace || icons.Avatar,
          location: icons.UserSettingsIcon,
          ...selectedProvider,
        },
        service_schedule: {
          scheduled_on: selectedDate,
          scheduled_at: selectedProvider.serviceTime,
        },
        additional_service: selectedAddOns,
      }];
      dispatch(loadCartContent(obj));
      dispatch(placeOrder());
    } else {
      dispatch(addToGuestCheckout());
      history.push(Constants.routes.cart.url);
    }
  };

  const handleEdit = (e) => () => {
    history.push(stepRoute(e));
    dispatch(editOrderSummary(e));
  };
  return (
    <Div pb={4}>
      <BoxDivision pt={4} mt={2} mb={4}>
        <MDLabel fontSize={Theme.fontSizes.xl} mb={3} pb={1}>
          <FormattedMessage id="order_summary" defaultMessage="Summary of your order:" />
        </MDLabel>
      </BoxDivision>
      <BoxDivision mb={20}>
        <Division
          type="choose_summary"
          className="edit_button"
          mr={4}
          onClick={handleEdit(1)}
          mb={3}
          pb={2}
          height={65}
        >
          <CommonCard
            avtarImg={selectedServices?.banner || icons.Avatar}
            header={selectedServices?.serviceName}
            leftIcon={images.TagSingleIcon}
            leftContent={selectedServices?.categoryName}
            paddingLeft={20}
            msFull="msFull"
            heightValue="64px"
          />
        </Division>
        <Division className="edit_button" onClick={handleEdit(2)} mb={3} pb={2} type="choose_size" height={65}>
          <CommonCard
            avtarImg={dogSizes?.dogFace || icons.Avatar}
            header={Constants.translations[dogSizes?.dogWeight]}
            leftIcon={icons.ClockIcon}
            leftContent={selectedServices?.service?.is_full_day_service === 1 ? Constants.language.label_day : mapDogSizeToDuration(dogSizes?.dogWeight, selectedServices?.service)}
            rightIcon={images.TagSingleIcon}
            rightContent={mapDogSizeToPrice(dogSizes?.dogWeight, selectedServices?.service)}
            fontSize={Theme.fontSizes.small}
            color={Colors.DarkGrey}
            paddingLeft="12px"
            msRight="msRight"
            msLeft="msLeft"
          />
        </Division>
        <Division
          height={65}
          className="edit_button"
          ml={30}
          onClick={handleEdit(3)}
          type="provider_summary"
          mb={3}
          pb={2}
        >
          <CommonCard
            avtarImg={selectedLocation?.profilePic?.url || icons.Avatar}
            header={selectedLocation?.organisationName}
            leftIcon={icons.ServiceLocation}
            leftContent={selectedLocation?.address}
            fontSize={Theme.fontSizes.es}
            fontFamily={Theme.fonts.regular}
            fontWeight={Theme.fontWeights.normal}
            paddingLeft={10}
            msFull="msFull"
            width="auto !important"
          />
        </Division>
      </BoxDivision>
      <BoxDivision flexDirection={["column", "row"]} alignItems="center">
        {isAFullDayService  ? (
          <>
            <Division
              onClick={handleEdit(4)}
              height={65}
              className="edit_button"
              mx={[10, 10, 10, 30]}
              mt={[1, 10]}
              mb={4}
              pb={2}
              type="booking_service_date"
            >
              <LabelBox type="date_time">
                <SpanBox type="booking_date" p={1}>
                  <FormattedMessage id="label_start_date" defaultMessage="Start date" />
                </SpanBox>
                <SpanBox type="booking_time" mx={["auto"]} p={1}>
                  {Array.isArray(selectedDate) ? selectedDate[0] : selectedDate}
                </SpanBox>
              </LabelBox>
            </Division>
            <Division
              onClick={handleEdit(4)}
              height={65}
              className="edit_button"
              mx={[10, 10, 10, 30]}
              mt={[1, 10]}
              mb={4}
              pb={2}
              type="booking_service_date"
            >
              <LabelBox type="date_time">
                <SpanBox type="booking_date" p={1}>
                  <FormattedMessage id="label_end_date" defaultMessage="End date" />
                </SpanBox>
                <SpanBox p={1} type="booking_time" mx={["auto"]}>
                  {Array.isArray(selectedDate) ? (selectedDate[1]) : selectedDate}
                </SpanBox>
              </LabelBox>
            </Division>
          </>
        ) : (
          <Division
            onClick={handleEdit(4)}
            height={65}
            className="edit_button"
            mx={[10, 10, 10, 30]}
            mt={[1, 10]}
            mb={4}
            pb={2}
            type="booking_service_date"
          >
            <LabelBox type="date_time">
              <SpanBox type="booking_date" p={1}>
                {changeDateFormat(selectedDate,"YYYY-MM-DD", twoDigitDateFormat)}
              </SpanBox>
              <SpanBox type="booking_time" mx={["auto"]}>
                {moment(selectedProvider?.serviceTime?.split(" - ")[0], twentyFourHourFormat).format(dottedHourFormat)}
              </SpanBox>
            </LabelBox>
          </Division>
        )}
        <Division height="100%" className="edit_button" onClick={handleEdit(4)} mt={[10, 0]} mb={3} pb={2} ml={[10, 10, 10, 30]} type="service_providers">
          <CommonCard
            avtarImg={selectedProvider?.providerFace || icons.Avatar}
            header={selectedProvider?.providerName}
            leftIcon={icons.UserSettingsIcon}
            leftContent={(<FormattedMessage id="service_provider" defaultMessage="Service provider" />)}
            paddingLeft={20}
            msFull="msFull"
            heightValue={64}
          />
        </Division>
      </BoxDivision>
      {addOns && addOns.length > 0 ? (
        <BoxDivision pt={4} mt={2} mb={4}>
          <MDLabelBox fontSize={Theme.fontSizes.xl} mb={3} pb={1} type="like_extra_services">
            <FormattedMessage id="extra_services" defaultMessage="Would you like to add some extra services?" />
          </MDLabelBox>
        </BoxDivision>
      ) : ""}
      <ExtraServices msLeft="msLeft" msRight="msRight" />
      {setError && (
        <BoxDivision pt={3}>
          {setError}
        </BoxDivision>
      )}
      <Division mt="20px" px={[3, 0 ,0]} color={Colors.DarkGrey} maxWidth="540px" mx="auto">
        <FormattedMessage id="text_additional_cost" />
      </Division>
      <BoxDivision mt={4} pt={2} type="extra_services_button" pb={[0, 0, "28px"]}>
        <Button
          secondary
          label={<FormattedMessage id="add_to_cart" defaultMessage="Add to cart" />}
          className="mx-2"
          mr={4}
          onClick={handleModalClick}
          disabled={disableBtn}
        />
        <Button
          primary
          className="mx-2"
          width="165px"
          label={<FormattedMessage id={totalPrice === 0 ? "confirm_order" : "checkout"} defaultMessage="Checkout" />}
          mr={4}
          onClick={handleCheckoutClick}
          disabled={disableBtn}
        />
      </BoxDivision>
      <Division id={rawHTML ? "my-checkout-container" : ""} />
      {openCartModal && <AddToCartPopup />}
    </Div>
  );
};
export default memo(BookingFlowStepFive);
