/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux";
import {
  MDLabel, Div, MDImage, Span, FormWrapper, MDInputValue,
} from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import Theme from "../../../styles/Theme";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import NameCard from "../../../components/Card/ProfileCard";
import { CardWrapper } from "../../Customers/Styles";
import Loader from "../../../components/Loader/CardLoader";
import Colors from "../../../styles/Colors";

const ClientDogTab = ({
  orderData,
}) => {
  const [clientData, setClientData] = useState({});
  const [dogData, setDogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dogBreeds = useSelector((state) => state.AppReducer.dogBreeds);
  const {
    icons, language, weightTranslations, heightTranslations, translations: translate, input: { name: inputName }, adminRoutes
  } = Constants;
  const { space, colors, fontSizes } = Theme;
  const history = useHistory();

  const fetchDetails = async () => {
    setLoading(true);
    const { clientDogDetails: { client = {}, dogs = [] } = {} } = orderData;
    setClientData(client);
    setDogData(dogs);
    setLoading(false);
  };

  const redirecthandleEditCustomer = (item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${item.id}/${adminRoutes.editCustomer}?isdog=true`);
  }

  useEffect(() => {
    fetchDetails();
  }, []);
  const { profile_pic: profilePic = {} } = clientData;
  const { url: clientProfilePic = "" } = profilePic || {};
  return (
    <FormWrapper>
      {loading && <Loader />}
      {!loading
      && (
        <Div>
          <MDLabel fontSize={space.m} className="mb-4">
            <FormattedMessage id="common_client" />
          </MDLabel>
          {clientData ? (
            <Div display="flex" className="mb-4 pb-2" cursor="pointer" onClick={redirecthandleEditCustomer(clientData)}>
              <Div display="contents">
                <MDImage
                  width="80px"
                  height="80px"
                  borderRadius="10px !important"
                  src={clientProfilePic || FavIcon}
                  bg={clientProfilePic ? "" : `${colors.primary}`}
                  className={clientProfilePic ? "" : "p-2"}
                  alt="icon"
                />
              </Div>
              <Div alignSelf="center" pl={3}>
                <MDLabel fontSize={fontSizes.medium}>
                  <Span pb={12}>{clientData.name || clientData.full_name || ""}</Span>
                </MDLabel>
                <MDLabel fontSize={fontSizes.small}>
                  <Span pb={12}>{clientData.phone_number_list || ""}</Span>
                </MDLabel>
                <MDLabel fontSize={fontSizes.small}>
                  <Span>{clientData.email || ""}</Span>
                </MDLabel>
              </Div>
            </Div>
          )
            : (
              <MDLabel fontSize={fontSizes.medium} className="mb-4">
                <FormattedMessage id="client_not_available" defaultMessage="Client is not available" />
              </MDLabel>
            )}
          {dogData && dogData.length ? dogData.map((dog, index) => {
            const {
              profilePicUrl = "",
              name = "", breed = "", dog_size_weight: dogSizeWeight = "", dog_size_height: dogSizeHeight = "", gender = "", isSterilized = false,
              isInsured = false, age = "", description = "",
            } = dog;
            return (
              <Div className="mb-5" key={index} cursor="pointer" onClick={redirecthandleEditCustomer(clientData)}>
                <MDLabel fontSize={space.m} className="mb-4">
                  {`${language.common_dog} ${index + 1}`}
                </MDLabel>

                <Div display="flex" mb={30}>
                  <Div display="contents">
                    <MDImage
                      width={80}
                      height={80}
                      borderRadius="10px !important"
                      src={profilePicUrl || FavIcon}
                      bg={profilePicUrl ? "" : `${colors.primary}`}
                      className={profilePicUrl ? "" : "p-2"}
                      alt="icon"
                    />
                  </Div>
                  <Div alignSelf="center" pl={3}>
                    <MDLabel fontSize={fontSizes.medium}>
                      <Span pb={12}>{name || ""}</Span>
                    </MDLabel>
                    <MDLabel fontSize={fontSizes.small} bg={(dogBreeds[breed] || breed) && Colors.Orange} borderRadius="10px" py="4px" px="8px">
                      <Span>{dogBreeds[breed] || breed}</Span>
                    </MDLabel>
                  </Div>
                </Div>
                <Div className="row" m={0} pb={1}>
                  <Div className="col-6 col-lg-4" p={0} mb={3} display="flex" flexDirection="column" justifyContent="space-between">
                    <MDLabel lineHeight="19px" fontSize={fontSizes.small} display="contents">
                      {language.label_select_gender}
                    </MDLabel>
                    <Div pt={2}>
                      <NameCard
                        nameCard
                        width={160}
                        userAvtar={gender === inputName.male ? icons.MaleDog : icons.FemaleDog}
                        data={{ label: translate[gender] }}
                      />
                    </Div>
                  </Div>
                  <Div className="col-6 col-lg-4" p={0} mb={3} display="flex" flexDirection="column" justifyContent="space-between">
                    <MDLabel lineHeight="19px" fontSize={fontSizes.small} display="contents">
                      {language.kastrerad_steriliserad}
                    </MDLabel>
                    <Div className="row m-0" pt={2} justifyContent="space-between" alignItems="center">
                      <CardWrapper
                        borderRadius="10px"
                        height="50px"
                      >
                        <MDLabel fontSize={fontSizes.regular}>
                          {isSterilized ? language.common_yes : language.common_no}
                        </MDLabel>
                      </CardWrapper>
                    </Div>
                  </Div>
                  <Div className="col-6 col-lg-4" p={0} mb={3} display="flex" flexDirection="column" justifyContent="space-between">
                    <MDLabel lineHeight="19px" fontSize={fontSizes.small} display="contents">
                      {language.label_is_dog_insured}
                    </MDLabel>
                    <Div className="row" pt={2} m={0} justifyContent="space-between" alignItems="center">
                      <CardWrapper
                        borderRadius="10px"
                        height={50}
                      >
                        <MDLabel fontSize={fontSizes.regular}>
                          {isInsured ? language.common_yes : language.common_no}
                        </MDLabel>
                      </CardWrapper>
                    </Div>
                  </Div>
                </Div>
                <Div className="row" m={0} mb={3} pb={1}>
                  <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
                    {language.label_select_height_dog}:
                  </MDLabel>
                  <MDInputValue>
                    {heightTranslations[dogSizeHeight]}
                  </MDInputValue>
                </Div>
                <Div className="row" m={0} mb={3} pb={1}>
                  <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
                    {language.label_select_weight_dog}:
                  </MDLabel>
                  <MDInputValue>
                    {weightTranslations[dogSizeWeight]}
                  </MDInputValue>
                </Div>
                <Div className="row" m={0} mb={3} pb={1}>
                  <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
                    {language.label_select_age}:
                  </MDLabel>
                  <MDInputValue>
                    {translate[age]}
                  </MDInputValue>
                </Div>
                <Div>
                  <MDLabel lineHeight="19px" fontSize={fontSizes.regular} mb={2}>
                    {language.label_additional_information}:
                  </MDLabel>
                  <MDInputValue>
                    {description}
                  </MDInputValue>
                </Div>
              </Div>
            );
          })
            : clientData ? (
              <MDLabel fontSize={fontSizes.medium} className="mb-4">
                <FormattedMessage id="dog_not_available" defaultMessage="Dog is not available" />
              </MDLabel>
            ) : ""}
        </Div>
      )}
    </FormWrapper>
  );
};
ClientDogTab.propTypes = {
  orderData: PropTypes.node,
};
ClientDogTab.defaultProps = {
  orderData: {},
};

export default ClientDogTab;
