import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Div, MDLabel, CardHeader,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import AvailabilityEditReponse from "./AvailabilityEditReponse";
import CheckBox from "../Input/CheckBox";

export default function WarningPopupModal({
  onClose,
  availabilityModalTitle, details, data, stateObject, setStateObject, setSubmit, assignemntCount,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const handleSelect = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    if (isChecked) {
      setStateObject({
        ...stateObject,
        recKey: true,
      });
    } else {
      setStateObject({
        ...stateObject,
        recKey: false,
      });
    }
  }, [isChecked]);
  const hadnleWarningResponse = () => {
    setSubmit();
    onClose();
  };
  const handleClose = useCallback(() => {
    setSubmit();
  });
  return (
    <Modal className="availability-form">
      <Div width="500px">
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id={availabilityModalTitle} defaultMessage="Warning!" />
          </MDLabel>
        </CardHeader>
        <Div display="flex" className="col-10 offset-1" paddingTop="1rem" justifyContent="center">
          {data?.length > 0 ? (
            <>
              <MDLabel alignSelf="center" fontSize="15px" fontWeight="normal">
                <FormattedMessage id="label_time_warning_a" defaultMessage="There is an order for this time" />
                {data?.map((timeDate) => (
                  <>
                    (
                    {moment(timeDate.assignment_start_datetime).format("hh:mm:ss")}
                    -
                    {moment(timeDate.assignment_end_datetime).format("hh:mm:ss")}
                    )
                  </>
                ))}
                <FormattedMessage id="label_time_warning_b" defaultMessage="you can only edit time before or after." />
              </MDLabel>
            </>
          ) : ""}

          {details && (
            <>
              <Div display="flex" flexDirection="column">
                <MDLabel alignSelf="center" fontSize="16px" class="col-12" fontWeight="500" textAlign="center">
                  <FormattedMessage
                    id="label_recurrent_warning"
                    defaultMessage="All availabilities is RECURRENT.You also
              can edit all recurrent availabilities similar to it.
              Past Availabilities will not change."
                  />
                </MDLabel>
                <Div display="flex" className="col-12" justifyContent="center">
                  <CheckBox
                    primary
                    label={<FormattedMessage id="label_similar" defaultMessage="save changes for all similar" />}
                    isSelected={!!isChecked}
                    inputProps="py-2"
                    onClick={() => handleSelect()}
                  />
                </Div>
              </Div>
            </>
          )}
          {details?.length > 0 && (
            <AvailabilityEditReponse
              details={data}
              renderModal={hadnleWarningResponse}
              assignemntCount={assignemntCount}
            />
          )}
        </Div>
        <Div display="flex" className="col-10 col-lg-8" mx="auto" justifyContent="center" mb={20} mt={60}>
          <Div display="flex" className="col-12 col-lg-6">
            <Button label="Ok" width="100%" onClick={handleClose} />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
WarningPopupModal.propTypes = {
  /**
   * Pass children innto the modal
   */
  onClose: PropTypes.func,
  details: PropTypes.string,
  availabilityModalTitle: PropTypes.string,
  data: PropTypes.string,
  stateObject: PropTypes.string,
  setStateObject: PropTypes.string,
  setSubmit: PropTypes.string,
  assignemntCount: PropTypes.string,
};
WarningPopupModal.defaultProps = {
  onClose: undefined,
  details: undefined,
  availabilityModalTitle: undefined,
  data: {},
  stateObject: {},
  setStateObject: {},
  setSubmit: undefined,
  assignemntCount: {},
};
