import styled from "styled-components";
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  background,
  position,
  compose,
  border,
} from "styled-system";
import Theme from "../../styles/Theme";
import { Card, Div, MDLabel } from "../../styles/Styles";

const {
  colors, fontSizes,
} = Theme;

export const CardWrapper = styled(Card)`
  border: ${({ isSelected }) => (isSelected ? `3px solid ${colors.primary}` : "none")};
`;
CardWrapper.defaultProps = {
  borderRadius: "10px",
  boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.07)",
  width: "160px",
  display: "flex",
  justifyContent: "center",
  height: "50px",
};
export const Content = styled(Div)``;

export const Label = styled(MDLabel)`
  font-size: ${({ error }) => (error ? `${fontSizes.small}` : `${fontSizes.regular}`)};
  line-height: 19px;
  display: 'initial';
  color: ${({ error }) => (error ? `${colors.error}` : `${colors.black}`)};
  float: ${({ error }) => (error ? "right" : "start")};
`;

export const Span = styled.span`
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  opacity: ${({ disable, opacity }) => opacity || (disable ? 0.5 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  &:hover {
    text-decoration: ${({ textDecoration, showHoverEffect }) => (showHoverEffect ? "underline" : textDecoration || "none")};
  }
  `;

export const MDImage = styled.img`
${compose(typography, space, border, color, flexbox, layout, background, position)};
  height: ${({ type }) => (type === "calendarCard" ? "35px" : "")};
  cursor: ${({ useCursorPointer, cursor }) => (useCursorPointer ? "pointer" : (cursor || "inherit"))};
`;
