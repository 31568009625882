/**
 * Handles room serialization.
 */
class Room {
  fromRoomDetailsJson = (details) => {
    const {
      name = "",
      room_size: size = "",
      dog_limit: dogsLimit = "",
      profile_pic: profilePic = "",
      services = [],
      organization_id: organisation,
      new_assignment: openOrders = 0,
    } = details;
    const data = {
      name,
      organisation,
      profilePic,
      dogsLimit,
      size,
      openOrders,
    };
    return { data, services };
  };

  /**
   * Returns room details payload of JSON type.
   *
   * @method
   */
  toJson = (obj) => {
    const { profilePic: { id: profilePic = "" } = {} } = obj;
    const data = {
      name: obj.name,
      organization_id: obj.organisation.value,
      profile_pic_id: profilePic,
      dog_limit: obj.dogsLimit,
      room_size: obj.size,
      room_service_list: obj.services,
    };
    return data;
  };
}
export default Room;
