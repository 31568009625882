import React, { useEffect, useState, useCallback  } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import svLocale from "@fullcalendar/core/locales/sv";
import enLocale from "@fullcalendar/core/locales/en-gb";
import luxonPlugin from "@fullcalendar/luxon";
import { DateTime } from "luxon";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment, { now } from "moment";
import "moment/locale/sv";
import PortalContainer from "../../components/Containers/PortalContainer";
import {
  Div,
  Header,
  MDLabel,
  // Span,
  IsMobile,
  IsDesktop,
  MDImage,
  MDLabelWrapper,
} from "../../styles/Styles";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import Constants from "../../shared/Constants";
import Colors from "../../styles/Colors";
import { FilterIcon } from "../../styles/Icons";
import MainLoader from "../../components/Loader/MainLoader";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Plus from "../../assets/Icons/Plus.svg";
import AvailabilityModal from "../../components/AvailabilityModal/AvailabilityModal";
import { saveAvailableDateRange, availabilityDelete } from "../../store/actions/AppAction";
import Config from "../../config";
import SelectBox from "../../components/Input/SelectBox";
import AvailabilityDetailsOld from "../../components/AvailabilityModal/AvailabilityDetailsOld";
import AvailabilityOrderModal from "../../components/AvailabilityModal/AvailabilityOrderModal";
import AvailabilitySlotModal from "../../components/AvailabilityModal/AvailabilitySlotModal";
import DeleteModal from "../../components/AvailabilityModal/DeleteModal";
import DeleteSuccessPopUp from "../../components/AvailabilityModal/DeleteSuccessPopUp";
import * as types from "../../store/actions/types";
import useEventContent from "./useEventContent";
import { formatDate as customFormatDate } from "../../shared/utils";
import useUserPermission from "./useUserPermission";
import useCalendarData from "./useCalendarData";
import FilterModal from "./Filter";
import OrdersModal from "./OrdersModal";

const currentLanguage = Config.language || "sv";
moment.locale("sv");
const LABEL_ALL = "All";
const {
  SUPER_ADMIN,
  input: { format },
  language,
} = Constants;
const initialAvailability = {
  sp_id: "",
  startDate: "",
  endDate: "",
  time: [],
  daysArray: [],
  key: "",
  brake: [],
  recKey: false,
  services: [],
};

const Calendar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [availabileData, setAvailabileData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [isAvailabilityEdit, setiIsAvailabilityEdit] = useState(false);
  const [showAvailabilityOld, setShowAvailabilityOld] = useState(false);
  const [showAvailabilityOrder, setShowAvailabilityOrder] = useState(false);
  const [showAvailabilitySlots, setShowAvailabilitySlots] = useState(false);
  const [isAvailabilityTitle, setIsAvailabilityTitle] = useState(false);
  const slotData = useSelector((state) => state.AppReducer.slotData);
  const [isFetchEdit, setIsFetchEdit] = useState({
    date: "",
    sp_id: "",
    slot_id: "",
  });
  const calendarData = useCalendarData();
  const userPermission = useUserPermission();
  const [isEditSuceessPopup, setIsEditSuccessPopup] = useState(false);
  const [availability, setAvailability] = useState(initialAvailability);
  const deleteAvailabilitydata = useSelector((state) => state.AppReducer.deleteAvailability);
  const userDetail = useSelector((state) => state.AppReducer.userDetails);
  const eventsData = useSelector((state) => state.AppReducer.calendarPayload);
  const organizationData = useSelector((state) => state.AppReducer.organization);
  const serviceProviderData = useSelector((state) => state.AppReducer.serviceProvider);
  const availableDateRange = useSelector((state) => state.AppReducer.availableDateRange);
  const [isLoader, setLoaderState] = useState(false);
  const { user_access: { rights: userType = "" } = {} } = userDetail || {};
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData]= useState("");
  const [orderData, setOrderData] = useState([]);
  const [showOrderModal, setShowOrderModal] = useState(false);

  const serviceProviderOptions =
    selectedOrganization && selectedOrganization.access === Constants.MANAGE
      ? [
        {
          label: <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />,
          value: LABEL_ALL,
          isSelected: false,
        },
        ...serviceProviderData,
      ]
      : serviceProviderData;

  const postDeleteAvailability = (params) => {
    dispatch(availabilityDelete(params));
  };

  const setAvailableDateRange = (params) => {
    dispatch(saveAvailableDateRange(params));
  };
  const { calendar, number, sv, adminRoutes } = Constants;

  const {
    dayGridMonth,
    timeGridDay,
    timeGridWeek,
    twoDigit,
    day,
    title,
    slotDuration,
    dateFormats,
    shortMeridiem,
    scrollTime,
  } = calendar;
  const { apiQueryFormat } = dateFormats;

  const calendarRef = React.createRef();
  const [eventMutatedData, setEventMutatedData] = useState({});
  const [showShow, setShowShow] = useState("");
  const [selectedServiceProvider, setSelectedServiceProvider] = useState("");
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [showAvailabilityRender, setShowAvailabilityRender] = useState(false);
  const [slotDetails, setSlotDetails] = useState([]);

  const [viewType, setViewType] = useState("");

  useEffect(()=>{
    const loadData = async () => {
      setLoaderState(true);
      calendarData.getOrganisations();
      setLoaderState(false);
    };
    loadData();
  },[])

  useEffect(() => {
    const loadData = async () => {
      setLoaderState(true);
      calendarData.getOrganisations();
      setLoaderState(false);
    };
    if (
      userPermission.isCalendarReadable() ||
      userPermission.isCalendarEditable() ||
      userPermission.isCalendarManagable()
    ) {
      if (!organizationData?.length) {
        loadData();
      } else {
        const orgs = organizationData.filter((o) => o.access >= 0 );
        setSelectedOrganization(
          {
            label: orgs[0]?.name,
            value: orgs[0]?.id,
            access: orgs[0]?.access,
            isSelected: true,
          },
        );
      }
    }
  }, [organizationData, userDetail]);

  useEffect(() => {
    const load = async () => {
      setLoaderState(true);
      calendarData.fetchOrdersAndAvailabilities(selectedOrganization);
      window.scrollTo(0,0);
      setLoaderState(false);
    };

    if (selectedOrganization.value && availableDateRange.startDate) {
      load();
    }
  }, [userType, availableDateRange, selectedOrganization]);

  useEffect(async () => {
    const { id } = selectedServiceProvider?.data || {};
    let event;
    setLoaderState(true);
    if (viewType !== dayGridMonth) {
      event = await calendarData.formatDataForWeekAndDayView(showShow.value, id);
      setLoaderState(false);
    } else {
      event = await calendarData.formatDataForMonthView(showShow.value, id);
      setLoaderState(false);
    }
    setEventMutatedData(event);
  }, [eventsData, showShow, selectedServiceProvider]);

  const handleDelete = async () => {
    setIsDelete(!isDelete);
    setShowAvailabilityOrder(false);
  };

  const handleCloseAllPopups = () => {
    setAvailabileData({});
    setShowAvailabilityModal(false);
  };

  const handleClosePopup = useCallback(async () => {
    setIsDeletePopup(!isDeletePopup);
    if (isDeletePopup) {
      calendarData.fetchOrdersAndAvailabilities(selectedOrganization);
    }
  });

  const handleDeleteAvailability = (params) => {
    setLoaderState(true);
    postDeleteAvailability(params);
    setIsDeletePopup(true);
    setLoaderState(false);
  };
  const [showModal, setShowModal] = useState(false);

  const handleRenderAvailability = () => {
    setShowAvailabilityRender(!showAvailabilityRender);
  };

  const handleRenderAvailabilityOld = () => {
    setShowAvailabilityOld(!showAvailabilityOld);
  };

  const handleRenderAvailabilityOrder = () => {
    setShowAvailabilityOrder(!showAvailabilityOrder);
  };

  const handleRenderAvailabilitySlots = () => {
    setShowAvailabilitySlots(!showAvailabilitySlots);
  };

  const handleEventHide = (eventInfo) => {
    eventInfo.view.calendar.scrollToTime(scrollTime);
    const {
      view,
      event: {
        extendedProps: { type: eventType },
      },
    } = eventInfo;

    if (view.type === dayGridMonth) {
      if (!eventType || eventType === "available" || eventType === "order") {
        eventInfo.event.setProp("display", "none");
      }
      if (
        eventInfo.event.extendedProps.type === "adminAvailable" ||
        eventInfo.event.extendedProps.type === "adminOrder"
      ) {
        eventInfo.event.setProp("display", "block");
      }
    }
    // if (view.type === timeGridDay || view.type === timeGridWeek) {
    //   if (view.type === timeGridDay) {
    //     if (!eventType || eventType === 'available' || eventType === 'order') {
    //       eventInfo.event.setProp('display', 'block');
    //     }
    //     if (eventInfo.event.extendedProps.type === 'adminAvailable'
    //  || eventInfo.event.extendedProps.type === 'adminOrder') {
    //       eventInfo.event.setProp('display', 'none');
    //     }
    //   }
  };

  const adminShowEditAvailability = (selectedSlotData = {}, isPastDate) => {
    const { service_provider_slot: { organization_id: orgId = "" } = {} } = selectedSlotData;
    const organisation = organizationData.find((obj) => obj.id === orgId);
    const serviceProvider = serviceProviderData.find(({ id }) => id === selectedSlotData.sp_id) || {};
    const { full_name: serviceProviderName = "" } = serviceProvider;
    setIsAvailabilityTitle(
      organisation.access === Constants.MANAGE
        ? `${serviceProviderName} ${language.label_availability_details}`
        : language.title_edit_availability
    );
    setIsFetchEdit({
      ...isFetchEdit,
      slot_id: selectedSlotData.slot_id,
      sp_id: selectedSlotData.sp_id,
      date: selectedSlotData.date,
    });
    setAvailability({
      ...availability,
      slot_id: selectedSlotData.slot_id,
      date: selectedSlotData.date,
    });
    if (!isPastDate && (organisation.access === Constants.WRITE || organisation.access === Constants.MANAGE)) {
      setiIsAvailabilityEdit(true);
      setShowAvailabilityOrder(true);
    } else {
      setShowAvailabilityOld(true);
    }
  };

  const handleEventClick = (ref) => {
    const onEventClick = (info) => {
      info.jsEvent.preventDefault();
      const { type = "",orderCount = 0, orderData = [], orders = [],  slotDetails: propSlotDetails = [], name="", serviceProviderIds = [] } = info.event.extendedProps;
      const {
        calendar: { available: AVAILABLE, adminAvailable: ADMIN_AVAILABLE },
      } = Constants;
      let details = propSlotDetails;
      if(viewType !== dayGridMonth) {
        details = propSlotDetails.filter((o)=> serviceProviderIds?.includes(o?.sp_id));
      }
      
      if (!type || type === AVAILABLE || type === ADMIN_AVAILABLE) {
        const dateAvailable = customFormatDate(info.event.startStr, format.date);
        const currentDate = moment(now()).format(format.date);
        info.jsEvent.preventDefault();
        setShowAvailabilitySlots(true);
        const availableSlotDetails =
          viewType && viewType !== dayGridMonth ? details : [info.event.extendedProps];
        setSlotDetails({
          data: type === AVAILABLE || name === AVAILABLE ? availableSlotDetails : details,
          isPastDate: !(dateAvailable >= currentDate),
          userType: selectedOrganization.access === Constants.MANAGE ? SUPER_ADMIN : userType,
        });
      } else if(type === "adminOrder") {
        info.jsEvent.preventDefault();
        setShowOrderModal(true);
        setOrderData(orderData);
      } else if (type === "assignment" && orderCount >=3 ){
        info.jsEvent.preventDefault();
        setShowOrderModal(true);
        setOrderData(orders);
      }
      else if (type !== "assignment") {
        info.jsEvent.preventDefault();
        const calendarApi = ref.current.getApi();
        calendarApi.gotoDate(info.event.startStr);
        calendarApi.changeView(timeGridDay);
      }
    };
    return onEventClick;
  };

  const handleCloseOrderModal= useCallback(() =>{
    setShowOrderModal(false);
    setOrderData([]);
  });

  const handleCardClick = useCallback(({ assignmentId = "" }) => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${assignmentId}/${adminRoutes.editAssignment}`);
  });

  const handleDateClick = (ref) => {
    const onDateClick = (info) => {
      const {
        view: { type = "" },
      } = info;
      const calendarApi = ref.current.getApi();
      const { dateStr = "" } = info || {};
      if (!info?.jsEvent?.target?.classList.contains("fc-bg-event")) {
        setAvailabileData({
          date: dateStr,
        });
        if (type === timeGridWeek) {
          info.jsEvent.preventDefault();
          setIsAvailabilityTitle("label_set_availability");
          setShowAvailabilityModal(true);
        } else {
          calendarApi.gotoDate(info.dateStr);
          calendarApi.changeView(timeGridDay);
          if (type === timeGridDay) {
            setIsAvailabilityTitle("label_set_availability");
            setiIsAvailabilityEdit(false);
            setShowAvailabilityModal(true);
          }
        }
      }
    };
    return onDateClick;
  };

  const handleDayCellContent = (info) => {
    const { dayNumberText } = info;
    return dayNumberText.length === 1 ? `0${dayNumberText}` : dayNumberText;
  };

  const handleSlotLabelContent = (info) => {
    const { text } = info;
    const {
      calendar: { fm, em, am, pm },
    } = Constants;
    const meridiemValue = info.text.slice(3) === fm ? text.replace(fm, am) : text.replace(em, pm);
    return meridiemValue.slice(0, 2) === "00" ? meridiemValue.replace("00", "00") : meridiemValue;
  };

  const isMobile = window.screen.width < 992;

  const handleDayHeaderContent = (info) => {
    let str;
    if (info.view.type === timeGridWeek) {
      str = formatDate(info.date, {
        day: twoDigit,
      });
      const dayName = info.text.slice(0, isMobile ? 2 : 3);
      return (
        <Div display="flex" flexDirection={["column","column","row", "row"]} justifyContent="center" pt={1} alignItems="center">
          <span className="fc-week-dayname">{dayName}</span>
          <span className="fc-week-daynumber">{str}</span>
        </Div>
      );
    }
    return str;
  };

  const formatDayTitle = (info) => {
    const { start } = info;
    const dayViewTitle = isMobile ? moment(start.marker).format("DD.MM.YYYY") : moment(start.marker).format(`dddd, [${language.label_of}] DD MMMM`);
    return dayViewTitle;
  };

  const formatWeekTitle = (info) => {
    const { start, end, defaultSeparator } = info;
    const {
      calendar: {
        dateFormats: { dottedMonthDate },
      },
    } = Constants;
    const { year } = end;
    const weekStart = moment(start.marker).format(dottedMonthDate);
    const endOfWeek = moment(start.marker).add(6, "days");
    const weekEnd = moment(endOfWeek).format(dottedMonthDate);
    return `${weekStart} ${defaultSeparator} ${weekEnd}, ${year}`;
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };

  const handleOrderButtonClick = () => {
    history.push({
      pathname: Constants.routes.addOrder.url,
      state: {
        fromPage: Constants.input.name.calendar,
      },
    });
  };
  const handleCloseAvailability = async () => {
    setAvailabileData({});
    calendarData.fetchOrdersAndAvailabilities(selectedOrganization);
    setShowAvailabilityModal(false);
  };

  const handleCloseAvailabilityOld = () => {
    setShowAvailabilityOld(false);
  };

  const handleCloseAvailabilityOrder = async () => {
    setAvailabileData({});
    if (isEditSuceessPopup) {
      calendarData.fetchOrdersAndAvailabilities(selectedOrganization);
    }
    setIsEditSuccessPopup(false);
    setShowAvailabilityOrder(false);
  };

  const handleCloseAvailabilitySlots = () => {
    setShowAvailabilitySlots(false);
  };

  const handleShowSuccessPopUp = () => {
    setShowAvailabilityOrder(false);
    setIsEditSuccessPopup(true);
  };

  const handleAssignmentButtonClick = () => {
    history.push({
      pathname: Constants.routes.addAssignment.url,
      state: {
        fromPage: Constants.input.name.calendar,
      },
    });
  };

  const handleChange = async ({ target }) => {
    if (target.name === "organisation") {
      setSelectedOrganization({
        name: "organisation",
        label: target.value.label,
        value: target.value.value,
        access: target.value.access,
        isSelected: true,
      });
      setShowShow({
        name: "show",
        label: <FormattedMessage id="filter_value_all" defaultMessage="all" />,
        value: LABEL_ALL,
        isSelected: true,
      });
      setSelectedServiceProvider({
        name: "service_providers",
        label: <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />,
        value: LABEL_ALL,
        isSelected: true,
      });
      dispatch({
        type: types.GET_SERVICE_PROVIDER,
        payload: [],
      });
    } else if (target.name === "show") {
      setShowShow({ ...target.value, isSelected: true });
    } else if (target.name === "service_providers") {
      if (target.value.value === LABEL_ALL) {
        setSelectedServiceProvider({
          label: <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />,
          value: LABEL_ALL,
          isSelected: true,
          data: {},
        });
      } else {
        setSelectedServiceProvider({
          name: "service_providers",
          label: target.value.label,
          value: target.value.value,
          data: { id: target?.value?.data?.id },
          isSelected: true,
        });
      }
    }
  };

  const isShowAvailability = selectedOrganization && selectedOrganization.access > Constants.READ;
  const isShowCalendar = isLoader || calendarData.getLoaderState() ? "hide-fc" : "show-fc";

  const handleRefetchEvents = async (fromDate, toDate) => {
    const date = new Date(toDate);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const formattedFirstDay = DateTime.fromISO(firstDay.toISOString()).toFormat(apiQueryFormat);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const formattedLastDay = DateTime.fromISO(lastDay.toISOString()).toFormat(apiQueryFormat);
    setAvailableDateRange({
      startDate: fromDate || formattedFirstDay,
      endDate: toDate || formattedLastDay,
    });
  };
  const handleCalendarData = async (info) => {
    const { start, end } = info;
    const fromDate = DateTime.fromISO(start.toISOString()).toFormat(apiQueryFormat);
    const toDate = DateTime.fromISO(end.toISOString()).toFormat(apiQueryFormat);
    setAvailableDateRange({ startDate: fromDate, endDate: toDate });
    const {
      view: { type = "" },
    } = info;
    const { startDate, endDate } = availableDateRange;
    switch (type) {
    case dayGridMonth:
    case timeGridWeek:
    case timeGridDay:
      {
        const isValidFromDate = fromDate >= startDate && fromDate <= endDate;
        const isValidToDate = toDate >= startDate && toDate <= endDate;
        if (!isValidFromDate || !isValidToDate) {
          handleRefetchEvents(fromDate, toDate);
        }
        setViewType(type);
      }
      break;
    default:
      console.log(type);
    }
    if((type === timeGridWeek || type === timeGridDay) && isMobile) {
      setShowShow({ name: "show", value: filterData?.availabilities ? "availability" : "orders", isSelected: true });
    } else {
      setShowShow({ name: "show", value: LABEL_ALL, isSelected: true });
    }
  };

  const allServiceProviders = serviceProviderOptions.map((serviceProvider) => ({
    label: serviceProvider.full_name || (
      <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />
    ),
    value: serviceProvider.full_name || LABEL_ALL,
    isSelected: false,
    data: serviceProvider,
  }));
  const allOrganizations = organizationData
    ? organizationData
      .filter((o) => o.access >= 0)
      .map((org) => ({
        label: org.name,
        value: org.id,
        isSelected: false,
        access: org.access,
      }))
    : [];
  const allShowOptions = [
    { label: <FormattedMessage id="filter_value_all" defaultMessage="all" />, value: LABEL_ALL, isSelected: false },
    { label: <FormattedMessage id="label_order" defaultMessage="Orders" />, value: "orders", isSelected: false },
    {
      label: <FormattedMessage id="label_availability" defaultMessage="Availability" />,
      value: "availability",
      isSelected: false,
    },
  ];

  const handleApplyFilter = useCallback((data) => () => {
    setFilterData(data);
    const { service_provider: serviceProvider= {}, organization = {}, orders= false }= data;
    setSelectedOrganization({
      name: "organisation",
      label: organization?.label,
      value: organization?.value,
      access: organization?.access,
      isSelected: true,
    });
    if(!serviceProvider) {
      setSelectedServiceProvider({
        name: "service_providers",
        label: <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />,
        value: LABEL_ALL,
        isSelected: true,
      });
    } else {
      setSelectedServiceProvider({
        name: "service_providers",
        label: serviceProvider?.label,
        value: serviceProvider?.value,
        data: { id: serviceProvider?.data?.id },
        isSelected: true,
      });
    }
    setShowShow({ name: "show", value: orders ? "orders": "availability", isSelected: true });
    setShowFilter(false);
  });

  const handleFilter = useCallback(() => {
    setShowFilter(!showFilter);
  });

  const renderEventContent = useEventContent(
    history,
    selectedServiceProvider,
    showShow,
    serviceProviderData,
    userType,
    userDetail,
    selectedOrganization
  );

  const handleAddAvailability = useCallback(() => {
    setShowAvailabilityModal(true);
    setIsAvailabilityTitle("label_set_availability");
    setiIsAvailabilityEdit(false);
  });

  return (
    <PortalContainer type="calendar">
      {showModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="header_new_issue" defaultMessage="New issue" />}
            message={<FormattedMessage id="add_event_message" defaultMessage="Add Event" />}
            orderLabel={<FormattedMessage id="order_label" defaultMessage="Add Order" />}
            assignmentLabel={<FormattedMessage id="assignment_label" defaultMessage="New Assignment" />}
            addNewEventCard
            onOrderClick={handleOrderButtonClick}
            onAssignmentClick={handleAssignmentButtonClick}
            onCancel={handleCancelClick}
          />
        </Modal>
      )}
      {isDelete && (
        <DeleteModal
          renderModal={handleDelete}
          data={availability}
          slotData={slotData?.assignments ? slotData?.assignments : []}
          setAvailability={setAvailability}
          setSubmit={handleDeleteAvailability}
        />
      )}
      {showFilter && (
        <FilterModal
          handleApplyFilter={handleApplyFilter}
          handleClose={handleFilter}
          filterData={filterData}
          allOrgs={allOrganizations}
          allServiceProviders={allServiceProviders}
        />
      )}
      {isDeletePopup && <DeleteSuccessPopUp data={deleteAvailabilitydata} renderModal={handleClosePopup} />}
      {isEditSuceessPopup && (
        <Modal>
          <Card
            header={<FormattedMessage id="common_done" defaultMessage="done" />}
            message="changes saved"
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleCloseAvailabilityOrder}
          />
        </Modal>
      )}
      {showAvailabilitySlots && (
        <AvailabilitySlotModal
          onClose={handleCloseAvailabilitySlots}
          renderAvailability={handleRenderAvailabilitySlots}
          availabilityModalTitle="Availability Details"
          slotDetails={slotDetails}
          onSubmit={adminShowEditAvailability}
          organizationId={selectedOrganization.value}
          hasManageCalendarAccess={selectedOrganization.access === Constants.MANAGE}
        />
      )}
      {showOrderModal && (
        <OrdersModal
          onClose={handleCloseOrderModal}
          onSubmit={handleCardClick}
          orderData={orderData}
        />
      )}
      {showAvailabilityOrder && (
        <AvailabilityOrderModal
          onClose={handleCloseAvailabilityOrder}
          renderAvailability={handleRenderAvailabilityOrder}
          availabilityModalTitle={isAvailabilityTitle}
          data={isFetchEdit}
          availability={availability}
          setAvailability={setAvailability}
          handleDelete={handleDelete}
          showSuccessPopup={handleShowSuccessPopUp}
          organizationId={selectedOrganization.value}
          hasManageCalendarAccess={selectedOrganization.access === Constants.MANAGE}
        />
      )}
      {showAvailabilityOld && (
        <AvailabilityDetailsOld
          onClose={handleCloseAvailabilityOld}
          renderAvailability={handleRenderAvailabilityOld}
          availabilityModalTitle="Availability Details"
          data={isFetchEdit}
          serviceProviders={serviceProviderData}
        />
      )}

      {showAvailabilityModal && (
        <AvailabilityModal
          onSubmit={handleCloseAvailability}
          onClose={handleCloseAllPopups}
          renderAvailability={handleRenderAvailability}
          editAvailability={isAvailabilityEdit}
          availabilityModalTitle={isAvailabilityTitle}
          data={availabileData}
          serviceProviders={serviceProviderData}
          selectedOrganization={selectedOrganization}
        />
      )}
      <Div display="contents">
        <Div display="flex" justifyContent="space-between">
          <Header>
            <FormattedMessage id="common_calendar" defaultMessage="Calendar" />
            -
            <FormattedMessage id="common_all_org" defaultMessage="All organisations" />
          </Header>
          <Div>
            <IsMobile
              display={["flex !important", "flex !important", "flex !important", "none !important"]}
              alignItems="center">
              <MDLabelWrapper height="40px" width="40px" backgroundColor={Colors.White} id="filter-button" onClick={handleFilter}>
                <FilterIcon />
              </MDLabelWrapper>
            </IsMobile>
          </Div>
          {isShowAvailability && (
            <Div>
              <IsMobile
                display={["flex !important", "flex !important", "flex !important", "none !important"]}
                alignItems="center">
                <MDLabelWrapper height="40px" width="40px" ml={2} backgroundColor={Colors.Primary} onClick={handleAddAvailability}>
                  <MDImage src={Plus} alt="icon" height="13px" width="13px" />
                </MDLabelWrapper>
              </IsMobile>
              <IsDesktop display={["none !important", "none !important", "none !important", "flex !important", ]}>
                <Button
                  label={<FormattedMessage id="label_add_available" defaultMessage="Add Availability" />}
                  primary
                  startIcon={PlusIcon}
                  onClick={handleAddAvailability}
                  borderRadius="100px"
                />
              </IsDesktop>
            </Div>
          )}
        </Div>

        <Div
          display={["none", "none","none","flex"]}
          mt={20}
          flexDirection={["column", "column", "column", "row"]}
          alignItems="flex-start">
          <MDLabel fontSize="20px" mr={15} my={15} ml={0} alignSelf="left">
            <FormattedMessage id="label_organisation" defaultMessage="Organisation" />:
          </MDLabel>
          <SelectBox
            value={selectedOrganization}
            onChange={handleChange}
            name="organisation"
            options={allOrganizations}
            placeholder=""
            width="100%"
          />
          <MDLabel fontSize="20px" mr={15} ml={[0, 15]} alignSelf="left" my={15}>
            <FormattedMessage id="label_show" defaultMessage="Show" />:
          </MDLabel>
          <SelectBox
            value={showShow}
            onChange={handleChange}
            name="show"
            options={allShowOptions}
            placeholder={isLoader ? "" : <FormattedMessage id="filter_value_all" defaultMessage="all" />}
            width="100%"
          />
          {selectedOrganization && selectedOrganization.access === Constants.MANAGE && (
            <React.Fragment>
              <MDLabel fontSize="20px" mr={15} my={15} ml={[0, 15]} alignSelf="left">
                SP:{" "}
              </MDLabel>
              <SelectBox
                value={selectedServiceProvider}
                onChange={handleChange}
                name="service_providers"
                options={allServiceProviders}
                placeholder={
                  isLoader ? "" : <FormattedMessage id="filter_value_all_available" defaultMessage="All Available" />
                }
                width="100%"
              />
            </React.Fragment>
          )}
        </Div>
      </Div>
      {(isLoader || calendarData.getLoaderState()) && (
        <Div display="flex" mt={24}>
          <MainLoader type="calendar" />
        </Div>
      )}
      <Div mt={24} className={isShowCalendar}>
        <FullCalendar
          height={viewType && viewType !== dayGridMonth ? 670 : "auto"}
          locale={currentLanguage === sv ? svLocale : enLocale}
          stickyHeaderDates={false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin]}
          initialView={dayGridMonth}
          nowIndicator="true"
          firstDay={number.one}
          eventBorderColor={Colors.White}
          moreLinkContent={(info) => info.shortText}
          moreLinkClick={day}
          eventContent={renderEventContent}
          selectable
          events={eventMutatedData}
          selectOverlap={(event) => event.rendering === "background"}
          eventDidMount={handleEventHide}
          headerToolbar={isMobile ? 
            {
              left: "timeGridDay,timeGridWeek,dayGridMonth",
              right: `prev ${title} next`,
            }
            : {
              right: "timeGridDay,timeGridWeek,dayGridMonth",
              left: `prev ${title} next`,
            }
          }
          slotLabelFormat={[
            {
              hour: twoDigit,
              minute: twoDigit,
              meridiem: shortMeridiem,
              hour12: false,
            },
          ]}
          views={{
            week: {
              titleFormat: formatWeekTitle,
            },
            day: {
              titleFormat: formatDayTitle,
            },
          }}
          slotDuration={slotDuration}
          ref={calendarRef}
          scrollTime={scrollTime}
          dateClick={handleDateClick(calendarRef)}
          eventClick={handleEventClick(calendarRef)}
          dayCellContent={handleDayCellContent}
          slotLabelContent={handleSlotLabelContent}
          dayHeaderContent={handleDayHeaderContent}
          fixedWeekCount={false}
          slotMinTime="00:00:00"
          slotMaxTime="23:59:59"
          datesSet={handleCalendarData}
        />
      </Div>
    </PortalContainer>
  );
};

export default Calendar;
