/* eslint-disable react/prop-types */
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import Constants from "../../../shared/Constants";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import { LabelBox, Division } from "../BookingFlowStyles/BookingFlowStyles";
import { setSelectServiceProvider, emptyBookingFlowValues } from "../../../store/actions/BookingFlowServicesAction";
import {
  Div, InputBox, Box, MDImage, MDLabel, StyledDiv,
} from "../../../styles/Styles";
import {
  mapDogSizeToDuration,
  formatDate,
} from "../../../shared/utils";
import ServiceProviderCard from "../../../components/Card/ProfileCard";

const { icons, input, input: { format } } = Constants;
const {
  name: { serviceProvider },
} = input;

const ServiceProvider = ({ time, timeSlot, handlerNextStep }) => {
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const showPopup = () => {
    setOpenPopup(true);
  };
  const closePopup = () => {
    setOpenPopup(false);
  };

  const {
    filteredService,
    // organisationSchedule,
    calenderDate,
    dateFormatted,
    selectedServiceContainer,
    organisationServiceProvidersService,
  } = useSelector((state) => state.BookingFlowServicesReducer);
  const {
    selectedProvider,
    selectedDate,
    dogSizes: firstSelectDogSize,
  } = selectedServiceContainer;

  const { services } = filteredService && filteredService[0];
  const selectedDogSizeIs = mapDogSizeToDuration(firstSelectDogSize?.dogWeight, services[0], true);

  const startTime = time?.split(" - ")[0];
  const endTime = time?.split(" - ")[1];
  // const [isCounter, setIsCounter] = useState(0);
  const handleProviders = (data, slotId) => () => {
    const startTimeData = timeSlot?.split(" - ")[0];
    const endTimeData = timeSlot?.split(" - ")[1];
    const startTimeDate = `${selectedDate} ${startTimeData}`;
    const endTimeDate = `${selectedDate} ${endTimeData}`;

    const selectedPlaces = {
      serviceProviderId: data?.id,
      providerName: data?.full_name,
      providerFace: data?.file?.url,
      serviceTime: timeSlot,
      providerStartTime: startTimeDate,
      providerEndTime: endTimeDate,
      dogWeight: selectedDogSizeIs,
      slot_id: slotId,
    };
    dispatch(setSelectServiceProvider(selectedPlaces));
    if(selectedProvider?.serviceProviderId && selectedProvider?.serviceProviderId !==  selectedPlaces?.serviceProviderId){
      dispatch(emptyBookingFlowValues("date"));
    }
    handlerNextStep();
  };

  let IsAvailable = false;
  let isCount = 0;
  const ops = organisationServiceProvidersService ? organisationServiceProvidersService.service_provider : "";
  const availableServiceProviders = () => ops ? ops.map(
    (item) => {
      const slotTime = time.split(" - ");
      const formattedDate = formatDate(calenderDate, format.date);
      // const removeAssigned = dateFormatted[formattedDate]
      //   ? dateFormatted[formattedDate].map((obj) => {
      //     console.log(startTime, obj.from, endTime, obj.to, item.id, obj.serviceProviderId);
      //     return (startTime > obj.from
      //   && endTime < obj.to
      //     && item.id === obj.serviceProviderId
      //     );
      //   }) : [];

      const removeAssigned = dateFormatted[formattedDate]
        ? dateFormatted[formattedDate].map((obj) => {
          if (item?.id === obj.serviceProviderId) {
            if ((startTime === obj.from) || (endTime === obj.from)
          || (endTime === obj.to) || (startTime === obj.to)) {
              if ((startTime <= obj.to) || (endTime < obj.to)
            || (endTime <= obj.from) || (startTime < obj.from)) {
                if (((obj.from === endTime) && (obj.from > endTime))
               || ((startTime === obj.to) && (startTime > obj.to))) {
                  return false;
                }
                return true;
              }
              return true;
            }
          }
          return false;
        }) : [];
        // const removeAssignedProvider = organisationSchedule.filter((os) => {
        //   // console.log(item, os, 'checking');
        //   const startPoint = os.start_datetime?.split(' ')[0];
        //   const startPointTime = os.start_datetime?.split(' ')[1];
        //   const endPointTime = os.end_datetime?.split(' ')[1];
        //   // console.log(item, os, startTime, endTime, 'checking');
        //   return (
        //     startPoint === calenderDate
        //       && os.service_provider.id === item.id
        //       && startTime >= startPointTime
        //       && endTime <= endPointTime
        //   );
        // });

      // console.log(removeAssigned, removeAssignedProvider, dateFormatted,
      //  calenderDate, dateFormatted[calenderDate], 'dkjsbckd');
      if (removeAssigned.some((obj) => obj === true)) {
        return <></>;
      }

      const startTimeslot = timeSlot?.split(" - ")[0];
      const slotStartTime = startTimeslot?.split(":")[0] * 60 + startTimeslot?.split(":")[1];
      const endTimeslot = timeSlot?.split(" - ")[1];
      const slotEndTIme = endTimeslot?.split(":")[0] * 60 + endTimeslot?.split(":")[1];
      // eslint-disable-next-line prefer-template
      const startTimeSlotDate = moment(new Date(selectedDate)).format("YYYY-MM-DD") + " " + startTimeslot;
      // eslint-disable-next-line array-callback-return,no-unused-expressions
      item?.available_slots?.length > 0
      // eslint-disable-next-line array-callback-return
      && item?.available_slots?.every((object) => {
        const startTimeAvailable = object.start_time.split(":")[0] * 60 + object.start_time.split(":")[1];
        const endTimeAvailable = object.end_time.split(":")[0] * 60 + object.end_time.split(":")[1];
        if (+startTimeAvailable <= +slotStartTime && +endTimeAvailable >= +slotEndTIme) {
          IsAvailable = true;
          isCount = 1;
          return false;
        }
        IsAvailable = false;
        return true;
      });
      return (
        <>
          {IsAvailable && (
            <Div>
              {item?.available_slots?.length > 0
            && (
              <Division
                type="places"
                mb={1}
                pb={1}
                key={item?.id}
                setBorder={
                  selectedProvider?.providerStartTime === startTimeSlotDate
                  && selectedProvider.serviceProviderId === item?.id
                }
                onClick={handleProviders(item, item?.available_slots?.length > 0
                  && item?.available_slots?.find(
                    (obj) => obj.start_time <= slotTime[0] && obj.end_time >= slotTime[1])?.slot_id)}
              >
                <>
                  <InputBox
                    type="radio"
                    name={serviceProvider}
                    id={item?.id + time}
                    checked={
                      (selectedProvider?.providerStartTime === startTimeSlotDate
                        && selectedProvider.serviceProviderId === item?.id)
                    }
                    hidden
                  />
                  <LabelBox
                    htmlFor={item?.id + time}
                    mb={0}
                    border={
                      (selectedProvider?.providerStartTime === startTimeSlotDate
                        && selectedProvider.serviceProviderId === item?.id)
                        // eslint-disable-next-line prefer-template
                        ? ("3px solid " + Colors.Primary + " !important") : ""
                    }
                  >
                    <MDImage
                      src={item?.file?.url}
                      width={64}
                      height={64}
                      borderRadius={Theme.radii.medium}
                      alt="icon"
                    />
                    <Div alignSelf="center" pl={3} width={255}>
                      <Div display="flex" justifyContent="space-between" alignItems="start" position="relative">
                        <MDLabel mb={1} textAlign="left !important">{item?.full_name}</MDLabel>
                        <MDImage
                          src={icons.QuestionMarkIcon}
                          alt="icon"
                          pr={2}
                          onMouseOver={showPopup}
                          onMouseLeave={closePopup}
                        />
                        {openPopup && (
                          <StyledDiv position="absolute">
                            <ServiceProviderCard serviceProviderCard  serviceProviderDetails={item}/>
                          </StyledDiv>
                        )}
                      </Div>
                      <Box alignItems="center" justifyContent="start !important">
                        <MDImage src={icons.UserSettingsIcon} alt="icon" mr={1} />
                        <MDLabel fontSize={Theme.fontSizes.small} color={Colors.DarkGrey} mt={1}>
                          <FormattedMessage id="service_provider" defaultMessage="Service provider" />
                        </MDLabel>
                      </Box>
                    </Div>
                  </LabelBox>
                </>
              </Division>
            )}
            </Div>
          )}
        </>
      );
    },
  ) : "";
  return (
    <>
      {availableServiceProviders()}
      {isCount <= 0 && (
        <Div pl={2}>
          <FormattedMessage id="services_provider_not_found" defaultMessage="No services provider found" />
        </Div>
      )}
    </>
  );
};

export default memo(ServiceProvider);
