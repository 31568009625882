export const devEndpoint = "https://api.playground.klarna.com";
export const prodEndpoint = "https://api.klarna.com";
export const payEndPoint = process.env.NODE_ENV === "development" ? devEndpoint : prodEndpoint;
// user credentails
export const username = "PK41103_6d24917c14bb";
export const password = "vvjbBAX667B9KRHb";

export const orderPayload = {
  purchase_country: "SE",
  purchase_currency: "SEK",
  locale: "sv-SE",
  order_amount: 50000,
  order_tax_amount: 4545,
  order_lines: [
    {
      type: "physical",
      reference: "19-402-USA",
      name: "Red T-Shirt",
      quantity: 5,
      quantity_unit: "pcs",
      unit_price: 10000,
      tax_rate: 1000,
      total_amount: 50000,
      total_discount_amount: 0,
      total_tax_amount: 4545,
    },
  ],
  merchant_urls: {
    terms: "https://www.example.com/terms.html",
    checkout: "https://www.example.com/checkout.html?order_id={checkout.order.id}",
    confirmation: "https://www.example.com/confirmation.html?order_id={checkout.order.id}",
    push: "https://www.example.com/api/push?order_id={checkout.order.id}",
  },
};
