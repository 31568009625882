import React, { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import { verifyCustomer, getDogBreeds } from "../../../services/CustomerService";
import { orderGuestSubscription, orderSubscription } from "../../../services/SubscriptionService";
import Constants from "../../../shared/Constants";
import { formatDate, isCustomer, isLoggedIn } from "../../../shared/utils";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import Colors from "../../../styles/Colors";
import { Box, Div, MDLabel, P } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import { BoxDivision } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import { MobileDiv } from "../../Payment/Style";
import { Division } from "../../Services/Styles";
import WhiteEmptyFlag from "../../../assets/Icons/WhiteEmptyFlag.svg";
import ArrowLeft from "../../../assets/Icons/PrimaryColorArrow.svg";
import CheckBox from "../../../components/Input/CheckBox";
import { Link } from "../../../styles/Styles";
import config from "../../../config";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const {
  icons,
  images,
  language,
  routes,
  input: { format = {} },
} = Constants;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" />)
    .required(<FormattedMessage id="validation_empty_email_input" />),
  agreeTerms: Yup.boolean().oneOf([true]),
  agreeCreateAccount: Yup.boolean(),
});

const SubscriptionFlowStepFive = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedSubscription = {}, selectedDate, info = {}, selectedServiceLocation = {} } = useSelector(
    (state) => state.SubscriptionBookingFlowReducer
  );

  const { name: subscriptionPackageName = "" } = selectedSubscription;
  const { organisationName = "", address = "" } = selectedServiceLocation;
  const location = {
    name: organisationName,
    address: address,
  };
  const isUserLoggedIn = isLoggedIn() && isCustomer();
  const [dogBreedOptions, setDogBreedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadDogBreed = async () => {
      const response = await getDogBreeds();
      setDogBreedOptions(response);
    };
    loadDogBreed();
  }, []);

  const { dogName = "", dogBreed = "" } = info;
  const selectedDogBreed = dogBreedOptions.find((dog) => dogBreed === dog.value);
  const dogInfo = {
    name: dogName,
    breed: selectedDogBreed?.label,
  };

  const handlePrevPage = () => {
    dispatch(SubscriptionBookingFlowActions.previousStep());
    history.push(routes.chooseSubscriptionDate.url);
  };

  const handleAgreeCreateAccountSelect = (setFieldValue, values) => {
    setFieldValue("agreeCreateAccount", !values.agreeCreateAccount);
  };

  const handleAgreeSelect = (setFieldValue, values) => {
    setFieldValue("agreeTerms", !values.agreeTerms);
  };

  const redirectOnSuccess = (response, agreeCreateAccount) => {
    if (response && response.data) {
      dispatch(SubscriptionBookingFlowActions.nextStep());
      dispatch(SubscriptionBookingFlowActions.setInfo({}));
      history.push(`${routes.subscriptionConfirmation.url}?isChecked=${agreeCreateAccount}`)
      dispatch(SubscriptionBookingFlowActions.resetSubscriptionData());
    }
  };

  const handleApplyNow = (values) => {
    const {
      firstName = "",
      lastName = "",
      telephone = "",
      street = "",
      postcode = "",
      city = "",
      dogName = "",
      moreInformation = "",
      dogBreed = "",
      dogAge = "",
      dogGender = "",
      dog_size_weight  = "",
      dog_size_height= "",
      sterilized = false,
      dogInsured = false,
      isNewToDayCare = true,
      id: dogId = undefined,
    } = info;
    const dogData = {
      id: dogId,
      name: dogName,
      description: moreInformation,
      breed: dogBreed,
      age: dogAge,
      dog_size_weight: dog_size_weight,
      dog_size_height: dog_size_height,
      gender: dogGender,
      isSterilized: sterilized,
      isInsured: dogInsured,
      isNewToDayCare: isNewToDayCare,
    };
    const {email, agreeCreateAccount} = values;
    setIsLoading(true);
    if (isUserLoggedIn) {
      const loggedInUserData = localStorage?.userData ? JSON.parse(localStorage.userData) : {};
      const { customer } = loggedInUserData;
      const { first_name: firstName = "", last_name: lastName = "", phone_number_list: phoneNumbers = [] }= customer;
      const [ phone = "" ] = phoneNumbers;
      const requestData = {
        email: loggedInUserData.email,
        customer: {
          street: street,
          post_code: postcode,
          city: city,
          firstname: firstName,
          lastname: lastName,
          phone_number: phone,
        },
        dog: dogData,
        start_date: formatDate(selectedDate, format.dateWithTime),
        organization_id: selectedServiceLocation.organisationId,
        product_id: selectedSubscription.id,
        subscription_days: [],
      };
      orderSubscription(requestData).then((response) => {
        setIsLoading(false);
        redirectOnSuccess(response, agreeCreateAccount);
      });
    } else {
      const newEmail = email.replace("+", "%2b");
      verifyCustomer(newEmail)
        .then((verifyResponse) => {
          if (verifyResponse && !verifyResponse.customer_register) {
            const requestData = {
              email,
              customer: {
                firstname: firstName,
                lastname: lastName,
                phone_number: telephone,
                street: street,
                post_code: postcode,
                city: city,
              },
              create_user: agreeCreateAccount,
              dog: dogData,
              product_id: selectedSubscription.id,
              subscription_days: [],
              start_date: formatDate(selectedDate, format.dateWithTime),
              organization_id: selectedServiceLocation.organisationId,
            };
            orderGuestSubscription(requestData).then((response) => {
              setIsLoading(false);
              redirectOnSuccess(response, agreeCreateAccount);
            });
          } else {
            redirectToGuestLogIn(email);
          }
        })
        .catch(() => {
          redirectToGuestLogIn(email);
        });
    }
  };

  const redirectToGuestLogIn = (email) => {
    history.push({
      pathname: routes.guestUserLogin.url,
      state: {
        fromPage: "subscription",
        email,
        redirectRoute: routes.subscriptionOrderSummary.url,
      },
    });
  };


  const renderEmailTextBox = (values, handleChange, setFieldValue, handleBlur) => {
    if (!isUserLoggedIn) {
      return (
        <Division mt={4}>
          <Box>
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="your_email" defaultMessage="Your e-mail" />
            </MDLabel>
          </Box>
          <Box my={3}>
            <MDLabel fontSize={Theme.fontSizes.sm}>
              <FormattedMessage
                id="please_enter_your_email"
                defaultMessage="Please, enter your e-mail to apply"
              />
            </MDLabel>
          </Box>
          <TextBox
            width={["100%", "100%", "350px"]}
            onChange={handleChange}
            containerProps="m-auto justify-content-center"
            placeholder={language.label_email_address}
            name="email"
            value={values.email}
            onBlur={handleBlur}
          />
          <Div display="flex" justifyContent="center" mt={1} color="var(--red)">
            <ErrorMessage name="email" />
          </Div>
          <Box pt={[0, 30, 30]} justifyContent={["start !important", "center !important", "center !important"]}>
            <CheckBox
              size="14px"
              isSelected={values.agreeCreateAccount}
              onClick={handleAgreeCreateAccountSelect.bind(null, setFieldValue, values)}
            />
            <Div
              display={["block", "flex", "flex"]}
              mt={[24, 0, 0]}
              fontWeight={Theme.fontWeights.medium}
              fontFamily={Theme.fonts.regular}
            >
              <FormattedMessage id="create_guest_account_agree_text" defaultMessage="I want to create an account to have access to order history" />
            </Div>
          </Box>
          <Box pt={[0, 30, 30]} justifyContent={["start !important", "center !important", "center !important"]}>
            <CheckBox
              size="14px"
              isSelected={values.agreeTerms}
              onClick={handleAgreeSelect.bind(null, setFieldValue, values)}
            />
            <Div display={["block", "flex", "flex"]} mt={[24, 0, 0]} fontWeight={Theme.fontWeights.medium} fontFamily={Theme.fonts.regular}>
              <FormattedMessage id="agree_text_policy" defaultMessage="I have read & agree to MyDogCare's" />
              <Div ml={1}>
                <Link color="black" href={`${config.wpUrl}${Constants.PRIVACY_POLICY}`}
                  target="_blank" textDecoration="none" >
                  <FormattedMessage id="privacy_policy" defaultMessage="Privacy Policy" />
                </Link>
              </Div>
            </Div>
          </Box>
        </Division>
      );
    }
  };

  return (
    <MobileDiv type="widewrapper" className="m-auto">
      <Formik
        initialValues={{
          email: "",
          agreeTerms: false,
          agreeCreateAccount: true,
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, setFieldValue, isValid, dirty, handleBlur }) => {
          return (
            <Form>
              <BoxDivision pt={4} mt={2} mb={4}>
                <MDLabel fontSize={Theme.fontSizes.xl} mb={2} pb={1}>
                  <FormattedMessage id="subscription_details" defaultMessage="Subscription Details" />
                </MDLabel>
              </BoxDivision>
              <BoxDivision flexDirection={["column", "unset", "unset"]} flexWrap="nowrap">
                <Division>
                  <Division type="choose_summary" className="edit_button" mr={3} mb={3} pb={2} height={65}>
                    <CommonCard
                      avtarImg={WhiteEmptyFlag}
                      imgBg={selectedSubscription.iconColor}
                      header={<FormattedMessage id="subscription" defaultMessage="Subscription" />}
                      leftIcon={images.PriceTag}
                      leftContent={subscriptionPackageName}
                      paddingLeft={20}
                      imgHeight={10}
                      imgWidth={20}
                      heightValue={60}
                      msFull="msFull" />
                  </Division>
                  <Division type="choose_summary" className="edit_button" mr={3} mb={3} pb={2} height={65}>
                    <CommonCard
                      avtarImg={icons.DogIcon}
                      header={location.name}
                      leftIcon={icons.ServiceLocation}
                      leftContent={location.address}
                      fontSize={Theme.fontSizes.xs}
                      fontFamily={Theme.fonts.regular}
                      fontWeight={Theme.fontWeights.normal}
                      paddingLeft={10}
                      boxDivType="stepThree" />
                  </Division>
                </Division>
                <Division mb={20}>
                  <Division type="choose_summary" className="edit_button" mr={3} mb={3} pb={2} height={65}>
                    <CommonCard
                      avtarImg={icons.WhitePawIcon}
                      header={dogInfo.name}
                      leftContent={dogInfo.breed}
                      paddingLeft={20}
                      msFull="msFull"
                      heightValue="64px" />
                  </Division>
                  <Division type="choose_summary" className="edit_button" mr={3} pb={2} borderRadius="10px">
                    <Div
                      display="flex"
                      justifyContent="flex-start"
                      height="64px"
                      background={Colors.White}
                      width="100%"
                      borderRadius="10px">
                      <Division display="flex" flexDirection="column" justifyContent="center" ml={10}>
                        <P color={Colors.DarkGrey} fontWeight={Theme.fontWeights.medium} fontSize={Theme.fontSizes.regular}>
                          {language.start_date}
                        </P>
                        <MDLabel mt="-10px" fontSize={Theme.fontSizes.regular}>
                          {formatDate(selectedDate, format.twoDigitDateFormat)}
                        </MDLabel>
                      </Division>
                    </Div>
                  </Division>
                </Division>
              </BoxDivision>
              {renderEmailTextBox(values, handleChange, setFieldValue, handleBlur)}
              <BoxDivision>
                <Box py={4}>
                  <Div mr={2}>
                    <Button
                      secondary
                      startIcon={ArrowLeft}
                      type="button"
                      label={<FormattedMessage id="label_prev" defaultMessage="Prev" />}
                      width={["165px", "165px", "175px"]}
                      onClick={handlePrevPage}
                    />
                  </Div>
                  <Button
                    primary
                    type="submit"
                    label={<FormattedMessage id="choose_and_apply" defaultMessage="Apply Now!" />}
                    width={["166px", "166px", "175px"]}
                    onClick={handleApplyNow.bind(null, values)}
                    disabled={isLoading || (!isUserLoggedIn && (!isValid || !dirty))}
                  />
                </Box>
              </BoxDivision>
            </Form>
          );
        }}
      </Formik>
    </MobileDiv>
  );
};
export default memo(SubscriptionFlowStepFive);
