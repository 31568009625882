import React from "react";
import PropTypes from "prop-types";
import { InputBox, MDLabel } from "../../../styles/Styles";
import { Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import Colors from "../../../styles/Colors";
import { ButtonBox, MobileDiv } from "../Style";

const RadioSelect = (props) => {
  const {
    name,
    leftOnChange,
    leftOnBlur,
    leftDivType,
    leftValue,
    leftId,
    leftChecked,
    leftHtmlFor,
    leftImageSrc,
    leftHeader,
    leftMainType,
    leftImgHeight,
    rightDivType,
    rightValue,
    rightId,
    rightChecked,
    rightHtmlFor,
    rightImageSrc,
    rightHeader,
    rightMainType,
    rightImgHeight,
    rightOnChange,
    rightOnBlur,
    formikValues,
    labelProps,
    leftHeightValue,
    rightHeightValue,
    leftImgWidth,
    rightImgWidth
  } = props;
  const { errors = {}, touched = {} } = formikValues;
  const displayError = errors[name] && touched[name];
  return (
    <>
      <MobileDiv type="subwrapper" justifyContent="space-between">
        <ButtonBox type="mainwrapper" pt={1} className="row m-0" alignItems="center">
          <Division>{labelProps}</Division>
          <Division pt={3} type={rightDivType}>
            {displayError && (
              <MDLabel float="right" color={Colors.Red}>
                {errors[name]}
              </MDLabel>
            )}
          </Division>
        </ButtonBox>
        <ButtonBox type="mainwrapper" pt={1} className="row m-0" alignItems="center" flexWrap="nowrap !important">
          <Division type={leftDivType || "selectSize"} setBorder>
            <InputBox
              type="radio"
              name={name}
              value={leftValue}
              id={leftId}
              onChange={leftOnChange}
              onBlur={leftOnBlur}
              checked={leftChecked}
              formikValues={formikValues}
              hidden
            />
            <CommonCard
              htmlFor={leftHtmlFor}
              avtarImg={leftImageSrc}
              header={leftHeader}
              mainType={leftMainType}
              imgHeight={leftImgHeight || 50}
              imgWidth={leftImgWidth || 50}
              heightValue={leftHeightValue}
              width={!leftMainType && "160px !important"}
            />
          </Division>
          <Division type={rightDivType || "selectSize"} setBorder mr={3}>
            <InputBox
              type="radio"
              name={name}
              value={rightValue}
              id={rightId}
              onChange={rightOnChange}
              onBlur={rightOnBlur}
              checked={rightChecked}
              formikValues={formikValues}
              hidden
            />
            <CommonCard
              htmlFor={rightHtmlFor}
              avtarImg={rightImageSrc}
              header={rightHeader}
              mainType={rightMainType}
              imgHeight={rightImgHeight || 50}
              imgWidth={rightImgWidth || 50}
              heightValue={rightHeightValue}
              width={!rightMainType && "160px !important"}
            />
          </Division>
        </ButtonBox>
      </MobileDiv>
    </>
  );
};
RadioSelect.propTypes = {
  leftOnChange: PropTypes.func,
  leftOnBlur: PropTypes.func,
  rightOnChange: PropTypes.func,
  rightOnBlur: PropTypes.func,
  name: PropTypes.node,
  leftDivType: PropTypes.node,
  leftValue: PropTypes.node,
  leftId: PropTypes.node,
  leftChecked: PropTypes.node,
  leftHtmlFor: PropTypes.node,
  leftImageSrc: PropTypes.node,
  leftHeader: PropTypes.node,
  leftMainType: PropTypes.node,
  leftImgHeight: PropTypes.node,
  rightDivType: PropTypes.node,
  rightValue: PropTypes.node,
  rightId: PropTypes.node,
  rightChecked: PropTypes.node,
  rightHtmlFor: PropTypes.node,
  rightImageSrc: PropTypes.node,
  rightHeader: PropTypes.node,
  rightMainType: PropTypes.node,
  rightImgHeight: PropTypes.node,
  formikValues: PropTypes.node,
  labelProps: PropTypes.node,
  rightHeightValue: PropTypes.string,
  leftHeightValue: PropTypes.string,
  leftImgWidth: PropTypes.string,
  rightImgWidth: PropTypes.string
};
RadioSelect.defaultProps = {
  name: "",
  leftOnChange: () => {},
  leftOnBlur: () => {},
  rightOnChange: () => {},
  rightOnBlur: () => {},
  leftDivType: "",
  leftValue: "",
  leftId: "",
  leftChecked: "",
  leftHtmlFor: "",
  leftImageSrc: "",
  leftHeader: "",
  leftMainType: "",
  leftImgHeight: "",
  rightDivType: "",
  rightValue: "",
  rightId: "",
  rightChecked: "",
  rightHtmlFor: "",
  rightImageSrc: "",
  rightHeader: "",
  rightMainType: "",
  rightImgHeight: "",
  formikValues: "",
  labelProps: "",
  rightHeightValue: "",
  leftHeightValue: "",
  leftImgWidth:"",
  rightImgWidth:""
};

export default RadioSelect;
