import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import LoginContainer from "../../components/Containers/LoginContainer";
import { GreenLogo } from "../../styles/Icons";
import {
  Header, MDInputValue, MDLabel, Div,
} from "../../styles/Styles";
import TextBox from "../../components/Input/TextBox";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import AuthService from "../../services/AuthService";
import Config from "../../config";
import LoginPageLoader from "../../components/Loader/LoginPageLoader";
import { isLoggedIn, isAdmin, isCustomer, redirectToPage } from "../../shared/utils";
import Colors from "../../styles/Colors";
import { setLoginUser } from "../../store/actions/AppAction";
import Theme from "../../styles/Theme";

/**
 * Handles validation of input fields.
 */
const loginSchema = Yup.object().shape({
  /**
   * Email validation
   */
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" defaultMessage="Invalid email" />)
    .required(<FormattedMessage id="validation_empty_email_input" defaultMessage="Enter email" />),
  /**
   * Password validation
   * A valid password must contain atleast
   * one uppercase, one lowercase, one number and one special case character
   * with minimum of 8 digits.
   */
  password: Yup.string()
    .min(8, Constants.language.validation_length_password_input)
    .matches(
      /^.*(?=.{8,})((?=.*[!~@#$%^&*/?()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      Constants.language.validation_valid_password_input,
    )
    .required(Constants.language.validation_empty_password_input),
});

class Login extends Component {
  authService = new AuthService(Config.apiBase);
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inValidDetails: false,
      showPassword: true,
      isEmailVerified: false, 
    };
  }

  /**
   *
   * @returns forgot password page
   */
  handleForgotPassword = () => {
    const { history, isAdmin: isAdminLogin } = this.props;
    history.push({
      pathname: Constants.routes.forgotPassword.url,
      state: {
        isAdmin: isAdminLogin,
      },
    });
  };

  /**
   * If valid credentials, redirects to admin portal.
   * @returns Login to admin portal
   */
  handleSubmit = async (data) => {
    const { routes } = Constants;
    const { history, getLoginUser, userDetails } = this.props;
    this.setState({ loading: true, inValidDetails: false });
    try {
      await getLoginUser(data);
      if (userDetails) {
        const { type = "" } = userDetails;
        if (type === "customer") {
          history.push({ pathname: routes.myProfile.url });
        } 
        this.setState({ loading: false });
      }
    } catch (e) {
      const { response: { data: responsedata = "", status = 0 } = {} } = e;
      const { message = "" } = responsedata;
      const isEmailVerified = status === 403 && message === "Email not yet verified"
      this.setState({ loading: false, inValidDetails: status === 401, isEmailVerified });
    }
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  handleRegister = () => {
    const { history } = this.props;
    history.push(Constants.routes.registerCustomer.url);
  }

  render() {
    const {
      input: { name: inputNames, type: inputType }, icons: eyeIcons,
    } = Constants;
    const { userDetails }= this.props;
    if (isLoggedIn()) {
      let path = Constants.routes.logIn.url;
      if (isAdmin()) {
        path = redirectToPage(userDetails);
      }
      if (isCustomer()) {
        path = Constants.routes.myProfile.url;
      }

      return (
        <Redirect
          to={{
            pathName: path,
          }}
        />
      );
    }
    const { loading, inValidDetails, showPassword, isEmailVerified } = this.state;
    const { isAdmin: isAdminPortal } = this.props;

    return (
      <LoginContainer>
        {loading && <LoginPageLoader />}
        {!loading && (
          <>
            {
              isAdminPortal
              && <GreenLogo />
            }
            <Header mt={4} justifyContent="center">
              {isAdminPortal ? <FormattedMessage id="common_login" defaultMessage="Login" /> : <FormattedMessage id="common_log_in" defaultMessage="Log in" />}
            </Header>
            {inValidDetails && (
              <MDLabel color={`${Colors.Red} !important`} my={2} justifyContent="center">
                <FormattedMessage id="error_invalid_login_details" defaultMessage="Invalid login details" />
              </MDLabel>
            )}
            {isEmailVerified && (
              <MDLabel color={`${Colors.Red} !important`} my={2} justifyContent="center">
                <FormattedMessage id="login_verify_email_address" defaultMessage="Verify the email address. Please check the email sent you" />
              </MDLabel>
            )}
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={loginSchema}
              onSubmit={this.handleSubmit}
            >
              {(props) => {
                const {
                  values, handleChange, handleBlur, handleSubmit, isValid, touched,
                } = props;
                return (
                  <Form>
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      width="300px"
                      label={`${Constants.language.login_label_email}:`}
                      placeholder={Constants.language.placeholder_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name={inputNames.email}
                      formikValues={props}
                      login
                    />
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      width="300px"
                      label={`${Constants.language.login_label_password}:`}
                      placeholder={Constants.language.placeholder_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name={inputNames.password}
                      formikValues={props}
                      type={showPassword ? inputType.password : inputType.text}
                      login
                      endIcon={showPassword ? eyeIcons.EyeOpenIcon : eyeIcons.EyeCloseIcon}
                      onEndIconClick={this.togglePassword}
                    />
                    <MDInputValue
                      size="14"
                      my={3}
                      textDecoration="underline"
                      placeholder
                      justifyContent="center"
                      onClick={this.handleForgotPassword}
                      cursor="pointer"
                    >
                      <FormattedMessage id="common_forgot_password" defaultMessage="Forgot Password" />
                      ?
                    </MDInputValue>
                    <Button
                      primary
                      label={Constants.language.login_submit_button_log_in}
                      width="200px"
                      onClick={handleSubmit}
                      disable={!(isValid && Object.keys(touched).length > 0)}
                    />
                  </Form>
                );
              }}
            </Formik>
            {!isAdminPortal
              && (
                <Div mt={4}>
                  <Div className="border-line" />
                  <MDLabel mb={3} fontSize={Theme.fontSizes.medium} justifyContent="center">
                    <FormattedMessage id="subline_not_registered" defaultMessage="Not registered yet?" />
                  </MDLabel>
                  <MDInputValue placeholder m={3} py={1} px={4} fontSize={Theme.fontSizes.regular} justifyContent="center">
                    <FormattedMessage id="byline_not_registered" defaultMessage="Start using all preferences of MyDogCare user!" />
                  </MDInputValue>
                  <Button
                    secondary
                    label={Constants.language.label_register}
                    width="200px"
                    mr={3}
                    onClick={this.handleRegister}
                  />
                </Div>
              )}
          </>
        )}
      </LoginContainer>
    );
  }
}
Login.propTypes = {
  history: PropTypes.node,
  getLoginUser: PropTypes.func,
  userDetails: PropTypes.node,
  isAdmin: PropTypes.bool,
};
Login.defaultProps = {
  history: undefined,
  getLoginUser: () => {},
  userDetails: {},
  isAdmin: false,
};
function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (data) => dispatch(setLoginUser(data)),
  };
}
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
  userToken: state.AppReducer.userToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
