import moment from "moment-timezone";
import Constants from "../shared/Constants";

const {
  input: {
    format: { date: yearToDay, dateWithTime },
  },
} = Constants;

export const fromListJson = (values) => {
  const { data: list = [], meta } = values;
  const listData = list.map((obj) => {
    const data = obj;
    data.room = [];
    data.service = [];
    data.serviceProvider = [];
    data.organization = [];
    data.dateTime = [];
    const { assignments = [] } = data;
    assignments.map((assignment) => {
      const {
        room_id: room = "",
        service_id: service = "",
        service_provider_id: serviceProvider = "",
        organization = "",
        assignment_start_datetime: date = "",
      } = assignment;
      data.room.push(room);
      data.service.push(service);
      data.serviceProvider.push(serviceProvider);
      data.organization.push(organization);
      data.dateTime.push(moment.utc(date).local());
      return assignment;
    });
    return data;
  });
  return { data: listData, meta };
};
export const toJson = ({ payload, duration = 0, total = 0 }, edit = false) => {
  const formPayload = {};
  formPayload.assignment_list = payload.map((obj) => {
    const {
      room: { value: roomId = "" } = {},
      place: { value: placeId = "" } = {},
      service: { value: serviceId = "", is_full_day_service : isFullDayService = false,  } = {},
      client: { value: customerId = "" } = {},
      serviceProvider: { value: serviceProviderId = "" } = {},
      dog: { value: dogId = "" } = {},
      time: { date = "", from = "", slotId = "" } = {},
      description = [],
      id = "",
      startEndDate: { date: startDate = "", endDate = "", slotId: fullDaySlotId = "" } = {},
    } = obj;
    const descriptionId = description && description.map((o) => o.value).join(",");
    const formattedDate = moment(date).format(yearToDay);
    const assignmentDatetime = moment(`${formattedDate} ${from.split(" -")[0]}`);
    let data = {
      room_id: roomId,
      organization_id: placeId,
      service_id: serviceId,
      customer_id: customerId,
      service_provider_id: serviceProviderId,
      dog_id: dogId,
      assignment_datetime: moment.utc(assignmentDatetime).format(dateWithTime),
      add_ons: descriptionId || "",
      slot_id: slotId,
      is_full_day_service : isFullDayService,
    };
    if(isFullDayService) {
      const getStartDate = moment(`${moment(startDate).format(yearToDay)} 08:00`);
      const getEndDate = moment(`${moment(endDate).format(yearToDay)} 22:00`);
      data.is_full_day_service = true;
      data.start_date = moment.utc(getStartDate).format(dateWithTime);
      data.end_date = moment.utc(getEndDate).format(dateWithTime);
      data.assignment_datetime = moment.utc(getStartDate).format(dateWithTime);
      data.slot_id = fullDaySlotId;
    }
    if (edit) {
      data.id = id;
    }
    return data;
  });
  if (!edit) {
    formPayload.customer_id = formPayload.assignment_list && formPayload.assignment_list[0].customer_id;
    formPayload.total_duration = duration;
    formPayload.order_total = total;
  }
  return formPayload;
};

export const parseData = (obj = {}) => {
  const { name, full_name: fullName = "", first_name: firstName = "", profile_pic: profilePic = {}, id = "" } =
    obj || {};
  const { url = "" } = profilePic || {};
  const data = {
    label: name || fullName || firstName || "",
    value: id || "",
    profilePic: url || "",
  };
  return data || "";
};

export const fromOrderDetailsJson = (obj) => {
  const clientDogDetails = { client: "", dogs: [] };
  const {
    assignments = [],
    status,
    order_number: orderNo = "",
    customer: client = {},
    payment = [],
    order_items: orderItems = [],
  } = obj;
  clientDogDetails.client = client || "";
  assignments.map((asg) => {
    const { dog_id: dog = {} } = asg;
    const { dogs = [] } = clientDogDetails;

    if (dog && !dogs.find((o) => o.id === dog.id)) {
      clientDogDetails.dogs.push(dog);
    }
    return asg;
  });
  return {
    status,
    orderNo,
    clientDogDetails,
    assignments,
    payment,
    orderItems,
  };
};

export const toCreateOrderJson = (payload) => {
  let duration = 0;
  let total = 0;
  const { customer = {} } = localStorage?.userData ? JSON.parse(localStorage?.userData) : {};

  const { id: customerId = "", firstname: firstName = "", lastname: lastName = "", phone_number: telephone = "" } =
    customer || {};
  const assignmentList = payload.map((obj) => {
    const {
      room: { value: roomId = "" } = {},
      service_location: { organisationId: placeId = "" } = {},
      service: { serviceId = "", is_full_day_service: isFullDayService = false } = {},
      service_provider: { serviceProviderId = "" } = {},
      service_provider: { slot_id: slotId = "" } = {},
      service_detail: { dogId = "", id: newDogId = "", price = 0, duration: serviceDuration = 0, dogWeight = "" } = {},
      service_schedule: { scheduled_on: date = [], scheduled_at: from = "" } = {},
      additional_service: description = {},
    } = obj;
    let totalPrice = price;
    let totalAddOnsPrice = 0;
    if (description) {
      description?.forEach((des) => {
        const { price: desPrice = 0 } = des;
        totalAddOnsPrice += Number(desPrice);
      });
      totalPrice = Number(totalPrice) + Number(totalAddOnsPrice);
    }
    total += totalPrice;
    duration += serviceDuration;
    const descriptionId =
      description &&
      Object.keys(description)
        ?.map((key) => {
          const addons = description[key];
          return addons.id;
        })
        .join(",");
    const formattedDate = isFullDayService && Array.isArray(date) ? moment(date[0]).format(yearToDay) : moment(date).format(yearToDay);
    const endDate = isFullDayService && Array.isArray(date) ? moment(date[1]).format(yearToDay) : moment(date).format(yearToDay);
    const assignmentDatetime = moment(`${formattedDate} ${from.split(" -")[0]}`);
    const endDatetime = moment(`${endDate} 22:00`);
    let data = {
      room_id: roomId,
      organization_id: placeId,
      service_id: serviceId,
      customer_id: customerId,
      service_provider_id: serviceProviderId,
      dog_id: newDogId || dogId || "",
      slot_id: slotId,
      assignment_datetime: moment.utc(assignmentDatetime).format(dateWithTime),
      add_ons: descriptionId || "",
      is_full_day_service: isFullDayService,
    };
    if (isFullDayService) {
      data = { ...data, start_date: assignmentDatetime, end_date: `${moment.utc(endDatetime).format(dateWithTime)}` };
    }
    if (!dogId && dogId === "") {
      data = { ...data, dog_size_weight: dogWeight };
    }
    return data;
  });
  return {
    assignment_list: assignmentList,
    customer_id: customerId,
    total_duration: duration,
    order_total: total,
    firstname: firstName,
    lastname: lastName,
    phone_number: telephone,
  };
};
