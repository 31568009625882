import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Button from "../Button/Button";
import {
  Card as CardWrapper,
  CardHeader,
  Div,
  MDLabel,
  P,
  MDImage,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import Theme from "../../styles/Theme";
import Loader from "../Loader/Loader";

/**
 * Card component
 */
const Card = ({
  header,
  message,
  confirmationCard,
  alertCard,
  addNewEventCard,
  onClick,
  onOrderClick,
  onAssignmentClick,
  buttonLabel,
  orderLabel,
  assignmentLabel,
  isPopOver,
  buttonIcon,
  onCancel,
  duration,
  price,
  isDisabled,
  onLoader,
  orderButtonIcon,
  cancelButtonLabel,
  cardWidth,
  buttonWidth,
  buttonPadding,
  alertButtonWidth = "162px"
}) => (
  <CardWrapper popover={isPopOver} addNewEventCard={addNewEventCard} width={cardWidth}>
    <CardHeader>
      <MDLabel alignSelf="center" fontSize={addNewEventCard ? `${Theme.fontSizes.xl}` : `${Theme.fontSizes.medium}`}>
        {header}
      </MDLabel>
    </CardHeader>
    <Div p={3}>
      <Div p={2}>
        <P m={0} textAlign="center" fontSize={addNewEventCard ? `${Theme.fontSizes.small}` : ""}>
          {!onLoader && message}
          {onLoader && <Loader />}
        </P>
        {isPopOver && (
          <P mb={0} mt={4} display="flex" justifyContent="center">
            <MDImage src={Constants.icons.ClockIcon} alt="icon" className="pr-2" />
            <MDLabel className="pr-3" fontSize="16px">
              <span className="disabled-state">
                <FormattedMessage id="common_from" />
              </span>
              &nbsp;
              {duration}
            </MDLabel>
            <MDLabel className="pl-3" fontSize="16px">
              <MDImage src={Constants.icons.PriceTag} alt="icon" className="pr-2" />
              <span className="disabled-state">
                <FormattedMessage id="common_from" />
              </span>
              &nbsp;
              {price}
            </MDLabel>
          </P>
        )}
        {confirmationCard && !alertCard && (
          <P mb={0} mt={4} display="flex" justifyContent="center">
            <Button label="Cancel" secondary className="mr-lg-3 mr-sm-1" width="162px" onClick={onCancel} disabled={onLoader} />
            <Button padding={buttonPadding} className="ml-lg-3 ml-sm-2" width="162px" disabled={isDisabled || onLoader} onClick={onClick} label={buttonLabel} startIcon={buttonIcon} primary />
          </P>
        )}
        {alertCard && !confirmationCard && (
          <P mb={0} mt={4} display="flex" justifyContent="center">
            <Button width={alertButtonWidth} onClick={onClick} label={buttonLabel} primary />
          </P>
        )}
        {addNewEventCard && !confirmationCard && !alertCard && !onLoader && (
          <>
            <P mb={3} mt={3} pb={1} display="flex" justifyContent="center">
              <Button width={buttonWidth || "55%"} onClick={onOrderClick} label={orderLabel} startIcon={orderButtonIcon} primary />
            </P>
            <P mb={3} mt={3} pb={1} display="flex" justifyContent="center">
              <Button
                width={buttonWidth || "55%"}
                onClick={onAssignmentClick}
                label={assignmentLabel}
                startIcon={buttonIcon}
                primary
              />
            </P>
            <P mb={3} mt={3} pb={1} display="flex" justifyContent="center">
              <Button label={cancelButtonLabel} secondary width={buttonWidth || "55%"} onClick={onCancel} />
            </P>
          </>
        )}
      </Div>
    </Div>
  </CardWrapper>
);
Card.propTypes = {
  /**
   * What should be the header of the card
   */
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * What should be in the body part
   */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Is this a desicion making card?
   */
  confirmationCard: PropTypes.bool,
  /**
   * Is this a confirmation card?
   */
  alertCard: PropTypes.bool,
  /**
   * Is this a add new event card?
   */
  addNewEventCard: PropTypes.bool,
  /**
   * What should be the name on the button
   */
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * What should be the name on the add order button
   */
  orderLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * What should be the name on the add assignment button
   */
  assignmentLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional click event
   */
  onClick: PropTypes.func,
  /**
   * Order click event
   */
  onOrderClick: PropTypes.func,
  /**
   * Assignment click event
   */
  onAssignmentClick: PropTypes.func,
  /**
   * Cancel function handler
   */
  onCancel: PropTypes.func,
  /**
   * Is popover...
   */
  isPopOver: PropTypes.bool,
  /**
   * Need any icon before the button
   */
  buttonIcon: PropTypes.node,
  duration: PropTypes.number,
  price: PropTypes.number,
  /**
   * Disable for button - like banuser
   */
  isDisabled: PropTypes.bool,
  onLoader: PropTypes.bool,
  orderButtonIcon: PropTypes.node,
  cancelButtonLabel: PropTypes.string,
  cardWidth: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonPadding: PropTypes.string,
  alertButtonWidth: PropTypes.string
};
Card.defaultProps = {
  header: null,
  message: null,
  confirmationCard: false,
  alertCard: false,
  addNewEventCard: false,
  buttonLabel: "Ok",
  orderLabel: "Ok",
  assignmentLabel: "Ok",
  onClick: undefined,
  onOrderClick: undefined,
  onAssignmentClick: undefined,
  onCancel: undefined,
  isPopOver: false,
  buttonIcon: null,
  duration: "",
  price: "",
  isDisabled: false,
  onLoader: false,
  orderButtonIcon: undefined,
  cancelButtonLabel: "Cancel",
  cardWidth: "340px",
  buttonWidth: "55%",
  buttonPadding: null,
};
export default Card;
