import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import SelectBox from "../../../components/Input/SelectBox";
import {
  MDLabel, Div, IsDesktop, IsMobile, FormWrapper, MDImage, Span,
} from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import CheckBox from "../../../components/Input/CheckBox";
import ServiceCard from "../../../components/Card/ProfileCard";
import ServiceList from "../../../services/ServicesPageService";
import Config from "../../../config";
import UploadPicture from "../../../components/UploadSection/UploadPicture";
import FormLoader from "../../../components/Loader/FormLoader";
import Popup from "../../Common/ServicesModal";
import Modal from "../../../components/Modal/Modal";
import { getServices } from "../../../services/OrdersService";
import { ModulePermissions, getAssociatedOrganizations } from "../../../shared/usePermissions";

const {
  language,
  input: { name: inputNames, type: inputTypes },
  icons,
} = Constants;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(language.validation_room_name),
  size: Yup.number().required(language.validation_room_size)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  services: Yup.array().min(1, language.validation_select_services)
    .required(language.validation_select_services),
  dogsLimit: Yup.number().required(language.validation_room_limit)
    .test(
      "Is positive?",
      language.validation_invalid_value,
      (value) => value > 0,
    ),
  profilePic: Yup.object().required(language.validation_profile_picture),
  organisation: Yup.object().required(language.validation_select_organisations),
});
class CreateRoomForm extends Component {

    serviceList = new ServiceList(Config.apiBase);

    constructor(props) {
      super(props);

      this.state = {
        loading: false,
        services: [],
        organisations: [],
        servicesSelected: [],
        isTouched: false,
        data: {
          name: "",
          size: "",
          dogsLimit: "",
          organisation: "",
          profilePic: "",
          services: [],
        },
        showModal: false,
        selectedOrgId: "",
        selectedServices: [],
        hasNoWriteAccess: false,
      };
    }

    componentDidMount = async () => {
      this.setState({ loading: true });
      await this.fetchOrganisations();
      const { edit } = this.props;
      if (edit) {
        await this.handleFormatData();
      }
      this.setState({ loading: false });
    };

    handleServices = () => {
      let { servicesSelected, services, selectedServices } = this.state;
      services = services.map((obj) => {
        const service = obj;
        if (selectedServices.includes(service.value)) {
          service.isSelected = true;
          servicesSelected.push(service);
          return {...service, isSelected : true};
        }
        return service;
      });
      this.setState({ servicesSelected ,services});
    };

    componentDidUpdate = async (prevProps, prevState) => {
      const { userDetails = {} } = this.props;
      const { selectedOrgId, selectedServices, edit }= this.state;
      if(prevState.selectedOrgId !== selectedOrgId ) {
        await this.fetchServices();
      }
      if( prevState.selectedServices !== selectedServices) {
        this.handleServices();
      }
      if( prevProps.userDetails !== userDetails) {
        this.fetchOrganisations();
        if(edit) {
          this.handleFormatData();
        }
      }
    }

    handleOrgChange = (onChange) =>(e) =>{
      onChange(e);
      const { value: {id = "" }}= e.target;
      this.setState({ selectedOrgId:id })
    }

    handleFormatData=() => {
      const { roomData } = this.props;
      const { data = {}, services: selectedServices = [] } = roomData;
      const { organisations } = this.state;
      const { organisation: { id : orgIdFromJson = "" } = {} }= data;
      this.setState({ selectedOrgId : orgIdFromJson });
      if(organisations.find((o)=>( o.value === data.organisation?.id ))){
        this.setState({ hasNoWriteAccess : false });
        organisations.map((org) => {
          if (org.value === data.organisation?.id) {
            data.organisation = { ...org, isSelected: true };
            return { ...org, isSelected: true };
          }
          return { ...org };
        });
      } else {
        this.setState({ hasNoWriteAccess : true });
        const { userDetails = {} } = this.props;
        const { permissions = [] } = userDetails.permissions
          ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};

        permissions.map((obj) => {
          if (obj.organization_id === data.organisation) {
            data.organisation=
            {
              label: obj.organization_name,
              value: obj.organization_id,
              id: obj.organization_id,
              isSelected:true,
            };
          }
        });
      }
      this.setState({
        data, organisations, selectedServices: selectedServices
      });
    }

    fetchOrganisations = async () => {
      const { userDetails = {} } = this.props;
      const { permissions = [], organization: { organization_details: allOrganisations = [] } = {} } = userDetails.permissions
        ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
      const organisations = getAssociatedOrganizations(ModulePermissions.Room, Constants.WRITE, permissions, allOrganisations);
      this.setState({ organisations });
    };

    fetchServices = async ( ) => {
      let { services, selectedOrgId } = this.state;
      const { edit } = this.props;
      try {
        const data = await getServices(selectedOrgId);
        if (data) {
          services = data.map((obj) => {
            const {
              name = "", id = "", description = "", duration: { small: smallDuration = "" }, price: { small: smallPrice = "" },
              profile_pic: profilePic = {},
            } = obj;
            const { url = "" } = profilePic || {};
            const service = {
              label: name,
              value: id,
              id,
              description,
              duration: smallDuration,
              price: smallPrice,
              profilePic: url,
            };
            return (service);
          });
          this.setState({ services });
          if(edit){
            this.handleServices();
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    handleSelectedServices = (service, onChange) => () => {
      const { services, selectedServices: currentServices } = this.state;
      const { edit } = this.props;
      let selectedServices = [];
      let { servicesSelected } = this.state;
      if (edit) {
        selectedServices = service;
        servicesSelected = service.filter((obj) => obj.isSelected);
      } else {
        selectedServices = services.map((obj) => {
          const src = obj;
          if (obj.id === service.id) {
            src.isSelected = src.isSelected ? !src.isSelected : true;
            servicesSelected.push(src);
            return { ...src };
          }
          return { ...obj };
        });
      }
      onChange({ target: { name: inputNames.services, value: servicesSelected } });
      this.setState({
        services: selectedServices, servicesSelected, isTouched: true, showModal: false,
        selectedServices: currentServices.push(service.id)
      });
    }

    handleServiceModal=() => {
      this.setState({ showModal: true }, this.openElement.click());
    }

    handleCloseModal= () => {
      this.setState({ showModal: false });
    }

    handleOnBlur=() => {
      this.setState({ isTouched: true });
    }

    handleSubmit=(values) => {
      const data = values;
      const { submitData } = this.props;
      const { services } = this.state;
      data.services = [];
      services.map((obj) => {
        if (obj && obj.isSelected) {
          data.services.push(obj.value);
        }
        return obj;
      });
      submitData(data);
    }

    handleReset = () => {
      const { edit, onCancel } = this.props;
      if (edit) {
        onCancel();
      } else {
        let {
          organisations, services,
        } = this.state;
        organisations = organisations.map((o) => ({ ...o, isSelected: false }));
        services = services.map((o) => ({ ...o, isSelected: false }));
        this.setState({
          isTouched: false,
          organisations,
          services,
        });
      }
    }

    render() {
      const {
        data, organisations, services, loading, isTouched, servicesSelected, showModal,
        hasNoWriteAccess,
      } = this.state;
      const { deleteRoom, edit } = this.props;
      const selectedServices = edit ? services.filter((o) => o.isSelected) : [];
      return (
        <>
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={data}
            validationSchema={ValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {(props) => {
              const {
                values, handleChange, handleBlur, handleSubmit,
                isValid, touched, handleReset, errors,
              } = props;
              values.services = servicesSelected;
              const isDisabled = (isValid && servicesSelected.length > 0)
                && (Object.keys(touched).length > 0 || isTouched);
              return (
                <>
                  {loading && (
                    <FormWrapper>
                      <FormLoader />
                    </FormWrapper>
                  )}
                  {!loading && (
                    <>
                      <FormWrapper>
                        <UploadPicture
                          label={language.label_room_picture}
                          value={values.profilePic}
                          name={inputNames.profilePic}
                          files={values.profilePic}
                          formikValues={props}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={hasNoWriteAccess}
                        />
                      </FormWrapper>
                      <Form>
                        <FormWrapper>
                          <SelectBox
                            startIcon={icons.HomeIcon}
                            containerProps="m-auto justify-content-center"
                            label={language.label_organisation}
                            placeholder={language.placeholder_choose}
                            onChange={this.handleOrgChange(handleChange)}
                            onBlur={this.handleOnBlur}
                            value={values.organisation}
                            name={inputNames.organisation}
                            formikValues={props}
                            options={organisations}
                            disabled={hasNoWriteAccess}
                          />
                          <TextBox
                            startIcon={icons.CubeIcon}
                            containerProps="m-auto justify-content-center"
                            label={language.label_room_name}
                            placeholder={language.label_room_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            name={inputNames.name}
                            formikValues={props}
                            IconHeight="20px"
                            disabled={hasNoWriteAccess}
                          />
                          <Div className="row m-0" width={1} display="flex" justifyContent="space-between">
                            <TextBox
                              startIcon={icons.CubeIcon}
                              label={language.label_size}
                              superScript
                              placeholder={language.placeholder_size}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.size}
                              name={inputNames.size}
                              type={inputTypes.number}
                              formikValues={props}
                              IconHeight="20px"
                              disabled={hasNoWriteAccess}
                              width="48%"
                            />
                            <TextBox
                              startIcon={icons.PawIcon}
                              label={language.label_dogs_limit}
                              placeholder={language.placeholder_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.dogsLimit}
                              type={inputTypes.number}
                              name={inputNames.dogsLimit}
                              formikValues={props}
                              IconHeight="20px"
                              disabled={hasNoWriteAccess}
                              width="48%"
                            />
                          </Div>
                        </FormWrapper>
                        <MDLabel fontSize={Theme.space.m} className="py-2" lineHeight={Theme.lineHeights.tight}>
                          <FormattedMessage id="user_choose_services" />
                        </MDLabel>

                        <MDLabel fontFamily={Theme.fonts.regular} textAlign="start">
                          <FormattedMessage
                            id="room_choose_services_description"
                            defaultMessage="Choose here services, which can be provided by this room"
                          />
                        </MDLabel>
                        {errors.services && touched.services && (
                          <MDLabel color={Theme.colors.error} pt={3}>
                            {errors.services}
                          </MDLabel>
                        )}
                        <Div mt={3} mb={2} className="row m-0">
                          {edit
                            ? selectedServices.map((service) => (
                              <Div display="inline-flex" className="col-lg-4 col-md-12 p-0" my={2}>
                                <ServiceCard
                                  serviceCard
                                  userAvtar={service.profilePic}
                                  data={{ ...service, isSelected: false }}
                                />
                              </Div>
                            ))
                            : services.map((service) => (
                              <Div display="inline-flex" my={2} className="col-lg-4 col-md-12 p-0">
                                <CheckBox
                                  primary
                                  label={<ServiceCard serviceCard data={service} userAvtar={service.profilePic} />}
                                  isSelected={service.isSelected ? service.isSelected : false}
                                  inputProps="py-2"
                                  onClick={this.handleSelectedServices(service, handleChange)}
                                  disabled={hasNoWriteAccess}
                                />
                              </Div>
                            ))}
                        </Div>
                        {edit && (
                          <MDLabel onClick={this.handleServiceModal} mb={4} disable={hasNoWriteAccess}>
                            <MDImage src={icons.EditIcon} alt="icon" mr={2} role="button" />
                            <Span color={Theme.colors.primary} textDecoration="underline" useCursorPointer disable={hasNoWriteAccess}>
                              <FormattedMessage id="label_edit_list_services" defaultMessage="Edit list of services" />
                            </Span>
                          </MDLabel>
                        )}
                        <IsDesktop className="d-lg-flex mt-3">
                          <Button
                            primary
                            type="submit"
                            startIcon={icons.AssignmentsMenuIcon}
                            IconHeight="18px"
                            label={<FormattedMessage id="common_save" />}
                            onClick={handleSubmit}
                            disable={!isDisabled}
                            width="200px"
                            className="mr-3"
                            disabled={hasNoWriteAccess}
                          />
                          <Button
                            secondary
                            label={<FormattedMessage id="common_cancel" />}
                            type="reset"
                            onClick={() => { handleReset(); this.handleReset(); }}
                            width="200px"
                            className="mx-3"
                            disabled={hasNoWriteAccess}
                          />
                          {edit && (
                            <Button
                              primary
                              type="button"
                              startIcon={icons.DeleteIcon}
                              IconHeight="18px"
                              label={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
                              onClick={deleteRoom}
                              width="200px"
                              className="ml-4"
                              disabled={hasNoWriteAccess}
                            />
                          )}
                        </IsDesktop>
                        <IsMobile className="mt-3">
                          <Button
                            primary
                            type="submit"
                            startIcon={icons.AssignmentsMenuIcon}
                            IconHeight="18px"
                            label={<FormattedMessage id="common_save" />}
                            onClick={handleSubmit}
                            disable={!isDisabled}
                            width="100%"
                            className="mb-3"
                            disabled={hasNoWriteAccess}
                          />
                          <Button
                            secondary
                            label={<FormattedMessage id="common_cancel" />}
                            type="reset"
                            onClick={() => { handleReset(); this.handleReset(); }}
                            width="100%"
                            className="mb-4 mt-1"
                            disabled={hasNoWriteAccess}
                          />
                          <Button
                            primary
                            startIcon={icons.DeleteIcon}
                            IconHeight="18px"
                            type="button"
                            label={<FormattedMessage id="common_delete" />}
                            onClick={deleteRoom}
                            width="100%"
                            className="mb-4"
                            disabled={hasNoWriteAccess}
                          />
                        </IsMobile>
                      </Form>
                      <a
                        href
                        data-toggle="modal"
                        data-target="#Modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        ref={(openModal) => { this.openElement = openModal; }}
                      />
                      {showModal && (
                        <Modal>
                          <Popup
                            data={services}
                            onSave={this.handleSelectedServices}
                            onCancel={this.handleCloseModal}
                            onChange={handleChange}
                          />
                        </Modal>
                      )}
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        </>
      );
    }
}
CreateRoomForm.propTypes = {
  submitData: PropTypes.func,
  deleteRoom: PropTypes.func,
  edit: PropTypes.bool,
  roomData: PropTypes.string,
  onCancel: PropTypes.func,
  userDetails: PropTypes.node,
};
CreateRoomForm.defaultProps = {
  submitData: null,
  deleteRoom: null,
  edit: false,
  roomData: {},
  onCancel: undefined,
  userDetails: {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});
export default connect(mapStateToProps)(CreateRoomForm);
