import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { getAssignmentsList } from "../../../services/RoomService";
import OrdersTab from "../../Common/AssignmentsTab";
import Constants from "../../../shared/Constants";

const {
  sorting: { type: sortType }, language, filter: { options: optionsData, components },
} = Constants;

const header = [
  {
    className: "col",
    value: language.filter_label_status,
    key: "status",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.filter_label_customer,
    key: "customer",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.common_dog,
    key: "dog_size",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.header_date_time,
    key: "date_time",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.header_service,
    key: "services",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.label_organisation,
    key: "organization",
    icon: sortType.ALPHABETS,
  },
];
const value = [
  {
    className: "col",
    key: "status",
    type: "label",
  },
  {
    className: "col",
    key: "customer",
    type: "singleUser",
    isDesktop: true,
  },
  {
    className: "col",
    key: "dog",
    type: "label",
  },
  {
    className: "col",
    key: "startDateTime",
    type: "date",
  },
  {
    className: "col",
    key: "service",
    type: "singleUser",
  },
  {
    className: "col",
    key: "organization",
    type: "singleUser",
  },
];
const filterDataValues = [
  {
    id: "status",
    component: components.CHECKBOX,
    label: language.filter_label_status,
    values: optionsData.ORDER_STATUS,
    defaultValues: [],
    name: "status",
  },
  {
    id: "service_providers",
    component: components.LETTERSELECT,
    label: language.common_service_provider,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "service_providers",
  },
  {
    id: "services",
    component: components.CHECKBOX,
    label: language.common_service,
    values: [],
    defaultValues: [],
    name: "services",
  },
  {
    id: "date",
    component: components.DATEPICKER,
    label: language.filter_date_diapason,
    fromDate: { defaultDate: "" },
    toDate: { defaultDate: "" },
    name: "date_time",
  },
  {
    id: "organization",
    component: components.LETTERSELECT,
    label: language.filter_label_organization,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "organization",
  },
  {
    id: "customers",
    component: components.LETTERSELECT,
    label: language.filter_label_customer,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "customers",
  },
];

const AssignmentTab = ({ roomId, redirectTo }) => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 10,
    pages: 1,
  });
  const [filterOptions, setFilterOptions] = useState(filterDataValues);
  const serviceProviderFilters = useSelector((state) => state.FilterReducer.serviceProviderFilters);
  const organizationFilters = useSelector((state) => state.FilterReducer.organizationFilters);
  const allServices = useSelector((state) => state.FilterReducer.allServices);
  const customerFilters = useSelector((state) => state.FilterReducer.customerFilters);

  const fetchFilters = async (options) => {
    setLoading(true);
    const filteredData = options || filterOptions;
    filteredData[1].availableValues = serviceProviderFilters;
    filteredData[2].values = allServices;
    filteredData[4].availableValues = organizationFilters;
    filteredData[5].availableValues = customerFilters;
    setFilterOptions(filteredData);
    setLoading(false);
  };

  /**
   *
   * @async
   * @function fetchAssignments Fetches assignments
   * @param {String} searchInput search text
   *
   */
  const fetchAssignments = (options, params) => {
    setLoading(true);
    getAssignmentsList(roomId, params).then((response) => {
      const { meta: srcMetaData = {}, data = {} } = response;
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMetaData;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      fetchFilters(options);
      setOrderData(data.map((obj) => ({
        ...obj,
        startDateTime: moment.utc(obj.startDateTime).local(),
        endDateTime: moment.utc(obj.endDateTime).local(),
      })));
      setMeta(metaData);
      setLoading(false);
    }, () => {
      setLoading(false);
    });
  };

  const handleGotoPage = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 10;
    setMeta(meta);
  };
  return (
    <OrdersTab
      handleGotoPage={handleGotoPage}
      loading={loading}
      fetchData={fetchAssignments}
      meta={meta}
      redirectTo={redirectTo}
      filterDataValues={filterDataValues}
      header={header}
      value={value}
      orderData={orderData}
      isCustomers
    />
  );
};
AssignmentTab.propTypes = {
  redirectTo: PropTypes.func,
  roomId: PropTypes.string,
};
AssignmentTab.defaultProps = {
  redirectTo: () => {},
  roomId: "",
};
export default AssignmentTab;
