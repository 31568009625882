import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Div, MDLabel, CardHeader, MDImage,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import CircleTickMark from "../../assets/Icons/CircleTickMark.svg";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import Theme from "../../styles/Theme";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import { formatDate } from "../../shared/utils";

export default function AvailabilityEditReponse({
  renderModal, details, assignemntCount,
}) {
  const {
    widths: {
      w25,
    },
    heights: {
      h20,
    },
    radii: {
      large,
    },
  } = Theme;
  const hadleModal = () => {
    renderModal();
  };
  return (
    <Modal className="availability-form">
      <Div width="500px">
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id="label_availability_details" defaultMessage="Availability Details" />
          </MDLabel>
        </CardHeader>
        <Div display="flex" flexDirection="column" className="col-10 offset-1" paddingTop="1rem" justifyContent="center">
          {assignemntCount?.get_available_slot_count && (
            <MDLabel alignSelf="center" fontSize="15px" fontWeight="normal">
              <FormattedMessage id="label_order_recurrent" defaultMessage="All recurrent availability edited except" />
              (
              {assignemntCount?.get_available_slot_count}
              )
              <FormattedMessage id="label_order_recurrent_a" defaultMessage="because you already have order for them" />
            </MDLabel>
          )}
          {details && (
            <Div my={2}>
              {details.map((value, key) => (
                <>
                  <Div display="flex" id={key} my={2} className="col-10 offset-1">
                    <Div display="flex" className="col-12">
                      <MDImage
                        src={CalendarIcon}
                        useCursorPointer
                        alt="icon"
                        width={w25}
                        height={h20}
                        borderRadius={large}
                      />
                      <FormattedMessage
                        id="label_to"
                        defaultMessage={formatDate(value.assignment_start_datetime)}
                      />
                    </Div>
                  </Div>
                  <Div display="flex" id={key} my={2} className="row col-10 offset-1">
                    <Div display="flex" className="col-9" mx={2} alignItems="center">
                      <MDLabel fontSize="18px" color="darkgray" mr={15}>
                        <FormattedMessage id="label_from" defaultMessage="From:" />
                      </MDLabel>
                      <MDImage
                        src={ClockIcon}
                        useCursorPointer
                        alt="icon"
                        width={w25}
                        height={h20}
                        borderRadius={large}
                      />
                      <FormattedMessage
                        id="label_to"
                        defaultMessage={moment(value.assignment_start_datetime).format("hh:mm:ss")}
                      />
                      <MDLabel fontSize="18px" color="darkgray" ml={15} mr={15}>
                        <FormattedMessage id="label_to" defaultMessage="To:" />
                      </MDLabel>
                      <MDImage
                        src={ClockIcon}
                        useCursorPointer
                        alt="icon"
                        width={w25}
                        height={h20}
                        borderRadius={large}
                      />
                      <FormattedMessage
                        id="label_to"
                        defaultMessage={moment(value.assignment_end_datetime).format("hh:mm:ss")}
                      />
                    </Div>
                  </Div>
                </>
              ))}
            </Div>
          )}
        </Div>
        <hr />
        <Div display="flex" className="col-8 offset-2" justifyContent="center" mb={20}>
          <Div display="flex" className="col-6">
            <Button label="Ok" width="100%" onClick={hadleModal} startIcon={CircleTickMark} />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
AvailabilityEditReponse.propTypes = {
  /**
   * Pass children innto the modal
   */
  renderModal: PropTypes.func,
  details: PropTypes.string,
  assignemntCount: PropTypes.string,
};
AvailabilityEditReponse.defaultProps = {
  renderModal: undefined,
  details: undefined,
  assignemntCount: undefined,
};
