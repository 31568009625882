import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  MDLabelWrapper, Div
} from "../../styles/Styles";
import Colors from "../../styles/Colors";
import { DownloadIcon } from "../../styles/Icons";
import { CSVLink } from "react-csv";
import DeleteSuccessPopUp from "../AvailabilityModal/DeleteSuccessPopUp";
import { getExceptionPopupContent } from "../../shared/utils";

const CsvDownloadButton = ({ fileName, onCsvClick}) => {
  const downloadCsv = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [isErrorModal, setIsErrorModal] = useState(false);

  const handleButtonClick = async () => {
    try {
      const customerData = await onCsvClick();
      setCsvData(customerData);
      downloadCsv.current.link.click();
    } catch (error) {
      setIsErrorModal(true);
    }
  };

  const getFileName = () => {
    const timestamp = moment().format("YYYYMMDDHHmm");
    return `${fileName}_${timestamp}.csv`;
  }

  const handleClosePopup = () => {
    setIsErrorModal(false);
  }

  return (
    <>
      {isErrorModal && <DeleteSuccessPopUp data={getExceptionPopupContent()} renderModal={handleClosePopup} />}
      <Div position="relative" width="40px" ml={3}>
        <CSVLink
          data={csvData}
          filename={getFileName()}
          ref={downloadCsv}
          separator={";"}
        >
        </CSVLink>
        <MDLabelWrapper height="40px" width="40px" backgroundColor={Colors.White} onClick={handleButtonClick}>
          <DownloadIcon />
        </MDLabelWrapper>
      </Div>
    </>);
}

CsvDownloadButton.propTypes = {
  fileName: PropTypes.string,
  onCsvClick: PropTypes.func,
}; 

CsvDownloadButton.defaultProps = {
  fileName: "",
  onCsvClick: () => {},
};

export default CsvDownloadButton;
