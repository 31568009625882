import React, { useEffect, useState, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import CommonCard from "../CommonSections/CommonCards/CommonCard";
import NextAndBack from "../NextAndBackButtons/NextAndBack";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import dogSizes from "../Content/dogSizes.json";
import { fetchDogDetails, selectDogSize, emptyBookingFlowValues } from "../../../store/actions/BookingFlowServicesAction";
import { BoxDivision, Division } from "../BookingFlowStyles/BookingFlowStyles";
import {
  Div, MDLabel, P, InputBox,
} from "../../../styles/Styles";
import Loader from "../../../components/Loader/CardLoader";
import { mapDogSizeToDuration, mapDogSizeToPrice, mapSizeToDog } from "../../../shared/utils";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import { emptyPaymentSnippet } from "../../../store/actions/PaymentAction";

const { icons, images } = Constants;

const BookingFlowStepTwo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const childRef = useRef(null);

  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);

  const selectedDog = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes,
  );

  const userDogSize = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.userDogSize,
  );

  const filteredService = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer,
  );
  const { selectedServices: { service: services = {} } = {} } = filteredService || {};

  const isUserLoggedIn = useSelector((state) => state.BookingFlowServicesReducer.isUserLogged);

  const getDogDetails = useSelector((state) => state.BookingFlowServicesReducer.getDogDetails);

  const userData = localStorage.userData && JSON.parse(localStorage.userData);
  const customerId = userData?.customer?.id;

  const [isLoading, setIsLoading] = useState(false);

  const [showGuestDogs, setShowGuestDogs] = useState({
    buttonFlag: false,
    guestDogs: false,
  });
  const { buttonFlag, guestDogs } = showGuestDogs;

  const handleSelectDogSize = (item, index) => () => {
    const selectedDogsizesInfo = {
      dogId: index,
      customerId,
      dogWeight: item?.height,
      price: Number(mapDogSizeToPrice(item?.height, services, true)?.split(" ")[0] || 0),
      duration: Number(mapDogSizeToDuration(item?.height, services, true)?.split(" ")[0] || 0),
      dogFace: item?.avatar,
    };
    dispatch(selectDogSize(selectedDogsizesInfo));
    if(selectedDog.dogId !== selectedDogsizesInfo?.dogId) {
      dispatch(emptyBookingFlowValues("dog"))
    }
  };

  const handleOnDogSizeSelected = (item) => () => {
    handleSelectDogSize(item, item?.id)();
    childRef.current?.handlerNextStep();
  };

  const handleGuestDog = () => {
    setShowGuestDogs({ ...showGuestDogs, buttonFlag: false, guestDogs: true });
  };

  useEffect(() => {
    if (selectedDog && selectedDog.dogId) {
      const item = dogSizes.find((o) => (o.id === selectedDog.dogId));
      if (item) {
        handleSelectDogSize(item, selectedDog.dogId)();
      } else if (isUserLoggedIn && getDogDetails.length > 0) {
        const dog = getDogDetails.find((o) => (o.id === selectedDog.dogId));
        if (dog) {
          handleSelectDogSize({ ...dog, avatar: dog?.profile_pic?.url }, selectedDog.dogId)();
        }
      }
    }
    dispatch(emptyPaymentSnippet());
  }, []);

  useEffect(async () => {
    if (isUserLoggedIn && customerId) {
      setIsLoading(true);
      await dispatch(fetchDogDetails(customerId, customerId));
      setIsLoading(false);
    }
  }, [isUserLoggedIn, customerId]);

  useEffect(() => {
    if (steps === 1) {
      history.push(Constants.routes.chooseService.url);
    }
  }, [steps]);

  useEffect(() => {
    if (!isUserLoggedIn && getDogDetails.length <= 0) {
      setShowGuestDogs({ ...showGuestDogs, buttonFlag: false, guestDogs: true });
    } else if (isUserLoggedIn) {
      setShowGuestDogs({ ...showGuestDogs, buttonFlag: true, guestDogs: false });
    }
  }, [isUserLoggedIn, getDogDetails]);

  useEffect(() => {
    if (selectedDog && selectedDog.dogId) {
      const item = dogSizes.find((o) => (o.id === selectedDog.dogId));
      if (item) {
        handleSelectDogSize(item, selectedDog.dogId);
      }
    }
  }, [selectedDog, userDogSize]);

  const renderDogSizes = dogSizes?.length > 0
    && dogSizes?.map((item, index) => (
      <Division key={item?.id} mx={[0, 3, 3]} type="select_Size" setBorder useCursorPointer onClick={handleOnDogSizeSelected(item)}>
        <InputBox
          type="radio"
          name="dogSizes"
          title={item?.height}
          id={item?.id + index}
          checked={selectedDog?.dogId === item?.id}
          hidden
          ref={childRef}
        />
        <CommonCard
          htmlFor={item?.id + index}
          imgWidth={75}
          imgHeight={75}
          avtarImg={item?.avatar || FavIcon}
          header={Constants.translations[item?.height]}
          leftIcon={icons.ClockIcon}
          leftContent={services?.is_full_day_service === 1 ? Constants.language.label_day : mapDogSizeToDuration(item?.height, services)}
          rightIcon={images.TagSingleIcon}
          rightContent={mapDogSizeToPrice(item?.height, services)}
          fontSize={Theme.fontSizes.small}
          color={Colors.DarkGrey}
          fontFamily={Theme.fonts.semibold}
          paddingLeft="12px"
          msLeft="msLeft"
          msRight="msRight"
          heightValue={75}
          // imgHeight={64}
          width="290px !important"
          isDog
          imagePadding={item?.avatar ? "" : "8px"}
        />
      </Division>
    ));

  const handleLoggedDogSize = (item, index) => () => {
    const {
      age,
      name,
      profile_pic: profilePic,
      dog_size_weight: dogSizeWeight,
      dogBreed,
      description,
      gender,
      isInsured,
      isSterilized,
      customerId: custId,
    } = item;
    const selectedDogsizesInfo = {
      dogId: index,
      customerId: custId,
      dogAge: age,
      dogWeight: dogSizeWeight,
      dogName: name,
      price: Number(mapDogSizeToPrice(dogSizeWeight, services)?.split(" ")[0]),
      duration: mapDogSizeToDuration(dogSizeWeight, services),
      dogFace: profilePic?.url,
      dogBreed,
      dogInsured: isInsured,
      isSterilized,
      dogGender: gender,
      serviceDescription: description,
    };
    if(selectedDog?.dogId && selectedDog?.dogId !== selectedDogsizesInfo?.dogId) {
      dispatch(emptyBookingFlowValues("dog"))
    }
    dispatch(selectDogSize(selectedDogsizesInfo));
    childRef.current?.handlerNextStep();
  };
  const renderLoggedDogSizes = getDogDetails?.length > 0
    && getDogDetails.map((item) => (
      <Division key={item?.id} mx={[0, 3, 3]} mt={2} pt={2} type="select_Size" setBorder onClick={handleLoggedDogSize(item, item?.id)}>
        <InputBox
          type="radio"
          name="dogSizes"
          title={item?.name}
          id={item?.id}
          checked={selectedDog?.dogId === item?.id}
          hidden
        />
        <CommonCard
          htmlFor={item?.id}
          avtarImg={item?.profile_pic?.url || FavIcon}
          imgWidth={90}
          imgHeight={90}
          header={item?.name}
          lengthOfDog={mapSizeToDog(item?.height)}
          age={item?.age}
          leftIcon={icons.ClockIcon}
          leftContent={services?.is_full_day_service === 1 ? Constants.language.label_day : `${mapDogSizeToDuration(item?.dog_size_weight, services)}`}
          rightIcon={images.TagSingleIcon}
          rightContent={`${mapDogSizeToPrice(item?.dog_size_weight, services)}`}
          fontSize={Theme.fontSizes.small}
          fontFamily={Theme.fonts.semibold}
          paddingLeft={12}
          msLeft="msLeft"
          msRight="msRight"
          color={Colors.DarkGrey}
          heightValue={90}
          width="290px !important"
          isDog
          imagePadding={item?.profile_pic?.url ? "" : "8px"}
        />
      </Division>
    ));

  return (
    <Div pb={4} display="flex" flexDirection="column" alignItems="center">
      <Div textAlign={["center"]} mt={4} pt={2} mb={4}>
        <MDLabel fontSize={Theme.fontSizes.xl} justifyContent="center" mb={20}>
          <FormattedMessage id="your_label_dog" defaultMessage="Your Dog" />
        </MDLabel>
        <P
          fontFamily={Theme.fonts.regular}
          fontSize={Theme.fontSizes.regular}
          color={Colors.DarkGrey}
          px={3}
        >
          <FormattedMessage
            id="your_label_placechoose"
            defaultMessage="Please, choose your dog size. Price and duration of the service depend on it."
          />
        </P>
      </Div>

      {isLoading
        ? (
          <BoxDivision mb={20}>
            <Loader width={255} height={64} />
          </BoxDivision>
        )
        : isUserLoggedIn && (
          <>
            <BoxDivision type="wrap_div" mb={30}>{renderLoggedDogSizes}</BoxDivision>
            {guestDogs && (
              <>
                <MDLabel fontSize={Theme.fontSizes.medium} justifyContent="center" mb={30}>
                  <FormattedMessage id="add_another_dog" defaultMessage="Add another dog:" />
                </MDLabel>
                <BoxDivision mb={20} display={["block", "grid", "grid"]} gridTemplateColumns="1fr 1fr" gridRowGap="30px" gridColumnGap="14px">{renderDogSizes}</BoxDivision>
              </>
            )}
          </>
        )}
      {!isUserLoggedIn &&(
        <BoxDivision mb={20} display={["block", "grid", "grid"]} gridTemplateColumns="1fr 1fr" gridRowGap="30px" gridColumnGap="14px">{renderDogSizes}</BoxDivision>
      )}

      {!isLoading && isUserLoggedIn && buttonFlag && (
        <BoxDivision my={4}>
          <Button
            label={<FormattedMessage id="another_dog" defaultMessage="Another Dog" />}
            className="mx-2"
            width="auto"
            startIcon={images.PlusIcon}
            onClick={handleGuestDog}
          />
        </BoxDivision>
      )}
      <NextAndBack typeName="backToNext" ref={childRef}/>
    </Div>
  );
};
export default memo(BookingFlowStepTwo);
