import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Theme from "../../../styles/Theme";
import Constants from "../../../shared/Constants";
import { MDInputBox } from "../Style";
import {
  Div, MDLabel, Span, CurvedDiv,
} from "../../../styles/Styles";
import { BoxDivision, LinkItem } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";

const CardContainer = ({
  children, cardTitle, categoryLabel, categoryValue,
}) => {
  const history = useHistory();
  const handlePush = (path) => () => {
    history.push(path);
  };
  return (
    <>
      <Div
        px={0}
        pb={43}
        my={4}
        background={Theme.colors.text.white}
        borderRadius={Theme.radii.medium}
        boxShadow={Theme.shadows.normal}
      >
        <BoxDivision
          boxShadow={Theme.shadows.light}
          type="choose_Services"
          position="relative"
          justifyContent="space-between"
          mb={2}
          height={70}
        >
          <CurvedDiv
            display="flex"
          >
            <MDLabel fontSize={Theme.fontSizes.xl} lineHeight="24px" ml={3}>
              <FormattedMessage id="label_additional_information" defaultMessage={cardTitle} />
            </MDLabel>
          </CurvedDiv>
          {categoryValue && (
            <MDInputBox type="labelmain" fontSize={Theme.fontSizes.small} pr={3}>
              <Span pr={2}>
                <FormattedMessage id="common_category" defaultMessage={categoryLabel} />
              </Span>
              <LinkItem type="categories" mr={3} onClick={handlePush(Constants.routes.bookingFlow.url)}>
                <FormattedMessage id="category_value" defaultMessage={categoryValue} />
              </LinkItem>
            </MDInputBox>
          )}
        </BoxDivision>
        {children}
      </Div>
    </>
  );
};
CardContainer.propTypes = {
  children: PropTypes.node,
  cardTitle: PropTypes.string,
  categoryLabel: PropTypes.string,
  categoryValue: PropTypes.node,
};
CardContainer.defaultProps = {
  children: {},
  cardTitle: "Loading...",
  categoryLabel: "Category",
  categoryValue: "",
};

export default CardContainer;
