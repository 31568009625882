import React from "react";
import { Div } from "../../styles/Styles";
import Constants from "../../shared/Constants";
import CalendarCard from "../../components/Card/CalendarCard";
import DayViewEvent from "./CustomEvents/DayViewEvent";
import MonthViewEvent from "./CustomEvents/MonthViewEvent";
import WeekViewEvent from "./CustomEvents/WeekViewEvent";

const useEventContent = (
  history, showServiceProvider, showShow, serviceProviderData, userType, userDetail,selectedOrganization,
) => {
  const renderEventContent = (eventInfo) => {
    const { calendar, adminRoutes } = Constants;
    const { dayGridMonth, timeGridDay, timeGridWeek } = calendar;
    const { view } = eventInfo;
    const calendarData = eventInfo.event.extendedProps.data;

    const handleCardClick = (assignmentId) => {
      history.push(`${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${assignmentId}/${adminRoutes.editAssignment}`);
    };
    if (view.type === dayGridMonth) {
      return (
        <MonthViewEvent
          showServiceProvider={showServiceProvider}
          showShow={showShow}
          eventInfo={eventInfo}
        />
      );
    }
    if (view.type === timeGridDay) {
      return (
        <DayViewEvent
          history={history}
          eventInfo={eventInfo}
          userDetail={userDetail}
          selectedOrganization={selectedOrganization}
        />
      );
    }
    if (view.type === timeGridWeek) {
      return (
        <WeekViewEvent
          history={history}
          userDetail={userDetail}
          eventInfo={eventInfo}
          selectedOrganization={selectedOrganization}
        />
      );
    }
    return (
      <>
        {calendarData && (
          <Div className="d-inline-flex" width="inherit">
            <CalendarCard
              data={calendarData}
              viewType={view.type}
              onCardClick={() => handleCardClick(calendarData.assignmentId)}
            />
          </Div>
        )}
      </>
    );
  };
  return renderEventContent;
};

export default useEventContent;
