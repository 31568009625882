import moment from "moment-timezone";
import Weekdays from "../shared/WeekDays";
import Constants from "../shared/Constants";

const { input: { format: { date: yearToDay, dateWithTime, time: timeFormat } } } = Constants;
export const toJson = (obj) => {
  const {
    room: { value: roomId = "" } = {},
    place: { value: placeId = "" } = {},
    service: { value: serviceId = "", is_full_day_service: isFullDayService = false } = {},
    client: { value: customerId = "" } = {},
    serviceProvider: { value: serviceProviderId = "" } = {},
    dog: { value: dogId = "" } = {},
    schedule: { scheduleDate = "", scheduleDays = [] } = {},
    description = "",
    time: { date = "", from = "", slotId = "" } = {},
    startEndDate: { date: startDate = "", endDate = "", slotId: fullDaySlotId = "" } = {},
  } = obj;
  const sheduleId = scheduleDays.map((o) => o.value).join(",");
  const formattedDate = date ? moment(date).format(yearToDay) : "";
  const assignmentDatetime = moment(`${formattedDate} ${from.split(" -")[0]}`);
  const data = {
    room_id: roomId,
    organization_id: placeId,
    service_id: serviceId,
    customer_id: customerId,
    service_provider_id: serviceProviderId,
    dog_id: dogId,
    scheduler: sheduleId,
    description,
    assignment_datetime: moment.utc(assignmentDatetime).format(dateWithTime),
    add_ons: "",
    scheduler_end_date: scheduleDate,
    slot_id: slotId,
    is_full_day_service: false,
  };

  if(isFullDayService) {
    const getStartDate = moment(`${moment(startDate).format(yearToDay)} 08:00`);
    const getEndDate = moment(`${moment(endDate).format(yearToDay)} 22:00`);
    data.is_full_day_service = true;
    data.start_date = moment.utc(getStartDate).format(dateWithTime);
    data.end_date = moment.utc(getEndDate).format(dateWithTime);
    data.assignment_datetime = moment.utc(getStartDate).format(dateWithTime);
    data.slot_id = fullDaySlotId;
  }

  return data;
};
export const fromJson = (obj) => {
  const {
    customer_id: customer = {}, description = "", assignment_start_datetime: time = "",
    dog_id: dog = {}, organization: place = {}, room_id: room = {},
    service_id: service = {}, service_provider_id: serviceProvider = {}, scheduler = {}, status = "",
    assignment_end_datetime: endDate = "", is_full_day_service: fullDayService
  } = obj;
  const { scheduled_days: scheduledDays = "", scheduler_end_date: scheduleDate = "" } = scheduler || {};
  const partScheduler = scheduledDays ? scheduledDays.split(",") : [];
  const scheduleData = Weekdays.filter((day) => partScheduler.includes(day.value));
  const data = {};
  if (serviceProvider) {
    const { first_name: name, id, profile_pic: profilePic = {} } = serviceProvider;
    const { url = "" } = profilePic || {};
    data.serviceProvider = {
      label: name,
      value: id,
      profilePic: url,
    };
  }
  if (service) {
    const {
      name, id, profile_pic: profilePic = {}, duration,
    } = service;
    const { url = "" } = profilePic || {};
    data.service = {
      label: name,
      value: id,
      profilePic: url,
      duration,
      is_full_day_service: fullDayService === 1,
    };
  }
  if (dog) {
    const {
      name = "", id = "", profile_pic: profilePic = {}, dog_size_weight: dogSizeWeight = "", breed = "",
    } = dog;
    const { url = "" } = profilePic || {};
    data.dog = {
      dog_size_weight: dogSizeWeight,
      label: name, value: id, profilePic: url,
      dogSizeWeight: dogSizeWeight || "", breed: breed || "",
    };
  }
  if (customer) {
    const { name = "", id = "", profile_pic: profilePic = {} } = customer;
    const { url = "" } = profilePic || {};
    data.client = {
      label: name,
      value: id,
      profilePic: url,
    };
  }
  if (place) {
    const {
      name = "", id = "", profile_pic: profilePic = {}, address = "",
    } = place;
    const { url = "" } = profilePic || {};
    data.place = {
      label: name,
      info: address,
      value: id,
      profilePic: url,
    };
  }
  if (room) {
    const {
      name = "", id = "", profile_pic: profilePic = {},
    } = room;
    const { url = "" } = profilePic || {};
    data.room = { label: name, value: id, profilePic: url };
  }
  data.schedule = { scheduleDays: scheduleData, scheduleDate };
  data.time = { date: moment.utc(time).local().format(yearToDay) || "", from: moment.utc(time).local().format(timeFormat) || "" };
  data.endDate = { date: moment.utc(endDate).local().format(yearToDay) || "", from: moment.utc(endDate).local().format(timeFormat) || "" };
  if(fullDayService){
    data.startEndDate = {
      date: moment.utc(time).local().format(yearToDay),
      endDate: moment.utc(endDate).local().format(yearToDay),
    }
  }
  return { data: { data, description }, status };
};
