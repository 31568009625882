/**
 * Handles Service serialization.
 */
class Service {
  fromServiceDetailsJson=(obj = {}) => {
    const {
      profile_pic: profilePic = "",
      add_ons: additionalOptions = [],
      category_id: category = {},
      is_full_day_service: fulldayservice = "",
      duration={},
      price_mini: priceXS = "",
      price_large: priceL = "",
      price_medium: priceM = "",
      price_small: priceS = "",
      name = "",
      description = "",
      room = "",
    } = obj;
    const { mini = 0, small = 0, medium = 0, large = 0 }= duration;
    const data = {
      name,
      category,
      description,
      fulldayservice: fulldayservice ? true: false,
      durationL: large,
      durationM: medium,
      durationS: small,
      durationXS: mini,
      priceXS,
      priceL,
      priceM,
      priceS,
      profilePic,
      room: room && room.id ? room.id : "",
    };
    return {
      data, additionalOptions,
    };
  }

  /**
   * Returns the service Details payload of JSON type.
   *
   * @method
   */
  toJson=(obj) => {
    const { profilePic: { id: profilePic = "" } = {} } = obj;
    const data = {
      name: obj.name,
      description: obj.description,
      category_id: obj.category.value,
      profile_pic_id: profilePic,
      price: {
        price_mini: obj.priceXS,
        price_small: obj.priceS,
        price_medium: obj.priceM,
        price_large: obj.priceL,
      },
      duration: {
        duration_mini: obj.durationXS || 0,
        duration_small: obj.durationS || 0,
        duration_medium: obj.durationM || 0,
        duration_large: obj.durationL || 0,
      },
      is_full_day_service: obj.fulldayservice,
      add_ons: obj.additionalOptions,
    };
    const { room: { value = "" } = "" } = obj;
    if (value) {
      data.room_id = value;
    }
    return data;
  }
}
export default Service;
