import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateDogForm from "./Components/CreateDogForm";
import {
  MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";

const AddDog = (props) => {
  const handleBackToCustomers = () => {
    const { history } = props;
    history.goBack();
  };
  const { icons } = Constants;
  const {
    match: { params = {} },
  } = props;
  const { id = "" } = params;
  return (
    <Container>
      <Div display="flex">
        <MDLabel cursor="pointer" onClick={handleBackToCustomers}>
          <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
          <FormattedMessage id="breadcrumb_back_to_customers" />
        </MDLabel>
      </Div>
      <>
        <Header className="py-2">
          <FormattedMessage id="customer_add_new_dog" />
        </Header>
        <CreateDogForm handleBackToCustomers={handleBackToCustomers} userId={id} />
      </>
    </Container>
  );
};
AddDog.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
};
AddDog.defaultProps = {
  history: undefined,
  match: undefined,
};
export default AddDog;
