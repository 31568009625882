import React from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import {
  InputArea, Div, MDImage, MDLabel,
} from "../../styles/Styles";

const TextArea = ({
  startIcon,
  endIcon,
  IconHeight,
  formikValues = {},
  name,
  label,
  type,
  id,
  minLength,
  maxLength,
  disabled,
  value,
  placeholder = "",
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  width,
  onClick,
  login,
  containerProps,
  ...props
}) => {
  const { errors = {}, touched = {} } = formikValues;
  return (
    <Div width={width} className={containerProps} disable={disabled}>
      {label && (
        <Div pb={2} pt="15px">
          <MDLabel fontSize="16px" display="initial">
            {label}
          </MDLabel>
          {!login && errors[name] && touched[name] && (
            <MDLabel float="right" color={Colors.Red} pb={1}>
              {errors[name]}
            </MDLabel>
          )}
        </Div>
      )}
      {login && errors[name] && touched[name] && (
        <MDLabel float="right" color={Colors.Red} pb={1}>
          {errors[name]}
        </MDLabel>
      )}
      <Div
        border="1px solid"
        borderColor={errors[name] && touched[name] ? Colors.Red : Colors.Border}
        display="flex"
        borderRadius={5}
        width={width}
        props={props}
        className={className}
        onClick={onClick}
      >
        {startIcon && (
          <Div className="col-auto pl-2 pr-0" alignSelf="center">
            <MDImage src={startIcon} alt="icon" width="20px" height={IconHeight} />
          </Div>
        )}
        <Div className="col p-0" width="inherit">
          <InputArea
            name={name}
            type={type}
            id={id}
            width="100%"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
          />
        </Div>
        {endIcon && (
          <Div className="col-auto pl-0 pr-2" alignSelf="center">
            <MDImage src={endIcon} alt="icon" width="20px" height={IconHeight} />
          </Div>
        )}
      </Div>
    </Div>
  );
};
TextArea.propTypes = {
  /**
   * Label of the input field
   */
  label: PropTypes.string,
  /**
   * value of the input field
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Which type of input
   */
  type: PropTypes.string,
  /**
   * Id of the input
   */
  id: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Do input field be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Optional onClick handler
   */
  onClick: PropTypes.func,
  /**
   * Calls when value is changed
   */
  onChange: PropTypes.func,
  /**
   * Calls when input field is touched
   */
  onFocus: PropTypes.func,
  /**
   * Calls when focus out from input
   */
  onBlur: PropTypes.func,
  /**
   * What should be as placeholder..
   */
  placeholder: PropTypes.string,
  /**
   * Calls on key press
   */
  onKeyPress: PropTypes.func,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  /**
   * Url of the startIcon
   */
  startIcon: PropTypes.string,
  /**
   * url of the end icon
   */
  endIcon: PropTypes.string,
  /**
   * Height of the icon
   */
  IconHeight: PropTypes.string,
  /**
   * Do the input contain any minLength of letters..
   */
  minLength: PropTypes.number,
  /**
   * Do the input has any maximum no of letters..
   */
  maxLength: PropTypes.number,
  /**
   * Customise the styles of the input
   */
  className: PropTypes.string,
  /**
   * Width of the input box
   */
  width: PropTypes.string,
  /**
   * ClassName applies for whole division
   */
  containerProps: PropTypes.string,
  /**
   * Is login page?
   */
  login: PropTypes.bool,
};
TextArea.defaultProps = {
  name: null,
  type: null,
  id: null,
  value: null,
  login: false,
  formikValues: {},
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onKeyPress: undefined,
  placeholder: null,
  disabled: false,
  label: null,
  width: "auto",
  className: null,
  maxLength: undefined,
  minLength: undefined,
  IconHeight: "auto",
  endIcon: null,
  startIcon: null,
  onClick: undefined,
  containerProps: null,
};
export default TextArea;
