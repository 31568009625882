import styled from "styled-components";
import { Div } from "../../styles/Styles";

export const CardWrapper = styled(Div)`
  width: ${({ width }) => (width || "790px !important")}; 
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;
export const Division = styled(Div)``;
