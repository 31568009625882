import axios from "axios";
import Constants from "../shared/Constants";
import {
  payEndPoint, orderPayload, username, password,
} from "../utility/klarna";
import { request } from "./ApiService";

export const {
  apiEndPoints: { endPointUrl = {} },
  HTTP_STATUS: { OK, CREATED },
  requestType: { GET },
} = Constants;

const authValue = `Basic ${window.btoa(`${username}:${password}`)}`;
const callMaster = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: authValue,
  },
});

export const postCall = async (endPoint, data) => {
  const url = payEndPoint + endPoint;
  const res = await callMaster.post(url, data);
  if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
    // TODO: Throw more specific errors
    throw Error(res);
  }
  return res;
};

export const createOrderId = async () => {
  const url = "/checkout/v3/orders";
  const response = await postCall(url, orderPayload);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
};

export async function confirmOrderAfterPayment(id) {
  const url = `order-confirmation/${id}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}
