import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateCustomerForm from "./Components/CreateCustomerForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel, MDInputValue,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import TextBox from "../../components/Input/TextBox";
import CustomerService from "../../services/CustomerService";
import Config from "../../config";
import Tabs from "../../components/Tabs/Tabs";
import OrdersTab from "./Components/Orders";
import DogsTab from "./Components/DogsTab";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Theme from "../../styles/Theme";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Subscription from "./Components/SubscriptionTab";

const { language, adminRoutes, routes } = Constants;
class EditCustomer extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const { userDetails = {} } = this.props;
    const userData = userDetails.user_access
      ? userDetails : localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { user_access: userAccess = {} } = userData;
    const { is_customer_editable: disableData = 1 } = userAccess;
    this.state = {
      disableData,
      loading: false,
      customerData: {},
      banReason: "",
      showSuccessModal: false,
      isBanUser: false,
      isUnbanUser: false,
      isBanned: false,
      tabs: [language.common_details, language.common_dogs, language.common_orders, language.common_subscriptions],
      selectedTab: language.common_details,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
    };
  }

  fetchSelectedTab = () =>{
    const {
      path
    } = this.props;
    
    let currentTab = "";
    switch(path) {
    case routes.dogsInCustomer.url:
      currentTab = language.common_dogs;
      break;
    case routes.ordersOfCustomer.url:
      currentTab = language.common_orders;
      break;
    case routes.subscriptionsOfCustomer.url:
      currentTab = language.common_subscriptions;
      break;
    default:
      currentTab = language.common_details;
    }
    
    this.setState({selectedTab: currentTab })
  }

  componentDidMount=() => {
    this.fetchDetails();
    this.fetchSelectedTab();
  }

  componentDidUpdate=(prevProps)=>{
    const { path } = this.props;
    if(prevProps.path !== path){
      this.fetchSelectedTab();
      this.fetchDetails();
    }
  }

  fetchDetails=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.customerService.getCustomerDetails(id);
      window.scrollTo(0,0);
      this.setState({ loading: false, customerData: response });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleSubmitData= (isSaved = false) => {
    if (isSaved) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_customer_updated,
      }, this.fetchDetails);
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  handleCancelChanges=() => {
    this.fetchDetails();
  }

  handleSwitchBetweenTabs = (data)=>{
    const { history, match: { params = {} } } = this.props;
    const { id = "" } = params;
    let switchPath =  "";

    if(data === language.common_dogs) {
      switchPath = `/admin/customers/${id}/edit-customer/dogs`;
    } else if (data === language.common_orders) {
      switchPath = `/admin/customers/${id}/edit-customer/orders`;
    } else if (data === language.common_subscriptions) {
      switchPath = `/admin/customers/${id}/edit-customer/subscriptions`;
    } else {
      switchPath = `/admin/customers/${id}/edit-customer`;
    }
    history.push(switchPath);
  }
  

  handleTabs = (data) => {
    this.setState({ selectedTab: data });
    if (data === language.common_details) {
      this.fetchDetails();
    }
    this.handleSwitchBetweenTabs(data);
  };

  handleDeleteCustomer=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    try {
      const response = await this.customerService.deleteCustomer(id);
      if (response) {
        this.getDetails();
        this.setState({
          changesSaved: true,
          isDeleteModalOpen: false,
          loading: false,
          alertMessage: language.customer_deleted_alert_message,
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  /**
   * Opens a delete confirmation popup
   */
  handleDeleteModal =() => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({
      changesSaved: false,
      isDeleteModalOpen: false,
      alertMessage: "",
      alertHeader: "",
    });
  }

  handleBackToCustomers = () => {
    const { alertMessage } = this.state;
    if (alertMessage === language.customer_deleted_alert_message) {
      const { history } = this.props;
      history.push(Constants.routes.customersList.url);
    } else {
      this.handleCancel();
    }
  };

  handleAddDog=(id) => () => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${id}/${adminRoutes.addDog}`);
  };

  handleShowUnbanUserModal=() => {
    this.setState({ isUnbanUser: true }, () => {
      this.openElement.click();
    });
  }

  handleBanUser=async () => {
    const {
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    const { isBanUser, banReason } = this.state;
    const data = {
      ban: false,
    };
    if (isBanUser) {
      data.ban = true;
      data.banReason = banReason;
    }
    try {
      const response = await this.customerService.banUser(id, data);
      if (response) {
        this.getDetails();
        this.setState({
          showSuccessModal: true, isBanUser: false, isUnbanUser: false, isBanned: isBanUser,
        });
      }
    } catch (e) {
      // Handle error
    }
  }

  handleCloseModal=() => {
    this.setState({
      banReason: "", isUnbanUser: false, isBanUser: false, showSuccessModal: false,
    },
    this.fetchDetails);
  }

  handleMoveTabs=(id) => {
    const { tabs } = this.state;
    const selectedTab = tabs[id];
    this.setState({ selectedTab });
    this.handleSwitchBetweenTabs(selectedTab);
  }

  handleShowBanUserModal=() => {
    this.setState({ isBanUser: true }, () => {
      this.openElement.click();
    });
  }

  handleChange=(event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleRedirectToOrders = (item) => () => {
    const { history, location: { pathname : currentPath = "", search = ""} } = this.props;
    const params = new URLSearchParams(search);
    const pageNo = params.get("page");
    history.push({
      pathname :`${adminRoutes.adminPrefix}/${adminRoutes.orders}/${item.id}/${adminRoutes.editOrder}`,
      state: `${currentPath}?isOrders=true${pageNo && `&page=${pageNo}`}`
    });
  };

  handleRedirectToCustomers = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { icons, input: { name: inputNames } } = Constants;
    const {
      location: { pathname = ""},
      match: { params = {} },
    } = this.props;
    const { id = "" } = params;
    const {
      loading, banReason, customerData = {}, selectedTab, tabs,
      isBanUser, isBanned, isUnbanUser, showSuccessModal, disableData,
      isDeleteModalOpen,
      changesSaved, alertMessage, alertHeader,
    } = this.state;
    const { data: { name = "", openOrders = 0 } = {}, status = "", bannedReason = "" } = customerData;
    return (
      <Container>
        <Div display="flex">
          <MDLabel cursor="pointer" onClick={this.handleRedirectToCustomers}>
            <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
            <FormattedMessage id="common_back" />
          </MDLabel>
        </Div>
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Div display="flex" justifyContent="space-between">
              <Header className="py-2">{name}</Header>
              <Div>
                <Div display="flex" ml={1}>
                  <MDInputValue placeholder size="18">
                    <FormattedMessage id="filter_label_status" />
                    :
                  </MDInputValue>
                  <MDLabel fontSize={Theme.fontSizes.medium} ml={1}>
                    {Constants.types[status]}
                  </MDLabel>
                </Div>
                {status === Constants.status.banned && (
                  <Button
                    secondary
                    startIcon={icons.ThumbIcon}
                    label={<FormattedMessage id="button_unban_customer" />}
                    onClick={this.handleShowUnbanUserModal}
                    width="auto"
                    type="button"
                    disabled={disableData !== 1}
                    className="mb-4 mt-4"
                  />
                )}
              </Div>
            </Div>
            {status === Constants.status.banned && (
              <Div className="p-4" width="fit-content" bg="white" borderRadius="10px">
                <Div className="row m-0 ">
                  <MDImage src={icons.WarningIcon} alt="icon" className="mr-2" />
                  <MDLabel fontSize="18px"><FormattedMessage id="user_is_banned_message" /></MDLabel>
                </Div>
                <Div>
                  <Div className="my-3">
                    <MDLabel textAlign="left"><FormattedMessage id="common_reason" /></MDLabel>
                  </Div>
                  <Div>
                    <MDInputValue textAlign="left" size="14">{bannedReason}</MDInputValue>
                  </Div>
                </Div>
              </Div>
            )}
            <Div className="my-3">
              <Tabs
                tabs={tabs}
                openOrders={openOrders}
                selectedTab={selectedTab}
                onClick={this.handleTabs}
                onNext={this.handleMoveTabs}
                onPrevious={this.handleMoveTabs}
              />
            </Div>
            {selectedTab === tabs[0]
            && (
              <FormWrapper>
                <CreateCustomerForm
                  edit
                  userId={id}
                  status={status}
                  onCancel={this.handleCancelChanges}
                  customerData={customerData}
                  handleBackToCustomers={this.handleSubmitData}
                  deleteCustomer={this.handleDeleteModal}
                  disableData={disableData !== 1}
                  showBanUserModal={this.handleShowBanUserModal}
                />
              </FormWrapper>
            )}
            {selectedTab === tabs[1] && (
              <>
                <Div display="flex" justifyContent={["flex-start", "flex-end"]} py={2}>
                  <Button
                    primary
                    startIcon={PlusIcon}
                    label={<FormattedMessage id="customer_add_new_dog" />}
                    onClick={this.handleAddDog(id)}
                    width="auto"
                    type="button"
                    disabled={disableData !== 1}
                  />
                </Div>
                <DogsTab userId={id} />

              </>
            )}
            {selectedTab === tabs[2] && (
              <>
                <OrdersTab userId={id} redirectTo={this.handleRedirectToOrders} />
              </>
            )}
            {selectedTab === tabs[3] && (
              <Subscription userId={id} currentPath={pathname} />
            )}
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => {
            this.openElement = openModal;
          }}
        />
        {isBanUser && (
          <Modal>
            <Card
              header={<FormattedMessage id="button_ban_customer" />}
              message={(
                <Div>
                  <FormattedMessage id="user_ban_confirmation_message" />
                  {" "}
                  {name}
                ?
                  <TextBox
                    containerProps="m-auto justify-content-center pt-3"
                    placeholder={language.placeholder_reason}
                    onChange={this.handleChange}
                    value={banReason}
                    name={inputNames.banReason}
                  />
                </Div>
              )}
              buttonLabel={<FormattedMessage id="button_ban_customer" />}
              confirmationCard
              buttonIcon={Constants.icons.WhiteBanIcon}
              isDisabled={!banReason.length}
              onClick={this.handleBanUser}
              onCancel={this.handleCloseModal}
            />
          </Modal>
        )}
        {isUnbanUser && (
          <Modal>
            <Card
              header={<FormattedMessage id="button_unban_customer" />}
              message={(
                <Div>
                  <FormattedMessage id="user_unban_confirmation_message" />
                  {" "}
                  {name}
                  ?
                </Div>
              )}
              buttonLabel={<FormattedMessage id="button_unban_customer" />}
              confirmationCard
              buttonIcon={Constants.icons.BorderedThumbIcon}
              onClick={this.handleBanUser}
              onCancel={this.handleCloseModal}
            />
          </Modal>
        )}
        {showSuccessModal && (
          <Modal>
            <Card
              header={<FormattedMessage id="common_done" />}
              message={isBanned ? (
                <FormattedMessage id="user_is_banned_message" />
              )
                : <FormattedMessage id="user_unbanned_alert_message" />}
              alertCard
              buttonLabel="Ok"
              onClick={this.handleCloseModal}
            />
          </Modal>
        )}
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="customer_delete_room" defaultMessage="Delete room" />}
              message={<FormattedMessage id="customer_deleted_confirmation_message" defaultMessage="Are you sure to delete this customer?" />}
              buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteCustomer}
              onCancel={this.handleCancel}
              onLoader={loading}
            />
          </Modal>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
              onClick={this.handleBackToCustomers}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
EditCustomer.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
  getLoginUser: PropTypes.func,
  location: PropTypes.node,
  path: PropTypes.string,
};
EditCustomer.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
  getLoginUser: () => {},
  location: undefined,
  path : ""
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(EditCustomer);
