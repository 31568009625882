import React from "react";
import { FormattedMessage } from "react-intl";

const PageNotFound = () => (
  <h1>
    <FormattedMessage id="title_page_not_found" />
    ...
  </h1>
);
export default PageNotFound;
