import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import FilterButton from "../../components/Filter/FilterButton";
import { Div, MDLabel } from "../../styles/Styles";
import Theme from "../../styles/Theme";
import ListScreen from "../../components/ListScreen/ListScreen";
import Pagination from "../../components/Pagination/Pagination";
import ListScreenLoader from "../../components/Loader/ListScreenLoader";
import Constants from "../../shared/Constants";

const { sorting: { type: sortType, keys: { sortBy, sort } } } = Constants;

const AssignmentsTab = ({
  redirectTo, handleGotoPage, filterDataValues, meta,
  header, value, fetchData, loading, orderData, isOrder, isCustomers,
}) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortedBy, setSortedBy] = useState("created_at");

  const [toBeFilteredData, setToBeFilteredData] = useState({});
  const [filterOptions, setFilterOptions] = useState(filterDataValues);

  /**
   *
   * @async
   * @function fetchAssignments Fetches assignments
   * @param {String} searchInput search text
   *
   */
  const fetchAssignments = (searchInput, options, filterData = undefined) => {
    const filter = filterData || toBeFilteredData;
    const queryParams = [
      ["pageNumber", searchInput ? 1 : meta.pageNumber],
      ["pageSize", meta.pageSize],
      [sortBy, sortedBy],
      [sort, sortAscending ? sortType.ASC : sortType.DESC],
      ["filter", filter],
    ];
    fetchData(options, queryParams);
  };

  /**
   *
   * Calls fetchAssignments function when the below values changes
   * [loading, meta.pageNumber,location, sortAscending, sortedBy]
   *
   */
  useEffect(() => {
    fetchAssignments();
  }, [sortAscending, sortedBy, meta.pageNumber]);

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  /**
   *
   * Handles sorting of columns in the list screen
   * @param {object} item column data
   * @returns sorted list
   *
   */
  const handleSorting = (item) => () => {
    if (sortedBy === item) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
      setSortedBy(item);
    }
  };

  const handleFilterApply = async (data = [], options = undefined) => {
    const filteredData = {};
    data.map((obj) => {
      if (filteredData[obj.name]) {
        filteredData[obj.name].push(obj.value);
      } else {
        filteredData[obj.name] = [];
        filteredData[obj.name].push(obj.value);
      }
      return obj;
    });
    setToBeFilteredData(filteredData);
    setFilterOptions(options);
    setIsFilterOpen(false);
    await fetchAssignments(true, options, filteredData);
  };

  return (
    <Div>
      <Div justifyContent="space-between" display="flex" pb={4}>
        <Div>
          <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
            <FormattedMessage id={isOrder ? "common_orders" : "user_assignments_tab"} />
          </MDLabel>
        </Div>
        <Div className="row m-0">
          <FilterButton
            open={isFilterOpen}
            options={filterOptions}
            closeFilter={handleCloseFilter}
            onApply={handleFilterApply}
          />
        </Div>
      </Div>
      {loading && <ListScreenLoader />}
      {!loading && (
        <>
          <ListScreen
            moreButton
            isOrders
            header={header}
            value={value}
            data={orderData}
            onClick={redirectTo}
            handleSorting={handleSorting}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            isCustomers={isCustomers}
            dataNotFoundMessage={<FormattedMessage id={isOrder ? "orders_not_found" : "assignments_not_found"} />}
          />
          {meta.pages > 1 && (
            <Div py={4}>
              <Pagination gotoPage={handleGotoPage} cursor={meta} />
            </Div>
          )}
        </>
      )}
    </Div>
  );
};
AssignmentsTab.propTypes = {
  redirectTo: PropTypes.func,
  filterDataValues: PropTypes.node,
  header: PropTypes.node,
  value: PropTypes.node,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  orderData: PropTypes.node,
  meta: PropTypes.node,
  handleGotoPage: PropTypes.func,
  isOrder: PropTypes.bool,
  isCustomers: PropTypes.bool,
};
AssignmentsTab.defaultProps = {
  redirectTo: () => {},
  filterDataValues: [],
  header: [],
  value: [],
  fetchData: () => {},
  loading: false,
  orderData: [],
  meta: {},
  handleGotoPage: () => {},
  isOrder: false,
  isCustomers: false,
};
export default AssignmentsTab;
