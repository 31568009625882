import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import RoomModel from "../models/Room";
import { queryParamParser } from "../shared/utils";

const {
  apiEndPoints: { endPointUrl = {} },
  HTTP_STATUS: { OK, CREATED }, requestType: { GET },
  input: { param: { roomId } },
} = Constants;

export async function getRoomsList(params = []) {
  const url = `${endPointUrl.ROOMS}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function getAssignmentsList(id, params) {
  const param = [[roomId, id]];
  const queryParams = [...params, ...param];
  const url = `${endPointUrl.ASSIGNMENTS}${queryParamParser(queryParams)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function getAssignmentFilters(id) {
  const url = `${endPointUrl.ROOMS}/${id}/${endPointUrl.ASSIGNMENTS}/${endPointUrl.FILTERS}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

class RoomService extends ApiService {
  async getRoomDetails(id) {
    this.roomsModel = new RoomModel({});
    const response = await this.get(`${endPointUrl.ROOMS}/${id}`);
    const { data: { data = {} } = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.roomsModel.fromRoomDetailsJson(data);
    }
  }

  async addRoom(payload) {
    const response = await this.post(`${endPointUrl.ROOMS}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (CREATED !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateRoom(id, payload) {
    const response = await this.put(`${endPointUrl.ROOMS}/${id}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async deleteRoom(id) {
    const response = await
    this.delete(`${endPointUrl.ROOMS}/${id}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }
}
export default RoomService;
