import styled from "styled-components";

export const Slide = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: ${({ open }) => (open ? "block" : "none")};
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
`;
export const DialogBox = styled.div`
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  min-width: 35%;
  margin: 0;
  left: 0;
  position: fixed;
  top: 0;
  height: 100vh;
`;
export const BodyContent = styled.div`
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
`;
export const Content = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  padding: 5px 20px;
  background-color: #f1f5f7 !important;
  transition: transform 0.5s ease; /* our nice transition */
  height: 100%;
  overflow: ${({ open }) => (open ? "visible" : "hidden")};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  -webkit-transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
`;
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: ${({ open }) => (open ? "0.5" : "1")};
  display: ${({ open }) => (open ? "inherit" : "none")};
`;
