import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Div, MDLabel, CardHeader, MDInputValue, Span } from "../../styles/Styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";

const { fontSizes } = Theme;

export default function AvailabilitySlotModal({ onClose, slotDetails = {}, onSubmit, hasManageCalendarAccess }) {
  const serviceProviderData = useSelector((state) => state.AppReducer.serviceProvider);

  slotDetails?.data?.sort((a, b) => {
    const dateA = new Date(a.start);
    const dateB = new Date(b.start);
    return dateA - dateB;
  });

  const { data: slotDetail = [], isPastDate = true } = slotDetails || {};

  const handleClose = () => {
    onClose();
  };

  const handleSelectType = (value) => () => {
    onClose();
    onSubmit({ ...value, isTimeSelected: true }, isPastDate);
  };

  const getSPName = (slot) => {
    const serviceProvider = serviceProviderData.filter((sp) => sp.id === slot.sp_id);
    const { full_name: spName = "" } = serviceProvider.length ? serviceProvider[0] : {};
    return spName;
  };

  const uniqueSlots = [...new Map(slotDetail.map((item) => [item.slot_id, item])).values()];

  return (
    <Modal className="availability-form">
      <Div width={["350px", "510px"]}>
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id="label_choose_availability" defaultMessage="Choose Availability" />
          </MDLabel>
        </CardHeader>
        <Div
          display="flex"
          flexDirection="column"
          className="col-11"
          paddingTop="1rem"
          margin="auto"
          justifyContent="center">
          <MDInputValue mb={20}>
            {isPastDate ? (
              <FormattedMessage id="label_past_days" defaultMessage="You cant edit slots for past days" />
            ) : (
              <FormattedMessage
                id="label_multiple_slots"
                defaultMessage="There is several aviabilities on this time. Please choose:"
              />
            )}
          </MDInputValue>
          <Div display="inline-flex" flexWrap="wrap" justifyContent="space-between">
            {uniqueSlots &&
              uniqueSlots.map((slot) => {
                const {
                  id,
                  service_provider_slot: { start_time: startTime },
                  service_provider_slot: { end_time: endTime },
                } = slot || {};

                return (
                  <Div width={["96%","96%","96%","48%"]} my={2} p={2} key={id} bg={Colors.LightOrangeRgba} onClick={handleSelectType(slot)} cursor="pointer">
                    {hasManageCalendarAccess && <MDLabel>{getSPName(slot)}</MDLabel>}
                    <Span color={Colors.DarkGrey} fontSize={fontSizes.xs}>{`${startTime} - ${endTime}`}</Span>
                  </Div>
                );
              })}
          </Div>
        </Div>
        <hr />
        <Div
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="center"
          alignItems="stretch"
          mb={20}
          mx={40}>
          <Div mr={[0, 4]} ml={[1, 0]} my={[1, 0]}>
            <Button
              width={[1, 160]}
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              secondary
              onClick={handleClose}
            />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}

AvailabilitySlotModal.propTypes = {
  onClose: PropTypes.func,
  slotDetails: PropTypes.node,
  onSubmit: PropTypes.func,
  hasManageCalendarAccess: PropTypes.bool,
};

AvailabilitySlotModal.defaultProps = {
  onClose: undefined,
  slotDetails: [],
  onSubmit: undefined,
  hasManageCalendarAccess: false,
};
