import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Theme from "../../../styles/Theme";
import {
  MDLabel, Box, Span,
} from "../../../styles/Styles";
import { Item } from "../../../components/Sidemenu/Styles";
import { MDInputValueBox } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";

const TotalPriceContainer = (props) => {
  const { totalRate, paymentData } = props;
  return (
    <>
      <Box mt={4} mb={3} pb={2}>
        <MDLabel fontSize={Theme.fontSizes.xl}>
          <FormattedMessage id="total_payment" defaultMessage="Total payment" />
        </MDLabel>
      </Box>
      <Box mb={2} pb={1}>
        <MDInputValueBox type="colon_add" mr={2}>
          <FormattedMessage id="common_total" defaultMessage="Total" />
          <Span>:</Span>
        </MDInputValueBox>
        <MDLabel fontSize={Theme.fontSizes.xl} pr={1}>
          <FormattedMessage id="common_sek" defaultMessage="SEK" />
        </MDLabel>
        <MDLabel fontSize={Theme.fontSizes.xl}>{totalRate}</MDLabel>
      </Box>
      <Box>
        {paymentData.map((data, index) => (
          <Fragment key={Item.id}>
            <MDLabel fontSize={Theme.fontSizes.regular} px={1}>
              <FormattedMessage id="common_sek" defaultMessage="SEK" />
            </MDLabel>
            <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
              {data.total}
            </MDLabel>
            <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
              <FormattedMessage id="for_part" defaultMessage="for part" />
            </MDLabel>
            <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
              {index + 1}
            </MDLabel>
            {index < paymentData.length - 1 ? " + " : ""}
          </Fragment>
        ))}
      </Box>
      <Box>
        <MDLabel fontSize={Theme.fontSizes.xl} my={4}>
          <FormattedMessage id="payment_method" defaultMessage="Payment method" />
        </MDLabel>
      </Box>
      <Box>
        <MDLabel fontSize={Theme.fontSizes.regular}>
          <FormattedMessage id="choose_payment_method" defaultMessage="Choose here your payment method:" />
        </MDLabel>
      </Box>
    </>
  );
};
TotalPriceContainer.propTypes = {
  totalRate: PropTypes.node,
  paymentData: PropTypes.node,
};
TotalPriceContainer.defaultProps = {
  totalRate: 0,
  paymentData: {},
};
export default TotalPriceContainer;
