/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Luhn from "luhn";
import PropTypes from "prop-types";
import UserModel from "../../../models/UserProfile";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import TextArea from "../../../components/Input/TextArea";
import SelectBox from "../../../components/Input/SelectBox";
import UploadPicture from "../../../components/UploadSection/UploadPicture";
import {
  MDLabel, Div, IsDesktop, IsMobile, MDImage, Span, FormWrapper, MDInputValue, Card, StyledCardWrapper,
} from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import UserService from "../../../services/UserService";
import ServiceList from "../../../services/ServicesPageService";
import Config from "../../../config";
import FormLoader from "../../../components/Loader/FormLoader";
import CheckBoxAccordian from "../../../components/Accordian/CheckBoxAccordian";
import { ModulePermissions, getAssociatedOrganizations } from "../../../shared/usePermissions";
const {
  language,
  input: { name: inputNames },
  icons,
  dogSize,
} = Constants;

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  description: Yup.string().min(0, language.validation_length_password_input)
    .max(500, language.validation_length_about_user).nullable(),
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" />)
    .required(<FormattedMessage id="validation_empty_email_input" />),
  bookingNoticeTime: Yup.string().required(language.validation_invalid_value).test(
    "Enter an value between 1 to 24",
    language.validation_invalid_value,
    (value) => value >= 0 && value <=24,
  ).matches(/^\d{1,24}$|^\d{2}(?=.$)\d*\.\d*\d$/, language.validation_invalid_value),
  role: Yup.object().required(language.validation_select_type_user),
  profilePic: Yup.object().required(language.validation_profile_picture),
  id: Yup.string().test("test-number",
    language.validation_invalid_id,
    (value) => Luhn.validate(value && value.replace("-", ""))).required(language.validation_enter_id),
  phone: Yup.array()
    .of(
      Yup.string()
        .typeError(language.validation_valid_phone_number)
        .required(language.validation_valid_phone_number)
        .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
    )
    .required(language.validation_valid_phone_number)
    .min(1, language.validation_valid_phone_number),
});
class CreateUserForm extends Component {
  userService = new UserService(Config.apiBase);

  serviceList = new ServiceList(Config.apiBase);

  constructor(props) {
    super(props);


    this.state = {
      isTouched: false,
      userOptions: Constants.options.UserOptions.map((o) => ({ ...o, isSelected: false })),
      userRights: Constants.options.Rights.map((right) => ({ ...right, isSelected: false })),
      orderUserRights: [
        ...Constants.options.Rights,
        { label: language.common_delete, value: "delete" }
      ].map((right) => ({ ...right, isSelected: false })),
      userRoles: Constants.options.UserRoles.map((role) => ({ ...role, isSelected: false })),
      superAdminRight: [{ label: language.option_manage_calendar, value: "manage", isSelected: false }],
      userAccessList: [{
        organisation_id: "",
        calendar: "",
        room: "",
        order: "",
        dog: "",
        customer: "",
        services: "",
        users: "",
        assignment: "",
        organisation: "",
        rights: "",
        subscription: ""
      }],
      existingPages: [
        { label: language.common_calendar, value: "calendar" },
        { label: language.common_rooms, value: "room" },
        { label: language.common_customers, value: "customer" },
        { label: language.common_dogs, value: "dog" },
        { label: language.common_orders, value: "order" },
        { label: language.common_services, value: "services" },
        { label: language.common_users, value: "users" },
        { label: language.common_assignments, value: "assignment" },
        { label: language.list_header_organisations, value: "organisation" },
        { label: language.subscription, value: "subscription"}
      ],
      organisations: [],
      services: [],
      data: {
        firstName: "",
        lastName: "",
        description: "",
        id: "",
        email: "",
        bookingNoticeTime: "",
        role: "",
        profilePic: "",
        serviceProviderOrgs: [""],
        phone: [""],
      },
      loading: false,
      serviceableDogSize: [
        dogSize.small,
        dogSize.medium,
        dogSize.large,
      ],
      showPopup: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const { edit, data } = this.props;
    await this.fetchOrganisations();
    await this.fetchServices();
    if (edit && data) {
      await this.handleFormatData();
    }
    this.setState({ loading: false });
  };

  handleUserAccessList=(id) => (event) => {
    const { name, value } = event.target;
    const { userAccessList } = this.state;
    userAccessList[id][name] = value;
    if (name !== inputNames.rights && name !== inputNames.organisation_id) {
      const { rights: { label = "" } } = userAccessList[id];
      if (label) {
        userAccessList[id].rights.label = label.indexOf(language.common_customize) !== -1 ? `${label}` : `${label}/${language.common_customize}`;
      }
    }
    this.setState({ userAccessList });
    if (name === inputNames.rights) {
      this.handleUserRoleManagement(id);
    }
  };

  handleBlurForSelectComp=() => {
    this.setState({ isTouched: true });
  }

  handleAddAccessToNewOrganisation=() => {
    const { userAccessList } = this.state;
    userAccessList.push({
      organization_id: "",
      calendar: "",
      room: "",
      order: "",
      dog: "",
      customer: "",
      rights: "",
      subscription: ""
    });
    this.setState({ userAccessList });
  };

  handleRemoveOrganisation=(index) => () => {
    const { userAccessList } = this.state;
    const userAccess = userAccessList.filter((_o, i) => index !== i);
    this.setState({ userAccessList: userAccess });
  }

  handleUserRoleManagement=(id) => {
    const { userAccessList } = this.state;
    const { Roles, Rights, SuperAdminRight } = Constants.options;
    const edit = Rights[0];
    const read = Rights[1];
    const ntg = Rights[2];
    const manage = SuperAdminRight[0];
    const { rights: { value: userValue = "" } } = userAccessList[id];
    if (userValue === Roles[0]) {
      userAccessList[id].calendar = edit;
      userAccessList[id].room = edit;
      userAccessList[id].customer = edit;
      userAccessList[id].services = edit;
      userAccessList[id].assignment = edit;
      userAccessList[id].users = edit;
      userAccessList[id].dog = edit;
      userAccessList[id].organisation = read;
      userAccessList[id].order = edit;
      userAccessList[id].subscription = edit;
    }
    if (userValue === Roles[1]) {
      userAccessList[id].calendar = manage;
      userAccessList[id].room = edit;
      userAccessList[id].customer = edit;
      userAccessList[id].services = edit;
      userAccessList[id].assignment = edit;
      userAccessList[id].users = edit;
      userAccessList[id].dog = edit;
      userAccessList[id].organisation = edit;
      userAccessList[id].order = edit;
      userAccessList[id].subscription = edit;
    }
    if (userValue === Roles[2]) {
      userAccessList[id].calendar = edit;
      userAccessList[id].room = read;
      userAccessList[id].customer = edit;
      userAccessList[id].services = edit;
      userAccessList[id].assignment = edit;
      userAccessList[id].users = read;
      userAccessList[id].dog = edit;
      userAccessList[id].organisation = read;
      userAccessList[id].order = edit;
      userAccessList[id].subscription = ntg;
    }
    if (userValue === Roles[3]) {
      userAccessList[id].calendar = read;
      userAccessList[id].room = read;
      userAccessList[id].customer = read;
      userAccessList[id].services = read;
      userAccessList[id].assignment = read;
      userAccessList[id].users = ntg;
      userAccessList[id].dog = read;
      userAccessList[id].organisation = ntg;
      userAccessList[id].order = read;
      userAccessList[id].subscription = ntg;
    }
    if (userValue === Roles[4]) {
      userAccessList[id].calendar = edit;
      userAccessList[id].room = edit;
      userAccessList[id].customer = edit;
      userAccessList[id].services = edit;
      userAccessList[id].assignment = edit;
      userAccessList[id].users = edit;
      userAccessList[id].dog = edit;
      userAccessList[id].organisation = edit;
      userAccessList[id].order = read;
      userAccessList[id].subscription = edit;
    }
    if (userValue === Roles[5]) {
      userAccessList[id].calendar = edit;
      userAccessList[id].room = ntg;
      userAccessList[id].customer = ntg;
      userAccessList[id].services = ntg;
      userAccessList[id].assignment = ntg;
      userAccessList[id].users = ntg;
      userAccessList[id].dog = ntg;
      userAccessList[id].organisation = ntg;
      userAccessList[id].order = ntg;
      userAccessList[id].subscription = ntg;
    }
    this.setState({ userAccessList });
  };

  handleFormatData = () => {
    const { data: userData } = this.props;
    if (userData) {
      const {
        data = {}, selectedServices = [], userAccess: accessGivenTopages = [],
      } = userData;
      const userAccess = accessGivenTopages && accessGivenTopages.length ? accessGivenTopages : [{
        organisation_id: "",
        calendar: "",
        room: "",
        order: "",
        dog: "",
        customer: "",
        services: "",
        users: "",
        assignment: "",
        organisation: "",
        rights: "",
        subscription: ""
      }];
      const { organisations } = this.state;
      let { services, userOptions } = this.state;
      services = services.map((obj) => {
        const filteredService = selectedServices.filter((service) => service.id === obj.id);
        if (filteredService.length > 0) {
          return {
            ...obj,
            isSelected: true,
            serviceable_dog_size: filteredService[0].serviceable_dog_size,
          };
        }
        return { ...obj };
      });
      userOptions = userOptions.map((role) => {
        if (role !== undefined) {
          if (role && role.value === data.role) {
            data.role = role;
            return { ...role, isSelected: true };
          }
          return { ...role };
        }
        return "";
      });

      const userRights = userAccess.map((obj) => {
        const user = {...obj};
        const orgId = user.organisation_id;
        const { serviceProviderOrgs = [] }=data;
        const org = serviceProviderOrgs.find((o) => o.value === orgId);
        user.organisation_id = org;
        return user;
      });
      this.setState({
        data,
        services,
        userAccessList: userRights,
        userOptions,
        organisations,
      });
    }
  };

  /**
   * Shows an popup info about the minimum time before the order.
   */
   handlePopUp = () => {
     const { showPopup } = this.state;
     this.setState({ showPopup: !showPopup });
   };

  fetchServices=async () => {
    const { serviceableDogSize } = this.state;
    let { services } = this.state;
    try {
      const response = await this.serviceList.getServices();
      const { data = [] } = response;
      if (data) {
        services = data.map((obj) => {
          const {
            name = "", id = "", duration: { small: smallDuration = "" }, price: { small: smallPrice = "" },
            profile_pic: profilePic = {}, serviceable_dog_size: size = serviceableDogSize,
          } = obj;
          const { url = "" } = profilePic || {};
          return {
            label: name,
            value: id,
            id,
            duration: smallDuration,
            price: smallPrice,
            profilePic: url,
            serviceable_dog_size: size,
          };
        });
        this.setState({ services });
      }
    } catch (e) {
      console.error(e);
    }
  }

  fetchOrganisations = async () => {
    const { userDetails = {} } = this.props;
    const { permissions = [], organization: { organization_details: allOrganisations = [] } = {} } = userDetails.permissions
      ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    const organisations = getAssociatedOrganizations(ModulePermissions.User, Constants.WRITE, permissions, allOrganisations);
    this.setState({ organisations });
  };

  handleSelectedServices = (service) => () => {
    const { services, serviceableDogSize } = this.state;
    const { edit } = this.props;
    let selectedServices = [];
    if (edit) {
      selectedServices = service;
    } else {
      selectedServices = services.map((obj) => {
        const src = obj;
        if (obj.id === service.id) {
          src.isSelected = src.isSelected ? !src.isSelected : true;
          src.serviceable_dog_size = serviceableDogSize;
          return { ...src };
        }
        return { ...obj };
      });
    }
    this.setState({
      services: selectedServices,
      isTouched: true,
    });
  };

  handleReset = () => {
    const { edit, onCancel } = this.props;
    if (edit) {
      onCancel();
    } else {
      let {
        userOptions, userRights, orderUserRights, userRoles, organisations, services,
      } = this.state;
      userOptions = userOptions.map((o) => ({ ...o, isSelected: false }));
      userRights = userRights.map((o) => ({ ...o, isSelected: false }));
      orderUserRights = orderUserRights.map((o) => ({ ...o, isSelected: false }));
      userRoles = userRoles.map((o) => ({ ...o, isSelected: false }));
      organisations = organisations.map((o) => ({ ...o, isSelected: false }));
      services = services.map((o) => ({ ...o, isSelected: false }));
      this.setState({
        userAccessList: [{
          organisation_id: "",
          calendar: "",
          room: "",
          order: "",
          dog: "",
          customer: "",
          services: "",
          users: "",
          assignment: "",
          organisation: "",
          rights: "",
          subscription:""
        }],
        isTouched: false,
        userOptions,
        userRights,
        orderUserRights,
        userRoles,
        organisations,
        services,
      });
    }
  }

  handleSubmit = async (data, props) => {
    const { setErrors } = props;
    this.setState({ loading: true });
    const {
      handleBackToUsers, edit, userId, submitData,
    } = this.props;
    const {
      userAccessList, services,
    } = this.state;
    const userData = data;
    userData.service_list = services.filter((service) => (service && service.isSelected))
      .map((obj) => ({
        service_id: obj.id,
        serviceable_dog_size: obj.serviceable_dog_size,
      }));
    userData.userAccess = userAccessList;
    if (userData.role !== Constants.role.Freelancer) {
      userData.organization_linked = userAccessList.map((o) => o.organisation_id.value);
    }
    try {
      this.profileModel = new UserModel({});
      if (edit) {
        const response = await this.userService.updateUser(
          userId, this.profileModel.toJson(userData),
        );
        if (response) {
          this.setState({ loading: false }, submitData(true));
        }
      } else {
        const response = await this.userService.addUser(this.profileModel.toJson(userData));
        if (response) {
          this.setState({ loading: false });
          handleBackToUsers(true);
        }
      }
    } catch (e) {
      const { response: { data: { errors = {}, message = "" }, status = "" } } = e;
      if (status === 400) {
        if (message.includes("Email")) {
          setErrors({ email: Constants.language.validate_email_already_exists });
        }
        if (message.includes("Organization Number")) {
          setErrors({ id: Constants.language.validate_id_already_exists });
        }
        this.setState({ loading: false });
      } else {
        const { email = "", organization_number: id = "" } = errors;
        if (email || id) {
          if (email && id) {
            setErrors({
              email: Constants.language.validate_email_already_exists,
              id: Constants.language.validate_id_already_exists,
            });
          }
          if (email && !id) {
            setErrors({ email: Constants.language.validate_email_already_exists });
          }
          if (id && !email) {
            setErrors({ id: Constants.language.validate_id_already_exists });
          }
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          submitData();
        }
      }
    }
  };

  handleServiceList = (seletedService) => {
    this.setState({ services: seletedService, isTouched: seletedService.length > 0 });
  }

  getUserRightsForModule = (item) => {
    const { user_access: { rights: userType = "" } = {} } = this.props.userDetails || {};
    const {
      userRights, orderUserRights, superAdminRight
    } = this.state;
    if  (item && item.value === inputNames.calendar
      && userType.includes(Constants.SUPER_ADMIN)) {
      return [...(item.value === "order" ? orderUserRights : userRights), ...superAdminRight];
    }
    return (item.value === "order" ? orderUserRights : userRights);
  };

  render() {
    const {
      deleteUser, edit, disableData
    } = this.props;
    const {
      data, userOptions, loading,
      organisations, services,
      userRoles, existingPages, userAccessList,
      isTouched, showPopup,
    } = this.state;
    return (
      <>
        <Formik
          enableReinitialize
          // validateOnMount
          initialValues={data}
          validationSchema={ValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {(props) => {
            const {
              values, errors, handleChange,
              handleBlur, handleSubmit, isValid, touched, handleReset,
            } = props;
            const { phone = [""] } = values;

            let isAddMoreDisabled = false;
            phone.forEach((num) => {
              if ((num && num.length < 9) || !num) {
                isAddMoreDisabled = true;
              }
            });

            let isOrgDisabled = false;
            userAccessList.forEach((org) => {
              if (!org.organisation_id || !org.rights) {
                isOrgDisabled = true;
              }
            });
            const { rights = "", organisation_id: orgId = "" } = (userAccessList && userAccessList[0]) || {};
            const isuserRightsAdded = rights && orgId;
            const isDisabled = (isValid && isuserRightsAdded
            && (Object.keys(touched).length > 0 || isTouched) && userAccessList
            && services.filter((service) => service.isSelected).length > 0);
            return (
              <>
                {loading && (
                  <FormWrapper>
                    <FormLoader />
                  </FormWrapper>
                )}
                {!loading && (
                  <>
                    <FormWrapper>
                      <SelectBox
                        startIcon={icons.UserSettingsIcon}
                        onChange={handleChange}
                        onBlur={this.handleBlurForSelectComp}
                        value={values.role}
                        name={inputNames.role}
                        options={userOptions}
                        placeholder={language.placeholder_choose}
                        label={language.label_type_of_user}
                        formikValues={props}
                        disabled={disableData}
                      />
                      <UploadPicture
                        label={language.label_profile_picture}
                        value={values.profilePic}
                        name={inputNames.profilePic}
                        files={values.profilePic}
                        formikValues={props}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableData}
                      />
                    </FormWrapper>
                    <Form>
                      <FormWrapper>
                        <TextBox
                          startIcon={icons.ProfileIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.placeholder_name}
                          placeholder={language.placeholder_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          name={inputNames.firstName}
                          formikValues={props}
                          IconHeight="20px"
                          disabled={disableData}
                        />
                        <TextBox
                          startIcon={icons.ProfileIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.placeholder_surname}
                          placeholder={language.placeholder_surname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          name={inputNames.lastName}
                          formikValues={props}
                          IconHeight="20px"
                          disabled={disableData}
                        />
                        <TextArea
                          containerProps="m-auto justify-content-center"
                          placeholder={language.label_about_user}
                          label={
                            <>
                              <MDLabel className="mt-3 pt-1 pb-2 mb-1" fontSize={Theme.fontSizes.regular} disable={disableData}>
                                {language.label_about_user}
                              </MDLabel>
                              <MDInputValue className="pb-2 mb-1" fontSize={Theme.fontSizes.regular} textAlign="left" disable={disableData}>
                                {language.description_about_user}
                              </MDInputValue>
                            </>
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          name={inputNames.description}
                          formikValues={props}
                          disabled={disableData}
                          width="100%"
                        />
                        <TextBox
                          startIcon={icons.HashIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.label_personal_number}
                          placeholder={language.placeholder_personal_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.id}
                          name={inputNames.id}
                          formikValues={props}
                          IconHeight="20px"
                          type="numeric"
                          disabled={disableData}
                        />
                        <MDLabel className="mt-3 mb-2" fontSize="16px" disable={disableData}>
                          {language.label_phone}
                        </MDLabel>
                        <FieldArray
                          name={inputNames.phone}
                          render={({ push, remove }) => (
                            <div>
                              {values.phone && values.phone.map((ph, index) => (
                                <Div className="d-flex mb-3" key={index}>
                                  {values.phone.length > 1 && (
                                    <MDImage
                                      src={Constants.icons.MinusIcon}
                                      alt="icon"
                                      onClick={() => remove(index)}
                                      role="button"
                                      className={`${disableData && "disabled-state"} mr-3`}
                                    />
                                  )}
                                  <TextBox
                                    startIcon={icons.PhoneIcon}
                                    containerProps="m-auto justify-content-center"
                                    placeholder={language.telephone_label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={ph}
                                    showError={errors.phone && errors.phone[index]}
                                    isTouched={touched.phone && touched.phone[index]}
                                    name={`${inputNames.phone}.${index}`}
                                    IconHeight="20px"
                                    width="100%"
                                    disabled={disableData}
                                    phone
                                  />
                                </Div>
                              ))}
                              <MDLabel
                                onClick={() => push("")}
                                disable={disableData || isAddMoreDisabled || phone.length === 3}
                              >
                                <MDImage src={Constants.icons.AddIcon} alt="icon" className="mr-2" role="button" />
                                <Span color={Theme.colors.primary} textDecoration="underline" cursor="pointer" disable={disableData || isAddMoreDisabled || phone.length === 3}>
                                  <FormattedMessage id="label_add_phone" />
                                </Span>
                              </MDLabel>
                            </div>
                          )}
                        />
                        <TextBox
                          startIcon={icons.EmailIcon}
                          containerProps="m-auto justify-content-center"
                          label={language.label_email}
                          placeholder={language.label_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name={inputNames.email}
                          formikValues={props}
                          IconHeight="20px"
                          disabled={disableData}
                        />
                      </FormWrapper>
                      <>
                        <MDLabel fontSize={Theme.space.m} className="py-2" mt={34} lineHeight={Theme.lineHeights.tight}>
                          <FormattedMessage id="user_choose_services" />
                        </MDLabel>

                        <MDLabel fontFamily={Theme.fonts.regular}>
                          <FormattedMessage id="users_choose_services_description" />
                        </MDLabel>

                        <Div mt="23px" mb="40px" disable={disableData}>
                          {services && (
                            <CheckBoxAccordian
                              services={services}
                              handleOnChange={this.handleServiceList}
                              isEdit
                            />
                          )}
                        </Div>
                      </>
                      <MDLabel fontSize={Theme.space.m} pt={2} pb={20} lineHeight={Theme.lineHeights.tight}>
                        <FormattedMessage id="booking_notice_time_title" defaultMessage="Minimum time to booking" />
                      </MDLabel>

                      <MDLabel fontSize={Theme.fontSizes.regular} fontFamily={Theme.fonts.regular} mb={2}>
                        <FormattedMessage id="booking_notice_time_subtitle" defaultMessage="From 1 till 24 hours" />
                      </MDLabel>
                      <Div display="flex">
                        <TextBox
                          startIcon={icons.ClockIcon}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bookingNoticeTime}
                          name={inputNames.bookingNoticeTime}
                          formikValues={props}
                          IconHeight="16px"
                          width={160}
                          disabled={disableData}
                        />
                        <MDImage
                          src={icons.QuestionMarkIcon}
                          alt="icon"
                          pl={2}
                          onMouseOver={this.handlePopUp}
                          onMouseLeave={this.handlePopUp}
                          useCursorPointer
                        />
                      </Div>
                      {showPopup && (
                        <StyledCardWrapper position="relative">
                          <Card position="absolute !important" bottom="-13px !important" left="195px !important" width={["200px", "265px"]}>
                            <Div py={16} pl={["16px", "16px", "27px", "27px"]} pr="11px">
                              <MDLabel mb={0}>
                                <FormattedMessage id="booking_notice_time_info" defaultMessage="Set here the minimum time before the order you need for preparations." />
                              </MDLabel>
                            </Div>
                          </Card>
                        </StyledCardWrapper>
                      )}
                      <FormWrapper mt={30}>
                        <>
                          {userAccessList.map((userRight, index) => (
                            <>
                              <MDLabel fontSize={Theme.space.m} className="py-2" lineHeight={Theme.lineHeights.tight}>
                                {userAccessList.length > 1 && (
                                  <MDImage
                                    src={Constants.icons.MinusIcon}
                                    alt="icon"
                                    onClick={this.handleRemoveOrganisation(index)}
                                    className={`${disableData && "disabled-state"} mr-3 cursor-pointer`}
                                    role="button"
                                  />
                                )}
                                <FormattedMessage id="organisation_rights" />
                                {userAccessList.length > 1 && (`  ${index + 1}`)}
                                :
                              </MDLabel>
                              <SelectBox
                                startIcon={icons.HomeIcon}
                                containerProps="m-auto justify-content-center"
                                placeholder={language.placeholder_choose}
                                onChange={this.handleUserAccessList(index)}
                                onBlur={this.handleBlurForSelectComp}
                                label={language.label_organisation}
                                value={userRight.organisation_id}
                                name={inputNames.organisation_id}
                                formikValues={props}
                                options={organisations}
                                width="100%"
                                disabled={disableData}
                                key={index}
                              />

                              <SelectBox
                                key={index+"1"}
                                startIcon={icons.HomeIcon}
                                containerProps="m-auto justify-content-center"
                                placeholder={language.placeholder_choose}
                                onChange={this.handleUserAccessList(index)}
                                onBlur={this.handleBlurForSelectComp}
                                label={language.label_rights_preset}
                                value={userRight.rights}
                                name={inputNames.rights}
                                formikValues={props}
                                options={userRoles}
                                width="100%"
                                disabled={disableData}
                              />

                              {existingPages.map((right,i) => (

                                <Div className="d-flex my-3" key={i}>
                                  <Div className="col-auto p-0" alignSelf="center" width="110px">
                                    <MDLabel>
                                      {right.label}
                                    </MDLabel>
                                  </Div>
                                  <Div className="col p-0">
                                    <SelectBox
                                      containerProps="m-auto justify-content-center"
                                      placeholder={language.placeholder_choose}
                                      onChange={this.handleUserAccessList(index)}
                                      onBlur={this.handleBlurForSelectComp}
                                      value={userRight[right.value]}
                                      name={inputNames[right.value]}
                                      formikValues={props}
                                      options={this.getUserRightsForModule(right)}
                                      width="100%"
                                      disabled={disableData}
                                    />
                                  </Div>

                                </Div>
                              ))}
                            </>
                          ))}
                          <MDLabel onClick={this.handleAddAccessToNewOrganisation} className="mb-3" disable={disableData || isOrgDisabled}>
                            <MDImage src={Constants.icons.AddIcon} alt="icon" className="mr-2" role="button" />
                            <Span color={Theme.colors.primary} textDecoration="underline" cursor="pointer" disable={disableData || isOrgDisabled}>
                              <FormattedMessage id="label_add_organisation" />
                            </Span>
                          </MDLabel>
                        </>
                        <IsDesktop className="d-lg-flex">
                          <Button
                            primary
                            type="submit"
                            startIcon={icons.AssignmentsMenuIcon}
                            IconHeight="18px"
                            label={<FormattedMessage id="common_save" />}
                            onClick={handleSubmit}
                            disable={!isDisabled}
                            width="200px"
                            className="mr-3"
                            disabled={disableData}
                          />
                          <Button
                            secondary
                            type="reset"
                            label={<FormattedMessage id="common_cancel" />}
                            onClick={() => { handleReset(); this.handleReset(); }}
                            width="200px"
                            className="mx-3"
                            disabled={disableData}
                          />
                          {edit && (
                            <Button
                              primary
                              type="button"
                              startIcon={icons.DeleteIcon}
                              IconHeight="18px"
                              label={<FormattedMessage id="common_delete" />}
                              onClick={deleteUser}
                              width="200px"
                              className="ml-3"
                              disabled={disableData}
                            />
                          )}
                        </IsDesktop>
                        <IsMobile>
                          <Button
                            primary
                            type="submit"
                            startIcon={icons.AssignmentsMenuIcon}
                            IconHeight="18px"
                            label={<FormattedMessage id="common_save" />}
                            onClick={handleSubmit}
                            width="100%"
                            className="mb-3"
                            disabled={disableData}
                          />
                          <Button
                            secondary
                            type="reset"
                            label={<FormattedMessage id="common_cancel" />}
                            onClick={() => { handleReset(); this.handleReset(); }}
                            width="100%"
                            className="mb-4 mt-1"
                            disabled={disableData}
                          />
                          {edit && (
                            <Button
                              primary
                              type="button"
                              startIcon={icons.DeleteIcon}
                              IconHeight="18px"
                              label={<FormattedMessage id="common_delete" />}
                              onClick={deleteUser}
                              width="100%"
                              className="mb-4 mt-2"
                              disabled={disableData}
                            />
                          )}
                        </IsMobile>
                      </FormWrapper>
                    </Form>
                  </>
                )}

              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
CreateUserForm.propTypes = {
  edit: PropTypes.bool,
  deleteUser: PropTypes.func,
  handleBackToUsers: PropTypes.func,
  data: PropTypes.node,
  userId: PropTypes.string,
  disableData: PropTypes.bool,
  onCancel: PropTypes.func,
  submitData: PropTypes.func,
  userDetails: PropTypes.node,
};
CreateUserForm.defaultProps = {
  edit: false,
  deleteUser: undefined,
  handleBackToUsers: undefined,
  data: {},
  userId: null,
  disableData: false,
  onCancel: undefined,
  submitData: () => {},
  userDetails: {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});
export default connect(mapStateToProps)(CreateUserForm);
