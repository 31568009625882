import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import { MDLabel, Box, Span } from "../../../styles/Styles";
import { DivWrapper, MobileDiv } from "../Style";
import ServiceAppointment from "../../Cart/components/ServiceAppointment";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import { MDInputValueBox } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";

const OrderSummaryTotal = (props) => {
  const { item, index } = props;
  const caluculateTotal = (values = []) => {
    let totalPrice = 0;
    if (values.length) {
      values.map((obj) => {
        totalPrice += Number(obj.price || 0);
        return obj;
      });
    }
    return totalPrice;
  };
  return (
    <>
      <Box mt={4} mb={3} pb={1}>
        <MDLabel fontSize={Theme.fontSizes.medium} pr={1}>
          <FormattedMessage id="part" defaultMessage="Part" />
        </MDLabel>
        <MDLabel fontSize={Theme.fontSizes.medium}>{index + 1}</MDLabel>
      </Box>
      <Box mb={2}>
        <MDInputValueBox type="colon_eighteen" mr={2}>
          <FormattedMessage id="payment" defaultMessage="Payment" />
          <Span>:</Span>
        </MDInputValueBox>
        <MDLabel mr={1} fontSize={Theme.fontSizes.medium}>
          <FormattedMessage id="common_sek" defaultMessage="SEK" />
        </MDLabel>
        <MDLabel mr={1} fontSize={Theme.fontSizes.medium}>{item.total}</MDLabel>
      </Box>
      <Box>
        <MDLabel mr={1}>
          <FormattedMessage id="common_sek" defaultMessage="SEK" />
          {` ${item.service_detail.tagRate}`}
        </MDLabel>
        <MDLabel mr={1}>
          <FormattedMessage id="service" defaultMessage="Service" />
        </MDLabel>
        <FormattedMessage id="common_plus" defaultMessage=" + " />
        <MDLabel mx={1}>
          <FormattedMessage id="common_sek" defaultMessage="SEK" />
        </MDLabel>
        <MDLabel mr={1}>
          {caluculateTotal(item?.additional_service || [])}
        </MDLabel>
        <MDLabel>
          <FormattedMessage id="additional_options" defaultMessage="additional options" />
        </MDLabel>
      </Box>
      <MobileDiv className="m-auto" type="payment">
        <Box flexWrap="wrap" justifyContent="start !important" pt={3} px={2}>
          <DivWrapper type="wrapperdiv">
            <CommonCard
              avtarImg={item.service.bnr}
              header={item.service.name}
              leftIcon={item.service.tagIcon}
              leftContent={item.service.type}
              paddingLeft={10}
              type="responsive_view"
              boxDivType="choose_Item"
              mainType="no_cursor"
            />
          </DivWrapper>
          <DivWrapper type="wrapperdiv">
            <CommonCard
              avtarImg={item.service_detail.dogFace || item.service_detail.dogSizes}
              header={item.service_detail.size}
              leftIcon={item.service_detail.iconOne}
              leftContent={item.service_detail.time}
              rightIcon={item.service.tagIcon}
              rightContent={`${item.service_detail.tagRate} kr`}
              paddingLeft={10}
              mainType="no_cursor"
            />
          </DivWrapper>
          <DivWrapper type="wrapperdiv">
            <CommonCard
              avtarImg={item.service_location.bnr}
              header={item.service_location.title}
              leftIcon={item.service_location.locationIcon}
              leftContent={item.service_location.area}
              paddingLeft={10}
              mainType="no_cursor"
              type="responsive_view"
              boxDivType="choose_Item"
            />
          </DivWrapper>
          <DivWrapper type="wrapperdiv">
            <ServiceAppointment
              scheduledOn={item.service_schedule.scheduled_on}
              scheduledAt={item.service_schedule.scheduled_at}
              isEditable
            />
          </DivWrapper>
          <DivWrapper type="wrapperdiv">
            <CommonCard
              avtarImg={item.service_provider.face}
              header={item.service_provider.provider}
              leftIcon={item.service_provider.location}
              leftContent={item.service_provider.info}
              paddingLeft={10}
              mainType="no_cursor"
              type="responsive_view"
              boxDivType="choose_Item"
            />
          </DivWrapper>
          {item?.additional_service
            && Object.keys(item?.additional_service).length > 0
            && Object.keys(item.additional_service).map((key) => {
              const addons = item.additional_service[key];
              return (
                <DivWrapper type="responsivediv">
                  <CommonCard
                    header={addons?.name || addons?.addonName}
                    leftIcon={item.service_detail.iconOne}
                    leftContent={`+${addons?.duration || addons?.addonDuration} min`}
                    rightIcon={item.service.tagIcon}
                    rightContent={`+${addons?.price} kr`}
                    paddingLeft={10}
                    mainType="responsivediv"
                    type="responsive_view"
                    boxDivType="choose_Item"
                  />
                </DivWrapper>
              );
            })}
        </Box>
      </MobileDiv>
    </>
  );
};
OrderSummaryTotal.propTypes = {
  item: PropTypes.node,
  index: PropTypes.node,
};
OrderSummaryTotal.defaultProps = {
  item: {},
  index: 0,
};
export default OrderSummaryTotal;
