import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../shared/Constants";
import { addOnsToStore } from "../../../store/actions/BookingFlowServicesAction";
import Colors from "../../../styles/Colors";
import {
  Div, MDImage, MDInputValue, MDLabel, Span,
} from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import {
  BoxDivision, Division, LabelBox, MDLabelBox,
} from "../BookingFlowStyles/BookingFlowStyles";
import { mapDogSizeToPrice } from "../../../shared/utils";

const { icons, images } = Constants;

const ExtraServices = () => {
  const dispatch = useDispatch();

  const firstService = useSelector((state) => state.BookingFlowServicesReducer.firstSelectService);
  const addOns = firstService && firstService[0]?.add_ons;

  const selectedAddOns = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedAddOns,
  );

  const { service } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices,
  );

  const extraRate = [];
  const extra = Object.keys(selectedAddOns)?.length > 0
  && Object.keys(selectedAddOns)?.forEach(
    (key) => extraRate.push(selectedAddOns[key]?.price),
  );
  const totalExtra = Number(extraRate?.reduce((acc, item) => acc + item, 0));
  const totalAddonNumber = extraRate.length;
  const { dogWeight } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes,
  );
  const { selectedDate  } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer,
  );
  let price = mapDogSizeToPrice(dogWeight, service).replace(" kr", "");

  if(service?.is_full_day_service) {
    const [startDate, endDate] = selectedDate;
    const totalDays = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24) + 1;
    price = Number(price) * totalDays;
  }
  const totalPrice = Number(price) + totalExtra;
  const handlePrice = (item, checked) => {
    dispatch(addOnsToStore(item, checked));
  };
  return (
    <Div>
      <BoxDivision>
        {addOns?.length > 0
          && addOns?.map((item, index) => (
            <Division key={item?.id + extra} type="set_width" setBorder mr={30} mb={3} pb={2}>
              <input
                type="checkbox"
                name="extraServices"
                id={item?.id}
                onChange={(e) => handlePrice(item, e.target.checked)}
                checked={selectedAddOns.find((obj) => obj.name === item.name)}
                hidden
              />
              <LabelBox htmlFor={item?.id} p="12px 16px" type="extra" id={item?.id}>
                <Division>
                  <Div>
                    <MDLabel fontSize={Theme.fontSizes.regular} textAlign="left" wordBreak="break-word">{item?.name}</MDLabel>
                  </Div>
                  <BoxDivision mt={2} type="choose_Item">
                    <BoxDivision pr={1} mr={3} type={index === 1 ? "extra_none" : ""}>
                      <MDImage src={icons.ClockIcon} mr={1} alt="icon" />
                      <MDLabelBox
                        fontSize={Theme.fontSizes.xs}
                        fontFamily={Theme.fonts.regular}
                        fontWeight={Theme.fontWeights.normal}
                      >
                        +
                        {item?.duration}
                        {" "}
                        <FormattedMessage id="min" defaultMessage="min" />
                      </MDLabelBox>
                    </BoxDivision>
                    <BoxDivision pr={1}>
                      <MDImage src={images.TagSingleIcon} mr={1} alt="icon" />
                      <MDLabel
                        fontSize={Theme.fontSizes.xs}
                        fontFamily={Theme.fonts.regular}
                        fontWeight={Theme.fontWeights.normal}
                      >
                        +
                        {item?.price}
                        {" "}
                        <FormattedMessage id="common_kr" defaultMessage="kr" />
                      </MDLabel>
                    </BoxDivision>
                  </BoxDivision>
                </Division>
              </LabelBox>
            </Division>
          ))}
      </BoxDivision>
      <BoxDivision pt={4} mt={2} mb={3}>
        <MDLabel fontSize={Theme.fontSizes.xl} mb={3} pb={1}>
          <FormattedMessage id="payment" defaultMessage="Payment" />
        </MDLabel>
      </BoxDivision>
      <BoxDivision>
        <Division width={60}>
          <MDInputValue size={24} mb={3} pb={1} placeholder>
            <FormattedMessage id="total" defaultMessage="Total" />
          </MDInputValue>
        </Division>
        <Division>
          <MDLabel fontSize={Theme.fontSizes.xl}>
            <Span px={2}>:</Span>
            {`${totalPrice} ${Constants.language.common_sek}`}
          </MDLabel>
        </Division>
      </BoxDivision>
      <BoxDivision>
        {!!totalAddonNumber && (
          <><BoxDivision type="extra_krs" mb={0}>
            <MDLabel fontSize={Theme.fontSizes.regular} pr={2}>
              {price}
              {" "}
              <FormattedMessage id="common_sek" defaultMessage="SEK" />
            </MDLabel>
            <MDLabel color={Colors.DarkGrey} fontWeight={Theme.fontWeights.normal}>
              <FormattedMessage id="service_plus" defaultMessage="service" />
            </MDLabel>
            <MDLabel color={Colors.DarkGrey} pl={1}>
              +
            </MDLabel>
          </BoxDivision><BoxDivision ml={2}>
            <MDLabel fontSize={Theme.fontSizes.regular} pr={2}>
              {totalExtra}
              {" "}
              <FormattedMessage id="common_sek" defaultMessage="SEK" />
            </MDLabel>
            <MDLabel color={Colors.DarkGrey} fontWeight={Theme.fontWeights.normal}>
              <FormattedMessage id="additional_options" defaultMessage="additional options" />
            </MDLabel>
          </BoxDivision></>
        )}
      </BoxDivision>
    </Div>
  );
};
export default ExtraServices;
