import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import TextBox from "../../../components/Input/TextBox";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import CheckBox from "../../../components/Input/CheckBox";
import { Box, Div, MDLabel } from "../../../styles/Styles";
import { MobileDiv } from "../Style";
import * as payAction from "../../../store/actions/PaymentAction";
import { isLoggedIn } from "../../../shared/utils";
import { saveContactInfo } from "../../../services/CustomerService";
import { loggedInUser } from "../../../store/actions/AppAction";
import AuthService from "../../../services/AuthService";
import Config from "../../../config";
import PropTypes from "prop-types";

const { language, input } = Constants;

const PickValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  telephone: Yup.string().typeError(language.validation_valid_phone_number)
    .required(language.validation_valid_phone_number)
    .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
});

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  telephone: Yup.string().typeError(language.validation_valid_phone_number)
    .required(language.validation_valid_phone_number)
    .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
});

const AdditionalInfoForm = (props) => {
  const authService = new AuthService(Config.apiBase);
  const dispatch = useDispatch();
  const OrderId = localStorage?.getItem?.("orderData")
    ? JSON.parse(localStorage.getItem("orderData")).id
    : "";
  const [customerData, setCustomerData] = useState({
    house: "",
    flat: "",
    firstName: "",
    lastName: "",
    telephone: "",
  });
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const { orderData, requestPickUp } = useSelector((state) => state.PaymentReducer);
  const pageStep = useSelector((state) => state.PaymentReducer.pageStep);
  const [isCheckSelected, setIsCheckSelected] = useState(false);
  const handleCheckBoxValues = () => {
    setIsCheckSelected(!isCheckSelected);
  };
  const handlePrevPage = () => {
    dispatch(payAction.moveToPrevStep());
  };

  useEffect(() => {
    if (isLoggedIn()) {
      const { customer = {} } = JSON.parse(localStorage.userData);
      const {
        phone_number_list: telephone = [],
        flat = "", house = "", first_name: firstName = "", last_name: lastName = "",

      } = customer;
      setCustomerData({
        telephone: telephone[0] || "",
        first_name: firstName,
        last_name: lastName,
        flat,
        house,
      });
    }
  }, [isLoggedIn()]);

  const handleFormSubmit = async (event) => {
    const { customer = {} } = localStorage?.userData ? JSON.parse(localStorage.userData) : {};
    const payload = {
      contact_information: {
        first_name: event.firstName,
        last_name: event.lastName,
        phone_number_list: [
          event.telephone,
        ],
        house: event.house,
        flat: event.flat,
        add_to_profile: isCheckSelected,
      },
    };

    if (isLoggedIn()) {
      payload.customer_id = customer.id || "";
    } else {
      const { customer: cusValue = {} } = localStorage?.orderData
        ? JSON.parse(localStorage.orderData) : {};
      payload.contact_information.add_to_profile = true;
      payload.customer_id = cusValue.id || "";
    }

    await saveContactInfo(OrderId, payload).then((response) => {
      if (response && isLoggedIn()) {
        try {
          authService.usersDetails().then((res) => {
            getLoginUser(res);
            localStorage.setItem("userData", JSON.stringify(res));
          });
        } catch (e) {
          console.error(e);
        }
      }
    });

    dispatch(payAction.submitContactDetails(event));
  };
  return (
    <Formik
      validationSchema={requestPickUp ? PickValidationSchema : ValidationSchema}
      enableReinitialize
      validateOnMount
      initialValues={customerData}
      onSubmit={handleFormSubmit}
    >
      {(propsFormik) => {
        const {
          values = formik.values,
          handleChange = formik.handleChange,
          handleBlur = formik.handleBlur,
          handleSubmit = formik.handleSubmit,
          formik = propsFormik,
        } = props;
        return (
          <MobileDiv type="subnewwrapper" width="60%" justifyContent="space-between" className="m-auto">
            <Box mt={4}>
              <MDLabel fontSize={Theme.fontSizes.xl}>
                {isLoggedIn()
                  ? <FormattedMessage id="please_check_info" defaultMessage="Please check your contact information" />
                  : <FormattedMessage id="how_should_we_connect_with_you" defaultMessage="How should we contact you?" />}

              </MDLabel>
            </Box>
            {(!isLoggedIn() && !props.hideSubmitButton)&& (
              <Box mt={3}>
                <MDLabel>
                  <FormattedMessage id="label_email" defaultMessage="label_email" />
                  {`: ${orderData.customer?.email}`}
                </MDLabel>
              </Box>
            )}
            <Box mb={[2, 2, 2]} mt={1} display="flex" justifyContent="space-between">
              <Div pr={[0, 0, 3]} width={["100%", "50%"]}>
                <TextBox
                  containerProps="m-auto justify-content-center"
                  label={language.placeholder_name}
                  placeholder={language.placeholder_name}
                  name={input.name.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formikValues={formik}
                  value={values.firstName}
                />
              </Div>
              <Div pr={[0, 0, 3]} width={["100%", "50%"]}>
                <TextBox
                  containerProps="m-auto justify-content-center"
                  label={language.placeholder_surname}
                  placeholder={language.placeholder_surname}
                  name={input.name.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formikValues={formik}
                  value={values.lastName}
                />
              </Div>
            </Box>
            <Box mb={[2, 2, 4]} mt={1} display="block">
              <Div pr={[0, 0, 1]} width={["100%", "50%"]}>
                <TextBox
                  label={language.telephone_label}
                  placeholder={language.telephone_label}
                  name={input.name.telephone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formikValues={formik}
                  value={values.telephone}
                  phone
                />
              </Div>
            </Box>
            {requestPickUp && (
              <>
                <Box my={4}>
                  <MDLabel fontSize={Theme.fontSizes.xl}>
                    <FormattedMessage id="label_address" defaultMessage="Address" />
                  </MDLabel>
                </Box>
                <MDLabel>
                  <FormattedMessage
                    id="additional_service_text"
                    defaultMessage="As well as you also chose Pick up from home additional
              service please fill the form below"
                  />
                </MDLabel>
                <Box pt={4} px={3}>
                  <CheckBox
                    label={isLoggedIn() ? (<FormattedMessage id="update_info_in_my_profile" />) : (
                      <FormattedMessage
                        id="privacy_policy_label"
                        defaultMessage="Jag har läst and godkänner MyDogCare's integritetspolicy"
                      />
                    )}
                    isSelected={isCheckSelected}
                    onClick={handleCheckBoxValues}
                  />
                </Box>
              </>
            )}
            {!props.hideSubmitButton && (
              <Box py={4}>
                <Div mr={2}>
                  {pageStep === 0 ? null : (
                    <Button primary type="button" label={<FormattedMessage id="label_prev" defaultMessage="Prev" />} width="175px" onClick={handlePrevPage} />
                  )}
                </Div>
                <Button
                  primary
                  type="submit"
                  label={<FormattedMessage id="common_save" defaultMessage="Save" />}
                  width="175px"
                  onClick={handleSubmit}
                />
              </Box>
            )}
          </MobileDiv>
        );
      }}
    </Formik>
  );
};

AdditionalInfoForm.propTypes = {
  hideSubmitButton: PropTypes.bool,
  values: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  requestPickUp: PropTypes.bool,
  formik: PropTypes.node,
};

export default AdditionalInfoForm;
