import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import CustomerModel, { fromOrderslistJson, fromListScreenJson } from "../models/Customers";
import { queryParamParser } from "../shared/utils";

const {
  apiEndPoints: { endPointUrl = {} }, input: { param: { customerId } },
  HTTP_STATUS: { OK, CREATED }, requestType: { GET, PUT },
} = Constants;

export async function getOrdersList(id, params) {
  const param = [[customerId, id]];
  const queryParams = [...params, ...param];
  const url = `${endPointUrl.ORDERS}${queryParamParser(queryParams)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return fromOrderslistJson(data);
}

export async function getDogBreeds() {
  return fetch("/data/dog-breeds.json").then(async (response) => {
    if (response.error) {
      throw new Error(response.error);
    }
    const dogBreedResponse = await response.json();
    const dogBreeds = Object.keys(dogBreedResponse).map((obj) => {
      return { label: dogBreedResponse[obj], value: obj };
    });
    return dogBreeds;
  });
}

export async function getOrderFilters(id) {
  const url = `${endPointUrl.CUSTOMER}/${id}/${endPointUrl.ORDERS}/${endPointUrl.FILTERS}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function getCustomersList(params = []) {
  const url = `${endPointUrl.CUSTOMERS}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return fromListScreenJson(data);
}

export async function verifyCustomer(email = "") {
  const params = [["email", email]];
  const url = `${endPointUrl.CUSTOMERS}/${endPointUrl.VERIFY}${queryParamParser(params)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}

export async function saveContactInfo(id, payload) {
  const url = `${endPointUrl.ORDERS}/${id}/${endPointUrl.CONTACT_INFORMATION}`;
  const response = await request(PUT, url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

class CustomerService extends ApiService {
  async getCustomersList(queryParam = []) {
    const response = await this.get(`${endPointUrl.CUSTOMERS}${this.queryParamString(queryParam)}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return fromListScreenJson(data);
    }
  }

  async getCustomerDetails(id) {
    this.customersModel = new CustomerModel({});
    const response = await this.get(`${endPointUrl.CUSTOMERS}/${id}`);
    const { data: { data = {} } = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.customersModel.fromCustomerDetailsJson(data);
    }
  }

  async addCustomer(payload) {
    const response = await this.post(`${endPointUrl.CUSTOMERS}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateCustomer(id, payload) {
    const response = await this.put(`${endPointUrl.CUSTOMERS}/${id}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async deleteCustomer(id) {
    const response = await
    this.delete(`${endPointUrl.CUSTOMERS}/${id}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async banUser(id, payload) {
    const response = await this.put(`${endPointUrl.CUSTOMERS}/${id}/${endPointUrl.BAN_USER}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getDogsList(id) {
    this.customersModel = new CustomerModel({});
    const params = [[customerId, id]];
    const response = await this.get(`${endPointUrl.CUSTOMERS}/${id}/${endPointUrl.DOGS}${queryParamParser(params)}`);
    const { data: { data = {} } = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.customersModel.fromDogDetailsJson(data);
    }
  }

  async addDog(userId, payload) {
    const response = await
    this.post(`${endPointUrl.CUSTOMERS}/${userId}/${endPointUrl.DOGS}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (CREATED !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async deleteDog(userId, dogId) {
    const response = await
    this.delete(`${endPointUrl.CUSTOMERS}/${userId}/${endPointUrl.DOGS}/${dogId}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateDog(userId, dogId, payload) {
    const response = await
    this.put(`${endPointUrl.CUSTOMERS}/${userId}/${endPointUrl.DOGS}/${dogId}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateDogDetails(dogId, payload) {
    const response = await
    this.put(`${endPointUrl.ORDERS}/${endPointUrl.DOGS}/${dogId}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getDogDetails(userId, dogId) {
    this.customersModel = new CustomerModel({});
    const response = await
    this.get(`${endPointUrl.CUSTOMERS}/${userId}/${endPointUrl.DOGS}/${dogId}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.customersModel.formDogDetailsJson(data.data);
    }
  }
}
export default CustomerService;
