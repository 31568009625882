import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Button from "../../components/Button/Button";
import LoginContainer from "../../components/Containers/LoginContainer";
import TextBox from "../../components/Input/TextBox";
import { GreenLogo } from "../../styles/Icons";
import ArrowLeft from "../../assets/Icons/PrimaryColorArrow.svg";
import { Header, MDInputValue, Div } from "../../styles/Styles";
import Constants from "../../shared/Constants";
import SignUpService from "../../services/SignUpService";
import Config from "../../config";
import LoginPageLoader from "../../components/Loader/LoginPageLoader";

/**
 * Handles validation for input fields.
 */
const validationSchema = Yup.object().shape({
  /**
   * Email validation
   */
  email: Yup.string()
    .email(<FormattedMessage id="validation_valid_email_input" />)
    .required(<FormattedMessage id="validation_empty_email_input" />),
});
class ForgotPassword extends Component {
  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      isMailSubmitted: false,
      loading: false,
    };
  }

  /**
   * Redirects to previous page
   * @returns back to previous page
   */
  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  /**
   * If the user has an account with the entered e-mail
   * password link will be sent to their e-mail
   * @returns Mail sent confirmaton page.
   */
  handleSubmit = async (data) => {
    this.setState({ loading: true });
    try {
      const response = await this.signUpService.forgotPasswordService(data);
      if (response) {
        this.setState({ loading: false, isMailSubmitted: true });
      }
    } catch (e) {
      this.setState({ loading: false, isMailSubmitted: true });
    }
  };

  /**
   * Redirects to login page
   * @returns login page
   */
  redirectToLoginPage = () => {
    const { history, location: { state = {} } } = this.props;
    if (state.isAdmin) {
      history.push(Constants.routes.logIn.url);
    } else {
      history.push(Constants.routes.customerLogin.url);
    }
  };

  render() {
    const { input: { name: inputNames } } = Constants;
    const { isMailSubmitted, loading } = this.state;
    return (
      <LoginContainer>
        {!loading && <GreenLogo />}
        {isMailSubmitted && (
          <>
            <Header mt={4} justifyContent="center">
              <FormattedMessage id="common_done" />
              !
            </Header>
            <MDInputValue size="14" my={3} pb={2} justifyContent="center">
              <FormattedMessage id="forgot_password_submitted_message" />
            </MDInputValue>
            <Button
              primary
              label={Constants.language.login_submit_button_log_in}
              width="200px"
              onClick={this.redirectToLoginPage}
            />
          </>
        )}
        {!isMailSubmitted && (
          <>
            {loading && <LoginPageLoader />}
            {!loading && (
              <>
                <Header mt={4} justifyContent="center">
                  <FormattedMessage id="common_reset_password" />
                </Header>
                <MDInputValue size="14" my={3} justifyContent="center">
                  <FormattedMessage id="forgot_password_message" />
                </MDInputValue>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {(props) => {
                    const {
                      values, handleChange, handleBlur, handleSubmit, isValid, touched,
                    } = props;
                    return (
                      <Form>
                        <TextBox
                          containerProps="m-auto justify-content-center"
                          width="300px"
                          label={`${Constants.language.login_label_email}:`}
                          placeholder={Constants.language.placeholder_email}
                          value={values.email}
                          name={inputNames.email}
                          formikValues={props}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          login
                        />
                        <Div mt={4} display="flex" justifyContent="center">
                          <Button
                            secondary
                            label={Constants.language.common_back}
                            width="140px"
                            type="button"
                            className="mr-3"
                            startIcon={ArrowLeft}
                            onClick={this.goBack}
                          />
                          <Button
                            primary
                            label="Send"
                            width="140px"
                            onClick={handleSubmit}
                            disable={!(isValid && Object.keys(touched).length > 0)}
                          />
                        </Div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </>
        )}
      </LoginContainer>
    );
  }
}
ForgotPassword.propTypes = {
  history: PropTypes.func,
  location: PropTypes.node,
};
ForgotPassword.defaultProps = {
  history: undefined,
  location: {},
};
export default ForgotPassword;
