/* eslint-disable react/jsx-filename-extension */
import React from "react";

import Button from "./Button";
import Add16 from "../../stories/assets/repo.svg";

const param = {
  title: "Button",
  component: Button,
  argTypes: {
    backgroundColor: { control: "color" },
  },
};
export default param;

// eslint-disable-next-line react/jsx-props-no-spreading
const Template = (args) => <Button {...args} />;

export const Primary = Template.bind({});
Primary.args = {
  primary: true,
  label: "Button",
};

export const Secondary = Template.bind({});
Secondary.args = {
  secondary: true,
  label: "Button",
};

export const IconButton = Template.bind({});
IconButton.args = {
  primary: true,
  label: "Button",
  icon: Add16,
  startIcon: Add16,
  IconHeight: "15px",
  IconWidth: "15px",
  hasIconOnly: false,
};

export const ColorButton = Template.bind({});
ColorButton.args = {
  primary: true,
  label: "Button",
  backgroundColor: "Violet",
  size: "medium",
};

export const DisabledButton = Template.bind({});
DisabledButton.args = {
  primary: true,
  label: "disabled",
  disabled: true,
};

export const Large = Template.bind({});
Large.args = {
  size: "large",
  label: "Button",
};

export const Medium = Template.bind({});
Medium.args = {
  size: "medium",
  label: "Button",
};

export const Small = Template.bind({});
Small.args = {
  size: "small",
  label: "Button",
};
