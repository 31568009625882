import React, { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Constants from "../../../shared/Constants";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import Colors from "../../../styles/Colors";
import { Box, Div, IsDesktop, IsMobile, MDLabel, P } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import { BoxDivision, ContentLeftDivision } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { CalendarWrapper } from "../../Assignments/Styles";
import Calendar from "react-calendar";

import { Division } from "../../Services/Styles";
import PrevNextButtons from "../Components/PrevNextButtons";
import SelectedSubscriptionDetail from "../Components/SelectedSubscriptionDetail";

const SubscriptionFlowStepFour = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePrevPage = () => {
    dispatch(SubscriptionBookingFlowActions.previousStep());
    history.push(Constants.routes.chooseSubscriptionOrganization.url);
  };

  const selectedDate = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedDate);

  const [currentSelectedDate, setcurrentSelectedDate] = useState(selectedDate || new Date());

  const handleNextPage = () => {
    dispatch(SubscriptionBookingFlowActions.setSelectedDate(currentSelectedDate));
    dispatch(SubscriptionBookingFlowActions.nextStep());
    history.push(Constants.routes.subscriptionOrderSummary.url);
  };

  const renderCalendar = (
    <CalendarWrapper>
      <Calendar
        onChange={setcurrentSelectedDate}
        value={currentSelectedDate}
        next2Label={null}
        prev2Label={null}
        minDate={new Date()}
      />
    </CalendarWrapper>
  );

  return (
    <Div>
      <SelectedSubscriptionDetail />
      <BoxDivision mt={4} type="choose_Day_Time" px={["11px", 0, 0]}>
        <ContentLeftDivision pl={3} type="choose_day" width={["340px !important", "unset !important", "unset !important"]} mx="auto" mb="8px !important">
          <Box display="flex" flexDirection="column">
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="choose_the_day" defaultMessage="Choose the Day" />
            </MDLabel>
            <P
              pt={3}
              width="100%"
              fontFamily={Theme.fonts.regular}
              fontSize={Theme.fontSizes.small}
              color={Colors.DarkGrey}
              textAlign="center">
              <FormattedMessage
                id="day_description"
                defaultMessage="Here you can choose date, when the service should be provided"
              />
            </P>

            <IsDesktop>
              <Division type="calendar_Card" pl={[4, 0, 0]}>
                {renderCalendar}
              </Division>
            </IsDesktop>
            <IsMobile>
              <Division type="calendar_Card">{renderCalendar}</Division>
            </IsMobile>
          </Box>
        </ContentLeftDivision>
      </BoxDivision>
      <PrevNextButtons
        handleNext={handleNextPage}
        handlePrevPage={handlePrevPage}
        nextDisabled={!currentSelectedDate}
        showNextButton={true}
      />
    </Div>
  );
};
export default memo(SubscriptionFlowStepFour);
