import styled from "styled-components";
import { Div, Span } from "../../styles/Styles";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";

export const DateCard = styled(Div)`
  background-color: ${({ date }) => (date ? Colors.Primary : Colors.White)};
  border-radius: ${({ date }) => (date ? "10px 10px 0px 0px" : "0px 0px 10px 10px")};
  color: ${({ date }) => (date ? Colors.White : Colors.Black)};
`;
DateCard.defaultProps = {
  height: "32px",
  width: "90px",
  display: "flex",
  justifyContent: "center",
};

export const ChangeButton = styled(Span)`
  color: ${Colors.Primary};
  text-decoration: underline;
  cursor: pointer;
`;
export const CalendarWrapper = styled(Div)`
  width: 340px !important;
  margin: auto;
  & .react-calendar button {
    outline: none !important;
  }
  & .react-calendar__navigation {
    background: transparent;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: ${Theme.fonts.semibold};
  }
  & .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${Colors.Transparent};
    outline: none;
  }
  & .react-calendar__navigation__arrow {
    font-size: 27px;
    color: ${Colors.Primary};
  }
  & .react-calendar__tile:disabled {
    background-color: transparent;
    opacity: 0.5;
  }
  & .react-calendar__tile {
    font-size: 14px;
    padding: 0.95rem 0.5rem;
    font-family: ${Theme.fonts.semibold};
  }
  & .react-calendar__month-view__weekdays {
    background-color: ${Colors.Blue};
    border-radius: 10px 10px 0px 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: initial;
    font-family: ${Theme.fonts.regular};
  }
  & .abbr[title],
  abbr[data-original-title] {
    text-decoration: none !important;
  }
  & .react-calendar__month-view__weekdays {
    font-size: 12px;
    font-family: ${Theme.fonts.regular};
  }
  & .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    text-decoration: none !important;
  }
  & .react-calendar__viewContainer {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  & .react-calendar__month-view__weekdays__weekday {
    letter-spacing: 1px;
    font-family: ${Theme.fonts.regular};
  }
  & .react-calendar__tile--now {
    background-color: ${Colors.Transparent};
  }
`;
