import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateUserForm from "./Components/CreateUserForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel,
} from "../../styles/Styles";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import UserModel from "../../models/UserProfile";
import FormLoader from "../../components/Loader/FormLoader";
import UserService from "../../services/UserService";
import Config from "../../config";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const { language, icons } = Constants;

class AddUser extends Component {
  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userCreated: false,
      alertHeader: "",
      alertMessage: "",
    };
  }

  handleSubmitData=async (data) => {
    this.setState({ loading: true });
    try {
      this.userModel = new UserModel({});
      const response = this.service.addUser(this.userModel.toJson(data));
      if (response) {
        this.handleBackToUsers();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleAlertModal=(isSaved = false) => {
    if (isSaved) {
      this.setState({ userCreated: true, alertMessage: language.alert_user_created });
    } else {
      this.setState({
        userCreated: true,
        alertHeader: language.header_error,
        alertMessage: language.alert_try_later,
      });
    }
  }

  handleBackToUsers = () => {
    this.setState({ userCreated: false, alertHeader: "", alertMessage: "" });
    const { history } = this.props;
    history.push(Constants.routes.usersList.url);
  };

  render() {
    const {
      loading, userCreated, alertHeader, alertMessage,
    } = this.state;
    return (
      <Container>
        <Div display="flex">
          <MDLabel cursor="pointer" onClick={this.handleBackToUsers}>
            <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
            <FormattedMessage id="breadcrumb_back_to_users" />
          </MDLabel>
        </Div>
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Header className="py-2" textAlign={["left", "center"]}>
              <FormattedMessage id="user_add_new_user" />
            </Header>
            <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
              <FormattedMessage id="user_add_user_description" />
            </MDLabel>
            <CreateUserForm
              submitData={this.handleAlertModal}
              handleBackToUsers={this.handleAlertModal}
            />
          </>
        )}
        {userCreated && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToUsers}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
AddUser.propTypes = {
  history: PropTypes.node,
};
AddUser.defaultProps = {
  history: undefined,
};
export default AddUser;
