import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Slide, DialogBox, BodyContent, Content, Backdrop,
} from "./Styles";
import { Div, IsDesktop } from "../../styles/Styles";

const Slider = ({
  open = false, children = {}, id = "", closeSlider,
}) => {
  const closeOptionsRef = useRef(null);

  const handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (
      closeOptionsRef
      && closeOptionsRef.current
      && !closeOptionsRef.current.contains(e.target)
      && open
    ) {
      closeSlider();
    }
  };

  /**
   * Adding event listener when mounting and
   * Removing event listener when unmounting.
   */
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);

    // cleanup this component
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, []);
  return (
    <Div width="100%">
      <Slide open={open} id={id || "test"} tabIndex="-1">
        <DialogBox>
          <Content open={open}>
            <BodyContent ref={closeOptionsRef}>{children}</BodyContent>
          </Content>
        </DialogBox>
      </Slide>
      <IsDesktop>
        <Backdrop open={open} />
      </IsDesktop>
    </Div>
  );
};

Slider.propTypes = {
  /**
   * Do you want to open the slider..
   */
  open: PropTypes.bool,
  /**
   * Id of the slider
   */
  id: PropTypes.string,
  /**
   * Pass the children..
   */
  children: PropTypes.func,
  /**
   * Close slider handler
   */
  closeSlider: PropTypes.func,
};
Slider.defaultProps = {
  open: false,
  id: null,
  children: {},
  closeSlider: undefined,
};
export default Slider;
