import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import AdditionalDetail from "./Components/AdditionalDetail";
import CardContainer from "./Components/CardContainer";
import Theme from "../../styles/Theme";
import { Div, MDLabel, Box } from "../../styles/Styles";
import Loader from "../../components/Loader/Loader";
import { getOrderDetails } from "../../services/OrdersService";

const DogDetails = () => {
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);

  const { search } = useLocation();
  const params = new URLSearchParams(search)

  const apiToken = params.get("token");
  useEffect(() => {
    async function getDogDetails() {
      setLoading(true);
      await getOrderDetails("my", apiToken)
        .then((response) => {
          const { orderItems = [] } = response;
          setOrderItems(orderItems.map((o) => ({
            ...o,
            dog: { ...o.dog, isSterilized: "", isInsured: "", api_token: apiToken },
          })));
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
    getDogDetails();
  }, [apiToken]);

  return (
    <>
      <Div className="container" px={2}>
        {loading && (
          <Div>
            <CardContainer cardTitle={<FormattedMessage id="label_details" defaultMessage="Details" />}>
              <Box mt={4}>
                <Loader height="30px" width="20%" />
              </Box>
              <Box mt={2}>
                <Loader height="75px" width="75%" />
              </Box>
              <Box mt={2} justifyContent="space-evenly">
                <Loader height="50px" width="20%" />
                <Loader height="50px" width="20%" />
              </Box>
            </CardContainer>
          </Div>
        )}
        {!loading &&
            <Div>
              <CardContainer cardTitle={<FormattedMessage id="label_details" defaultMessage="Details" />}>
                <Box mt={4}>
                  <MDLabel fontSize={Theme.fontSizes.xl}>
                    <FormattedMessage id="summary_of_your_order" defaultMessage="Summary of your order" />
                  </MDLabel>
                </Box>
                <AdditionalDetail paymentData={orderItems} orderItems={orderItems} isConfirmDogDetailsPage={true}/>
              </CardContainer>
            </Div>
        }
      </Div>
    </>
  );
};

export default DogDetails;
