import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import {
  FormWrapper, Header, Div,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import CustomerService from "../../services/CustomerService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import CreateDogForm from "../Customers/Components/CreateDogForm";

const { language } = Constants;
class CustomerAddDog extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { customer = {} } = userData;
    this.state = {
      loading: false,
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
      userId: customer.id,
    };
  }

  componentDidMount=() => {
    this.getDetails();
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleSubmitData= (isSaved = false) => {
    if (isSaved) {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_customer_updated,
      });
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  handleOk = () => {
    const { history } = this.props;
    history.push(Constants.routes.myDogs.url);
  }

  render() {
    const {
      loading,
      changesSaved, alertMessage, alertHeader, userId,
    } = this.state;
    return (
      <Container bg="transparent !important">
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Div display="flex" justifyContent="space-between">
              <Header className="py-2">
                <FormattedMessage id="my_account_add_dogs" />
              </Header>
            </Div>
            <FormWrapper>
              <CreateDogForm userId={userId} handleBackToCustomers={this.handleSubmitData} />
            </FormWrapper>
          </>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
              onClick={this.handleOk}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
CustomerAddDog.propTypes = {
  history: PropTypes.node,
  getLoginUser: PropTypes.func,
};
CustomerAddDog.defaultProps = {
  history: undefined,
  getLoginUser: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerAddDog);
