const Routes = {
  home: { url: "/" },
  pageNotFound: { url: "/page-not-found" },
  pageNotAccessible: { url: "/admin/page-not-accessible" },
  logIn: { url: "/admin/login", isAdmin: true },
  forgotPassword: { url: "/forgot-password" },
  forgotPasswordAlertPage: { url: "/mail-sent-alert" },
  resetPassword: { url: "/admin/reset-password", isAdmin: true },
  resetPasswordAlertPage: { url: "/password-reset-alert" },
  adminPortal: { url: "/admin" },
  roomsList: { url: "/admin/rooms" },
  addRoom: { url: "/admin/add-room" },
  editRoom: { url: "/admin/rooms/:id/edit-room" },
  assignmentsInRoom: { url: "/admin/rooms/:id/edit-room/assignments" },
  customersList: { url: "/admin/customers" },
  addCustomer: { url: "/admin/add-customer" },
  editCustomer: { url: "/admin/customers/:id/edit-customer" },
  dogsInCustomer: { url: "/admin/customers/:id/edit-customer/dogs" },
  ordersOfCustomer: { url: "/admin/customers/:id/edit-customer/orders" },
  subscriptionsOfCustomer: { url: "/admin/customers/:id/edit-customer/subscriptions" },
  addDog: { url: "/admin/customers/:id/add-dog" },
  ordersList: { url: "/admin/orders" },
  addOrder: { url: "/admin/add-order" },
  editOrder: { url: "/admin/orders/:id/edit-order" },
  customerDogTabInOrder: { url: "/admin/orders/:id/edit-order/customer-dog" },
  paymentTabInOrder: { url: "/admin/orders/:id/edit-order/payment" },
  subscriptionsList: { url: "/admin/subscription" },
  activeSubscriptionsList: { url: "/admin/subscription/active"},
  cancelledSubscriptionsList: { url: "/admin/subscription/cancelled"},
  archievedSubscriptionsList: { url: "/admin/subscription/archieve"},
  addSubscriptions: {url:"/admin/add-subscription" },
  adminAddSubscriptions: { url: "/admin/subscrition/add-subscription" },
  editSubscriptions: {url:"/admin/subscription/:id/edit-subscription" },
  customerDogInSubscriptions: {url:"/admin/subscription/:id/edit-subscription/customer-dog" },
  assignmentsList: { url: "/admin/assignments" },
  addAssignment: { url: "/admin/add-assignment" },
  editAssignment: { url: "/admin/assignments/:id/edit-assignment" },
  servicesList: { url: "/admin/services" },
  addService: { url: "/admin/add-service" },
  editService: { url: "/admin/services/:id/edit-service" },
  calendar: { url: "/admin/calendar" },
  usersList: { url: "/admin/users" },
  addUser: { url: "/admin/add-user" },
  editUser: { url: "/admin/users/:id/edit-user" },
  assignmentsOfUser: { url: "/admin/users/:id/edit-user/assignments" },
  editOrganization: { url: "/admin/organization/:id/edit-organization" },
  bookingFlow: { url: "/booking-flow" },
  payment: { url: "/payment" },
  cart: { url: "/cart" },
  customerLogin: { url: "/login", isAdmin: false },
  registerCustomer: { url: "/register", isAdmin: false },
  confirmCustomerRegistration: { url: "/confirm-customer", isAdmin: false },
  customerResetPassword: { url: "/reset-password", isAdmin: false },
  myProfile: { url: "/my-account/profile" },
  myOrders: { url: "/my-account/orders" },
  myAccounts: { url: "/my-account" },
  myDogs: { url: "/my-account/dogs" },
  addMyDog: { url: "/my-account/dogs/add-dog" },
  editMyDog: { url: "/my-account/dogs/:id/edit-dog" },
  orderConfirm: { url: "/order-confirm" },
  guestUserLogin: { url: "/guest/login" },
  chooseService: { url: "/booking-flow/choose-service" },
  chooseDog: { url: "/booking-flow/choose-dog" },
  chooseCenter: { url: "/booking-flow/choose-center" },
  chooseTimeDate: { url: "/booking-flow/choose-date-and-time" },
  orderSummary: { url: "/booking-flow/order-summary" },
  chooseSubscription: {url: "/booking-flow/subscriptions/choose-subscription" },
  chooseSubscriptionDog: { url: "/booking-flow/subscriptions/choose-dog" },
  chooseSubscriptionOrganization: { url: "/booking-flow/subscriptions/choose-organization" },
  chooseSubscriptionDate: { url: "/booking-flow/subscriptions/choose-date" },
  subscriptionOrderSummary: { url: "/booking-flow/subscriptions/order-summary" },
  subscriptionConfirmation: { url: "/booking-flow/subscriptions/order-confirmation" },
  confirmDogDetails: { url: "/dog-details"}
};
export default Routes;
