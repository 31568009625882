const Colors = {
  Primary: "#025652",
  Secondary: "#F9FAFE",
  Blue: "#CADDFD",
  Black: "#1A2424",
  DarkGrey: "#646868",
  DarkWhite: "#F9FAFE",
  Green: "#025652",
  LightGreen: "#0A7974",
  LightGrey: "#B6BABD",
  Orange: "#FDC282",
  Pink: "#FFE4E1",
  Red: "#E40000",
  Transparent: "transparent",
  White: "#FFFFFF",
  Yellow: "#FDC282",
  Border: "rgb(26,36,36,0.3)",
  ModalBg: "rgb(0,0,0,0.4)",
  Grey: "rgb(100,104,104,0.2)",
  LightBlue: "#e0ecff",
  Beige: "#E9D0B4",
  LightOrangeRgba: "rgba(253, 194, 130, 0.3)"
};

export default Colors;
