import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import { Div } from "../../../styles/Styles";
import { EditBox, SpanElement } from "../Style";

const ServiceAppointment = ({
  scheduledOn, scheduledAt, isEditable, stepValue, handleOnClick,
}) => (
  <SpanElement
    height={65}
    position="relative"
    type={!isEditable ? "services" : "new_services"}
    boxShadow={Theme.shadows.light}
    borderRadius={Theme.radii.medium}
    my={2}
    mr={["20px", "20px", 2]}
    width={["100%", "100%", "258px"]}
  >
    <Div display="inline-block" type="editHover" width="100%">
      {!isEditable && (
        <EditBox
          fontSize={Theme.fontSizes.medium}
          type="editdiv"
          color={Theme.colors.text.white}
          cursor="pointer"
          onClick={handleOnClick(stepValue)}
        >
          <FormattedMessage id="common_edit" defaultMessage="Edit" />
        </EditBox>
      )}
      <Div
        background={Theme.colors.primary}
        color={Theme.colors.text.white}
        fontWeight={Theme.fontWeights.medium}
        borderRadius="10px 10px 0 0"
        textAlign="center"
        px={2}
        py={1}
      >
        {scheduledOn}
      </Div>
      <Div
        background={Theme.colors.text.white}
        fontWeight={Theme.fontWeights.medium}
        borderRadius="10px 10px 0 0"
        textAlign="center"
        px={2}
        pb={1}
      >
        {scheduledAt}
      </Div>
    </Div>
  </SpanElement>
);
ServiceAppointment.propTypes = {
  scheduledOn: PropTypes.node,
  scheduledAt: PropTypes.node,
  isEditable: PropTypes.bool,
  stepValue: PropTypes.string,
  handleOnClick: PropTypes.func,
};
ServiceAppointment.defaultProps = {
  scheduledAt: "Loading...",
  scheduledOn: "Loading...",
  isEditable: false,
  stepValue: "",
  handleOnClick: () => {},
};
export default memo(ServiceAppointment);
