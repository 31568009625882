import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Theme from "../../../styles/Theme";
import Accordion from "./Accordion";
import { MDLabel } from "../../../styles/Styles";
import AdditionalInfoForm from "../FormContent/AdditionalInfoForm";
import AboutDogForm from "../FormContent/AboutDogForm";
import { isLoggedIn } from "../../../shared/utils";

const AdditionalDetail = ({ orderItems }) => {
  const { requestPickUp } = useSelector((state) => state.PaymentReducer);
  return (
    <>
      <MDLabel textAlign="left" ml={3} py={4} fontSize={Theme.fontSizes.regular}>
        <FormattedMessage
          id="need_more_information"
          defaultMessage="Your payment is sent, thank you! But we need some more information to complete your order"
        />
      </MDLabel>
      {!isLoggedIn() ? (
        <>
          {orderItems
            && orderItems?.map((item, index) => (
              <Accordion
                key={index}
                index={index}
                cardTitle={item?.service_name}
                categoryValue={item?.service?.service_category}
              >
                <AboutDogForm 
                  lastOne={index === orderItems?.length - 1}  
                  index={index} 
                  item={item} />
              </Accordion>
            ))}
        </>
      ) : (
        <>
          {orderItems
            && orderItems?.map((item, index) => (
              <Accordion
                index={index}
                key={index}
                cardTitle={item?.service_name}
                categoryValue={item?.service?.categoryName}
              >
                <AboutDogForm
                  lastOne={!requestPickUp && index === orderItems?.length - 1}
                  index={index}
                  item={item}
                />
              </Accordion>
            ))}
          {requestPickUp && (
            <Accordion index={orderItems?.length || 0} cardTitle={<FormattedMessage id="label_contact_information" defaultMessage="Contact information" />} categoryValue="">
              <AdditionalInfoForm />
            </Accordion>
          )}
        </>
      )}
    </>
  );
};

AdditionalDetail.propTypes = {
  orderItems: PropTypes.node,
};
AdditionalDetail.defaultProps = {
  orderItems: [],
};
export default AdditionalDetail;
