/* eslint-disable radix */
/* eslint-disable array-callback-return */

import { getRoomsList } from "../../services/RoomService";
import { getCalendarEvents } from "../../services/CalendarService";
import { fetchLoginDetails } from "../../services/AuthService";
import { getDogBreeds } from "../../services/CustomerService";
import * as types from "./types";
import {
  getAvailability,
  getUserServices,
  getSlotData,
  updateSlotData,
  deleteAvailability, deleteAvailabilityDetails,
} from "../../services/availableService";
import { getOrganization } from "../../services/OrganizationService";
import { getServiceProvider } from "../../services/ServicesPageService";

export function fetchRoomsList(params) {
  return (dispatch) => getRoomsList(params).then((res) => {
    dispatch({
      type: types.FETCH_ROOMS_TRIAL,
      payload: res,
    });
  });
}

export function setLoginUser(data = {}) {
  return (dispatch) => fetchLoginDetails(data).then((res) => {
    dispatch({
      type: types.SET_LOGIN_USER,
      payload: res,
    });
    return res;
  });
}

export function loggedInUser(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_USER_DATA,
      payload: data,
    });
  };
}

export function setLoader(payload) {
  return (dispatch) => {
    dispatch({ type: types.SET_LOADER, payload });
  };
}

export function saveAvailableDateRange(params) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_AVAILABLE_DATE_RANGE,
      payload: params,
    });
  };
}

export function fetchCalendarEvents(params) {
  saveAvailableDateRange(params);
  return (dispatch) => getCalendarEvents(params)
    .then((res) => {
      dispatch({
        type: types.FETCH_CALENDAR_EVENTS,
        payload: res,
      });
      dispatch(setLoader(false));
    })
    .catch((err) => {
      dispatch(setLoader(false));
      console.log(err);
    });
}

export function fetchDogBreeds() {
  return (dispatch) => getDogBreeds().then((res) => {
    dispatch({
      type: types.DOG_BREEDS,
      payload: res,
    });
  });
}
export const fetchAvailability = (params) => (dispatch) => {
  const response = getAvailability(params);

  dispatch({
    type: types.FETCH_AVAILABILITY,
    payload: response,
  });
  dispatch(setLoader(false));
};

export function fetchOrganization() {
  return (dispatch) => getOrganization().then((res) => {
    dispatch({
      type: types.SET_ORGANISATION_RECORD,
      payload: res,
    });
  });
}
export function fetchServiceProvider(params) {
  return (dispatch) => getServiceProvider(params).then((res) => {
    dispatch({
      type: types.GET_SERVICE_PROVIDER,
      payload: res,
    });
  });
}

export function fetchUserServices(params = "") {
  return (dispatch) => getUserServices(params).then((res) => {
    dispatch({
      type: types.GET_USER_SERVICES,
      payload: { ...res },
    });
  }).catch(() => {
    dispatch({
      type: types.GET_USER_SERVICES,
      payload: {},
    });
  });
}

export function fetchSlotData(params) {
  return (dispatch) => getSlotData(params).then((res) => {
    dispatch({
      type: types.GET_SLOT_DATA,
      payload: res,
    });
  });
}

export function updateSlot(params) {
  return (dispatch) => updateSlotData(params).then((res) => {
    dispatch({
      type: types.UPDATE_SLOT_DATA,
      payload: res,
    });
  });
}
export function availabilityDelete(params) {
  return (dispatch) => deleteAvailability(params).then((res) => {
    dispatch({
      type: types.DELETE_AVAILABILITY,
      payload: res,
    });
  });
}
export function availabilityDeleteDetails(params) {
  return (dispatch) => deleteAvailabilityDetails(params).then((res) => {
    dispatch({
      type: types.DELETE_AVAILABILITY_DETAILS,
      payload: res,
    });
  });
}
export default setLoginUser;

export function setOrganization(promises, payload) {
  return (dispatch) => {
    const storePayload = {};
    Object.keys(promises).forEach((storeKey, index) => {
      if (payload[index]) {
        const { data = [] } = payload[index];
        if(storeKey === "availabilitySlot") {
          storePayload.availableSlots = data;
        } else if (storeKey === "calendarEvents") {
          const status = ["open", "new", "close"];
          storePayload.calendarEvents = data.filter((obj) => status.includes(obj.status));
        } else {
          storePayload[storeKey] = data;
        }
      }
    });
    dispatch({
      type: types.SET_CALENDAR_PAYLOAD,
      payload: storePayload,
    });
  };
}

export function saveOrganization(payload) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ORGANISATION_RECORD,
      payload,
    });
  };
}

export function setEmptyUserService() {
  return (dispatch) => {
    dispatch({
      type: types.GET_USER_SERVICES,
      payload: {},
    });
  };
}
