import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import PersistentStorage from "../shared/PersistentStorage.ts";
import Config from "../config";

const axiosBase = require("axios");

const apiBaseUrl = Config.apiBase;

const axiosInstance = axiosBase.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  responseType: "json",
});

export async function request(method, endPoint, data = {}, newToken = "") {
  const url = `${apiBaseUrl}${endPoint}`;

  axiosInstance.interceptors.request.use(
    (obj) => {
      const config = obj;
      const token = localStorage.getItem("token") || newToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      delete axiosInstance.default.headers.common.Authorization;
      Promise.reject(error);
    }
  );
  return axiosInstance[method](url, data);
}

loadProgressBar({}, axiosInstance);

const persistentStorage = new PersistentStorage();

export async function get(endPoint) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${persistentStorage.getToken()}`,
  };
  const url = apiBaseUrl + endPoint;
  const response = await axios.get(url, { headers });
  if (response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  return response;
}

export async function getAll(endPoints) {
  const responses = await Promise.all(endPoints);
  return responses;
}

class ApiService {
  baseURL = "";

  headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  imageHeaders = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };

  persistentStorage = new PersistentStorage();

  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  async get(endPoint, cancelToken = {}) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const response = await axios.get(url, { headers: this.headers, cancelToken: cancelToken.token });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async getImage(endPoint) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const response = await axios.get(url, { headers: this.headers });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async getUser(endPoint) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const response = await axios.get(url, { headers: this.headers });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async postImage(endPoint, data, options = {}) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const res = await axios.post(url, data, { ...options, headers: this.imageHeaders });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async login(endPoint, data) {
    const url = apiBaseUrl + endPoint;
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async signup(endPoint, data) {
    const url = apiBaseUrl + endPoint;
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async post(endPoint, data) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async put(endPoint, data) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const res = await axios.put(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async delete(endPoint) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const res = await axios.delete(url, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async filterdelete(endPoint, payload) {
    const url = apiBaseUrl + endPoint;
    this.setAuthorizationToken();
    const { headers } = this;
    const config = {
      headers,
      data: payload,
    };
    const res = await axios.delete(url, config);
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  setHeader(key, value) {
    this.headers[key] = value;
    this.imageHeaders[key] = value;
  }

  setAuthorizationToken() {
    this.setHeader("Authorization", `Bearer ${this.persistentStorage.getToken()}`);
  }

  generateCancelToken = () => axios.CancelToken.source();

  setCancelToken(token) {
    this.headers.cancelToken = token;
  }

  queryParamString = (data) => {
    let queryParam = data;
    const filterElement = queryParam.find((obj) => obj[0] === "filter");
    queryParam = queryParam.filter((obj) => obj[0] !== "filter");

    let filterKeys;
    let filterQueryParams = "";
    if (filterElement && filterElement[1]) {
      filterKeys = Object.keys(filterElement[1]);
      if (filterKeys.length) {
        filterQueryParams = `filter=${filterKeys.map((obj) => obj).join(",")}&${filterKeys
          .map(
            (obj) =>
              `${obj}=${filterElement[1][obj]
                .map((ele) => encodeURIComponent(ele))
                .filter((o) => o !== "")
                .join(",")}`
          )
          .filter((o) => o !== "")
          .join("&")}`;
      }
    }

    let otherQueries;
    if (queryParam.length > 0) {
      otherQueries = queryParam
        .map((obj) => {
          if (obj[1] !== undefined && obj[1] !== null) {
            return obj.join("=");
          }
          return undefined;
        })
        .filter((o) => o)
        .join("&");
    }
    let params = "?";
    if (filterQueryParams) {
      params = `${params + filterQueryParams}&`;
    }
    return params + otherQueries;
  };
}
export default ApiService;
