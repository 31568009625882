import { formatUtcToLocal } from "../shared/utils";

export const fromJson = (obj) => {
  const {
    id: assignmentId,
    startDateTime = "",
    endDateTime = "",
    organization,
    service,
    serviceProvider,
    room,
  } = obj;
  const { name: organizationName = "" } = organization || {};
  const { name: serviceName = "", additional_option_id: additionalOptions = "" } = service || {};
  const { profile_pic: profilePic = {}, full_name: serviceProviderName = "" } = serviceProvider || {};
  const { name: roomName = "" } = room || {};
  const { url = "" } = profilePic || {};
  const data = {
    assignmentId,
    organizationName,
    url,
    serviceName,
    additionalOptions,
    serviceProviderName,
    roomName,
    is_full_day_service: obj?.is_full_day_service ? true : false,
    display: obj?.is_full_day_service? "none" : "",
    start: startDateTime && formatUtcToLocal(startDateTime,"HH:mm"),
    end: endDateTime && formatUtcToLocal(endDateTime,"HH:mm"),
  };
  const start = startDateTime && formatUtcToLocal(startDateTime,"yyyy-MM-DD HH:mm");
  const end = endDateTime && formatUtcToLocal(endDateTime,"yyyy-MM-DD HH:mm");
  return {
    assignmentId,
    start: start?.includes(" ") ? start.replace(" ", "T") : "",
    end: end?.includes(" ") ? end.replace(" ", "T") : "",
    url,
    data,
    overlap: true,
    serviceProvider,
  };
};

export default fromJson;
