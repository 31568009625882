import React from "react";
import Loader from "./Loader";
import { ListCard, ListItem, ListCardHeader } from "../ListScreen/Styles";
import { Div, IsDesktop, IsMobile } from "../../styles/Styles";

const CalendarLoader = () => (
  <Div display="flex" justify-content="center" width={1}>
    <ListCard pb={2} width={1}>
      {[...Array(1)].map(() => (
        <ListCardHeader py={3}>
          <IsDesktop width="50%">
            <ListItem className="col">
              <Loader width="50%" height="45px" ml={12} />
            </ListItem>
          </IsDesktop>
          <IsMobile>
            <ListItem className="col">
              <Loader width="100%" height="60px" />
            </ListItem>
          </IsMobile>
          <Div display="flex" width="50%" justifyContent="space-between">
            <Loader className="col" mr={3} height="45px" />
            <Loader className="col" mr={28} height="45px" />
          </Div>
        </ListCardHeader>
      ))}
      {[...Array(1)].map(() => (
        <ListItem ml={2} mr={2}>
          <ListItem className="col" ml={1}>
            <Loader />
          </ListItem>
        </ListItem>
      ))}
      <Div className="overflow-auto">
        {[...Array(4)].map(() => (
          <ListItem m={2} mx={3}>
            {[...Array(7)].map(() => (
              <ListItem ml={1} className="col p-1">
                <Loader height="70px" />
              </ListItem>
            ))}
          </ListItem>
        ))}
      </Div>
    </ListCard>
  </Div>
);
export default CalendarLoader;
