import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Container from "../../components/Containers/PortalContainer";
import {
  FormWrapper, MDImage, Header, Div, MDLabel, IsDesktop, IsMobile,
} from "../../styles/Styles";
import { ButtonWrapper } from "./Styles";
import Constants from "../../shared/Constants";
import {
  getOrderDetails, deleteOrder, closeOrder, refundOrder, cancelOrder,
} from "../../services/OrdersService";
import Tabs from "../../components/Tabs/Tabs";
import TotalTab from "./Components/TotalTab";
import ClientDogTab from "./Components/ClientDogTab";
import { OrderPageLoader } from "../../components/Loader/FormLoader";
import Label from "../../components/Label/Label";
import Colors from "../../styles/Colors";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";
import AssignmentTab from "./Components/AssignmentTab";
import Button from "../../components/Button/Button";
import { checkRightPermission } from "../../shared/utils";

const {
  language, assignmentHeaderTranslations: translate, labelColor,
  adminRoutes, status: {
    close, cancelled, refunded, cancelledNoRefunded, cancelledRefunded,
  }, paymentMethods, routes,
} = Constants;

const EditOrder = ({ userDetails = {}, match: { params = {} }, history, path = "" }) => {
  const { id = "" } = params;
  const dispatch = useDispatch();
  const authService = new AuthService(Config.apiBase);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const { icons, themes: { fontSizes } } = Constants;
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const tabs = [language.common_assignments, language.common_client_dogs, language.common_total];
  const [selectedTab, setSelectedTab] = useState(language.common_assignments);
  const userData = userDetails.user_access
    ? userDetails
    : localStorage.userData
      ? JSON.parse(localStorage.userData)
      : {};
  const { permissions = [] } = userData;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [changesSaved, setChangesSaved] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelModalWithRefund, setShowCancelModalWithRefund] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundSuccess, setRefundSuccess] = useState(false);

  const fetchDetails = async () => {
    setLoading(true);
    await getOrderDetails(id).then((response) => {
      window.scrollTo(0,0);
      setOrderData({
        ...response,
        assignments: response.assignments.map((assignment) => (
          {
            ...assignment,
            assignment_start_datetime: moment.utc(assignment.assignment_start_datetime).local(),
          }
        )),
      });
      setLoading(false);
    }, (error) => {
      console.error(error);
      setLoading(false);
    });
  };

  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSelectedTab = () =>{

    let currentTab = "";
    switch(path) {
    case routes.customerDogTabInOrder.url:
      currentTab = language.common_client_dogs;
      break;
    case routes.paymentTabInOrder.url:
      currentTab = language.common_total;
      break;
    default:
      currentTab = language.common_assignments;
    }
    setSelectedTab(currentTab)
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    fetchSelectedTab();
  }, [path]);

  const handleSwitchBetweenTabs = (data) => {
    let switchPath = "";
    switch(data) {
    case language.common_client_dogs:
      switchPath = `/admin/orders/${id}/edit-order/customer-dog`;
      break;
    case language.common_total:
      switchPath = `/admin/orders/${id}/edit-order/payment`;
      break;
    default:
      switchPath = `/admin/orders/${id}/edit-order`;
    }
    history.push(switchPath);
  }

  const handleTabs = (data) => {
    setSelectedTab(data);
    handleSwitchBetweenTabs(data);
  };

  const handleMoveTabs = (value) => {
    const choosedTab = tabs[value];
    setSelectedTab(choosedTab);
    handleSwitchBetweenTabs(choosedTab);
  };

  const handleBackToOrders = () => {
    setChangesSaved(false);
    setAlertMessage("");
    setAlertHeader("");
    if (alertMessage === language.order_deleted_alert_message) {
      history.push(Constants.routes.ordersList.url);
    }
  };

  const handleDeleteOrder = async () => {
    setLoading(true);
    await deleteOrder(id).then((response) => {
      if (response) {
        getDetails();
        setLoading(false);
        setShowDeleteModal(false);
        setChangesSaved(true);
        setAlertMessage(language.order_deleted_alert_message);
        fetchDetails();
      }
    }, (error) => {
      console.error(error);
      setLoading(false);
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    });
  };

  const handleCloseOrder = async () => {
    setLoading(true);
    await closeOrder(id).then((response) => {
      if (response) {
        getDetails();
        fetchDetails();
      }
    },
    (error) => {
      console.error(error);
      setLoading(false);
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    });
  };

  const handleCancelModal = () => {
    setShowCancelModal(false);
    setShowDeleteModal(false);
    setShowCancelModalWithRefund(false);
    setShowCancelOrderModal(false);
    setChangesSaved(false);
    setShowRefundModal(false);
    setRefundSuccess(false);
    setAlertHeader("");
    setAlertMessage("");
  };

  /**
   * Opens a delete confirmation popup
   */
  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleRedirectToOrders = () => {
    history.goBack();
  };

  const handleRedirectToAssignments = (item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${item.id}/${adminRoutes.editAssignment}`);
  };

  const handleCancelOrderModel = () => {
    const paymentCheck = orderData?.payment?.filter(
      (obj) => obj.payable_type === paymentMethods.KLARNA,
    );
    if (paymentCheck && paymentCheck.length > 0) {
      setShowCancelModalWithRefund(true);
    } else {
      setShowCancelOrderModal(true);
    }
    setChangesSaved(false);
    setAlertHeader("");
    setAlertMessage("");
  };

  const handleCancelOrder = async (refund = true) => {
    setLoading(true);
    await cancelOrder(id, orderData.customer_id, { is_klarna_refund: refund })
      .then((response) => {
        if (response) {
          setChangesSaved(true);
          setLoading(false);
          setShowCancelModalWithRefund(false);
          setShowCancelOrderModal(false);
          setAlertMessage(language.order_cancelled);
          fetchDetails();
        }
      },
      (error) => {
        console.error(error);
        setShowCancelModalWithRefund(false);
        setShowCancelOrderModal(false);
        setLoading(false);
        setChangesSaved(true);
        setAlertHeader(language.header_error);
        setAlertMessage(language.alert_try_later);
      });
  };

  const checkButtonDisable = (
    [
      close,
      cancelled,
      refunded,
      cancelledNoRefunded,
      cancelledRefunded,
    ].includes(orderData.status));

  const handleRefundOrderModelOpen = () => {
    setShowRefundModal(true);
  };

  const handleRefundOrderModelClose = async () => {
    setLoading(true);
    try {
      await refundOrder(id);
      setLoading(false);
      setShowRefundModal(false);
      setRefundSuccess(true);
      fetchDetails();
    } catch (error) {
      console.error(error);
      setLoading(false);
      setShowRefundModal(false);
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    }
  };

  const { status = "" } = orderData || {};
  const { assignments = [] } = orderData || {};
  const assignmentCloseStatusData = assignments.find(
    (assignment) => assignment.status === close,
  ) ?? [];

  const org = assignments.length ? assignments[0].organization : {}
  const { id : orgId = "" } = org ||{};
  const filterOrgData = permissions.filter((obj)=>obj.organization_id === orgId);
  const hasWriteAccess = checkRightPermission(filterOrgData,"is_order_editable",Constants.WRITE);
  const hasOrderDelete = checkRightPermission(filterOrgData,"is_order_deletable",Constants.MANAGE);

  return (
    <Container>
      <Div>
        <Div display="flex">
          <MDLabel cursor="pointer" onClick={handleRedirectToOrders}>
            <MDImage src={icons.ArrowLeft} alt="icon" className="pr-1" />
            <FormattedMessage id="common_back" defaultMessage="Back" />
          </MDLabel>
        </Div>
        {loading && (
          <FormWrapper>
            <OrderPageLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            <Div className="row m-0 justify-content-between">
              <Header py={2} textAlign="left" fontSize={[fontSizes.xl, fontSizes.header]}>
                <FormattedMessage id="common_order_ID" />
                &nbsp;
                {orderData.orderNo}
              </Header>
              <Label
                value={translate[status]}
                bg={labelColor[status]}
                color={(status === close || status === cancelled || status === refunded)
                  ? Colors.White : Colors.Black}
              />
            </Div>
            <Div className="my-3">
              <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                onClick={handleTabs}
                onNext={handleMoveTabs}
                onPrevious={handleMoveTabs}
              />
            </Div>
            {selectedTab === tabs[0] && (
              <AssignmentTab
                orderData={assignments}
                redirectTo={handleRedirectToAssignments}
              />
            )}
            {selectedTab === tabs[1] && (
              <ClientDogTab
                orderId={id}
                orderData={orderData}
                handleCloseOrder={handleCloseOrder}
                handleDeleteOrder={handleDeleteModal}
              />
            )}
            {selectedTab === tabs[2] && (
              <TotalTab
                orderId={id}
                orderData={orderData}
                handleCloseOrder={handleCloseOrder}
                handleDeleteOrder={handleDeleteModal}
              />
            )}
            <ButtonWrapper className="py-3">
              <IsDesktop>
                {!checkButtonDisable && (
                  <>
                    <Button
                      label={<FormattedMessage id="button_close_order" />}
                      primary
                      startIcon={Constants.icons.AssignmentsMenuIcon}
                      width="175px"
                      onClick={handleCloseOrder}
                      disabled={checkButtonDisable || !hasWriteAccess}
                      padding="10px !important"
                      className="mr-3"
                    />
                    {assignmentCloseStatusData.length === 0 && (
                      <Button
                        label={<FormattedMessage id="button_cancel_order" />}
                        secondary
                        startIcon={Constants.icons.PrimaryDeleteIcon}
                        width="175px"
                        onClick={handleCancelOrderModel}
                        disabled={checkButtonDisable || !hasWriteAccess}
                        padding="10px !important"
                      />
                    )}
                  </>
                )}
                <Button
                  secondary
                  startIcon={icons.PrimaryDeleteIcon}
                  IconHeight="18px"
                  label={<FormattedMessage id="common_delete" />}
                  width="200px"
                  className="ml-3"
                  onClick={handleDeleteOrder}
                  disabled={!hasOrderDelete}
                />
                {(orderData.status === close) && (
                  <Button
                    primary
                    startIcon={icons.RefreshIcon}
                    IconHeight="18px"
                    label={<FormattedMessage id="button_refund" />}
                    width="250px"
                    className="ml-3 mt-3"
                    disabled={!hasWriteAccess}
                    onClick={handleRefundOrderModelOpen}
                  />
                )}
              </IsDesktop>
              <IsMobile>
                {!checkButtonDisable && (
                  <>
                    <Button
                      label={<FormattedMessage id="button_close_order" />}
                      primary
                      startIcon={Constants.icons.AssignmentsMenuIcon}
                      width="100%"
                      onClick={handleCloseOrder}
                      disabled={checkButtonDisable || !hasWriteAccess}
                      padding="10px !important"
                      className="mr-3"
                    />
                    {assignmentCloseStatusData.length === 0 && (
                      <Button
                        label={<FormattedMessage id="button_cancel_order" />}
                        secondary
                        startIcon={Constants.icons.PrimaryDeleteIcon}
                        width="100%"
                        onClick={handleCancelOrderModel}
                        disabled={checkButtonDisable || !hasWriteAccess}
                        padding="10px !important"
                        className="mt-3"
                      />
                    )}
                  </>
                )}
                <Button
                  secondary
                  startIcon={icons.PrimaryDeleteIcon}
                  IconHeight="18px"
                  label={<FormattedMessage id="common_delete" />}
                  width="100%"
                  className="my-4"
                  onClick={handleDeleteOrder}
                  disabled={!hasOrderDelete}
                />
                {(orderData.status === close) && (
                  <Button
                    primary
                    startIcon={icons.RefreshIcon}
                    IconHeight="18px"
                    label={<FormattedMessage id="button_refund" />}
                    width="100%"
                    className="mt-3"
                    onClick={handleRefundOrderModelOpen}
                    disabled={!hasWriteAccess}
                  />
                )}
              </IsMobile>
            </ButtonWrapper>
          </>
        )}
      </Div>
      {showDeleteModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="order_delete" defaultMessage="Delete assignment" />}
            message={<FormattedMessage id="order_delete_confirmation_message" defaultMessage="Are you sure you want to delete ?" />}
            buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
            confirmationCard
            buttonIcon={Constants.icons.DeleteIcon}
            onClick={handleDeleteOrder}
            onCancel={handleCancelModal}
            onLoader={loading}
          />
        </Modal>
      )}
      {showRefundModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="button_refund" defaultMessage="Refund Money" />}
            message={<FormattedMessage id="refund_model_message" defaultMessage="Are you sure that you want to refund money?" />}
            buttonLabel={<FormattedMessage id="button_refund" defaultMessage="Refund Money" />}
            confirmationCard
            buttonIcon={Constants.icons.RefreshIcon}
            onClick={handleRefundOrderModelClose}
            onCancel={handleCancelModal}
            onLoader={loading}
            buttonPadding="9px 18px"
          />
        </Modal>
      )}
      {changesSaved && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleBackToOrders}
          />
        </Modal>
      )}
      {showCancelModalWithRefund && (
        <Modal>
          <Card
            header={<FormattedMessage id="button_cancel_order" defaultMessage="Cancel order" />}
            message={<FormattedMessage id="cancel_model_message_with_refund" values={{ break: <br /> }} />}
            orderLabel={<FormattedMessage id="button_cancel_with_refund" />}
            addNewEventCard
            orderButtonIcon={Constants.icons.RefreshIcon}
            buttonIcon={Constants.icons.DeleteIcon}
            assignmentLabel={<FormattedMessage id="button_cancel_without_refund" />}
            cancelButtonLabel={<FormattedMessage id="button_close_dialog" />}
            onAssignmentClick={() => handleCancelOrder(false)}
            onOrderClick={() => handleCancelOrder(true)}
            onCancel={handleCancelModal}
            onLoader={loading}
            cardWidth="410px"
            buttonWidth="352px"
          />
        </Modal>
      )}
      {showCancelOrderModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="button_cancel_order" defaultMessage="Cancel order" />}
            message={<FormattedMessage id="cancel_model_message" />}
            buttonLabel={<FormattedMessage id="button_cancel_order" defaultMessage="Delete" />}
            confirmationCard
            buttonIcon={Constants.icons.DeleteIcon}
            onClick={() => handleCancelOrder(false)}
            onCancel={handleCancelModal}
            onLoader={loading}
          />
        </Modal>
      )}
      {showCancelModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="button_cancel_order" defaultMessage="Cancel order" />}
            message={<FormattedMessage id="cancel_model_message" />}
            buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
            confirmationCard
            buttonIcon={Constants.icons.DeleteIcon}
            onClick={handleCloseOrder}
            onCancel={handleCancelModal}
            onLoader={loading}
          />
        </Modal>
      )}
      {refundSuccess && (
        <Modal>
          <Card
            header={<FormattedMessage id="common_done" defaultMessage="done" />}
            message={(
              <FormattedMessage
                id="refund_confirm_message"
                defaultMessage="The refund is confirmed. User will get his money back"
                values={{ break: <br /> }}
              />
            )}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleCancelModal}
          />
        </Modal>
      )}
    </Container>
  );
};
EditOrder.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
  path: PropTypes.string,
};
EditOrder.defaultProps = {
  history: undefined,
  match: {},
  userDetails: {},
  path: ""
};
export default EditOrder;
