import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxPromise from "redux-promise";
import rootReducer from "./reducers/index";

const initialState = {};

const middleware = [thunk];
export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(ReduxPromise, ...middleware)),
);

export default store;
