import React , { useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import {
  CalendarCardWrapper,
  Div,
  MDLabel,
  MDInputValue,
} from "../../styles/Styles";

/**
 * Calendar card component
 */
const CalendarCard = ({
  data = {}, onCardClick, viewType,
}) => {
  const {
    serviceName = "",
    serviceProviderName = null,
    start = "",
    end = "",
  } = data;

  const handleCardClick = useCallback((e) => {
    e.preventDefault();
    onCardClick();
  });

  return (
    <Div width="inherit" height="inherit" onClick={handleCardClick}>
      <CalendarCardWrapper type="calendarCard" m={0} pb={35} py={2} px={1} boxShadow={Theme.shadows.light} viewType={viewType} bg={Colors.Blue}>
        <Div display="flex" mb={1}>
          <MDLabel fontSize={Theme.fontSizes.es} lineHeight="13px" color={Colors.Black} wordBreak="break-all">
            {serviceName}
          </MDLabel>
        </Div>
        <Div display="flex" flexWrap="wrap" mb={1}>
          <MDInputValue size={11} height="auto" wordBreak="break-all" color={Colors.DarkGrey} width="auto" lineHeight="13px">
            {`${start} - ${end}`}
          </MDInputValue>
        </Div>
        <Div display="flex" flexWrap="wrap">
          <MDLabel
            fontSize={Theme.fontSizes.es}
            lineHeight="13px"
            color={Colors.Black}
            display={["none", "none", "none", "flex"]}
            wordBreak="break-all"
          >
            {serviceProviderName || <FormattedMessage id="label_not_assigned" defaultMessage="Not assigned" />}
          </MDLabel>
        </Div>

      </CalendarCardWrapper>
    </Div>
  );
};

CalendarCard.propTypes = {
  /**
   * Data to be shown on card
   */
  data: PropTypes.string,
  /**
 * Optional onclick handler
 */
  onCardClick: PropTypes.func,
  /**
   * View Type to be shown on card
   */
  viewType: PropTypes.string,
};

CalendarCard.defaultProps = {
  data: "",
  onCardClick: undefined,
  viewType: "",
};
export default CalendarCard;
