import React, { memo } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import Constants from "../../../shared/Constants";
import { resetBookingFlow } from "../../../store/actions/BookingFlowServicesAction";
import { Box, MDLabel, Span, Div } from "../../../styles/Styles";
import { calculateVATAmount } from "../../../shared/utils";
import CheckBox from "../../../components/Input/CheckBox";

const { language, input } = Constants;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(language.validation_empty_email_input)
    .required(language.validation_empty_email_input),
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  telephone: Yup.string().typeError(language.validation_valid_phone_number)
    .required(language.validation_valid_phone_number)
    .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
});

const BottomSection = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    totalRate, totalService, totalcharge, onCheckout, isUserLogged, disableBtn,
    totalNoOfAddOns = 0,
  } = props;

  const handlePush = () => {
    dispatch(resetBookingFlow());
    history.push(Constants.routes.chooseService.url);
  };

  const handleCheckBoxClick = (value, onChange = () => { }) => {
    onChange({ target: { name: "create_user", value: !value } });
  };

  return (
    <>
      <Box mt={4} pt={2}>
        <MDLabel color={Theme.colors.text.darkGrey} fontSize={Theme.fontSizes.xl}>
          <FormattedMessage id="total" defaultMessage="Total :" />
        </MDLabel>
        <MDLabel fontSize={Theme.fontSizes.xl} pl={1}>
          <Span px={1}>:</Span>
          {totalRate && `${Constants.currency.sek} ${totalRate}`}
        </MDLabel>
      </Box>
      <Box pt={3}>
        <MDLabel fontSize={Theme.fontSizes.small} pl={1}>
          {`(${Constants.language.label_vat}
            ${(totalRate) && Constants.currency.sek} ${calculateVATAmount(totalRate)})`}
        </MDLabel>
      </Box>
      {(!!totalNoOfAddOns && totalcharge) && (
        <Box mt={[0, 0, 2]} pt={3}>
          <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
            <FormattedMessage id="currency_sek" defaultMessage={Constants.currency.sek} />
          </MDLabel>
          <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
            {totalService}
          </MDLabel>
          <FormattedMessage id="service" defaultMessage="Service" />
          <FormattedMessage id="common_plus" defaultMessage=" + " />
          <MDLabel fontSize={Theme.fontSizes.regular} px={1}>
            <FormattedMessage id="currency_sek" defaultMessage={Constants.currency.sek} />
          </MDLabel>
          <MDLabel fontSize={Theme.fontSizes.regular} pr={1}>
            {totalcharge}
          </MDLabel>
          <FormattedMessage id="additional_options" defaultMessage="additional options" />
        </Box>
      )}
      <Box mt={4}>
        <Button
          label={<FormattedMessage id="book_more" defaultMessage="Book more" />}
          secondary
          py={2}
          px={5}
          onClick={handlePush}
        />
      </Box>
      {isUserLogged && (
        <Box mt={4}>
          <Button
            label={<FormattedMessage id={totalRate === 0 ? "confirm_order" : "pay_by_klarna"} defaultMessage="Pay by klarna" />}
            primary
            width="auto"
            p={1}
            onClick={onCheckout}
            disabled={disableBtn}
          />
        </Box>
      )}
      {!isUserLogged && (
        <Div>
          <Box mt={4} mb={20}>
            <MDLabel fontSize={Theme.fontSizes.xl}>
              <FormattedMessage id="checkout" defaultMessage="Checkout" />
            </MDLabel>
          </Box>
          <Box>
            <MDLabel fontSize={Theme.fontSizes.regular} fontWeight={Theme.fontWeights.regular} fontFamily={Theme.fonts.regular}>
              <FormattedMessage id="info_checkout" defaultMessage="Please, enter info below for checkout" />
            </MDLabel>
          </Box>
        </Div>
      )}
      {!isUserLogged && (
        <Formik
          enableReinitialize
          initialValues={{ email: "", firstName: "", lastName: "", telephone: "", create_user: true }}
          validationSchema={ValidationSchema}
          onSubmit={onCheckout}
        >
          {(formikProps) => {
            const {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              touched,
            } = formikProps;
            return (
              <Form>
                <Box mb={[0, 2, 2]} mt={1} mx={2} display={["block !important", "flex !important", "flex !important"]} justifyContent="space-between">
                  <Div pr={[0, 20, 30]}>
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      label={language.placeholder_name}
                      placeholder={language.placeholder_name}
                      name={input.name.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formikValues={formikProps}
                      value={values.firstName}
                      width={["100%", 300, 350]}
                    />
                  </Div>
                  <Div>
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      label={language.placeholder_surname}
                      placeholder={language.placeholder_surname}
                      name={input.name.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formikValues={formikProps}
                      value={values.lastName}
                      width={["100%", 300, 350]}
                    />
                  </Div>
                </Box>
                <Box mb={[2, 2, 2]} mx={2} display={["block !important", "flex !important", "flex !important"]} justifyContent="space-between">
                  <Div pr={[0, 20, 30]}>
                    <TextBox
                      containerProps="m-auto justify-content-center"
                      label={language.label_email}
                      placeholder={language.epost}
                      name={input.name.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formikValues={formikProps}
                      value={values.email}
                      width={["100%", 300, 350]}
                    />
                  </Div>
                  <Div>
                    <TextBox
                      label={language.telephone_label}
                      containerProps="m-auto justify-content-center"
                      placeholder={language.telephone_label}
                      name={input.name.telephone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formikValues={formikProps}
                      value={values.telephone}
                      width={["100%", 300, 350]}
                      phone
                    />
                  </Div>
                </Box>
                <Box mt={30} mx={12}>
                  <CheckBox
                    size="14px"
                    isSelected={values.create_user}
                    label={<Div display={["block" , "flex", "flex"]} mt={[24, 0, 0]} fontWeight={Theme.fontWeights.medium} fontFamily={Theme.fonts.semibold}><FormattedMessage id="create_guest_account_agree_text" defaultMessage="I want to create an account to have access to order history" /></Div>}
                    onClick={handleCheckBoxClick.bind(this, values.create_user, handleChange)}
                    name="create_user"
                    onBlur={handleBlur}
                    formikValues={formikProps}
                  />
                </Box>
                <Box mt={4}>
                  <Button
                    disable={!(isValid && Object.keys(touched).length > 0)}
                    label={<FormattedMessage id="to_checkout" defaultMessage="To checkout" />}
                    primary
                    width={175}
                    p={1}
                    onClick={handleSubmit}
                    disabled={disableBtn}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};
BottomSection.propTypes = {
  totalRate: PropTypes.number,
  totalService: PropTypes.number,
  totalcharge: PropTypes.number,
  onCheckout: PropTypes.func,
  isUserLogged: PropTypes.bool,
  disableBtn: PropTypes.bool,
  totalNoOfAddOns: PropTypes.number,
};
BottomSection.defaultProps = {
  totalRate: 0,
  totalService: 0,
  totalcharge: 0,
  onCheckout: () => {},
  isUserLogged: false,
  disableBtn: false,
  totalNoOfAddOns: 0,
};
export default memo(BottomSection);
