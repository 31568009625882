import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Theme from "../../../styles/Theme";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import Constants from "../../../shared/Constants";
import { resetBookingFlow } from "../../../store/actions/BookingFlowServicesAction";
import {
  Box, CardHeader, Div, MDInputValue, MDLabel,
} from "../../../styles/Styles";

const { icons } = Constants;

const AddToCartPopup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEdit = () => {
    dispatch(resetBookingFlow());
    history.push(Constants.routes.cart.url);
  };

  const handleServices = () => {
    dispatch(resetBookingFlow());
    history.push(Constants.routes.chooseService.url);
  };

  return (
    <Modal>
      <Div width={380} mb={3} p={2}>
        <CardHeader>
          <MDLabel alignSelf="center" fontSize={Theme.fontSizes.medium}>
            <FormattedMessage id="done" defaultMessage="Done!" />
          </MDLabel>
        </CardHeader>
        <Div className="text-center" alignSelf="center" my={3}>
          <MDInputValue fontSize={Theme.fontSizes.xs}>
            <FormattedMessage
              id="order_in_cart"
              defaultMessage="The order now is in the cart. You can continue booking."
            />
          </MDInputValue>
        </Div>
        <Box justifyContent="space-around !important">
          <Button
            startIcon={icons.whitePriceTag}
            label={<FormattedMessage id="label_to_cart_page" defaultMessage="To cart" />}
            primary
            onClick={handleEdit}
          />
          <Button
            label={<FormattedMessage id="label_to_service_page" defaultMessage="To services" />}
            secondary
            onClick={handleServices}
          />
        </Box>
      </Div>
    </Modal>
  );
};
export default AddToCartPopup;
