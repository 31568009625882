import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";
import Constants from "../../shared/Constants";
import {
  BoxDivision, MDImg, Division, MDLabelBox,
} from "../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { MDImage, MDLabel, CurvedDiv } from "../../styles/Styles";
import { OrdersMenuIcon } from "../../styles/Icons";
import Tabs from "../../components/Tabs/Tabs";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";
import { editOrderSummary } from "../../store/actions/BookingFlowServicesAction";

const { icons, routes, language } = Constants;

const isActive = (route) => window.location.pathname.search(route) >= 0;

const NavItem = styled(Division)`
  &:hover {
    background-color: ${Colors.LightGreen};
    color: ${Colors.White};
  }
`;
const Menu = ({ children }) => {
  const authService = new AuthService(Config.apiBase);
  const [selectedTab, setSelectedTab] = useState("User info");
  const tabs = [language.my_account_user_info, language.list_header_dogs, language.common_orders];
  const tabRoutes = [routes.myProfile.url, routes.myDogs.url, routes.myOrders.url];
  const history = useHistory();
  const dispatch = useDispatch();
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));

  const userDetails = useSelector((state) => state.AppReducer.userDetails);

  const { customer: { name: userName = "" } = {} } = userDetails || {};

  const handleTabs = (tab) => {
    setSelectedTab(tab);
  };

  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleMoveTabs = (id) => {
    const tab = tabs[id];
    history.push(tabRoutes[id]);
    setSelectedTab(tab);
  };

  const { location: { pathname = "" } = {} } = history;

  useEffect(() => {
    if (pathname === routes.myProfile.url) {
      setSelectedTab(tabs[0]);
    }
    if (pathname === routes.myOrders.url) {
      setSelectedTab(tabs[2]);
    }
    if (pathname === routes.myDogs.url
       || pathname === routes.addMyDog.url
        || pathname === routes.editMyDog.url) {
      setSelectedTab(tabs[1]);
    }
  }, [pathname]);

  const handleBackToService = () => {
    editOrderSummary(1);
    history.push(Constants.routes.bookingFlow.url);
  };

  return (
    <Division>
      <Division className="container" px={0} pb={4} mb={4}>
        <MDLabelBox
          cursor="pointer"
          onClick={handleBackToService}
          mt={4}
          width={Theme.widths.full}
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.xs}
          type="back_To_services"
        >
          <MDImg src={icons.ArrowLeft} mr={1} alt="icon" />
          <FormattedMessage id="Back_to_Services_Page" defaultMessage="Back to Services Page" />
        </MDLabelBox>
        <MDLabelBox fontSize={Theme.fontSizes.header} py={3} type="booking_Service" lineHeight={1.3}>
          {userName || language.label_profile}
        </MDLabelBox>
        <Division
          className="container"
          p="0px !important"
          mb={2}
          background={Colors.White}
          borderRadius={Theme.radii.medium}
          boxShadow={Theme.shadows.light}
        >
          <BoxDivision
            background={Colors.White}
            boxShadow={Theme.shadows.light}
            borderRadius={Theme.radii.medium}
            type="choose_Services"
            pl={3}
            pr={4}
            position="relative"
            height={70}
          >
            <CurvedDiv display="flex">
              <MDLabelBox fontSize={Theme.fontSizes.xl} type="choose_Text">
                {language.my_account_user_profile}
              </MDLabelBox>
            </CurvedDiv>
            <MDImg src={icons.PawIcon} type="dog_Paw" alt="icon" />
          </BoxDivision>
          <Division display={["block", "none"]} my={3}>
            <Tabs
              tabs={tabs}
              selectedTab={selectedTab}
              onClick={handleTabs}
              onNext={handleMoveTabs}
              onPrevious={handleMoveTabs}
            />
          </Division>
          <Division display="flex">
            <Division width={175} pt={40} boxShadow="0px 7px 64px rgba(0, 0, 0, 0.07)" display={["none", "block"]}>
              <Link to={routes.myProfile.url} className="text-decoration-none">
                <NavItem
                  width={1}
                  height={50}
                  borderRadius={10}
                  display="flex"
                  pl={20}
                  justifyContent="start"
                  alignSelf="center"
                  className="cursor-pointer navItem"
                  color={isActive(routes.myProfile.url) ? Colors.White : Colors.Black}
                  bg={isActive(routes.myProfile.url) ? Colors.Primary : Colors.White}
                >
                  <MDLabel useCursorPointer>
                    <MDImage
                      alt="icon"
                      mr={2}
                      className="nav-image"
                      src={isActive(routes.myProfile.url)
                        ? icons.WhiteProfileIcon : icons.ProfileIcon}
                      height={14}
                    />
                    <MDImage
                      alt="icon"
                      mr={2}
                      className="nav-img"
                      src={icons.WhiteProfileIcon}
                      height={14}
                    />
                    {language.my_account_user_info}
                  </MDLabel>
                </NavItem>
              </Link>
              <Link to={routes.myDogs.url} className="text-decoration-none">
                <NavItem
                  width={1}
                  height={50}
                  borderRadius={10}
                  display="flex"
                  pl={20}
                  justifyContent="start"
                  alignSelf="center"
                  className="cursor-pointer navItem"
                  color={isActive(routes.myDogs.url) ? Colors.White : Colors.Black}
                  bg={isActive(routes.myDogs.url) ? Colors.Primary : Colors.White}
                >
                  <MDLabel useCursorPointer width="100%">
                    <MDImage
                      alt="icon"
                      mr={2}
                      height={14}
                      className="nav-image"
                      src={isActive(routes.myDogs.url) ? icons.DogPawIcon : icons.PawIcon}
                    />
                    <MDImage
                      alt="icon"
                      mr={2}
                      className="nav-img"
                      height={14}
                      src={icons.DogPawIcon}
                    />
                    {language.your_label_dogs}
                  </MDLabel>
                </NavItem>
              </Link>
              <Link to={routes.myOrders.url} className="text-decoration-none">
                <NavItem
                  width={1}
                  height={50}
                  borderRadius={10}
                  display="flex"
                  pl={20}
                  justifyContent="start"
                  alignSelf="center"
                  className="cursor-pointer navItem"
                  color={isActive(routes.myOrders.url) ? Colors.White : Colors.Black}
                  bg={isActive(routes.myOrders.url) ? Colors.Primary : Colors.White}
                >
                  <MDLabel useCursorPointer>
                    {isActive(routes.myOrders.url) ? <OrdersMenuIcon className="mr-2 nav-image" />
                      : <MDImage src={icons.CartIcon} alt="icon" mr={2} height={14} className="nav-image" />}
                    <OrdersMenuIcon className="mr-2 nav-img" />

                    {language.common_orders}
                  </MDLabel>
                </NavItem>
              </Link>
            </Division>
            <Division width={1} minHeight={[400, 619]}>
              {children}
            </Division>
          </Division>
        </Division>
      </Division>
    </Division>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
};

Menu.defaultProps = {
  children: {},
};

export default Menu;
