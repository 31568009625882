import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import ServiceModel from "../models/Service";
import { queryParamParser } from "../shared/utils";

const {
  apiEndPoints: { endPointUrl = {} }, HTTP_STATUS: { OK, CREATED },
  requestType: { GET },
} = Constants;

export async function getServiceList(queryParam) {
  const url = `${endPointUrl.SERVICES}${queryParamParser(queryParam)}`;
  const response = await request(GET, url);
  const { data = {}, status = "", statusText = "" } = response;
  if (OK !== status) {
    throw Error(statusText);
  } else {
    return data;
  }
}

export function getServiceProviderPromise(params) {
  const url = `${endPointUrl.ORGANIZATIONS}/${endPointUrl.SERVICEPROVIDERS}?organization_id=${params}`;
  return request("get", url)
    .then((response) => response.data);
}

export async function getServiceProvider(params) {
  const response = await getServiceProviderPromise(params);
  const { data = [] } = response;
  return data;
}
class Service extends ApiService {
  async getServiceList(queryParam = []) {
    const response = await this.get(`${endPointUrl.SERVICES}${this.queryParamString(queryParam)}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getServices() {
    const response = await this.get(`${endPointUrl.PUBLIC}/${endPointUrl.SERVICES}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getServiceDetails(id) {
    this.serviceModel = new ServiceModel({});
    const response = await this.get(`${endPointUrl.SERVICES}/${id}`);
    const { data: { data = {} }, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return this.serviceModel.fromServiceDetailsJson(data);
    }
  }

  async addService(payload) {
    const response = await this.post(`${endPointUrl.SERVICES}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (CREATED !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async updateService(id, payload) {
    const response = await this.put(`${endPointUrl.SERVICES}/${id}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async deleteService(id) {
    const response = await
    this.delete(`${endPointUrl.SERVICES}/${id}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async getCategoryList() {
    const response = await this.get(`${endPointUrl.CATEGORIES}`);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }

  async saveCategory(payload) {
    const response = await this.post(`${endPointUrl.CATEGORIES}/${endPointUrl.SAVE_MULTIPLE}`, payload);
    const { data = {}, status = "", statusText = "" } = response;
    if (OK !== status) {
      throw Error(statusText);
    } else {
      return data;
    }
  }
}
export default Service;
