import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux";
import {
  MDLabel,
  Div,
  MDImage,
  Span,
  FormWrapper,
  MDInputValue,
} from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import Theme from "../../../styles/Theme";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import NameCard from "../../../components/Card/ProfileCard";
import { CardWrapper } from "../../Customers/Styles";
import Colors from "../../../styles/Colors";

const {
  icons,
  language,
  translations: translate,
  weightTranslations,
  heightTranslations,
  adminRoutes,
  input: { name: inputName },
} = Constants;
const { space, colors, fontSizes } = Theme;


const DogView = ({ subscriptionData = {} }) => {
  const history = useHistory();
  const { dog: dogData = {} } = subscriptionData;
  const {
    profile_pic: dogProfilePic = "",
    name = "", breed = "", dog_size_weight: dogSizeWeight = "", dog_size_height: dogSizeHeight = "", gender = "", isSterilized = false,
    isInsured = false, isNewToDayCare = false, age = "", description = "",
  } = dogData || {};
  const { url: profilePicUrl } = dogProfilePic || {};
  const dogBreeds = useSelector((state) => state.AppReducer.dogBreeds);
  const getBoolean = (question) =>{
    return question ? language.common_yes: language.common_no
  }


  const redirecthandleEditCustomer =(item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${item.customer.id}/${adminRoutes.editCustomer}?isdog=true`);
  }

  if(!dogData){
    return <MDLabel fontSize={fontSizes.medium} className="mb-4">
      <FormattedMessage
        id="dog_not_available"
        defaultMessage="Dog is not available"
      />
    </MDLabel>
  }
  return <Div className="mb-5">
    <MDLabel fontSize={space.m} className="mb-4">
      {`${language.common_dog}`}
    </MDLabel>
    <Div display="flex" mb={30} cursor="pointer"
      onClick={redirecthandleEditCustomer(subscriptionData)}>
      <MDImage
        width={80}
        height={80}
        borderRadius="10px !important"
        src={profilePicUrl || FavIcon}
        bg={profilePicUrl ? "" : `${colors.primary}`}
        className={profilePicUrl ? "" : "p-2"}
        alt="icon"
      />
      <Div alignSelf="center" pl={3}>
        <MDLabel fontSize={fontSizes.medium}>
          <Span pb={12}>{name || ""}</Span>
        </MDLabel>
        <MDLabel justifyContent="center" fontSize={fontSizes.small} bg={(dogBreeds[breed] || breed) && Colors.Orange} borderRadius="10px" py="4px" px="8px">
          <Span>{dogBreeds[breed] || breed}</Span>
        </MDLabel>
      </Div>
    </Div>
    <Div
      className="row"
      m={0}
      pb={1}
    >
      <Div
        className="col-6 col-lg-4"
        p={0}
        mb={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <MDLabel
          pb="12px"
          lineHeight="19px"
          width="100%"
          fontSize={fontSizes.small}
        >
          {language.label_select_gender}
        </MDLabel>
        <NameCard
          nameCard
          width={160}
          userAvtar={
            gender === inputName.male
              ? icons.MaleDog
              : icons.FemaleDog
          }
          data={{ label: translate[gender] }}
        />
      </Div>
      <Div
        className="col-6 col-lg-4"
        p={0}
        mb={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <MDLabel
          pb="12px"
          lineHeight="19px"
          width="100%"
          fontSize={fontSizes.small}
        >
          {language.kastrerad_steriliserad}
        </MDLabel>
        <Div
          className="row m-0"
          justifyContent="space-between"
          alignItems="center"
        >
          <CardWrapper borderRadius="10px" height="50px">
            <MDLabel fontSize={fontSizes.regular}>
              {getBoolean(isSterilized)}
            </MDLabel>
          </CardWrapper>
        </Div>
      </Div>
      <Div
        className="col-6 col-lg-4"
        p={0}
        mb={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <MDLabel
          pb="12px"
          lineHeight="19px"
          width="100%"
          fontSize={fontSizes.small}
        >
          {language.label_is_dog_insured}
        </MDLabel>
        <Div
          className="row"
          m={0}
          justifyContent="space-between"
          alignItems="center"
        >
          <CardWrapper borderRadius="10px" height={50}>
            <MDLabel fontSize={fontSizes.regular}>
              {getBoolean(isInsured)}
            </MDLabel>
          </CardWrapper>
        </Div>
      </Div>
    </Div>
    <Div className="row" m={0} mb={3} pb={1}>
      <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
        {language.label_select_height_dog}:
      </MDLabel>
      <MDInputValue>{heightTranslations[dogSizeHeight]}</MDInputValue>
    </Div>
    <Div className="row" m={0} mb={3} pb={1}>
      <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
        {language.label_select_weight_dog}:
      </MDLabel>
      <MDInputValue>{weightTranslations[dogSizeWeight]}</MDInputValue>
    </Div>
    <Div className="row" m={0} mb={3} pb={1}>
      <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
        {language.label_select_age}:
      </MDLabel>
      <MDInputValue>{translate[age]}</MDInputValue>
    </Div>

    <Div className="row" m={0} mb={3} pb={1}>
      <MDLabel lineHeight="19px" fontSize={fontSizes.regular} pr={1}>
        {language.isDogUsedTo}:
      </MDLabel>
      <MDInputValue>{getBoolean(isNewToDayCare)}</MDInputValue>
    </Div>

    <Div>
      <MDLabel lineHeight="19px" fontSize={fontSizes.regular} mb={2}>
        {language.label_additional_information}:
      </MDLabel>
      <MDInputValue textAlign="left">{description}</MDInputValue>
    </Div>
  </Div>
}

const SubscriptionClientsDogs = ({ subscriptionData }) => {
  return (
    <FormWrapper>
      <Div>
        <DogView subscriptionData={subscriptionData} />
      </Div>
    </FormWrapper>
  );
};

DogView.propTypes = {
  dogData: PropTypes.node,
  subscriptionData: PropTypes.node,
};
DogView.defaultProps = {
  dogData: {},
  subscriptionData: {},
};

SubscriptionClientsDogs.propTypes = {
  subscriptionData: PropTypes.node,
};
SubscriptionClientsDogs.defaultProps = {
  subscriptionData: {},
};

export default SubscriptionClientsDogs;
