import React, { memo } from "react";
import PropTypes from "prop-types";
import Theme from "../../../styles/Theme";
import { MobileDiv } from "../Style";
import {
  Box, Div, MDImage, MDLabel, Span,
} from "../../../styles/Styles";

const HeadAccordion = ({
  item,
  index,
  deleteIcon,
  toggleHandler,
  activeIndex,
  arrowUpIcon,
  arrowDownIcon,
  deleteHandler,
  isUserLogged,
}) => {
  let totalOfService = 0;
  let extraRate = 0;
  item?.selectedAddOns.map((obj) => {
    extraRate += Number(obj.price);
    return obj;
  });
  totalOfService = Number(item?.dogSizes?.price) + Number(extraRate);
  return (
    <MobileDiv display="flex" className="m-auto" type="cart" pl={2} pr={4} pt={2} key={extraRate + isUserLogged}>
      <Box
        width="5%"
        mr={2}
        pt={2}
        mt={1}
        onClick={deleteHandler(
          item?.selectedProvider?.providerStartTime,
          index,
        )}
      >
        <Span lineHeight={["60px"]}>
          <MDImage cursor="pointer" width={Theme.fontSizes.medium} src={deleteIcon} alt="icon" />
        </Span>
      </Box>
      <Box
        width="95%"
        background={Theme.colors.text.white}
        borderRadius={activeIndex === index ? "15px 15px 0 0" : Theme.radii.medium}
        boxShadow={Theme.shadows.light}
        justifyContent="start"
        mt={3}
      >
        <Div className="container" px={0} mx={0} justifyContent="space-between" display="flex">
          <Div ml={3} height={60}>
            <MDLabel lineHeight={["60px"]} fontSize={Theme.fontSizes.medium}>
              {item?.selectedServices?.serviceName}
            </MDLabel>
          </Div>
          <Div display="flex">
            <MDLabel mr={3} pr={2} lineHeight={["60px"]} fontSize={Theme.fontSizes.medium}>
              {totalOfService}
              kr
            </MDLabel>
            <Box background={Theme.colors.blue} cursor="pointer" borderRadius={10} p={3} onClick={toggleHandler(index)}>
              {activeIndex === index ? (
                <MDImage src={arrowDownIcon} alt="icon" cursor="pointer" width={20} />
              ) : (
                <MDImage src={arrowUpIcon} alt="icon" cursor="pointer" width={20} />
              )}
            </Box>
          </Div>
        </Div>
      </Box>
    </MobileDiv>
  );
};
HeadAccordion.propTypes = {
  item: PropTypes.node,
  index: PropTypes.node,
  deleteIcon: PropTypes.node,
  toggleHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  activeIndex: PropTypes.node,
  arrowUpIcon: PropTypes.node,
  arrowDownIcon: PropTypes.node,
  isUserLogged: PropTypes.node,
};
HeadAccordion.defaultProps = {
  item: {},
  index: "",
  deleteIcon: "",
  toggleHandler: () => {},
  deleteHandler: () => {},
  activeIndex: "Loading...",
  arrowUpIcon: "",
  arrowDownIcon: "",
  isUserLogged: "",
};
export default memo(HeadAccordion);
