import React from "react";
import PropTypes from "prop-types";
import Constants from "../../shared/Constants";
import { ListItem, Content } from "./Styles";
import {
  Div, MDInputValue, MDLabel, Span,
} from "../../styles/Styles";
import Label from "../Label/Label";
import Colors from "../../styles/Colors";
import { formatDate } from "../../shared/utils";

const {
  packagesTranslations,
  applicationStatus,
  listItemTypes, assignmentHeaderTranslations: translate,
  labelColor, status, language, columnTranslations,
  input: { format: { dottedHourFormat, twoDigitDateFormat } },
} = Constants;

const ColumnItem = ({
  type,
  itemData,
  className,
  group,
  isMobile,
  redirectTo,
  isOrders,
  isCustomers,
  isOrg,
  redirectToOrg
}) => {
  const handleRedirectToOrg = (id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (redirectToOrg) {
      redirectToOrg(id);
    }
  };

  if (type === listItemTypes.multipleUser) {
    return (
      <ListItem className={`${className} ${isMobile ? "d-grid" : "d-inline-block align-self-center"}`}>
        {itemData && !itemData.length
          ? <MDLabel>-</MDLabel>
          : itemData.map((obj, index) => {
            if (obj) {
              const {
                name = "", full_name: fullName = "", id = "",
              } = obj;
              return (
                <Content
                  title={fullName || name}
                  mt={index === 1 ? 10 : 0}
                  onClick={isOrg ? handleRedirectToOrg(id) : () => {}}
                >
                  <Span className="text-truncate" alignSelf="center" showHoverEffect={isOrg}>
                    {fullName || name}
                  </Span>
                </Content>
              );
            }
            return <MDLabel>-</MDLabel>;
          })}
      </ListItem>
    );
  }
  if (type === listItemTypes.packages && itemData) {
    const { name = ""} = itemData;
    return (
      <ListItem title={name} className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center" showHoverEffect={isOrg}>
            {packagesTranslations[name]}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.singleUser && itemData) {
    const {
      name = "", full_name: fullName = "", id = "",
    } = itemData;
    return (
      <ListItem title={fullName || name} className={`${className}`} onClick={isOrg ? handleRedirectToOrg(id) : redirectTo(id)}>
        <Content>
          <Span className="text-truncate" alignSelf="center" showHoverEffect={isOrg}>
            {fullName || name}
          </Span>
        </Content>
      </ListItem>
    );
  }

  if (type === listItemTypes.dogHeight && itemData) {
    const {
      dog_size_height : dogSizeHeight = "", id = ""
    } = itemData;
    const getDogHeightBgColor = () => {
      switch (dogSizeHeight) {
      case "mini":
        return Colors.LightGrey;
      case "small":
        return Colors.Pink;
      case "medium":
        return Colors.Orange;
      case "large":
        return Colors.Blue;
      default:
        break;
      }
    }
    const getDogHeightValue = () => {
      switch (dogSizeHeight) {
      case "mini":
        return "XS";
      case "small":
        return "S";
      case "medium":
        return "M";
      case "large":
        return "L";
      default:
        break;
      }
    }

    return (
      <ListItem title={dogSizeHeight} className={`${className}`} onClick={isOrg ? handleRedirectToOrg(id) : redirectTo(id)}>
        <Content>
          <Span bg={getDogHeightBgColor()} width="25px" height="25px" borderRadius="10px" display="flex" justifyContent="center" alignItems="center" alignSelf="center">
            {getDogHeightValue()}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.user || type === listItemTypes.organization) {
    if (group) {
      const { organization_details: orgDetails = [] } = itemData;
      return (
        <ListItem className={`${className} ${isMobile ? "d-grid" : "d-inline-block"}`}>
          {(orgDetails && orgDetails.length > 0)
            ? orgDetails.map((obj, index) => {
              const {
                name = "", id = "",
              } = obj;
              if (index < 2) {
                return (
                  <Content
                    mt={index === 1 ? 10 : 0}
                    title={name}
                    onClick={isOrg ? handleRedirectToOrg(id) : redirectTo(id)}
                  >
                    <Span className="text-truncate" alignSelf="center" showHoverEffect={isOrg}>{name}</Span>
                  </Content>
                );
              }
              if (index === 2) {
                return (
                  <MDLabel pl="45px" textDecoration="underline">
                    <Span useCursorPointer className="text-truncate">
                      {`+ ${orgDetails?.length - 2} ${language.common_more}`}
                    </Span>
                  </MDLabel>
                );
              }
              return "";
            }) : <MDLabel>-</MDLabel>}
        </ListItem>
      );
    }
    return (
      <ListItem title={itemData.full_name || itemData.name} className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center" color={Colors.Black}>
            {itemData.full_name || itemData.name}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.roles) {
    return (
      <ListItem className={`${className} text-truncate`} flexDirection="column">
        {itemData && itemData.length > 0 ? itemData.map((obj, index) => {
          if (index < 2) {
            return (
              <Div className="align-self-center d-flex w-100" justifyContent="inherit">
                <Content title={columnTranslations[obj]}>
                  <Span className="text-truncate" alignSelf="center">
                    {columnTranslations[obj]}
                  </Span>
                </Content>
              </Div>
            );
          }
          if (index === 2) {
            return (
              <Div className="align-self-center d-flex py-1 w-100" justifyContent="inherit">
                <MDLabel textDecoration="underline">
                  <Span useCursorPointer className="text-truncate">
                    {`+ ${itemData.length - 2} ${language.common_more}`}
                  </Span>
                </MDLabel>
              </Div>
            );
          }
          return "";
        }) : (
          <Div className="align-self-center d-flex col-12 p-0 py-1" justifyContent="inherit">
            <MDLabel>
              -
            </MDLabel>
          </Div>
        )}
      </ListItem>
    );
  }
  if (type === listItemTypes.labelWithLetter ) {
    let labelValue;
    if(isCustomers){
      if(itemData?.height) {
        labelValue = translate[itemData?.height]?.charAt(0);
      } else {
        labelValue= "-";
      }
    } else {
      labelValue = itemData?.charAt(0);
    }
    return (
      <ListItem title={isCustomers ? translate[itemData?.height]?.charAt(0) : itemData?.charAt(0)} className={`${className} text-truncate`}>
        <Label
          value={labelValue}
          bg={isCustomers ? labelColor[itemData?.height] : ""}
          color={isOrders && itemData === status.close ? Colors.White : Colors.Black}
        />
      </ListItem>
    );
  }
  if (type === listItemTypes.label) {
    if (itemData && typeof (itemData) === "object") {
      if (!Array.isArray(itemData)) {
        return (
          <ListItem title={isCustomers ? translate[itemData.dog_size_weight] : itemData} className={`${className} text-truncate`}>
            <Label
              value={isCustomers && itemData.dog_size_weight ? translate[itemData.dog_size_weight] : "-"}
              bg={isCustomers ? labelColor[itemData.dog_size_weight] : ""}
              color={isOrders && itemData === status.close ? Colors.White : Colors.Black}
            />
          </ListItem>
        );
      }
      if (isCustomers) {
        return (
          <ListItem title={itemData.length} className={`${className} text-truncate`}>
            <MDLabel>
              {itemData.length}
            </MDLabel>
          </ListItem>
        );
      }
      return (
        <ListItem className={`${className} d-grid`}>
          {itemData.length > 0 ? itemData.map((obj, index) => (
            <Content className={`${index > 0 && "mt-2"}`} title={isOrders ? translate[obj] : isCustomers ? obj.name : obj}>
              <Span alignSelf="center">
                <Label
                  value={isOrders ? obj ? translate[obj] : "-" : isCustomers ? obj.size ? obj.size : "-" : obj}
                  bg={isOrders ? labelColor[obj] : isCustomers ? labelColor[obj.height] : ""}
                  color={isOrders && (obj === status.close || obj === status.refunded)
                    ? Colors.White : Colors.Black}
                />
              </Span>
              {isCustomers && (
                <Content ml={2}>
                  <Span alignSelf="center">{obj.name}</Span>
                </Content>
              )}
            </Content>
          ))
            : (
              <Content>
                -
              </Content>
            )}
        </ListItem>
      );
    }
    return (
      <ListItem title={isOrders ? translate[itemData] : itemData} className={`${className} text-truncate`}>
        <Content>
          {itemData
            ? (
              <Label
                value={isOrders ? translate[itemData] : itemData}
                bg={isOrders ? labelColor[itemData] : ""}
                color={isOrders && (itemData === status.close
                  || itemData === status.cancelled || itemData === status.refunded)
                  ? Colors.White : Colors.Black}
              />
            )
            : <Label value={itemData || 0} bg={Colors.Grey} />}
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.date) {
    if (itemData && itemData.length && typeof (itemData) === "object") {
      return (
        <ListItem className={`${className} ${"d-grid"}`}>
          {itemData.map((obj, index) => (
            <Div display={["flex", "grid"]} key={index}>
              <MDLabel className={`${index === 1 && "pt-2"} text-truncate`} title={obj}>
                {obj ? formatDate(obj, twoDigitDateFormat) : "-"}
              </MDLabel>
              {isMobile && <Span mr={1}>,</Span>}
              <MDInputValue className={`${index === 1 && "pt-2"} text-truncate`} title={obj} fontSize="14px !important">
                {obj ? formatDate(obj, dottedHourFormat) : ""}
              </MDInputValue>
            </Div>
          ))}
        </ListItem>
      );
    }
    return (
      <ListItem title={itemData} className={`${className} text-truncate`}>
        <Div display={["flex", "grid"]}>
          <MDLabel className="text-truncate">
            {itemData ? formatDate(itemData, twoDigitDateFormat) : "-"}
          </MDLabel>
          {isMobile && <Span mr={1}>,</Span>}
          <MDInputValue className="text-truncate" fontSize="14px !important">
            {itemData ? formatDate(itemData, dottedHourFormat) : ""}
          </MDInputValue>
        </Div>
      </ListItem>
    );
  }
  if (type === listItemTypes.number) {
    return (
      <ListItem title={itemData} className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center">
            {itemData || "-"}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.personalNumber) {
    return (
      <ListItem title={itemData} className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center">{itemData || "-"}</Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.type) {
    return (
      <ListItem title={Constants.types[itemData] || "-"} className={`${className}`}>
        <Content
          color={itemData === Constants.status.new ? Colors.Yellow : Colors.Black}
        >
          <Span className="text-truncate" alignSelf="center">
            {Constants.types[itemData] || "-"}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.price) {
    if (itemData && itemData.length && typeof (itemData) === "object") {
      return (
        <ListItem className={`${className} ${isMobile ? "d-grid" : "d-inline-block align-self-center"}`}>
          {itemData.map((obj) => (
            <MDLabel className="text-truncate">
              <Span className="text-truncate">
                {obj !== "-" ? `${language.common_sek} ${obj}` : "-"}
              </Span>
            </MDLabel>
          ))}
        </ListItem>
      );
    }
    return (
      <ListItem className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center">
            {itemData !== "-" ? `${language.common_sek} ${itemData}` : "-"}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.duration) {
    return (
      <ListItem className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center">
            {itemData !== "-" ? `${itemData} min` : "-"}
          </Span>
        </Content>
      </ListItem>
    );
  }
  if (type === listItemTypes.applicationStatus) {
    return (
      <ListItem className={`${className}`}>
        <Content>
          <Span className="text-truncate" alignSelf="center">
            {applicationStatus[itemData]}
          </Span>
        </Content>
      </ListItem>
    );
  }
  return (
    <ListItem title={itemData} className={`${className}`}>
      <Content>
        <Span className="text-truncate" alignSelf="center">
          {itemData || "-"}
        </Span>
      </Content>
    </ListItem>
  );
};

ColumnItem.propTypes = {
  type: PropTypes.string,
  itemData: PropTypes.node,
  className: PropTypes.string,
  group: PropTypes.bool,
  isMobile: PropTypes.bool,
  redirectTo: PropTypes.func,
  isOrders: PropTypes.bool,
  isCustomers: PropTypes.bool,
  isOrg: PropTypes.bool,
  redirectToOrg: PropTypes.func,
};
ColumnItem.defaultProps = {
  type: "",
  itemData: "",
  className: "",
  group: false,
  isMobile: false,
  redirectTo: () => {},
  isOrders: false,
  isCustomers: false,
  isOrg: false,
  redirectToOrg: () => {},
};
export default ColumnItem;
