import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import PortalContainer from "../../components/Containers/PortalContainer";
import Button from "../../components/Button/Button";
import FilterButton from "../../components/Filter/FilterButton";
import { Div, Header } from "../../styles/Styles";
import ListScreen from "../../components/ListScreen/ListScreen";
import PlusIcon from "../../assets/Icons/PlusIccon.svg";
import Pagination from "../../components/Pagination/Pagination";
import Constants from "../../shared/Constants";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import Service from "../../services/ServicesPageService";
import Config from "../../config";
import ListScreenLoader from "../../components/Loader/ListScreenLoader";
import EditCategory from "./Components/EditCategory";
import { IsDesktop, IsMobile } from "../../components/ListScreen/Styles";
import { setLoader, loggedInUser } from "../../store/actions/AppAction";
import SortButton from "../../components/Sorting/Sorting";
import AuthService from "../../services/AuthService";
import { getCategoryFilters, getAvailableServices } from "../../store/actions/FilterAction";
import { checkRightPermission } from "../../shared/utils";

const {
  language, sorting: { type: sortType, keys: { sortBy, sort } },
  filter: { components }, adminRoutes,
} = Constants;
class Services extends Component {
  service = new Service(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);

    const { userDetails = {}, location : { search }  } = this.props;

    const userInfo = userDetails.user_access
      ? userDetails : localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};

    const { permissions = [] } = userInfo;

    const hasWriteAccess= checkRightPermission(permissions,"is_services_editable",Constants.WRITE);

    const params = new URLSearchParams(search);
    const pageNo = params.get("page");

    this.state = {
      hasWriteAccess,
      deleteAccID: "",
      meta: {
        pages: 1,
        totalCount: null,
        pageNumber: pageNo || 1,
        pageSize: 10,
      },
      sortedBy: "",
      sortAscending: false,
      isFilterOpen: false,
      isDeleteModalOpen: false,
      isServiceDeleted: false,
      isCategoryModalOpen: false,
      services: [],
      filterOptions: [
        {
          id: "category",
          component: components.CHECKBOX,
          label: language.label_service,
          values: [],
          defaultValues: [],
          name: "organisation",
        },
      ],
      toBeFilteredData: {},
      showSortModal: false,
      filterValues: {}
    };
  }

  componentDidMount() {
    const { categoryFilters = [], history: { location } } = this.props;
    const { filterOptions } = this.state;
    const search = location.search;
    const params = new URLSearchParams(search);
    const appliedServiceFilters = JSON.parse(params.get("filter"));
    const { filter= {}, options = filterOptions } = appliedServiceFilters || {};
    let applyOptions = options;
    applyOptions[0].values = categoryFilters;    
    this.setState({
      filterOptions: applyOptions,
      filterValues: appliedServiceFilters || {},
    });
    this.fetchServices(filter, false, applyOptions);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: { pageNumber },
      sortedBy,
      sortAscending,
      filterOptions,
      filterValues,
    } = this.state;
    const { location = {} } = this.props;

    const { filter= {}, options = filterOptions } = filterValues;
    if (
      prevState.meta.pageNumber !== pageNumber
      || location.key !== prevProps.location.key
      || sortedBy !== prevState.sortedBy
      || sortAscending !== prevState.sortAscending
    ) {
      this.fetchServices(filter, false, options);
    }
  }

  /**
   *
   * @async
   * @function fetchServices Fetches all types of services.
   * @returns data of the services.
   *
   */
  fetchServices = async (filters, searchInput, options) => {
    const {
      onLoading, getCategories, categoryFilters, getServiceFilters,
    } = this.props;
    onLoading(true);
    getCategories();
    getServiceFilters();
    const {
      meta: { pageNumber, pageSize },
      sortedBy,
      sortAscending,
      toBeFilteredData,
      filterOptions,
    } = this.state;
    const filterValues = options || filterOptions;
    const filter = filters || toBeFilteredData;
    const queryParams = [
      ["pageNumber", searchInput ? 1 : pageNumber],
      ["pageSize", pageSize],
      [sortBy, sortedBy],
      [sort, sortAscending ? sortType.ASC : sortType.DESC],
      ["filter", filter],
    ];
    try {
      const response = await this.service.getServiceList(queryParams);
      filterValues[0].values = categoryFilters;
      const { meta: srcMeta = {}, data = [] } = response;
      const services = data.map((o)=>({...o, duration: `${o?.duration?.mini} - ${o?.duration?.large}`}));
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMeta;
      const newMeta = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      window.scrollTo(0,0);
      this.setState({
        filterOptions: filterValues,
        services,
        meta: newMeta,
      });
      onLoading(false);
    } catch (e) {
      onLoading(false);
    }
  };

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @function handleAddService
   * Redirects to create new service page
   *
   */
  handleAddService = () => {
    const { history } = this.props;
    history.push(Constants.routes.addService.url);
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  handleGotoPage = (pageNumber) => {
    const { history } = this.props;
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    this.setState(
      ({ meta }) => ({
        meta: { ...meta, pageNumber },
      }),
    );
  };

  /**
   * Handles filter actions
   */
  handleCloseFilter = () => {
    const { isFilterOpen } = this.state;
    this.setState({ isFilterOpen: !isFilterOpen });
  };

  handleFilterApply=(filterValues = [], options = undefined) => {
    const { history }=this.props;
    const filters = {};
    filterValues.map((obj) => {
      if (filters[obj.id]) {
        filters[obj.id].push(obj.value);
      } else {
        filters[obj.id] = [];
        filters[obj.id].push(obj.value);
      }
      return obj;
    });
    this.setState({ 
      toBeFilteredData: filters, 
      isFilterOpen: false,
      filterValues: {
        filter: filters,
        values: filterValues,
        options,
      },
    });
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("filter", JSON.stringify({ filter: filters, options }));
    currentUrlParams.set("page", 1);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    this.fetchServices(filters, filterValues, options);
  }

  /**
   *
   * Redirects to detailed view page
   * @param {object} item service data
   * @returns to selected service's detailed page
   *
   */
  handleDetailPage = (item) => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.services}/${item.id}/${adminRoutes.editService}`);
  };

  handleEditService = (item) => () => {
    const { history } = this.props;
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.services}/${item.id}/${adminRoutes.editService}`);
  };

  /**
   * Opens a delete confirmation popup
   * @param {object} item selected service data
   */
  handleDeleteModal = (item) => {
    const { id = "" } = item;
    this.setState({ isDeleteModalOpen: true, deleteAccID: id }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * @async
   * @function handleDeleteService
   * Deletes the selected service.
   * @returns service deletion
   *
   */
  handleDeleteService = async () => {
    const { deleteAccID } = this.state;
    const { onLoading } = this.props;
    onLoading(true);
    try {
      const response = await this.service.deleteService(deleteAccID);
      if (response) {
        this.getDetails();
        this.setState({ isDeleteModalOpen: false, isServiceDeleted: true, deleteAccID: "" }, this.fetchServices);
        onLoading(false);
      }
    } catch (e) {
      onLoading(false);
    }
  };

  handleSaveCategory=async () => {
    const { getCategories } = this.props;
    await getCategories();
    this.handleCancel();
    this.fetchServices();
  }

  /**
   * Cancels the service deletion operation
   */
  handleCancel = () => {
    this.setState({
      isServiceDeleted: false,
      isDeleteModalOpen: false,
      isCategoryModalOpen: false,
    });
  };

  /**
   *
   * Handles sorting of columns in the list screen
   * @param {object} item column data
   * @returns sorted list
   *
   */
  handleSorting = (item) => () => {
    const { sortedBy, sortAscending } = this.state;
    if (sortedBy === item) {
      this.setState({ sortAscending: !sortAscending });
    } else {
      this.setState({ sortedBy: item, sortAscending: true });
    }
  };

  handleOpenEditCategory=() => {
    this.setState({ isCategoryModalOpen: true }, () => {
      this.openElement.click();
    });
  }

  handleSortModal=() => {
    const { showSortModal } = this.state;
    this.setState({ showSortModal: !showSortModal });
  }

  handleApplySorting=(sortKey = "", sortOrder = false) => {
    this.setState({ sortedBy: sortKey, sortAscending: sortOrder, showSortModal: false });
  }

  render() {
    const {
      meta,
      filterOptions,
      isFilterOpen,
      isDeleteModalOpen,
      isServiceDeleted,
      isCategoryModalOpen,
      hasWriteAccess,
      services,
      sortedBy,
      sortAscending,
      showSortModal,
    } = this.state;
    const { isLoader } = this.props;
    const header = [
      {
        className: "col-2 ",
        value: language.placeholder_name,
        key: "name",
        icon: sortType.ALPHABETS,
      },
      {
        className: "col",
        value: language.label_category,
        key: "category_name",
        icon: sortType.ALPHABETS,
      },
      {
        className: "col",
        value: language.list_header_price_xs,
        key: "price_mini",
        icon: sortType.ARROW,
      },
      {
        className: "col",
        value: language.list_header_price_s,
        key: "price_small",
        icon: sortType.ARROW,
      },
      {
        className: "col",
        value: language.list_header_price_m,
        key: "price_medium",
        icon: sortType.ARROW,
      },
      {
        className: "col",
        value: language.list_header_price_b,
        key: "price_large",
        icon: sortType.ARROW,
      },
      {
        className: "col",
        value: language.list_header_duration,
      },
    ];
    const value = [
      {
        className: "col-2",
        key: "name",
        type: "user",
        group: false,
        isDesktop: true,
      },
      {
        className: "col",
        key: "category_name",
        type: "text",
        group: false,
      },
      {
        className: "col",
        key: "price_mini",
        type: "price",
        group: false,
      },
      {
        className: "col",
        key: "price_small",
        type: "price",
        group: false,
      },
      {
        className: "col",
        key: "price_medium",
        type: "price",
        group: false,
      },
      {
        className: "col",
        key: "price_large",
        type: "price",
        group: false,
      },
      {
        className: "col",
        key: "duration",
        type: "duration",
        group: true,
      },
    ];
    return (
      <PortalContainer>
        <Div justifyContent="space-between" display="flex" pb={4}>
          <Div>
            <Header display="inline-block">
              <FormattedMessage id="common_services" />
            </Header>
          </Div>
          <IsDesktop>
            <Div className="row m-0">
              {hasWriteAccess && (
                <>
                  <Button
                    label={<FormattedMessage id="service_edit_categories" />}
                    secondary
                    startIcon={Constants.icons.EditIcon}
                    width="auto"
                    onClick={this.handleOpenEditCategory}
                    padding="10px !important"
                    className="mr-3"
                  />
                  <Button
                    label={<FormattedMessage id="service_add_new_service" />}
                    primary
                    startIcon={PlusIcon}
                    width="auto"
                    onClick={this.handleAddService}
                    padding="10px !important"
                  />
                </>
              )}
              <FilterButton
                open={isFilterOpen}
                options={filterOptions}
                closeFilter={this.handleCloseFilter}
                onApply={this.handleFilterApply}
              />
            </Div>
          </IsDesktop>
        </Div>
        <IsMobile>
          <Div display="flex" justifyContent="space-between" pb={4}>
            {hasWriteAccess && (
              <Div>
                <Button
                  label={<FormattedMessage id="service_add_new_service" />}
                  primary
                  startIcon={PlusIcon}
                  width="175px"
                  onClick={this.handleAddService}
                  padding="10px !important"
                  className="mb-3 mr-3"
                />
                <Button
                  label={<FormattedMessage id="service_edit_categories" />}
                  secondary
                  startIcon={Constants.icons.EditIcon}
                  width={["185px", "175px"]}
                  onClick={this.handleOpenEditCategory}
                  padding="10px !important"
                />
              </Div>
            )}
            <Div display="flex">
              <SortButton
                isOpen={showSortModal}
                sortingKeys={header}
                sortedBy={sortedBy}
                sortAscending={sortAscending}
                onClose={this.handleSortModal}
                onSubmit={this.handleApplySorting}
              />
              <FilterButton
                open={isFilterOpen}
                options={filterOptions}
                closeFilter={this.handleCloseFilter}
                onApply={this.handleFilterApply}
              />
            </Div>
          </Div>
        </IsMobile>
        {isLoader && <ListScreenLoader />}
        {!isLoader && (
          <>
            <ListScreen
              moreButton
              deleteAction={hasWriteAccess}
              editAction={hasWriteAccess}
              header={header}
              value={value}
              data={services}
              onEdit={this.handleDetailPage}
              onDelete={this.handleDeleteModal}
              onClick={this.handleEditService}
              handleSorting={this.handleSorting}
              sortedBy={sortedBy}
              sortAscending={sortAscending}
              dataNotFoundMessage={<FormattedMessage id="services_not_found" />}
            />

            {meta.pages > 1 && (
              <Div py={4} display={["block", "flex", "flex"]} >
                <Div display={["flex"]} justifyContent="center" alignItems="center">
                  {`${services.length < 10 ? services.length + ((meta.pageNumber - 1) * 10) : services.length * meta.pageNumber} / ${meta.totalCount}`}
                </Div>
                <Div width="90%" justifyContent="center">
                  <Pagination gotoPage={this.handleGotoPage} cursor={meta} /></Div>
              </Div>
            )}
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => {
            this.openElement = openModal;
          }}
        />
        {isCategoryModalOpen && (
          <Modal>
            <EditCategory onCancel={this.handleCancel} onSubmit={this.handleSaveCategory} />
          </Modal>
        )}
        {isDeleteModalOpen && (
          <Modal>
            <Card
              header={<FormattedMessage id="service_delete_service" />}
              message={<FormattedMessage id="service_deleted_confirmation_message" />}
              buttonLabel={<FormattedMessage id="common_delete" />}
              confirmationCard
              buttonIcon={Constants.icons.DeleteIcon}
              onClick={this.handleDeleteService}
              onCancel={this.handleCancel}
              onLoader={isLoader}
            />
          </Modal>
        )}
        {isServiceDeleted && (
          <Modal>
            <Card
              header={<FormattedMessage id="common_done" />}
              message={<FormattedMessage id="service_deleted_alert_message" />}
              alertCard
              buttonLabel="Ok"
              onClick={this.handleCancel}
            />
          </Modal>
        )}
      </PortalContainer>
    );
  }
}
Services.propTypes = {
  history: PropTypes.node,
  location: PropTypes.node,
  userDetails: PropTypes.node,
  onLoading: PropTypes.func,
  isLoader: PropTypes.bool,
  serviceFilters: PropTypes.node,
  categoryFilters: PropTypes.node,
  getCategories: PropTypes.func,
  getServiceFilters: PropTypes.func,
  getLoginUser: PropTypes.func,
};
Services.defaultProps = {
  history: undefined,
  location: null,
  userDetails: {},
  onLoading: () => {},
  isLoader: false,
  serviceFilters: [],
  categoryFilters: [],
  getCategories: () => {},
  getServiceFilters: () => {},
  getLoginUser: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
  isLoader: state.AppReducer.isLoader,
  serviceFilters: state.FilterReducer.serviceFilters,
  categoryFilters: state.FilterReducer.categoryFilters,
});
function mapDispatchToProps(dispatch) {
  return {
    onLoading: (payload) => dispatch(setLoader(payload)),
    getServiceFilters: () => dispatch(getAvailableServices()),
    getCategories: () => dispatch(getCategoryFilters()),
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Services);
