import markerSDK from "@marker.io/browser";
import Config from "./config";
import Package from "../package.json";

const loadMarkerIO = async () => {
  const widget = await markerSDK.loadWidget({
    destination: Config.markerDestinationId,
    version: Package.version,
  });
  return widget;
};

export default loadMarkerIO;
