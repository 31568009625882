import React from "react";
import { BrowserRouter as Router ,Switch} from "react-router-dom";

import PrivateRoute from "./shared/PrivateRoute";
import Container from "./components/Container";
import RouteList from "./shared/RoutesList";
import Config from "./config";


const AppRoute = () => (
  <Router basename={`${Config.baseName}`}>
    <Container>
      <Switch>
        {RouteList.map(({ url: route, component: Component, isAdmin = false }) => (
          <PrivateRoute
            exact
            path={route}
            component={Component}
            isAdmin={isAdmin}
          />
        ))}
      </Switch>
    </Container>
  </Router>
);
export default AppRoute;
