import React from "react";
import Loader from "./Loader";
import { Card, Div } from "../../styles/Styles";

const CardLoader = () => (
  <Card width="350px" className="p-2 my-2">
    <Div display="flex" width="350px">
      <Loader width="80px" height="80px" />
      <Div className="mx-3">
        <Loader width="150px" />
        <Loader width="100px" />
      </Div>
    </Div>
  </Card>
);
export default CardLoader;
