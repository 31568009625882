import { useSelector } from "react-redux";
import Constants from "../../shared/Constants";
import { checkRightPermission, checkPermission } from "../../shared/utils";

const useUserPermission = () => {
  const userPermissions = useSelector((state) => state.AppReducer?.userDetails?.permissions);

  const isCalendarManagable = () => checkRightPermission(userPermissions,"is_calendar_editable",Constants.MANAGE);

  const isCalendarEditable = () => checkRightPermission(userPermissions,"is_calendar_editable",Constants.WRITE);

  const isCalendarReadable = () => checkRightPermission(userPermissions,"is_calendar_editable",Constants.READ);

  const isAssignmentsReadable = () =>  checkPermission(userPermissions,"is_assignments_editable");

  return { isCalendarManagable, isCalendarEditable, isAssignmentsReadable, isCalendarReadable };
};

export default useUserPermission;
