import React from "react";
import styled, { keyframes } from "styled-components";
import { Div } from "../../styles/Styles";

const preloadAnim = keyframes`
0% {
    background-position: -769px 0;
  }
100% {
    background-position: 769px 0;
  }
`;

const Division = styled(Div)`
  animation-duration: 30.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${preloadAnim};
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #d1dee4 8%, #eeeef2 18%, #d1dee4 33%);
`;
Division.defaultProps = {
  my: "8px",
  height: "24px",
  width: "100%",
  display: "inherit",
};

// eslint-disable-next-line react/jsx-props-no-spreading
const Loader = (props) => <Division className="skeletonLoader" {...props} />;

export default Loader;
