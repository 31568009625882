import React, { useState, memo, useEffect } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  serviceBookingDate,
  serviceBookingMonth,
  fetchAvailableDates,
  updateServiceBookingDate,
} from "../../../store/actions/BookingFlowServicesAction";
import { Division } from "../BookingFlowStyles/BookingFlowStyles";
import { getStartAndEndDateOfaMonth, formatDate } from "../../../shared/utils";
import Constants from "../../../shared/Constants";
import { setLoader } from "../../../store/actions/AppAction";

const Div = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: inherit;
`;

const ToolTip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    background-color: #ffffff;
    color: #000000;
    &::after {
      border-top-color: #ffffff !important;
    }
  }
`;

// eslint-disable-next-line react/prop-types
const CalendarCard = ({ setcurrentSelectedDate, currentSelectedDate, minDate, organisationId, serviceId, dogWeight = "" }) => {
  const dispatch = useDispatch();
  const [activeTransparent, setActiveTransparent] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const {
    input: { format },
  } = Constants;
  const selectedServices = useSelector((state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices);
  const dogSize = useSelector((state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes);

  const { service = {} } =selectedServices;

  const { is_full_day_service = "" } = service;

  const getCalendarDate = () => {
    if(currentSelectedDate){
      if(is_full_day_service) {
        return Array(currentSelectedDate).isArray ? [moment(currentSelectedDate[0], format.date).toDate(), moment((currentSelectedDate[1] || currentSelectedDate[0]), format.date).toDate()]: [moment(currentSelectedDate, format.date).toDate(),moment(currentSelectedDate, format.date).toDate()];
      } else {
        return moment(currentSelectedDate, format.date).toDate();
      }
    } else {
      return null;
    }
  }

  const [timeData, setTimeData] = useState([]);
  const [calendarValue, setCalendarValue] = useState(getCalendarDate());
  const [availableMonth] = useState(getStartAndEndDateOfaMonth(new Date()));
  const [isFirstTime, setIsFirstTime] = useState(true);
  const setLoaderState = (params) => dispatch(setLoader(params));

  const getAvailableSlots = useSelector((state) => state.BookingFlowServicesReducer.getAvailableDates);

  useEffect(() => {
    const newavailableMonth = getStartAndEndDateOfaMonth(new Date());
    const { startDate, endDate } = newavailableMonth;
    if(is_full_day_service) {
      dispatch(serviceBookingDate(getCalendarDate()));
    } else {
      dispatch(serviceBookingDate(currentSelectedDate ? moment(currentSelectedDate, format.date).toDate() : new Date()));
    }
    dispatch(
      serviceBookingMonth({
        startDate: startDate.format(Constants.input.format.date),
        endDate: endDate.format(Constants.input.format.date),
      })
    );
  }, []);

  async function handleOnChangeCalendar (e) {
    setActiveTransparent(true);
    setCalendarValue(e);
    if(is_full_day_service) {
      setcurrentSelectedDate([moment(e[0]).format(Constants.input.format.date), moment(e[1]).format(Constants.input.format.date)]);
    } else {
      setcurrentSelectedDate(moment(e).format(Constants.input.format.date));
    }
    if(is_full_day_service && typeof e[1] === "undefined") {
      setSelectedStartDate(new Date(e[0]));
    } else {
      setSelectedStartDate(null);
    }
    setTimeData(timeData.map((o) => ({ ...o, isDisabled: false, isSelected: false })));
    let { startDate = "", endDate = "" } = availableMonth;
    endDate = Date.parse(endDate);
    startDate = Date.parse(startDate);
    if(is_full_day_service) {
      dispatch(updateServiceBookingDate([moment(new Date(e[0])).format("YYYY-MM-DD"),moment(new Date(e[1] || e[0])).format("YYYY-MM-DD")]));
    } else {
      dispatch(updateServiceBookingDate(moment(new Date(e)).format("YYYY-MM-DD")));
    }
    setLoaderState(true);
    startDate = is_full_day_service ? moment(e[0]) : moment(e) || startDate;
    endDate = is_full_day_service ? moment(e[1]) : moment(e) || endDate;
    dispatch(
      serviceBookingMonth({
        startDate: startDate.format(Constants.input.format.date),
        endDate: endDate.format(Constants.input.format.date),
      })
    );
  }

  const calenderStartEnd = useSelector((state) => state.BookingFlowServicesReducer.calenderStartEnd);

  const fetchAvailabilty = (activeDate = moment(new Date()).format("YYYY-MM-DD")) => {
    const firstDay = moment(activeDate, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD");
    const lastDay = moment(activeDate, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD");
    setSelectedStartDate(null);
    dispatch(fetchAvailableDates(organisationId, firstDay, lastDay, serviceId, dogWeight, isFirstTime, dogSize.duration));
  };

  const {selectedDate =  moment(new Date()).format("YYYY-MM-DD") } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer
  );

  useEffect(() => {
    setIsFirstTime(false);
    const filterDates = getAvailableSlots.filter(
      (obj) => moment(obj.date).toDate() > moment(new Date()).subtract(1, "days")
    );
    if (filterDates?.length) {
      let [{ date: activeDate = "" }] = filterDates;
      if (is_full_day_service) {
        const setDates = currentSelectedDate && Array.isArray(currentSelectedDate)
          ? [moment(currentSelectedDate[0]).toDate(), moment(currentSelectedDate[1] || currentSelectedDate[0]).toDate()]
          : moment(activeDate).toDate();
        const selectDates = currentSelectedDate && Array.isArray(currentSelectedDate)
          ? [moment(currentSelectedDate[0]).format("YYYY-MM-DD"), moment(currentSelectedDate[1] || currentSelectedDate[0]).format("YYYY-MM-DD")]
          : moment(activeDate).format("YYYY-MM-DD");
        setcurrentSelectedDate(selectDates);
        setCalendarValue(setDates);
        dispatch(serviceBookingDate(selectDates));
      } else {
        if(filterDates.find((o)=> o?.date === moment(selectedDate).format("YYYY-MM-DD"))) {
          setcurrentSelectedDate(selectedDate);
          setCalendarValue(moment(selectedDate).toDate());
          dispatch(serviceBookingDate(selectedDate));
        } else {
          setcurrentSelectedDate(activeDate);
          setCalendarValue(moment(activeDate).toDate());
          dispatch(updateServiceBookingDate(activeDate));
        }
      }
    }
  }, [getAvailableSlots]);


  useEffect(() => {
    const { startDate = "" } = calenderStartEnd;
    if (selectedDate || startDate) {
      fetchAvailabilty(selectedDate || startDate);
    }
  }, []);

  function tileDisabled ({ date }) {
    const formattedDate = formatDate(date);

    if(is_full_day_service){
      // Here we are trying to group the continous dates
      const groupedDates = getAvailableSlots
        .map((obj) => obj.date)
        .reduce((acc, date) => {
          const group = acc[acc.length - 1];
          if (moment(date).diff(moment(group[group.length - 1] || date), "days") > 1) {
            acc.push([date])
          } else {
            group.push(date);
          }
          return acc;
        }, [[]])
        .find((obj) => obj?.includes(formatDate(selectedStartDate)));

      if(groupedDates){
        return !groupedDates.flat().find((obj) => obj === formattedDate);
      }
    }
    return !getAvailableSlots.find((obj) => obj.date === formattedDate);
  }

  const onActiveStartDateChange = ({ activeStartDate }) => {
    fetchAvailabilty(moment(activeStartDate).format(format.date));
  };

  function tileContent ({ date }) {
    const formattedDate = formatDate(date);
    const isDisabled =  !getAvailableSlots.find((obj) => obj.date === formattedDate);
    return (<>
      {isDisabled && is_full_day_service ?
        (<>
          <Div data-tip={Constants.language.services_provider_not_found} data-for='toolTip1' data-place='top'>
            {moment(date).format("D")}
          </Div>
          <ToolTip id="toolTip1" />
        </>
        ):
        <Div>
          {moment(date).format("D")}
        </Div>
      }
    </>
    );
  }

  return (
    <Division className="modifyCalendar">
      {is_full_day_service ? (
        <Calendar
          className={activeTransparent ? "transparent" : ""}
          onChange={handleOnChangeCalendar}
          value={calendarValue}
          calendarValue="calendarValue"
          minDate={minDate ? new Date(minDate) : new Date()}
          tileContent={tileContent}
          tileDisabled={tileDisabled}
          selectRange
          onActiveStartDateChange={onActiveStartDateChange}
          allowPartialRange={true}
        />
      ) : (
        <Calendar
          className={activeTransparent ? "transparent" : ""}
          onChange={handleOnChangeCalendar}
          value={calendarValue}
          calendarValue="calendarValue"
          minDate={minDate ? new Date(minDate) : new Date()}
          tileDisabled={tileDisabled}
          tileContent={tileContent}
          onActiveStartDateChange={onActiveStartDateChange}
        />
      )}
    </Division>
  );
};
export default memo(CalendarCard);
