import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Constants from "../../../shared/Constants";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/Input/TextBox";
import { CardWrapper, Label } from "../Styles";
import NameCard from "../../../components/Card/ProfileCard";
import {
  Div, IsDesktop, IsMobile, FormWrapper, ContentRight, ContentLeft,
} from "../../../styles/Styles";
import TextArea from "../../../components/Input/TextArea";
import SelectBox from "../../../components/Input/SelectBox";
import CustomerService, { getDogBreeds } from "../../../services/CustomerService";
import Config from "../../../config";
import UploadPicture from "../../../components/UploadSection/UploadPicture";
import FormLoader from "../../../components/Loader/FormLoader";
import DogModel from "../../../models/Customers";

const { language, themes: { widths }, options: { dogsAge } } = Constants;

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(language.validation_invalid_name),
  breed: Yup.object().required(language.validation_breed),
  dog_size_weight: Yup.object().required(language.validation_weight),
  dog_size_height: Yup.object().required(language.validation_height),
  age: Yup.object().required(language.validation_age),
  gender: Yup.string().required(language.validation_dog_gender),
  isSterilized: Yup.boolean().required(language.validation_option),
  isInsured: Yup.boolean().required(language.validation_option),
});
class CreateDogForm extends Component {
    dogService = new CustomerService(Config.apiBase);

    constructor(props) {
      super(props);

      this.state = {
        loading: false,
        breedOptions: [],
        ageOptions: dogsAge.map((o) => ({ ...o, isSelected: false })),
        weightOptions: [
          { label: language.size_dog_mini, value: "mini" },
          { label: language.size_dog_small, value: "small" },
          { label: language.size_dog_medium, value: "medium" },
          { label: language.size_dog_large, value: "large" },
        ],
        heightOptions: [
          { label: language.height_dog_mini, value: "mini" },
          { label: language.height_dog_small, value: "small" },
          { label: language.height_dog_medium, value: "medium" },
          { label: language.height_dog_large, value: "large" },
        ],
        data: {
          name: "",
          breed: "",
          gender: "",
          isSterilized: "",
          dog_size_weight: "",
          dog_size_height: "",
          age: "",
          isInsured: "",
          description: "",
          profilePic: "",
        },
        isDogCreated: false,
      };
    }

    componentDidMount = async () => {
      this.setState({ loading: true });
      const breedOptions = await getDogBreeds();
      this.setState({ breedOptions });
      const { edit } = this.props;
      if (edit) {
        await this.handleFormatData();
      }
      this.setState({ loading: false });
    };

    handleFormatData=() => {
      const { dogData = {} } = this.props;
      const { data = {} } = dogData;
      const {
        breedOptions, heightOptions, ageOptions, weightOptions,
      } = this.state;
      const {
        dog_size_weight, breed, age, dog_size_height,
      } = data;
      const weigthValue = weightOptions.filter((wgt) => wgt.value === dog_size_weight);
      const heightValue = heightOptions.filter((hgt) => hgt.value === dog_size_height);
      const breedValue = breedOptions.filter((brd) => brd.value === breed);
      const ageValue = ageOptions.filter((ag) => ag.value === age);
      data.dog_size_weight = weigthValue && weigthValue[0];
      data.dog_size_height = heightValue && heightValue[0];
      data.age = ageValue && ageValue[0];
      data.breed = breedValue && breedValue[0];
      this.setState({ data });
    }

    handleReset=() => {
      const { edit, onCancel, isOrder } = this.props;
      const {
        breedOptions, ageOptions, weightOptions, heightOptions,
      } = this.state;
      if (edit || isOrder) {
        onCancel();
      } else {
        this.setState({
          ageOptions: ageOptions.map((o) => ({ ...o, isSelected: false })),
          weightOptions: weightOptions.map((o) => ({ ...o, isSelected: false })),
          heightOptions: heightOptions.map((o) => ({ ...o, isSelected: false })),
          breedOptions: breedOptions.map((o) => ({ ...o, isSelected: false })),
        });
      }
    }

    handleSubmit=async (data) => {
      const {
        handleBackToCustomers, userId, submitData, edit, dogId, isOrder,
      } = this.props;
      if (edit) {
        submitData(dogId, data);
      } else {
        this.setState({ loading: true });
        try {
          this.dogModel = new DogModel({});
          const { clientId = userId } = userId;
          const response = await this.dogService.addDog(clientId, this.dogModel.toDogJson(data));
          if (response) {
            if (isOrder) {
              this.setState({ isDogCreated: true, loading: false });
            } else {
              handleBackToCustomers(true);
            }
          }
        } catch (e) {
          this.setState({ loading: false });
        }
      }
    }

    render() {
      const {
        data, loading,
        breedOptions, ageOptions, heightOptions, isDogCreated, weightOptions,
      } = this.state;
      const {
        input: { name: inputNames },
        icons,
      } = Constants;
      const {
        edit, disableData, deleteDog, dogId, isOrder, handleBackToCustomers,
      } = this.props;
      return (
        <>
          {isDogCreated
            ? (
              <>
                <Div justifyContent="center" display="flex" width="100%" my={4}>
                  <FormattedMessage id="new_dog_added_message" defaultMessage="New dog added succesfully" />
                </Div>
                <Div justifyContent="center" display="flex" width="100%" mb={4}>
                  <Button
                    startIcon={icons.AssignmentsMenuIcon}
                    label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
                    onClick={handleBackToCustomers}
                  />
                </Div>
              </>
            )
            : (
              <Formik
                enableReinitialize
                initialValues={data}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit}
                render={(props) => {
                  const {
                    values, handleChange, handleBlur, handleSubmit, isValid,
                    touched, dirty, handleReset, errors, validateField,
                  } = props;
                  return (
                    <>
                      {loading && (
                        <FormWrapper>
                          <FormLoader />
                        </FormWrapper>
                      )}
                      {!loading && (
                        <>
                          <FormWrapper width={isOrder ? `${widths.full}` : `${widths.w730}`}>
                            <UploadPicture
                              label={language.label_picture}
                              value={values.profilePic}
                              name={inputNames.profilePic}
                              files={values.profilePic}
                              formikValues={props}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disableData}
                            />
                            <Form width={isOrder ? `${widths.full}` : `${widths.w730}`}>
                              <Div className="row m-0">
                                <ContentLeft>
                                  <TextBox
                                    startIcon={icons.PawIcon}
                                    containerProps="m-auto justify-content-center"
                                    label={language.label_dog_name}
                                    placeholder={language.label_dog_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    name={inputNames.name}
                                    formikValues={props}
                                    IconHeight="20px"
                                    disabled={disableData}
                                  />
                                </ContentLeft>
                                <ContentRight>
                                  <SelectBox
                                    onChange={handleChange}
                                    onBlur={() => validateField(inputNames.breed)}
                                    value={values.breed}
                                    name={inputNames.breed}
                                    options={breedOptions}
                                    placeholder={language.placeholder_choose}
                                    label={language.label_select_breed}
                                    formikValues={props}
                                    disabled={disableData}
                                  />
                                </ContentRight>
                              </Div>
                              <Div className="row m-0">
                                <ContentLeft>
                                  <SelectBox
                                    onChange={handleChange}
                                    onBlur={() => validateField(inputNames.dog_size_weight)}
                                    value={values.dog_size_weight}
                                    name={inputNames.dog_size_weight}
                                    options={weightOptions}
                                    placeholder={language.placeholder_choose}
                                    label={language.label_select_weight_dog}
                                    formikValues={props}
                                    disabled={disableData}
                                  />
                                </ContentLeft>
                                <ContentRight>
                                  <SelectBox
                                    onChange={handleChange}
                                    onBlur={() => validateField(inputNames.dog_size_height)}
                                    value={values.dog_size_height}
                                    name={inputNames.dog_size_height}
                                    options={heightOptions}
                                    placeholder={language.placeholder_choose}
                                    label={language.label_select_height_dog}
                                    formikValues={props}
                                    disabled={disableData}
                                  />
                                </ContentRight>
                              </Div>
                              <Div className="row m-0">
                                <ContentLeft>
                                  <Div pb={2} pt={3}>
                                    <Label disable={disableData}>
                                      {language.label_select_gender}
                                    </Label>
                                    {(errors.gender && touched.gender) && (
                                      <Label error>
                                        {errors.gender}
                                      </Label>
                                    )}
                                  </Div>
                                  <Div
                                    m={0}
                                    flexDirection="row"
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                    flex={[1, 0]}
                                  >
                                    <Div pr={[2, 3]} width="50%">
                                      <NameCard
                                        nameCard
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.gender,
                                            value: inputNames.male,
                                          },
                                        })}
                                        userAvtar={icons.MaleDog}
                                        showCursor
                                        data={{ label: language.label_gender_male, isSelected: values.gender === "male" }}
                                        width="100%"
                                      />
                                    </Div>
                                    <Div pl={[2, 3]} width="50%">
                                      <NameCard
                                        nameCard
                                        userAvtar={icons.FemaleDog}
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.gender,
                                            value: inputNames.female,
                                          },
                                        })}
                                        showCursor
                                        data={{ label: language.label_gender_female, isSelected: values.gender === "female" }}
                                        width="100%"
                                      />
                                    </Div>
                                  </Div>
                                </ContentLeft>
                                <ContentRight>
                                  <Div pb={2} pt={3}>
                                    <Label disable={disableData}>
                                      {language.label_sterilised}
                                    </Label>
                                    {(errors.isSterilized && touched.isSterilized) && (
                                      <Label error>
                                        {errors.isSterilized}
                                      </Label>
                                    )}
                                  </Div>
                                  <Div
                                    m={0}
                                    flexDirection="row"
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Div pr={[2, 3]} width="50%">
                                      <CardWrapper
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.isSterilized,
                                            value: true,
                                          },
                                        })}
                                        isSelected={values.isSterilized}
                                        cursor="pointer"
                                      >
                                        <Label>
                                          {language.common_yes}
                                        </Label>
                                      </CardWrapper>
                                    </Div>
                                    <Div pl={[2, 3]} width="50%">
                                      <CardWrapper
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.isSterilized,
                                            value: false,
                                          },
                                        })}
                                        isSelected={values.isSterilized === false}
                                        ml={4}
                                        mt={[3, 0]}
                                        cursor="pointer"
                                      >
                                        <Label>
                                          {language.common_no}
                                        </Label>
                                      </CardWrapper>
                                    </Div>
                                  </Div>
                                </ContentRight>
                              </Div>
                              <Div className="row m-0">
                                <ContentLeft>
                                  <SelectBox
                                    onChange={handleChange}
                                    onBlur={() => validateField(inputNames.age)}
                                    value={values.age}
                                    name={inputNames.age}
                                    options={ageOptions}
                                    placeholder={language.placeholder_choose}
                                    label={language.label_select_age}
                                    formikValues={props}
                                    disabled={disableData}
                                  />
                                </ContentLeft>
                                <ContentRight>
                                  <Div className="pb-2 pt-3">
                                    <Label disable={disableData}>
                                      {language.label_is_dog_insured}
                                    </Label>
                                    {(errors.isInsured && touched.isInsured) && (
                                      <Label error>
                                        {errors.isInsured}
                                      </Label>
                                    )}
                                  </Div>
                                  <Div
                                    m={0}
                                    flexDirection="row"
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Div pr={[2, 3]} width="50%">
                                      <CardWrapper
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.isInsured,
                                            value: true,
                                          },
                                        })}
                                        onBlur={handleBlur}
                                        isSelected={values.isInsured}
                                        cursor="pointer"
                                      >
                                        <Label>
                                          {language.common_yes}
                                        </Label>
                                      </CardWrapper>
                                    </Div>
                                    <Div pl={[2, 3]} width="50%">
                                      <CardWrapper
                                        onClick={() => handleChange({
                                          target: {
                                            name: inputNames.isInsured,
                                            value: false,
                                          },
                                        })}
                                        onBlur={handleBlur}
                                        value={false}
                                        isSelected={values.isInsured === false}
                                        ml={[4, 4, 0]}
                                        mt={[3, 0]}
                                        cursor="pointer"
                                      >
                                        <Label>
                                          {language.common_no}
                                        </Label>
                                      </CardWrapper>
                                    </Div>
                                  </Div>
                                </ContentRight>
                              </Div>
                              <TextArea
                                containerProps="m-auto justify-content-center pb-1"
                                label={language.label_additional_information}
                                placeholder={language.label_additional_information}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                name={inputNames.description}
                                formikValues={props}
                                disabled={disableData}
                                width="100%"
                              />
                              {edit && (
                                <Button
                                  primary
                                  size="small"
                                  type="button"
                                  startIcon={icons.DeleteIcon}
                                  IconHeight="18px"
                                  label={<FormattedMessage id="button_delete_dog" />}
                                  onClick={() => deleteDog(dogId)}
                                  className={["py-2 mt-3", "mt-3"]}
                                  width={["100%", "auto"]}
                                  disabled={disableData}
                                />
                              )}
                              <>
                                <IsDesktop
                                  className="d-lg-flex"
                                  justifyContent={isOrder ? "center" : "start"}
                                  mb={isOrder ? 2 : 4}
                                  mt={isOrder ? 3 : 4}
                                  flexDirection={isOrder ? "row-reverse" : "row"}
                                >
                                  <Button
                                    primary
                                    type="submit"
                                    startIcon={icons.AssignmentsMenuIcon}
                                    IconHeight="18px"
                                    label={<FormattedMessage id={isOrder ? "common_ok" : "common_save"} defaultMessage={isOrder ? "Ok" : "Save"} />}
                                    onClick={handleSubmit}
                                    disable={!(isValid && dirty)}
                                    width="200px"
                                    className="mr-3"
                                    disabled={disableData}
                                  />
                                  <Button
                                    secondary
                                    type="reset"
                                    label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                                    onClick={() => { handleReset(); this.handleReset(); }}
                                    width="200px"
                                    className="mx-3"
                                    disabled={disableData}
                                  />
                                </IsDesktop>
                                <IsMobile className="my-4">
                                  <Button
                                    primary
                                    type="submit"
                                    startIcon={icons.AssignmentsMenuIcon}
                                    IconHeight="18px"
                                    label={<FormattedMessage id={isOrder ? "common_ok" : "common_save"} defaultMessage={isOrder ? "Ok" : "Save"} />}
                                    onClick={handleSubmit}
                                    disable={!(isValid && dirty)}
                                    width="100%"
                                    className="mb-3"
                                    disabled={disableData}
                                  />
                                  <Button
                                    secondary
                                    type="reset"
                                    label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                                    onClick={() => { handleReset(); this.handleReset(); }}
                                    width="100%"
                                    className="mb-4 mt-1"
                                    disabled={disableData}
                                  />
                                </IsMobile>
                              </>
                            </Form>
                          </FormWrapper>
                        </>
                      )}
                    </>
                  );
                }}
              />
            )}
        </>
      );
    }
}
CreateDogForm.propTypes = {
  edit: PropTypes.bool,
  disableData: PropTypes.bool,
  handleBackToCustomers: PropTypes.func,
  userId: PropTypes.string,
  dogData: PropTypes.string,
  deleteDog: PropTypes.func,
  dogId: PropTypes.string,
  submitData: PropTypes.func,
  onCancel: PropTypes.func,
  isOrder: PropTypes.bool,
};
CreateDogForm.defaultProps = {
  edit: false,
  disableData: false,
  handleBackToCustomers: undefined,
  userId: undefined,
  dogData: undefined,
  deleteDog: undefined,
  dogId: "",
  submitData: undefined,
  onCancel: undefined,
  isOrder: false,
};
export default CreateDogForm;
