import moment from "moment-timezone";

class Customer {
  fromCustomerDetailsJson = (details) => {
    const {
      description = "",
      email = "",
      flat = "",
      house = "",
      first_name: firstName = "",
      last_name: lastName = "",
      ban_reason: bannedReason = "",
      profile_pic: profilePic = "",
      status = "",
      street = "",
      phone_number_list: phone = [""],
      new_orders: openOrders = 0,
      name = "",
      created_at: createdAt,
    } = details;
    const data = {
      firstName,
      name,
      lastName,
      email,
      street,
      house,
      flat,
      description,
      profilePic,
      openOrders,
      phone,
      createdAt,
    };
    return {
      data,
      status,
      bannedReason,
    };
  };

  fromDogDetailsJson = (details) => details.map((obj) => this.formDogDetailsJson(obj));

  formDogDetailsJson = (obj) => {
    const {
      name = "",
      age = "",
      description = "",
      gender = "",
      dog_size_weight = "",
      dog_size_height = "",
      breed = "",
      isInsured = "",
      isSterilized = "",
      profile_pic: profilePic = "",
      id = "",
    } = obj;
    const data = {
      breed,
      name,
      age,
      description,
      gender,
      dog_size_weight,
      dog_size_height,
      isInsured,
      isSterilized,
      profilePic,
    };
    return { data, id };
  }

  /**
   * Returns customer details payload of JSON type.
   *
   * @method
   */
  toJson = (obj) => {
    const {
      description, email, flat, house, profilePic = {}, firstName, lastName, street, phone,
    } = obj;
    const { id: profilePicId = "" } = profilePic || {};
    const data = {
      profile_pic_id: profilePicId,
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number_list: phone,
      street,
      house,
      flat,
      description,
    };
    return data;
  };

  /**
   * Returns dog details payload of JSON type.
   *
   * @method
   */
  toDogJson = (obj) => {
    const data = obj;
    const {
      breed: { value: breed = "" } = {}, age: { value: age = "" } = {}, dog_size_weight: { value: dogSizeWeight = "" } = {},
      dog_size_height: { value: dogSizeHeight = "" } = {},
      profilePic = {},
    } = obj;
    const { id: profilePicId = "" } = profilePic || {};
    data.profile_pic_id = profilePicId;
    data.breed = breed;
    data.age = age;
    data.dog_size_weight = dogSizeWeight;
    data.dog_size_height = dogSizeHeight;
    return data;
  };
}

export const fromOrderslistJson = (values) => {
  const { data: list = [], meta } = values;
  const listData = list.map((obj) => {
    const data = obj;
    data.room = [];
    data.service = [];
    data.serviceProvider = [];
    data.organization = [];
    data.dateTime = [];
    data.dog_size_weight = [];
    data.price = obj.total_price || 0;
    const { assignments = [] } = data;
    assignments.map((assignment) => {
      const {
        room_id: room = "", service_id: service = "", service_provider_id: serviceProvider = "",
        organization = "", assignment_start_datetime: time = "", dog_id: dog = {},
      } = assignment;
      const { dog_size_weight = "" } = dog || {};
      data.room.push(room || "");
      data.service.push(service || "");
      data.serviceProvider.push(serviceProvider || "");
      data.organization.push(organization || "");
      data.dateTime.push(moment.utc(time).local() || "");
      data.dog_size_weight.push(dog_size_weight || "");
      return assignment;
    });
    return data;
  });
  return { data: listData, meta };
};

export const fromListScreenJson = (values) => {
  const { data = [], meta } = values;
  const customers = data.map((obj) => {
    const { dogs: { dogs: allDogs = [] } } = obj;
    const dogs = allDogs.map((dog) => {
      const { name = "", dog_size_weight = "" } = dog;
      const size = dog_size_weight ? dog_size_weight[0].toUpperCase() : "";
      return { name, dog_size_weight, size };
    });
    return { ...obj, dogs };
  });
  return { data: customers, meta };
};

export default Customer;
