/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { FlexContainer, Container } from "./Styles";

/**
 * Container UI
 */
const PortalContainer = ({ children, type, ...props }) => (
  <FlexContainer
    justifyContent="start !important"
    className="mt-0 pt-2"
    {...props}
  >
    <Container type={type}>{children}</Container>
  </FlexContainer>
);
PortalContainer.propTypes = {
  /**
   * Pass children into the component
   */
  children: PropTypes.node,
  type: PropTypes.string,
};
PortalContainer.defaultProps = {
  children: null,
  type: null,
};
export default PortalContainer;
