import React, { useState, memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Constants from "../../../shared/Constants";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import { Division, BoxDivision } from "../BookingFlowStyles/BookingFlowStyles";
import {
  Div, MDImage, Span, MDLabel,
} from "../../../styles/Styles";
import {
  timelineLabels,
  mapDogSizeToDuration,
} from "../../../shared/utils";
import Loader from "../../../components/Loader/Loader";
import ServiceProvider from "./ServiceProvider";
import { setLoader } from "../../../store/actions/AppAction";

const { icons } = Constants;

// eslint-disable-next-line react/prop-types
const TimeAccordion = ({ currentSelectedDate, handlerNextStep}) => {
  const {
    // filteredService,
    selectedServiceContainer,
    organisationServiceProvidersService,
  } = useSelector((state) => state.BookingFlowServicesReducer);
  const getAvailableSlots = useSelector((state) => state.BookingFlowServicesReducer.getAvailableDates);
  const dispatch = useDispatch();
  const [isStartTime, setIsStartTime] = useState("");
  const [isEndTime, setIsEndTime] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const setLoaderState = (params) => dispatch(setLoader(params));
  const {
    dogSizes: firstSelectDogSize,
    selectedServices = {},
  } = selectedServiceContainer;
  // const { services } = filteredService && filteredService[0];
  const selectedDogSizeIs = mapDogSizeToDuration(
    firstSelectDogSize?.dogWeight, selectedServices?.service, true,
  ) || mapDogSizeToDuration(firstSelectDogSize?.dogWeight, selectedServices?.service, true);

  // const timeIntervals = timelineLabels(Number(selectedDogSizeIs.replace('min', '')),
  //   'm',
  //   isStartTime,
  //   isEndTime);
  useEffect(() => {
    setTimeIntervals([]);
    setIsStartTime(organisationServiceProvidersService.start_time);
    setIsEndTime(organisationServiceProvidersService.end_time);
    setLoaderState(false);
  }, [organisationServiceProvidersService]);
  useEffect(() => {
    setTimeIntervals(timelineLabels(Number(selectedDogSizeIs ? selectedDogSizeIs.replace("min", "") : 0),
      "m",
      isStartTime,
      isEndTime, currentSelectedDate));
  }, [isStartTime, isEndTime]);
  const [timeSlot, setTimeSlot] = useState(timeIntervals && timeIntervals[0]);
  useEffect(() => {
    if (timeIntervals.length > 0) {
      setTimeSlot(timeIntervals[0]);
    }
  }, [timeIntervals]);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleAccordion = (index, time) => () => {
    setActiveIndex(activeIndex === index ? -1 : index);
    setTimeSlot(time);
  };
  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const filterArray = (slots) => {
    const unique = slots.filter(onlyUnique);
    if (unique.indexOf(selectedServiceContainer.selectedProvider.serviceTime) === -1 && slots?.length === 1) {
      setActiveIndex(0);
      setTimeSlot(slots[0]);
    }
    else {
      setActiveIndex(unique.indexOf(selectedServiceContainer.selectedProvider.serviceTime));
      setTimeSlot(selectedServiceContainer.selectedProvider.serviceTime);
    }
    setAvailableSlots(unique);
    setLoaderState(false);
  };
  useEffect(() => {
    const arraySlot = [];
    if (timeIntervals.length > 0) {
      setLoaderState(true);
      timeIntervals.forEach((el) => {
        const startTimeslot = el?.split(" - ")[0];
        const slotStartTime = startTimeslot?.split(":")[0] * 60 + startTimeslot?.split(":")[1];
        const endTimeslot = el?.split(" - ")[1];
        const slotEndTIme = endTimeslot?.split(":")[0] * 60 + endTimeslot?.split(":")[1];
        organisationServiceProvidersService?.service_provider?.forEach((sp) => {
          sp?.available_slots?.every((slots) => {
            const startTimeAvailable = slots.start_time.split(":")[0] * 60 + slots.start_time.split(":")[1];
            const endTimeAvailable = slots.end_time.split(":")[0] * 60 + slots.end_time.split(":")[1];
            if (+startTimeAvailable <= +slotStartTime && +endTimeAvailable >= +slotEndTIme) {
              arraySlot.push(el);
              return false;
            }
            return true;
          })
        });
      });
      filterArray(arraySlot);
    }
  }, [timeIntervals, organisationServiceProvidersService]);

  return (
    <Div>
      {isStartTime && getAvailableSlots?.length > 0 && availableSlots?.length > 0 ? availableSlots?.map((time, index) => (
        <Div key={time} mb={3} pb={1}>
          <BoxDivision
            type="accordion"
            height={60}
            bg={Colors.White}
            boxShadow={Theme.shadows.light}
            borderRadius={Theme.radii.medium}
            pl={3}
          >
            <Span
              alignItems="center"
              alignSelf="center"
              fontFamily={Theme.fonts.semibold}
              fontSize={Theme.fontSizes.regular}
            >
              {time.replace(/:/g, ".")}
            </Span>
            <BoxDivision>
              <MDLabel mr={2}>
                <MDImage mr={1} src={icons.ClockIcon} alt="icon" />
                {selectedDogSizeIs}
              </MDLabel>
              <Span
                bg={Colors.Blue}
                width={40}
                textAlign="center"
                lineHeight="60px"
                borderRadius={Theme.radii.medium}
                opacity="0.5"
                onClick={handleAccordion(index, time)}
                useCursorPointer
              >
                <MDImage src={activeIndex === index ? icons.UpArrow : icons.ArrowDown} alt="icon" width="20px" />
              </Span>
            </BoxDivision>
          </BoxDivision>
          <Division mt={4} className={activeIndex === index ? "active" : ""} type="active">
            <BoxDivision type="place_Content">
              {organisationServiceProvidersService === null ? (
                <Division type="places" mb={3} pb={1} setBorder>
                  <Loader width="250px" height="75px" />
                </Division>
              ) : (
                <>
                  {activeIndex === index && organisationServiceProvidersService
                  && <ServiceProvider time={time} timeSlot={timeSlot} handlerNextStep={handlerNextStep } />}
                  {organisationServiceProvidersService?.service_provider?.length <= 0 && (
                    <Division type="places" mb={3} pb={1} setBorder>
                      <FormattedMessage id="services_provider_not_found" defaultMessage="No services provider found" />
                    </Division>
                  )}
                </>
              )}
            </BoxDivision>
          </Division>
        </Div>
      ))
        : (
          <MDLabel mb={3} mt={4} pt={4} pr={1} fontSize={18} lineHeight={1.5}>
            <FormattedMessage id="services_provider_not_found" defaultMessage="No service providers found" />
          </MDLabel>
        )}
    </Div>
  );
};
export default memo(TimeAccordion);
