import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { getServiceList, getOrganizations } from "../../services/SubscriptionService";
import Constants from "../../shared/Constants";

const { subscriptionPackage, subscriptionIconAndColor } = Constants;

export const SubscriptionBookingFlowAction = {
  previousStep: (state) => {
    state.step -= 1;
  },
  nextStep: (state) => {
    state.step += 1;
  },
  moveToStep: (state, step) => {
    state.step = step.payload;
  },
  setSelectedSubscription: (state, action) => {
    state.selectedSubscriptionId = action.payload;
    state.selectedSubscription = getSelectedPackage(action.payload, state.subscriptionPackages);
  },
  setSelectedServiceLocation: (state, action) => {
    state.selectedServiceLocation = action.payload;
  },
  setInfo: (state, payload) => {
    state.info = payload.payload;
  },
  setSelectedDate: (state, action) => {
    state.selectedDate = action.payload;
  },
  resetSubscriptionData: (state) => {
    state.info = {};
    state.selectedServiceLocation={};
    state.selectedDate="";
    state.selectedSubscriptionId="";
  }
};

export const fetchSubscriptionServices = createAsyncThunk(
  "subscriptionPackages/fetchSubscriptionServices",
  async () => {
    try {
      const response = await getServiceList();
      const updatedData = assignIconAndColorForSubscription(response.data);
      console.log(updatedData)
      return updatedData;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
);

const assignIconAndColorForSubscription = (subscriptionPackages) => {
  const completedPackageNames = [];
  subscriptionPackages.forEach((subscriptionPackage) => {
    const packageName = subscriptionPackage.name;
    const iconData = getIconAndColor(packageName, completedPackageNames.includes(packageName));
    completedPackageNames.push(packageName);
    subscriptionPackage.icon = iconData.icon;
    subscriptionPackage.iconColor = iconData.color;
  });
  return subscriptionPackages;
};

const getIconAndColor = (packageName, isSecondTime) => {
  if (packageName.includes(subscriptionPackage.bronze)) {
    if (isSecondTime) {
      return subscriptionIconAndColor.bronze[1];
    } else {
      return subscriptionIconAndColor.bronze[0];
    }
  }
  if (packageName.includes(subscriptionPackage.silver)) {
    return subscriptionIconAndColor.silver;
  }
  if (packageName.includes(subscriptionPackage.gold)) {
    return subscriptionIconAndColor.gold;
  }
  if (packageName.includes(subscriptionPackage.custom)) {
    return subscriptionIconAndColor.custom;
  }
};

export const fetchOrganizations = createAsyncThunk("organizations/fetchOrganizations", async (serviceId) => {
  const response = await getOrganizations(serviceId);
  const { data = []} = response;
  return data.filter(organization => organization.visible_for_customers);
});

export const updatedSubscriptionPackages = createSelector(
  (state) => state,
  (state) => concatPrice(state.SubscriptionBookingFlowReducer.subscriptionPackages)
);

function concatPrice(subscriptionPackages) {
  const updatedPackagesObj = {};
  let updatedPackages = [];

  subscriptionPackages &&
    subscriptionPackages.map((subscription) => {
      let data = updatedPackagesObj[subscription.name];
      if (data) {
        let priceList = data.priceList ? data.priceList : [];
        if (priceList && priceList.length > 0) {
          let price = { price: subscription.price.large, id: subscription.id };
          priceList.push(price);
        } else {
          priceList = [];
          let firstPrice = { price: data.price.large, id: data.id, days: data.description };
          let price = { price: subscription.price.large, id: subscription.id, days: subscription.description };
          priceList.push(firstPrice);
          priceList.push(price);
        }
        const detail = JSON.parse(subscription.details);
        data.description = detail.days;
        data.priceList = priceList;
        updatedPackagesObj[subscription.name] = data;
      } else {
        let value = { ...subscription };
        value.priceList = [];
        updatedPackagesObj[subscription.name] = value;
      }
    });
  updatedPackages = Object.values(updatedPackagesObj);
  return updatedPackages;
}

function getSelectedPackage(subscriptionId, subscriptionPackages) {
  if (subscriptionPackages && subscriptionId) {
    return subscriptionPackages.find((subscription) => subscription.id === subscriptionId);
  }
  return null;
}
