import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Geocode from "react-geocode";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import Constants from "../../../shared/Constants";
import { Div, MDImage, MDLabel } from "../../../styles/Styles";
import Config from "../../../config";
import { FormattedMessage } from "react-intl";

const { icons } = Constants;

Geocode.setApiKey(Config.key);
Geocode.setLanguage("en");
Geocode.setRegion("sw");

const mapStyles = {
  width: "100%",
  height: "400px",
};

const Maps = ({ selectedLocation, zoom, organizationAddress = [], loadedDefaultMarker = [] }) => {
  const [selected, setSelected] = useState({});
  const [marker, setMarker] = useState({ lat: 0, lng: 0 });
  const [defaultMarker, setDefaultMarker] = useState(loadedDefaultMarker);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: Config.key,
  });

  const getCoordinatesFromApi = async (address = "") => {
    const response = await Geocode.fromAddress(address);
    const { results = [] } = response;
    const {
      geometry: { location = {} } = {},
    } = results[0];
    const { lat, lng } = location;
    return { lat, lng };
  };

  useEffect(() => {
    const loadGeocode = async () => {
      if (organizationAddress.length > 0) {
        const addresses = await Promise.all(organizationAddress.map((address = "") => getCoordinatesFromApi(address)));
        setDefaultMarker(addresses);
      }
    };
    loadGeocode();
  }, [organizationAddress]);

  const onSelect = (item) => setSelected(item);

  const onHandleClose = () => () => setSelected({});

  // Get latitude & longitude from address.
  useEffect(async () => {
    if (selectedLocation) {
      const { lat, lng } = await getCoordinatesFromApi(selectedLocation);
      setMarker({ ...marker, lat, lng });
    } else {
      const { lat, lng } = await getCoordinatesFromApi(Constants.stockholm);
      setMarker({ ...marker, lat, lng });
    }
  }, [selectedLocation]);

  const renderGoogleMaps = () => (
    <GoogleMap mapContainerStyle={mapStyles} zoom={zoom} center={marker}>
      {defaultMarker &&
        defaultMarker.map((obj) => (
          <Marker
            key={`${obj.lat}-${obj.lng}`}
            position={{ lat: obj.lat, lng: obj.lng }}
            onClick={() => {
              onSelect(obj);
            }}
            options={{ icon: `${icons.Marker}` }}
          />
        ))}
      {selected.location && (
        <InfoWindow position={selected.location} clickable onCloseClick={onHandleClose()}>
          <Div>
            <MDImage src={selected.face} />
            <MDLabel>{selected.title}</MDLabel>
            <span display="block">{selected.name}</span>
          </Div>
        </InfoWindow>
      )}
    </GoogleMap>
  );

  return loadError || !isLoaded ? (
    <Div>
      <FormattedMessage id="map_load_error" defaultMessage="Map cannot be loaded right now, sorry." />
    </Div>
  ) : (
    renderGoogleMaps()
  );
};
Maps.propTypes = {
  selectedLocation: PropTypes.string,
  zoom: PropTypes.number,
  organizationAddress: PropTypes.node,
  loadedDefaultMarker: PropTypes.node,
};
Maps.defaultProps = {
  selectedLocation: "",
  zoom: "",
  organizationAddress: [],
};
export default Maps;
