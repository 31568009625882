import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment-timezone";
import Container from "../../components/Containers/PortalContainer";
import AssignmentForm from "./Components/AssignmentForm";
import Constants from "../../shared/Constants";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import { FormWrapper, Div } from "../../styles/Styles";
import { OrderPageLoader } from "../../components/Loader/FormLoader";
import { getDetails, deleteAssignment, closeAssignment } from "../../services/AssignmentService";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";
import { checkRightPermission } from "../../shared/utils";

const { language } = Constants;

const EditAssignment = ({ userDetails = {}, match: { params = {} }, history }) => {
  const { id = "" } = params;
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState({});
  const userData = userDetails.user_access
    ? userDetails
    : localStorage.userData
      ? JSON.parse(localStorage.userData)
      : {};
  const { permissions = [] } = userData;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [changesSaved, setChangesSaved] = useState(false);
  const dispatch = useDispatch();
  const authService = new AuthService(Config.apiBase);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));

  const getUserDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackToAssignments = () => {
    getUserDetails();
    setChangesSaved(false);
    setAlertMessage("");
    setAlertHeader("");
    if (alertMessage === language.assignment_deleted_alert_message) {
      history.push(Constants.routes.assignmentsList.url);
    }
  };

  const fetchDetails = () => {
    setLoader(true);
    try {
      getDetails(id).then((response) => {
        const { data: responseData = {}, status: asgStatus = "" } = response;
        setStatus(asgStatus);
        setData({
          ...responseData,
          assignment_start_datetime: moment.utc(responseData.assignment_start_datetime).local(),
        });
        setLoader(false);
      });
    } catch (e) {
      setLoader(false);
    }
  };

  const handleAlertModal = (isSaved) => {
    if (isSaved) {
      getUserDetails();
      setAlertMessage(language.alert_assignment_updated);
      setChangesSaved(true);
      fetchDetails();
    } else {
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    }
  };

  const handleDeleteAssignment = async () => {
    setLoader(true);
    try {
      const response = await deleteAssignment(id);
      if (response) {
        getUserDetails();
        setLoader(false);
        setShowDeleteModal(false);
        setChangesSaved(true);
        setAlertMessage(language.assignment_deleted_alert_message);
      }
    } catch (e) {
      setLoader(false);
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    }
  };

  const handleCloseAssignment = async () => {
    setLoader(true);
    try {
      const response = await closeAssignment(id);
      if (response) {
        getUserDetails();
        fetchDetails();
      }
    } catch (e) {
      setLoader(false);
      setChangesSaved(true);
      setAlertHeader(language.header_error);
      setAlertMessage(language.alert_try_later);
    }
  };

  useEffect(() => {
    fetchDetails();
    window.scrollTo(0, 0);
  }, []);

  const handleCancelModal = () => {
    setShowDeleteModal(false);
    setChangesSaved(false);
    setAlertHeader("");
    setAlertMessage("");
  };

  /**
   * Opens a delete confirmation popup
   */
  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleRedirectToAssignments = () => {
    history.goBack();
  };

  const redirectToDetailPage = (passId = "", type = "") => () => {
    switch (type) {
    case "place":
      return history.push(`/admin/organization/${passId}/edit-organization`);
    case "room":
      return history.push(`/admin/rooms/${passId}/edit-room`);
    case "client":
      return history.push(`/admin/customers/${passId}/edit-customer`);
    case "dog":
      return history.push(`/admin/customers/${passId}/edit-customer/dogs`);
    case "service":
      return history.push(`/admin/services/${passId}/edit-service`);
    case "serviceProvider":
      return history.push(`/admin/users/${passId}/edit-user`);
    default:
      return "";
    }
  };

  const org = data ? data.data : {}
  const { place : { value : orgId = "" } = {} } = org || {};
  const filterOrgData = permissions.filter((obj)=>obj.organization_id === orgId);
  const hasWriteAccess = checkRightPermission(filterOrgData,"is_assignments_editable",Constants.WRITE);
  const { serviceProvider: { label: serviceProvider = "" } = {} } = data;
  return (
    <Container>
      <Div>
        {!loader && (
          <CommonAddPage
            handleBack={handleRedirectToAssignments}
            header={`${Constants.language.assignment_for_header} ${serviceProvider}`}
            breadCrumbLabel="breadcrumb_back"
            status={status}
          />
        )}
        <FormWrapper>
          {loader && <OrderPageLoader />}
          {!loader && (
            <AssignmentForm
              edit
              status={status}
              assignmentId={id}
              assignmentData={data}
              handleBackToAssignments={handleAlertModal}
              handleDelete={handleDeleteModal}
              handleClose={handleCloseAssignment}
              redirectToDetailPage={redirectToDetailPage}
              hasNoWriteAccess={!hasWriteAccess}
            />
          )}
        </FormWrapper>
      </Div>
      {showDeleteModal && (
        <Modal>
          <Card
            header={<FormattedMessage id="assignment_delete" defaultMessage="Delete assignment" />}
            message={<FormattedMessage id="assignment_delete_confirmation_message" defaultMessage="Are you sure you want to delete ?" />}
            buttonLabel={<FormattedMessage id="common_delete" defaultMessage="Delete" />}
            confirmationCard
            buttonIcon={Constants.icons.DeleteIcon}
            onClick={handleDeleteAssignment}
            onCancel={handleCancelModal}
            onLoader={loader}
          />
        </Modal>
      )}
      {changesSaved && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleBackToAssignments}
          />
        </Modal>
      )}
    </Container>
  );
};
EditAssignment.propTypes = {
  history: PropTypes.node,
  match: PropTypes.node,
  userDetails: PropTypes.node,
};
EditAssignment.defaultProps = {
  history: undefined,
  match: null,
  userDetails: {},
};
export default EditAssignment;
