import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  AvtarIcon, MDLabel, Span, P, IsDesktop, IsMobile,
} from "../../styles/Styles";
import { HeaderContainer, ProfileWrapper } from "./Styles";
import Avtar from "../../assets/Icons/Avtar.svg";
import { HamburgerIcon, LogOutIcon } from "../../styles/Icons";
import SideMenu from "../Sidemenu/Sidemenu";
import Theme from "../../styles/Theme";
import SignUpService from "../../services/SignUpService";
import AuthService from "../../services/AuthService";
import Config from "../../config";
import Constants from "../../shared/Constants";
import Loader from "../Loader/Loader";
import { loggedInUser } from "../../store/actions/AppAction";
import { clearBookingFlowData } from "../../store/actions/BookingFlowServicesAction";
import { isCustomer } from "../../shared/utils";

class Header extends Component {
  authService = new AuthService(Config.apiBase);

  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      redirect: false,
      userName: "admin",
      userAvtar: "",
    };
  }

  componentDidMount=() => {
    const { userDetails } = this.props;
    let { userName } = this.state;
    let userAvtar;
    const { full_name: name = "", profile_pic: profilePic = {} } = userDetails || {};
    const { url = "" } = profilePic || {};
    userAvtar = url || "";
    userName = name || "";
    if (!userName) {
      const data = localStorage.userData ? JSON.parse(localStorage.userData) : {};
      userName = data.full_name || "";
      userAvtar = data.profile_pic ? data.profile_pic.url : "";
    }
    this.setState({ userName, userAvtar });
  };

  /**
   * Handle show or hide options block
   */
  handleDisplayOptions = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  /**
   * @async
   * @function handleLogout Logout the account and redirects to login page
   */
  handleLogout = async () => {
    this.setState({ loading: true });
    const { emptyUserDetails, resetBookingFlowData } = this.props;
    try {
      const response = await this.signUpService.logoutService();
      if (isCustomer()) {
        resetBookingFlowData();
      }
      if (response) {
        localStorage.clear();
        emptyUserDetails();
        this.setState({ redirect: true, loading: false });
      }
    } catch (e) {
      this.setState({ redirect: true, loading: false });
    }
    localStorage.clear();
  };

  /**
   * @async
   * @function fetchDetails
   * Fetches details of the loggedIn user.
   */
   fetchDetails = async () => {
     this.setState({ loading: true });
     try {
       const response = await this.authService.usersDetails();
       const { full_name: fullName = "", profile_pic: profilePic = {} } = response;
       const { url = "" } = profilePic || {};
       this.setState({ userName: fullName, userAvtar: url, loading: false });
     } catch (e) {
       this.setState({ loading: false });
     }
   };

   render() {
     const {
       show, redirect, userName, userAvtar, loading,
     } = this.state;
     if (redirect) {
       return (<Redirect to={{ pathname: Constants.routes.logIn.url }} />);
     }
     return (
       <>
         <HeaderContainer className="row m-0 w-100">
           <div>
             <IsMobile>
               <MDLabel
                 ref={(targetMenu) => {
                   this.targetMenu = targetMenu;
                 }}
                 className="col-auto pl-0"
                 onClick={this.handleDisplayOptions}
                 role="button"
                 cursor="pointer"
               >
                 <HamburgerIcon />
                 <Span pl={2}><FormattedMessage id="common_menu" /></Span>
               </MDLabel>
             </IsMobile>
           </div>
           <ProfileWrapper>
             <IsMobile alignSelf="center">
               <MDLabel fontSize="12px" display="initial" onClick={this.handleLogout}>
                 <LogOutIcon />
                 <Span px={1} display="initial" cursor="pointer">
                   <FormattedMessage id="common_logout" />
                 </Span>
               </MDLabel>
             </IsMobile>
             {loading && <Loader height="50px" width="50px" />}
             {!loading && <AvtarIcon src={userAvtar || Avtar} alt="icon" />}
             <IsDesktop pl={1} alignSelf="center" textAlign="left">
               <P m={0}>
                 {loading && <Loader />}
                 {!loading && (
                   <MDLabel>
                     <Span fontFamily={Theme.fonts.regular}>
                       <FormattedMessage id="header_hello" />
                     ,
                     </Span>
                  &nbsp;
                     <Span>{userName || "Admin"}</Span>
                   </MDLabel>
                 )}
               </P>
               <P m={0}>
                 <MDLabel fontSize="12px" display="flex" onClick={this.handleLogout}>
                   <LogOutIcon />
                   <Span pl={1} display="table-caption" alignSelf="center" cursor="pointer">
                     <FormattedMessage id="common_logout" />
                   </Span>
                 </MDLabel>
               </P>
             </IsDesktop>
           </ProfileWrapper>
         </HeaderContainer>
         <IsMobile>
           <SideMenu open={show} handleCloseMenu={this.handleDisplayOptions} />
         </IsMobile>
       </>
     );
   }
}
Header.propTypes = {
  userDetails: PropTypes.node,
  emptyUserDetails: PropTypes.func,
  resetBookingFlowData: PropTypes.func,
};

Header.defaultProps = {
  userDetails: {},
  emptyUserDetails: () => {},
  resetBookingFlowData: () => {},
};

const mapStateToProps = (state) => ({
  userToken: state.AppReducer.userToken,
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    emptyUserDetails: (payload) => dispatch(loggedInUser(payload)),
    resetBookingFlowData: () => dispatch(clearBookingFlowData()),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Header);
