import React from "react";
import moment, { now } from "moment";
import {
  Div, MDLabel,
} from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import CalendarCard from "../../../components/Card/CalendarCard";
import Colors from "../../../styles/Colors";
import { formatDate } from "../../../shared/utils";

const DayViewEvent = ({ history, eventInfo }) => {
  
  const renderEventContent = () => {
    const {
      adminRoutes,
      input: { format },
    } = Constants;
    const { view } = eventInfo;
    const calendarData = eventInfo.event.extendedProps.data;
    const orderCount = eventInfo.event.extendedProps.count;

    const handleCardClick = (assignmentId) => {
      history.push(
        `${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${assignmentId}/${adminRoutes.editAssignment}`
      );
    };
    const { showAvailbityButton = false, serviceProviderIds = [], showFullDayAvailbityButton = false } = eventInfo.event.extendedProps || {};
    const dateAvailable = formatDate(eventInfo.event.start, format.date);
    const currentDate = moment(now()).format(format.date);
    if (showAvailbityButton || orderCount) {
      return (
        <Div>
          {showAvailbityButton && (
            <Div
              width={1}
              ml={2}
              display="flex"
              alignItems="center"
              bg="transparent">
              <Div
                width="auto !important"
                className={`available-btn m-0 ${dateAvailable >= currentDate ? "" : "available-btn-dark"}`}
                color={dateAvailable >= currentDate ? Colors.Black : ""}>
                <Div display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                  <MDLabel fontSize="11px" lineHeight="13px">
                    {`${serviceProviderIds.length} SP (${Constants.language.common_services})`}
                  </MDLabel>
                </Div>
              </Div>
            </Div>
          )}
          {orderCount && (
            <Div
              display="flex"
              color={Colors.Black}
              bg={dateAvailable >= currentDate ? Colors.Blue : Colors.LightGrey}
              alignItems="center"
              borderRadius="10px"
              flexDirection="row"
              ml={2}
              width={"fit-content !important"}>
              <Div
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                p="6px 10px"
                color={dateAvailable >= currentDate ? Colors.Black : ""}>
                <MDLabel fontSize="11px" lineHeight="13px">
                  {`${orderCount} ${Constants.language.common_orders} (${Constants.language.label_full_day})`}
                </MDLabel>
              </Div>
            </Div>
          )}
        </Div>
      );
    }

    if (showFullDayAvailbityButton) {
      return (
        <Div
          width={1}
          ml={2}
          display="flex"
          alignItems="center"
          bg="transparent">
          <Div
            width="auto !important"
            className={`available-btn m-0 ${dateAvailable >= currentDate ? "available-full-day-btn" : "available-btn-dark"}`}
            color={Colors.White}>
            <MDLabel fontSize="11px" lineHeight="13px">
              {`${serviceProviderIds.length} SP (${Constants.language.label_full_day})`}
            </MDLabel>
          </Div>
        </Div>
      )
    }
    return (
      <>
        {calendarData && (
          <Div className="d-inline-flex" width={1}>
            <CalendarCard
              data={calendarData}
              viewType={view.type}
              onCardClick={() => handleCardClick(calendarData.assignmentId)}
            />
          </Div>
        )}
      </>
    );
  };
  return renderEventContent();
};

export default DayViewEvent;
