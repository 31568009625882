import React from "react";
import PropTypes from "prop-types";
import { ButtonBase } from "./Styles";
import { MDImage, MDLabel } from "../../styles/Styles";

/**
 * Primary UI component for user interaction
 */
const Button = ({
  primary = false,
  secondary = false,
  startIcon,
  endIcon,
  hasIconOnly = false,
  backgroundColor = null,
  size = "medium",
  height = "auto",
  label,
  IconHeight,
  IconWidth,
  type,
  onClick,
  width,
  disabled,
  padding,
  className,
  disable,
  ...props
}) => (
  <ButtonBase
    size={size}
    primary={primary}
    secondary={secondary}
    height={height}
    backgroundColor={backgroundColor}
    onClick={onClick}
    width={width}
    disabled={disabled}
    padding={padding}
    props={props}
    className={className}
    disable={disable}
    type={type}
  >
    <MDLabel justifyContent="center" cursor="pointer">
      {startIcon && (
        <>
          <MDImage src={startIcon} alt="icon" height={IconHeight} width={IconWidth} alignSelf="center" pr={1} />
          &nbsp;
        </>
      )}
      {!hasIconOnly && (
        <MDLabel cursor="pointer" justifyContent="center" width="max-content">
          {label}
        </MDLabel>
      )}
      {endIcon && (
        <>
          &nbsp;
          <MDImage src={endIcon} alt="icon" height={IconHeight} width={IconWidth} />
        </>
      )}
    </MDLabel>
  </ButtonBase>
);

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * Is this the principal call to action on the page?
   */
  secondary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Height of the button?
   */
  height: PropTypes.string,
  /**
   * Width of the button?
   */
  width: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Renders a icon before the text
   */
  startIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * Renders a icon after the text
   */
  endIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * Renders icon only on button without any label
   */
  hasIconOnly: PropTypes.bool,
  /**
   * Is button in disable state..
   */
  disabled: PropTypes.bool,
  /**
   * What should be the icon height..
   */
  IconHeight: PropTypes.string,
  /**
   * What should be the icon width..
   */
  IconWidth: PropTypes.string,
  /**
   * What is the type of the input.
   */
  type: PropTypes.string,
  /**
   * Do you wanna add or remove padding
   */
  padding: PropTypes.string,
  /**
   * Customize button
   */
  className: PropTypes.string,
  /**
   * show ui as disabled button
   */
  disable: PropTypes.bool,
};

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  secondary: false,
  size: "medium",
  height: "auto",
  onClick: undefined,
  label: null,
  startIcon: null,
  endIcon: null,
  hasIconOnly: false,
  disabled: false,
  width: "auto",
  IconHeight: "auto",
  IconWidth: "auto",
  type: null,
  padding: null,
  className: null,
  disable: false,
};
export default Button;
