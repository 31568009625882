import React, {useCallback} from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Div, MDLabel, CardHeader, MDImage,
} from "../../styles/Styles";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import CalendarIcon from "../../assets/Icons/CalendarIcon.svg";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import Theme from "../../styles/Theme";
import { formatDate } from "../../shared/utils";

export default function DeleteSuccessPopUp({
  renderModal, data,
}) {
  const hadleModal = useCallback(() => {
    renderModal();
  });
  const {
    widths: {
      w25,
    },
    heights: {
      h20,
    },
    radii: {
      large,
    },
  } = Theme;
  return (
    <Modal className="availability-form">
      <Div width="500px">
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            {data?.header || <FormattedMessage id="label_del" defaultMessage="Delete Availability" />}
          </MDLabel>
        </CardHeader>
        <Div display="flex" flexDirection="column" className="col-10 offset-1" paddingTop="1rem" justifyContent="center" alignItems="center">
          {data?.message || <FormattedMessage id="label_del_message" value="Slot deleted successfully" />}
        </Div>
        {data?.data?.length > 0
          && data?.data?.map((value) => (
            value.assignments?.length > 0
            && value.assignments.map((assignemnt, key) => (
              <>
                <Div display="flex" id={key} my={2} className="col-10 offset-1">
                  <Div display="flex" justifyContent="center" my={2} className="col-12">
                    <MDImage
                      src={CalendarIcon}
                      useCursorPointer
                      alt="icon"
                      width={w25}
                      height={h20}
                      borderRadius={large}
                    />
                    {formatDate(assignemnt?.assignment_start_datetime)}
                  </Div>
                </Div>
                <Div display="flex" justifyContent="center" id={key} my={2} className="row col-10 offset-1">
                  <Div display="flex" className="col-9" mx={2} alignItems="center">
                    <MDLabel fontSize="18px" color="darkgray" mr={15}>From: </MDLabel>
                    <MDImage
                      src={ClockIcon}
                      useCursorPointer
                      alt="icon"
                      width={w25}
                      height={h20}
                      borderRadius={large}
                    />
                    {moment(assignemnt?.assignment_start_datetime).format("HH:mm")}
                    <MDLabel fontSize="18px" color="darkgray" ml={15} mr={15}>To: </MDLabel>
                    <MDImage
                      src={ClockIcon}
                      useCursorPointer
                      alt="icon"
                      width={w25}
                      height={h20}
                      borderRadius={large}
                    />
                    {moment(assignemnt?.assignment_end_datetime).format("HH:mm")}
                  </Div>
                </Div>
              </>
            ))
          ))}
        <Div display="flex" className="col-8 offset-2" justifyContent="center" mt={30} mb={20}>
          <Div display="flex" className="col-6">
            <Button label="Ok" width="100%" onClick={hadleModal} />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
DeleteSuccessPopUp.propTypes = {
  /**
   * Pass children innto the modal
   */
  renderModal: PropTypes.func,
  data: PropTypes.string,
};
DeleteSuccessPopUp.defaultProps = {
  renderModal: false,
  data: undefined,
};
