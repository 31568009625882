import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CardWrapper } from "../Styles";
import {
  CardHeader, MDLabel, Div, P, MDImage,
} from "../../../styles/Styles";
import Button from "../../../components/Button/Button";
import Service from "../../../services/ServicesPageService";
import Config from "../../../config";
import TextBox from "../../../components/Input/TextBox";
import Constants from "../../../shared/Constants";
import Loader from "../../../components/Loader/Loader";
import PlusIcon from "../../../assets/Icons/PlusIccon.svg";

const { icons, language } = Constants;
class EditCategory extends Component {
  service = new Service(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoryData: {},
      finaldata: [],
      loading: false,
      isDisabled: false,
    };
  }

  componentDidMount=() => {
    this.fetchCategories();
  }

  handleBlur=(ctg) => (event) => {
    const { value } = event.target;
    const { categoryData } = this.state;
    let { finaldata, categories } = this.state;
    if (ctg.id) {
      const intialData = categoryData.data.find((ftr) => ftr.id === ctg.id);
      if (intialData && ctg.name === intialData.name) {
        finaldata = finaldata.filter((x) => x.id !== ctg.id);
      } else {
        const dataExist = finaldata.find((ftr) => ftr.id === ctg.id);
        if (dataExist) {
          finaldata.map((data) => {
            if (data.id === ctg.id) {
              return { ...data, type: "EDIT", name: value };
            }
            return data;
          });
        } else {
          finaldata.push({
            type: "EDIT",
            id: ctg.id,
            name: value,
          });
        }
      }
    } else {
      const dataExist = finaldata.find((ftr) => !ftr.id && ftr.key === ctg.key);
      if (dataExist) {
        finaldata = finaldata.map((data) => {
          if (data && data.key === ctg.key) {
            return { ...data, type: "ADD", name: value };
          }
          return data;
        });
      } else {
        finaldata.push({
          type: "ADD",
          name: value,
          key: ctg.key,
        });
      }
    }
    if (finaldata && finaldata.length === 0) {
      categories = categories.map((o) => ({ ...o, isTouched: false, showError: "" }));
    }
    this.setState({ finaldata, isDisabled: true, categories });
  }

  handleChange=(ctg, num) => (event) => {
    const { value } = event.target;
    const { categories } = this.state;
    const changeValue = categories.map((o, index) => {
      const obj = o;
      if (index === num) {
        obj.name = value;
        obj.showError = "";
        obj.isTouched = false;
      }
      return obj;
    });
    this.handleBlur(ctg, num);
    this.setState({ categories: changeValue, isDisabled: false });
  }

  handleDeleteCategory=(ctg, num) => () => {
    const { categories } = this.state;
    let { finaldata } = this.state;
    if (ctg.id) {
      finaldata.push({
        type: "REMOVE",
        name: ctg.name,
        id: ctg.id,
      });
    } else {
      finaldata = finaldata.filter((obj) => obj.key !== ctg.key);
    }
    let newCategories = categories.filter((c, index) => num !== index);

    if (finaldata && finaldata.length === 0) {
      newCategories = newCategories.map((o) => ({ ...o, isTouched: false, showError: "" }));
    }
    this.setState({ categories: newCategories, finaldata, isDisabled: true });
  }

  handleAddCategory=() => {
    const { categories } = this.state;
    const generateKey = crypto.getRandomValues(new Int8Array(3))[0];
    categories.push({
      name: "", service_count: 0, label: language.category_new_category, key: generateKey,
    });
    this.setState({ categories });
  }

  handleSave=async () => {
    this.setState({ loading: true });
    const { onSubmit } = this.props;
    let { categories } = this.state;
    const { finaldata } = this.state;
    const payload = finaldata.map((obj) => {
      const { type, name, id = "" } = obj;
      return { type, name, id };
    }).filter((o) => o && o.name !== "");
    const data = { category_list: payload };
    try {
      const response = await this.service.saveCategory(data);
      if (response) {
        categories = categories.map((o) => ({ ...o, isTouched: false, showError: true }));
        onSubmit();
        this.setState({ loading: false, categories });
      }
    } catch (e) {
      const { response: { data: responseData = {} } = {} } = e;
      const { error_data: errorData = "" } = responseData || {};
      if (errorData) {
        const { ADD = [], EDIT = [], REMOVE = [] } = errorData;
        if (ADD.length) {
          categories = categories.map((obj) => (ADD.includes(obj.name)
            ? {
              ...obj,
              showError:
              language.category_already_exists,
              isTouched: true,
            } : { ...obj }));
        }
        if (EDIT.length) {
          const editErrors = EDIT.map((o) => (o.name ? o.name : "")).filter((o) => o !== "");
          categories = categories.map((obj) => (editErrors.includes(obj.name)
            ? {
              ...obj,
              showError: language.category_already_exists,
              isTouched: true,
            } : { ...obj }));
        }
        if (REMOVE.length) {
          categories = categories.map((obj) => (REMOVE.includes(obj.name)
            ? {
              ...obj,
              showError: language.category_already_exists,
              isTouched: true,
            } : { ...obj }));
        }
        this.setState({ loading: false, categories });
      }
      this.setState({ loading: false });
    }
  }

  fetchCategories=async () => {
    this.setState({ loading: true });
    try {
      const response = await this.service.getCategoryList();
      const { data = [] } = response;
      this.setState({
        categories: data, loading: false, finaldata: [], categoryData: response,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      categories, loading, finaldata, isDisabled,
    } = this.state;
    const { onCancel } = this.props;
    return (
      <CardWrapper>
        <CardHeader>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id="service_edit_list_of_categories" />
          </MDLabel>
        </CardHeader>
        <Div p={3}>
          {loading
          && (
            <Div p={2} display="flex" justifyContent="center">
              <Loader width="50%" height="45px" />
            </Div>
          )}
          {!loading && (
            <Div p={2}>
              <P m={0}>
                <Div>
                  {categories.map((category, index) => {
                    const {
                      name = "", label = "", service_count: serviceCount = "", showError = "", isTouched = false,
                    } = category;
                    return (
                      <Div display="inline-flex" className="col-lg-6 col-md-12 ">
                        <Div className="d-flex w-100">
                          <Div
                            width={20}
                            height={20}
                            mr={3}
                            pt={isTouched ? 90 : 70}
                          >
                            <MDImage
                              src={Constants.icons.MinusIcon}
                              alt="icon"
                              useCursorPointer
                              onClick={this.handleDeleteCategory(category, index)}
                            />
                          </Div>
                          <TextBox
                            value={name}
                            label={name || label}
                            mandatoryLabel={`${serviceCount} ${language.category_num_services}`}
                            onChange={this.handleChange(category, index)}
                            width="100%"
                            containerProps="col p-0"
                            onBlur={this.handleBlur(category, index)}
                            placeholder={language.placeholder_name}
                            showError={showError}
                            isTouched={isTouched}
                          />
                        </Div>
                      </Div>
                    );
                  })}
                </Div>
                <P>
                  <Button
                    className="ml-3 my-3"
                    onClick={this.handleAddCategory}
                    label={<FormattedMessage id="service_add_new_category" />}
                    startIcon={PlusIcon}
                    primary
                  />
                </P>
              </P>
              <P mb={0} mt={4} display="flex" justifyContent="center">
                <Button label={<FormattedMessage id="common_cancel" />} secondary className="mr-3" onClick={onCancel} />
                <Button
                  className="ml-3"
                  onClick={this.handleSave}
                  label={<FormattedMessage id="common_save" />}
                  startIcon={icons.AssignmentsMenuIcon}
                  primary
                  disabled={!(finaldata.length && finaldata[0] && isDisabled)}
                />
              </P>
            </Div>
          )}
        </Div>
      </CardWrapper>
    );
  }
}
EditCategory.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
EditCategory.defaultProps = {
  onCancel: undefined,
  onSubmit: undefined,
};
export default EditCategory;
