import React from "react";
import Loader from "./Loader";
import {
  ListCard, ListItem, ListCardHeader, IsDesktop, IsMobile,
} from "../ListScreen/Styles";
import { Div } from "../../styles/Styles";

const ListScreenLoader = () => (
  <>
    <ListCard pb={2}>
      <IsDesktop>
        <ListCardHeader p={2}>
          {[...Array(5)].map(() => <ListItem className="col"><Loader /></ListItem>)}
        </ListCardHeader>

        {[...Array(5)].map(() => (
          <ListItem m={2}>
            {[...Array(5)].map(() => <ListItem className="col"><Loader /></ListItem>)}
          </ListItem>
        ))}
      </IsDesktop>
      <IsMobile>
        {[...Array(5)].map(() => (
          <>
            <ListCardHeader display="flex" justifyContent="space-between" p={3}>
              <Loader width="125px" />
              <Loader width="95px" />
            </ListCardHeader>
            {[...Array(5)].map(() => (
              <Div display="flex" justifyContent="space-between" className="p-3">
                <Loader width="120px" />
                <Loader width="140px" />
              </Div>
            ))}
          </>
        ))}
        <ListCardHeader />
        <Div />
      </IsMobile>
    </ListCard>
  </>
);
export default ListScreenLoader;
