import React from "react";
import Loader from "./Loader";
import { Div } from "../../styles/Styles";

const LoginPageLoader = () => (
  <Div display="inline-block" justifyContent="center">
    <Loader width="180px" height="70px" my={3} />
    <Loader width="300px" height="30px" my={2} />
    <Loader width="120px" height="20px" my={2} />
    <Loader width="300px" height="50px" my={1} />
    <Loader width="120px" height="20px" my={2} />
    <Loader width="300px" height="50px" my={1} />
    <Loader width="150px" height="40px" my={3} />
  </Div>
);
export default LoginPageLoader;
