import styled from "styled-components";
import Theme from "../../styles/Theme";
import { Card, Div, MDLabel } from "../../styles/Styles";

const {
  colors, fontSizes,
} = Theme;

export const CardWrapper = styled(Card)`
  border: ${({ isSelected }) => (isSelected ? `3px solid ${colors.primary}` : "none")};
  @media (max-width: 375px) {
    width: 100% !important;
  }
`;
CardWrapper.defaultProps = {
  borderRadius: "10px",
  boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.07)",
  width: "160px",
  display: "flex",
  justifyContent: "center",
  height: "50px",
};
export const Content = styled(Div)``;

export const Label = styled(MDLabel)`
  font-size: ${({ error }) => (error ? `${fontSizes.small}` : `${fontSizes.regular}`)};
  line-height: 19px;
  display: 'initial';
  color: ${({ error }) => (error ? `${colors.error}` : `${colors.black}`)};
  float: ${({ error }) => (error ? "right" : "start")};
`;
