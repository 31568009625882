import styled from "styled-components";
import { compose, typography, flexbox } from "styled-system";
import Button from "../../components/Button/Button";
import ProfileCard from "../../components/Card/ProfileCard";
import { Box, Div, MDInputValue } from "../../styles/Styles";
import { CardWrapper } from "../Customers/Styles";

export const MobileDiv = styled(Div)`
  width: ${({ type }) => (type === "payment" ? "75%" : type === "formwrapper" || type === "subwrapper" ? "48%" : type === "widewrapper" ? "70%" : "")};
  flex-wrap: ${({ type }) => (type === "subwrapper" ? "no-wrap" : "")};
  @media (max-width: 570px) {
    width: ${({ type }) => (type === "payment" ? "100%" : type === "formwrapper" || type === "subwrapper" || type === "widewrapper" || type === "subnewwrapper" ? "100%" : "")};
    padding: ${({ type }) => (type === "payment" ? "0 10px" : type === "formwrapper" || type === "widewrapper" || type === "subnewwrapper" ? "0 15px" : type === "subwrapper" ? 0 : "")};
  }
  `;

export const ButtonBox = styled(Box)`
  ${compose(flexbox)};
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? "row" : "")};
  flex-wrap: ${({ type }) => (type === "main_new_wrapper" ? "nowrap" : "wrap")};
  justify-content: ${({ type }) => (type === "mainwrapper" ? "space-between" : type === "main_new_wrapper" ? "flex-start" : "")};
  @media (max-width: 992px) {
    flex-direction: ${({ flexDirection }) => (flexDirection || "")};
  }
  @media (max-width: 570px) {
    justify-content: ${({ type }) => (type === "main_new_wrapper" ? "space-between" : "")};
    }
  `;

export const MDInputBox = styled(MDInputValue)`
  ${compose(typography)};

  @media (max-width: 570px) {
    width: ${({ type }) => (type === "labelmain" ? "85px" : "")};
    flex-wrap: ${({ type }) => (type === "labelmain" ? "wrap" : "")};
    text-align: ${({ type }) => (type === "labelmain" ? "left" : "")};
  }
`;

export const CardWrapperBox = styled(CardWrapper)`
  width: ${({ type }) => (type === "cardwrapper" ? "120px !important" : "")};
`;

export const NameCardBox = styled(ProfileCard)`
  width: ${({ type }) => (type === "nameCard" ? "50% !important" : "")};
  @media (max-width: 570px) {
    width: ${({ type }) => (type === "nameCard" ? "120px !important" : "")};
  }
`;

export const DivWrapper = styled(Div)`
  margin-right: ${({ type }) => (type === "wrapperdiv" || type === "responsivediv" ? "20px" : "")};
  margin-bottom: ${({ type }) => (type === "wrapperdiv" || type === "responsivediv" ? "20px" : "")};
  @media (max-width: 570px) {
    width: ${({ type }) => (type === "wrapperdiv" ? "100%" : type === "responsivediv" ? "auto" : "")};
  }
`;

export const PaymentBtn = styled(Button)`
  width: ${({ type }) => (type === "payment_button" ? "auto" : "")};
  margin: ${({ type }) => (type === "payment_button" ? "0 15px" : "")};
  @media (max-width: 570px) {
    width: ${({ type }) => (type === "payment_button" ? "100%" : "")};
    margin-bottom: ${({ type }) => (type === "payment_button" ? "20px" : "")};
  }
`;
