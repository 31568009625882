import React from "react";
import PropTypes from "prop-types";
import Theme from "../../../styles/Theme";
import { Div, MDLabel } from "../../../styles/Styles";

const HeadSection = ({ headerTitle, fontProp }) => (
  <Div
    className="container"
    justifyContent="space-between"
    display="flex"
    mt={4}
    p={[3, 0]}
    mx={[0, 3]}
  >
    <MDLabel fontSize={fontProp || Theme.fontSizes.xl} lineHeight="32px">{headerTitle}</MDLabel>
  </Div>
);
HeadSection.propTypes = {
  headerTitle: PropTypes.string,
  fontProp: PropTypes.string,
};
HeadSection.defaultProps = {
  headerTitle: "Loading...",
  fontProp: "",
};
export default HeadSection;
