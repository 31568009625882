import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ProfileCard from "../../../components/Card/ProfileCard";
import SelectBox from "../../../components/Input/SelectBox";
import Modal from "../../../components/Modal/Modal";
import Constants from "../../../shared/Constants";
import { Div, FormWrapper, MDInputValue, MDLabel, Span } from "../../../styles/Styles";
import { ChangeButton, DateCard } from "../../Assignments/Styles";
import { MDImage } from "../../MyAccounts/Styles";
import Popup from "./Modals";
import PropTypes from "prop-types";
import TextArea from "../../../components/Input/TextArea";
import CheckBox from "../../../components/Input/CheckBox";
import TextBox from "../../../components/Input/TextBox";
import { getServiceList } from "../../../services/SubscriptionService";
import { formatDate } from "../../../shared/utils";
import { useHistory } from "react-router-dom";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import usePermissions, { ModulePermissions } from "../../../shared/usePermissions";

const {
  options,
  currency: {sek},
  subscriptionStatus,
  subscriptionPackage,
  icons,input: { name: inputs, format: { twoDigitDateFormat } }, themes: { fontSizes,widths },
  language,
  adminRoutes
} = Constants;
const { space } = Theme;


const DetailsScreenReadOnly = ({services, subscriptionData, weekDays, selectedPackage}) => {
  const option = services.find(item => item.value === subscriptionData?.product?.id);
  const subscriptionDays = subscriptionData?.subscription_days?.map((day)=>{
    return weekDays.find(item => item.value === day);
  })
  return <Div className="col-8" pl={0} pr={0}>
    <MDLabel mt={["16px", "40px"]} mb="12px" fontSize={fontSizes.medium}>
      <FormattedMessage id="packages" defaultMessage="Packages" />
    </MDLabel>
    <Div display="flex" alignItems="center" p="0px">
      {option?.icon && (
        <Div display="flex" alignItems="center" cursor="pointer" mr="8px">
          <MDImage
            src={option?.icon}
            alt="icon"
            width="11px"
            height="20px"
          />
        </Div>
      )} <MDInputValue cursor="pointer">{option?.label}</MDInputValue>
    </Div>
    <MDLabel mt="40px" mb="20px" fontSize={fontSizes.medium} >
      <FormattedMessage id="days" defaultMessage="days"/>:
    </MDLabel>
    {subscriptionDays.map((day) => (
      <MDLabel py="8px" key={day?.value} fontSize={fontSizes.medium} >
        {day?.key}
      </MDLabel>
    ))}
    <MDLabel mt={["22px", "22px", "40px"]} mb="20px" fontSize={fontSizes.medium} >
      <FormattedMessage id="next_payment" defaultMessage="Next payment"/>:
    </MDLabel>
    <MDLabel mt="20px" mb={["30px", "20px", "20px"]} fontWeight="400" fontFamily="Roboto" fontSize={fontSizes.regular} >
      {sek} {selectedPackage?.details?.price}
    </MDLabel>
  </Div>
}

const ProceedToActiveSection = ({handleSelect,handleChange,hasNoWriteAccess,
  subscriptionData, isCustom, showCountError, weekDays, dayCount, selectedDays, price}) => {
  return <>
    <Div className="my-3 col-lg-8" pl="0" pr="0">
      <SelectBox
        options={options.applicationStatus.map(item => {return {...item, isSelected: false}})}
        placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
        width="100%"
        disabled={hasNoWriteAccess}
        name="application_status"
        onChange={handleChange}
        value={options.applicationStatus.find(item => item.value === subscriptionData?.application_status)}
        label={language.status_of_application}
      />
    </Div>
    <Div className="mb-lg-3 px-0 pb-3 col-lg-8" display="flex" flexDirection="column">
      <MDLabel mt={["16px", "40px"]} fontSize={fontSizes.xl} lineHeight="32px" alignSelf="start" textAlign="left">
        {language.info_active}:
      </MDLabel>
      {isCustom && <MDLabel mt={["20px", "30px"]} fontSize={fontSizes.medium} alignSelf="start" textAlign="left">
        <FormattedMessage id="days" defaultMessage="days"/>:
      </MDLabel>}
      {!isCustom && <>
        <MDLabel mt="30px" fontSize={fontSizes.medium} alignSelf="start" textAlign="left">
          <FormattedMessage id="days_choose" defaultMessage={`Days (choose ${dayCount})`}  values={{days: dayCount}}/>:
        </MDLabel>
        {showCountError && <MDLabel mt="15px" fontSize={fontSizes.small} alignSelf="start" textAlign="left" color="var(--red)">
          <FormattedMessage id="need_to_choose" defaultMessage={`Need to choose ${dayCount} days)`}  values={{days: dayCount}}/>:
        </MDLabel>}
      </>}

      {weekDays.map((day, index) => (
        <Div key={index} display="flex" className="col-12" mt="20px" pl="0">
          <CheckBox
            primary
            disabled={hasNoWriteAccess}
            isSelected={selectedDays.includes(day.value)}
            onClick={() => handleSelect(day.value)}
            label={day.key}
          />
        </Div>
      ))}
      <Div pt="10px">
        <TextBox
          mt={["20px","40px"]}
          name="price"
          type="number"
          disabled={hasNoWriteAccess}
          label={language.price_month}
          onChange={handleChange}
          value={price}
          IconHeight="20px"
        />
      </Div>
    </Div>
  </>
}

const SubscriptionInfoForm = ({subscriptionData, setSubscriptionData,dataToBeUpdated, setDataToBeUpdated, proceedToActive, updateIsActiveButtonEnabled, hasNoWriteAccess}) => {
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedDays, setSelectedDays] = useState(subscriptionData?.subscription_days || [])
  const [isCustom, setIsCustom] = useState(subscriptionData?.product?.name === "Custom");
  const [selectedPackage, setSelectedPackage] = useState({...subscriptionData.product});
  const [price, setPrice] = useState(subscriptionData?.price);
  const [showCountError, setShowCountError] = useState(false);
  const userInfo = useSelector((state) => state.AppReducer.userDetails);
  const history = useHistory();
  const [weekDays] = useState([
    {
      key: language.label_monday,
      value: "monday",
    },
    {
      key: language.label_tuesday,
      value: "tuesday",
    },
    {
      key: language.label_wednesday,
      value: "wednesday",
    },
    {
      key: language.label_thursday,
      value: "thursday",
    },
    {
      key: language.label_friday,
      value: "friday",
    },
    {
      key: language.label_saturday,
      value: "saturday",
    },
    {
      key: language.label_sunday,
      value: "sunday",
    },
  ]);
  const [getOrganizations] = usePermissions();

  useEffect(()=>{
    if(!price){
      const { details : { price: selectedPrice = 0 } = {} } = selectedPackage;
      setPrice(selectedPrice);
    }
  }, [selectedPackage?.details?.price]);

  useEffect(()=>{
    if(services.length && subscriptionData?.product?.id){
      const packageInfo = services.find(item => item.id === subscriptionData?.product?.id);
      if(typeof packageInfo?.details === "string"){
        packageInfo.details = JSON.parse(packageInfo?.details);
      }
      setSelectedPackage(packageInfo);
    }

  }, [services, subscriptionData?.product?.id])

  useEffect(()=>{
    setOrganizations(getOrganizations(ModulePermissions.Subscription, Constants.WRITE));
  }, [userInfo]);

  useEffect(()=>{
    if(!proceedToActive){
      updateIsActiveButtonEnabled(true);
    } else {
      const isAllDaySelected = selectedPackage.name !== "Custom" ? selectedPackage?.details?.day_count === selectedDays.length : selectedDays.length > 0;
      if(!isAllDaySelected && selectedPackage.name !== "Custom"){
        setShowCountError(true);
        updateIsActiveButtonEnabled(false);
        return;
      } else {
        setShowCountError(false);
      }
      const isButtonActive = !!(proceedToActive && dataToBeUpdated?.start_date && dataToBeUpdated?.product_id && isAllDaySelected && dataToBeUpdated?.price);
      updateIsActiveButtonEnabled(isButtonActive);
    }

  }, [proceedToActive, selectedPackage, dataToBeUpdated, selectedDays]);

  useEffect(()=>{
    if(selectedDays) {
      setDataToBeUpdated(prevData => {
        return {...prevData, subscription_days: selectedDays}
      })
    }
  },[selectedDays?.length])

  const getLabel = (name, labelPrice) => {
    return `${name} - ${sek} ${labelPrice}/${language.month}`
  }

  useEffect(async ()=>{
    const getStatusOfApplicationFiltered = item=> {
      const details = typeof item?.details === "string" ? JSON.parse(item.details) : item.details;
      const {price: packagePrice = 0 } = details || {};
      if(item.name.includes(subscriptionPackage.bronze) && details.day_count === 2) {
        return {label: getLabel(subscriptionPackage.bronze, packagePrice), value: item.id, icon: icons.GreenIcon,  ...item}
      }
      if(item.name.includes(subscriptionPackage.bronze) && details.day_count === 3) {
        return {label: getLabel(subscriptionPackage.bronze, packagePrice), value: item.id, icon: icons.BronzeIcon,  ...item}
      }
      if(item.name.includes(subscriptionPackage.silver)) {
        return {label: getLabel(subscriptionPackage.silver, packagePrice), value: item.id, icon: icons.SilverIcon,  ...item}
      }
      if(item.name.includes(subscriptionPackage.gold)) {
        return {label: getLabel(subscriptionPackage.gold, packagePrice), value: item.id, icon: icons.GoldIcon,  ...item}
      }
      if(item.name.includes(subscriptionPackage.custom)) {
        return {label: item.name, value: item.id, icon: icons.CustomIcon,  ...item}
      }
    }
    const {data} = await getServiceList();
    const statusOfApplication = data.map(getStatusOfApplicationFiltered);
    setServices(statusOfApplication);
  }, []);

  const handleOpenModal = (name) => () => {
    setShowModal(name);
    setModalData(organizations);
  };

  const handleSelectedData = (name, value) => {
    const data = {...subscriptionData}
    if(name === inputs.place){
      data["organization"] = {...value, address: value.info, profile_pic: {url: value.profilePic}};
      setDataToBeUpdated(prevData => {
        return {...prevData, organization_id: value.id}
      })
    } else {
      data[name] = value;
      setDataToBeUpdated(prevData => {
        return {...prevData, [name]: value}
      });
    }
    setSubscriptionData(data)
    setShowModal("");
  }

  const handleSelect = (value) => {
    if(selectedDays.includes(value)){
      setSelectedDays(selectedDays.filter(day => day !== value));
    } else {
      if(selectedDays.length === selectedPackage.details.day_count){
        return;
      }
      setSelectedDays(prevValue => [...prevValue, value]);
    }
  }

  const handlePackageChange = (e) => {
    if(e.target.value.name === "Custom"){
      setIsCustom(true);
      setSelectedDays([]);
      setShowCountError(false);
    } else {
      setIsCustom(false);
    }
    const packageInfo = e.target.value;
    if(typeof packageInfo?.details === "string"){
      packageInfo.details = JSON.parse(packageInfo?.details);
    }
    const { details : { price: selectedPrice = 0 } = {} } = packageInfo;
    setPrice(selectedPrice);
    setSelectedPackage(packageInfo);
    setDataToBeUpdated(prevData => {
      return {...prevData, product_id: packageInfo.id, price: packageInfo.details.price}
    })
  }

  const handleChange = (e) =>{
    const data = {...subscriptionData}
    if(e.target.name === "application_status"){
      data["application_status"] = e.target.value.value;
      setDataToBeUpdated(prevData => {
        return {...prevData, [e.target.name]: e.target.value.value}
      })
      setSubscriptionData(data)
      return;
    }
    if(e.target.name === "price"){
      setPrice(e.target.value);
      setSelectedPackage(prevPackage => {
        return {...prevPackage, details: {...prevPackage.details, price: e.target.value}}
      });
    }
    if(e.target.name === inputs.description){
      data[inputs.description] = e.target.value.value;
    }
    setDataToBeUpdated(prevData => {
      return {...prevData, [e.target.name]: e.target.value}
    })
    setSubscriptionData(data)
  }

  const redirecthandleEditCustomer =(item) => () => {
    history.push(`${adminRoutes.adminPrefix}/${adminRoutes.customers}/${item.customer.id}/${adminRoutes.editCustomer}/dogs`);
  }

  const redirectToOrganizationDetailPage = (id="") => () => {
    return history.push(`/admin/organization/${id}/edit-organization`);
  };


  const address = subscriptionData?.organization?.address || subscriptionData?.organization?.info;
  const { status } = subscriptionData;
  const dayCount = selectedPackage?.details?.day_count || 0;
  const hiddenClass = hasNoWriteAccess ? "events-none" : "" ;
  const disabledState = hasNoWriteAccess ? "disabled-state" : "";

  const ClientView = ({subscriptionData}) => {
    const history = useHistory();
    const { customer: clientData = {}} = subscriptionData;
    const { profile_pic: profilePic = {} } = clientData;
    const { url: clientProfilePic = "" } = profilePic || {};

    const redirectToDetailPage = (id="") => () => {
      history.push(`/admin/customers/${id}/edit-customer`);
    };
    if(!clientData){
      return <MDLabel fontSize={fontSizes.medium} className="mb-4">
        <FormattedMessage
          id="client_not_available"
          defaultMessage="Client is not available"
        />
      </MDLabel>
    }
    return <Div display="flex" className="mb-4 pb-2" cursor="pointer"
      onClick={redirectToDetailPage(clientData?.id)}>
      <Div>
        <MDImage
          width="64px"
          height="64px"
          borderRadius="10px !important"
          src={clientProfilePic || FavIcon}
          bg={clientProfilePic ? "" : `${Colors.Primary}`}
          className={clientProfilePic ? "" : "p-2"}
          alt="icon"
        />
      </Div>
      <Div alignSelf="center" pl={3}>
        <MDLabel fontSize={fontSizes.medium}>
          <Span pb={1}>
            {clientData.name}
          </Span>
        </MDLabel>
        <MDLabel fontSize={fontSizes.small}>
          <Span pb={1}>{clientData.phone_number_list || ""}</Span>
        </MDLabel>
        <MDLabel fontSize={fontSizes.small}>
          <Span>{clientData.email || ""}</Span>
        </MDLabel>
      </Div>
    </Div>
  }

  return <>
    <FormWrapper width="870px">
      <MDLabel mt="16px" mb={["16px", "40px"]} lineHeight="32px" fontSize={fontSizes.xl}>
        <FormattedMessage
          id="subscription_for_dog"
          defaultMessage="customer Info"
          values={{packageName: subscriptionData?.product?.name, dogSize:subscriptionData?.dog?.height}}/>:
      </MDLabel>
      <FormWrapper>
        <Div>
          <MDLabel fontSize={space.m} className="mb-4">
            <FormattedMessage id="common_client" />
          </MDLabel>
          <ClientView subscriptionData={subscriptionData}/>
        </Div>
      </FormWrapper>
      <Div className="row m-0 my-3">
        <Div className="col-lg-5 col-md-12" p="0" pb={3} mb={3} pt={[3, 3, 0]}
          onClick={redirecthandleEditCustomer(subscriptionData)}>
          <MDLabel mb={3} fontSize={fontSizes.medium}>
            <FormattedMessage id="common_dog" defaultMessage="Dog" />
          </MDLabel>
          <ProfileCard
            data={subscriptionData?.dog}
            userAvtar={subscriptionData?.dog?.profile_pic?.url}
            showCursor
            width="fit-content"
            minWidth={widths.w255}
            onClick={redirecthandleEditCustomer(subscriptionData)}
            height="64px" />
        </Div>
        <Div className="col-lg-4 col-md-12" p="0">
          <MDLabel mb={3} fontSize={fontSizes.medium}>
            <FormattedMessage id="assignment_content_place" defaultMessage="Place" />
          </MDLabel>
          <ProfileCard
            data={{...subscriptionData?.organization, info: address }}
            width={widths.w255}
            minWidth={widths.w255}
            height="64px"
            userAvtar={subscriptionData?.organization?.profile_pic?.url}
            onClick={redirectToOrganizationDetailPage(subscriptionData?.organization?.id)}
            showCursor
          />
          <MDLabel mt="22px" className={disabledState} onClick={handleOpenModal(inputs.place)} >
            <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
            <ChangeButton className={hiddenClass}>
              <FormattedMessage id="assignment_change_place" defaultMessage="Change place" />
            </ChangeButton>
          </MDLabel>
        </Div>
      </Div>
      <Div className="row m-0" pt={["16px","40px"]} pb="10px">
        <Div className="col-lg-5 col-md-12" p={0} pb="16px" pt={0}>
          <MDLabel mb={3} fontSize={fontSizes.medium}>
            <FormattedMessage id="start_date" defaultMessage="Start Date" />
          </MDLabel>
          {subscriptionData.start_date && <DateCard height="64px" width={widths.w125}>
            <MDLabel fontSize={fontSizes.regular}>
              {formatDate(subscriptionData.start_date, twoDigitDateFormat)}
            </MDLabel>
          </DateCard>}
          <MDLabel className={disabledState}  mt="20px" onClick={handleOpenModal(inputs.startDate)}>
            <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
            <ChangeButton className={hiddenClass}>
              {subscriptionData.start_date &&<FormattedMessage id="edit_start_date" defaultMessage="Edit start date" />}
              {!subscriptionData.start_date &&<FormattedMessage id="add_start_date" defaultMessage="Add end date" />}
            </ChangeButton>
          </MDLabel>
        </Div>
        <Div className="col-lg-4 col-md-12" p={0} pt={[3, 3, 0]}>
          <MDLabel mb={3} fontSize={fontSizes.medium}>
            <FormattedMessage id="label_end_date" defaultMessage="End Date" />
          </MDLabel>
          {subscriptionData.end_date && <DateCard height="64px" width={widths.w125}>
            <MDLabel fontSize={fontSizes.regular}>
              {formatDate(subscriptionData.end_date, twoDigitDateFormat)}
            </MDLabel>
          </DateCard>}
          <MDLabel className={disabledState} mt="20px" onClick={handleOpenModal(inputs.endDate)}>
            <MDImage src={icons.EditIcon} alt="icon" className="mr-1" role="button" />
            <ChangeButton className={hiddenClass}>
              {subscriptionData.end_date &&<FormattedMessage id="edit_end_date" defaultMessage="Edit end date" />}
              {!subscriptionData.end_date &&<FormattedMessage id="add_end_date" defaultMessage="Add end date" />}
            </ChangeButton>
          </MDLabel>
        </Div>
      </Div>
      {status === subscriptionStatus.canceled && <DetailsScreenReadOnly
        services={services} subscriptionData={subscriptionData}
        weekDays={weekDays}
        selectedPackage={selectedPackage} />}
      {status !== subscriptionStatus.canceled && <>
        <Div className="col-lg-8 col-md-12" pl={[0, 0, 0, 0]} pr={0}>
          <SelectBox
            options={services}
            placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
            label={language.packages}
            onChange={handlePackageChange}
            value={selectedPackage.id}
            disabled={hasNoWriteAccess}
            width="100%"
          />
        </Div>
        <ProceedToActiveSection
          hasNoWriteAccess={hasNoWriteAccess}
          subscriptionData={subscriptionData}
          handleSelect={handleSelect}
          handleChange={handleChange}
          showCountError={showCountError}
          isCustom={isCustom}
          dayCount={dayCount}
          weekDays={weekDays}
          selectedDays={selectedDays}
          price={price} />
      </>}
      <Div className="col-lg-8  px-0" mt="4px" mb="40px">
        <MDLabel mb="15px" fontSize={fontSizes.medium}>
          <FormattedMessage id="notes" defaultMessage="Notes" />
        </MDLabel>
        <MDLabel mb="15px" fontFamily="Roboto" fontSize={fontSizes.regular} fontWeight="400">
          <FormattedMessage id="if_needed" defaultMessage="If needed" />
        </MDLabel>
        <TextArea
          disabled={hasNoWriteAccess}
          value={subscriptionData?.description}
          name={inputs.description}
          onChange={handleChange}
        />
      </Div>
    </FormWrapper>
    {showModal && (
      <Modal>
        <Popup
          data={modalData}
          values={subscriptionData}
          handleSubmit={handleSelectedData}
          name={showModal}
          onCancel={() => {
            setShowModal("");
          }}
        />
      </Modal>
    )}
  </>
}

DetailsScreenReadOnly.propTypes = {
  services: PropTypes.node,
  subscriptionData: PropTypes.node,
  weekDays: PropTypes.node,
  selectedPackage: PropTypes.node,
};
DetailsScreenReadOnly.defaultProps = {
  subscriptionData: {},
  services: [],
  weekDays: [],
  selectedPackage: [],
};

ProceedToActiveSection.propTypes = {
  subscriptionData: PropTypes.node,
  handleSelect: PropTypes.func,
  handleChange: PropTypes.func,
  showCountError: PropTypes.bool,
  isCustom: PropTypes.bool,
  weekDays: PropTypes.node,
  dayCount: PropTypes.number,
  price: PropTypes.number,
  selectedDays: PropTypes.node,
  hasNoWriteAccess: PropTypes.bool,
  selectedPackage: PropTypes.node
};
ProceedToActiveSection.defaultProps = {
  subscriptionData: {},
  dataToBeUpdated:{},
  selectedDays: [],
  weekDays: [],
  showCountError: false,
  isCustom: false,
  dayCount: 0,
  price: 0,
  hasNoWriteAccess: false
};

SubscriptionInfoForm.propTypes = {
  subscriptionData: PropTypes.any,
  dataToBeUpdated: PropTypes.any,
  edit: PropTypes.bool,
  setSubscriptionData: PropTypes.func,
  proceedToActive: PropTypes.bool,
  setDataToBeUpdated: PropTypes.func,
  updateIsActiveButtonEnabled: PropTypes.func,
  hasNoWriteAccess: PropTypes.bool
};
SubscriptionInfoForm.defaultProps = {
  subscriptionData: {},
  dataToBeUpdated:{},
  edit: false,
  proceedToActive: false,
  hasNoWriteAccess: false
};
export default SubscriptionInfoForm;
