import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Theme from "../../../../styles/Theme";
import { MDLabel, MDImage, Box } from "../../../../styles/Styles";
import { MDImg, LabelBox, MDLabelBox, BoxDivision, Division } from "../../BookingFlowStyles/BookingFlowStyles";
import Colors from "../../../../styles/Colors";

const CommonCard = ({
  avtarImg,
  header,
  leftIcon,
  leftContent,
  rightContent,
  rightIcon,
  htmlFor,
  fontSize,
  color,
  fontFamily,
  fontWeight,
  paddingLeft,
  type,
  mainType,
  imgHeight,
  boxDivType,
  onChange,
  typeNames,
  handleEdit,
  page,
  age,
  imgWidth,
  msLeft,
  msRight,
  msFull,
  imagePadding = 0,
  width,
  heightValue,
  isDog,
  lengthOfDog = "",
  isDisabled = false,
  imgBg = "",
}) => {
  const isUserLoggedIn = useSelector((state) => state.BookingFlowServicesReducer.isUserLogged);

  const getContentDisplay = () => {
    let display = "";
    if (width) {
      if (isDog) {
        display = "flex";
      } else {
        display = "contents !important";
      }
    }
    return display;
  };

  const renderLeftContent = () => {
    if (leftContent || leftIcon) {
      return (
        <BoxDivision pr={1} type={msLeft || msFull} display={getContentDisplay()}>
          {leftIcon && <MDImage src={leftIcon} mr={1} alt="icon" width={18} height={18} />}
          {leftContent && (
            <MDLabelBox
              type={type}
              fontSize={fontSize}
              color={color}
              fontFamily={fontFamily}
              fontWeight={fontWeight}
              textAlign="left">
              {leftContent}
            </MDLabelBox>
          )}
        </BoxDivision>
      );
    }
  };

  const renderRightContent = () => {
    if (rightIcon || rightContent) {
      return (
        <BoxDivision type={msRight} pr={3}>
          <MDImage src={rightIcon} mr={1} alt="icon" width={18} height={18} />
          <MDLabel type={type} fontSize={Theme.fontSizes.small} fontFamily={fontFamily} fontWeight={fontWeight} color={Colors.DarkGrey}>
            {rightContent}
          </MDLabel>
        </BoxDivision>
      );
    }
  };

  const renderAge = () => {
    if (isUserLoggedIn && age) {
      return <MDLabel fontSize={14}>{age}</MDLabel>;
    }
  };

  const renderLengthOfDog = () => {
    if (lengthOfDog) {
      return <MDLabel fontSize={14}>{lengthOfDog}</MDLabel>;
    }
  };

  return (
    <LabelBox
      htmlFor={htmlFor}
      type={mainType || typeNames}
      onClick={onChange || handleEdit(page)}
      width={width}
      height="auto !important"
      className={!!isDisabled && "disabled-state"}>
      {avtarImg && (
        <Box width={imgBg ? "80px" : ""} background={imgBg} borderRadius={10}>
          <MDImg
            src={avtarImg}
            type="roundImg"
            alt="icon"
            width={imgWidth || 64}
            minHeight={imgHeight || 64}
            height={heightValue || 64}
            bg={imagePadding && Colors.Primary}
            p={imagePadding}
          />
        </Box>
      )}
      <Division
        alignSelf="center"
        paddingLeft={paddingLeft}
        width={!paddingLeft && "100%"}
        type="msFull"
        display={!paddingLeft && "flex"}
        justifyContent="center">
        <MDLabel fontSize={Theme.fontSizes.regular} display={!paddingLeft && "block"}>
          {header}
          {renderAge()}
          {renderLengthOfDog()}
        </MDLabel>
        <BoxDivision
          mt={2}
          type={boxDivType || "chooseItem"}
          justifyContent={boxDivType === "stepThree" ? "flex-start !important" : "space-between !important"}>
          {renderLeftContent()}
          {renderRightContent()}
        </BoxDivision>
      </Division>
    </LabelBox>
  );
};
CommonCard.propTypes = {
  avtarImg: PropTypes.string,
  header: PropTypes.string,
  leftIcon: PropTypes.string,
  leftContent: PropTypes.string,
  rightContent: PropTypes.number,
  rightIcon: PropTypes.string,
  htmlFor: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  paddingLeft: PropTypes.string,
  type: PropTypes.string,
  mainType: PropTypes.string,
  imgHeight: PropTypes.string,
  boxDivType: PropTypes.string,
  onChange: PropTypes.func,
  typeNames: PropTypes.string,
  handleEdit: PropTypes.func,
  page: PropTypes.number,
  age: PropTypes.string,
  imgWidth: PropTypes.string,
  msLeft: PropTypes.string,
  msRight: PropTypes.string,
  msFull: PropTypes.string,
  imagePadding: PropTypes.string,
  width: PropTypes.string,
  heightValue: PropTypes.string,
  isDog: PropTypes.bool,
  lengthOfDog: PropTypes.string,
  isDisabled: PropTypes.bool,
  imgBg: PropTypes.string,
};
CommonCard.defaultProps = {
  avtarImg: "",
  header: "",
  leftIcon: "",
  leftContent: "",
  rightContent: "",
  rightIcon: "",
  htmlFor: "",
  fontSize: "",
  color: "",
  fontFamily: "",
  fontWeight: "",
  paddingLeft: "",
  type: "",
  mainType: "",
  imgHeight: "",
  boxDivType: "",
  onChange: () => {},
  typeNames: "",
  handleEdit: () => {},
  page: "",
  age: "",
  imgWidth: "",
  msLeft: "",
  msRight: "",
  msFull: "",
  imagePadding: 0,
  width: "",
  heightValue: "64px",
  isDog: false,
  lengthOfDog: "",
  isDisabled: false,
  imgBg: "",
};
export default memo(CommonCard);
