import { request } from "./ApiService";
import Constants from "../shared/Constants";

const { HTTP_STATUS: { OK }, apiEndPoints: { endPointUrl: endPoint } } = Constants;

export const uploadImage = async (file) => {
  const form = new FormData();
  form.append("media", file);
  form.append("type", "image");
  const url = `${endPoint.FILES}`;
  const response = await request("post", url, form);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data;
};

export const deleteImage = async (id) => {
  const url = `${endPoint.FILES}/${id}`;
  const response = await request("delete", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    throw Error(statusText);
  }
  return data;
};
