import React from "react";
import PropTypes from "prop-types";
import { PaymentBtn, ButtonBox } from "../Style";
import Constants from "../../../shared/Constants";

const PaymentButtons = ({ pushHandler, disabled }) => {
  const { language } = Constants;

  return (
    <>
      <ButtonBox mt={4}>
        <PaymentBtn
          type="payment_button"
          primary
          label={language.pay_by_klarna}
          onClick={pushHandler}
          disabled={disabled}
        />
      </ButtonBox>
    </>
  );
};
PaymentButtons.propTypes = {
  pushHandler: PropTypes.func,
  disabled: PropTypes.bool,
};
PaymentButtons.defaultProps = {
  pushHandler: () => {},
  disabled: false,
};

export default PaymentButtons;
