import ApiService, { request } from "./ApiService";
import Const from "../shared/Constants";
import { checkPermission } from "../shared/utils";

export const formatUserPermissions=(userAccess)=>{
  return {
    module:{
      assignments: checkPermission(userAccess, "is_assignments_editable"),
      calendar: checkPermission(userAccess, "is_calendar_editable"),
      customer: checkPermission(userAccess, "is_customer_editable"),
      order: checkPermission(userAccess, "is_order_editable"),
      room: checkPermission(userAccess, "is_room_editable"),
      services: checkPermission(userAccess, "is_services_editable"),
      users: checkPermission(userAccess, "is_users_editable"),
      subscriptions: checkPermission(userAccess, "is_subscription_editable"),
    },
    rights: userAccess.map((o) => o.rights),
    organization_id: userAccess.map((o) => o.organization_id),
    organization_name: userAccess.map((o) => o.organization_name),
  };
};

export async function fetchLoginDetails(data) {
  const url = Const.apiEndPoints.endPointUrl.LOGIN;
  const response = await request("post", url, data);
  if (response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  const { data: responseData = {} } = response;
  const {
    token = "", user = {}, assignments: { new: openAssignments = 0 } = {},
    orders: { new: openOrders = 0 } = {},
    customer: { new: newCustomers = 0 } = {},
    subscription: { open: newSubscription = 0 } = {}
  } = responseData;
  user.openAssignments = openAssignments;
  user.openOrders = openOrders;
  user.newCustomers = newCustomers;
  user.newSubscription = newSubscription
  let { user_access: userAccess = [] } = user;
  user.user_access = formatUserPermissions(userAccess) || {};
  user.permissions = userAccess || [];
  localStorage.setItem(Const.storage.token, token);
  localStorage.setItem("userData", JSON.stringify(user));
  return { token, user };
}

class AuthService extends ApiService {
  userLogin = "login";
  userDetail = "details";

  async signIn(loginCredentials) {
    localStorage.clear();
    const response = await this.login(this.userLogin, loginCredentials);

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      // Store the token
      const { data = {} } = response.data;
      const {
        token = "", user = {}, assignments: { new: openAssignments = 0 } = {},
        orders: { new: openOrders = 0 } = {},
        customer: { new: newCustomers = 0 } = {},
      } = data;
      user.openAssignments = openAssignments;
      user.openOrders = openOrders;
      user.newCustomers = newCustomers;
      let { user_access: userAccess = [] } = user;
      user.user_access = formatUserPermissions(userAccess) || {};
      user.permissions = userAccess || [];
      localStorage.setItem(Const.storage.token, token);
      localStorage.setItem("userData", JSON.stringify(user));
      return user;
    }
  }

  async usersDetails() {
    const response = await this.get(this.userDetail);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      const {
        data: {
          user = {}, assignments: { new: openAssignments = 0 } = {},
          orders: { new: openOrders = 0 } = {},
          customer: { new: newCustomers = 0 } = {},
          subscription: { open: newSubscription = 0 } = {},
        },
      } = response;
      user.openAssignments = openAssignments;
      user.openOrders = openOrders;
      user.newCustomers = newCustomers;
      user.newSubscription = newSubscription;
      let { user_access: userAccess = [] } = user;
      user.user_access = formatUserPermissions(userAccess) || {};
      user.permissions = userAccess || [];
      localStorage.setItem("userData", JSON.stringify(user));
      return user;
    }
  }

  isAdminUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => obj === Const.role.Admin).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isCustomerUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => obj === Const.role.Customer).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isAuthenticated() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const jwtPayload = this.parseJwt(token);
      if (jwtPayload) {
        return jwtPayload.exp > +new Date() / 1000;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  parseJwt = (token) => {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    }
    return token;
  };
}

export default AuthService;
