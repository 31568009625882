import { request } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser } from "../shared/utils";
import { fromJson } from "../models/Assignment";

const {
  apiEndPoints: { endPointUrl = {} }, HTTP_STATUS: { OK, CREATED },
  input: {
    param: {
      customerId, orgId, userId,
    },
  },
} = Constants;

export async function getAssignmentList(params) {
  const url = `${endPointUrl.ASSIGNMENTS}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function createAssignment(payload) {
  const url = `${endPointUrl.ASSIGNMENTS}`;
  const response = await request("post", url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== CREATED) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function updateAssignment(id, payload) {
  const url = `${endPointUrl.ASSIGNMENTS}/${id}`;
  const response = await request("put", url, payload);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getDetails(id) {
  const url = `${endPointUrl.ASSIGNMENTS}/${id}`;
  const response = await request("get", url);
  const { data: { data: responseData = {} }, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return fromJson(responseData);
}
export async function getOrganisations() {
  const url = `${endPointUrl.PUBLIC}/${endPointUrl.ORGANIZATIONS}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getServiceProviders(id) {
  const params = [[orgId, id]];
  const url = `${endPointUrl.ORGANIZATIONS}/${endPointUrl.SERVICEPROVIDERS}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getRooms(id) {
  const params = [[orgId, id]];
  const url = `${endPointUrl.ORGANIZATIONS}/${endPointUrl.ROOMS}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getServices(room = "") {
  const params = [[userId, room]];
  const url = `${endPointUrl.USERS}/${endPointUrl.SERVICES}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getClients() {
  const url = `${endPointUrl.PUBLIC}/${endPointUrl.CUSTOMERS}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function getDogs(id) {
  const params = [[customerId, id]];
  const url = `${endPointUrl.CUSTOMERS}/${endPointUrl.DOGS}${queryParamParser(params)}`;
  const response = await request("get", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function closeAssignment(id) {
  const url = `${endPointUrl.ASSIGNMENTS}/${id}/${endPointUrl.CLOSE}`;
  const response = await request("put", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
export async function deleteAssignment(id) {
  const url = `${endPointUrl.ASSIGNMENTS}/${id}`;
  const response = await request("delete", url);
  const { data = {}, status = "", statusText = "" } = response;
  if (status !== OK) {
    // TODO: Throw more specific errors
    throw Error(statusText);
  }
  return data;
}
