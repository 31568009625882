import { useSelector } from "react-redux";

export const ModulePermissions = {
  Assignment: "is_assignments_editable",
  Calendar: "is_calendar_editable",
  Customer: "is_customer_editable",
  Dog: "is_dog_editable",
  Order: "is_order_editable",
  Organization: "is_organization_editable",
  Room: "is_room_editable",
  Service: "is_service_editable",
  Subscription: "is_subscription_editable",
  User: "is_users_editable"
};

export const getAssociatedOrganizations = (module, permission, permissions, allOrganisations) => {
  const orgIds = permissions
    .filter((obj) => obj[module] === permission)
    .map((obj) => obj.organization_id);
  return allOrganisations
    .filter((org) => orgIds.includes(org.id))
    .map((org) => ({
      label: org.name,
      value: org.id,
      id: org.id,
      profilePic: org.profile_pic?.url || "",
      info: org.address || "",
    }));
};

const usePermissions = () => {
  const userInfo = useSelector(
    (state) => state.AppReducer.userDetails
  );
  const { permissions = [], organization: { organization_details: allOrganisations = [] } = {} } = userInfo;

  const getOrganizations = (module, permission) => {
    return getAssociatedOrganizations(module, permission, permissions, allOrganisations);
  }

  return [getOrganizations];
};

export default usePermissions;
