import React from "react";
import moment, { now } from "moment";
import {
  Div, MDLabel,
} from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import CalendarCard from "../../../components/Card/CalendarCard";
import Colors from "../../../styles/Colors";
import { formatDate } from "../../../shared/utils";

const WeekViewEvent = ({ history, eventInfo }) => {
  const isMobile = window.screen.width < 992;

  const renderEventContent = () => {
    const {
      adminRoutes,
      input: { format },
    } = Constants;
    const { view } = eventInfo;
    const calendarData = eventInfo.event.extendedProps?.orderCount >=3 ? eventInfo.event.extendedProps : eventInfo.event.extendedProps?.data;
    const orderCount = eventInfo.event.extendedProps.count;
    const handleCardClick = (assignmentId) => {
      history.push(
        `${adminRoutes.adminPrefix}/${adminRoutes.assignments}/${assignmentId}/${adminRoutes.editAssignment}`
      );
    };
    const { showAvailbityButton = false, serviceProviderIds = [] , showFullDayAvailbityButton = false } = eventInfo.event.extendedProps;

    const getServiceProviderLabel = () => {
      if (isMobile) {
        return `${showAvailbityButton ? "S": "D"}: ${serviceProviderIds?.length}`;
      } else {
        return `${serviceProviderIds.length} SP (${showAvailbityButton? Constants.language.common_services: Constants.language.label_full_day})`;
      }
    };

    const dateAvailable = formatDate(eventInfo.event.start, format.date);
    const currentDate = moment(now()).format(format.date);

    if (showAvailbityButton || orderCount) {
      return (
        <Div>
          {showAvailbityButton && (
            <Div
              display="flex"
              color={Colors.White}
              className={`available-btn ${dateAvailable >= currentDate ? "" : "available-btn-dark"}`}
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              width={["auto", "fit-content"]}>
              <Div
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                color={dateAvailable >= currentDate ? Colors.Black : ""}>
                <MDLabel fontSize="11px" lineHeight="13px">
                  {getServiceProviderLabel()}
                </MDLabel>
              </Div>
            </Div>
          )}
          {orderCount && (
            <Div
              display="flex"
              color={Colors.Black}
              bg={dateAvailable >= currentDate ? Colors.Blue : Colors.LightGrey}
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
              flexDirection="row"
              width={["auto", "fit-content"]}>
              <Div
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                p="6px 10px"
                color={dateAvailable >= currentDate ? Colors.Black : ""}>
                <MDLabel fontSize="11px" lineHeight="13px">
                  {`${isMobile? "O": Constants.language.common_orders}: ${orderCount} ${isMobile ? "" : `(${Constants.language.label_full_day})`}`}
                </MDLabel>
              </Div>
            </Div>
          )}
        </Div>
      );
    }
    if (showFullDayAvailbityButton) {
      return (<Div
        display="flex"
        color={Colors.White}
        className={`available-btn ${dateAvailable >= currentDate ? "available-full-day-btn" : "available-btn-dark"}`}
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        width={["auto", "fit-content"]}>
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          color={Colors.White}>
          <MDLabel fontSize="11px" lineHeight="13px">
            {getServiceProviderLabel()}
          </MDLabel>
        </Div>
      </Div>
      )
    }

    return (
      <>
        {calendarData && (
          calendarData?.orderCount ? (
            <Div className="d-inline-flex" bg={Colors.Blue} width="inherit" height="inherit" justifyContent="center">
              <MDLabel fontSize="11px" lineHeight="13px" color={Colors.Black}>
                {isMobile ? 
                  calendarData?.orderCount
                  :`${calendarData?.orderCount} ${Constants.language.common_orders}`}
              </MDLabel>
            </Div>
          ) : (
            <Div className="d-inline-flex" width="inherit" height="inherit">
              <CalendarCard
                data={calendarData}
                viewType={view.type}
                onCardClick={() => handleCardClick(calendarData.assignmentId)}
              />
            </Div>
          ))}
      </>
    );
  };
  return renderEventContent();
};

export default WeekViewEvent;
