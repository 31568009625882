import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import messagesEn from "./translations/en.json";
import messagesSv from "./translations/sv.json";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import loadMarkerIO from "./loadMarkerIO";
import App from "./App";

// css
import "./styles/Fonts/fonts.css";
import "./styles/custom.scss";
import "./styles/main.css";

import Config from "./config";

const messages = {
  en: messagesEn,
  sv: messagesSv,
};

loadMarkerIO();

const currentLanguage = Config.language || "sv";
if (document.getElementById("root")) {
  ReactDOM.render(
    <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
      <App />
    </IntlProvider>,
    document.getElementById("root"),
  );
}

serviceWorker.unregister();
