import React, { memo, useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchOrganizations } from "../../../store/actions/SubscriptionBookingFlowAction";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import { Div, InputBox, P, Span } from "../../../styles/Styles";
import {
  BoxDivision,
  ContentLeftDivision,
  ContentRightDivision,
  MDLabelBox,
  Division
} from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import CommonCard from "../../BookingFlow/CommonSections/CommonCards/CommonCard";
import FavIcon from "../../../assets/Icons/FavIcon.svg";
import Constants from "../../../shared/Constants";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import Map from "../../BookingFlow/Maps/Maps";
import SelectedSubscriptionDetail from "../Components/SelectedSubscriptionDetail";
import PrevNextButtons from "../Components/PrevNextButtons";

const { icons } = Constants;

const SubscriptionFlowStepThree = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const childRef = useRef(null);
  const selectedSubscriptionId = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedSubscriptionId);
  const selectedLocation = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedServiceLocation);
  const organizations = useSelector((state) => state.SubscriptionBookingFlowReducer.organizations);

  const [organizationAddress, setOrganizationAddress] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [zoom, setZoom] = useState(8);

  useEffect(() => {
    dispatch(fetchOrganizations(selectedSubscriptionId));
  }, [selectedSubscriptionId]);

  useEffect(() => {
    let addresses = organizations && organizations.map((organization) => organization.address);
    setOrganizationAddress(addresses);
    setZoom(12);
  }, [organizations]);

  useEffect(() => {
    if (selectedLocation && Object.keys(selectedLocation).length > 0) {
      setIsDisabled(true);
    }
  }, [selectedLocation]);

  const handleSelectedLocation = (item) => {
    const { name, address, id, profile_pic: profilePic } = item;
    const selectedPlaces = {
      organisationId: id,
      address,
      organisationName: name,
      profilePic,
    };
    dispatch(SubscriptionBookingFlowActions.setSelectedServiceLocation(selectedPlaces));
    setZoom(12);
    if(childRef.current){
      handleFormSubmit();
    }
  };

  const handlePrevPage = () => {
    dispatch(SubscriptionBookingFlowActions.previousStep());
    history.push(Constants.routes.chooseSubscriptionDog.url);
  };

  const handleFormSubmit = () => {
    dispatch(SubscriptionBookingFlowActions.nextStep());
    history.push(Constants.routes.chooseSubscriptionDate.url);
  };

  const renderPlaces =
    organizations &&
    organizations.map((organization) => {
      return (
        <Division key={organization.name} type="select_Size" mb={3} pb={1} width="340px" setBorder onClick={handleSelectedLocation.bind(this, organization)}>
          <InputBox
            type="radio"
            name="dogSizes"
            id={organization.id}
            title={organization.name}
            checked={selectedLocation?.organisationId === organization.id}
            hidden
            ref={childRef}
          />
          <CommonCard
            htmlFor={organization.id}
            avtarImg={organization.profile_pic.url || FavIcon}
            header={organization.name}
            leftIcon={icons.ServiceLocation}
            leftContent={organization.address}
            fontSize={Theme.fontSizes.xs}
            fontFamily={Theme.fonts.regular}
            fontWeight={Theme.fontWeights.normal}
            paddingLeft={10}
            imagePadding={organization.profile_pic ? "" : "8px"}
            width="auto !important"
            boxDivType="stepThree"
          />
        </Division>
      );
    });
  return (
    <Div>
      <SelectedSubscriptionDetail />
      <BoxDivision mt={4} type="choose_Address">
        <ContentLeftDivision pl={3} type="choose_Places">
          <MDLabelBox fontSize={Theme.fontSizes.xl} mb={3} pb={1} type="content_center">
            <FormattedMessage id="choose_the_place" defaultMessage="Choose the Place" />
          </MDLabelBox>
          <P
            fontFamily={Theme.fonts.regular}
            fontSize={Theme.fontSizes.regular}
            color={Colors.DarkGrey}
            className="center_text">
            <FormattedMessage id="places_where" defaultMessage="Here are places, where" />
            <Span fontWeight={Theme.fontWeights.bold} color={Colors.Black} paddingLeft="1px" paddingRight="8px">
              <FormattedMessage id="daycare_service" defaultMessage=" Daycare Service" />
            </Span>
          </P>
          <BoxDivision type="place_Content">{renderPlaces}</BoxDivision>
          <BoxDivision bottom={["5px !important", "50px !important", "50px !important"]}>
            <PrevNextButtons handleNext={handleFormSubmit} handlePrevPage={handlePrevPage} nextDisabled={!isDisabled} showNextButton={false} />
          </BoxDivision>
        </ContentLeftDivision>
        <ContentRightDivision pr={3} type="marker_Map">
          <Division type="width_height_map" mb={[4, 0]}>
            {organizationAddress && organizationAddress.length !== 0 && (
              <Map selectedLocation={selectedLocation?.address} zoom={zoom} organizationAddress={organizationAddress} />
            )}
          </Division>
        </ContentRightDivision>
      </BoxDivision>
    </Div>
  );
};
export default memo(SubscriptionFlowStepThree);
