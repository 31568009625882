import React from "react";
import Loader from "./Loader";
import {
  ListCard, ListItem, ListCardHeader, IsDesktop, IsMobile,
} from "../ListScreen/Styles";

const TimeAccordianLoader = () => (
  <>
    <ListCard pb={2}>
      <IsDesktop>
        <ListCardHeader p={2}>
          {[...Array(1)].map(() => <ListItem className="col"><Loader height="60px" /></ListItem>)}
        </ListCardHeader>

        <ListCardHeader my={4} mx={4}>
          {[...Array(2)].map(() => <ListItem className="col"><Loader height="70px" /></ListItem>)}
        </ListCardHeader>

        {[...Array(5)].map(() => (
          <ListItem m={2}>
            {[...Array(1)].map(() => <ListItem className="col"><Loader height="60px" /></ListItem>)}
          </ListItem>
        ))}
      </IsDesktop>
      <IsMobile>
        <ListCardHeader p={2}>
          {[...Array(1)].map(() => <ListItem className="col"><Loader height="60px" /></ListItem>)}
        </ListCardHeader>

        <ListCardHeader my={4} mx={4}>
          {[...Array(2)].map(() => <ListItem className="col"><Loader height="70px" /></ListItem>)}
        </ListCardHeader>

        {[...Array(5)].map(() => (
          <ListItem m={2}>
            {[...Array(1)].map(() => <ListItem className="col"><Loader height="60px" /></ListItem>)}
          </ListItem>
        ))}
      </IsMobile>
    </ListCard>
  </>
);
export default TimeAccordianLoader;
