import ApiService, { request } from "./ApiService";
import Constants from "../shared/Constants";
import { queryParamParser } from "../shared/utils";

const { apiEndPoints: { endPointUrl = {} }, SUPER_ADMIN } = Constants;

export function getCalendarEventsPromise(params) {
  const { id = "", user_access: { rights: userType = "" } = {} } = JSON.parse(localStorage.getItem("userData")) || {};
  let queryParams = [...params];
  if (!userType?.includes(SUPER_ADMIN)) {
    queryParams = [...[["user_id", id]], ...params];
  }
  const url = `${endPointUrl.ASSIGNMENTS}${queryParamParser(queryParams)}`;
  return request("get", url)
    .then((response) => response.data);
}

export async function getCalendarEvents(params) {
  const response = await getCalendarEventsPromise(params);
  if (response && response.status !== 200) {
    // TODO: Throw more specific errors
    throw Error(response.statusText);
  }
  return response;
}
class CalendarService extends ApiService {
}
export default CalendarService;
