import * as types from "./types";
import {
  getAllFilters,
  getAllCategories, getAllServices, getAllOrganizations, getAllOrdersServices,
} from "../../services/FilterService";

export function getFilters() {
  return (dispatch) => getAllFilters().then((res) => {
    dispatch({
      type: types.FILTERS,
      payload: res,
    });
  }).catch(() => {
    dispatch({
      type: types.FILTERS,
      payload: [],
    });
  });
}

export function getOrdersServicesFilters() {
  return (dispatch) => getAllOrdersServices().then((res) => {
    dispatch({
      type: types.ORDERS_SERVICES_FILTERS,
      payload: res,
    });
  }).catch((error) => {
    console.log(error)
    dispatch({
      type: types.ORDERS_SERVICES_FILTERS,
      payload: [],
    });
  });
}

export function getAvailableServices() {
  return (dispatch) => getAllServices().then((res) => {
    dispatch({
      type: types.SERVICES_FILTERS,
      payload: res,
    });
  }).catch(() => {
    dispatch({
      type: types.SERVICES_FILTERS,
      payload: [],
    });
  });
}

export function getAvailableOrganizations() {
  return (dispatch) => getAllOrganizations().then((res) => {
    dispatch({
      type: types.ORGANIZATION_FILTERS,
      payload: res,
    });
  }).catch(() => {
    dispatch({
      type: types.ORGANIZATION_FILTERS,
      payload: [],
    });
  });
}
export function getCategoryFilters() {
  return (dispatch) => getAllCategories().then((res) => {
    dispatch({
      type: types.CATEGORY_FILTERS,
      payload: res,
    });
  }).catch(() => {
    dispatch({
      type: types.CATEGORY_FILTERS,
      payload: [],
    });
  });
}

export function storeRoomFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_ROOMS_FILTERS,
      payload: data,
    })
  );
}

export function storeCustomerFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_CUSTOMERS_FILTERS,
      payload: data,
    })
  );
}

export function storeServiceFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_SERVICES_FILTERS,
      payload: data,
    })
  );
}

export function storeOrderFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_ORDERS_FILTERS,
      payload: data,
    })
  );
}

export function storeAssignmentFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_ASSIGNMENTS_FILTERS,
      payload: data,
    })
  );
}

export function storeSubscriptionFilters(data) {
  return (dispatch) => (
    dispatch({
      type: types.STORE_SUBSCRIPTION_FILTERS,
      payload: data,
    })
  );
}

export default getFilters;
