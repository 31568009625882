import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import Constants from "../../../shared/Constants";
import {
  MDLabel, Div, MDImage, FormWrapper,
} from "../../../styles/Styles";
import { ContentHeader, Content } from "../Styles";
import Theme from "../../../styles/Theme";
import { getPaymentDetails } from "../../../services/OrdersService";
import Loader from "../../../components/Loader/CardLoader";
import { convertSEK , formatUTCDateToLocal} from "../../../shared/utils";

const TotalTab = ({ orderId, orderData : { assignments = []} = {} }) => {
  const [paymentData, setPaymentData] = useState({});
  const [serviceTotal, setServiceTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const { space } = Theme;
  const { icons, language, input: { format } } = Constants;

  const fetchPaymentDetails = async () => {
    setLoading(true);
    await getPaymentDetails(orderId).then(
      (response) => {
        const { part = [] } = response;
        setPaymentData(response);
        setServiceTotal(part || []);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  return (
    <FormWrapper>
      {loading && <Loader />}
      {!loading && (
        <Div py={3}>
          {serviceTotal && serviceTotal.map((obj, index) => {
            const {
              id,
              add_on: addOn = [], price = 0, duration, service_name: serviceName = "",
              service: { price: servicePrice = 0, duration: serviceDuration = 0 } = {},
            } = obj;
            const assignment = assignments.find((o)=> Number(o?.duration) === serviceDuration && o?.service_name === serviceName);
            const { 
              is_full_day_service = false,
              assignment_start_datetime = "",
              assignment_end_datetime = "",
            } = assignment || {};
            const startDate = moment.utc(assignment_start_datetime).local().format("YYYY-MM-DD");
            const endDate = moment.utc(assignment_end_datetime).local().format("YYYY-MM-DD");
            let noOfDays = 0;

            if(is_full_day_service){
              noOfDays = ((new Date(endDate)?.getTime() - new Date(startDate)?.getTime()) / (1000 * 3600 * 24)) + 1;
            }
            return (
              <Div className="mb-5" key={id}>
                <MDLabel fontSize={space.m} className="mb-4">
                  {`${language.common_part} ${index + 1}: ${serviceName || ""}`}
                </MDLabel>
                <ContentHeader className="mb-2">
                  <FormattedMessage id="label_duration" />
                </ContentHeader>
                <ContentHeader className="mb-2">{is_full_day_service ? `${noOfDays} ${language.days} ${language.total}`: `${duration} ${language.min} ${language.total}`}</ContentHeader>
                {addOn && addOn.length ? (
                  <Content className="mb-4">
                    {`${serviceDuration} ${language.order_min_service} + ${addOn.map((o) => o && o.duration).join(" + ")} ${
                      language.order_min_additional_options
                    }`}
                  </Content>
                ) : (
                  ""
                )}
                <ContentHeader className="mb-2">
                  <FormattedMessage id="common_cost" />
                </ContentHeader>
                <ContentHeader className="mb-2">{`${language.common_sek} ${convertSEK(price)}`}</ContentHeader>
                {addOn && addOn.length ? (
                  <Content className="mb-4">
                    {`${language.common_sek} ${servicePrice} ${language.order_min_service} + ${language.common_sek}
                    ${addOn.map((o) => o && convertSEK(o.price)).join(" + ")} ${language.order_min_additional_options}`}
                  </Content>
                ) : (
                  ""
                )}
              </Div>
            );
          })}
          <Div>
            <MDLabel fontSize={space.m} className="mb-3">
              <FormattedMessage id="orders_payment_total" />
            </MDLabel>
            <ContentHeader className="mb-2">
              <MDImage src={icons.PrimaryTickIcon} alt="icon" />
              &nbsp;
              {`${language.common_sek} ${convertSEK(paymentData.total) || "0"}`}
            </ContentHeader>
            <Content className="mb-4">
              {formatUTCDateToLocal(paymentData.created_at, `${format.twoDigitDateFormat}[, at] ${format.dottedHourFormat}`)}
            </Content>
          </Div>
        </Div>
      )}
    </FormWrapper>
  );
};
TotalTab.propTypes = {
  orderId: PropTypes.string,
  orderData: PropTypes.object,
};
TotalTab.defaultProps = {
  orderId: "",
  orderData: {},
};
export default TotalTab;
