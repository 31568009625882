import styled from "styled-components";
import Theme from "../../styles/Theme";
import { Div, MDLabel } from "../../styles/Styles";

export const SideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 192px;
  background-color: ${Theme.colors.black};
  box-shadow: ${Theme.shadows.light};
  height: 100% !important;
  max-height: 100% !important;
  color: ${Theme.colors.white};
`;

export const MenuContainer = styled.div`
  transition-property: top, bottom, width, transform;
  transition-duration: 0.2s, 0.2s, 0.35s, 0.35s;
  transition-timing-function: linear, linear, ease, ease;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  position: relative;
  display: flex;
  left: 0;
  bottom: 0;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: calc(100% - 145px);
  scrollbar-width: none;
`;

export const MenuItem = styled.li`
  padding: 16px !important;
  display: block;
  height: auto;
  width: auto;
  margin: 0;
  cursor: pointer;
  color: ${Theme.colors.white};
  text-decoration: none;
  background-color: ${({ active }) => (active ? `${Theme.colors.primary}` : `${Theme.colors.black}`)};
`;

export const Item = styled(MDLabel)`
  cursor: pointer;
  text-decoration: none;
`;
export const MenuLabel = styled(Div)`
  align-self: center;
  display: flex;
  cursor: pointer;
`;
