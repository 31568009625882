import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Div, MDLabel, CardHeader } from "../../styles/Styles";
import Modal from "../Modal/Modal";
import SelectTime from "../Input/SelectTime";
import SelectBox from "../Input/SelectBox";
import CustomDatePicker from "../Input/CustomDatePicker";
import Button from "../Button/Button";
import CheckBox from "../Input/CheckBox";
import DaysModel from "./DaysModel";
import { fetchUserServices, setEmptyUserService } from "../../store/actions/AppAction";
import { formatDate } from "../../shared/utils";
import ClockIcon from "../../assets/Icons/ClockIcon.svg";
import CircleTickMark from "../../assets/Icons/CircleTickMark.svg";
import PlusIccon from "../../assets/Icons/PlusIccon.svg";
import Constants from "../../shared/Constants";
import { storeSlotAvailability } from "../../services/availableService";
import { timeIntervals } from "./TimeIntervals";
import CheckBoxAccordian from "../Accordian/CheckBoxAccordian";

export default function AvailabilityModal({
  onClose,
  renderAvailability,
  availabilityModalTitle,
  data,
  serviceProviders,
  onSubmit,
  selectedOrganization,
}) {
  const { date = "" } = data;
  const [selectedDay, setSelectedDay] = useState(formatDate(date || new Date(), "dddd"));
  const [selectServiceProvider, setSelectServiceProvider] = useState("");
  const checkState = useSelector((state) => state.AppReducer.userDetails);
  const { id } = checkState;

  const [availability, setAvailability] = useState({
    sp_id: id,
    startDate: formatDate(date || new Date()),
    endDate: "",
    time: [],
    brake: [],
    is_full_day_available: false,
    daysArray: [],
    services: [],
    key: "",
    brakes: [],
    times: [],
    organization_id: selectedOrganization.value,
  });
  const dispatch = useDispatch();
  const intervals = timeIntervals();
  const [isOverlapMsg, setIsOverlapMsg] = useState(false);
  const collectUserServices = useSelector((state) => state.AppReducer.userService);

  const [userServices, setUserServices] = useState(collectUserServices);

  const fetchUserService = (params) => {
    dispatch(fetchUserServices(params));
  };

  useEffect(() => {
    if(availability?.is_full_day_available){
      setUserServices(collectUserServices.filter((o)=>o.is_full_day_service).map((o)=>({...o,isSelected:false})));
    } else {
      setUserServices(collectUserServices.filter((o)=>!o.is_full_day_service).map((o)=>({...o,isSelected:false})));
    }
  }, [collectUserServices, availability?.is_full_day_available]);

  const [isBreak, setisBreak] = useState(false);
  const [isMore, setisMore] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState("");
  const [timeError, setTimeError] = useState(false);
  const [breakError, setBreakError] = useState(false);
  const [showRepeat, setRepeat] = useState({
    label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />,
    value: "Don't Repeat",
    isSelected: true,
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleValidateForm = (formdata = {}) => {
    const { startDate: formStartDate, endDate: formEndDate, time, daysArray, services } = formdata;

    if (daysArray && daysArray.length === 0) {
      if (formStartDate && time && time[0] && time[1] && services.length) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (formStartDate && formEndDate && time && time[0] && time[1] && services.length && daysArray.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleDateChange = async (name, val) => {
    if (name === "startDate") {
      setSelectedDay(formatDate(val, "dddd"));
    }
    setAvailability({
      ...availability,
      [name]: formatDate(val),
    });
    handleValidateForm({
      ...availability,
      [name]: formatDate(val),
    });
  };
  const handleSubmit = async () => {
    setErrorMessage("");
    try {
      const response = await storeSlotAvailability(availability);
      if (response) {
        renderAvailability();
        onSubmit();
      }
    } catch (e) {
      const { response: { data: { message = "" } = {} } = {} } = e;
      if (e && message) {
        const isUnauthorized = message.includes(Constants.UNAUTHORIZED);
        setErrorMessage(
          isUnauthorized ? Constants.language.access_resticted : Constants.language.slots_available_message
        );
      }
    }
  };
  useEffect(() => {
    if (checkState) {
      const { date: currentDate = "" } = data;
      const startTime = formatDate(currentDate, "HH:mm");
      let endTime = formatDate(currentDate, "HH");
      endTime = String(Number(endTime) + 1);

      if (Number(endTime) < 10) {
        endTime = `0${endTime}`;
      }
      let duration = [];
      if (startTime && startTime !== "Invalid date") {
        duration = [
          { label: startTime, value: startTime },
          { label: `${endTime}:00`, value: `${endTime}:00` },
        ];
        availability.times = duration;
        availability.time = [startTime, `${endTime}:00`];
      }

      const spStartDate = formatDate(currentDate || new Date()) || "";
      const { sp_id: spID } = data || {};

      setAvailability({
        ...availability,
        sp_id: selectedOrganization.access === Constants.MANAGE ? spID : id,
        spStartDate,
      });
    }
  }, [checkState]);
  useEffect(() => {
    if (isBreak) {
      // eslint-disable-next-line max-len
      if (
        (availability.brake?.length > 0 && availability.brake[0][0] === "") ||
        (availability.brake?.length > 0 && availability.brake?.[0][1] === "")
      ) {
        setBreakError(true);
      } else {
        setAvailability({
          ...availability,
          brake: [],
          brakes: [],
        });
        setBreakError(false);
      }
    } else {
      setAvailability({
        ...availability,
        brake: [],
        brakes: [],
      });
      setBreakError(false);
    }
  }, [isBreak]);
  useEffect(async () => {
    if (selectedOrganization.access === Constants.MANAGE) {
      const { sp_id: spID } = data || {};
      await fetchUserService(spID);
    } else if (checkState) {
      const { id: spId } = checkState || {};
      await fetchUserService(spId);
    }
  }, [checkState]);

  const handleRepeat = ({ target }) => {
    setRepeat(target.value);
    let formData = availability;
    if (target.value.value === "everyDay") {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "everyday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "everyday",
      });
    } else if (target.value.value === "Don't Repeat") {
      formData = {
        ...availability,
        daysArray: [],
        key: "",
      };
      setAvailability({
        ...availability,
        daysArray: [],
        key: "",
      });
    } else if (target.value.value === "mon-fri") {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "monday-friday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "monday-friday",
      });
    } else if (target.value.value === "mon-sat") {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      formData = {
        ...availability,
        daysArray: days,
        key: "monday-saturday",
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: "monday-saturday",
      });
    } else if (target.value.value === "everyTuesday") {
      const days = [Constants.WEEK_DAYS[selectedDay.toLowerCase()]];
      formData = {
        ...availability,
        daysArray: days,
        key: `every-${Constants.WEEK_DAYS[selectedDay.toLowerCase()].toLowerCase()}`,
      };
      setAvailability({
        ...availability,
        daysArray: days,
        key: `every-${Constants.WEEK_DAYS[selectedDay.toLowerCase()]}`,
      });
    } else if (target.value.value === "more") {
      setisMore(!isMore);
    }
    handleValidateForm(formData);
  };

  const validateTime = (formdata = {}) => {
    const { time = [] } = formdata;
    let startTime = (time && time[0]) || "";
    let endTime = (time && time[1]) || "";
    if (startTime && endTime) {
      startTime = startTime.split(":")[0] * 60 + startTime.split(":")[1];
      endTime = endTime.split(":")[0] * 60 + endTime.split(":")[1];
      return Number(startTime) >= Number(endTime);
    }
    return false;
  };

  const handleValidateBreaks = (formData) => {
    const { brake: formBrakeData, time } = formData;
    const brakeData = [...formBrakeData];
    const timeData = [...time];
    if (brakeData && brakeData.length > 0) {
      const latestBrake = brakeData[brakeData.length - 1];
      if (latestBrake && latestBrake[0] && latestBrake[1]) {
        const newBrakeStart = latestBrake[0].split(":")[0] * 60 + latestBrake[0].split(":")[1];
        const newBrakeEnd = latestBrake[1].split(":")[0] * 60 + latestBrake[1].split(":")[1];
        const startTime = timeData && timeData[0] && timeData[0].split(":")[0] * 60 + timeData[0].split(":")[1];
        const endTime = timeData && timeData[1] && timeData[1].split(":")[0] * 60 + timeData[1].split(":")[1];
        return !(
          Number(newBrakeStart) >= Number(startTime) &&
          Number(newBrakeStart) < endTime &&
          Number(newBrakeEnd) > Number(newBrakeStart) &&
          Number(newBrakeEnd) > Number(startTime) &&
          Number(newBrakeEnd) <= Number(endTime)
        );
      }
      return false;
    }
    return false;
  };

  const handleTimeChange = ({ target }) => {
    const { name = "", value = {} } = target;
    setErrorMessage("");
    let formdata;
    if (name === "startTime") {
      const { time, times: availabilityTimes } = availability;
      availabilityTimes[0] = value || "";
      time[0] = value.value || "";
      formdata = {
        ...availability,
        time,
        availabilityTimes,
      };
      setAvailability({
        ...availability,
        time,
        availabilityTimes,
      });
    } else {
      const { time, times: availabilityTimes } = availability;
      availabilityTimes[1] = value || "";
      time[1] = value.value || "";
      formdata = {
        ...availability,
        time,
        availabilityTimes,
      };
      setAvailability({
        ...availability,
        time,
        availabilityTimes,
      });
    }
    setTimeError(validateTime(formdata));
    setIsOverlapMsg(handleValidateBreaks(formdata));
    handleValidateForm(formdata);
  };

  const handleAddBreak = () => {
    if (!isBreak) {
      setAvailability({
        ...availability,
        brake: [["", ""]],
        brakes: [["", ""]],
      });
    }
    setIsOverlapMsg(false);
    setisBreak(!isBreak);
  };

  const handleBrakeChange = ({ target }, index) => {
    setIsOverlapMsg(false);
    setErrorMessage("");
    setBreakError(false);
    let formdata;
    if (target.name === "startTime") {
      const { brake: availabilityBrake, brakes: availabilityBrakes } = availability;
      availabilityBrakes[index][0] = target.value;
      availabilityBrake[index][0] = target.value && target.value.value;
      formdata = {
        ...availability,
        availabilityBrake,
        availabilityBrakes,
      };
      setAvailability({
        ...availability,
        availabilityBrake,
        availabilityBrakes,
      });
    } else {
      const { brake: availabilityBrake, brakes: availabilityBrakes } = availability;
      availabilityBrakes[index][1] = target.value;
      availabilityBrake[index][1] = target.value && target.value.value;
      formdata = {
        ...availability,
        availabilityBrake,
        availabilityBrakes,
      };
      setAvailability({
        ...availability,
        availabilityBrake,
        availabilityBrakes,
      });
    }
    setIsOverlapMsg(handleValidateBreaks(formdata));
    handleValidateForm(formdata);
  };
  const handleCloseAvailability = () => {
    setisMore(false);
  };
  const handleRenderAvailability = () => {
    setisMore(!isMore);
  };
  const openDaysMOdal = () => {
    setisMore(true);
  };
  const selectionMap = (value) => {
    setAvailability({
      ...availability,
      services: value,
    });
    handleValidateForm({
      ...availability,
      services: value,
    });
  };

  const handleSelect = (value) => {
    selectionMap(
      value
        .filter((selectedService) => selectedService.isSelected)
        .map((service) => ({
          service_id: service.id,
          serviceable_dog_size: service.serviceable_dog_size,
        }))
    );
  }

  const addBreakeFiled = () => {
    const { brake: availabilityBrake, brakes: availabilityBrakes = "" } = availability;
    setAvailability({
      ...availability,
      brake: [...availabilityBrake, ...[["", ""]]],
      brakes: [...availabilityBrakes, ...[["", ""]]],
    });
    handleValidateForm({
      ...availability,
      brake: [...availabilityBrake, ...[["", ""]]],
      brakes: [...availabilityBrakes, ...[["", ""]]],
    });
  };

  const handleSPChange = (e) => {
    const { id: spID = "" } = e.target.value;
    setEmptyUserService();
    setSelectServiceProvider(e.target.value);
    setAvailability({ ...availability, sp_id: spID });
    if (selectedOrganization.access === Constants.MANAGE) {
      fetchUserService(spID);
    }
  };

  const { startDate, endDate, brake, brakes, times } = availability;

  let isAddBreak = false;
  brake.forEach((num) => {
    if (!(num && num[0] && num[1])) {
      isAddBreak = true;
    }
  });

  return (
    <Modal className="availability-form">
      <Div width={["350px", "500px"]} px={[3, 0]}>
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id={availabilityModalTitle} defaultMessage="Add Availability" />
          </MDLabel>

          {isMore && (
            <DaysModel
              onClose={handleCloseAvailability}
              renderAvailability={handleRenderAvailability}
              onClick={openDaysMOdal}
              availabilityab={availability}
              setAvailability={setAvailability}
            />
          )}
        </CardHeader>
        <MDLabel justifyContent="center" fontSize="14px" display="flex" my={3}>
          {selectedOrganization && selectedOrganization.label}
        </MDLabel>
        <Div>
          {selectedOrganization.access === Constants.MANAGE && (
            <Div display={["block", "block", "flex"]} justifyContent="flex-start" alignItems="center" mx={["5px", 40]}>
              <Div className="col-3" p={0} mb={[10, 10, 0]}>
                <MDLabel fontSize="20px" mr={15}>
                  <FormattedMessage id="label_sp" defaultMessage="SP" />:
                </MDLabel>
              </Div>
              <SelectBox
                value={selectServiceProvider}
                onChange={handleSPChange}
                name="service_providers"
                options={
                  serviceProviders
                    ? serviceProviders.map((sp) => ({
                      label: sp.full_name,
                      value: sp.full_name,
                      isSelected: false,
                      id: sp.id,
                      data: sp,
                    }))
                    : { label: "Choose", value: "Choose", isSelected: false }
                }
                placeholder="Choose"
                width={["100%", "100%", 255]}
                height="40px"
              />
            </Div>
          )}
          <hr />
          <Div display="flex" justifyContent="flex-start" alignItems={["center", "center", "start"]} mx={["5px", 40]}>
            <Div className="col-3" p={0}>
              <MDLabel fontSize="18px">
                <FormattedMessage id="label_date" defaultMessage="Date:" />:
              </MDLabel>
            </Div>
            <Div className="col-9"  mt="-16px" p={0}>
              <CustomDatePicker
                value={startDate}
                disabled={false}
                name="startDate"
                onChange={handleDateChange}
                maxDate=""
                width={[230, 255]}
                minDate=""
              />
            </Div>
          </Div>
          <hr />
          <Div display={["block", "block", "flex"]} className="row" mx={["5px", 40]} my={0}>
            <Div display="flex" className="col-3" p={0}>
              <MDLabel fontSize="18px" mr={35} mb={[10, 10, 0]}>
                <FormattedMessage id="label_time" defaultMessage="Time:" />:
              </MDLabel>
            </Div>
            <Div display="flex" flexDirection={["unset", "unset", "row"]} className="col-9" p={0}>
              <MDLabel
                display={["block", "flex"]}
                width="100%"
                textAlign="left"
                fontSize="16px"
                color="darkgray"
                mr={15}
                ml={0}
                my={[1, 0]}
                fontWeight="normal">
                <FormattedMessage id="label_from" defaultMessage="From:" />:
              </MDLabel>
              <SelectTime
                value={times && times[0]}
                name="startTime"
                options={intervals}
                placeholder="--:--"
                width={100}
                endIcon={ClockIcon}
                onChange={handleTimeChange}
                IconHeight="20px"
              />
              <MDLabel
                display={["block", "flex"]}
                width="100%"
                textAlign="left"
                fontSize="16px"
                color="darkgray"
                ml={15}
                mr={15}
                my={[1, 0]}
                fontWeight="normal">
                <FormattedMessage id="label_to" defaultMessage="To:" />:
              </MDLabel>
              <SelectTime
                name="endTime"
                value={times && times[1]}
                options={intervals}
                placeholder="--:--"
                width={100}
                endIcon={ClockIcon}
                onChange={handleTimeChange}
                IconHeight="20px"
              />
            </Div>
            {timeError && (
              <Div display="flex" justifyContent="center" my={3}>
                <MDLabel color="red">
                  <FormattedMessage
                    id="error_time_start_end"
                    defaultMessage="Start time shouldn't be greater than end time."
                  />
                </MDLabel>
              </Div>
            )}
            <Div display="flex" flexDirection={["unset", "unset", "row"]} className="offset-lg-3" p={0} width={1}>
              <Div width={[1, 1, 1, 1 / 2]} mt={20}>
                <CheckBox
                  isSelected={isBreak}
                  size="14px"
                  disabled={timeError}
                  onClick={handleAddBreak}
                  label={<FormattedMessage id="label_set_break" defaultMessage="Set Break" />}
                  name="isBreak"
                />
              </Div>
            </Div>
          </Div>

          {isBreak && (
            <Div display={["block", "block", "flex"]} mx={["5px", 40]} mt={20} p={0}>
              <Div className="col-3" p={0} mb={[10, 10, 0]}>
                <MDLabel fontSize="18px" alignSelf="flex-start" mt={3}>
                  <FormattedMessage id="label_break" defaultMessage="Break:" />:
                </MDLabel>
              </Div>
              <Div className="row col-9" p={0}>
                {brake && brake?.length
                  ? brake.map((el, i) => (
                    <Div display="flex" flexDirection={["unset", "unset", "row"]} className="col-12" mt={i > 0 ? 20 : 0} key={`el-${[i]}`}>
                      <MDLabel
                        display={["block", "flex"]}
                        width="100%"
                        textAlign="left"
                        fontSize="16px"
                        color="darkgray"
                        mr={15}
                        ml={0}
                        my={[1, 0]}
                        fontWeight="normal">
                        <FormattedMessage id="label_from" defaultMessage="From:" />:
                      </MDLabel>
                      <SelectTime
                        name="startTime"
                        value={brakes[i][0] || ""}
                        options={intervals}
                        placeholder="--:--"
                        width={100}
                        onChange={(e) => handleBrakeChange(e, i)}
                        endIcon={ClockIcon}
                        IconHeight="20px"
                      />
                      <MDLabel
                        display={["block", "flex"]}
                        width="100%"
                        textAlign="left"
                        fontSize="16px"
                        color="darkgray"
                        ml={15}
                        mr={15}
                        my={[1, 0]}
                        fontWeight="normal">
                        <FormattedMessage id="label_to" defaultMessage="To:" />:
                      </MDLabel>
                      <SelectTime
                        name="endTime"
                        value={brakes[i][1] || ""}
                        options={intervals}
                        placeholder="--:--"
                        width={100}
                        onChange={(e) => handleBrakeChange(e, i)}
                        endIcon={ClockIcon}
                        IconHeight="20px"
                      />
                    </Div>
                  ))
                  : ""}
                {isOverlapMsg && (
                  <MDLabel
                    fontSize="12px"
                    className="col-12"
                    alignSelf="flex-end"
                    justify-content="flex-end"
                    color="red"
                    mt={1}>
                    <FormattedMessage
                      id="label_brake_overlapping"
                      defaultMessage="Brake is overlapping, Please change the brake time"
                    />
                  </MDLabel>
                )}
                <Div display="flex" className="col-12"  mt={20}>
                  <Button
                    className="break-btn"
                    startIcon={PlusIccon}
                    label={<FormattedMessage id="label_one_more_break" defaultMessage="One more break" />}
                    width="150px"
                    onClick={addBreakeFiled}
                    fontSize="14px"
                    disabled={isAddBreak || isOverlapMsg}
                  />
                </Div>
              </Div>
            </Div>
          )}
          <hr />
          {!!userServices && (
            <Div  display={["block", "block", "flex"]} mt={20} p={0} mx={["5px", 40]}>
              <Div className="col-3" p={0} mb={[16, 16, 0]}>
                <MDLabel fontSize="18px" mr={35}>
                  <FormattedMessage id="label_services" defaultMessage="Service:" />:
                </MDLabel>
              </Div>
              <Div className="flex-col col-lg-9 m-0" p={0}>
                {userServices.length > 0 && (
                  <CheckBoxAccordian handleOnChange={handleSelect} services={userServices} editAvailabilty isEdit />
                )}
              </Div>
            </Div>
          )}
          <hr />

          <Div display="flex" alignItems="center" mt={20} p={0} mx={["5px", 40]}>
            <Div className="col-3" p={0}>
              <MDLabel fontSize="18px" mr={35}>
                <FormattedMessage id="label_repeat" defaultMessage="Repeat:" />:
              </MDLabel>
            </Div>
            <Div className="col-9" pl={["25px", "25px", 0, 0]} pr={0}>
              <SelectBox
                value={showRepeat}
                onChange={handleRepeat}
                name="repeat"
                options={[
                  {
                    label: <FormattedMessage id="label_dont_repeat" defaultMessage="Dont't Repeat" />,
                    value: "Don't Repeat",
                    isSelected: false,
                  },
                  {
                    label: <FormattedMessage id="label_everyday" defaultMessage="Everyday" />,
                    value: "everyDay",
                    isSelected: false,
                  },
                  {
                    label: <FormattedMessage id="label_monday_friday" defaultMessage="Monday-Friday" />,
                    value: "mon-fri",
                    isSelected: false,
                  },
                  {
                    label: <FormattedMessage id="label_monday_saturday" defaultMessage="Monday-Saturday" />,
                    value: "mon-sat",
                    isSelected: false,
                  },
                  {
                    label: `${Constants.language.label_every} ${Constants.translations[selectedDay]}`,
                    value: "everyTuesday",
                    isSelected: false,
                  },
                  {
                    label: <FormattedMessage id="label_more" defaultMessage="More..." />,
                    value: "more",
                    isSelected: false,
                  },
                ]}
                placeholder={<FormattedMessage id="placeholder_choose" defaultMessage="Choose" />}
                width={["100%", "100%", 255, 255]}
              />
            </Div>
          </Div>
          <Div
            display="flex"
            alignItems="center"
            p={0}
            disable={showRepeat && showRepeat.value === "Don't Repeat"}
            mx={["5px", 40]}>
            <Div className="col-3" p={0}>
              <MDLabel textAlign="left" fontSize="18px" mt={3}>
                <FormattedMessage id="label_end_date" defaultMessage="End Date" />:
              </MDLabel>
            </Div>
            <Div className="col-9" pl={["25px", "25px", 0, 0]} pr={0}>
              <CustomDatePicker
                value={endDate}
                name="endDate"
                onChange={handleDateChange}
                maxDate=""
                width={["85%", "85%", 255, 255]}
                disabled={showRepeat && showRepeat.value === "Don't Repeat"}
                minDate={(startDate && new Date(startDate)) || ""}
              />
            </Div>
          </Div>
          <hr />
          {showErrorMessage && (
            <Div display="flex" justifyContent="center" my={3}>
              <MDLabel color="red">{showErrorMessage}</MDLabel>
            </Div>
          )}
          <Div display="flex" mb={16} flexDirection={["column-reverse", "row"]} justifyContent="center" mx={[0, 0, 40]}>
            <Div mr={[0, 4]} ml={[1, 0]} my={[1, 0]}>
              <Button
                label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
                secondary
                width={[1, 160]}
                onClick={onClose}
              />
            </Div>
            <Div m={[1, 0]} mb={[15, 0 ,0]}>
              <Button
                label={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
                width={[1, 160]}
                onClick={handleSubmit}
                startIcon={CircleTickMark}
                disabled={
                  isOverlapMsg ||
                  isDisabled ||
                  timeError ||
                  breakError ||
                  (selectedOrganization.access === Constants.MANAGE && !selectServiceProvider)
                }
                my={[1, 0]}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}
AvailabilityModal.propTypes = {
  /**
   * Pass children innto the modal
   */
  onClose: PropTypes.func,
  renderAvailability: PropTypes.func,
  availabilityModalTitle: PropTypes.string,
  data: PropTypes.node,
  serviceProviders: PropTypes.node,
  onSubmit: PropTypes.func,
  selectedOrganization: PropTypes.object,
};
AvailabilityModal.defaultProps = {
  onClose: undefined,
  renderAvailability: undefined,
  availabilityModalTitle: undefined,
  data: {},
  serviceProviders: [],
  onSubmit: () => {},
  selectedOrganization: {},
};
