import React from "react";
import PropTypes from "prop-types";
import Colors from "../../styles/Colors";
import {
  InputBox, Div, MDImage, MDLabel, Span, MDInputValue,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import { addHyphenToNumber } from "../../shared/utils";
import { InputContainer } from "./Styles";

const TextBox = ({
  currency,
  startIcon,
  endIcon,
  onEndIconClick,
  IconHeight,
  formikValues = {},
  name,
  label,
  type,
  id,
  minLength,
  maxLength,
  disabled,
  value,
  placeholder = "",
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  width,
  onClick,
  login,
  containerProps,
  mandatoryLabel,
  superScript,
  showError,
  isTouched,
  extraLabel,
  ...props
}) => {
  /**
   * Handles on key press event
   * @param {Event} evt
   */
  const handleKeypress = (evt) => {
    const theEvent = evt || window.event;
    const phoneNames = ["phone0", "phone1", "phone2", "phone3"];
    const { value: eventValue, name: eventName } = theEvent.target;
    if (
      eventName === Constants.input.name.id
      && ((eventValue.replace(/-/g, "")).length) >= Constants.PersonalNumberLength - 1
    ) {
      theEvent.preventDefault();
      if (onChange) {
        onChange({
          target: {
            name: [eventName],
            value: addHyphenToNumber(eventValue, 6),
          },
        });
      }
    } else if (eventValue.length >= Constants.phoneNumberLength
       && eventName.includes(phoneNames)) {
      theEvent.preventDefault();
    }
  };

  const { errors = {}, touched = {} } = formikValues;
  const displayError = (errors[name] && touched[name]) || (showError && isTouched);
  return (
    <InputContainer width={width} className={containerProps} disable={disabled}>
      <Div className={(label || mandatoryLabel) ? "pb-2 pt-3" : ""}>
        {(label || mandatoryLabel) && (
          <>
            <MDLabel fontSize="16px" display={superScript ? "flex" : "initial"}>
              {label}
              {superScript && (
                <Span>
                  <sup>{Constants.number.two}</sup>
                </Span>
              )}
              {extraLabel && (
                <Span display="inline-flex" pl={5} ml={3}>
                  <MDInputValue pl={3} placeholder>
                    {extraLabel}
                  </MDInputValue>
                </Span>
              )}
            </MDLabel>
            {mandatoryLabel && (
              <MDLabel fontSize="16px" display="initial" float="right">
                {mandatoryLabel}
              </MDLabel>
            )}

          </>
        )}
        {!mandatoryLabel && !login && displayError && (
          <MDLabel float="right" color={Colors.Red}>
            {errors[name] || showError}
          </MDLabel>
        )}
        {!mandatoryLabel && login && displayError && (
          <Div>
            <MDLabel float="right" color={Colors.Red} pt={1}>
              {errors[name]}
            </MDLabel>
          </Div>
        )}
      </Div>
      {mandatoryLabel && displayError && (
        <Div>
          <MDLabel float="right" color={Colors.Red} my={1}>
            {errors[name] || showError}
          </MDLabel>
        </Div>
      )}
      <Div
        border="1px solid"
        borderColor={displayError ? Colors.Red : Colors.Border}
        display="flex"
        borderRadius={5}
        props={props}
        className={className}
        onClick={onClick}
      >
        {startIcon && (
          <Div className="col-auto pl-2 pr-0" alignSelf="center">
            <MDImage src={startIcon} alt="icon" width="20px" height={IconHeight} />
          </Div>
        )}
        {currency && (
          <Div className="col-auto pl-2 pr-0" alignSelf="center">
            {currency}
          </Div>
        )}
        <Div className="col p-0" width="inherit">
          <InputBox
            name={name}
            type={type}
            id={id}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress || handleKeypress}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
          />
        </Div>
        {endIcon && (
          <Div className="col-auto pl-0 pr-2" alignSelf="center">
            <MDImage src={endIcon} alt="icon" width="20px" height={IconHeight} onClick={onEndIconClick} />
          </Div>
        )}
      </Div>
    </InputContainer>
  );
};
TextBox.propTypes = {
  /**
   * Label of the input field
   */
  label: PropTypes.string,
  /**
   * value of the input field
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Which type of input
   */
  type: PropTypes.string,
  /**
   * Id of the input
   */
  id: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Do input field be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Optional onClick handler
   */
  onClick: PropTypes.func,
  /**
   * Calls when value is changed
   */
  onChange: PropTypes.func,
  /**
   * Calls when input field is touched
   */
  onFocus: PropTypes.func,
  /**
   * Calls when focus out from input
   */
  onBlur: PropTypes.func,
  /**
   * What should be as placeholder..
   */
  placeholder: PropTypes.string,
  /**
   * Calls on key press
   */
  onKeyPress: PropTypes.func,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  /**
   * Url of the startIcon
   */
  startIcon: PropTypes.string,
  /**
   * url of the end icon
   */
  endIcon: PropTypes.string,
  /**
   * Calls on end icon click
  */
  onEndIconClick: PropTypes.func,
  /**
   * Height of the icon
   */
  IconHeight: PropTypes.string,
  /**
   * Do the input contain any minLength of letters..
   */
  minLength: PropTypes.number,
  /**
   * Do the input has any maximum no of letters..
   */
  maxLength: PropTypes.number,
  /**
   * Customise the styles of the input
   */
  className: PropTypes.string,
  /**
   * Width of the input box
   */
  width: PropTypes.string,
  /**
   * ClassName applies for whole division
   */
  containerProps: PropTypes.string,
  /**
   * Is login page?
   */
  login: PropTypes.bool,
  mandatoryLabel: PropTypes.string,
  superScript: PropTypes.bool,
  showError: PropTypes.string,
  extraLabel: PropTypes.string,
  isTouched: PropTypes.bool,
  phone: PropTypes.bool,
  currency: PropTypes.string
};
TextBox.defaultProps = {
  name: null,
  type: null,
  id: null,
  value: null,
  login: false,
  formikValues: {},
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onKeyPress: undefined,
  placeholder: null,
  disabled: false,
  label: null,
  width: "auto",
  className: null,
  maxLength: undefined,
  minLength: undefined,
  IconHeight: "auto",
  endIcon: null,
  onEndIconClick: null,
  startIcon: null,
  onClick: undefined,
  containerProps: null,
  mandatoryLabel: null,
  superScript: false,
  showError: null,
  extraLabel: null,
  isTouched: false,
  phone: false,
  currency: ""
};
export default TextBox;
