import * as types from "../actions/types";

const initialState = {
  roomFilters: [],
  customerFilters: [],
  organizationFilters: [],
  serviceFilters: [],
  userFilters: [],
  categoryFilters: [],
  allServices: [],
  serviceProviderFilters: [],
  organizationsFilters: [],
  orderServicesFilters: [],
  roomFilterValues: {},
  customerFilterValues: {},
  serviceFilterValues: {},
  orderFilterValues: {},
  assignmentFilterValues: {},
  subscriptionFilterValues: {}
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
  case types.FILTERS: {
    const allServices = payload[0] || [];
    const categories = payload[1] || [];
    return {
      ...state,
      allServices,
      categoryFilters: categories,
    };
  }
  case types.ROOMS_FILTERS: {
    return { ...state, roomFilters: payload };
  }
  case types.USERS_FILTERS: {
    return { ...state, userFilters: payload };
  }
  case types.CATEGORY_FILTERS: {
    return { ...state, categoryFilters: payload };
  }
  case types.SERVICES_FILTERS: {
    return { ...state, serviceFilters: payload };
  }
  case types.ORDERS_SERVICES_FILTERS: {
    return { ...state, orderServicesFilters: payload };
  }
  case types.ORGANIZATION_FILTERS: {
    return { ...state, organizationsFilters: payload };
  }
  
  case types.CUSTOMERS_FILTERS: {
    return { ...state, customerFilters: payload };
  }
  case types.STORE_ROOMS_FILTERS: {
    return {...state, roomFilterValues: payload}
  }
  case types.STORE_CUSTOMERS_FILTERS: {
    return {...state, customerFilterValues: payload}
  }
  case types.STORE_SERVICES_FILTERS: {
    return {...state, serviceFilterValues: payload}
  }
  case types.STORE_ORDERS_FILTERS: {
    return {...state, orderFilterValues: payload}
  }
  case types.STORE_ASSIGNMENTS_FILTERS: {
    return {...state, assignmentFilterValues: payload}
  }
  case types.STORE_SUBSCRIPTION_FILTERS: {
    return {...state, subscriptionFilterValues: payload}
  }
  default:
    return { ...state };
  }
};
