import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Proptypes from "prop-types";

const ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
}

ScrollToTop.propTypes = {
  history: Proptypes.node,
  children: Proptypes.node,
};

ScrollToTop.defaultProps = {
  history: {},
  children: {},
};

export default withRouter(ScrollToTop);
