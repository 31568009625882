import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from "../../components/Containers/PortalContainer";
import AssignmentForm from "./Components/AssignmentForm";
import Constants from "../../shared/Constants";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import { FormWrapper } from "../../styles/Styles";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import Config from "../../config";

const { language } = Constants;

const AddAssignment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authService = new AuthService(Config.apiBase);
  const getLoginUser = (payload) => dispatch(loggedInUser(payload));
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [assignmentCreated, setassignmentCreated] = useState(false);

  /* Scroll to top when navigated from calendar screen */
  useEffect(() => {
    const location = history.location || {};
    const state = location.state || {};
    const sourcePage = state.fromPage || "";
    if (sourcePage === Constants.input.name.calendar) {
      window.scrollTo(0, 0);
    }
    return "";
  }, []);

  const getDetails = () => {
    try {
      authService.usersDetails().then((response) => {
        getLoginUser(response);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBackToAssignments = () => {
    getDetails();
    setassignmentCreated(false);
    setAlertMessage("");
    setAlertHeader("");
    history.push(Constants.routes.assignmentsList.url);
  };

  const handleAlertModal = (isSaved = false) => {
    if (isSaved) {
      getDetails();
      setassignmentCreated(true);
      setAlertMessage(language.alert_assignment_created);
    } else {
      setassignmentCreated(true);
      setAlertMessage(language.alert_try_later);
      setAlertHeader(language.header_error);
    }
  };

  const redirectToDetailPage = (passId = "", type = "") => () => {
    switch (type) {
    case "place":
      return history.push(`/admin/organization/${passId}/edit-organization`);
    case "room":
      return history.push(`/admin/rooms/${passId}/edit-room`);
    case "client":
      return history.push(`/admin/customers/${passId}/edit-customer`);
    case "service":
      return history.push(`/admin/services/${passId}/edit-service`);
    case "serviceProvider":
      return history.push(`/admin/users/${passId}/edit-user`);
    default:
      return "";
    }
  };

  return (
    <Container>
      <FormWrapper>
        <CommonAddPage
          handleBack={handleBackToAssignments}
          header={Constants.language.assignment_add_new_assignment}
          breadCrumbLabel="breadcrumb_back"
        />
        <AssignmentForm
          handleBackToAssignments={handleAlertModal}
          redirectToDetailPage={redirectToDetailPage}
        />
      </FormWrapper>
      {assignmentCreated && (
        <Modal>
          <Card
            header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
            message={alertMessage}
            alertCard
            buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
            onClick={handleBackToAssignments}
          />
        </Modal>
      )}
    </Container>
  );
};

export default AddAssignment;
