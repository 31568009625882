import styled from "styled-components";
import { Button } from "../../styles/Styles";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";

export const ButtonBase = styled(Button)`
  font-size: ${({ size }) => (size === "small" ? "12px" : size === "large" ? "16px" : "14px")};
  padding: ${({ size, padding }) => (padding || (size === "small"
    ? "4px 8px"
    : size === "large"
      ? "13px 50px"
      : "9.5px 30px"))};
  border-radius: ${({ size }) => (size === "small" ? "20px" : "100px")};
  cursor: ${({ cursor }) => cursor || "pointer"};
  outline: none;
  color: ${({ secondary }) => (secondary ? `${Colors.Primary}` : `${Colors.White}`)};
  background-color: ${({ secondary, backgroundColor }) => backgroundColor || (secondary ? `${Colors.Transparent}` : `${Colors.Primary}`)};
  border: 2px solid ${Colors.Primary};
  border-color: ${({ backgroundColor }) => backgroundColor || `${Colors.Primary}`};
  opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
  &:active,
  &:focus,
  &:hover {
    outline: none;
    color: ${({ secondary }) => (secondary ? `${Colors.LightGreen}` : `${Colors.White}`)};
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
    border-color: ${({ backgroundColor }) => backgroundColor || `${Colors.LightGreen}`};
    background-color: ${({ secondary, backgroundColor }) => backgroundColor || (secondary ? `${Colors.Transparent}` : `${Colors.LightGreen}`)};
  }
`;

ButtonBase.defaultProps = {
  width: Theme.widths.auto,
  fontFamily: Theme.fonts.semibold,
  fontWeight: Theme.fontWeights.medium,
  fontStyle: "normal",
  fontSize: Theme.fontSizes.small,
  display: "inline-block",
  lineHeight: "normal",
  textAlign: "center",
  alignSelf: "center",
  boxShadow: Theme.shadows.light,
  color: Theme.colors.white,
  bg: Theme.colors.primary,
};
export default ButtonBase;
