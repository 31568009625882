import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Division } from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import Constants from "../../../shared/Constants";
import Colors from "../../../styles/Colors";
import { Box, Div, IsDesktop, IsMobile, MDImage, MDLabel } from "../../../styles/Styles";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { SubscriptionBookingFlowActions } from "../../../store/reducers/SubscriptionBookingFlowReducer";
import { Span } from "../../MyAccounts/Styles";
import { IconButton } from "../../../components/Button/Button.stories";
const { icons, themes, language } = Constants;
import WhiteEmptyFlag from "../../../assets/Icons/WhiteEmptyFlag.svg";

const SelectedSubscriptionDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [services, setServices] = useState([]);
  const selectedSubscription = useSelector((state) => state.SubscriptionBookingFlowReducer.selectedSubscription);
  useEffect(() => {
    if (selectedSubscription && selectedSubscription.details) {
      const detail = JSON.parse(selectedSubscription.details);
      setDetails(detail);
      const serviceList = detail.services_included;
      setServices(serviceList);
    }
  }, [selectedSubscription]);
  const [tabOpened, setTabOpened] = useState(false);

  const handleTabOpening = () => {
    setTabOpened(!tabOpened);
  };

  const servicesIncluded =
    services &&
    services.map((service) => {
      return (
        <Div mb={1} width={themes.widths.w255} height="40px" display="flex" alignItems="center">
          <MDImage src={icons.YellowPoint} />
          <Div ml={2}>
            <Span ml={2} fontSize={themes.fontSizes.small}>
              {service}
            </Span>
          </Div>
        </Div>
      );
    });

  const handleAnotherPackage = () => {
    dispatch(SubscriptionBookingFlowActions.previousStep());
    history.push(Constants.routes.chooseSubscription.url);
  };

  const renderDays = selectedSubscription.description && (
    <Div
      px={2}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      background={Colors.Blue}
      borderRadius={10}
      pb={1}
      width="160px">
      <Div>
        <MDImage src={icons.CalendarSelected} width="13px" height="21px" />
      </Div>
      <Div ml={1} mt={1}>
        <Span fontSize={themes.fontSizes.small}>{selectedSubscription.description}</Span>
      </Div>
    </Div>
  );

  const renderPickupReturn = details.pickup_return && (
    <Div
      px={2}
      mt={3}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      background={Colors.Blue}
      borderRadius={10}
      width="200px"
      pb={1}>
      <Div>
        <MDImage src={icons.CalendarSelected} width="13px" height="21px" />
      </Div>
      <Div ml={1} mt={1}>
        <Span ml={1} fontSize={themes.fontSizes.small}>
          {`${language.pickup_return}: ${details.pickup_return}`}
        </Span>
      </Div>
    </Div>
  );

  const renderAll = (isMobile) => {
    return (
      <Division
        mt={2}
        px={[0,2]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column">
        <Div width="93%" mt={3} boxShadow={themes.shadows.light} borderRadius={themes.radii.medium}>
          <Div
            boxShadow={themes.shadows.light}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="10px"
            height="60px">
            <Div>
              <Box pl={3}>
                <Box
                  background={selectedSubscription.iconColor}
                  borderRadius="50%"
                  width="35px"
                  height="35px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  <MDImage width="11px" height="20px" src={WhiteEmptyFlag} alt="icon" />
                </Box>
                <MDLabel ml={2} fontSize={themes.fontSizes.medium}>
                  {selectedSubscription.name}
                </MDLabel>
              </Box>
            </Div>
            <Div>
              <Box>
                {!isMobile && (
                  <MDLabel mx={2} fontSize={themes.fontSizes.medium}>
                    {`${language.currency_sek} ${selectedSubscription.price?.large}/${language.month_short}`}
                  </MDLabel>
                )}

                <Div
                  background={Colors.LightBlue}
                  borderRadius="10px"
                  height="60px"
                  width="40px"
                  cursor="pointer"
                  onClick={handleTabOpening}
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  <MDImage src={tabOpened ? icons.UpArrow : icons.ArrowDown} width={"14px"} height={"20px"} />
                </Div>
              </Box>
            </Div>
          </Div>
          {tabOpened && (
            <Div
              pt={4}
              pl={4}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              boxShadow={themes.shadows.light}
              borderRadius={3}>
              {servicesIncluded && (
                <Div mb={4}>
                  <MDLabel fontSize={themes.fontSizes.large}>
                    <FormattedMessage id="services_included" defaultMessage="Services Included" /> :
                  </MDLabel>
                </Div>
              )}
              <IsDesktop>
                <Box>
                  <Div>
                    {renderDays}
                    {renderPickupReturn}
                  </Div>

                  <Div
                    pl={4}
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="flex-start">
                    {servicesIncluded}
                  </Div>
                </Box>
              </IsDesktop>
              <IsMobile>
                <Box display="flex" flexDirection="column">
                  <Div>
                    {renderDays}
                    {renderPickupReturn}
                  </Div>

                  <Div
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="flex-start">
                    {servicesIncluded}
                  </Div>
                </Box>
              </IsMobile>
              <Div pb={2} pt={4} display="flex" justifyContent="flex-start">
                <IconButton
                  label={language.another_package}
                  onClick={handleAnotherPackage}
                  startIcon={icons.WhiteEditIcon}
                  primary
                />
              </Div>
            </Div>
          )}
        </Div>
      </Division>
    );
  };

  return (
    <Division>
      <IsMobile>{renderAll(true)}</IsMobile>
      <IsDesktop>{renderAll(false)}</IsDesktop>
    </Division>
  );
};

export default memo(SelectedSubscriptionDetail);
