import produce from "immer";
import moment from "moment";
import * as types from "../actions/types";
import Constants from "../../shared/Constants";
import { fetchAvailabilityLocalDateConvertedJson } from "../../shared/utils";

const { input: { format } } = Constants;

export const initialState = {
  token: null,
  userData: {},
  getCategoriesList: [],
  filteredService: [],
  getDogDetails: [],
  getOrganisationDetails: [],
  organisationSchedule: [],
  organisationServiceProviders: null,
  organisationServiceProvidersService: {},
  dateFormatted: [],
  selectedServiceContainer: {
    selectedServices: {},
    dogSizes: {},
    selectedLocation: {},
    selectedProvider: {},
    selectedDate: null,
    selectedAddOns: [],
  },
  steps: 1,
  isUserLogged: false,
  firstSelectService: [],
  calenderDate: moment(new Date()).format("yyyy-MM-DD"),
  passToCheckout: false,
  setError: null,
  cartData: [],
  calenderStartEnd: {
    startDate: moment(new Date()).format("yyyy-MM-DD"),
    // endDate: moment(new Date() + 30).format('yyyy-MM-DD'),
    endDate: moment(new Date()).format("yyyy-MM-DD"),
  },
  cartCount: 0,
  bannedPopup: false,
  selectCartId: -1,
  getAvailableDates: [],
  isLoading: false,
  availabilityErrorPopup: false,
  emailErrorPopup: false,
};

const addSelectedService = (data, content) => {
  const store = data;
  store.selectedServiceContainer.selectedServices = content;
  store.filteredService = store.getCategoriesList.filter((item) => item.id === content.categoryId);
  store.firstSelectService = store.filteredService[0]?.services?.filter(
    (item) => item.name === content.serviceName,
  );
  store.selectedServiceContainer.selectedDate = null;
  store.calenderDate = null;
  store.steps = 2;
};

const emptyOtherChoosenValues = (data, type)=>{
  const store = data;
  switch(type) {
  case "service":
    store.selectedServiceContainer = {...store.selectedServiceContainer,dogSizes: {}, selectedLocation: {}, selectedProvider: {},selectedAddOns: [], selectedDate: moment(new Date()).format(format.date)};
    break;
  case "dog":
    store.selectedServiceContainer = {...store.selectedServiceContainer,selectedLocation: {}, selectedProvider: {},selectedAddOns: [], selectedDate: moment(new Date()).format(format.date) };
    break;
  case "location":
    store.selectedServiceContainer = {...store.selectedServiceContainer,selectedProvider: {},selectedAddOns: [], selectedDate: moment(new Date()).format(format.date)};
    break;
  case "date":
    store.selectedServiceContainer = {...store.selectedServiceContainer,selectedAddOns: []};
    break;
  default:
    store.selectedServiceContainer = {...store.selectedServiceContainer};
  }
};

const setAddonToCart = (data, content) => {
  const store = data;
  const { item = {}, checked= false } = content;
  const toDelete = item.name;
  if (checked === true) {
    store.selectedServiceContainer.selectedAddOns = [
      ...store.selectedServiceContainer.selectedAddOns,
      ...[item],
    ];
  } else if (checked === false) {
    const allAddons = store.selectedServiceContainer.selectedAddOns;
    store.selectedServiceContainer.selectedAddOns = allAddons.filter((x) => x.name !== toDelete);
    store.selectedServiceContainer.selectedAddOns = [
      ...store.selectedServiceContainer.selectedAddOns,
    ];
  }
};

const setGuestCartItems = (store, startState) => {
  const data = store;
  let guestCart = [];
  const oldCart = JSON.parse(sessionStorage.getItem("cart")) || [];
  const items = store.selectedServiceContainer;
  const updatedCartItem = data.selectCartId !== -1
    ? { ...items, selectCartId: data.selectCartId }
    : { ...items, selectCartId: oldCart ? oldCart.length : 0 };
  if (data.selectCartId !== -1) {
    guestCart = oldCart.map((obj) => (
      obj.selectCartId === data.selectCartId ? updatedCartItem : obj));
  } else if (oldCart) {
    guestCart = [...oldCart, updatedCartItem];
  } else {
    guestCart = [...guestCart, updatedCartItem];
  }
  sessionStorage.setItem("cart", JSON.stringify(guestCart));
  data.cartCount = guestCart.length || 0;
  return startState;
};

const setGuestCheckoutItems = (store, startState) => {
  const data = store;
  let guestCart = [];
  const oldCart = JSON.parse(sessionStorage.getItem("cart")) || [];
  const items = {
    ...store.selectedServiceContainer,
    ...store.filteredService,
    selectCartId: store.selectCartId !== -1 ? store.selectCartId : oldCart ? oldCart.length : 0,
  };
  if (store.selectCartId !== -1) {
    guestCart = oldCart.map((obj) => (
      obj.selectCartId === store.selectCartId ? items : obj));
  } else if (oldCart) {
    guestCart = [...oldCart, items];
  } else {
    guestCart = [...guestCart, items];
  }
  sessionStorage.setItem("cart", JSON.stringify(guestCart));
  data.passToCheckout = true;
  data.cartCount = guestCart.length || 0;
  return startState;
};

const setStepAndContent = (store = {}, payload = {}) => {
  const { data: payloadData = {}, step } = payload;
  const data = store;
  data.selectedServiceContainer = payloadData;
  data.steps = step;
  data.selectCartId = payloadData.selectCartId;
  const { selectedServices = {} } = payloadData;

  data.filteredService = store.getCategoriesList.filter(
    (item) => item.id === selectedServices.categoryId,
  );
  data.firstSelectService = store.filteredService[0]?.services?.filter(
    (item) => item.name === selectedServices.serviceName,
  );
};

const BookingFlowServicesReducer = (state = initialState, action) => produce(state, (draft) => {
  const data = draft;
  const { type, payload } = action;
  switch (type) {
  case types.GET_CATEGORIES_LIST:
    data.token = !!localStorage.getItem("token");
    data.userData = !(localStorage.userData && Object.keys(localStorage.userData).length === 0);
    data.isUserLogged = !!localStorage.getItem("token")
          && !(localStorage.userData && Object.keys(localStorage.userData).length === 0);
    data.getCategoriesList = payload.data;
    break;
  case types.GET_DOG_DETAILS:
    data.getDogDetails = payload.data;
    break;
  case types.CHOOSE_SERVICE:
    addSelectedService(data, payload);
    break;
  case types.SELECTED_DOG_SIZE:
    data.selectedServiceContainer.dogSizes = payload;
    break;
  case types.EMPTY_ADDONS_TO_STORE:
    data.selectedServiceContainer.selectedAddOns = [];
    break;
  case types.SELECTED_SERVICE_LOCATION:
    data.selectedServiceContainer.selectedLocation = payload;
    break;
  case types.SELECTED_SERVICE_PROVIDER:
    data.selectedServiceContainer.selectedProvider = payload;
    break;
  case types.SELECTED_BOOKING_DATE:
    data.selectedServiceContainer.selectedDate = Array.isArray(payload)
      ? [payload[0], payload[1] || payload[0]]
      : payload;
    data.calenderDate = data.selectedServiceContainer.selectedDate;
    break;
  case types.SELECT_BOOKING_DATE:
    data.selectedServiceContainer.selectedDate = payload;
    data.selectedServiceContainer.selectedProvider = {};
    data.calenderDate = data.selectedServiceContainer.selectedDate;
    break;
  case types.SELECTED_BOOKING_MONTH:
    data.calenderStartEnd = payload;
    break;
  case types.GO_TO_SELECTED_SERVICES:
    data.steps = payload;
    break;
  case types.BANNED_POPUP:
    data.bannedPopup = payload;
    break;
  case types.GO_TO_NEXT_STEP:
    data.steps = 2;
    break;
  case types.SELECTED_NEXT_STEP:
    data.steps += 1;
    break;
  case types.SELECTED_PREVIOUS_STEP:
    data.steps -= 1;
    break;
  case types.SET_ORGANISATION_RECORD:
    data.getOrganisationDetails = payload;
    break;
  case types.GET_AVAILABLE_DATES:
    data.getAvailableDates = payload;
    data.isLoading = false;
    break;
  case types.SET_LOADING:
    data.isLoading = true;
    break;
  case types.SET_ORGANISATION_SCHEDULE:
    data.organisationSchedule = payload.res?.data;
    data.dateFormatted = payload.dateFormatted;
    break;
  case types.SET_ORGANISATION_PROVIDERS:
    data.organisationServiceProviders = payload;
    break;
  case types.SET_ORGANISATION_PROVIDERS_SERVICE: {
    const formattedData = payload?.service_provider?.map(
      (o) => ({
        ...o,
        available_slots: o?.available_slots?.map(
          (obj) => (fetchAvailabilityLocalDateConvertedJson(obj)),
        ),
      }),
    );
    data.organisationServiceProvidersService = {
      ...payload,
      end_time: moment().endOf("day").local()
        .format("HH:mm"),
      service_provider: formattedData,
    };
    break;
  }
  case types.ADDONS_TO_STORE:
    setAddonToCart(data, payload);
    break;
  case types.ADD_TO_GUEST_CART:
    setGuestCartItems(data, initialState);
    break;
  case types.ADD_TO_GUEST_CHECKOUT:
    setGuestCheckoutItems(data, initialState);
    break;
  case types.SET_FLAG:
    data.passToCheckout = action.payload;
    break;
  case types.SET_ERROR:
    data.setError = payload;
    break;
  case types.SET_STEP_AND_CONTENT:
    setStepAndContent(data, payload);
    break;
  case types.EMPTY_CHOOSEN_VALUES:
    emptyOtherChoosenValues(data,payload);
    break;
  case types.SET_USER_CART_DATA:
    data.cartData = action.payload;
    data.cartCount = action.payload.length || 0;
    break;
  case types.RESET_BOOKING_FLOW:
    data.selectedServiceContainer = {
      selectedServices: {},
      dogSizes: {},
      selectedLocation: {},
      selectedProvider: {},
      selectedDate: moment(new Date()).format(format.date),
      selectedAddOns: [],
    };
    data.intialLoading = false;
    data.selectCartId = -1;
    data.steps = 1;
    break;
  case types.CART_COUNT:
    data.cartCount = action.payload;
    break;
  case type.SET_SELECTED_CART_ID:
    data.selectCartId = action.payload;
    break;
  case types.AVAILABILITY_ERROR_POPUP:
    data.availabilityErrorPopup = payload;
    break;
  case types.EMAIL_ERROR_POPUP:
    data.emailErrorPopup = payload;
    break;
  default:
    return state;
  }
  return data;
});

export default BookingFlowServicesReducer;
