import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import OrderSummaryTotal from "./Components/OrderSummaryTotal";
import HeadSection from "./Components/HeadSection";
import TotalPriceContainer from "./Components/TotalPriceContainer";
import PaymentButtons from "./Components/PaymentButtons";
import CardContainer from "./Components/CardContainer";
import Theme from "../../styles/Theme";
import { Div, MDLabel, Box } from "../../styles/Styles";
import * as payAction from "../../store/actions/PaymentAction";
import { Division } from "../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import Constants from "../../shared/Constants";
import {
  isLoggedIn,
  mapDogSizeToDuration,
  mapDogSizeToPrice,
  mapSizeToDog,
} from "../../shared/utils";
import BannedModal from "../Cart/components/BannedModal";
import * as bookingAction from "../../store/actions/BookingFlowServicesAction";

const {
  icons,
} = Constants;

const Payment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    totalRate,
    // totalcharge,
    cartContent: paymentData = [],
  } = useSelector((state) => state.PaymentReducer);
  const rawHTML = useSelector((state) => state.PaymentReducer.snippetResponse?.html_snippet);
  const selectedProvider = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedProvider,
  );
  const selectedServiceContainer = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer,
  );
  const { cartData, bannedPopup } = useSelector(
    (state) => state.BookingFlowServicesReducer,
  );
  const handleBannedPopClick = () => {
    dispatch(bookingAction.bannedPopup(!bannedPopup));
  };
  const [disableBtn, setDisableBtn] = useState(false);
  const pushPaymentHandler = () => {
    setDisableBtn(true);
    dispatch(payAction.requestPaymentAction());
  };

  function renderSnippet(htmlSnippet) {
    const checkoutContainer = document.getElementById("my-checkout-container");
    checkoutContainer.innerHTML = htmlSnippet;
    const scriptsTags = checkoutContainer.getElementsByTagName("script");
    for (let i = 0; i < scriptsTags.length; i += 1) {
      const { parentNode } = scriptsTags[i];
      const newScriptTag = document.createElement("script");
      newScriptTag.type = "text/javascript";
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }

  useEffect(() => {
    const location = history.location || {};
    const state = location.state || {};
    const sourcePage = state.fromPage || "";
    setDisableBtn(false);
    if (sourcePage === "cart") {
      window.scrollTo(0, 0);
      if (isLoggedIn()) {
        if (cartData.length > 0) {
          const obj = cartData.map((cart) => {
            const {
              organization_id: organization = {},
              service_id: selectedServices = {},
              dog_id: dog = {},
              service_provider_id: cartSelectedProvider = {},
              add_on: addOns = [],
              assignment_start_datetime: assignmentStartDatetime,
              // price: cartPrice,
              slot_id: slotId,
            } = cart;
            const scheduledDateTime = assignmentStartDatetime.split(" ");
            const scheduledTime = scheduledDateTime[1].split(":").slice(0, 2).join(":");

            let caluculateTotal = 0;
            addOns.map((x) => {
              caluculateTotal += Number(x.price || 0);
              return x;
            });
            return {
              service_title: selectedServices.name,
              service_category: selectedServices.categoryName || "",
              total: Number(caluculateTotal)
              + Number(mapDogSizeToPrice(dog.height, selectedServices)),
              service: {
                name: selectedServices.name,
                type: "service",
                bnr: selectedServices.banner || selectedServices?.profile_pic?.url || icons.PawIcon,
                tagIcon: icons.TagIcon,
                serviceId: selectedServices.id,
                ...selectedServices,
              },
              service_location: {
                title: organization.name,
                bnr: organization?.profile_pic?.url || icons.Avatar,
                area: organization.address,
                locationIcon: icons.ServiceLocation,
                location: organization.location || {},
                organisationId: organization.id,
                ...organization,
              },
              service_detail: {
                title: dog.name,
                size: dog.height,
                time: mapDogSizeToDuration(dog.height, selectedServices),
                tagRate: mapDogSizeToPrice(dog.height, selectedServices),
                dogSizes: dog?.profile_pic?.url || icons.PawIcon,
                iconOne: icons.ClockIcon,
                iconTwo: icons.TagIcon,
                age: mapSizeToDog(dog.dogWeight),
                ...dog,
              },
              service_provider: {
                provider: cartSelectedProvider.full_name,
                info: organization.address,
                face: cartSelectedProvider?.profile_pic?.url || icons.Avatar,
                location: icons.ServiceLocation,
                serviceProviderId: cartSelectedProvider.id,
                slotId,
                ...cartSelectedProvider,
              },
              service_schedule: {
                scheduled_on: scheduledDateTime[0],
                scheduled_at: scheduledTime,
              },
              additional_service: addOns,
            };
          });
          dispatch(payAction.loadCartContent(obj));
        } else {
          history.push(Constants.routes.cart.url);
        }
      }
    } else if (isLoggedIn()) {
      const {
        selectedServices = {},
        selectedLocation = {},
        selectedAddOns = {},
        selectedDate = "",
        dogSizes = {},
      } = selectedServiceContainer;

      if (Object.keys(selectedServices).length > 0
        && Object.keys(selectedLocation).length > 0
        && Object.keys(dogSizes).length > 0
        && selectedDate !== ""
      ) {
        let caluculateTotal = 0;
        selectedAddOns.map((x) => {
          caluculateTotal += Number(x.price || 0);
          return x;
        });
        const obj = [
          {
            service_title: selectedServices.serviceName,
            service_category: selectedServices.categoryName,
            total: Number(caluculateTotal) + Number(dogSizes.price),
            service: {
              name: selectedServices.serviceName,
              type: "service",
              bnr: selectedServices.banner || icons.PawIcon,
              tagIcon: icons.TagIcon,
              ...selectedServices,
            },
            service_location: {
              title: selectedLocation.organisationName,
              bnr: selectedLocation.profilePic || icons.Avatar,
              area: selectedLocation.address,
              locationIcon: icons.ServiceLocation,
              location: selectedLocation.location || {},
              ...selectedLocation,
            },
            service_detail: {
              title: dogSizes.dogName,
              size: dogSizes.dogWeight,
              time: `${dogSizes.duration} min`,
              tagRate: dogSizes.price,
              dogSizes: icons.PawIcon,
              iconOne: icons.ClockIcon,
              iconTwo: icons.TagIcon,
              age: mapSizeToDog(dogSizes.dogWeight),
              ...dogSizes,
            },
            service_provider: {
              provider: selectedProvider.providerName,
              info: Constants.language.common_service_provider,
              face: selectedProvider.providerFace || icons.Avatar,
              location: icons.UserSettingsIcon,
              ...selectedProvider,
            },
            service_schedule: {
              scheduled_on: selectedDate,
              scheduled_at: selectedProvider.serviceTime,
            },
            additional_service: selectedAddOns,
          },
        ];
        dispatch(payAction.loadCartContent(obj));
      } else {
        history.push(Constants.routes.bookingFlow.url);
      }
    }
    if (!isLoggedIn()) {
      const cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];

      if (cart?.length > 0) {
        const obj = cart && cart?.map((c) => {
          const {
            selectedServices = {},
            selectedLocation = {},
            selectedAddOns = {},
            selectedDate = "",
            dogSizes = {},
            selectedProvider: cartSelectedProvider = {},
          } = c;
          let caluculateTotal = 0;
          selectedAddOns.map((x) => {
            caluculateTotal += Number(x.price || 0);
            return x;
          });
          return {
            service_title: selectedServices.serviceName,
            service_category: selectedServices.categoryName,
            total: Number(caluculateTotal) + Number(dogSizes.price),
            service: {
              name: selectedServices.serviceName,
              type: "service",
              bnr: selectedServices.banner || icons.PawIcon,
              tagIcon: icons.TagIcon,
              ...selectedServices,
            },
            service_location: {
              title: selectedLocation.organisationName,
              bnr: selectedLocation.profilePic || icons.Avatar,
              area: selectedLocation.address,
              locationIcon: icons.ServiceLocation,
              location: selectedLocation.location || {},
              ...selectedLocation,
            },
            service_detail: {
              title: dogSizes.dogName,
              size: dogSizes.dogWeight,
              time: dogSizes.duration,
              tagRate: dogSizes.price,
              dogSizes: icons.PawIcon,
              iconOne: icons.ClockIcon,
              iconTwo: icons.TagIcon,
              age: mapSizeToDog(dogSizes.dogWeight),
              ...dogSizes,
            },
            service_provider: {
              provider: cartSelectedProvider.providerName,
              info: Constants.language.common_service_provider,
              face: cartSelectedProvider.providerFace || icons.Avatar,
              location: icons.UserSettingsIcon,
              ...cartSelectedProvider,
            },
            service_schedule: {
              scheduled_on: selectedDate,
              scheduled_at: cartSelectedProvider.serviceTime,
            },
            additional_service: selectedAddOns,
          };
        });
        dispatch(payAction.loadCartContent(obj));
        if (obj.length <= 0) {
          history.push(Constants.routes.bookingFlow.url);
        }
      } else {
        history.push(Constants.routes.bookingFlow.url);
      }
    }
    return history.listen(() => {
      if (history.action === "POP") {
        history.push(Constants.routes.cart.url);
      }
    });
  }, []);

  useEffect(() => {
    if (rawHTML) {
      renderSnippet(rawHTML);
      setDisableBtn(false);
    }
  }, [rawHTML]);
  return (
    <>
      <Div className="container" px={2}>
        <>
          <HeadSection headerTitle="Payment" />
          <CardContainer cardTitle={<FormattedMessage id="label_details" defaultMessage="Details" />}>
            <Box mt={4}>
              <MDLabel fontSize={Theme.fontSizes.xl}>
                <FormattedMessage id="summary_of_your_order" defaultMessage="Summary of your order" />
              </MDLabel>
            </Box>
            {paymentData && paymentData?.map((item, index) => (
              <Div key={item.id} className="m-auto">
                <OrderSummaryTotal item={item} index={index} />
              </Div>
            ))}
            <TotalPriceContainer
              totalRate={Number(totalRate)}
              paymentData={paymentData}
            />
            <PaymentButtons pushHandler={pushPaymentHandler} disabled={disableBtn} />
            <Division id={rawHTML ? "my-checkout-container" : ""} />
          </CardContainer>
        </>
        {bannedPopup
          && <BannedModal onClickModal={handleBannedPopClick} />}
      </Div>
    </>
  );
};

export default Payment;
