import styled from "styled-components";

export const DragAndDrop = styled.input`
  display: none;
`;
export const UploadImage = styled.div`
  display: flex;
`;
export const Label = styled.label`
  display: flex;
  justify-content: center;
  margin: 0px;
`;
