import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateServiceForm from "./Components/CreateServiceForm";
import Constants from "../../shared/Constants";
import ServiceModel from "../../models/Service";
import Service from "../../services/ServicesPageService";
import Config from "../../config";
import CommonAddPage from "../Common/CommonBreadCrumbSection";
import { FormWrapper } from "../../styles/Styles";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";

const { language } = Constants;

class AddService extends Component {
  service = new Service(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serviceCreated: false,
      alertHeader: "",
      alertMessage: "",
    };
  }

  handleSubmitData=async (data) => {
    this.setState({ loading: true });
    try {
      this.serviceModel = new ServiceModel({});
      const response = await this.service.addService(this.serviceModel.toJson(data));
      if (response) {
        this.setState({ loading: false });
        this.handleBackToServices();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleAlertModal=(isSaved = false) => {
    if (isSaved) {
      this.setState({ serviceCreated: true, alertMessage: language.alert_service_created });
    } else {
      this.setState({
        serviceCreated: true,
        alertHeader: language.header_error,
        alertMessage: language.alert_try_later,
      });
    }
  }

  handleBackToServices = () => {
    this.setState({ serviceCreated: false, alertHeader: "", alertMessage: "" });
    const { history } = this.props;
    history.push(Constants.routes.servicesList.url);
  };

  render() {
    const {
      loading, serviceCreated, alertHeader, alertMessage,
    } = this.state;
    return (
      <Container>
        {loading && <FormWrapper><FormLoader /></FormWrapper>}

        {!loading && (
          <FormWrapper>
            <CommonAddPage
              handleBack={this.handleBackToServices}
              header={Constants.language.service_add_new_service}
              description="service_add_service_description"
              breadCrumbLabel="breadcrumb_back"
            />
            <CreateServiceForm
              submitData={this.handleSubmitData}
              handleBackToServices={this.handleAlertModal}
            />
          </FormWrapper>
        )}
        {serviceCreated && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="Ok" />}
              onClick={this.handleBackToServices}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
AddService.propTypes = {
  history: PropTypes.node,
};
AddService.defaultProps = {
  history: undefined,
};
export default AddService;
