import styled from "styled-components";
import { Div, Span } from "../../styles/Styles";
import Theme from "../../styles/Theme";

export const MobileDiv = styled(Div)`
  width: ${({ type }) => (type === "cart" ? "85%" : "")};
  display: ${({ type }) => (type === "editdiv" ? "none" : "")};
  @media (max-width: 570px) {
    width: ${({ type }) => (type === "cart" ? "100%" : "")};
    padding: ${({ type }) => (type === "cart" ? "0 10px" : "")};
  }
`;

export const EditBox = styled(Span)`
  display: none;
`;

export const SpanElement = styled(Span)`
  position: ${({ type }) => (type === "services" ? "relative" : "")};
  margin-left: ${({ type }) => (type === "services" ? "16px" : "")};
  @media (max-width: 570px) {
    display: ${({ type }) => (type === "wrapservices" ? "flex" : "")};
    width: ${({ newWidth }) => (newWidth === "responsive" ? "100%" : "")};
  }

  &:hover ${EditBox} {
    display: block;
    position: absolute;
    z-index: 3;
    width: 100%;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }
  &:hover:after {
    content: '';
    position: ${({ type }) => (type === "services" ? "absolute" : "")};
    top: ${({ type }) => (type === "services" ? 0 : "")};
    left: ${({ type }) => (type === "services" ? 0 : "")};
    background: ${Theme.colors.primary};
    opacity: ${({ type }) => (type === "services" ? 0.9 : "")};
    height: ${({ type }) => (type === "services" ? "100%" : "")};
    width: ${({ type }) => (type === "services" ? "100%" : "")};
    border-radius: ${({ type }) => (type === "services" ? "10px" : "")};
    cursor: ${({ type }) => (type === "services" ? "pointer" : "inherit")};
  }
`;
