import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Colors from "../../../styles/Colors";
import Theme from "../../../styles/Theme";
import { MDInputBox } from "../Style";
import Constants from "../../../shared/Constants";
import {
  BoxDivision, Division, LinkItem, SpanBox,
} from "../../BookingFlow/BookingFlowStyles/BookingFlowStyles";
import { Div, MDImage, Span } from "../../../styles/Styles";

const { icons } = Constants;

const Accordion = (props) => {
  const {
    index, cardTitle, categoryValue, children, categoryLabel,
  } = props;
  const currentPage = useSelector((state) => state.PaymentReducer.pageStep);
  const history = useHistory();
  const handlePush = (path) => () => {
    history.push(path);
  };
  const [activeIndex, setActiveIndex] = useState(true);
  const handleAccordion = () => {
    setActiveIndex(!activeIndex);
  };
  return (
    <Div mb={3} pb={1}>
      <BoxDivision
        type="accordion"
        height={60}
        mainType={currentPage === index && activeIndex ? "active" : ""}
        background={Theme.colors.text.white}
        boxShadow={Theme.shadows.normal}
        pl={3}
      >
        <Span
          alignItems="center"
          alignSelf="center"
          fontFamily={Theme.fonts.semibold}
          fontSize={Theme.fontSizes.regular}
        >
          {currentPage > index ? (
            <MDImage src={Constants.icons.DoneIcon} alt="icon" height={40} width={40} alignSelf="center" mr={3} />
          ) : (
            <SpanBox
              type="step_Number"
              lineHeight={2.5}
              width={40}
              height={40}
              mr={3}
              background={Colors.Blue}
              fontFamily={Theme.fonts.semibold}
              fontWeight={Theme.fontWeights.medium}
              borderRadius={Theme.radii.medium}
              isDone={currentPage > index}
              className={currentPage === index ? "active" : null}
            >
              {index + 1}
            </SpanBox>
          )}

          {cardTitle}
        </Span>
        <BoxDivision>
          {categoryValue && (
            <MDInputBox type="labelmain" fontSize={Theme.fontSizes.small} pr={3}>
              <Span pr={2}>
                <FormattedMessage id="common_category" defaultMessage={categoryLabel} />
              </Span>
              <LinkItem type="categories" mr={3} onClick={handlePush(Constants.routes.bookingFlow.url)}>
                <FormattedMessage id="category_value" defaultMessage={categoryValue} />
              </LinkItem>
            </MDInputBox>
          )}
          <BoxDivision>
            <Span
              bg={Colors.Blue}
              width={40}
              textAlign="center"
              lineHeight="60px"
              borderRadius={Theme.radii.medium}
              opacity="0.5"
              onClick={handleAccordion}
              useCursorPointer
            >
              <MDImage src={currentPage === index && activeIndex === true ? icons.UpArrow : icons.ArrowDown} alt="icon" />
            </Span>
          </BoxDivision>
        </BoxDivision>
      </BoxDivision>
      <Division
        background={Colors.White}
        className={currentPage === index && activeIndex ? "active" : ""}
        type="active"
        mainActive
        boxShadow={Theme.shadows.normal}
      >
        <BoxDivision mainType="content_part" type="place_Content">{children}</BoxDivision>
      </Division>
    </Div>
  );
};

Accordion.propTypes = {
  cardTitle: PropTypes.string,
  categoryLabel: PropTypes.string,
  categoryValue: PropTypes.node,
  children: PropTypes.node,
  index: PropTypes.number,
};
Accordion.defaultProps = {
  cardTitle: "Loading...",
  categoryLabel: "Category",
  categoryValue: "",
  children: {},
  index: "",
};
export default Accordion;
