import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PlusIcon from "../../../assets/Icons/PlusIccon.svg";
import Button from "../../../components/Button/Button";
import FilterButton from "../../../components/Filter/FilterButton";
import ListScreen from "../../../components/ListScreen/ListScreen";
import MainLoader from "../../../components/Loader/MainLoader";
import Pagination from "../../../components/Pagination/Pagination";
import Tabs from "../../../components/Tabs/Tabs";
import { getSubscriptionsList } from "../../../services/SubscriptionService";
import Constants from "../../../shared/Constants";
import { checkRightPermission, getUserInfo, getSubscriptionFilters } from "../../../shared/utils";
import { getAvailableOrganizations, getOrdersServicesFilters } from "../../../store/actions/FilterAction";
import { Div, MDLabel } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import Routes from "../../../shared/Routes";

const {
  subscriptionPackage,
  packageTranslations,
  filter: { components, options: optionType },
  sorting: { type: sortType },
  language,
  themes: { widths },
  adminRoutes,
  SUBSCRIPTION_SORT_FIELDS
} = Constants;

const header = [
  {
    className: "col-3",
    value: language.list_header_customer,
    key: "customer",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.list_header_type,
    key: "product",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.label_dog,
    key: "dog",
    icon: sortType.ALPHABETS,
  },
  {
    className: "col",
    value: language.label_height,
    key: "dog_size",
    icon: sortType.ARROW,
  },
  {
    className: "col",
    value: language.filter_label_status,
    key: "application_status",
    icon: sortType.ARROW,
  },
  {
    className: "col-2",
    value: language.list_header_organisations,
    key: "organizations",
    icon: sortType.ALPHABETS,
  },
];

const ColumnFilters = [
  {
    id: "dog_size",
    component: components.CHECKBOX,
    label: language.label_height,
    values: optionType.DOG_HEIGHT,
    defaultValues: [],
    availableValues: [],
    name: "dog_size",
  },
  {
    id: "application_status",
    component: components.CHECKBOX,
    label: language.filter_info_status,
    values: optionType.INFO_STATUS,
    defaultValues: [],
    availableValues: [],
    name: "application_status",
  },
];

const Columns = [
  {
    className: "col-3",
    key: "customer",
    type: "singleUser",
  },
  {
    className: "col",
    key: "product",
    type: "packages",
  },
  {
    className: "col",
    key: "dog",
    type: "singleUser",
  },
  {
    className: "col",
    key: "dog",
    type: "dogHeight",
  },
  {
    className: "col",
    key: "application_status",
    type: "applicationStatus",
  },
  {
    className: "col-2",
    key: "organization",
    type: "singleUser",
    isOrg: true,
  },
];

const SubscriptionTab = ({ userId, currentPath }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const organizationsFilters = useSelector((state) => state.FilterReducer.organizationsFilters);
  const orderServicesFilters = useSelector((state) => state.FilterReducer.orderServicesFilters);
  const userDetails = useSelector((state) => state.AppReducer.userDetails);
  const { permissions = [] } = getUserInfo(userDetails);
  const hasWriteAccess = checkRightPermission(permissions, "is_subscription_editable", Constants.WRITE);

  const getOrganizationsFilters = () => dispatch(getAvailableOrganizations());
  const getServicesFilters = () => dispatch(getOrdersServicesFilters());

  const [selectedTab, setSelectedTab] = useState(language.label_application);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortedBy, setSortedBy] = useState("created_at");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState(ColumnFilters);
  const [subscriptions, setSubscriptions] = useState();
  const [loading, setLoading] = useState(true);
  const [toBeFilteredData, setToBeFilteredData] = useState({});

  const tabs = [language.label_application, language.label_active, language.label_stop];

  const tabByStatus = {
    [language.label_application]: "open",
    [language.label_active]: "active",
    [language.label_stop]: "canceled",
  };

  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 10,
    pages: 1,
  });

  const getOrganisationFilter = async () => {
    if (!organizationsFilters.length) {
      await getOrganizationsFilters();
    }
  };

  const getServiceFilter = async () => {
    if (!orderServicesFilters.length) {
      await getServicesFilters();
    }
  };

  const getOrdersService = () => {
    const packageType = [];

    const makeProductFilter = (item) => {
      const bronzePackage = packageType.find((product) => product.label.includes(subscriptionPackage.bronze));

      if (!bronzePackage && item?.label?.toLowerCase().includes(subscriptionPackage.bronze.toLowerCase())) {
        packageType.push({
          label: packageTranslations.bronze,
          value: item.value,
          className: "col-4",
        });
      }
      if (bronzePackage && item.label.includes(subscriptionPackage.bronze)) {
        bronzePackage.value = `${bronzePackage.value}, ${item.value}`;
      }
      if (item.label.toLowerCase().includes(subscriptionPackage.silver.toLowerCase())) {
        packageType.push({
          label: packageTranslations.silver,
          value: item.value,
          className: "col-4",
        });
      }
      if (item.label.toLowerCase().includes(subscriptionPackage.gold.toLowerCase())) {
        packageType.push({
          label: packageTranslations.gold,
          value: item.value,
          className: "col-4",
        });
      }
      if (item.label.toLowerCase().includes(subscriptionPackage.custom.toLowerCase())) {
        packageType.push({
          label: packageTranslations.custom,
          value: item.value,
          className: "col-4",
        });
      }
    };

    orderServicesFilters.forEach(makeProductFilter);
    return packageType;
  };

  const fetchFilters = async () => {
    await Promise.all([getOrganisationFilter(), getServiceFilter()]);
    const filter = filterOptions.filter((item) => !["organization_id"].includes(item.id));
    const isOrdersServiceFilter = filter.find((item) => item.id === "product_id");
    const isOrganizationIdInFilter = filter.find((item) => item.id === "organization_id");
    const approvedOrganizations = organizationsFilters.filter(({ value }) => {
      const orgs = permissions.find((org) => org.organization_id === value);
      return orgs?.is_subscription_editable >= 0;
    });
    if (
      !isOrdersServiceFilter &&
      orderServicesFilters.length > 0 &&
      !isOrganizationIdInFilter &&
      approvedOrganizations.length >= 0
    ) {
      const newFilters = [
        {
          id: "product_id",
          component: components.CHECKBOX,
          label: language.list_header_type,
          values: getOrdersService(),
          defaultValues: [],
          name: "product_id",
        },
        {
          id: "subscription_days",
          defaultValues: [],
          component: components.CHECKBOX,
          label: language.days_choose,
          values: optionType.WEEK_DAYS,
          name: "subscription_days",
        },
        ...filter,
        {
          id: "organization_id",
          defaultValues: [],
          component: components.CHECKBOX,
          label: language.list_header_organisations,
          values: approvedOrganizations.map((item) => {
            return { ...item, className: "col-12" };
          }),
          name: "organization_id",
        },
      ];
      setFilterOptions(newFilters);
    }
    if (selectedTab === language.label_application) {
      const filteredOptions = filter.filter((item) => item.id !== "organization_id");
      const itHasApplicationStatus = filter.find((item) => item.id === "application_status");
      if (!itHasApplicationStatus && !isOrganizationIdInFilter && approvedOrganizations.length >= 0) {
        const applicationStatus = ColumnFilters.find((item) => item.id === "application_status");
        setFilterOptions([
          ...filteredOptions,
          applicationStatus,
          {
            id: "organization_id",
            defaultValues: [],
            component: components.CHECKBOX,
            label: language.list_header_organisations,
            values: approvedOrganizations.map((item) => {
              return { ...item, className: "col-12" };
            }),
            name: "organization_id",
          },
        ]);
      }
    }
    if (selectedTab === language.label_active || selectedTab === language.label_stop) {
      const isOrganizationIdInFilter = filter.find((item) => item.id === "organization_id");
      const filteredOptions = filter.filter((item) => item.id !== "application_status");
      const approvedOrganizations = organizationsFilters.filter(({ value }) => {
        const orgs = permissions.find((org) => org.organization_id === value);
        return orgs?.is_subscription_editable >= 0;
      });
      // When it has more then one organization filter will be shown again
      if (!isOrganizationIdInFilter && approvedOrganizations.length >= 0) {
        const newFilters = [
          ...filteredOptions,
          {
            id: "organization_id",
            defaultValues: [],
            component: components.CHECKBOX,
            label: language.list_header_organisations,
            values: approvedOrganizations.map((item) => {
              return { ...item, className: "col-12" };
            }),
            name: "organization_id",
          },
        ];
        setFilterOptions(newFilters);
      } else {
        setFilterOptions(filteredOptions);
      }
    }
  };

  useEffect(() => {
    fetchFilters();
  }, [selectedTab, orderServicesFilters.length, organizationsFilters.length]);
  /**
   *
   * @async
   * @function fetchSubscriptions Fetches subscriptions
   *
   */
  const fetchSubscriptions = (filters = undefined) => {
    setLoading(true);
    const filter = filters || toBeFilteredData;
    // It will show subscription whose organization has READ/WRITE permissions
    if (!filter["organization_id"] && permissions.length) {
      const orgs = permissions.filter((org) => org.is_subscription_editable !== -1);
      filter["organization_id"] = orgs.map((org) => org.organization_id);
    }

    const sortOrder = sortAscending ? sortType.ASC : sortType.DESC;
    const queryParams = [
      ["where[customer_id]", userId],
      ["page", meta.pageNumber],
      ["pageSize", meta.pageSize],
      ["sortBy", sortedBy],
      ["sort", sortOrder],
      ["filter", getSubscriptionFilters(filter)],
      ["status", tabByStatus[selectedTab]],
      ...(sortedBy === "customer"
        ? [
          ["orderBy[customers.firstname]", sortOrder],
          ["orderBy[customers.lastname]", sortOrder],
        ]
        : [["orderBy", `${SUBSCRIPTION_SORT_FIELDS[sortedBy]},${sortOrder}`]]),
    ];
    getSubscriptionsList(queryParams)
      .then(
        (response) => {
          const { meta: srcMeta = {}, data = [] } = response;
          const responseData = data.map((subscription) => ({
            ...subscription,
            type: subscription?.dog?.breed || "",
          }));
          const { current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount } = srcMeta;
          const newMeta = {
            pageNumber: currentPage,
            pageSize: perPage,
            pages,
            totalCount,
          };
          setSubscriptions(responseData);
          setMeta(newMeta);
          setLoading(false);
        },
        (error) => {
          console.error(error); // Error!
          setLoading(false);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePagination = (pageNumber) => {
    if (pageNumber !== meta.pageNumber) {
      setMeta((prevMeta) => {
        return { ...prevMeta, pageNumber };
      });
    }
  };

  useEffect(() => {
    if (selectedTab && meta?.pageNumber && permissions.length > 0) {
      fetchSubscriptions();
    }
  }, [selectedTab, meta?.pageNumber, permissions.length, sortedBy, sortAscending]);

  const handleTabs = (tab) => {
    setToBeFilteredData({});
    setFilterOptions((previousFilter) => previousFilter.map((item) => ({ ...item, defaultValues: [] })));
    setSelectedTab(tab);
  };

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterApply = async (filterValues = [], options = undefined) => {
    const filters = {};
    filterValues.forEach(({ name, value }) => {
      if (filters[name]) {
        filters[name].push(value);
      } else {
        filters[name] = [];
        filters[name].push(value);
      }
    });
    setToBeFilteredData(filters);
    setFilterOptions(options);
    setIsFilterOpen(false);
    fetchSubscriptions(filters);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSorting = (name) => () => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };
  /**
   *
   * Redirects to detailed view page
   * @param {object} item subscription data
   * @returns to selected subscription's detailed page
   *
   */

  const { location: { search = ""} } = history;
  const params = new URLSearchParams(search);
  const pageNo = params.get("page");
   
  const handleDetailPage = (item) => () => {
    //Redirect to subscription details page
    history.push({
      pathname:`${adminRoutes.adminPrefix}/${adminRoutes.subscription}/${item.id}/${adminRoutes.editSubscription}`,
      state:`${currentPath}?isSubscription=true${pageNo && `&page=${pageNo}`}`});
  };

  const handleMoveTabs = (id) => {
    setSelectedTab(tabs[id]);
  };

  const handleAddNew = () => {
    history.push({
      pathname:Routes.adminAddSubscriptions.url,
      state:`${currentPath}?isSubscription=true${pageNo && `&page?${pageNo}`}`});
  };

  return (
    <Div>
      <Div mb={3}>
        <MDLabel fontSize={Theme.space.m} lineHeight={Theme.lineHeights.tight}>
          <FormattedMessage id="common_subscriptions" defaultMessage="Subscriptions" />
        </MDLabel>
      </Div>

      <Div justifyContent="space-between" display="flex" flexDirection={["column", "row"]} mb={3}>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onClick={handleTabs}
          onNext={handleMoveTabs}
          onPrevious={handleMoveTabs}
        />
        {!loading && (
          <Div display="flex" justifyContent={["space-between", "flex-start"]} pb={[3, 3, 4, 4]} pt={[4, 4, 0, 0]}>
            {hasWriteAccess && (
              <Button
                label={<FormattedMessage id="common_add_new" defaultMessage="Add new" />}
                primary
                startIcon={PlusIcon}
                width={widths.w175}
                padding="10px !important"
                onClick={handleAddNew}
              />
            )}
            <FilterButton
              open={isFilterOpen}
              closeFilter={handleCloseFilter}
              options={filterOptions}
              onApply={handleFilterApply}
            />
          </Div>
        )}
      </Div>
      {!loading && (
        <>
          <ListScreen
            data={subscriptions}
            moreButton
            header={header}
            value={Columns}
            isCustomers
            onClick={handleDetailPage}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            handleSorting={handleSorting}
            dataNotFoundMessage={
              <FormattedMessage id="subscription_not_found" defaultMessage="No subscriptions found" />
            }
          />
          {meta.pages > 1 && (
            <Div py={4}>
              <Pagination gotoPage={handlePagination} cursor={meta} />
            </Div>
          )}
        </>
      )}
      {loading && <MainLoader type="list" />}
    </Div>
  );
};

SubscriptionTab.propTypes = {
  userId: PropTypes.string,
  currentPath: PropTypes.string,
};

export default SubscriptionTab;
