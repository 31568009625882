const host = "https://api.app.mydogcare.com/";
export default {
  domain: host,
  apiBase: `${host}api/v1/`,
  baseName: "/",
  language: "sv",
  key: "AIzaSyBI1klLeRL4BZJxTFGun1515l-5WPMUfUg",
  orderConfirmUrl: "http://app.mydogcare.com/order-confirm?order_id={checkout.order.id}",
  markerDestinationId: "6523acd161bda4c9a6bdb9a7",
  wpUrl: "https://mydogcare.com/",
};
