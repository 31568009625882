import styled from "styled-components";
import { layout, compose } from "styled-system";
import { Div, Card } from "../../styles/Styles";

export const Row = styled(Div)`
  display: flex;
  margin: 0px;
  padding: 20px 32px;
  border-bottom: 1px solid rgb(182, 186, 189, 0.2);
  @media (max-width: 768px) {
    padding: 15px;
    flex-direction: ${({ flex }) => (flex ? "row" : "column")};
  }
`;
export const CardWrapper = styled(Card)`
  ${compose(layout)};
  position: absolute;
  width: 790px !important;
  right: 0;
  top: 50px;
  z-index: 5;
  @media (max-width: 768px) {
    position: fixed;
    width: 100% !important;
    overflow: auto !important;
    padding-bottom: 50px !important;
  }
`;
