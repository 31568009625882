import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  background,
  position,
  compose,
  border,
  shadow,
} from "styled-system";
import Colors from "../../styles/Colors";
import {
  Div,
} from "../../styles/Styles";

export const Select = styled.select`
${compose(typography, border, space, color, flexbox, layout, background, position, shadow)};
  border: 1px solid rgb(26,36,36,0.3) !important;
  border-radius: 5px;
  height: 48px;
  cursor: pointer;
  background-color: transparent !important;
  outline: none !important;
  padding: 0 8px;
`;

class SelectBox extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props;
    this.state = {
      isDropdownOpened: false,
      selectedValue: "",
      selectedLabel: "",
    };
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  /**
   * Adding an event listener to check click event.
   */
  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps) {
    let { options: currentOptions = [] } = this.props;
    if (currentOptions && prevProps.options !== currentOptions) {
      let selectedValue = "";
      currentOptions = currentOptions
        ? currentOptions.map((obj) => {
          let option = obj;
          option = {
            ...obj,
            isSelected: obj.isSelected ? (selectedValue = obj.value) : false,
          };
          return option;
        })
        : [];
      this.onOptionsUpdate(selectedValue, currentOptions);
    }
  }

  /**
   * Removing added event listener.
   */
  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  /**
   * Closes the dropdown on clicking outside the component.
   * @param {event} e captures onclick event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { id } = this.props;
    const { isDropdownOpened } = this.state;
    if (!this.selectRef[id].contains(e.target) && isDropdownOpened) {
      this.handleDropDown();
    }
  }

  /**
   * Handles open and close action of dropdown
   */
  handleDropDown = () => {
    const { isDropdownOpened, selectedOption } = this.state;
    const { onBlur, name } = this.props;
    if (onBlur && isDropdownOpened) {
      onBlur({ target: { name, value: selectedOption } });
    }
    this.setState({ isDropdownOpened: !isDropdownOpened });
  };

  /**
   * Updates the selected value and options.
   * @param {*} selectedValue selected options
   * @param {object} options Options
   */
  onOptionsUpdate = (selectedValue, options) => {
    const { value } = this.props;
    const selectedLabel = options.filter((o) => (o.value === selectedValue ? o.label : value));
    const label = (selectedLabel && selectedLabel[0]) || "";
    this.setState({
      selectedValue,
      selectedLabel: label,
    });
  };

  /**
   * Handles selected option
   * @param {object} option
   */
   handleSelectOption = (e) => {
     const { target: { value = "" } = {} } = e;
     const { options } = this.props;
     const { selectedLabel, selectedValue } = this.state;
     console.log(selectedLabel, selectedValue, "sdgbj");
     const { onChange, name } = this.props;
     const selectedOption = options?.map((obj) => ({ ...obj, isSelected: value === obj.value }));
     const selectedOptValue = selectedOption.filter((o) => o.isSelected)[0];
     this.setState(
       {
         selectedLabel: selectedOptValue.label,
         selectedValue: selectedOptValue.value,
         selectedOption: selectedOptValue,
       },
       this.handleDropDown,
     );
     if (onChange) {
       const event = { target: { name, value: selectedOptValue } };
       onChange(event);
     }
   };

   render() {
     const {
       id,
       formikValues = {},
       name,
       disabled,
       placeholder = "",
       width,
       options,
       value,
       showError,
       isTouched,
     } = this.props;
     const { errors = {}, touched = {} } = formikValues;
     const displayError = (errors[name] && touched[name]) || (showError && isTouched);
     return (
       <Div
         width={width}
         disable={disabled}
         ref={(selectRef) => {
           this.selectRef[id] = selectRef;
         }}
         position="relative"
         className={disabled ? "disabled-state" : ""}
       >
         <Select
           className="col"
           width="inherit"
           px={2}
           py="15px"
           cursor="pointer"
           value={value?.label}
           placeholder={placeholder}
           borderColor={displayError ? Colors.Red : Colors.Border}
           onChange={this.handleSelectOption}
         >
           <option value="" disabled selected={!value?.value}>
             {placeholder}
           </option>
           {options.map((obj) => (
             <option value={obj?.value} selected={value?.value === obj?.value}>
               {obj?.label}
             </option>
           ))}
         </Select>
       </Div>
     );
   }
}

SelectBox.propTypes = {
  /**
   * Label of the input field
   */
  label: PropTypes.string,
  /**
   * value of the input field
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Id of the input
   */
  id: PropTypes.string,
  /**
   * Name of the input
   */
  name: PropTypes.string,
  /**
   * Do input field be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Width of the input field
   */
  width: PropTypes.string,
  /**
   * What options should be shown in dropdown
   */
  options: PropTypes.node,
  /**
   * Calls when value is changed
   */
  onChange: PropTypes.func,
  /**
   * What should be as placeholder..
   */
  placeholder: PropTypes.string,
  /**
   * Formik values
   */
  formikValues: PropTypes.node,
  onBlur: PropTypes.func,
  showError: PropTypes.string,
  isTouched: PropTypes.bool,
  // endIcon: PropTypes.string,
};
SelectBox.defaultProps = {
  name: null,
  id: null,
  value: null,
  formikValues: {},
  onChange: undefined,
  placeholder: null,
  disabled: false,
  label: null,
  options: [],
  width: "auto",
  onBlur: undefined,
  showError: "",
  isTouched: false,
  // endIcon: '',

};
export default SelectBox;
