import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Div, MDImage, Span,
} from "../../styles/Styles";
import CheckBox from "../Input/CheckBox";
import Constants from "../../shared/Constants";
import Colors from "../../styles/Colors";

const CheckBoxAccordian = ({
  services = [], handleOnChange, editAvailabilty, isEdit,
}) => {
  const {
    icons, dogSize,
  } = Constants;
  const serviceableDogSize = Object.values(dogSize);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedServices, setSelectedServices] = useState(services);

  useEffect(() => {
    setSelectedServices(services);
  }, [services]);
  const handleOnServiceChange = (service, index) => () => {
    const changedServices = selectedServices.map((obj) => ({
      ...obj,
      isSelected: (obj.id === service.id) ? !obj.isSelected : obj.isSelected,
      serviceable_dog_size: editAvailabilty ? obj.selectedDogSize : serviceableDogSize,
    }));

    setSelectedServices(changedServices);
    setActiveIndex(index);
    handleOnChange(changedServices);
  };

  const handleDropdownOnclick = (size, service) => () => {
    const changedServices = selectedServices.map((obj) => {
      let { isSelected, serviceable_dog_size: serviceDogSize = [] } = obj;
      if (obj.id === service.id && isSelected && typeof (serviceDogSize) === "object") {
        if (serviceDogSize.includes(size)) {
          serviceDogSize = serviceDogSize
            .filter((userSelectedDogSize) => userSelectedDogSize !== size);
        } else {
          serviceDogSize = [...serviceDogSize, size];
        }
        isSelected = serviceDogSize.length > 0;
      }
      return { ...obj, isSelected, serviceable_dog_size: serviceDogSize };
    });

    setSelectedServices(changedServices);
    handleOnChange(changedServices);
  };

  const handleAccordion = (index) => () => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return selectedServices.map((item, index) => (
    <Div mb={3} key={item.id}>
      <Div display="flex" type="accordion">
        <CheckBox
          disabled={item.disable || !isEdit}
          label={item.label}
          isSelected={item.isSelected ? item.isSelected
            : false}
          inputProps="py-2"
          onClick={handleOnServiceChange(item, index)}
        />
        <MDImage
          className="ml-2"
          useCursorPointer
          src={activeIndex === index
            ? icons.UpArrow : icons.ArrowDown}
          alt="icon"
          onClick={handleAccordion(index)}
        />
      </Div>
      {activeIndex === index
        && (
          <Div
            className={activeIndex === index ? "active" : ""}
            type="active"
            pl="32px"
            pt="20px"
          >
            <Span fontSize="14px" fontWeight="500" color={Colors.Black}>
              <FormattedMessage id="label_prefer_to_work" defaultMessage="You prefer to work with" />
            :
            </Span>
            <Span pt="16px" display="block">
              <CheckBox
                disabled={!item.isSelected || (editAvailabilty && !item.selectedDogSize.includes(dogSize.large))}
                label={<FormattedMessage id="label_big_dog" defaultMessage="Large dogs (over 15 kg)" />}
                isSelected={(item.isSelected && item.serviceable_dog_size.includes(dogSize.large))
                || (editAvailabilty && item.serviceable_dog_size.includes(dogSize.large))}
                onClick={handleDropdownOnclick(dogSize.large, item)}
              />
            </Span>
            <Span py="16px" display="block">
              <CheckBox
                disabled={!item.isSelected || (editAvailabilty && !item.selectedDogSize.includes(dogSize.medium))}
                isSelected={(item.isSelected && item.serviceable_dog_size.includes(dogSize.medium))
                || (editAvailabilty && item.serviceable_dog_size.includes(dogSize.medium))}
                label={<FormattedMessage id="label_medium_dog" defaultMessage="Medium dogs (8-15 kg)" />}
                onClick={handleDropdownOnclick(dogSize.medium, item)}
              />
            </Span>
            <Span pb="16px" display="block">
              <CheckBox
                disabled={!item.isSelected || (editAvailabilty && !item.selectedDogSize.includes(dogSize.small))}
                isSelected={(item.isSelected && item.serviceable_dog_size.includes(dogSize.small))
                  || (editAvailabilty && item.serviceable_dog_size.includes(dogSize.small))}
                label={<FormattedMessage id="label_small_dog" defaultMessage="Small dogs (2-8 kg)" />}
                onClick={handleDropdownOnclick(dogSize.small, item)}
              />
            </Span>
            <Span pb="4px" display="block">
              <CheckBox
                disabled={!item.isSelected || (editAvailabilty && !item.selectedDogSize.includes(dogSize.mini))}
                isSelected={(item.isSelected && item.serviceable_dog_size.includes(dogSize.mini))
                  || (editAvailabilty && item.serviceable_dog_size.includes(dogSize.mini))}
                label={<FormattedMessage id="label_mini_dog" defaultMessage="Mini dogs (under 2 kg)" />}
                onClick={handleDropdownOnclick(dogSize.mini, item)}
              />
            </Span>
          </Div>
        )}
    </Div>
  ));
};

CheckBoxAccordian.propTypes = {
  services: PropTypes.node,
  handleOnChange: PropTypes.func,
  editAvailabilty: PropTypes.bool,
  isEdit: PropTypes.bool,
};

CheckBoxAccordian.defaultProps = {
  services: [],
  handleOnChange: () => {},
  editAvailabilty: false,
  isEdit: false,
};

export default CheckBoxAccordian;
