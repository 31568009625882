import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Div, MDLabel, CardHeader, MDInputValue } from "../../styles/Styles";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Colors from "../../styles/Colors";
import Theme from "../../styles/Theme";


export default function OrdersModal({ onClose, orderData = [], onSubmit }) {
  orderData?.data?.sort((a, b) => {
    const dateA = new Date(a.start);
    const dateB = new Date(b.start);
    return dateA - dateB;
  });

  const handleClose = useCallback(() => {
    onClose();
  });

  const handleSelectType = (value) => () => {
    onClose();
    onSubmit(value);
  };

  return (
    <Modal className="availability-form">
      <Div width={["350px", "510px"]}>
        <CardHeader px={3}>
          <MDLabel alignSelf="center" fontSize="18px">
            <FormattedMessage id="common_orders" defaultMessage="Orders" />
          </MDLabel>
        </CardHeader>
        <Div
          display="flex"
          flexDirection="column"
          className="col-11"
          paddingTop="1rem"
          margin="auto"
          justifyContent="center">
          <MDInputValue mb={20}>
            
            <FormattedMessage
              id="paragraph_choose_order"
              defaultMessage="Choose what order you want to check:"
            />
          </MDInputValue>
          <Div display="inline-flex" flexWrap="wrap" justifyContent="space-between">
            {orderData &&
              orderData.map((data) => {
                const {
                  serviceName = "",
                  serviceProviderName = null,
                  start = "",
                  end = "",
                  id=""
                } = data?.data;

                return (
                  <Div width={["96%","96%","96%","48%"]} my={2} p={2} key={id} bg={Colors.Blue} onClick={handleSelectType(data)} cursor="pointer">
                    <Div display="flex" mb={1}>
                      <MDLabel fontSize={Theme.fontSizes.es} lineHeight="13px" color={Colors.Black}>
                        {serviceName}
                      </MDLabel>
                    </Div>
                    <Div display="flex" flexWrap="wrap">
                      <MDInputValue wordBreak="break-all" mb={1} size={11} color={Colors.DarkGrey} width="auto" lineHeight="13px">
                        {`${start} - ${end}`}
                      </MDInputValue>
                    </Div>
                    <Div>
                      <MDLabel
                        fontSize={Theme.fontSizes.es}
                        lineHeight="13px"
                        color={Colors.Black}
                        display={["none", "none", "none", "flex"]}
                        wordBreak="break-all"
                      >
                        {serviceProviderName || <FormattedMessage id="label_not_assigned" defaultMessage="Not assigned" />}
                      </MDLabel>
                    </Div>
                  </Div>
                );
              })}
          </Div>
        </Div>
        <hr />
        <Div
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="center"
          alignItems="stretch"
          mb={20}
          mx={40}>
          <Div mr={[0, 4]} ml={[1, 0]} my={[1, 0]}>
            <Button
              width={[1, 160]}
              label={<FormattedMessage id="common_cancel" defaultMessage="Cancel" />}
              secondary
              onClick={handleClose}
            />
          </Div>
        </Div>
      </Div>
    </Modal>
  );
}

OrdersModal.propTypes = {
  onClose: PropTypes.func,
  orderData: PropTypes.node,
  onSubmit: PropTypes.func,
};

OrdersModal.defaultProps = {
  onClose: undefined,
  orderData: [],
  onSubmit: undefined,
};
