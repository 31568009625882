import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";
import FilterButton from "../../../components/Filter/FilterButton";
import { Div, MDLabel } from "../../../styles/Styles";
import Theme from "../../../styles/Theme";
import ListScreen from "../../../components/ListScreen/ListScreen";
import Pagination from "../../../components/Pagination/Pagination";
import Constants from "../../../shared/Constants";
import { getAllAssignments } from "../../../services/UserService";
import ListScreenLoader from "../../../components/Loader/ListScreenLoader";

const {
  sorting: { type: sortType, keys: { sortBy, sort } },
  language, filter: { options: optionsData, components },
} = Constants;

const filterDataValues = [
  {
    id: "status",
    component: components.CHECKBOX,
    label: language.filter_label_status,
    values: optionsData.ORDER_STATUS,
    defaultValues: [],
    name: "status",
  },
  {
    id: "service_providers",
    component: components.LETTERSELECT,
    label: language.common_service_provider,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "service_providers",
  },
  {
    id: "rooms",
    component: components.LETTERSELECT,
    label: language.filter_label_room,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "rooms",
  },
  {
    id: "services",
    component: components.CHECKBOX,
    label: language.common_service,
    values: [],
    defaultValues: [],
    name: "services",
  },
  {
    id: "date",
    component: components.DATEPICKER,
    label: language.filter_date_diapason,
    fromDate: { defaultDate: "" },
    toDate: { defaultDate: "" },
    name: "date_time",
  },
  {
    id: "organization",
    component: components.LETTERSELECT,
    label: language.filter_label_organization,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "organization",
  },
  {
    id: "customers",
    component: components.LETTERSELECT,
    label: language.filter_label_customer,
    values: optionsData.ALPHABETS,
    defaultValues: [],
    availableValues: [],
    name: "customers",
  },
];

const AssignmentsTab = ({ userId, redirectTo }) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 10,
    pages: 1,
  });
  const [sortedBy, setSortedBy] = useState("created_at");

  const [toBeFilteredData, setToBeFilteredData] = useState({});
  const [filterOptions, setFilterOptions] = useState(filterDataValues);
  const serviceProviderFilters = useSelector((state) => state.FilterReducer.serviceProviderFilters);
  const roomFilters = useSelector((state) => state.FilterReducer.roomFilters);
  const organizationFilters = useSelector((state) => state.FilterReducer.organizationFilters);
  const allServices = useSelector((state) => state.FilterReducer.allServices);
  const customerFilters = useSelector((state) => state.FilterReducer.customerFilters);

  const fetchFilters = async (options) => {
    setLoading(true);
    const filteredData = options || filterOptions;
    filteredData[1].availableValues = serviceProviderFilters;
    filteredData[2].availableValues = roomFilters;
    filteredData[3].values = allServices;
    filteredData[5].availableValues = organizationFilters;
    filteredData[6].availableValues = customerFilters;
    setFilterOptions(filteredData);
    setLoading(false);
  };

  /**
   *
   * @async
   * @function fetchAssignments Fetches assignments
   * @param {String} searchInput search text
   *
   */
  const fetchAssignments = (searchInput, options, filterData = undefined) => {
    setLoading(true);
    const filter = filterData || toBeFilteredData;
    const queryParams = [
      ["pageNumber", searchInput ? 1 : meta.pageNumber],
      ["pageSize", meta.pageSize],
      [sortBy, sortedBy],
      [sort, sortAscending ? sortType.ASC : sortType.DESC],
      ["filter", filter],
    ];
    getAllAssignments(userId, queryParams).then((response) => {
      const { meta: srcMetaData = {}, data = {} } = response;
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = srcMetaData;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      fetchFilters(options);
      setOrderData(data.map((obj) => ({
        ...obj,
        startDateTime: moment.utc(obj.startDateTime).local(),
        endDateTime: moment.utc(obj.endDateTime).local(),
      })));
      setMeta(metaData);
      setLoading(false);
    }, (error) => {
      console.error(error);// Error!
      setLoading(false);
    });
  };

  /**
   *
   * Calls fetchAssignments function when the below values changes
   * [loading, meta.pageNumber,location, sortAscending, sortedBy]
   *
   */
  useEffect(() => {
    fetchAssignments();
  }, [sortAscending, sortedBy, meta.pageNumber]);

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handleGotoPage = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 10;
    setMeta(meta);
  };

  /**
   * Handles filter actions
   */
  const handleCloseFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  /**
   *
   * Handles sorting of columns in the list screen
   * @param {object} item column data
   * @returns sorted list
   *
   */
  const handleSorting = (item) => () => {
    if (sortedBy === item) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
      setSortedBy(item);
    }
  };

  const handleFilterApply = async (data = [], options = undefined) => {
    const filteredData = {};
    data.map((obj) => {
      if (filteredData[obj.name]) {
        filteredData[obj.name].push(obj.value);
      } else {
        filteredData[obj.name] = [];
        filteredData[obj.name].push(obj.value);
      }
      return obj;
    });
    setToBeFilteredData(filteredData);
    setFilterOptions(options);
    setIsFilterOpen(false);
    await fetchAssignments(true, options, filteredData);
  };

  const header = [
    {
      className: "col",
      value: language.filter_label_status,
      key: "status",
      icon: sortType.ARROW,
    },
    {
      className: "col",
      value: language.filter_label_customer,
      key: "customer",
      icon: sortType.ALPHABETS,
    },
    {
      className: "col",
      value: language.common_dog,
      key: "dog_size",
      icon: sortType.ARROW,
    },
    {
      className: "col",
      value: language.header_date_time,
      key: "dateTime",
      icon: sortType.ARROW,
    },
    {
      className: "col",
      value: language.header_service,
      key: "service",
      icon: sortType.ALPHABETS,
    },
    {
      className: "col",
      value: language.filter_label_room,
      key: "room",
      icon: sortType.ALPHABETS,
    },
    {
      className: "col",
      value: language.label_organisation,
      key: "organization",
      icon: sortType.ALPHABETS,
    },
  ];
  const value = [
    {
      className: "col",
      key: "status",
      type: "label",
    },
    {
      className: "col",
      key: "customer",
      type: "singleUser",
      isDesktop: true,
    },
    {
      className: "col",
      key: "dog",
      type: "label",
    },
    {
      className: "col",
      key: "startDateTime",
      type: "date",
    },
    {
      className: "col",
      key: "service",
      type: "singleUser",
    },
    {
      className: "col",
      key: "room",
      type: "singleUser",
    },
    {
      className: "col",
      key: "organization",
      type: "singleUser",
    },
  ];

  return (
    <Div>
      <Div justifyContent="space-between" display="flex" pb={4}>
        <Div>
          <MDLabel fontSize={Theme.space.m} className="py-2 text-left" lineHeight={Theme.lineHeights.tight}>
            <FormattedMessage id="user_assignments_tab" />
          </MDLabel>
        </Div>
        <Div className="row m-0">
          <FilterButton
            open={isFilterOpen}
            options={filterOptions}
            closeFilter={handleCloseFilter}
            onApply={handleFilterApply}
          />
        </Div>
      </Div>
      {loading && <ListScreenLoader />}
      {!loading && (
        <>
          <ListScreen
            moreButton
            header={header}
            value={value}
            data={orderData}
            onClick={redirectTo}
            handleSorting={handleSorting}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            isCustomers
            isOrders
            dataNotFoundMessage={<FormattedMessage id="assignments_not_found" defaultMessage="No assignments found" />}
          />
          {meta.pages > 1 && (
            <Div py={4}>
              <Pagination gotoPage={handleGotoPage} cursor={meta} />
            </Div>
          )}
        </>
      )}
    </Div>
  );
};
AssignmentsTab.propTypes = {
  redirectTo: PropTypes.func,
  userId: PropTypes.string,
};
AssignmentsTab.defaultProps = {
  redirectTo: () => {},
  userId: "",
};
export default AssignmentsTab;
