import React from "react";
import Loader from "./Loader";
import { Div } from "../../styles/Styles";

const FormLoader = () => (
  <>
    <Div>
      <Loader width="270px" height="50px" my={3} />
      <Loader width="300px" height="30px" my={2} />
      <Loader width="120px" height="25px" my={3} />
      <Div display="flex">
        <Loader width="90px" height="90px" my={3} mr={3} />
        <Loader width="80px" height="30px" my={3} ml={3} />
      </Div>
      {[...Array(7)].map(() => (
        <>
          <Loader width="120px" height="25px" my={3} />
          <Loader height="50px" my={2} />
        </>
      ))}
      <Div display="flex">
        <Loader width="40%" height="40px" my={3} mr={3} />
        <Loader width="40%" height="40px" my={3} ml={3} />
      </Div>
    </Div>
  </>
);

export const CardLoader = () => (
  <Div width="350px" className="my-2">
    <Div display="flex" width="350px">
      <Loader width="80px" height="80px" />
      <Div className="mx-3">
        <Loader width="150px" />
        <Loader width="100px" />
      </Div>
    </Div>
  </Div>
);

export const OrderPageLoader = () => (
  <Div>
    <Loader width="270px" height="50px" my={3} />
    <Loader width="300px" height="30px" my={2} />
    <Loader width="120px" height="25px" my={3} />
    {[...Array(3)].map(() => (
      <Div className="d-lg-flex d-md-block">
        <CardLoader />
        <CardLoader />
      </Div>
    ))}
    <Loader width="270px" height="25px" my={3} />
    <Loader width="300px" height="50px" my={2} />
    <Loader width="270px" height="20px" my={3} />
    <Loader width="120px" height="25px" my={3} />
    <Div display="flex">
      <Loader width="40%" height="40px" my={3} mr={3} />
      <Loader width="40%" height="40px" my={3} ml={3} />
    </Div>
  </Div>
);

export default FormLoader;
