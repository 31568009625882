import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import Container from "../../components/Containers/PortalContainer";
import CreateCustomerForm from "../Customers/Components/CreateCustomerForm";
import {
  FormWrapper, MDImage, Header, Div, MDLabel, MDInputValue,
} from "../../styles/Styles";
import Constants from "../../shared/Constants";
import CustomerService from "../../services/CustomerService";
import Config from "../../config";
import FormLoader from "../../components/Loader/FormLoader";
import Modal from "../../components/Modal/Modal";
import Card from "../../components/Card/Card";
import Theme from "../../styles/Theme";
import AuthService from "../../services/AuthService";
import { loggedInUser } from "../../store/actions/AppAction";
import { isCustomer } from "../../shared/utils";

const { language } = Constants;
class CustomerDetails extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    const userData = localStorage.userData ? JSON.parse(localStorage.userData) : {};
    const { customer = {}, first_login: newLoggin = false } = userData;
    this.state = {
      loading: false,
      customerData: {},
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
      userId: customer.id,
      newUser: newLoggin,
    };
  }

  componentDidMount=() => {
    this.fetchDetails();
  }

  fetchDetails=async () => {
    this.setState({ loading: true });
    const {
      match: { params = {} },
    } = this.props;
    const { customer = {} } = JSON.parse(localStorage.userData);
    const { id = "" } = isCustomer() ? customer : params;
    try {
      const response = await this.customerService.getCustomerDetails(id);
      this.setState({ loading: false, customerData: response });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  getDetails = () => {
    const { getLoginUser } = this.props;
    try {
      this.authService.usersDetails().then((response) => {
        getLoginUser(response);
        localStorage.setItem("userData", JSON.stringify(response));
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleSubmitData= (isSaved = false) => {
    if (isSaved) {
      this.getDetails();
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_customer_updated,
      }, this.fetchDetails);
    } else {
      this.setState({
        loading: false,
        changesSaved: true,
        alertMessage: language.alert_try_later,
        alertHeader: language.header_error,
      });
    }
  }

  handleCancelChanges=() => {
    this.fetchDetails();
  }

  /**
   * Cancels the user deletion operation
   */
  handleCancel = () => {
    this.setState({
      changesSaved: false,
      alertMessage: "",
      alertHeader: "",
    });
  }

  render() {
    const { icons } = Constants;
    const {
      loading, customerData = {}, newUser,
      changesSaved, alertMessage, alertHeader, userId,
    } = this.state;
    const { status = "", bannedReason = "" } = customerData;
    return (
      <Container bg="transparent !important">
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && (
          <>
            {newUser && (
              <>
                <Div display="flex" justifyContent="space-between">
                  <Header className="py-2" textAlign="left">
                    <FormattedMessage id="my_account_welcome_header" />
                  </Header>
                </Div>
                <Div display="flex">
                  <MDLabel fontSize={Theme.fontSizes.medium} ml={1} textAlign="left">
                    <FormattedMessage id="my_account_welcome_description" values={{ break: <br /> }} />
                  </MDLabel>
                </Div>
              </>
            )}
            <Div display="flex" justifyContent="space-between">
              <Header className="py-2" textAlign="left">
                <FormattedMessage id="my_account_information_about_you" />
              </Header>
            </Div>

            {status === Constants.status.banned && (
              <Div className="p-4" width="fit-content" bg="white" borderRadius="10px">
                <Div className="row m-0 ">
                  <MDImage src={icons.WarningIcon} alt="icon" className="mr-2" />
                  <MDLabel fontSize="18px"><FormattedMessage id="user_is_banned_message" /></MDLabel>
                </Div>
                <Div>
                  <Div className="my-3">
                    <MDLabel textAlign="left"><FormattedMessage id="common_reason" /></MDLabel>
                  </Div>
                  <Div>
                    <MDInputValue textAlign="left" size="14">{bannedReason}</MDInputValue>
                  </Div>
                </Div>
              </Div>
            )}
            <FormWrapper>
              <CreateCustomerForm
                edit
                userId={userId}
                customerData={customerData}
                handleBackToCustomers={this.handleSubmitData}
              />
            </FormWrapper>
          </>
        )}
        {changesSaved && (
          <Modal>
            <Card
              header={alertHeader || <FormattedMessage id="common_done" defaultMessage="Done" />}
              message={alertMessage}
              alertCard
              buttonLabel={<FormattedMessage id="common_ok" defaultMessage="ok" />}
              onClick={this.handleCancel}
            />
          </Modal>
        )}
      </Container>
    );
  }
}
CustomerDetails.propTypes = {
  match: PropTypes.node,
  getLoginUser: PropTypes.func,
};
CustomerDetails.defaultProps = {
  match: {},
  getLoginUser: () => {},
};
const mapStateToProps = (state) => ({
  userDetails: state.AppReducer.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    getLoginUser: (payload) => dispatch(loggedInUser(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerDetails);
