import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PortalContainer from "../../components/Containers/PortalContainer";
import {
  Header, Div, MDLabel, MDInputValue,
} from "../../styles/Styles";
import TextBox from "../../components/Input/TextBox";
import Button from "../../components/Button/Button";
import Constants from "../../shared/Constants";
import CheckBox from "../../components/Input/CheckBox";
import Theme from "../../styles/Theme";
import SignupService from "../../services/SignUpService";
import Config from "../../config";

const { language, role: { Customer } } = Constants;
/**
 * Handles validation for input fields.
 */
const registerSchema = Yup.object().shape({
  firstName: Yup.string().required(language.validation_invalid_name),
  lastName: Yup.string().required(language.validation_invalid_surname),
  email: Yup.string()
    .required(language.validation_empty_email_input)
    .email(<FormattedMessage id="validation_valid_email_input" defaultMessage="Invalid email" />),
  phone: Yup.string()
    .typeError(language.validation_valid_phone_number)
    .required(language.validation_valid_phone_number)
    .matches(/^[0-9 ()+-]*$/, language.validation_valid_phone_number),
  password: Yup.string()
    .required(language.validation_empty_password_input)
    .min(8, <FormattedMessage id="validation_length_password_input" defaultMessage="Too short" />)
    .matches(
      /^.*(?=.{8,})((?=.*[!~@#$%^&*/?()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      language.validation_valid_password_input,
    ),
  confirmPassword: Yup.string()
    .required(language.validation_empty_confirm_password)
    .when("password", {
      is: (password) => !!(password && password.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        <FormattedMessage id="validation_valid_confirm_password" defaultMessage="Enter a valid value" />,
      ),
    }),
  isAgreed: Yup.boolean().oneOf([true], language.validation_valid_isAgreed),
});

const RegisterCustomer = () => {
  const signUpService = new SignupService(Config.apiBase);
  const history = useHistory();
  const {
    input: { name: inputNames, type: inputType }, icons: EyeIcons,
  } = Constants;
  const [isSubmitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const { widths } = Theme;

  const handleRedirectToLogin = () => {
    history.push(Constants.routes.customerLogin.url);
  };

  const handleCheckBoxClick = (value, onChange = () => { }) => () => {
    onChange({ target: { name: "isAgreed", value: !value } });
  };

  const handleFormSubmit = async (value, prop) => {
    const {
      firstName = "", lastName = "", phone = "", email = "", password = "", confirmPassword = "",
    } = value;
    const { setErrors } = prop;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone_number_list: [phone],
      role: Customer,
      email,
      confirm_password: confirmPassword,
      password,
    };
    try {
      const response = await signUpService.registerCustomer(payload);
      if (response) {
        setSubmitted(true);
      }
    } catch (e) {
      const { response: { data: { errors = {} } = {} } = {} } = e;
      const { email: emailError = "" } = errors;
      if (emailError) {
        setErrors({ email: Constants.language.validate_email_already_exists });
      }
    }
  };

  const togglePassword = (label) => {
    if (label === inputNames.confirmPassword) {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  return (
    <PortalContainer type="calendar">
      {isSubmitted && (
        <>
          <Header mt={7} justifyContent="center">
            <FormattedMessage id="common_done" defaultMessage="Done" />
          </Header>
          <Div>
            <MDInputValue className="text-break" size="16" justifyContent="center" my={3} p={3}>
              <FormattedMessage id="registration_completion_message" defaultMessage="Registration complete" />
            </MDInputValue>
          </Div>
          <Div display="flex" justifyContent="center" mt={2}>
            <Button
              primary
              label={language.common_back_to_login}
              width="auto"
              onClick={handleRedirectToLogin}
            />
          </Div>
        </>
      )}
      {!isSubmitted && (
        <>
          <Div>
            <Header justifyContent="center" fontSize={["30px", "36px"]}>
              <FormattedMessage id="register_customer" />
            </Header>
          </Div>
          <Div className="overflow-hidden position-relative">
            <Formik
              enableReinitialize
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                password: "",
                confirmPassword: "",
                isAgreed: false,
              }}
              validationSchema={registerSchema}
              onSubmit={handleFormSubmit}
            >
              {(prop) => {
                const {
                  values = {}, isValid, touched, handleChange, handleBlur, handleSubmit,
                } = prop;
                const isDisabled = isValid && Object.keys(touched).length > 0;
                return (
                  <Form>
                    <Div className="d-lg-flex d-md-block">
                      <Div className="d-lg-flex d-md-block justify-content-end" width={[widths.full, widths.half]}>
                        <TextBox
                          width={widths.w255}
                          containerProps="mr-lg-3 ml-5"
                          label={`${language.placeholder_name} *`}
                          placeholder={`${language.placeholder_name}...`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          name={inputNames.firstName}
                          type={inputType.text}
                          formikValues={prop}
                        />
                      </Div>
                      <Div className="d-lg-flex d-md-block" width={[widths.full, widths.half]}>
                        <TextBox
                          width={widths.w255}
                          containerProps="ml-lg-3 ml-5"
                          label={`${language.placeholder_surname} *`}
                          placeholder={`${language.placeholder_surname}...`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          name={inputNames.lastName}
                          type={inputType.text}
                          formikValues={prop}
                        />
                      </Div>
                    </Div>
                    <Div className="d-lg-flex d-md-block">
                      <Div className="d-lg-flex d-md-block justify-content-end" width={[widths.full, widths.half]}>
                        <TextBox
                          containerProps="mr-lg-3 ml-5"
                          width={widths.w255}
                          label={`${language.login_label_email} *`}
                          placeholder={language.placeholder_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name={inputNames.email}
                          formikValues={prop}
                          login
                        />
                        <ErrorMessage name="email">
                          <FormattedMessage id="validate_email_already_exists" defaultMessage="Email Already Registered" />
                        </ErrorMessage>
                      </Div>
                      <Div className="d-lg-flex d-md-block" width={[widths.full, widths.half]}>
                        <TextBox
                          containerProps="ml-lg-3 ml-5"
                          width={widths.w255}
                          label={`${language.common_label_your} ${language.label_phone}*`}
                          placeholder={language.telephone_label}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          name={inputNames.phone}
                          formikValues={prop}
                          phone
                        />
                      </Div>
                    </Div>
                    <Div className="d-lg-flex d-md-block">
                      <Div className="d-lg-flex d-md-block justify-content-end" width={[widths.full, widths.half]}>
                        <TextBox
                          containerProps="mr-lg-3 ml-5"
                          width={widths.w255}
                          label={`${language.login_label_password} *`}
                          placeholder={language.login_label_password}
                          name={inputNames.password}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          formikValues={prop}
                          extraLabel={language.extra_label}
                          type={showPassword ? inputType.password : inputType.text}
                          endIcon={showPassword ? EyeIcons.EyeOpenIcon : EyeIcons.EyeCloseIcon}
                          onEndIconClick={() => togglePassword(inputNames.password)}
                          login
                        />
                      </Div>
                      <Div className="d-lg-flex d-md-block" width={[widths.full, widths.half]}>
                        <TextBox
                          containerProps="ml-lg-3 ml-5"
                          width={widths.w255}
                          label={`${language.reset_password_label_repeat_password}*`}
                          placeholder={language.login_label_password}
                          name={inputNames.confirmPassword}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          formikValues={prop}
                          type={showConfirmPassword ? inputType.password : inputType.text}
                          endIcon={showConfirmPassword
                            ? EyeIcons.EyeOpenIcon
                            : EyeIcons.EyeCloseIcon}
                          onEndIconClick={() => togglePassword(inputNames.confirmPassword)}
                          login
                        />
                      </Div>
                    </Div>
                    <Div
                      className="d-lg-flex d-md-block"
                      justifyContent="center"
                      mt={3}
                      pt={1}
                    >
                      <Div className="d-lg-flex d-md-block" flexDirection="column">
                        <CheckBox
                          name={inputNames.isAgreed}
                          label={language.agree_text}
                          className="ml-lg-5 ml-5"
                          size={Theme.fontSizes.regular}
                          formikValues={prop}
                          value={values.isAgreed}
                          isSelected={values.isAgreed}
                          onChange={handleChange}
                          onClick={handleCheckBoxClick(values.isAgreed, handleChange)}
                          inputProps="py-2"
                        />
                      </Div>
                    </Div>
                    <Div display="flex" justifyContent="center" mt={4} pt={2}>
                      <Button
                        primary
                        label={language.common_ok}
                        onClick={handleSubmit}
                        disable={!isDisabled}
                        width={widths.w200}
                      />
                    </Div>
                  </Form>
                );
              }}
            </Formik>
            <Div display="flex" flexDirection="column" alignItems="center">
              <Div
                className="border-line"
                width={widths.w400}
                mt={4}
              />
              <MDLabel fontSize={Theme.fontSizes.medium}>
                <FormattedMessage id="label_already_account" defaultMessage="Have account already?" />
              </MDLabel>
            </Div>
            <Div display="flex" justifyContent="center" mt={3} p={1} mb={5}>
              <Button
                secondary
                label={language.login_submit_button_log_in}
                width={widths.w200}
                onClick={handleRedirectToLogin}
              />
            </Div>
          </Div>
        </>
      )}
    </PortalContainer>
  );
};

export default RegisterCustomer;
