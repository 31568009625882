import React, { useEffect, useState, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Theme from "../../../styles/Theme";
import Colors from "../../../styles/Colors";
import CalendarCard from "../Calendar/CalendarCard";
import TimeAccordion from "../Accordion/TimeAccordion";
import NextAndBack from "../NextAndBackButtons/NextAndBack";
import { Division } from "../../Services/Styles";
import { Div, MDLabel, P, Box, MDImage, StyledDiv } from "../../../styles/Styles";
import Constants from "../../../shared/Constants";
import { loadOrganisationSchedule, setSelectServiceProvider } from "../../../store/actions/BookingFlowServicesAction";
import {
  BoxDivision,
  ContentLeftDivision,
  ContentRightDivision,
  LabelBox,
} from "../BookingFlowStyles/BookingFlowStyles";
import * as types from "../../../store/actions/types";
import MainLoader from "../../../components/Loader/MainLoader";
import { setLoader } from "../../../store/actions/AppAction";
import { emptyPaymentSnippet } from "../../../store/actions/PaymentAction";
import Loader from "../../../components/Loader/Loader";
import ServiceProviderCard from "../../../components/Card/ProfileCard";
import { mapDogSizeToDuration } from "../../../shared/utils";

const {
  icons,
  input: { format },
} = Constants;

const BookingFlowStepFour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const childRef = useRef(null);

  const [openPopup, setOpenPopup] = useState("");
  const showPopup = (i) => () => {
    setOpenPopup(i);
  };

  const { serviceId } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices
  );

  const { organisationId } = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedLocation
  );

  const { organisationServiceProvidersService, selectedServiceContainer } = useSelector(
    (state) => state.BookingFlowServicesReducer
  );

  const { selectedProvider, selectedDate, dogSizes: firstSelectDogSize } = selectedServiceContainer;
  const chooseServiceTitle = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices
  );

  const { serviceName = "" } = chooseServiceTitle;

  const setLoaderState = (params) => dispatch(setLoader(params));
  const isLoader = useSelector((state) => state.AppReducer.isLoader);
  const selectedServices = useSelector(
    (state) => state.BookingFlowServicesReducer.selectedServiceContainer.selectedServices
  );

  const { service = {} } = selectedServices;

  const { is_full_day_service = false } = service;
  const isAFulldayService = is_full_day_service === 1;

  const [currentSelectedDate, setcurrentSelectedDate] = useState(selectedDate || null);
  const steps = useSelector((state) => state.BookingFlowServicesReducer.steps);

  const { dogWeight } = useSelector((state) => state.BookingFlowServicesReducer.selectedServiceContainer.dogSizes);

  useEffect(() => {
    dispatch(emptyPaymentSnippet());
  }, []);

  useEffect(() => {
    if (steps === 1) {
      history.push(Constants.routes.chooseService.url);
    }
  }, [steps]);

  useEffect(() => {
    if (serviceId && organisationId && currentSelectedDate) {
      setLoaderState(true);
      const id = [
        ["service_id", serviceId],
        ["organization_id", organisationId],
        ["serviceable_dog_size", dogWeight],
        ["is_full_day_service", isAFulldayService],
      ];
      const date = [
        [
          "date",
          (isAFulldayService  && currentSelectedDate && Array.isArray(currentSelectedDate))
            ? moment(currentSelectedDate[0] || currentSelectedDate, format.date).format(
              Constants.input.format.yearToDay
            )
            : moment(currentSelectedDate, format.date).format(Constants.input.format.yearToDay),
        ],
        [
          "end_date",
          (isAFulldayService  && currentSelectedDate && Array.isArray(currentSelectedDate))
            ? moment(currentSelectedDate[1] || currentSelectedDate, format.date).format(
              Constants.input.format.yearToDay
            )
            : moment(currentSelectedDate, format.date).format(Constants.input.format.yearToDay),
        ],
      ];
      dispatch(loadOrganisationSchedule(organisationId, id, date));
    }
  }, [serviceId, organisationId, currentSelectedDate, dogWeight]);

  const { filteredService = [] } = useSelector((state) => state.BookingFlowServicesReducer);

  const [filteredServices = []] = filteredService;
  const { services = [] } = filteredServices;
  const selectedDogSizeIs = mapDogSizeToDuration(firstSelectDogSize?.dogWeight, services[0], true);

  const handleProvider = (data) => () => {
    const { available_slots: slots = [] } = data;
    const [currentSlot = {}] = slots;
    const { slot_id: slotId = "" } = currentSlot;
    const startTimeDate = `${selectedDate[0]} 08:00`;
    const endTimeDate = `${selectedDate[1]} 22:00`;

    const selectedPlaces = {
      serviceProviderId: data?.id,
      providerName: data?.full_name,
      providerFace: data?.file?.url,
      serviceTime: "08:00 - 22:00",
      providerStartTime: startTimeDate,
      providerEndTime: endTimeDate,
      dogWeight: selectedDogSizeIs,
      slot_id: slotId,
    };
    dispatch(setSelectServiceProvider(selectedPlaces));
  };

  useEffect(() => {
    dispatch({
      type: types.SET_ORGANISATION_PROVIDERS,
      payload: null,
    });
  }, []);

  const handlerNextStep = () => {
    if(selectedProvider.providerStartTime){
      childRef.current?.handlerNextStep();
    }
  };

  return (
    <Div pb={4}>
      <BoxDivision mt={4} type="choose_Day_Time" px={[3, 0, 0]}>
        <ContentLeftDivision pl={3} type="choose_day">
          <MDLabel fontSize={Theme.fontSizes.xl} mb={3} pb={1}>
            <FormattedMessage id="choose_the_day" defaultMessage="Choose the Day" />
          </MDLabel>
          <P
            width={440}
            fontFamily={Theme.fonts.regular}
            fontSize={Theme.fontSizes.regular}
            color={Colors.DarkGrey}
            type="choose_paragraph">
            {`${Constants.language.label_select_date_for} ${serviceName}`}
          </P>
          <Division type="calendar_Card">
            <CalendarCard
              organisationId={organisationId}
              serviceId={serviceId}
              setcurrentSelectedDate={setcurrentSelectedDate}
              currentSelectedDate={currentSelectedDate}
              dogWeight={dogWeight}
            />
          </Division>
        </ContentLeftDivision>
        {isAFulldayService ? (
          <ContentRightDivision pr={3}>
            <MDLabel fontSize={Theme.fontSizes.xl} color={Colors.Black} mb={3} pb={1}>
              <FormattedMessage id="title_choose_service_providers" defaultMessage="Choose the Service provider" />
            </MDLabel>
            <P fontFamily={Theme.fonts.regular} fontSize={Theme.fontSizes.regular} color={Colors.DarkGrey}>
              <FormattedMessage
                id="subtitle_choose_service_providers"
                defaultMessage="Here you can choose person who will take care of your dog"
              />
            </P>
            {isLoader ? (
              <Div display="flex" m={15}>
                <MainLoader />
              </Div>
            ) : (
              <Div mb={3} pb={1}>
                <Division mt={4} type="active">
                  <BoxDivision type="place_Content">
                    {organisationServiceProvidersService === null ? (
                      <Division type="places" mb={3} pb={1} setBorder>
                        <Loader width="250px" height="75px" />
                      </Division>
                    ) : (
                      !!organisationServiceProvidersService?.service_provider?.length &&
                      organisationServiceProvidersService?.service_provider?.map((obj, i) => {
                        return (
                          !!obj?.available_slots?.length > 0 && (
                            <Division
                              type="places"
                              mb={1}
                              pb={1}
                              key={obj?.id}
                              setBorder={selectedProvider.serviceProviderId === obj?.id}>
                              <LabelBox
                                mb={0}
                                onClick={handleProvider(obj)}
                                border={
                                  selectedDate[0] ===
                                    moment(currentSelectedDate[0], format.date).format(
                                      Constants.input.format.yearToDay
                                    ) && selectedProvider.serviceProviderId === obj?.id
                                    ? "3px solid " + Colors.Primary + " !important"
                                    : "none"
                                }>
                                <MDImage
                                  src={obj?.profile_pic_url}
                                  width={64}
                                  height={64}
                                  borderRadius={Theme.radii.medium}
                                  alt="icon"
                                />
                                <Div alignSelf="center" pl={3} width={255}>
                                  <Div
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="start"
                                    position="relative">
                                    <MDLabel mb={1} textAlign="left !important">
                                      {obj?.full_name}
                                    </MDLabel>
                                    <MDImage
                                      src={icons.QuestionMarkIcon}
                                      alt="icon"
                                      pr={2}
                                      onMouseOver={showPopup(i)}
                                      onMouseLeave={showPopup("")}
                                    />
                                    {openPopup === i && (
                                      <StyledDiv position="absolute">
                                        <ServiceProviderCard serviceProviderCard serviceProviderDetails={obj} />
                                      </StyledDiv>
                                    )}
                                  </Div>
                                  <Box alignItems="center" justifyContent="start !important">
                                    <MDImage src={icons.UserSettingsIcon} alt="icon" mr={1} />
                                    <MDLabel fontSize={Theme.fontSizes.small} color={Colors.DarkGrey} mt={1}>
                                      <FormattedMessage id="service_provider" defaultMessage="Service provider" />
                                    </MDLabel>
                                  </Box>
                                </Div>
                              </LabelBox>
                            </Division>
                          )
                        );
                      })
                    )}
                  </BoxDivision>
                </Division>
              </Div>
            )}
          </ContentRightDivision>
        ) : (
          <ContentRightDivision type="choose_time" pr={3} height="420px" overflowY="scroll">
            <MDLabel fontSize={Theme.fontSizes.xl} color={Colors.Black} mb={3} pb={1}>
              <FormattedMessage id="choose_the_time" defaultMessage="Choose the Time" />
            </MDLabel>
            <P fontFamily={Theme.fonts.regular} fontSize={Theme.fontSizes.regular} color={Colors.DarkGrey}>
              <FormattedMessage
                id="provider_description"
                defaultMessage="Here you can choose time when the
                service should be provided and Service Proveder"
              />
            </P>
            {isLoader ? (
              <Div display="flex" m={15}>
                <MainLoader type="time-accordian" />
              </Div>
            ) : (
              <TimeAccordion currentSelectedDate={moment(currentSelectedDate, format.date).toDate()} handlerNextStep={handlerNextStep} />
            )}
          </ContentRightDivision>
        )}
      </BoxDivision>
      <NextAndBack typeName="backToNext" ref={childRef}/>
    </Div>
  );
};

export default memo(BookingFlowStepFour);
