const StorageName = {
  token: "token",
  userData: "userData",
};
class PersistentStorage {
  storage = localStorage;

  getToken() {
    return this.storage.getItem(StorageName.token);
  }

  setToken(token: string) {
    return this.storage.setItem(StorageName.token, token);
  }

  getUserDetails() {
    return this.storage.getItem(StorageName.userData);
  }

  setUserDetails(token: string) {
    return this.storage.setItem(StorageName.userData, token);
  }
}

export default PersistentStorage;
