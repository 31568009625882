import React, { Component } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import Button from "../../components/Button/Button";
import LoginContainer from "../../components/Containers/LoginContainer";
import TextBox from "../../components/Input/TextBox";
import { GreenLogo } from "../../styles/Icons";
import { Header, MDInputValue } from "../../styles/Styles";
import Constants from "../../shared/Constants";
import SignUpService from "../../services/SignUpService";
import Config from "../../config";
import LoginPageLoader from "../../components/Loader/LoginPageLoader";

/**
 * Handles validation for input fields.
 */
const passwordSchema = Yup.object().shape({
  /**
   * Password validation
   * A valid password must contain atleast
   * one uppercase, one lowercase, one number and one special case character
   * with minimum of 8 digits.
   */
  password: Yup.string()
    .required(<FormattedMessage id="validation_empty_password_input" defaultMessage="Enter password" />)
    .min(8, <FormattedMessage id="validation_length_password_input" defaultMessage="Too short!" />)
    .matches(
      /^.*(?=.{8,})((?=.*[!~@#$%^&*/?()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      Constants.language.validation_valid_password_input,
    ),
  /**
   * Confirm password validation
   */
  confirmPassword: Yup.string()
    .required(<FormattedMessage id="validation_empty_confirm_password" defaultMessage="Enter password" />)
    .when("password", {
      is: (password) => !!(password && password.length > 0),
      then: Yup.string().oneOf([Yup.ref("password")], <FormattedMessage id="validation_valid_confirm_password" defaultMessage="Password does not match" />),
    }),
});

class ResetPassword extends Component {
  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      params: {},
      loading: false,
      showPassword: true,
      showConfirmPassword: true,
    };
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;

    const params = queryString.parse(search);
    this.setState({ params });
  }

  /**
   * A new password will be added.
   * @returns password changed confirmaton page.
   */
  handleSubmit = async (data) => {
    this.setState({ loading: true });
    const { params } = this.state;
    const passwordData = {
      email: params.email,
      token: params.token,
      password: data.password,
      password_confirmation: data.confirmPassword,
    };
    try {
      const response = await this.signUpService.resetPasswordService(passwordData);
      if (response) {
        this.setState({ loading: false, isSubmitted: true });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Redirects to login page
   * @returns login page
   */
   redirectToLoginPage = () => {
     const { isAdmin } = this.props;
     const { history } = this.props;
     if (isAdmin) {
       history.push(Constants.routes.logIn.url);
     } else {
       history.push(Constants.routes.customerLogin.url);
     }
   };

  /**
   * Show/Hide password will be achieved.
   * @returns shows and hides password as per user click
   */
  togglePassword = (label) => {
    const { input: { name: inputNames } } = Constants;
    const { showPassword, showConfirmPassword } = this.state;
    if (label === inputNames.confirmPassword) {
      this.setState({ showConfirmPassword: !showConfirmPassword });
    } else {
      this.setState({ showPassword: !showPassword });
    }
  };

  render() {
    const { input: { name: inputNames, type: inputType }, language, icons: eyeIcons } = Constants;
    const {
      isSubmitted, loading, showPassword, showConfirmPassword,
    } = this.state;
    const { isAdmin } = this.props;

    return (
      <LoginContainer>
        {!loading && isAdmin && <GreenLogo />}
        {isSubmitted && (
          <>
            <Header mt={4} justifyContent="center">
              <FormattedMessage id="common_done" defaultMessage="Done" />
            </Header>
            <MDInputValue size={isAdmin ? "14" : "16"} my={3} pb={2} justifyContent="center">
              <FormattedMessage id="reset_password_submitted_message" defaultMessage="Submitted!" />
            </MDInputValue>
            <Button
              primary
              label={Constants.language.login_submit_button_log_in}
              width="200px"
              onClick={this.redirectToLoginPage}
            />
          </>
        )}
        {!isSubmitted && (
          <>
            {loading && <LoginPageLoader />}
            {!loading && (
              <>
                <Header mt={4} justifyContent="center">
                  <FormattedMessage id="reset_password_label_new_password" defaultMessage="New password" />
                </Header>
                <MDInputValue size={isAdmin ? "14" : "16"} my={3} justifyContent="center">
                  {isAdmin
                    ? <FormattedMessage id="reset_password_message" defaultMessage="Reset Password" />
                    : <FormattedMessage id="customer_reset_password_message" defaultMessage="Reset Password" />}
                </MDInputValue>
                <Formik
                  initialValues={{ confirmPassword: "", password: "" }}
                  validationSchema={passwordSchema}
                  onSubmit={this.handleSubmit}
                >
                  {(props) => {
                    const {
                      values, handleChange, handleBlur, handleSubmit, isValid, touched,
                    } = props;
                    return (
                      <Form>
                        <TextBox
                          containerProps="m-auto justify-content-center"
                          width="300px"
                          label={language.reset_password_label_new_password}
                          placeholder={language.placeholder_password}
                          type={showPassword ? inputType.password : inputType.text}
                          name={inputNames.password}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          formikValues={props}
                          login
                          endIcon={showPassword ? eyeIcons.EyeOpenIcon : eyeIcons.EyeCloseIcon}
                          onEndIconClick={() => this.togglePassword(inputNames.password)}
                        />
                        <TextBox
                          containerProps="m-auto justify-content-center"
                          width="300px"
                          label={language.reset_password_label_repeat_password}
                          placeholder={language.placeholder_password}
                          name={inputNames.confirmPassword}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          formikValues={props}
                          type={showConfirmPassword ? inputType.password : inputType.text}
                          login
                          endIcon={showConfirmPassword ? eyeIcons.EyeOpenIcon : eyeIcons.EyeCloseIcon}
                          onEndIconClick={() => this.togglePassword(inputNames.confirmPassword)}
                        />
                        <Button
                          primary
                          className="mt-4"
                          label={language.common_ok}
                          width="200px"
                          onClick={handleSubmit}
                          disable={!(isValid && Object.keys(touched).length > 0)}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </>
        )}
      </LoginContainer>
    );
  }
}
ResetPassword.propTypes = {
  history: PropTypes.string,
  location: PropTypes.string,
  isAdmin: PropTypes.bool,
};
ResetPassword.defaultProps = {
  history: null,
  location: null,
  isAdmin: false,
};
export default ResetPassword;
