import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CreateDogForm from "./CreateDogForm";
import DogService from "../../../services/CustomerService";
import Config from "../../../config";
import { MDLabel, Div, FormWrapper } from "../../../styles/Styles";
import FormLoader from "../../../components/Loader/FormLoader";
import Theme from "../../../styles/Theme";
import DogModel from "../../../models/Customers";

class DogsTab extends Component {
  dogService = new DogService(Config.apiBase);

  constructor(props) {
    super(props);

    this.state = {
      dogData: [],
      loading: false,
    };
  }

  componentDidMount=() => {
    this.fetchDogDetails();
  }

  fetchDogDetails=async () => {
    this.setState({ loading: true });
    const { userId } = this.props;
    try {
      const response = await this.dogService.getDogsList(userId);
      this.setState({ dogData: response, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleCancelChanges=() => {
    this.fetchDogDetails();
  }

  handleDeleteDog= async (dogId) => {
    this.setState({ loading: true });
    const { userId } = this.props;
    try {
      const response = await this.dogService.deleteDog(userId, dogId);
      if (response) {
        this.fetchDogDetails();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  handleUpdateData=async (dogId, data) => {
    this.setState({ loading: true });
    const { userId } = this.props;
    try {
      this.dogModel = new DogModel({});
      const response = await this.dogService.updateDog(userId,
        dogId, this.dogModel.toDogJson(data));
      if (response) {
        this.fetchDogDetails();
      }
    } catch (e) {
      this.fetchDogDetails();
      this.setState({ loading: false });
    }
  }

  render() {
    const { userId } = this.props;
    const { dogData, loading } = this.state;
    return (
      <>
        {loading && (
          <FormWrapper>
            <FormLoader />
          </FormWrapper>
        )}
        {!loading && dogData.length > 0
          ? dogData.map((data, index) => (
            <>
              <Div className="row m-0">
                <MDLabel fontSize={Theme.space.m} py={2} textAlign="left" lineHeight={Theme.lineHeights.tight}>
                  <FormattedMessage id="common_dog" defaultMessage="Dog" />
&nbsp;
                  {index + 1}
                  :&nbsp;
                  <FormattedMessage id="user_edit_user_description" defaultMessage="View & update details" />
                </MDLabel>
              </Div>
              <CreateDogForm
                dogData={data}
                onCancel={this.handleCancelChanges}
                edit
                submitData={this.handleUpdateData}
                userId={userId}
                dogId={data.id}
                deleteDog={this.handleDeleteDog}
              />
            </>
          )) : (
            <MDLabel fontSize={Theme.fontSizes.regular} display="flex" justifyContent="center" mt={5}>
              <FormattedMessage id="customer_no_dogs_available" defaultMessage="Currently no dogs available under this customer" />
            </MDLabel>
          )}
      </>
    );
  }
}

DogsTab.propTypes = {
  userId: PropTypes.string,
};

DogsTab.defaultProps = {
  userId: "",
};

export default DogsTab;
